<template>
  <div class="rfp-manager-create-rfp-drop-down toolbox flex-hold">
    <label class="toolbox-label">
      &nbsp;
    </label>
    <div
      class="toolbox-content"
    >
      <button
        class="toggler"
        :disabled="accounts.length === 0"
        @click="createRfp()"
      >
        <i class="material-icons">create_new_folder</i>
        Create New RFP
        <div
          class="rbTooltip simple"
          tooltip="placement:top-end"
          v-if="accounts.length === 0"
        >
          You can't create an RFP because you do not have any companies assigned to you.
        </div>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CreateRfpDropDown',
    props: {
      accounts: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        open: false
      }
    },
    methods: {
      createRfp() {this.$emit('createRfp')},
    }
  }
</script>

<style lang="stylus">
  .rfp-manager-create-rfp-drop-down{
    .toolbox-content{
      overflow visible !important;
    }

    .toggler{
      height: 27px;
      padding: 0 5px;
      display: flex;
      justify-content: space-around;
      color: #fff;
      background: #009e91;
      border: none;
      align-items: center;
      font-size 12px;

      i.material-icons{
        padding 0 5px 0 0;
      }
    }
  }
</style>
