/**
 * Created by DejanK on 9/1/2017.
 */
import HTML from './ask-to-sign-in.html'
import { BAD_CREDENTIALS } from "../../shared/sign-in.service";

const NAME = "askToSignIn"

export { NAME, HTML, AskToSignInDialogController as Controller}

AskToSignInDialogController.$inject = ['rbDialog', 'Invitation', 'SignInService'];
function AskToSignInDialogController(dialog, invitation, SignInService){
  const vm = this;

  vm.submit = submit
  vm.cancel = dialog.cancel

  $onInit();

  function $onInit(){
    vm.credentials = {
      email: invitation.emailAddress,
      password: ''
    }
  }

  function submit() {
    SignInService.signIn(vm.credentials)
      .then( dialog.hide, (error) => { vm.badCredentials = error === BAD_CREDENTIALS } )
  }
}
