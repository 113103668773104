/**
 * Created by DejanK on 7/20/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-input-taxes-tooltip.scss');

module.exports = {
  template: require('./rb-negotiations-input-taxes-tooltip.html'),
  bindings: {
    summary: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'TaxesFactory'];
function Ctrl($element,TaxesFactory) {
  let vm = this, tooltipster,
    htmlContentElement, parentElement;

  this.$onInit = ()=>{
    parentElement = $element.parent();
    htmlContentElement = $($element.children()[0]);

    parentElement.tooltipster({
      animationDuration: [300, 100],
      theme: ['', 'rb-negotiations-input-taxes-tooltip'],
      content: htmlContentElement,
      contentAsHtml: true,
      interactive: true,
      distance: 0,
      side: ['left', 'right', 'top', 'bottom']
    });

    tooltipster = parentElement.tooltipster('instance');
  };

  this.$onChanges = updateVM;

  this.$onDestroy = ()=>{
    tooltipster.destroy();
  };

  this.isUnavailable = function(tax){
    return !tax || !tax.valid || tax.type === 'UNAVAILABLE'
  };

  function updateVM(changes){
    if(!checkRequirements()) return;
    vm.summary = changes.summary.currentValue;
    vm.taxes = TaxesFactory.list();
  }

  function checkRequirements(){
    return vm.summary;
  }
}
