/**
 * Created by DejanK on 8/11/2016.
 */
import './forgotten-password.scss'
import { noop } from 'lodash'
import HTML from './enter-email-address.html'

export { HTML, ForgottenPasswordEnterEmailAddressController as Controller}

ForgottenPasswordEnterEmailAddressController.$inject = ['NotificationUtils', 'PublicAuth'];
function ForgottenPasswordEnterEmailAddressController(notificationUtils, publicAuth){
  const vm = this;

  vm.onSubmit = onSubmit;

  function onSubmit(emailAddress){
    notificationUtils.onSave(()=>{ return publicAuth.resetPassword(emailAddress) })
      .then( ()=>{ notificationUtils.showSuccess('reset-password-instructions-sent') }, noop )
  }
}
