/**
 * Created by DejanK on 2/9/2017.
 */

'use strict';

require('./input-date.scss');

let Pikaday = require('pikaday');
let Moment = require('moment');

module.exports = {
  template: '<input type="text"/>',
  bindings: {
    minDate: '<',
    maxDate: '<',
    years: '<',
    position: '<',
    format: '@'
  },
  require: {
    ModelCtrl: 'ngModel'
  },
  controller: Ctrl
};


Ctrl.$inject = ['$element'];
function Ctrl($element){
  let vm = this,
    input = angular.element($element.find('input')),
    pikaday;

  this.$onInit = () =>{
    vm.format = vm.format || 'MM/DD/YYYY';
    pikaday = new Pikaday({
      field: input[0],
      format: vm.format,
      position: vm.position || 'bottom left',
      minDate: parseDate(vm.minDate),
      maxDate: parseDate(vm.maxDate),
      yearRange: vm.years
      //showMonthAfterYear: true,
    });

    input.on('blur focusout onblur', ()=>{
      vm.ModelCtrl.$setViewValue(input.val());
      vm.ModelCtrl.$setTouched();
    });

    vm.ModelCtrl.$parsers.unshift((viewValue)=>{
      let m = Moment(viewValue, vm.format);
      if(m.isValid()){
        vm.ModelCtrl.$setValidity('valid', true);
        return m.format('YYYY-MM-DD')
      } else {
        vm.ModelCtrl.$setValidity('valid', false);
        return '';
      }
    });

    vm.ModelCtrl.$render = ()=>{
      let m = Moment(vm.ModelCtrl.$viewValue, 'YYYY-MM-DD');
      if(m.isValid()) {
        input.val(m.format(vm.format));
        pikaday.setMoment(m);
      } else {
        input.val(vm.ModelCtrl.$viewValue);
      }

    };
  };

  this.$onChanges = (changes) =>{
    changes.minDate && pikaday && onMinDateChanged();
    changes.maxDate && pikaday && onMaxDateChanged();
  };

  function parseDate(date){
    let d = date === 'now' ? Moment() : Moment(date);
    return date && d.isValid() ? d.toDate() : undefined;
  }

  function onMinDateChanged(){
    pikaday.setMinDate(parseDate(vm.minDate));
  }

  function onMaxDateChanged(){
    pikaday.setMaxDate(parseDate(vm.minDate));
  }
}
