/**
 * Created by DejanK on 4/5/2017.
 */
import angular from 'angular';
import States from './pages/rfp-hotel-bid.states';
import Dialogs from './dialogs';
import RfpHotelBidRepository from './rfp-hotel.bid.repo';
import rbBidSideMenu from './rb-bid-side-menu/rb-bid-side-menu.cmp';
import rbHotelRfpStandardBidResponse from './pages/response/standard-bid-response/standard-bid-response.cmp';
import rbHotelRfpRateQuickBidResponse from './pages/response/rate-quick-bid-response/rate-quick-bid-response.cmp';
import rbHotelRfpBidStandardPreview from './pages/preview/standard-preview.cmp';
import rbHotelRfpBidRateQuickPreview from './pages/preview/rate-quick-preview.cmp';

export default angular.module('rb.rfpHotel.main.bid', [])
  .config(States)
  .config(Dialogs)
  .service('RfpHotelBidRepository', RfpHotelBidRepository)
  .component('rbBidSideMenu', rbBidSideMenu)
  .component('rbHotelRfpStandardBidResponse', rbHotelRfpStandardBidResponse)
  .component('rbHotelRfpRateQuickBidResponse', rbHotelRfpRateQuickBidResponse)
  .component('rbHotelRfpBidStandardPreview', rbHotelRfpBidStandardPreview)
  .component('rbHotelRfpBidRateQuickPreview', rbHotelRfpBidRateQuickPreview)
