/**
 * Created by DejanK on 7/21/2017.
 */
import angular from 'angular';
import Negotiations from './negotiations';

import BidManagerTable from './rb-bid-manager-table/rb-bid-manager-table.cmp';
import rbBidManagerAddHotels from './rb-bid-manager-add-hotels/rb-bid-manager-add-hotels.cmp';
import rbBidManagerViews from './rb-bid-manager-views/rb-bid-manager-views.cmp';
import rbBidManagerCustomActions from './rb-bid-manager-custom-actions/rb-bid-manager-custom-actions.cmp';
import rbBidManagerFilterBy from './rb-bid-manager-filter-by/rb-bid-manager-filter-by.cmp';
import rbBidManagerWithNegotiationsNew from './rb-bid-manager-with-negotiations-new/rb-bid-manager-with-negotiations.cmp';


export default angular.module('rb.rfp.bidManager.main.ui', [ Negotiations ])
    .component('rbBidManagerWithNegotiationsNew', rbBidManagerWithNegotiationsNew)

    .component('rbBidManagerTable', BidManagerTable)
    .directive('rbBidMenu', require('./rb-bid-menu/rb-bid-menu.dir'))
    .component('rbBidManagerViews', rbBidManagerViews)
    .component('rbBidManagerGroupBy', require('./rb-bid-manager-group-by/rb-bid-manager-group-by.cmp'))
    .component('rbBidManagerFilterBy', rbBidManagerFilterBy)
    .component('rbBidManagerActions', require('./rb-bid-manager-actions/rb-bid-manager-actions.cmp'))
    .component('rbBidManagerActionsList', require('./rb-bid-manager-actions-list/rb-bid-manager-actions-list.cmp'))
    .component('rbBidManagerCustomActions', rbBidManagerCustomActions)
    .component('rbBidManagerAddHotels', rbBidManagerAddHotels)
