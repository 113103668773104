<template>
  <rbv-dropdown :align="align" class="rb-action-select" :is-open="open" @close="toggle" backdropStyle="backdrop">
    <div
      @click="toggle"
      slot="toggler">
      <div
        :class="{inactive: !(match && theValue), dark}"
        class="button control filter">
        <template v-if="!$slots.default">
          <slot name="icon"/>
          <label class="label">
            <slot name="label"/>
          </label>
          {{ match && theValue? match.text: ''}}
        </template>
        <slot v-else></slot>
        <i class="material-icons">keyboard_arrow_down</i>
      </div>
    </div>
    <ul slot="dropdown" class="dropdown" :class="{dark}">
      <li
        :class="{item: true, active: selected(item.id), [sortOrder]: selected(item.id) }"
        v-for="item in items"
        :key="item.id"
        @click="select(item.id)"
      >
        {{item.text}}
        <template v-if="sorter && item.id">
          <i class="material-icons icon2">sort</i>
        </template>
      </li>
    </ul>
  </rbv-dropdown>
</template>

<script>
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';

  export default {
    name: 'rb-hd-select',
    props: ['items', 'value', 'dark', 'sorter', 'align'],
    components: { RbvDropdown },
    data() {
      return {
        open: false
      };
    },
    computed: {
      match() {
        return this.items.find(item => item.id === this.theValue);
      },
      theValue() {
        return this.sorter? this.value.id : this.value;
      },
      sortOrder() {
        return this.value && this.value.order || 'asc';
      }
    },
    methods: {
      toggle() {
        this.open = !this.open;
      },
      select(id) {
        if(this.sorter) {
          if(this.theValue === id) {
            const order = this.sortOrder === 'asc'? 'desc': 'asc';
            this.$emit('input', {id, order})
          }else{
            this.$emit('input', {id, order: 'asc'});
          }
        }else{
          this.$emit('input', id);
        }
        this.toggle();
      },
      selected(id) {
        return id === this.theValue;
      },
    },
  };
</script>


<style lang="stylus">
  .rb-action-select{
    border-radius: 50px;
    height 35px;

    &+& {
      margin-left 10px;
    }

    .filter{
      position relative;
      background transparent !important;

      &.inactive{
        color #B0BEC5 !important;
      }

      &:not(.inactive){
        i.material-icons:first-child, .label{
          color #329188;
        }

        .label{
          position: absolute;
          top: -10px;
          font-size: 12px;
          left: 15px;
          background #fff;
        }

        &.dark {
          .label{
            background: #313c42;
          }
        }
      }

      .label{
        transition all .3s;
      }

      *{
        vertical-align middle;
      }
    }

    .backdrop{
      background-color transparent !important;
    }

    &.dark-background {
      .filter{
        background: #263238 !important;
      }
    }

    .item.desc .icon2{
      transform rotateX(180deg);
    }
  }
</style>

