<template>
  <rb-modal @cancel="cancel()" @hide="hide()" :class="$style.dialog">
    <span slot="header">{{ preview ? 'Preview' : 'View' }} "No Thank You" Message</span>
    <div v-if='isLoading' slot="body">
      <rb-loading />
    </div>
    <div v-else slot="body" :class="$style.letter" v-html="message"></div>
    <div slot="footer" class="layout-row">
      <div class="flex-100 text-left">
        <div v-if="preview" ><i class="material-icons" :class="$style.editMessageIcon">info</i>	<span :class="$style.editMessageText">Ability to edit message coming soon.</span></div>
      </div>
      <div class="flex-hold">
        <button class="rbButton mainButton" @click="hide()">OK</button>
      </div>
    </div>
  </rb-modal>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import RbLoading from 'vRoot/_core/RbvLoading.vue'
  import {notificationUtils, mainAPI} from "root/angular-injector-provider";

  export default {
    name: 'rbv-hotel-rfp-bid-manager-action-view-no-thank-you',
    mixins: [ DialogMixin, RbEventsMixin ],
    components: { RbModal, RbLoading },
    props: { 'bidId': { required: true }, preview: { "default": false } },
    data () {
      return {
        isLoading: true
      }
    },
    asyncComputed: {
      message(){
        return notificationUtils().onLoad( () => mainAPI().viewNoThankYouMessage(this.bidId) )
          .then(
            response => {
              this.isLoading = false
              return response.data;
            },
            () => this.cancel()
          )
      }
    }
  }
</script>
<style lang="stylus" module="">
  .dialog {
    max-width: 600px;
    font-size: 12px;
    color: #263238;
  }

  .letter {
    background-color: #F5F6F7;
    border: 1px solid #78909c;
    border-radius: 5px;
    color: #16272b;
    padding: 10px;
    font-size: 11px;
  }

  .editMessageIcon {
    font-size: 16px;
    height: 16px;
    color: #F15A24;
    vertical-align: middle;
  }

  .editMessageText {
    font-size: 11px;
    color: #F15A24;
    vertical-align: middle;
  }

</style>
