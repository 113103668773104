/**
 * Created by Stefan on 10/4/2016.
 */

export default {
  template: templateFn,
  bindings: {
    model: '='
  }
}

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, rbInputTemplate){
  return rbInputTemplate.textLengthTemplate(rbInputTemplate.readDefaultAttributes($attrs, {
    maxlength: 50
  }))
}
