/**
 * Created by DejanK on 4/25/2017.
 */
import './rb-travel-destination-views.scss'
import HTML from './rb-travel-destination-views.html'

export default {
  template: HTML,
  bindings: {
    destination: '<',
    rfpId: '<',
  },
  controller: RbTravelDestinationViews
};

RbTravelDestinationViews.$inject = ['ControllerUtils', 'RfpDestinationsViewService'];
function RbTravelDestinationViews(utils, DestinationsViewService) {
  const vm = this;

  vm.$onChanges = $onChanges;
  vm.onSelected = onSelected;
  vm.onDropDownOpened = onDropDownOpened;
  vm.onDropDownClosed = onDropDownClosed;
  vm.loadDestinations = loadDestinations;
  vm.onDestinationSelected = onDestinationSelected;
  vm.$onInit = () => {
    loadDestinations();
  }

  function loadDestinations() {
    utils.loadTo(vm, 'destinations', () => {
      return DestinationsViewService.getRfpTravelDestinations(vm.rfpId)
    });
  }

  function $onChanges(changes){
    (changes.destination || changes.destinations);
  }

  function onSelected(destination){
    if(destination === vm.destination) { return }

    vm.onDestinationSelected({event: {
      destination
    }});
  }

  function onDestinationSelected({event}) {
    utils.go('hotelRfp.rfp.destinations.map', {'destinationId':event.destination.id});
  }

  function onDropDownOpened(){
    vm.dropDownOpened = true;
  }

  function onDropDownClosed(){
    vm.dropDownOpened = false;
  }
}
