import noop from 'lodash/noop';
import browserUpdate from 'browser-update';

export default function () {
  browserUpdate({

    required: {e: -2, f: -3, o: -3, s: -1, c: -3},
    insecure: true,
    api: 2018.10,
    shift_page_down: false,
    reminder: 0,
    no_permanent_hide: true,
    reminderClosed: 1,

    onshow() {
      const ua = window.navigator.userAgent;
      if (ua.indexOf('MSIE ') > 0) {
        document.getElementById('rbMainView').setAttribute('style', 'display:none;');
        document.getElementById('browserNotSupported').setAttribute('style', 'display:block;');
      }
    },

    onclick(op) {
      op.setCookie = noop;
    },

    onclose(op) {
      op.setCookie = noop;
    }
  });
}
