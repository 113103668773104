import {logError} from 'root/log/log';

let injector

export {
  injectorGet as AngularServices,
  $newScope,
  $state,
  PublishAngularInjectorForVue,
  accountRepository,
  currentUser,
  httpErrorHandler,
  mainAPI,
  notificationUtils,
  ngDialog,
  hotelRfpBidManagerService,
  hotelRfpNegotiationsPanelMessagingService,
  hotelDirectoryRepository,
  rfpManagerRepository,
  AccountRepository,
  InvitationService,
  InvitationHandlersRepository
}

PublishAngularInjectorForVue.$inject = ['$injector']
function PublishAngularInjectorForVue($injector) {
  injector = $injector;
}

function injectorGet(name){
  !injector && logError('Angular Injector not Loaded');
  return injector.get(name)
}

function accountRepository(){
  return injectorGet('AccountRepository')
}

function currentUser(){
  return injectorGet('CurrentUser')
}

function mainAPI(){
  return injectorGet('MainAPI')
}

function httpErrorHandler(){
  return injectorGet('HttpErrorHandler')
}

function $newScope(){
  const $rootScope = injectorGet('$rootScope')
  return $rootScope.$new(true, $rootScope)
}

function $state(){
  return injectorGet('$state')
}

function notificationUtils(){
  return injectorGet('NotificationUtils')
}

function hotelDirectoryRepository(){
  return injectorGet('rbvHotelDirectoryRepository');
}
function ngDialog(){
  return injectorGet('rbDialog')
}

function hotelRfpBidManagerService(){
  return injectorGet('BidManagerService')
}

function hotelRfpNegotiationsPanelMessagingService(){
  return injectorGet('NegotiationsPanelMessagingService')
}

function rfpManagerRepository() {
  return injectorGet('rfpManagerRepository')
}

function AccountRepository() {
  return injectorGet('AccountRepository')
}

function InvitationService() {
  return injectorGet('InvitationService')
}

function InvitationHandlersRepository() {
  return injectorGet('InvitationHandlersRepository')
}
