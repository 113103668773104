import get from 'lodash/get';
import HTML from './view-standard-cover-letter.html';

export default {
  template: HTML,
  bindings: {
    rfp: '<'
  },
  controller: HotelRfpViewStandardCoverLetterController,
}

function HotelRfpViewStandardCoverLetterController(){
  const
    vm = this;

  Object.assign(this, {
    $onChanges ( changes ) {
      vm.rfp = get(changes, 'rfp.currentValue');
      vm.rfpId = get(vm.rfp, 'id');
      vm.isChainEnabled = get(vm.rfp, 'specifications.chainSupport', false)
    }
  });
}
