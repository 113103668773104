<template>
  <div class="vModal vModalDefault">
    <div class="vModalDefaultHeader">
      <slot name="header">Default Header</slot>
      <button class="vModalDefaultCloseButton" @click="cancel()">close</button>
    </div>
    <div class="vModalDefaultBody" :class="bodyScrollStyle">
      <slot name="body">Default Body</slot>
    </div>
    <div class="vModalDefaultFooter">
      <slot name="footer">
        <button class="rbButton" @click="cancel()">{{mergedOptions.labels.buttons.cancel}}</button>
        <button class="rbButton mainButton" @click="hide()" :disabled="isDisabled">{{mergedOptions.labels.buttons.apply}}</button>
      </slot>
    </div>
  </div>
</template>

<script>
  import { merge } from 'lodash'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'

  export default {
    name: 'rb-modal',
    mixins: [ DialogMixin ],
    data () {
      return {
        defaultOptions: { labels: { buttons: { apply: 'Apply', cancel: 'Cancel' } } }
      }
    },
    props: {
      options: {
        type: Object,
        default() { return {} }
      }
    },
    computed: {
      mergedOptions () {
        return merge({}, this.defaultOptions, this.options)
      },
      bodyScrollStyle () {
        return this.mergedOptions.bodyScroll ? this.$style.bodyScroll : ''
      },
      isDisabled () {
        return this.mergedOptions.disableOk && this.mergedOptions.disableOk()
      }
    }
  }
</script>

<style lang="stylus" module="">
  .bodyScroll {
    overflow: auto
  }
</style>
