/**
 * Created by stefan on 10/5/2016.
 */
import HTML from './rb-input-address-with-coordinates.html'
import { parseGooglePlace } from "root/shared/tools/google/googlePlaceParser";
import { get } from 'lodash'

export default {
  template: HTML,
  controller: Controller,
  bindings: {
    model: '=',
    type: '<',
    showForm: '<',
    required: '<'
  }
}

Controller.$inject = ['$scope'];
function Controller($scope) {
  const vm = this

  updateType()
  vm.$onChanges = $onChanges
  vm.onAddManually = onAddManually
  vm.onAutocompleteAddressSelected = onAutocompleteAddressSelected

  function updateType(type) {
    vm.type = getType(type)

    function getType(addressType) {
      switch (addressType) {
        case 'CITY':
          return 'CITY'
        case 'ADDRESS':
        default:
          return 'ADDRESS'
      }
    }
  }

  function $onChanges(changes){
    changes.type && updateType(changes.type.currentValue)
    changes.required && updateRequired(changes.required.currentValue)
    changes.showForm && changes.showForm.currentValue && onAddManually()
  }

  function updateRequired(val) {
    vm.required = val
  }

  function onAutocompleteAddressSelected($event) {
    vm.model = parseGooglePlace($event.place)
  }

  function onAddManually() {
    const c = get(vm, 'model.address.country');
    if(!c){ vm.model = { address: { country: 'US' } } }
    $scope.$timeout(() => { vm.addressForm.$rbTouchAllFields() })
  }
}
