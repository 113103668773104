<template>
  <div @scroll="onScroll($event)">
    <slot name="before"></slot>
    <v-chunk v-for="chunk in chunks" :key="chunk.id"
             :chunk-id="chunk.id"
             :items="chunk.items"
             :size="size"
             :upper-bound="upperBound"
             :lower-bound="lowerBound"
             :refresh="refresh">

      <template slot-scope="item">
        <slot :item="item.item"></slot>
      </template>
    </v-chunk>
    <slot name="after"></slot>
  </div>
</template>

<script>
  import { throttle } from 'lodash'
  import vChunk from './RbVirtualListChunk.vue'

  export default {
    name: 'vList',
    components: {vChunk},
    data: function(){
      return {
        upperBound: 0,
        lowerBound: 0,
        refresh: 0,
        chunks: null
      }
    },
    props: ['items', 'maxChunkSize', 'size', 'scrollTo' ],
    watch:{
      items (val) {
        this.chunks = this.createChunks(val);
        this.refresh++
      },
      scrollTo (val) {
        this.$el.scrollTop = this.size * val
        this.refresh++
      },
    },
    methods: {
      createChunks: function(items){
        const chunks = [], maxChunkSize = this.maxChunkSize || Math.ceil(Math.sqrt(items.length))
        let chunksLength = 0, chunkItemsLength = 0, chunk = null

        chunks.length = items.length; // allocate array space
        newChunk()

        items.forEach( i => {
          chunkItemsLength > maxChunkSize && newChunk()
          chunk.items[chunkItemsLength++] = i
        })
        chunk.items.length = chunkItemsLength
        chunks.length = chunksLength
        return chunks;

        function newChunk(){
          if(chunk){
            chunk.items.length = chunkItemsLength
            chunkItemsLength = 0
          }
          chunk = { id: chunksLength, items: [] }
          chunk.items.length = maxChunkSize
          chunks[chunksLength++] = chunk
        }
      },
      onScroll: throttle(function(){ this.refresh++ }, 33, {leading: false, trailing: true}),
    },
    created: function(){
      const buffer = 1.7 * Math.max(document.documentElement.clientHeight, window.innerHeight || 1080);
      this.upperBound = 0 - buffer
      this.lowerBound = buffer
      this.chunks = this.createChunks(this.items);
      this.refresh++
    }
  }
</script>
