/**
 * Created by DejanK on 1/9/2017.
 */



module.exports = RfpDestinationsMapController;

require('./map-destinations.scss');
const lodash = require('lodash');

RfpDestinationsMapController.$inject = ['ControllerUtils', 'NotificationUtils', 'TravelDestinationManagerService', 'RfpDestinationsViewService', 'TravelDestinationManagerFiltersService', '$scope'];
function RfpDestinationsMapController(utils, NotificationUtils, ManagerService, DestinationsViewService, filtersService, $scope){
  const vm = this;

  vm.toggleTable = toggleTable;

  vm.onFiltersChanged = onFiltersChanged;
  vm.saveFilters = saveFilters;
  vm.setDefaultFilters = setDefaultFilters;
  vm.onPanMapTo = onPanMapTo;
  vm.onDone = onDone;

  vm.tableExpanded = false;

  $onInit();

  function $onInit(){
    vm.rfpId = utils.getParam('rfpId');
    vm.destinationId = utils.getParam('destinationId');

    loadDestination(vm.rfpId, vm.destinationId);
  }

  function loadDestination(rfpId, destinationId) {
    utils.loadTo(vm, 'destination', () => ManagerService.getRfpTravelDestination(rfpId, destinationId))
      .then(getSuppliers)
      .catch( noop )
  }

  function getSuppliers(){
    utils.showDialog('working', {params: {type: 'loading'}});
    utils.loadTo(vm, '$suppliers', ()=> {
      return ManagerService.getSuppliers(vm.rfpId, vm.destinationId, vm.destination.data.filter);
    }).then(()=>{
    // load into temporary $suppliers to prevent resetting suppliers and forcing re-adding of all markers and list items
      vm.suppliers = vm.$suppliers;
    })
      .finally(utils.cancelDialog);
  }

  function toggleTable(event) {
    $scope.$timeout(() => { vm.tableExpanded = event.state })
  }

  function onFiltersChanged($event){
    if($event.apply && !lodash.isEqual(lodash.get(vm, 'destination.data.filter'), $event.filters)){
      lodash.set(vm, 'destination.data.filter', $event.filters);
      getSuppliers();
    }
  }

  function saveFilters($event) {
    NotificationUtils.onSave(() => filtersService.saveFilter(vm.rfpId, vm.destinationId, $event.filters))
      .catch(noop)
  }

  function setDefaultFilters($event){
    NotificationUtils.onSave(() => filtersService.setDefaultFilters(vm.rfpId, $event.filters))
      .catch(noop)
  }

  function onDone(){
    const param = utils.getParam('from');
    if(param && utils.goToEncodedState(param)){
      return;
    }
    utils.go('hotelRfp.rfp.destinations.view', {rfpId: vm.rfpId});
  }

  function noop() {

  }
  // old code

  function onPanMapTo($event){
    vm.mapCenter = $event.coordinates;
  }

}
