<template>
  <div></div>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import { Google } from 'root/shared/tools/google/googleProvider';

  export default {
    name: 'RbInputCoordinates',
    $_veeValidate: {
      value() {
        return this.modelValue
      }
    },
    props: {
      value: {},
      zoom: {
        default: 3
      },
      mapTypeId: {
        'default': 'terrain'
      }
    },
    data () {
      return {
        modelValue: undefined,
        viewValue: undefined,
        mapCenter: undefined
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(v) {
          this.modelValue = v;
          this.modelFormat();
          this.modelRender()
        },
      },
      zoom: {
        immediate: true,
        handler(val) {
          return this.map && this.map.setZoom(val)
        },
      },
    },
    mounted () {
      this.initMap()
        .then(this.initMarker)
    },
    methods: {
      // model methods
      modelFormat() {},
      modelRender() {
        this.viewValue = this.modelValue ? cloneDeep(this.modelValue) : {lat: 40.1451, lng: -99.6680};
        this.marker && this.marker.setPosition(this.viewValue);
        this.mapCenter = cloneDeep(this.viewValue);
        return this.map && this.map.panTo(this.mapCenter)
      },
      modelParse() {
        this.modelValue = this.viewValue
      },
      modelEmit() {
        this.$emit('input', this.modelValue);
        this.$emit('change', this.modelValue);
      },

      initMap(){
        return Google()
          .then(google => {
            this.map = new google.maps.Map(this.$el, {
              center: this.mapCenter,
              zoom: this.zoom,
              mapTypeId: this.mapTypeId
            })
          })
      },
      initMarker(){
        return this.map && Google()
          .then(google => {
            this.marker = new google.maps.Marker({
              map: this.map,
              position: this.viewValue,
              draggable: true
            });

            this.marker.addListener('dragend', event => {
              this.viewValue = event.latLng.toJSON();
              this.modelParse();
              this.modelEmit()
            })
          })
      }
    }
  }
</script>
