<template>
  <rb-modal @cancel="cancel()" @hide="submit()" :class="$style.dialog">
    <span slot="header">Send "No Thank You" Message</span>
    <div slot="body">
      You have selected {{bidsCountLabel}} to respond with "No Thank You" message. Are you sure you want to continue?
    </div>
    <div slot="footer" class="layout-row">
      <div class="flex-100 text-left">
        <button class="rbButton" @click="previewMessage()">Preview Message</button>
      </div>
      <div class="flex-hold">
        <button class="rbButton" @click="cancel()">Cancel</button>
        <button class="rbButton mainButton" @click="submit()">Send</button>
      </div>
    </div>
  </rb-modal>
</template>

<script>
  import {Dialog} from 'root/v-app/rbServices'
  import {noop} from 'lodash'
  import ViewNoThankYouMessageDialog from 'vRoot/rfp-hotel/bid-manager/actions/ViewNoThankYouMessageAction.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import RbLoading from 'vRoot/_core/RbvLoading.vue'
  import {notificationUtils, mainAPI} from "root/angular-injector-provider";

  export default {
    name: 'rbv-hotel-rfp-bid-manager-action-send-no-thank-you',
    mixins: [ DialogMixin, RbEventsMixin ],
    components: { RbModal, RbLoading },
    props: { 'bidsIds': {
        required: true,
        type: Array
      } },
    computed: {
      bidsCountLabel() {
        const count = this.bidsIds.length,
          isSingle = count === 1
        return isSingle ? 'a Bid' : `${count} Bids`
      }
    },
    methods: {
      submit () {
        notificationUtils().onSave( () => mainAPI().sendNoThankYou(this.bidsIds) )
          .then( report => this.hide(report), err => this.cancel(err) )
      },
      previewMessage() {
        Dialog.show(ViewNoThankYouMessageDialog, { bidId: this.bidsIds[0], preview: true })
          .catch( noop )
      }
    }
  }
</script>
<style lang="stylus" module="">
  .dialog {
    max-width: 450px;
    font-size: 12px;
    color: #263238;
  }
</style>
