/**
 * Created by DejanK on 4/5/2017.
 */
import { get, isEmpty, isUndefined, forEach, intersection, noop } from 'lodash'
import BidManagerTable from './bid-manager-table.service'
import {stopEvent} from '../../../../../shared/tools/view-utils';

import moment from 'moment';

import './rb-bid-manager-table.scss'
import HTML from './rb-bid-manager-table.html'
import { NAME as SetSupplierContactDialog} from '../../dialogs/set-supplier-contact/set-supplier-contact.ctrl';
import { NAME as BidManagerHotelProfileDialog } from 'root/rfp/bid-manager/main/dialogs/bid-manager-hotel-profile/bid-manager-hotel-profile.ctrl';
import { NAME as CreateNewSupplierContact } from '../../dialogs/create-new-supplier-contact/create-new-supplier-contact.ctrl';

import defaultImage from 'images/user-image.png'

export default {
  template: HTML,
  controller: Ctrl,
  bindings: {
    view: '<',
    bids: '<',
    clearSelectedBids: '<',
    sortBy: '&',
    onBidSelectionChange: '&'
  }
}

Ctrl.$inject = ['BidManagerService', 'rbDialog', '$state'];
function Ctrl(bidManagerService, dialog, $state) {
  const vm = this, EMPTY_VALUE = '---';
  let bidManagerTable;
  vm.selectedBids = {};

  vm.$onChanges = $onChanges;
  vm.duration = date => moment(date).fromNow();
  vm.contactHovered = contactHovered

  vm.defaultImage = defaultImage;
  vm.get = getValue;
  vm.onSortChanged = onSortChanged;
  vm.onBidSelectionChanged = onBidSelectionChanged;
  vm.onAllBidsSelectionChanged = onAllBidsSelectionChanged;
  vm.isEmpty = isEmpty;
  vm.setSupplierContact = setSupplierContact;

  vm.getColumnHeaderLabel = getColumnHeaderLabel;
  vm.printValue = BidManagerTable.cacheWrap(printValue);
  vm.printDiff = BidManagerTable.cacheWrap(printDiff);
  vm.toSrefLink = toSrefLink;
  vm.showWithdrawReason = showWithdrawReason;
  vm.showHotelProfile = showHotelProfile;
  vm.getRepresentativeType = getRepresentativeType;

  function $onChanges(changes){
    if(changes.clearSelectedBids) { vm.selectedBids = {}; vm.allBidsSelected = false; }
    if(changes.view && vm.view) {
      bidManagerTable = BidManagerTable()
    }
  }

  function contactHovered(bid) {
    if(bid.$supplierContact.isUser) {
      bid.$supplierContact.lastActive = 'LOADING';
      bidManagerService.getBidContactLastActive(bid.$bidId).then(({lastActive}) => {
        bid.$supplierContact.lastActive = lastActive;
      });
    }
  }

  function getValue(obj, key, defaultValue) { return get(obj, key, isUndefined(defaultValue) ? EMPTY_VALUE : defaultValue)}

  function onSortChanged(sortBy){ vm.sortBy({$event: {sortBy: sortBy}}) }

  function onBidSelectionChanged(){
    const bids = [];
    vm.allBidsSelected = true;

    forEach(vm.bids.data, (bid)=>{
      if(!bid.header){
        const selected = vm.selectedBids[bid.$bidId];
        if(selected){
          bids.push(bid);
        } else {
          vm.allBidsSelected = false;
        }
      }
    });

    publishSelectedBids(bids);
  }

  function onAllBidsSelectionChanged(){
    const bids = [];
    if(vm.allBidsSelected){
      forEach(vm.bids.data, (bid)=>{
        if(!bid.header){
          vm.selectedBids[bid.$bidId] = true;
          bids.push(bid);
        }
      });
    } else {
      vm.selectedBids = {};
    }

    publishSelectedBids(bids);
  }

  function publishSelectedBids(bids){
    vm.onBidSelectionChange({$event: {selectedBids: bids}})
  }

  function setSupplierContact(bid){
    dialog.show(SetSupplierContactDialog, { locals: {Bids: [ bid ]} })
      .then(res => {
        if(res && (res.create || res.edit)) {
          return createNewContact(res.user, bid);
        }else {
          return bidManagerService.updateBids(res);
        }
      });
  }

  function createNewContact(contact, bid) {
    return dialog.show(CreateNewSupplierContact, { locals: {Contact: contact, Bids: [ bid ]}})
      .then(res => {
        const bidObject = bidManagerService.updateBids(res).children[0].tObject;
        return setSupplierContact(bidObject)
      });
  }

  function getColumnHeaderLabel(columnClasses, label, abbreviation){
    return intersection(['xs', 'xxs'], columnClasses.split(' ')).length ? abbreviation : label;
  }

  function printValue(bid, cell){ return bidManagerTable.printValue(bid, cell) }

  function printDiff(bid, cell){ return bidManagerTable.printDiff(bid, cell, '---') }

  function toSrefLink($event, bid, cell){
    stopEvent($event);
    $state.inNew(cell.sref, bidManagerTable.getParams(bid, cell))
  }

  function showWithdrawReason(bid) { dialog.show('viewWithdrawMessage', {locals: {Bid: bid}}).catch( noop ) }

  function showHotelProfile($event, bid){
    dialog.show(BidManagerHotelProfileDialog, {locals: {HotelId: bid.$hotelId}}).catch( noop )
  }

  function getRepresentativeType(bid){
    switch(get(bid, 'supplier.contact.company.type')){
      case 'HOTEL': return 'Hotel Representative';
      case 'CHAIN': return 'Chain Representative';
      default: return ''
    }
  }
}
