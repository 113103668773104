/**
 * Created by DejanK on 10/10/2016.
 */

'use strict';

let lodash = require('lodash');

module.exports = function(){
  return {
    controller: Ctrl
  }
};

Ctrl.$inject = ['$window', '$rootScope'];
function Ctrl($window, $rootScope){
  angular.element($window).on('resize', lodash.debounce(()=>{
    $rootScope.$broadcast('RbWindowResized');
  }, 20));
}
