export default RFPManagerRepository

RFPManagerRepository.$inject = ['MainServer', '$q', 'DataServer'];
function RFPManagerRepository(mainServer, $q, dataServer){
  const $repo = this;
  $repo.getEntityIndustries = dataServer.getIndustries;

  $repo.listRFPs = accountIds => accountIds && accountIds.length > 0?
    mainServer.get(`rfp-manager/list-rfps/`, {accountIds}): $q.reject({status: 404});

  $repo.listCompanies = () => mainServer.get(`rfp-manager/list-accounts-with-access`);
  $repo.listAllCompanies = () => mainServer.get(`rfp-manager/list-all-accounts-with-access`);

  $repo.getBidManagerView = (params) => params ?
    mainServer.get(`rfp-manager/get-bid-manager-view`, params):
    $q.reject({status: 404});

  $repo.archiveRfps = rfpIds => rfpIds.length > 0? mainServer.put(`rfp-manager/archive-rfps`, {rfpIds}): $q.reject({status: 404});
  $repo.unArchiveRfps = rfpIds => rfpIds.length > 0? mainServer.put(`rfp-manager/un-archive-rfps`, {rfpIds}): $q.reject({status: 404});
  $repo.completeRfps = rfpIds => rfpIds.length > 0? mainServer.put(`rfp-manager/complete-rfps`, {rfpIds}): $q.reject({status: 404});
  
  $repo.updateLaunchDate = (rfpId, time) => rfpId && time? mainServer.put(`rfp-manager/update-launch-date`, {time, rfpId}): $q.reject({status: 404});
}
