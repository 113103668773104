export { stopEvent, encodeState }

function stopEvent(event){
  event.preventDefault();
  event.stopPropagation();
}

function encodeState(state){
  return encodeParam({state: state.current.name, params: state.params});
}

function encodeParam(obj){
  return encodeURIComponent(btoa(JSON.stringify(obj)));
}
