/**
 * Created by DejanK on 8/17/2016.
 */
import '../dialogs.scss'
import '../notifications.scss'
import HTML from './error-notification.html'

const NAME = 'error'

export { NAME, HTML, ErrorNotificationController as Controller }

ErrorNotificationController.$inject = ['rbDialog', 'Error'];
function ErrorNotificationController(rbDialog, Error) {
  const vm = this;
  vm.error = Error;
  vm.cancel = rbDialog.cancel;
}
