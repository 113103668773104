/**
 * Created by DejanK on 11/21/2016.
 */

'use strict';

module.exports = AuthorizationStorageService;

AuthorizationStorageService.$inject = ['locker'];
function AuthorizationStorageService(locker) {
  let $service = this,
    tokenStorageKey = 'auth',
    userStorageKey = 'user';

  $service.saveToken = saveToken;
  $service.getToken = getToken;
  $service.forget = forgetToken;

  $service.saveUser = saveUser;
  $service.getUser = getUser;

  function saveToken (authToken) {
    locker.put(tokenStorageKey, authToken);
    forgetUser();
  }

  function getToken() {
    return locker.get(tokenStorageKey)
  }

  function forgetToken() {
    locker.forget(tokenStorageKey);
    forgetUser();
  }

  function saveUser(currentUser) {
    currentUser.$token = getToken();
    locker.put(userStorageKey, currentUser)
  }

  function getUser() {
    let user = locker.get(userStorageKey);
    if(user && user.$token !== getToken()){
      forgetUser();
      user = undefined;
    }
    return user;
  }

  function forgetUser() {
    locker.forget(userStorageKey)
  }
}
