import { mainAPI } from "root/angular-injector-provider";

export default {
  labelOf: labelOf
}

function labelOf(type) {
  return mainAPI().getEntityTypes()
    .then( types => {
      const typeObject = types.find( t => t.id === type )
      return typeObject === null ? type : typeObject.label
    })
    .catch( () => type )
}
