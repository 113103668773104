/**
 * Created by DejanK on 4/26/2017.
 *
 * Bid Manager Group By
 */
'use strict';

module.exports = {
  template: require('./rb-bid-manager-group-by.html'),
  bindings: {
    groupedBy: '<',
    groupableBy: '<',
    onChange: '&'
  },
  controller: RbBidManagerGroupBy
};

RbBidManagerGroupBy.$inject = [];
function RbBidManagerGroupBy() {
  let vm = this;

  vm.onGroupByChanged = onGroupByChanged;

  function onGroupByChanged(){
    vm.onChange({event: {groupBy: vm.groupedBy}})
  }
}
