import Vue from 'vue'
import { cloneDeep } from 'lodash'

export default {
  questionAdded: questionAdded,
  questionRemoved: questionRemoved,
  sectionGroupAdded: sectionGroupAdded,
  sectionGroupRemoved: sectionGroupRemoved,
  moduleAdded: moduleAdded,
  moduleRemoved: moduleRemoved,
  questionRequiredChanged: questionRequiredChanged,
  tableAdded: tableAdded,
  tableRemoved: tableRemoved,
  tableConfigure: tableConfigure,
  userDefinedChanged: userDefinedChanged,
  userDefinedRemoved: questionRemoved,
  handleSpecialsChanged: handleSpecialsChanged
}

function questionAdded( questionnaireModel, data) {
  const modelModule = assureModuleInModel(questionnaireModel, data.moduleId, data.moduleTemplate),
    modelSection = assureSectionGroupInModuleModel(modelModule, data.sectionId, data.sectionTemplate)

  insertIntoPosition(modelSection, createQuestionModelFromTemplate(data.questionTemplate))
}

function questionRemoved( questionnaireModel, data ) {
  const moduleIndex = findCellIndex(questionnaireModel, data.moduleId),
    module = moduleIndex === -1 ? undefined : questionnaireModel.cells[moduleIndex],
    sectionIndex = findCellIndex(module, data.sectionId),
    section = sectionIndex === -1 ? undefined : module.cells[sectionIndex],
    questionIndex = findCellIndex(section, data.questionId)

  questionIndex !== -1 && !data.questionTemplate.locked && section.cells.splice(questionIndex, 1)
  !section.cells.length && module.cells.splice(sectionIndex, 1)
  !module.cells.length && questionnaireModel.cells.splice(moduleIndex, 1)
}

function sectionGroupAdded( questionnaireModel, data ) {
  const modelModule = assureModuleInModel(questionnaireModel, data.moduleId, data.moduleTemplate),
    modelSection = findCell(modelModule, data.sectionId)

  modelSection ? fillGroup(data.sectionTemplate, modelSection) : addGroup(data.sectionTemplate, modelModule)
}

function sectionGroupRemoved( questionnaireModel, data ) {
  const modelModuleIndex = findCellIndex(questionnaireModel, data.moduleId),
    modelModule = questionnaireModel.cells[modelModuleIndex],
    modelSectionIndex = findCellIndex(modelModule, data.sectionId),
    modelSection = modelModule.cells[modelSectionIndex]

  removeAllNonLockedQuestionsFromGroup( data.sectionTemplate, modelSection )

  !modelSection.cells.length && modelModule.cells.splice(modelSectionIndex, 1)
  !modelModule.cells.length && questionnaireModel.cells.splice(modelModuleIndex, 1)
}

function moduleAdded( questionnaireModel, questionnaireConfig, data ) {
  const modelModule = assureModuleInModel(questionnaireModel, data.moduleId, data.moduleTemplate)

  data.moduleTemplate.cells.forEach( ( templateSection, index ) => {
    if(!templateSection.locked) {
      const modelSection = modelModule.cells.length > index ? modelModule.cells[index] : null

      if (!modelSection || modelSection.id !== templateSection.id) {
        addSection(questionnaireConfig, templateSection, modelModule)
      } else if (templateSection.type === 'GROUP') {
        fillGroup(templateSection, modelSection)
      }
    }
  })
}

function moduleRemoved( questionnaireModel, data ) {
  const modelModuleIndex = findCellIndex(questionnaireModel, data.moduleId),
    modelModule = questionnaireModel.cells[modelModuleIndex]

  let modelSectionIndex = 0,
    modelSection = modelModule.cells[modelSectionIndex],
    templateSectionIndex = 0,
    templateSection = data.moduleTemplate.cells[templateSectionIndex++]

  while(modelSection && templateSection) {
    if(modelSection.id === templateSection.id){
      if( templateSection.locked ){
        modelSection = modelModule.cells[++modelSectionIndex]
      } else if (templateSection.type === 'GROUP') {
        removeAllNonLockedQuestionsFromGroup(templateSection, modelSection)

        if(modelSection.cells.length) {
          modelSection = modelModule.cells[++modelSectionIndex]
        } else {
          modelModule.cells.splice(modelSectionIndex, 1)
          modelSection = modelModule.cells[modelSectionIndex]
        }
      } else {
        modelModule.cells.splice(modelSectionIndex, 1)
        modelSection = modelModule.cells[modelSectionIndex]
      }
    }

    templateSection = data.moduleTemplate.cells[templateSectionIndex++]
  }

  !modelModule.cells.length && questionnaireModel.cells.splice(modelModuleIndex, 1)
}

function questionRequiredChanged( questionnaireModel, data ){
  const module = findCell(questionnaireModel, data.moduleId),
    section = findCell(module, data.sectionId),
    question = findCell(section, data.questionId)

  if( question ){
    data.value ? Vue.set( question, 'req', true ) : Vue.delete(question, 'req')
  }
}

function tableAdded ( questionnaireModel, questionnaireConfig, data ){
  const modelModule = assureModuleInModel(questionnaireModel, data.moduleId, data.moduleTemplate)
  addTable(questionnaireConfig, data.sectionTemplate, modelModule)
}

function tableRemoved ( questionnaireModel, data ){
  const moduleIndex = findCellIndex(questionnaireModel, data.moduleId),
    module = moduleIndex === -1 ? undefined : questionnaireModel.cells[moduleIndex],
    sectionIndex = findCellIndex(module, data.sectionId)

  module.cells.splice(sectionIndex, 1)
  !module.cells.length && questionnaireModel.cells.splice(moduleIndex, 1)
}

function tableConfigure (questionnaireConfig, data){
  configure(questionnaireConfig, data.config.id, data.config.data)
}

function userDefinedChanged( questionnaireModel, data ){
  const modelModule = assureModuleInModel(questionnaireModel, data.moduleId, data.moduleTemplate),
    modelSection = assureSectionGroupInModuleModel(modelModule, data.sectionId, data.sectionTemplate)

  insertIntoPosition(modelSection, data.model)
}

function configure(questionnaireConfig, id, data){
  const configIndex = questionnaireConfig.findIndex( c => c.id === id )
  configIndex !== -1 && questionnaireConfig.splice(configIndex, 1, {
    id: id,
    data: data
  })
}

function addSection(questionnaireConfig, templateSection, modelModule){
  templateSection.type === 'GROUP' ? addGroup(templateSection, modelModule) : addTable(questionnaireConfig, templateSection, modelModule)
}

function addGroup(templateSection, modelModule){
  const modelSection = createSectionGroupModelFromTemplate(templateSection)
  templateSection.cells.forEach(cell => modelSection.cells.push(createQuestionModelFromTemplate(cell)))
  insertIntoPosition(modelModule, modelSection)
}

function addTable(questionnaireConfig, templateSection, modelModule){
  const modelSection = createSectionTableModelFromTemplate(templateSection)
  insertIntoPosition(modelModule, modelSection)
  configure(questionnaireConfig, templateSection.id, cloneDeep(templateSection.defaultFilters))
}

function fillGroup(templateSection, modelSection){
  for(let i = 0, l = templateSection.cells.length; i<l; i++){
    const templateQuestion = templateSection.cells[i],
      modelQuestion = modelSection.cells.length > i ? modelSection.cells[i] : {}

    if(modelQuestion.id !== templateQuestion.id) {
      insertOnIndex(modelSection, createQuestionModelFromTemplate(templateQuestion), i)
    }
  }
}

function removeAllNonLockedQuestionsFromGroup(templateSection, modelSection){
  let modelQuestionIndex = 0,
    modelQuestion = modelSection.cells[modelQuestionIndex],
    templateQuestionIndex = 0,
    templateQuestion = templateSection.cells[templateQuestionIndex++]

  while (modelQuestion && templateQuestion) {
    if (templateQuestion.id === modelQuestion.id) {
      if (templateQuestion.locked) {
        modelQuestion = modelSection.cells[++modelQuestionIndex]
      } else {
        modelSection.cells.splice(modelQuestionIndex, 1)
        modelQuestion = modelSection.cells[modelQuestionIndex]
      }
    }
    templateQuestion = templateSection.cells[templateQuestionIndex++]
  }
}

function assureModuleInModel(model, moduleId, moduleTemplate){
  let moduleModel = findCell(model, moduleId)
  if(!moduleModel) {
    moduleModel = createModuleModelFromTemplate(moduleTemplate)
    insertIntoPosition(model, moduleModel)
  }
  return moduleModel
}

function assureSectionGroupInModuleModel(moduleModel, sectionId, sectionTemplate){
  let sectionModel = findCell(moduleModel, sectionId)
  if(!sectionModel) {
    sectionModel = createSectionGroupModelFromTemplate(sectionTemplate)
    insertIntoPosition(moduleModel, sectionModel)
  }
  return sectionModel
}

function createQuestionModelFromTemplate( template ) {
  return {
    id: template.id,
    ord: template.ord
  }
}

function createSectionGroupModelFromTemplate( template ) {
  return createContainerModelFromTemplate(template)
}

function createSectionTableModelFromTemplate( template ) {
  return {
    id: template.id,
    ord: template.ord
  }
}

function createModuleModelFromTemplate( template ) {
  return createContainerModelFromTemplate(template)
}

function createContainerModelFromTemplate( template ) {
  return {
    id: template.id,
    ord: template.ord,
    cells: []
  }
}

function findCell(object, cellId){
  return object && object.cells.find( cell => cell.id === cellId )
}

function findCellIndex(object, cellId){
  return object ? object.cells.findIndex( cell => cell.id === cellId ) : -1
}

function insertIntoPosition(model, cell) {
  const cells = model.cells, index = cells.findIndex( c => c.ord >= cell.ord )
  index === -1 ? cells.push(cell) : cells.splice(index, cells[index].ord === cell.ord ? 1 : 0, cell)
}

function insertOnIndex(model, cell, index) {
  index === -1 ? model.cells.push(cell) : model.cells.splice(index, 0, cell)
}

function handleSpecialsChanged(questionnaire, data) {
  data.type === 'hotelRFpRateGridConfigure' && configure(questionnaire.config, data.config.id, data.config.data)
}
