import HTML from './view-nam-standard-cover-letter.html';
import get from 'lodash/get';

export default {
  template: HTML,
  bindings: {
    rfp: '<'
  },
  controller: RfpNamCoverLetterViewController,
}

function RfpNamCoverLetterViewController(){
  const 
    vm = this;

  Object.assign(this, {
    $onChanges ( changes ) {
      vm.rfp = get(changes, 'rfp.currentValue');
      vm.rfpId = get(vm.rfp, 'id');
      vm.isChainEnabled = true;
    }
  });
}
