/**
 * Created by DejanK on 9/10/2016.
 */
import angular from 'angular';
import rbEditLetter from './editor/rb-edit-letter.cmp';
import rbLetter from './rb-letter.cmp';

const module = angular.module('rb.core.letter', [])
  .component('rbLetter', rbLetter)
  .component('rbEditLetter', rbEditLetter)

export default module
export const name = module.name
