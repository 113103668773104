/**
 * Created by DejanK on 1/18/2017
 *
 * Displays content in floating window
 */

'use strict';

require("./rb-float.scss");

let lodash = require("lodash");

module.exports = {
  bindings: {
    position: '@',
    offset: '@',
    show: '<'
  },
  transclude: true,
  template: '<div class="window-overlay">'+
  '<div class="window-container" ng-transclude></div>' +
  '</div>',
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$timeout'];
function Ctrl($element, $timeout){
  let vm = this, overlayElement, windowContainerElement, parentElement;

  this.$onInit = ()=>{
    overlayElement = angular.element($element.children()[0]);
    windowContainerElement = angular.element(overlayElement.children()[0]);
    parentElement = angular.element($element.parent());
    updateWindowPosition();
  };

  function updateWindowPosition(){
    switch(vm.position){
      case 'top-right':
        setWindowToTopRightOfParent();
        break;
      case 'top-left':
        setWindowToTopLeftOfParent();
        break;
    }
    adjustPositionToFitWindow();
  }

  function setWindowToTopRightOfParent(){
    let parentBounds = parentElement[0].getBoundingClientRect(),
      offset = getParsedOffset();

    windowContainerElement.css('top' , `${parentBounds.top + offset[1]}px`);
    windowContainerElement.css('left' , null);
    windowContainerElement.css('right' , `${window.innerWidth - parentBounds.right + offset[0]}px`);
    windowContainerElement.css('bottom' , null);
  }

  function setWindowToTopLeftOfParent(){
    let parentBounds = parentElement[0].getBoundingClientRect(),
      offset = getParsedOffset();
    windowContainerElement.css('top' , `${parentBounds.top + offset[1]}px`);
    windowContainerElement.css('left' , `${parentBounds.left + offset[0]}px`);
    windowContainerElement.css('right' , null);
    windowContainerElement.css('bottom' , null);
  }

  function adjustPositionToFitWindow(){
    $timeout(()=>{
      let floatRect = windowContainerElement[0].getBoundingClientRect();
      if(floatRect.top < 0){
        windowContainerElement.css('top', '0px');
        windowContainerElement.css('bottom', null);
      } else if(floatRect.bottom > window.innerHeight){
        windowContainerElement.css('top', null);
        windowContainerElement.css('bottom', '0px');
      }

      if(floatRect.left < 0){
        windowContainerElement.css('left', '0px');
        windowContainerElement.css('right', null);
      } else if(floatRect.right > window.innerWidth){
        windowContainerElement.css('left', null);
        windowContainerElement.css('right', '0px');
      }
    });
  }

  function getParsedOffset(){
    if(!vm.offset) return [0,0];
    let offset = vm.offset.split(',');
    offset[0] = parseInt(offset[0]);
    offset[1] = parseInt(offset[1]);
    return offset;
  }
}
