/**
 * Created by DejanK on 11/4/2016.
 */

'use strict';

const _ = require('lodash')

module.exports = {
  template: templateFn,
  bindings: {
    model: '=',
    rbReadonly: '<'
  },
  controller: Ctrl
};

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, RbInputTemplate){
  let options = angular.extend(RbInputTemplate.readDefaultAttributes($attrs), {
    repeat: 'industry in $ctrl.industries track by industry.id',
    value: '::industry.id',
    option: '{{::industry.label}}'
  });

  return RbInputTemplate.selectTemplate(options);
}

Ctrl.$inject = ['DataServer'];
function Ctrl(DataServer){
  let vm = this;

  DataServer.getIndustries()
    .then((industries)=>{
      const i = _.clone(industries)
      i.unshift({id: undefined, label:'-'})
      vm.industries = i
    });
}
