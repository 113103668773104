<template>
  <div :class="{ 'rbvDropDownContainer' : isOpen }">
    <div class="rbvDropDownToggler">
      <slot name="toggler" />
    </div>
    <div
      v-if="isOpen"
      class="rbvDropDown"
      :class="alignClass"
      @click="stop($event)"
    >
      <slot name="dropdown" />
    </div>
    <div
      v-if="isOpen && backdrop"
      class="rbvDropDownBackdrop"
      :class="backdropStyle"
      @click="close"
    >
      &nbsp;
    </div>
  </div>
</template>

<script>
  import {stopEvent} from 'root/shared/tools/view-utils';

  export default {
    name: 'RbvDropdown',
    props:  {
      isOpen: {
        type: Boolean,
        default: false
      },
      backdrop: {
        type: Boolean,
        default: true
      },
      backdropStyle: {
        type: String,
        default: ''
      },
      align: {
        type: String,
        default: 'left'
      }
    },
    computed: {
      alignClass () { return this.align === 'right' ? 'rbvDropDownRightAlign' : '' }
    },
    methods: {
      stop (event) { stopEvent(event)},
      close () { this.$emit('close') }
    }
  }
</script>

<style lang="stylus">
  .rbvDropDownContainer {
    position: relative;

    .rbvDropDownToggler {
      position: relative;
      z-index 5001;
    }

    .rbvDropDown {
      position: absolute;
      z-index 5002;
      min-width 100%;
    }

    .rbvDropDownRightAlign{
      right: 0
    }

    .rbvDropDownBackdrop {
      position fixed
      top 0
      left 0
      width 100vw
      height 100vh
      background rgba(0,0,0,.5)
      z-index 5000

      &.transparent {
        background transparent
      }
    }
  }

</style>
