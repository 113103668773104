<template>
  <questionnaire-form-section-group
    v-if="template.type === 'GROUP'"

    :globals="globals"
    :model="model"
    :response="response"
    :template="template"
    @change="$rbEmit"
    @mounted="onSectionMounted"
  />

  <questionnaire-form-section-table
    v-else-if="template.type === 'TABLE'"

    :config = "config"
    :globals = "globals"
    :model="model"
    :response="response"
    :template="template"
    @change="$rbEmit"
    @stateChange="$rbEmit"

    @mounted="onSectionMounted"
  />


  <questionnaire-form-hotel-rfp-rate-grid
    v-else-if="template.type === 'HOTEL_RFP_RATE_GRID'"

    :config = "config"
    :globals = "globals"
    :model="model"
    :response="response"
    :template="template"
    @change="$rbEmit"
    @stateChange="$rbEmit"

    @mounted="onSectionMounted"
  />
</template>

<script>
  import RbEventMixin from '../../_mixins/RbEventsMixin.vue'

  import QuestionnaireFormSectionGroup from './QuestionnaireFormSectionGroup.vue'
  import QuestionnaireFormSectionTable from './QuestionnaireFormSectionTable.vue'
  import QuestionnaireFormHotelRfpRateGrid from './HotelRfpQuestionnaireFormSectionRateGrid.vue'

  export default {
    name: 'rb-questionnaire-form-section',

    mixins: [ RbEventMixin ],

    components: {
      QuestionnaireFormSectionGroup,
      QuestionnaireFormSectionTable,
      QuestionnaireFormHotelRfpRateGrid
    },

    props: [ 'config', 'globals', 'model', 'response', 'template' ],

    methods: {
      onSectionMounted () {
        this.$emit('mounted') }
    }
  }
</script>
