/**
 * Created by DejanK on 12/22/2016.
 */
import { noop } from 'lodash'
import HTML from './ask-to-switch-account.html'
const NAME = 'askToSwitchUserAccount'

export { NAME, HTML, AskToSwitchAccountController as Controller }

AskToSwitchAccountController.$inject = ['NotificationUtils', 'CurrentUser', 'Account', 'rbDialog']
function AskToSwitchAccountController(notificationUtils, currentUser, account, dialog){
  const vm = this;

  vm.accountName = '';

  vm.allow = allow;
  vm.cancel = dialog.cancel;

  $onInit();

  function $onInit(){
    vm.accountName = account.name;
  }

  function allow(){
    notificationUtils.onSave(() => { return currentUser.switchToAccount(account.id) })
      .then(currentUser.refresh)
      .then(dialog.hide, noop)
  }
}
