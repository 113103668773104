<template>
  <div class="questionnaire">
    <questionnaire-edit-module
      v-for="moduleTemplate in modelCells" :key="moduleTemplate.id"
      :config="questionnaire.config"
      :model="getModel(moduleTemplate.id)"
      :preview="preview"
      :template="moduleTemplate"
      @questionAdded="$rbEmit"
      @questionRemoved="$rbEmit"
      @sectionAdded="$rbEmit"
      @sectionRemoved="$rbEmit"
      @moduleAdded="$rbEmit"
      @moduleRemoved="$rbEmit"
      @questionRequiredChanged="$rbEmit"
      @tableAdded="$rbEmit"
      @tableRemoved="$rbEmit"
      @tableConfigure="$rbEmit"
      @userDefinedChanged="$rbEmit"
      @userDefinedRemoved="$rbEmit"
      @specialsChanged="$rbEmit"
    />
  </div>
</template>

<script>
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireEditModule from './QuestionnaireEditModule.vue'

  export default {
    name: 'rb-questionnaire-edit',

    mixins: [ RbEventsMixin, QuestionnaireMixin ],

    components: { QuestionnaireEditModule },

    props: [ 'preview', 'questionnaire' ],

    computed: {
      modelCells () {
        return this.questionnaire.template.cells
      }
    }
  }
</script>
