<template>
  <div>
    <rb-virtual-list
      class="rfp-manager-table flex-100 layout-column"
      :items="rfps"
    >
      <template slot="before">
        <div
          class="flex-hold layout-row-flex bmt-header"
        >
          <div class="cell checkbox">
            <input
              id="selectAll"
              type="checkbox"
              name="selectAll"
              :value="true"
              :checked="allSelected"
              @input="$emit('selectAll')"
            >
            <label for="selectAll" />
          </div>

          <div
            v-for="column in tableColumns"
            :key="column.id"
            class="cell"
            :class="{[column.class]: true}"
          >
            <button
              class="icon transparent"
              @click="column.$sortable && sortTableBy(column)"
            >
              <span class="label">
                {{ column.label }}
              </span>
              <span
                v-if="column.$sortable"
                class="sort"
                :class="{asc: isSortingBy(column, 'asc'), desc: isSortingBy(column, 'desc')}"
              >
                <span class="asc">
                  <i class="material-icons">
                    arrow_drop_up
                  </i>
                </span>
                <span class="desc">
                  <i class="material-icons">
                    arrow_drop_down
                  </i>
                </span>
              </span>
            </button>
            <div
              class="rbTooltip simple"
              tooltip="placement:bottom-bottom"
            >
              {{ column.toolTip }}
            </div>
          </div>
        </div>
        <div
          v-if="rfps.length === 0"
          class="flex-100 layout-column flex-center"
        >
          No RFPs match this View.
        </div>
      </template>
      <template slot-scope="item">
        <rfp-manager-table-row
          :rfp="item.item"
          :columns="tableColumns"
          :selected="selected(item)"
          :accounts="accounts"
          :group-by="groupBy"
          @actionFired="callback"
          @select="$emit('select', item)"
          @createRFP="createRfp(item.item)"
          @assignTo="assignTo"
          @updateLaunchDate="updateLaunchDate(item.item, $event)"
        />
      </template>
    </rb-virtual-list>
  </div>
</template>

<script>
  import moment from 'moment';
  import rbVirtualList from '../../_core/RbVirtualList.vue';
  import rfpManagerTableRow from './table-row.vue';

  import rfpManagerGroupFilterAndSortService from '../services/rfp-manager-table.service';
  import {getStatusValue} from 'vRoot/rfp-manager/services/rfp-manager-table-grouper-service';

  export default {
    name: 'RfpManagerTable',
    components: { rbVirtualList, rfpManagerTableRow },
    props: {
      rfps: {
        type: Array,
        required: true
      },
      allRfps: {
        type: Array,
        required: true
      },
      selectedRfps: {
        type: Array,
        required: true
      },
      allSelected: {
        type: Boolean,
        required: true
      },
      accounts: {
        type: Array,
        required: true
      },
      sorter: {
        type: Object,
        required: true
      },
      groupBy: {
        type: Object,
        required: true
      },
      isCompany: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        tableColumns: [
          {
            id: 'companyName',
            label: 'Company Name',
            key: 'rfp.specifications.buyer.company.name',
            class: 'l',
            $sortable: true,
            toolTip: 'Company Name',
            included: !this.isCompany,
          },

          {
            id: 'rfpName',
            label: 'RFP Name',
            key: 'rfp.specifications.name',
            class: 'l',
            $sortable: true,
            toolTip: 'RFP Name',
            included: true,
          },
          {
            id: 'rfpType',
            label: 'RFP Type',
            key: 'rfp.specifications.type',
            class: 'm',
            $sortable: true,
            toolTip: 'RFP Type',
            included: true,
          },
          {
            id: 'assignedTo',
            label: 'Assigned To',
            key: 'rfp.assignee.fullName',
            class: 'm',
            $sortable: true,
            toolTip: 'User in charge of RFP',
            included: !this.isCompany,
          },
          {
            id: 'rfpLaunchDate',
            label: 'Launch Date',
            key: ({rfp}) => rfp.specifications.launchDate && moment(rfp.specifications.launchDate).format('YYYY-MM-DD'),
            class: 's',
            $sortable: true,
            toolTip: 'RFP launch date',
            included: true,
          },
          {
            id: 'rfpDueDate',
            label: 'Due Date',
            key: 'rfp.specifications.dueDate',
            class: 's',
            $sortable: true,
            toolTip: 'RFP due date',
            included: true,
          },
          {
            id: 'rfpStatus',
            label: 'RFP Status',
            key: ({rfp}) => getStatusValue(rfp),
            class: 's',
            $sortable: true,
            toolTip: 'RFP Status',
            included: true,
          },
          {
            id: 'bidsSent',
            label: 'Bids Sent',
            key: 'rfp.rfpBidsCount.sent',
            class: 's',
            $sortable: true,
            toolTip: 'Number of Bids Sent',
            included: true,
          },
          {
            id: 'bidsRespondedTo',
            label: 'Responded',
            key: 'rfp.rfpBidsCount.responded',
            class: 's',
            $sortable: true,
            toolTip: 'Number of bids responded',
            included: true,
          },
          {
            id: 'bidsInNegotiation',
            label: 'In NEG',
            key: 'rfp.rfpBidsCount.negotiating',
            class: 's',
            $sortable: true,
            toolTip: 'Number of bids in negotiation',
            included: true,
          },
          {
            id: 'bidsInFinalAgreement',
            label: 'FA Sent',
            key: 'rfp.rfpBidsCount.finalAgreements',
            class: 's',
            $sortable: true,
            toolTip: 'Bids in final Agreement',
            included: true,
          },
          {
            id: 'obrp',
            label: 'OBRP',
            key: 'rfp.rfpBidsCount.obrp',
            class: 'xs',
            $sortable: true,
            toolTip: 'Overall bid response ratio',
            included: true,
          },
        ].filter(({included}) => included),
      }
    },
    methods: {
      sortTableBy(by) {
        this.$emit('sort', by)
      },
      isSortingBy(column, order) {
        return this.sorter.by && this.sorter.by.id === column.id && this.sorter.direction === order;
      },
      selected({item}) {
        return rfpManagerGroupFilterAndSortService.rfpIsSelected(item, this.allRfps, this.selectedRfps, this.groupBy);
      },
      callback(data){
        this.$emit('actionFired', data)
      },
      createRfp({account}) {
        this.$emit('createRFP', account.id);
      },
      assignTo(accountId) {
        this.$emit('assignTo', accountId);
      },
      updateLaunchDate({rfp}, time) {
        this.$emit('updateLaunchDate', {time, id: rfp.id});
      },
    }
  }
</script>

<style lang="stylus">
  .rfp-manager-table{
    height: calc(100vh - 150px);
    overflow: auto;
    background #fff;

    .bmt-header{
      position sticky;
      z-index 99;
      top: 0;

      .cell{
        &.checkbox{
          box-sizing border-box;
        }

        padding 0;
        padding-bottom 10px;
        overflow unset;

        .rbTooltip.simple{
          white-space normal;
        }
      }

    }
    .cell{
      position relative;
      border-bottom none;

      &:last-child{
        padding-right 10px;
      }
    }

    div.checkbox input[type="checkbox"]:checked + label {
      color: #78909c;
    }
  }
</style>
