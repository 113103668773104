/**
 * Created by DejanK on 8/02/2017.
 */
'use strict';

let lodash = require('lodash');

module.exports = {
  template: require('./rb-negotiation-view-amenity-cell.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    amenityId: '@'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope', 'NegotiationsHtmlFactory', '$rootScope'];
function Ctrl($element, $scope, NegotiationsHtmlFactory, $rootScope) {
  let vm = this;

  vm.$onInit = $onInit;
  vm.$onChanges = $onChanges;

  vm.onMouseEnter = onMouseEnter;
  vm.onMouseLeave = onMouseLeave;


  function $onInit(){
    $scope.$on('RB_NEGOTIATIONS_MOUSE_ENTER', onMouseEnterScopeEvent);
    $scope.$on('RB_NEGOTIATIONS_MOUSE_LEAVE', onMouseLeaveScopeEvent);
  }

  function $onChanges(changes){
    changes.negotiation && changes.negotiation.currentValue && vm.amenityId && setupVm(changes.negotiation.currentValue, vm.amenityId);

    function setupVm(negotiation, amenityId){
      vm.negotiation = negotiation;
      vm.amenity = negotiation.getAmenity(amenityId);
      vm.amenityValue = vm.amenity.getFormattedValue();
      vm.amenityChangeClass = NegotiationsHtmlFactory.buildChangeNotificationClass(vm.amenity.change);
      vm.isAmenityIncluded = vm.amenity.included;
    }
  }

  function onMouseEnter(){
    $rootScope.$broadcast('RB_NEGOTIATIONS_MOUSE_ENTER', {cellId: vm.amenityId})
  }

  function onMouseLeave(){
    $rootScope.$broadcast('RB_NEGOTIATIONS_MOUSE_LEAVE', {cellId: vm.amenityId})
  }

  function onMouseEnterScopeEvent(event, data){
    if(data.cellId === vm.amenityId) $element.addClass('highlight');
  }

  function onMouseLeaveScopeEvent(event, data){
    if(data.cellId === vm.amenityId) $element.removeClass('highlight');
  }
}
