/**
 * Created by DejanK on 6/29/2017.
 *
 */

'use strict';

//let lodash = require('lodash');

module.exports = NegotiationsPanelMessagingService;

NegotiationsPanelMessagingService.$inject = ['$rootScope'];
function NegotiationsPanelMessagingService($rootScope){
  let $service = this;

  $service.PANEL_OPENED = 'NEGOTIATIONS_PANEL_OPENED_EVENT';
  $service.PANEL_CLOSED = 'NEGOTIATIONS_PANEL_CLOSED_EVENT';
  $service.BID_CHANGED = 'NEGOTIATIONS_PANEL_BID_CHANGED_EVENT';

  $service.openNegotiationsPanel = function openNegotiationsPanel(bidId){
    $rootScope.$broadcast($service.PANEL_OPENED, {bidId: bidId});
  };

  $service.changeBid = function changeBid(bidId){
    $rootScope.$broadcast($service.BID_CHANGED, {bidId: bidId});
  };

  $service.closeNegotiationsPanel = function closeNegotiationsPanel(){
    $rootScope.$broadcast($service.PANEL_CLOSED);
  }
}
