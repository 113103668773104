<template>
  <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">
        <div >Edit User Details</div>
        <div :class="$style.subHeader">Please fill out the form below, we will send the a link to your user.</div>
    </span>
    <div slot="body" class="vModalDefaultBody hotel-directory">
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.firstName}" :forId="'firstName'" :error="$showError('firstName')">
                <span slot="label">First Name<i>*</i></span>
                <input id="firstName" type="text" name="firstName" class="rbInput" autofocus
                    v-model="data.user.firstName"
                    maxlength="100"
                    v-validate="'required|max:100'"
                    data-vv-as="First Name"/>
            </rb-input>
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.lastName}" :forId="'lastName'" :error="$showError('lastName')">
                <span slot="label">Last Name<i>*</i></span>
                <input id="lastName" type="text" name="lastName" class="rbInput"
                    v-model="data.user.lastName"
                    maxlength="100"
                    v-validate="'required|max:100'"
                    data-vv-as="Last name"/>
            </rb-input>
        </div>
        <rb-input :class="{rbInputEmpty: !data.user.email}" :forId="'email'" :error="$showError('email')">
            <span slot="label">Email Address<i>*</i></span>
            <input id="email" type="text" name="email" class="rbInput"
                v-model="data.user.email"
                v-validate="'required|email'"
                data-vv-as="Email Adress"/>
        </rb-input>
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.phone}" :forId="'phone'" :error="$showError('phone')">
                <span slot="label">Phone Number<i>*</i></span>
                <input id="phone" type="text" name="phone" class="rbInput" autofocus
                    v-model="data.user.phone"
                    maxlength="100"
                    v-validate="'max:100'"
                    data-vv-as="Phone number"/>
            </rb-input>
        </div>
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.jobTitle}" :forId="'jobTitle'" :error="$showError('jobTitle')">
                <span slot="label">Job Title</span>
                <input id="jobTitle" type="text" name="jobTitle" class="rbInput" autofocus
                    v-model="data.user.jobTitle"
                    maxlength="100"
                    v-validate="'max:100'"
                    data-vv-as="Job Title"/>
            </rb-input>
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.department}" :forId="'department'" :error="$showError('department')">
                <span slot="label">Deparment</span>
                <input id="department" type="text" name="department" class="rbInput"
                    v-model="data.user.department"
                    maxlength="100"
                    v-validate="'max:100'"
                    data-vv-as="Department"/>
            </rb-input>
        </div>
    </div>

    <div slot="footer" class="vModalDefaultFooter">
        <button class="rbButton" type="button" @click="cancel()">Cancel</button>
        <button class="rbButton mainButton">
            <i class="material-icons">save</i>
            Apply Changes
        </button>
    </div>

  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import RbSelect from 'vRoot/_core/RbSelect.vue';
  import { cloneDeep } from 'lodash';

  export default {
    name: 'EditUploadUserDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { RbInput, ModalForm, RbSelect },
    props: ['user'],
    data() {
       return {
            data : {
                user: cloneDeep(this.user),
            }
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        min-width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .group{
        display: flex;
        justify-content space-between;
    }

    .groupItem{
        flex: 1;

        &+&{
            margin-left 5px;
        }
    }

    .another{
        float left;
    }
</style>
