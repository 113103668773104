<template>
    <li @click="openSubModule" :class="{[getClosedStyle('menuItem')]: !active, [getClosedStyle('activeMenuItem')]: active}">
        <div :class="$style.menuItemContent">
            <i class="material-icons" :class="active? getClosedStyle('activeIcon'): {}">{{icon}}</i>
            <div v-if="open" :class="$style.menuItemContentText">{{text}}</div>
            <i class="material-icons" v-if="open && $slots.default">arrow_drop_down</i>
        </div>
        <div v-if="active && open && $slots.default" :class="$style.drop">
            <slot/>
        </div>
    </li>
</template>

<script>
import hotelDirectoryService from '../../hotel-directory-service';

export default {
    name: 'HotelDirectoryEditorNavigationItem',
    props: ['active', 'id', 'open', 'icon', 'text'],
    methods: {
        getClosedStyle(style){
            style = this.open? style: 'closed'+style.charAt(0).toUpperCase() + style.substr(1);
            return this.$style[style];
        },
        openSubModule(){
            hotelDirectoryService.openSubModule(this.id);
        }
    }
}
</script>

<style lang="stylus" module>
    .menuItem{
        width 100%;
        color #B0BEC5;
    }

    .closedMenuItem{
        composes menuItem;
        height: 48px;
    }

    .activeMenuItem .menuItemContent{
        background-color #00B8AB;
        color: #fff !important;
    }

    .activeMenuItem{
        background-color #263238;
    }

    .closedActiveMenuItem .menuItemContent{
        background-color #00B8AB;
        color: #fff !important;
    }

    .menuItemContent{
        display flex;
        justify-content space-between;
        align-items center;
        padding: 12px 20px 12px 15px;
        box-sizing border-box;
        cursor pointer;
    }

    .menuItemContent:hover{
      background: #263238;
    }


    .menuItemContentText{
        flex 1;
        padding-left 15px;
        font-size: 13px;
        line-height: 25px;
    }

    .drop{
      padding: 12px 15px;
    }
</style>


