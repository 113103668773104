export default rbImgSrc

function rbImgSrc() {
    return {
      scope: { rbImgSrc: '<', defaultSrc: '<' },
      link: function(scope, element, attrs) {

        scope.$watch('rbImgSrc', val => { attrs.$set('src', val ? val : scope.defaultSrc) })

        element.bind('error', () => {
          if (attrs.src !== scope.defaultSrc) {
            attrs.$set('src', scope.defaultSrc);
          }
        })
      }
    }
}
