<template>
  <div class="rbv-progress-bar">
      <div class="percent" :style="{width: percentage+'%'}"></div>
  </div>
</template>

<script>
export default {
    name: 'rbv-progress-bar',
    props: {
        max: {
            type: Number,
            required: true,
            default: 1.0
        },
        value: {
            type: Number, 
            required: true,
            default: 0.0
        }
    },
    computed: {
        percentage(){
            return this.value<=this.max?(this.value/this.max)*100:100;
        }
    }
}
</script>

<style lang="stylus">
    .rbv-progress-bar{
        margin: 10px 0;
        padding: 0;
        font-size: 14px;
        clear: both;
        -moz-transition: opacity 1s linear;
        -o-transition: opacity 1s linear;
        -webkit-transition: opacity 1s linear;

        & .percent {
            background-color: #2eb8aa;
            height: 2px;
            width: 0;
        }
    }
</style>
