import HTML from './standard-preview.cmp.html';

export default {
  template: HTML,
  bindings: {
    bid: '<'
  },
  controller: HotelRfpBidStandardPreviewController,
}

HotelRfpBidStandardPreviewController.$inject = [];
function HotelRfpBidStandardPreviewController(){
}
