/**
 * Created by DejanK on 4/4/2017.
 */
import { get, find, merge, remove, set } from 'lodash'

export default TravelDestinationManagerService

TravelDestinationManagerService.$inject = ['RfpHotelRfpDestinationsRepository', '$timeout', '$rootScope'];
function TravelDestinationManagerService(RfpHotelRfpDestinationsRepository, $timeout, $scope){
  const $service = this
  let suppliersHttpResponse, focusedSupplierIdTimeoutPromise

  $service.getRfpTravelDestination = getRfpTravelDestination;
  $service.getSuppliers = getSuppliers;
  $service.getSupplierByHotelId = getSupplierByHotelId;
  $service.addToTheBidManager = addToTheBidManager;
  $service.removeFromBidManager = removeFromTheBidManager;
  $service.setFocusedSupplierId = setFocusedSupplierId;
  $service.scrollListTo = scrollListTo;
  $service.focusedSupplierId = null;
  $service.scrollListToId = null;

  function getRfpTravelDestination(rfpId, destinationId){
    return RfpHotelRfpDestinationsRepository.getRfpTravelDestination(rfpId, destinationId)
      .then(prepareFilter);

    function prepareFilter(httpResponse){
      let filter = getDefaultFilter(get(httpResponse, 'data.location.coordinates'));
      filter = merge(filter, get(httpResponse, 'data.filter' ));
      set(httpResponse, 'data.filter', filter);
      return httpResponse;
    }

    function getDefaultFilter(coordinates){
      return {
        coordinates: coordinates,
        maxDistance: {
          value: 3,
          unit: 'MI'
        },
        chains: []
      }
    }
  }

  function getSuppliers(rfpId, destinationId, filter){
    return RfpHotelRfpDestinationsRepository.geoSearchHotels(rfpId, destinationId, filter)
      .then(setSuppliers);

    function setSuppliers(httpResponse){
      suppliersHttpResponse = httpResponse;
      return suppliersHttpResponse;
    }
  }

  function getSupplierByHotelId(hotelId){
    return find(suppliersHttpResponse.data, {id: hotelId});
  }

  function addToTheBidManager(rfpId, destinationId, supplier){
    supplier.bidState = 'WORKING';
    const hotelId = supplier.id;

    return RfpHotelRfpDestinationsRepository
      .addToBidManager(rfpId, {travelDestinationId: destinationId, hotelId: hotelId})
      .then(updateSupplier);

    function updateSupplier(httpResponse){
      supplier.bidId = httpResponse.data.id;
      supplier.bidState = 'ADDED';
      return httpResponse;
    }
  }

  function removeFromTheBidManager(rfpId, destinationId, supplier){
    supplier.bidState = 'WORKING';
    return RfpHotelRfpDestinationsRepository
      .removeFromBidManager(rfpId, supplier.bidId)
      // todo: handle errors
      .then(updateSupplier);

    function updateSupplier(httpResponse){
      if(supplier.source === 'BID'){
        remove(suppliersHttpResponse.data, {bidId: httpResponse.data.id});
      } else {
        supplier.bidState = 'NA';
      }
    }
  }

  function setFocusedSupplierId(supplierId){
    $timeout.cancel(focusedSupplierIdTimeoutPromise);
    focusedSupplierIdTimeoutPromise = $scope.$timeout(()=>{
      $service.focusedSupplierId = supplierId;
    }, supplierId ? 200 : 0);
  }

  function scrollListTo(id){
    $service.scrollListToId = id;
  }
}
