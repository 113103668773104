/**
 * Created by DejanK on 4/3/2017.
 */
import angular from 'angular'
import States from './pages/rfp-hotel-rfp.states'
import DestinationsModule from './destinations/rfp-hotel.rfp.destinations.module'
import RfpHotelRfpRepository from './rfp-hotel.rfp.repo'
import rbTravelDestinationViews from './rb-travel-destination-views/rb-travel-destination-views.cmp';
import rbRfpSideMenu from './rb-rfp-side-menu/rb-rfp-side-menu.cmp'
import HotelDirectoriesModule from './directories/rfp-hotel.rfp.directories.module';
import rbHotelRfpViewRfpViewStandardCoverLetter from './pages/cover-letter/view-standard-cover-letter/view-standard-cover-letter.cmp';
import rbHotelRfpViewRfpViewRateQuickCoverLetter from './pages/cover-letter/view-rate-quick-cover-letter/view-rate-quick-cover-letter.cmp';
import rbHotelRfpViewRfpViewNamStandardCoverLetter from './pages/nam-cover-letter/view-standard-cover-letter/view-nam-standard-cover-letter.cmp';
import rbRateQuickRfpNavigation from 'vRoot/rfp-hotel/rfp/rate-quick/view/rb-rate-quick-rfp-navigation.cmp';

export default angular.module('rb.rfpHotel.main.rfp', [
  DestinationsModule.name,
  HotelDirectoriesModule.name
])
  .config(States)
  .service('RfpHotelRfpRepository', RfpHotelRfpRepository)
  .component('rbRfpSideMenu', rbRfpSideMenu)
  .component('rbRateQuickRfpNavigation', rbRateQuickRfpNavigation)
  .component('rbTravelDestinationViews', rbTravelDestinationViews)
  .component('rbHotelRfpViewRfpViewStandardCoverLetter', rbHotelRfpViewRfpViewStandardCoverLetter)
  .component('rbHotelRfpViewRfpViewRateQuickCoverLetter', rbHotelRfpViewRfpViewRateQuickCoverLetter)
  .component('rbHotelRfpViewRfpViewNamStandardCoverLetter',  rbHotelRfpViewRfpViewNamStandardCoverLetter)
;
