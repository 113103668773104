/**
 * Created by DejanK on 12/28/2017.
 *
 * rbMaxDyn
 *
 * Validates that number is not greater of given value (with interpolation)
 */
export default rbMaxDyn

function rbMaxDyn() {
  return {
    require: 'ngModel',
    link: (scope, e, attrs, ngModelCtrl)=>{
      scope.$watch(attrs.rbMaxDyn, newValue => {
        const max = newValue

        if(isNaN(max)){
          ngModelCtrl.$validators.rbMaxDyn = () => true
        } else {
          ngModelCtrl.$validators.rbMaxDyn = (modelValue, viewValue)=>{
            const v = parseFloat(modelValue || viewValue);
            return isNaN(v) || v <= max;
          }
        }
      })
    }
  }
}
