/**
 * Created by DejanK on 2/1/2017.
 */

'use strict';

require('./rb-virtual-repeat.scss');

module.exports = createRbVirtualRepeatModule();

function createRbVirtualRepeatModule(){
  let rbVirtualRepeatModule = angular.module('rb.virtual-repeat', []);

  rbVirtualRepeatModule
    .config(CoreConfigure)
    .directive('rbVirtualRepeatContainer', require('./rb-virtual-repeat-container.dir'))
    .directive('rbVirtualRepeat', require('./rb-virtual-repeat.dir'))
  ;

  return rbVirtualRepeatModule;
}

CoreConfigure.$inject = ['$provide'];
function CoreConfigure($provide) {
  $provide.decorator('$$rAF', ['$delegate', rAFDecorator]);
}

rAFDecorator.$inject = ['$delegate'];
function rAFDecorator($delegate) {
  /**
   * Use this to throttle events that come in often.
   * The throttled function will always use the *last* invocation before the
   * coming frame.
   *
   * For example, window resize events that fire many times a second:
   * If we set to use an raf-throttled callback on window resize, then
   * our callback will only be fired once per frame, with the last resize
   * event that happened before that frame.
   *
   * @param {function} callback function to debounce
   */
  $delegate.throttle = function(cb) {
    var queuedArgs, alreadyQueued, queueCb, context;
    return function debounced() {
      queuedArgs = arguments;
      context = this;
      queueCb = cb;
      if (!alreadyQueued) {
        alreadyQueued = true;
        $delegate(function() {
          queueCb.apply(context, Array.prototype.slice.call(queuedArgs));
          alreadyQueued = false;
        });
      }
    };
  };
  return $delegate;
}
