/**
 * Created by DejanK on 9/29/2017.
 */
import HTML from './view-final-agreement.html'
import '../../../rfp/pages/final-agreement/view-final-agreement.scss'
import autoSave from "root/shared/tools/file-autosave"

export { HTML, BidFinalAgreementViewController as Controller }

BidFinalAgreementViewController.$inject = ['$stateParams', 'NotificationUtils', 'MainAPI']
function BidFinalAgreementViewController($stateParams, notificationUtils, mainAPI){
  const vm = this

  vm.exportAsPdf = exportAsPdf
  vm.exportAsExcel = exportAsExcel

  $onInit()

  function $onInit(){
    vm.bidId = $stateParams.bidId
    notificationUtils.onLoad( () => mainAPI.viewHotelRfpBidFinalAgreement(vm.bidId), vm, 'fa' )
  }

  function exportAsPdf(){
    notificationUtils.onLoadWithNotification( () => mainAPI.exportFinalAgreementAsPdf(vm.bidId) )
      .then( (response) => { autoSave(response, 'application/pdf', `Final Agreement.pdf`) } )
  }

  function exportAsExcel(){
    notificationUtils.onLoadWithNotification( () => mainAPI.exportFinalAgreementAsExcel(vm.bidId) )
  }
}
