/**
 * Created by DejanK on 1/9/2017.
 */

'use strict';

let lodash = require('lodash');

module.exports = ControllerUtils;


ControllerUtils.$inject = ['$state', 'rbDialog', '$q', 'RbAnchorScroll', '$timeout', '$window'];
function ControllerUtils($state, rbDialog, $q, RbAnchorScroll, $timeout, $window){
  let $service = this;

  let defaultHttpErrorHandlers = {
    400: reportAndDisplayGenericErrorPage,
    500: reportAndDisplayGenericErrorPage,
    503: reportAndDisplayGenericErrorPage,
    401: saveVmStateAndRedirectToSignIn,
    403: toForbidden,
    404: toNotFound,
    408: retryOnce,
    other: displayGenericErrorPage
  };

  $service.hasNotChanged = hasNotChanged;
  $service.loadTo = loadTo;
  $service.save = save;
  $service.getParam = getStateParam;
  $service.go = $state.go;
  $service.hasHistoryBack = ()=>{return $window.history.length > 1;};
  $service.goHistoryBack = ()=>{$window.history.back();};
  $service.goToError = goToError;
  $service.error = goToError;
  $service.success = goToSuccess;
  $service.stopEvent = stopEvent;
  $service.scroll = RbAnchorScroll.scroll;
  $service.showDialog = rbDialog.show;
  $service.hideDialog = rbDialog.hide;
  $service.cancelDialog = rbDialog.cancel;
  $service.reload = ()=>{$state.reload()};
  $service.reportAndDisplayGenericErrorPage = reportAndDisplayGenericErrorPage;
  $service.encodeParam = encodeParam;
  $service.encodeCurrentState = encodeCurrentState;
  $service.decodeParam = decodeParam;
  $service.goToEncodedState = goToEncodedState;

  $service.$state = $state;
  $service.rbDialog = rbDialog;
  $service.$q = $q;
  $service.$timeout = $timeout;

  function loadTo(vm, destination, loaderFn, options){
    vm[destination] = 'loading';
    return loaderFn()
      .then((results)=>{
        vm[destination] = results;
        return results;
      }, (error) => {
        vm[destination] = 'error';
        return getHttpErrorHandler(error, vm, options);
      });
  }

  function save(vm, saveFn, options){
    rbDialog.show('working', {locals: {type: lodash.get(options, 'workingLabelType')}});
    return saveFn()
      .then(onSuccess, onFailure);

    function onSuccess(results){
      return rbDialog.hide()
        .then(function returnResults(){return results}, angular.noop);
    }

    function onFailure(error){
      return rbDialog.hide()
        .then( function handleError(){
          return getHttpErrorHandler(error, vm, options);
        }, angular.noop)
    }
  }

  function getHttpErrorHandler(error, vm, options){
    let errorHandlers = angular.merge({}, defaultHttpErrorHandlers, lodash.get(options, 'errorHandlers')),
      handler = errorHandlers[error.status+''] || errorHandlers.other;
    console.error('err', error, error.status, handler);
    return handler(error, vm, options) || $q.reject(error);
  }

  function reportAndDisplayGenericErrorPage(error, vm){
    
    //console.log(vm);
    //console.log(error, JSON.stringify(error));
    // todo: save to send errors to server;
    goToError('500');
    return $q.reject(error);
  }

  function saveVmStateAndRedirectToSignIn(){
    // todo: save VM state and provide data for redirection back after successful signIn
    $state.go('signIn')
  }

  function toForbidden(){
    goToError('403');
  }

  function toNotFound(){
    goToError('404');
  }

  function notifyNotFound(error, vm, options){
    rbDialog.show('error', { locals: { Error: angular.merge({
        heading: 'Requested resource',
        title: 'Not Found!',
        message: ''
      }, lodash.get(options, 'errorNotification'))}}
    );
  }

  function displayGenericErrorPage(error){
    goToError('500', error);
  }

  function retryOnce(){
    // todo: will see how to implement this. First server should support idempotent POSTs. Redirect to 408 for now
    goToError('408');
  }

  function goToError(errorId, error){
    $state.go('error', {id: errorId, error: error ? b64EncodeUnicode(JSON.stringify(error)) : ''}); // todo: check this
  }

  function goToSuccess(successId, successData){
    let to = {id: successId};
    if(successData) {to.data = btoa(JSON.stringify(successData))}
    $state.go('success', to);
  }

  function stopEvent(event){
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function getStateParam(paramKey){
    return lodash.get($state, 'params.'+paramKey);
  }

  function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
  }

  function hasNotChanged(val1, val2){
    return lodash.isEqual(deepOmitBy(val1, lodash.isUndefined), deepOmitBy(val2, lodash.isUndefined));

    function deepOmitBy(obj, predicate) {
      // basic _.omit on the current object
      let r = lodash.omitBy(obj, predicate);

      //transform the children objects
      lodash.each(r, function(val, key) {
        if (typeof(val) === "object")
          r[key] = deepOmitBy(val, predicate);
      });

      return r;
    }
  }

  function debugIsEqual(o1, o2){
    if(angular.isArray(o1)){
      return isEqualArray(o1, o2);
    } else if(angular.isObject(o1)) {
      return isEqualObject(o1, o2);
    } else {
      return isEqualValue(o1, o2);
    }

    function isEqualArray(o1, o2){
      if(!angular.isArray(o2)) {
        console.log('not array', o1, o2);
        return false;
      } else if (o1.length !== o2.length){
        console.log('arrays not equal in length', o1, o2);
        return false;
      } else {
        for(let i = 0, l = o1.length; i<l; i++){
          let e = isEqual(o1[i], o2[i]);
          if(!e){
            !e && console.log(`array item ${i} not equal`, o1, o2);
            return false;
          }
        }
        return true;
      }
    }

    function isEqualObject(o1, o2, debug){
      if(!angular.isObject(o2)) {
        console.log('not object', o1, o2);
        return false;
      } else {
        let o1Keys = Object.keys(o1);
        let o2Keys = Object.keys(o2);
        if (o1Keys.length !== o2Keys.length){
          console.log('objects not equal in length', o1, o2);
          return false;
        } else {
          for(let i = 0, l = o1Keys.length; i<l; i++){
            let e = isEqual(o1[o1Keys[i]], o2[o1Keys[i]]);
            if(!e){
              !e && console.log(`Object property ${o1Keys[i]} not equal`, o1, o2);
              return false;
            }
          }
          return true;
        }
      }
    }

    function isEqualValue(o1, o2) {
      let e = o1 === o2;
      if(!e){
        console.log(`${o1} not equal to ${o2}`);
        return false;
      }
      return true;
    }
  }


  function encodeParam(obj){
    return encodeURIComponent(btoa(JSON.stringify(obj)));
  }

  function encodeCurrentState(){
    return encodeParam({state: $state.current.name, params: $state.params});
  }

  function decodeParam(s){
    return JSON.parse(atob(decodeURIComponent(s)))
  }

  function goToEncodedState(s){
    let savedState = decodeParam(s);
    $state.go(savedState.state, savedState.params);
    return true;
  }

}
