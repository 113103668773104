/**
 * Created by DejanK on 7/6/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = RateFactory;

RateFactory.$inject = [];
function RateFactory(){

  let ratesConfig = {
    lraS: {
      name: 'LRA Single',
      abbr: 'LRA S'
    },
    nlraS: {
      name: 'NLRA Single',
      abbr: 'NLRA S'
    },
    lraD: {
      name: 'LRA Double',
      abbr: 'LRA D'
    },
    nlraD: {
      name: 'NLRA Double',
      abbr: 'NLRA D'
    }
  };

  function Rate(rateId, rateData, negotiation, negotiations){
    let rateKeyData = rateId.split('_');

    this.id = rateId;
    this.prefix = rateKeyData[0];
    this.season = rateKeyData[1][1];
    this.roomType = rateKeyData[2][2];
    this.negotiation = negotiation;
    this.negotiations = negotiations;

    angular.merge(this, rateData, ratesConfig[this.prefix]);
  }

  Rate.prototype.getValue = function(){
    return { value: this.value, valid: this.valid, included: false, type: this.type };
  };

  Rate.prototype.getFormattedValue = function(){
    return this.negotiations.currencyFormat.format(this.type === 'UNAVAILABLE' || !this.valid ? null : this.value);
  };

  return {
    create: (rateId, rateData, negotiation, negotiations)=>{
      return new Rate(rateId, rateData, negotiation, negotiations);
    }
  };
}
