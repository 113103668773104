<template>
  <select-rates :bidId="bidId" @cancel="cancel" @hide="hide" @submit="hide">
    <div slot="header">
      <div :class="$style.header">
        <img src="~images/ui/icon-warning.svg" :class="$style.headerIcon"/>
        <div>
          <h1 :class="$style.headerMain"><span :class="$style.headerMainText">Notice!</span></h1>
        </div>
      </div>
      <div :class="$style.modalBody">
        <div :class="$style.bodyText">
          We recognized you have just changed rates. Revise the rates you want to put on the Final Agreement.
        </div>
      </div>
    </div>
  </select-rates>
</template>

<script>
  import selectRates from './SelectFinalAgreementRates.vue'

  export default {
    name: 'rbv-hotel-rfp-revise-selected-rates-dialog',
    components: { selectRates },
    props: { 'bidId': {
        required: true,
        type: String
      } },
    methods: {
      cancel ( reason ) { this.$emit('cancel', reason) },
      hide ( reason ) { this.$emit('hide', reason) }
    }
  }
</script>
<style lang="stylus" module="">
  .header {
    flex 0 0 auto
    display flex
    flex-direction row
  }

  .headerIcon {
    flex 0 0 48px
    height 48px
    margin-right 10px
  }

  .headerText {
    color : #546E7A;
    text-shadow : 1px 0 0 rgba(0, 0, 0, 0.15);
    font-weight: normal;
    line-height 1
  }

  .headerMain{
    composes headerText
    height 30px
    font-size : 28px;
  }

  .headerMainText{
    vertical-align text-bottom
  }

  .modalBody {
    flex: 0 1 auto;
    padding: 0;
    display flex
    flex-direction column
  }

  .bodyText{
    font-size: 12px;
    line-height: 28px;
    color: #546E7A;
    flex: 0 0 auto;
    font-weight normal
  }
</style>
