<script type="text/jsx">
  import HotelRfpNegotiationsFormInputScreenShotPng from 'images/ui/hotel-rfp-negotiations-form-input-screenshot-1.png'
  import TooltipContainer from 'vRoot/_core/layouts/TooltipContainer.vue';
  import RbButtonWithMaterialIcon from 'vRoot/_core/buttons/ButtonWithMaterialIcon.vue';

  export default {
    functional: true,
    render (h, { listeners, $style }) {
      return <TooltipContainer boundary="window" scopedSlots={{

        default: () => <RbButtonWithMaterialIcon onClick={listeners.click} scopedSlots={{
          default: () => 'Accept Buyer Request',
          icon: () => 'check_circle' }} />,

        tooltip: () => <div class={ $style.container }>
          <p class={ $style.title }>
            Tip
          </p>
          <p>
            If you want to accept one part of the Rebid and not another, simply enter your CHANGE or Counteroffer into
            the request box <img class={ $style.screenShot }  src={HotelRfpNegotiationsFormInputScreenShotPng} /> and leave default box or non counter box alone.
          </p>
        </div>

      }} />
    }
  }
</script>

<!--suppress CssUnusedSymbol -->
<style module>

  .container {
    max-width: 300px;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .screenShot {
    height: 30px;
    line-height: 33px;
    vertical-align: text-bottom;
  }

</style>
