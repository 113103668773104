/**
 * Created by DejanK on 03/20/2017.
 */
import { abstractState, simpleState } from "../../shared/tools/router-helper";
import {USER} from "../../auth/auth-roles";

import { HTML as SelectAccountHtml, Controller as SelectAccountController } from './create-account/select-account/select-account.ctrl'
import { HTML as NewAccountHtml, Controller as NewAccountController } from './create-account/new-account/new-account.ctrl'
import SetAffiliation from './create-account/set-affiliation/set-affiliation.ctrl';

export default States

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state('account', abstractState('/account', USER))
    .state('account.create', abstractState('/create'))
    .state('account.create.affiliation', simpleState('/affiliation', SetAffiliation.HTML, SetAffiliation.Controller))

    .state('account.create.select', simpleState('/select?affiliation', SelectAccountHtml, SelectAccountController))
    .state('account.create.new', simpleState('/new?affiliation', NewAccountHtml, NewAccountController))
}
