import { isFunction, isNil, get } from 'lodash'

const validators = {
  required ( subject, isRequired ) {
    return isRequired && (subject === undefined || subject === null || (subject+'').trim().length === 0)
  },
  maxLength ( subject, maxLength ) { return subject && subject.length > maxLength },
  min ( subject, minValue ) {
    const s = parseFloat(subject)
    return !isNaN(s) && s < minValue
  },
  max ( subject, maxValue ) {
    const s = parseFloat(subject)
    return !isNaN(s) && s > maxValue
  },
  pattern ( subject, patternValue ) {
    return !isNil(subject) && !patternValue.test(subject)
  }
}

export { getValidationRules, validateTravelDestination }

function getValidationRules() {
  return {
    type: {
      required: true,
    },
    name: {
      required: true,
      maxLength: 100
    },
    estimatedRoomNights: {
      required: false,
      min: 0,
      max: 1000000,
      pattern: /^-?\d+$/
    },
    estimatedSpend: {
      required: false,
      min: 0,
      max: 100000000,
      pattern: /^-?\d+$/
    },
    location: {
      country ( location ) { return !get(location, 'address.country')},
      state ( location ) {
        const country = get(location, 'address.country')
        return ['United States', 'Canada'].indexOf(country) !== -1 && !get(location, 'address.state')
      },
      city ( location ) { return !get(location, 'address.city') },
      coordinates ( location ) { return !get(location, 'coordinates') },
    }
  }
}

function validateTravelDestination(td){
  const errors = {}, validationRules = getValidationRules()
  Object.keys(validationRules).forEach( rule => { errors[rule] = validate(td[rule], validationRules[rule], td) })
  return errors
}

function validate(subject, rules, destination) {
  const errorsBox = { invalid: false, errors: [] }

  Object.keys(rules).forEach( ruleName => {
    const rule = rules[ruleName],
      isError = isFunction(rule) ? rule(subject, destination) : validators[ruleName](subject, rule)

    if(isError){
      errorsBox.invalid = isError
      errorsBox.errors.push(ruleName)
    }
  })

  return errorsBox
}
