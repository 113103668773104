/**
 * Created by DejanK on 3/8/2017
 *
 * rbEmptyHeader Component
 */
import '../../../../public/ui/headers.scss'
import HTML from './rb-empty-header.html'

export default {
    template: HTML
};
