<template>
  <div>
    <questionnaire-form-section-group
      :response="response"
      :model="roomTypesModel"
      :template="getTemplate('RTD')"
      @change="$rbEmit"
    />

    <questionnaire-form-section-table
      :config = "config"
      :globals = "globals"
      :model="model"
      :response="response"
      :template="tableTemplate"

      @change="onRateGridValueChange"
      @stateChange="onStateChange"

      @mounted="onSectionMounted"
    />

    <modal v-if="showRateRuleError" @cancel="showRateRuleError = false" @submit="showRateRuleError = false">
      <span slot="header">Rate Rule Error</span>
      <div slot="body" class="rateRuleModalBody">
        <p class="rateRuleModalParagraph">
          Dear <strong>{{ response.answers['PROPNAME'] }}</strong>,
        </p>

        <p class="rateRuleModalParagraph">
          In our ongoing effort to forge strong lodging partnerships as well as keep costs in line with this year’s
          <strong>{{ response.answers['CLIENT_NAME'] }}</strong> Travel directives, we respectfully requests you keep this year’s rate
          within <strong>{{ rateRuleAmount }}%</strong> of last years <strong>${{ lastYearRate }}</strong> Single Standard Rate.
        </p>

        <p class="rateRuleModalParagraph">
          We sincerely appreciate your understanding.
        </p>
      </div>
      <div slot="footer">
        <button @click="showRateRuleError = false">OK</button>
      </div>
    </modal>

  </div>
</template>

<script>
  import { isEmpty } from 'lodash'
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import Modal from '../../_core/RbModal.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireFormSectionGroup from './QuestionnaireFormSectionGroup.vue'
  import QuestionnaireFormSectionTable from './QuestionnaireFormSectionTable.vue'

  export default {
    name: 'rb-questionnaire-hotel-rfp-rate-grid',

    mixins: [ RbEventsMixin, QuestionnaireMixin ],

    components: { QuestionnaireFormSectionGroup, QuestionnaireFormSectionTable, Modal },

    props: [ 'config', 'globals', 'model', 'response', 'template' ],

    data () {
      return {
        showRateRuleError: false
      }
    },

    computed: {
      roomTypesModel () {
        const model = { cells: [] }
        this.template.filters.roomType.forEach( roomTypeFilter => {

          const defineRoomTypeId = `ROOMTYPE${roomTypeFilter}DEFINE`,
            roomTypeNumberId = `ROOMTYPE${roomTypeFilter}NUMBER`

          if(this.roomTypeState.indexOf(roomTypeFilter) !== -1) {
            model.cells.push( {ord: 67 + (roomTypeFilter-1) * 2, id: defineRoomTypeId} )
            model.cells.push( {ord: 68 + (roomTypeFilter-1) * 2, id: roomTypeNumberId} )
          } else {
            const removeAnswersAndErrorsEvent = {
              name: 'change',
              id: defineRoomTypeId,
              value: undefined,
              error: []
            }
            this.$rbEmit(removeAnswersAndErrorsEvent)
            this.$rbEmit(removeAnswersAndErrorsEvent, {id: roomTypeNumberId})
          }
        })
        return model
      },

      roomTypeState () {
        return this.state.roomType.length <= this.tableConfig.roomType.length
          ? this.state.roomType
          : this.tableConfig.roomType
      },

      state () {
        return this.response.state.find( c => c.id === 'RT').data
      },

      tableConfig () {
        return this.config.find( c => c.id === 'RT').data
      },

      tableTemplate () {
        const template = this.getTemplate('RT')
        if(this.rateRuleAmount){
          const lraSingleStandardRateCell = template.cells[2].cells[3].cell
          if(this.rateRuleAmount) {
            lraSingleStandardRateCell.validations.max_value = (1 + this.rateRuleAmount/100) * this.lastYearRate
          }
        }
        return template
      },

      rateRuleAmount () { return this.tableConfig.rateRules && this.tableConfig.rateRules.amount || 0 },

      lastYearRate () { return this.globals.lastYearRate || 0 }
    },

    methods: {
      onStateChange ( event ) {
        event.templateFilters = this.template.filters
        event.filterId === 'season' && this.setProgramEndDateToNewSeasonEndDate(event.value)
        this.$rbEmit( event )
      },

      onSectionMounted () { this.$emit('mounted') },

      onRateGridValueChange( event ) {
        this.showRateRuleError = event.id === 'LRA_S1_RT1_SGL' && event.error.find( e => e.rule === 'max_value')
        this.$rbEmit( event )
      },

      setProgramEndDateToNewSeasonEndDate (change) {
        const currentEndSeasonAnswerKey = `SEASON${this.state.season.length}END`,
          newEndSeasonAnswerKey = `SEASON${this.state.season.length + change}END`,
          currentEndSeasonAnswer = this.response.answers[currentEndSeasonAnswerKey],
          newEndSeasonAnswer = this.response.answers[newEndSeasonAnswerKey]

        if(isEmpty(newEndSeasonAnswer) || change > 0){
          this.emitChange(newEndSeasonAnswerKey, currentEndSeasonAnswer || this.globals.programEndDate)
        }
        this.emitChange(currentEndSeasonAnswerKey, null)
      },
      emitChange (key, value){
        this.$rbEmit({
          name: 'change',
          id: key,
          value: value,
          error: []
        })
      }
    },
  }
</script>
<style lang="stylus">

  .rateRuleModalBody {
    padding: 12px
  }

  .rateRuleModalParagraph {
    margin: 12px 0
  }

</style>
