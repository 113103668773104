import { DEBUG } from './config/app.config';
import isObject from 'lodash/isObject';
import RbLog from './log/log';

import angular from 'angular';
import angularUiRouter from '@uirouter/angularjs';
import angularSanitize from 'angular-sanitize';
import angularMessages from 'angular-messages';
import angularAnimate from 'angular-animate';
import angularLocker from 'angular-locker';
import 'angular-recaptcha';


import APIs from './api';
import Public from './public';
import PublicAuth from './public-auth';
import PublicBid from './public-bid';
import publicInvitation from './invitation-handlers';

import Auth from './auth';
import UserAccount from './user-account';
import Core from './core';
import Rfp from './rfp/rfp.module';
import HotelRfp from './rfp-hotel/rfp-hotel.module';

import States from './states';

import angularLockerConfig from './config/angular-locker.config';
import vComponents from './v-components';
import { PublishAngularInjectorForVue } from './angular-injector-provider.js';

export default angular.module('readyBid', [
  angularSanitize, angularUiRouter, angularMessages,
  angularAnimate, angularLocker,

  APIs,
  Public, PublicAuth, PublicBid, publicInvitation,

  Auth,
  UserAccount,
  Core,
  Rfp.name,
  HotelRfp.name,

  vComponents,
  'vcRecaptcha'
])
  .config(ConfigCompile)
  .run(PublishAngularInjectorForVue)
  .config(Decorators)
  .config(angularLockerConfig)
  .run(debugUiRouter)
  .config(States)

ConfigCompile.$inject = ['$compileProvider'];
function ConfigCompile($compileProvider) {
  if(!DEBUG) {
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
  }
}

Decorators.$inject = ['$provide'];
function Decorators($provide){
  $provide.decorator('$state', stateDecorator)
  $provide.decorator('$timeout', scopeTimeoutDecorator)
  $provide.decorator('$interval', scopeIntervalDecorator)
  $provide.decorator('ngFormDirective', ngFormDecorator)
}

stateDecorator.$inject = ['$delegate', '$window'];
function stateDecorator($state, $window) {
  $state.inNew = openInNewTab;
  return $state;

  function openInNewTab(stateName, stateParams) {
    const url = $state.href(stateName, stateParams);
    $window.open(url,'_blank');
  }
}

scopeTimeoutDecorator.$inject = ['$delegate', '$rootScope'];
function scopeTimeoutDecorator($timeout, $rootScope) {
  const Scope = Object.getPrototypeOf($rootScope);

  Scope.$timeout = function( func, delay ){
    const promise = $timeout(func, delay);

    this.$on('$destroy', () => {
      if(DEBUG) {
        RbLog.info('timeout promise destroyed ');
      }
      $timeout.cancel(promise);
    });

    return promise;
  }

  return $timeout;
}

scopeIntervalDecorator.$inject = ['$delegate', '$rootScope'];
function scopeIntervalDecorator($interval, $rootScope) {
  const Scope = Object.getPrototypeOf($rootScope);

  Scope.$interval = function( func, delay ){
    const promise = $interval(func, delay);

    this.$on('$destroy', () => {
      if(DEBUG){
        RbLog.info('$interval promise destroyed ');
      }
      $interval.cancel(promise);
    });

    return promise;
  }

  Scope.$interval.cancel = $interval.cancel;

  return $interval;
}

ngFormDecorator.$inject = ['$delegate'];
function ngFormDecorator(ngFormDirective) {
  const FormController = ngFormDirective[0].controller;

  FormController.prototype.$rbTouchAllFields = function() {

    Object.keys( this ).forEach( key => {
      const formField = this[key];

      if(key !== '$$parentForm' && isObject( formField )) {

        if( FormController.prototype === Object.getPrototypeOf(formField)){
          formField.$rbTouchAllFields();
        }

        if(formField.$setTouched){
          formField.$setTouched();
        }
      }
    });
  };

  return ngFormDirective;
}

debugUiRouter.$inject = ['$rootScope'];
function debugUiRouter($rootScope){
  if(DEBUG) {
    $rootScope.$on('$stateChangeSuccess', (...TheArguments) => {RbLog.info({event: '$stateChangeSuccess', arguments: TheArguments})});
    $rootScope.$on('$stateChangeStart', (...TheArguments) => {RbLog.info({event: '$stateChangeStart', arguments: TheArguments})});
    $rootScope.$on('$stateChangeCancel', (...TheArguments) => {RbLog.warning({event: '$stateChangeCancel', arguments: TheArguments})});
    $rootScope.$on('$stateNotFound', (...TheArguments) => {RbLog.error({event: '$stateNotFound', arguments: TheArguments})});
    $rootScope.$on('$stateChangeError', (...TheArguments) => {RbLog.error({event: '$stateChangeError', arguments: TheArguments})});
  }
}
