import './ui/contact.scss'
import './ui/home.scss'
import './ui/headers.scss'
import angular from 'angular'

import Servers from '../shared-ng/servers'
import Utils from '../shared-ng/utils'
import NgUI from '../ui-ng'

import Dialogs from './dialogs'
import rbHomeHeader from './ui/rb-home-header/rb-home-header.cmp'

export default angular.module('rb.public', [ Servers, Utils, NgUI ])
  .config(Dialogs)
  .component('rbHomeHeader', rbHomeHeader)
