/**
 * Created by DejanK on 4/5/2017.
 *
 * All Single Bid Actions
 */
import get from 'lodash/get';

export default BidManagerSingleBidActionsService

BidManagerSingleBidActionsService.$inject = [ 'BidManagerActionsFactory' ];

function BidManagerSingleBidActionsService( BidManagerActionsFactory ) {
  const
    BUYER = 'BUYER',
    SUPPLIER = 'SUPPLIER',
    $service = this;

  $service.getActions = getActions;

  function getActions(bid, view) {
    const bidReport = {
      bids: [ bid ],
      view: view,
      statuses: [ bid.$status ],
      sharedRfp: bid.$rfpId,
      user: view.side,
      rfpType: view.rfpType,
      viewType: view.type,
      chain: bid.$chainId
    };

    return bidReport.rfpType === 'HOTEL' ? getHotelRfpActions(bidReport) : {};
  }

  function addAction(actionArray, actionFn, bidReport){
    const action = actionFn(bidReport);
    if(!action.isAvailable || action.isAvailable()) {
      actionArray.push(action);
    }
  }

  function getHotelRfpActions(bidReport) {
    const user = bidReport.user;
    let actions = {};

    if (user === BUYER) { actions = getBuyerHotelRfpActions(bidReport) }
    else if (user === SUPPLIER) { actions = getSupplierHotelRfpActions(bidReport) }
    return actions;
  }

  // eslint-disable-next-line complexity
  function getBuyerHotelRfpActions(bidReport) {
    const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

    switch (bidReport.statuses[0]) {

      case 'CREATED': // Bid Not Sent
        addAction(primaryActions, BidManagerActionsFactory.sendBid(), bidReport);
        addAction(primaryActions, BidManagerActionsFactory.setContact(), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.deleteBid, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'SENT': // Bid Sent : Bid Received
        addAction(primaryActions, BidManagerActionsFactory.sendBid({label: 'Resend Bid'}), bidReport);
        addAction(primaryActions, BidManagerActionsFactory.setContact(), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.withdrawBid, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'WITHDRAWN':
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'NOT_INTERESTED': // Decline To Bid
        addAction(primaryActions, BidManagerActionsFactory.sendBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'RECEIVED':
        addAction(primaryActions, BidManagerActionsFactory.sendBid({label: 'Resend Bid'}), bidReport);
        addAction(primaryActions, BidManagerActionsFactory.setContact(), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.withdrawBid, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'RESPONDED': // Bid Responded
        addAction(primaryActions, BidManagerActionsFactory.sendFinalAgreement, bidReport);
        addAction(primaryActions, BidManagerActionsFactory.withdrawBid, bidReport);

        addAction(primaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNoThankYouLetter, bidReport);

        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);

        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'FINAL_AGREEMENT': // Final Agreement Sent/Received
        addAction(primaryActions, BidManagerActionsFactory.editFinalAgreement({label: 'Final Agreement'}), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);

        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'NEGOTIATION_SENT': // Negotiation Sent/Received
        addAction(primaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        // addAction(secondaryActions, BidManagerActionsFactory.sendFinalAgreement, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNoThankYouLetter, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'NEGOTIATION_RESPONDED':
        addAction(primaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        // addAction(secondaryActions, BidManagerActionsFactory.sendFinalAgreement, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNoThankYouLetter, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'NEGOTIATION_FINALIZED':
        addAction(primaryActions, BidManagerActionsFactory.sendFinalAgreement, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNoThankYouLetter, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(otherActions, BidManagerActionsFactory.editBid, bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'NO_THANK_YOU': // No Thank You  Sent/Received
        addAction(primaryActions, BidManagerActionsFactory.viewNoThankYouLetter, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        addAction(rfpActions, BidManagerActionsFactory.editRfp, bidReport);
        break;

      case 'NO_LONGER_INTERESTED': // Participation Declined
        addAction(otherActions, BidManagerActionsFactory.previewBid(), bidReport);
        break;

      default:
    }

    return {
      primary: primaryActions,
      secondary: secondaryActions,
      other: otherActions,
      rfp: rfpActions
    };
  }

  // eslint-disable-next-line complexity
  function getSupplierHotelRfpActions(bidReport) {
    const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

    switch (bidReport.statuses[0]) {

      case 'CREATED':
        break;

      case 'SENT':
        addAction(primaryActions, BidManagerActionsFactory.previewBid({ label: 'View Bid' }), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.createEditResponse(), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.uploadResponses(), bidReport);
        // addAction(secondaryActions, BidManagerActionsFactory.declineBid, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNotInterested, bidReport);
        addAction(otherActions, BidManagerActionsFactory.forwardToNationalSales(), bidReport);
        break;

      case 'RECEIVED': {
        const isResponseValid =
          bidReport.bids.length > 0 && get(bidReport.bids[0], 'state.properties.isValid', false);
        if(isResponseValid){
          addAction(primaryActions, BidManagerActionsFactory.sendResponses, bidReport);
          addAction(secondaryActions, BidManagerActionsFactory.createEditResponse(), bidReport);
          addAction(secondaryActions, BidManagerActionsFactory.uploadResponses(), bidReport);
        } else {
          addAction(primaryActions, BidManagerActionsFactory.createEditResponse(), bidReport);
          addAction(primaryActions, BidManagerActionsFactory.uploadResponses(), bidReport);
          addAction(secondaryActions, BidManagerActionsFactory.sendResponses, bidReport);
        }
        addAction(secondaryActions, BidManagerActionsFactory.sendNotInterested, bidReport);
        // addAction(secondaryActions, BidManagerActionsFactory.declineBid, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid({label: 'Preview Initial Bid'}), bidReport);
        addAction(otherActions, BidManagerActionsFactory.forwardToNationalSales(), bidReport);
        break;
      }
      case 'WITHDRAWN':
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'NOT_INTERESTED':
        addAction(primaryActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.createEditResponse(), bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.uploadResponses(), bidReport);
        break;

      case 'RESPONDED':
        addAction(primaryActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'FINAL_AGREEMENT':
        addAction(primaryActions, BidManagerActionsFactory.viewFinalAgreement, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'NEGOTIATION_SENT':
        addAction(primaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNoLongerInterested, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'NEGOTIATION_RESPONDED':
        addAction(primaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'NEGOTIATION_FINALIZED':
        addAction(primaryActions, BidManagerActionsFactory.openNegotiationsPanel, bidReport);
        addAction(secondaryActions, BidManagerActionsFactory.sendNoLongerInterested, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'NO_THANK_YOU':
        addAction(primaryActions, BidManagerActionsFactory.viewNoThankYouLetter, bidReport);
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;

      case 'NO_LONGER_INTERESTED':
        addAction(otherActions, BidManagerActionsFactory.previewBid({ label: 'Preview Initial Bid' }), bidReport);
        break;
      default:
    }

    return {
      primary: primaryActions,
      secondary: secondaryActions,
      other: otherActions,
      rfp: rfpActions
    };
  }
}

