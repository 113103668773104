/**
 * Created by DejanK on 4/5/2017.
 *
 * Creates
 */

import  moment from 'moment';
import {get, cloneDeep, filter as lodashFilter, orderBy} from 'lodash';

module.exports = BidManagerBidsParserService;

BidManagerBidsParserService.$inject = [];
function BidManagerBidsParserService(){
  const $service = this;

  $service.parseBidsQueryHttpResponse = parseBidsQueryHttpResponse;
  $service.applyQuickiesChangeOnLastResponse = applyQuickiesChangeOnLastResponse;

  function parseBidsQueryHttpResponse(httpResponse, view){
    view.$lastHttpResponse = httpResponse;
    return applyQuickiesChangeOnLastResponse(view);
  }

  function applyQuickiesChangeOnLastResponse(view){
    let data = cloneDeep(view.$lastHttpResponse.data);
      const quickFilter = get(view, 'quickies.filter'),
      groupBy = get(view, 'quickies.group') || view.group,
      quickSort = get(view, 'quickies.sort');

    data = applySupplierContactInvitation(data);
    data = (quickFilter && groupBy) ? applyFilterBy(data, quickFilter, groupBy) : data;
    data = (quickSort || groupBy) ? applySortBy(data, quickSort, groupBy) : data;
    data = groupBy ? applyGroupBy(data, groupBy) : data;

    return {
      data: data,
      count: view.$lastHttpResponse.total
    };

    function applyFilterBy(bidsData, filter, grouper){
      const filterByKey = get(grouper, '$filter.by'),
        filterByValue = get(filter, 'value');
      if(filterByKey && filterByValue){
        return lodashFilter(bidsData, function(bid) {return get(bid, filterByKey) == filterByValue});
      }

      return null;
    }

    function applySupplierContactInvitation(bids) {
      return bids.map(bid => {
        if(bid.$supplierContact && bid.$supplierContact.invitations) {
          bid.$supplierContact = {
            ...bid.$supplierContact,
            invitationStatusDetails: invitationStatusDetails(bid.$supplierContact.invitations),
          }
        }

        return bid;
      });

      function invitationStatusDetails(details) {
        const status = {
          opened: '',
          firstSent: '',
          lastSent: '',
          nth: '',
          count: 0,
          first: '1st',
          statusDate: '',
          available: false,
          status: '',
          statusText: ''
        };

        setValue('available', details.invitationsCount > 0);
        setValue('opened', details.status && ['OPENED', 'USED'].indexOf(details.status.value) > -1);
        setValue('statusDate', moment(details.status && details.status.at).fromNow());
        setValue('firstSent', moment(details.firstSent).fromNow());
        setValue('lastSent', moment(details.lastSent).fromNow());
        setValue('count', +details.invitationsCount);
        setValue('status', details.status);
        setValue('statusText', details.statusText);
        setValue('nth', displayWithOrdinalSuffix(+details.invitationsCount));

        return status;

        function setValue(field, value) {
          status[field] = value;
        }

        function displayWithOrdinalSuffix(i) {
          const j = i % 10, k = i % 100;

          if (j === 1 && k !== 11) {
            return `${i}st`;
          } else if (j === 2 && k !== 12) {
            return `${i}nd`;
          } else if (j === 3 && k !== 13) {
            return `${i}rd`;
          } else {
            return `${i}th`;
          }
        }
      }
    }

    /*
     * data must be sorted by groupByKey first in order to this method to work properly.
     */
    function applyGroupBy(bidsData, groupedBy){
      const groupByKey = get(groupedBy, '$groupable.by'),
        groupByText = get(groupedBy, '$groupable.text') || groupByKey,
        groupedData = [];
        let lastGroupByValue = null;

      for(let i=0, il = bidsData.length; i<il; i++){
        const bid = bidsData[i], currentBidGroupByValue = get(bid, groupByKey);

        if(currentBidGroupByValue != lastGroupByValue){
          lastGroupByValue = currentBidGroupByValue;
          groupedData.push({
            header: 'header',
            title: (groupByText && get(bid, groupByText, 'Undefined')) || currentBidGroupByValue
          });
        }
        groupedData.push(bid);
      }
      return groupedData;
    }

    function applySortBy(bidsData, sortBy, groupedBy){
      const keys = [], directions = [];
      if(groupedBy) {
        addGroupByToSort(groupedBy);
      }
      if(sortBy){
        addSortByToSort(sortBy);
      }
      return orderBy(bidsData, keys, directions);

      function addGroupByToSort(grouper){
        const groupByKey = get(grouper, '$groupable.by'),
          groupByText = get(grouper, '$groupable.text');

        if(groupByText) {
          addToSort(groupByText)
        }
        if(groupByKey) {
          addToSort(groupByKey);
        }
      }

      function addSortByToSort(sorter){
        const sortByKeys = get(sorter, 'by.$sortable'),
          direction = sorter.direction;

        for(let i=0, il = sortByKeys.length; i<il; i++){
          addToSort(sortByKeys[i], direction);
        }
      }

      function addToSort(field, direction){
        keys.push(field);
        directions.push(direction === -1 ? 'desc' : 'asc');
      }
    }
  }
}
