import Vue from 'vue';

const EventBus = new Vue();

// events
const SEND_PREVIEW_EVENT = 'preview/send';
const EDIT_HEADER_EVENT = 'preview/edit-header';
const EDIT_SUBHEADER_EVENT = 'preview/edit-subheader';
const EDIT_WARNING_EVENT = 'preview/edit-warning';
const EDIT_BACKGROUND_EVENT = 'preview/edit-background';
const SHOW_SUBHEADER_EVENT = 'preview/show-subheader';
const SHOW_WARNING_EVENT = 'preview/show-warning';
const EDIT_VIEW_EVENT = 'preview/edit-view';

export {
  EventBus,
  SEND_PREVIEW_EVENT,
  EDIT_BACKGROUND_EVENT,
  EDIT_HEADER_EVENT,
  EDIT_WARNING_EVENT,
  EDIT_SUBHEADER_EVENT,
  SHOW_SUBHEADER_EVENT,
  SHOW_WARNING_EVENT,
  EDIT_VIEW_EVENT
};
