<template>
  <div>
    <questionnaire-view-section-group
      :response="response"
      :model="roomTypesModel"
      :template="getTemplate('RTD')"
    />

    <questionnaire-view-section-table
      :config = "config"
      :globals = "globals"
      :model="model"
      :response="response"
      :template="getTemplate('RT')"
      @mounted="onSectionMounted"
    />
  </div>
</template>

<script>
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireViewSectionGroup from './QuestionnaireViewSectionGroup.vue'
  import QuestionnaireViewSectionTable from './QuestionnaireViewSectionTable.vue'

  export default {
    name: 'rb-questionnaire-hotel-rfp-rate-grid',

    mixins: [ QuestionnaireMixin ],

    components: { QuestionnaireViewSectionGroup, QuestionnaireViewSectionTable },

    props: [ 'config', 'globals', 'model', 'response', 'template' ],

    computed: {
      roomTypesModel () {
        const model = { cells: [] },
          state = this.response.state.find( c => c.id === 'RT'),
          config = this.config.find( c => c.id === 'RT').data.roomType

        state.data.roomType.forEach( ( val ) => {
          if(config.indexOf(val) > -1) {
            model.cells.push({ord: 67 + (val - 1) * 2, id: `ROOMTYPE${val}DEFINE`})
            model.cells.push({ord: 68 + (val - 1) * 2, id: `ROOMTYPE${val}NUMBER`})
          }
        })
        return model
      },
    },

    methods: {
      onSectionMounted () { this.$emit('mounted') }
    }
  }
</script>
