<template>
  <div v-if="user === 'LOADING'" class="layout-column flex-center">
    <rbv-loading label="Loading, please wait..."/>
  </div>
  <div v-else>
    <div :class="$style.header">
      <div :class="$style.headerStatus">
        <i class="material-icons" :class="$style.headerStatusIcon">check_circle</i>
        <span :class="$style.headerStatusLabel">Verified User</span>
      </div>
      <h2 :class="$style.headerTitle">{{user.firstName}} {{user.lastName}}</h2>
    </div>

    <div :class="$style.section">
      <div :class="$style.sectionHeading">
        <div class="material-icons" :class="$style.sectionHeadingIcon">account_circle</div>
        <div :class="$style.sectionHeadingLabel">Personal Information</div>
        <div>
          <button class="rbButton smallIcon" @click="editPersonalInfo">
            <i class="material-icons">mode_edit</i>
          </button>
        </div>
      </div>
      <div :class="$style.sectionContent">

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">First Name</div>
          <div :class="$style.detailsValue">{{user.firstName}}</div>
        </div>

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">Last Name</div>
          <div :class="$style.detailsValue">{{user.lastName}}</div>
        </div>

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">Phone</div>
          <div :class="$style.detailsValue">{{user.phone || '-'}} </div>
        </div>

      </div>
    </div>

    <div :class="$style.section">
      <div :class="$style.sectionHeading">
        <div class="material-icons" :class="$style.sectionHeadingIcon">settings</div>
        <div :class="$style.sectionHeadingLabel">Account Options</div>
      </div>
      <div :class="$style.sectionContent">

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">Sign In Email Address</div>
          <div :class="$style.detailsValue">{{user.emailAddress}}</div>
          <div :class="$style.detailsEdit">
            <button class="rbButton smallIcon" @click="editEmail">
              <i class="material-icons">mode_edit</i>
            </button>
          </div>
        </div>

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">Password</div>
          <div :class="$style.detailsValue">**************</div>
          <div :class="$style.detailsEdit">
            <button class="rbButton smallIcon" @click="editPassword">
              <i class="material-icons">mode_edit</i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { Dialog } from "root/v-app/rbServices";
  import PersonalInfoDialog from './Dialogs/PersonalInfoDialog.vue';
  import EditEmailDialog from './Dialogs/EditEmailDialog.vue';
  import EditPasswordDialog from './Dialogs/EditPasswordDialog.vue';
  import rbvLoading from "vRoot/_core/RbvLoading.vue";

  import { noop, get } from 'lodash';
  import {currentUser, httpErrorHandler, notificationUtils} from "root/angular-injector-provider";

  export default {
    name: 'rbv-user-profile-personal-information',
    components: { rbvLoading },
    data () { return { updated: 0 } },
    asyncComputed: {
      user: {
        get () { return currentUser().get() },
        watch () { return this.updated },
        "default": "LOADING"
      }
    },
    methods: {
      editPersonalInfo(){
        Dialog.show( PersonalInfoDialog, { user: this.user } )
          .then(data => notificationUtils().onSave( () => currentUser().upateBasicUserInformation(data.form) ))
          .then(this.markUpdated)
          .catch(noop)
      },
      editEmail(){
        Dialog.show( EditEmailDialog )
          .then(data => notificationUtils().onSave( () => currentUser().updateEmailAddress(data.form), {
            customErrorHandlers: {
              '400'(error) {
                const errorMessage = get(error, 'data.message')

                switch (errorMessage) {
                  case 'EMAIL_ADDRESS_ALREADY_EXISTS':
                    return notificationUtils().showErrorDialog({
                      dialogData: {
                        heading: 'Email Address Update',
                        title: 'Failed!',
                        message: `User with ${data.form.emailAddress} already exists!`
                      }
                    })
                  default:
                    return httpErrorHandler().handle(error)
                }
              }
            }
          } ))
          .then(this.markUpdated)
          .catch(noop)
      },
      editPassword(){
        Dialog.show(EditPasswordDialog)
          .then(data => notificationUtils().onSave( () => currentUser().updatePassword(data.form) ) )
          .then(this.markUpdated)
          .catch(noop)
      },
      markUpdated () {
        this.updated++
      }
    }
  }
</script>

<style lang="stylus" module>

  .header {
    padding: 20px;
  }

  .headerStatus {
    line-height : 23px;
  }

  .headerStatusIcon {
    color: #00B8AB;
    font-size: 18px;
    vertical-align: middle;
  }

  .headerStatusLabel {
    font-size : 12px;
    color : #90A4AE;
    vertical-align: middle;
  }

  .headerTitle {
    font-size: 20px;
    color: #263238;
    font-weight: normal;
  }

  .sectionHeading {
    padding: 0 20px;
    height: 41px;
    display: flex;
    align-items: center;
  }

  .sectionHeadingIcon {
    margin-right: 10px
    color: #00B8AB;
  }

  .sectionHeadingLabel {
    flex: 1 0 auto
    font-weight: bold;
    font-size: 14px;
    color: #37474F;
  }

  .sectionContent {
    padding: 26px 20px;
    border-top: 1px solid #ECEFF1;
  }

  .detailsRow {
    display: flex
    font-size : 12px;
    line-height: 18px;
    margin-bottom: 9px;
  }

  .detailsLabel {
    flex: 0 0 130px;
    padding-right: 20px
    color: #78909C;
  }

  .detailsValue {
    flex: 1 0 300px;
    color: #455A64;
    overflow-x: auto;
  }

  .detailsEdit {
    flex: 0 0 35px;
    text-align: right
  }

</style>
