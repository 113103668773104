/**
 * Created by DejanK on 9/10/2016.
 */
import angular from 'angular'

import rbQuestionnaireFormSideMenu from './form/rb-questionnaire-form-side-menu/rb-questionnare-form-side-menu.cmp';


export default angular.module('rb.core.questionnaire', [])
    .directive('rbQuestionnaireNav', require('./rb-questionnaire-nav/rb-questionnaire-nav.dir'))
    .directive('rbQuestionnaireNavTarget', require('./rb-questionnaire-nav/rb-questionnaire-nav-target.dir'))
    .component('rbEditQuestionnaireSideMenu', require('./editor/rb-edit-questionnaire-side-menu/rb-edit-questionnaire-side-menu.cmp'))
    .component('rbQuestionnaireFormSideMenu', rbQuestionnaireFormSideMenu)
