/**
 * Created by DejanK on 7/3/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-panel-data.scss');

module.exports = {
  template: require('./rb-negotiations-panel-data.html'),
  transclude: true,
  bindings: {},
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl() {
  let vm = this;
}
