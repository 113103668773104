<template>
    <ul v-if="views">
        <li
            v-for="view of views"
            :key="view.id"
            :class="$style.flexParent"
        >
            <div :class="$style.flex">
              <div
                :class="active(view.id)?$style.activeSubMenuItem: $style.subMenuItem"
                @click.prevent.stop="open(view)"
              >
                <span :class="$style.subMenuItemIcon">
                    <i class="material-icons">face</i>
                </span>
                <span :class="$style.subMenuItemText">{{view.name}}</span>
              </div>
              <i class="material-icons" @click="manage(view)" :class="!active(view.id)? $style.manage: $style.activeManage">settings</i>
            </div>
            <template v-if="active(view.id)">
              <div :class="$style.flexChild" @click="trigger(events.SEND_PREVIEW_EVENT)">
                <i class="material-icons">email</i>
                <div>Send Preview</div>
              </div>
              <div :class="$style.flexChild" @click="trigger(events.EDIT_VIEW_EVENT)">
                <i class="material-icons">settings</i>
                <div>Display Settings</div>
              </div>
              <div :class="$style.flexChild" @click="trigger(events.EDIT_HEADER_EVENT)">
                <i class="material-icons icon">text_fields</i>
                <div>Edit Header</div>
              </div>
              <div :class="$style.flexChild" @click="trigger(events.EDIT_SUBHEADER_EVENT)">
                <i class="material-icons icon">text_fields</i>
                <div>Edit SubHeader</div>
              </div>
              <div :class="$style.flexChild" @click="trigger(events.EDIT_WARNING_EVENT)">
                <i class="material-icons icon">warning</i>
                <div>Edit Warning</div>
              </div>
              <div :class="$style.flexChild" @click="trigger(events.EDIT_BACKGROUND_EVENT)">
                <i class="material-icons icon">image</i>
                <div>Edit Background</div>
              </div>
              <check-box
                :value="view.showSubHeader"
                @input="trigger(events.SHOW_SUBHEADER_EVENT)"
                :check-box-value="true"
                check-box-label="Sub Header"
                :class="$style.flexChildCheckBox"/>
              <check-box
                :value="view.showWarning"
                @input="trigger(events.SHOW_WARNING_EVENT)"
                :check-box-value="true"
                check-box-label="Warning"
                :class="$style.flexChildCheckBox"/>
            </template>
        </li>

        <li
            :class="$style.createNew"
            @click.prevent.stop="createNew"
        >
            <span :class="$style.subMenuItemIcon">
                <i class="material-icons">add_circle_outline</i>
            </span>
            <span :class="$style.subMenuItemText">Create New View</span>
        </li>
    </ul>
</template>

<script>
import { Dialog } from "root/v-app/rbServices";
import CreateNewViewDialog from 'vRoot/hotel-directory/dialogs/CreateNewViewDialog.vue';
import ManageContentDialog from 'vRoot/hotel-directory/dialogs/ManageContentDialog.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue'
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import * as events from 'vRoot/hotel-directory/_core/EventBus';

export default {
    name: 'HotelDirectoryNavigationViewItems',
    props: ['views', 'active-view'],
    components: { CheckBox },
    data() {
      return { events }
    },
    methods: {
        active(id){
            return this.activeView && id === this.activeView.id
        },
        open(data){
            this.$emit('open', data);
        },
        createNew(){
            Dialog.show(CreateNewViewDialog).then(data => {
                this.$emit('createView', data);
            });
        },
        manage(view){
            Dialog.show(ManageContentDialog, {view, canDelete: this.views.length > 1}).then(data => {
                if(data.action === 'update') {
                    this.$emit('updateView', data.view);
                }else{
                    Dialog.show(WarningDialog, {
                        title: `Delete ${this.activeView.name} View?`,
                        subTitle: 'Please be aware that deleting this view will eliminate all viewership for this directory and all related functionality.'
                    }).then(() => {
                        this.$emit('deleteView', data.view);
                    });
                }
            });
        },
        trigger(event, data) {
          this.events.EventBus.$emit(event, data);
        }
    }
}
</script>

<style lang="stylus" module>
    .subMenuItem{
        min-height: 35px;
        box-sizing: border-box;
        color: #90a4ae;
        padding-left: 20px;
        display flex;
        align-items center;
        margin 10px 0;
        border-radius: 4px;
        overflow hidden;
        flex 1;
        cursor pointer;

        &:hover{
            color: #fff;
        }
    }

    .activeSubMenuItem{
        composes subMenuItem;
        color: #fff;
        flex 1;
    }

    .createNew{
        composes subMenuItem;
        background-color: #313c41;
        color: #fff;
    }

    .subMenuItemIcon{
      height: 24px;
      font-size: 20px;
    }

    .activeSubMenuItem .subMenuItemIcon{
      color #ccc;
    }

    .subMenuItemText{
        font-size 13px;
        padding-left 15px;
        display: flex;
        align-items: center;
    }

    .manage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 5px;
        cursor: pointer;
        color #90a4ae;
    }

    .activeManage{
        composes manage;
        color #fff;
    }

    .flex{
        display flex;
        justify-content space-between;
        align-items center;
        width 100%;
    }

    .flexParent{
      composes flex;
      flex-direction column;
      align-items flex-start;
    }

    .flexChild{
      composes flex;
      padding: 5px !important;
      box-sizing: border-box;
      font-size: 12px;
      cursor: pointer;
      width: calc(100% - 50px);
      border-radius 3px;
      margin: 5px 0 5px 50px;
    }

    .flexChild:hover{
      background #313c42;
    }

    .flexChild > div{
      flex 1;
      margin-left 10px;
    }

    .flexChildCheckBox{
      composes flexChild;
      justify-content flex-start;
    }
    .flexChildCheckBox :global .rbCheckboxOptionText{
      color: #fff !important;
    }
</style>
