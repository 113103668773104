/**
 * Created by DejanK on 01/09/2017.
 */
import { cloneDeep } from 'lodash'
import HTML from '../../edit-destination/edit-destination.html'
import { getAddressType } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { getValidationRules } from "root/rfp-hotel/main/rfp/destinations/travel-destination-validation";
import { hasNotChanged } from "root/shared/tools/utils";

const NAME = 'editUploadedTravelDestination'

export { NAME, HTML, EditUploadedDestinationController as Controller}

EditUploadedDestinationController.$inject = [ 'rbDialog', 'Destination', '$scope' ]
function EditUploadedDestinationController( dialog, destination, $scope ) {
  const vm = this

  vm.cancel = dialog.cancel
  vm.onSubmit = onSubmit
  vm.onTypeChange = onTypeChange
  vm.onFormLoaded = onFormLoaded

  $onInit()

  function $onInit() {
    vm.pageTitle = 'Edit Travel Destination'
    vm.destination = cloneDeep(destination)
    vm.addressType = getAddressType(vm.destination.type)
    vm.destinationRules = getValidationRules()
  }

  function onSubmit(isValid){
    if(isValid){
      hasNotChanged(vm.destination, destination) ? vm.cancel() : dialog.hide(vm.destination)
    }
  }

  function onTypeChange(){
    vm.addressType = getAddressType(vm.destination.type)
    delete vm.destination.location
  }

  function onFormLoaded( form ) {
    $scope.$timeout(() => { form.$rbTouchAllFields() })
  }
}
