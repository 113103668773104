/**
 * Created by DejanK on 11/26/2018.
 */
import HTML from './revise-bid-response.html'
import './../bid-response.scss'

import { noop, cloneDeep, uniq } from 'lodash'
import saveResponseService from './../save-response.service'
import HotelRfpRatePattern from "root/rfp-hotel/main/services/HotelRfpRatePattern";

import {Dialog as VueDialog} from "root/v-app/rbServices";
import ReviseFinalAgreementRatesDialog from "vRoot/rfp-hotel/final-agreement/ReviseFinalAgreementRatesDialog.vue"
import NotificationDialog from "root/v-components/_core/notifications/NotificationDialog.vue";

export { HTML, ReviseBidResponseController as Controller }

ReviseBidResponseController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', 'MainAPI', '$state', '$scope'];
function ReviseBidResponseController(notificationUtils, RfpHotelBidRepository, mainApi, $state, $scope){
  const vm = this
  let areQuestionsTouched, answersChangeReporter = new AnswersChangeReporter()

  vm.onAnswersChanged = onAnswersChanged;
  vm.close = close;
  vm.save = save;
  vm.gotoQuestion = gotoQuestion;

  $onInit();

  function $onInit(){

    vm.bidId = $state.params.bidId

    notificationUtils.onLoad( () => mainApi.getResponse(vm.bidId))
      .then( response => {
        displayNotification()
        vm.questionnaire = response.data
        vm.response = vm.questionnaire.response
        vm.questionnaire.responseDraft = vm.questionnaire.response
        answersChangeReporter = new AnswersChangeReporter(vm.questionnaire.response)
      })
      .then(touchQuestions)
      .catch(noop)
  }

  function displayNotification(){
    VueDialog.show(NotificationDialog, {
      title: 'Warning',
      subTitle: 'Notice',
      message: 'ReadyBid assumes you have agreed to the following changes with the supplier.',
    })
      .catch(noop)
  }

  function onAnswersChanged( event ){
    vm.response = event.response ;
    isResponseTouched() && touchQuestions()
    areQuestionsTouched && checkErrors()
  }

  function isResponseTouched(){
    return vm.response.response.touched
  }

  function touchQuestions(){
    if(!areQuestionsTouched) {
      $scope.$broadcast('vue-event', {event: 'questionnaireQuestion.touch'});
      areQuestionsTouched = true;
      checkErrors();
    }
  }

  function checkErrors(){
    vm.errors = Object.values(vm.response.errors).map(v => v[0]).sort((a, b) => a.ord - b.ord)
    vm.hiddenErrorsCount = vm.errors.length - 5
    vm.hiddenErrorsCount > 0 && ( vm.errors.length = 5)
  }

  function close(){ $state.go('hotelRfp.bid.finalAgreement.send') }

  function gotoQuestion(id){
    const div = document.getElementById('document-container'),
      el = document.getElementById(id);
    el && (div.scrollTop += el.getBoundingClientRect().top - 180);
  }

  function save() {
    answersChangeReporter.addNewAnswers(vm.response.response.answers)
    if(answersChangeReporter.areAnswersChanged()) {
      notificationUtils.onSave(
        () => saveResponseService.reviseResponse(vm.response.response.answers, vm.bidId),
        { customErrorHandlers: { 409: handleStatusChangedError }}
      )
        .then(showSaveSuccess)
        .catch(noop)
    } else {
      close()
    }

    function showSaveSuccess(){
      return notificationUtils.showSuccessDialog({
        dialogData: {
          heading: 'Questionnaire Response Revisions',
          title: 'Saved Successfully',
          message: 'Your changes to Questionnaire Response have been applied!'
        }})
        .finally(showReviseRatesDialog)
        .then(close)
    }

    function handleStatusChangedError(error) {
      VueDialog.show(NotificationDialog, {
        type: 'error',
        title: 'Response Not Updated',
        subTitle: 'Error',
        message: `System is unable to save the response because bid status have been changed to "${error.data.state.label}"`,
      })
        .finally(close)
    }

    function showReviseRatesDialog(){
      return answersChangeReporter.areRatesChanged()
        ? VueDialog.show(ReviseFinalAgreementRatesDialog, { bidId: vm.bidId })
        : Promise.resolve()
    }
  }
}

function AnswersChangeReporter(response){
  const originalAnswers = response && cloneDeep(response.answers) || {}
  let keysWithDifferences = []

  Object.assign(this, {
    addNewAnswers (newAnswers){
      const keys = uniq([].concat(Object.keys(originalAnswers), Object.keys(newAnswers)))
      keysWithDifferences = keys.filter( k => originalAnswers[k] !== newAnswers[k])
    },

    areAnswersChanged () {
      return keysWithDifferences.length !== 0
    },

    areRatesChanged () {
      return keysWithDifferences.filter( k => HotelRfpRatePattern.testRates(k)).length !== 0
    }
  })
}
