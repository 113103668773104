<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <span slot="header"> Edit Primary Contact Details </span>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      <div :class="$style.group">
        <rb-input
          :for-id="'firstName'"
          :error="$showError('firstName')"
        >
          <span slot="label">First Name*</span>
          <input
            id="firstName"
            v-model="data.firstName"
            v-validate="'required'"
            class="rbInput"
            name="firstName"
            data-vv-as="First Name"
            placeholder="First Name"
            autofocus
          >
        </rb-input>
        <rb-input
          :for-id="'lastName'"
          :error="$showError('lastName')"
        >
          <span slot="label">Last Name*</span>
          <input
            id="name"
            v-model="data.lastName"
            v-validate="'required'"
            class="rbInput"
            name="lastName"
            data-vv-as="Last Name"
            placeholder="Last Name"
          >
        </rb-input>
      </div>
      <rb-input
        :for-id="'email'"
        :error="$showError('email')"
      >
        <span slot="label">Email Address</span>
        <input
          id="email"
          v-model="data.emailAddress"
          v-validate="'required|email'"
          class="rbInput"
          name="email"
          data-vv-as="Email Address"
        >
      </rb-input>
      <rb-input
        :for-id="'phone'"
        :error="$showError('phone')"
      >
        <span slot="label">Phone Number</span>
        <input
          id="phone"
          v-model="data.phone"
          class="rbInput"
          name="phone"
        >
      </rb-input>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
      >
        <i class="material-icons">save</i>
        <template v-if="!user.id">
          Create New User
        </template>
        <template v-else>
          Save User
        </template>
      </button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'

  export default {
    name: 'EditPrimaryContactDialog',
    components: { ModalForm, RbInput },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      user: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data() {
      const user = this.user;
      return {
        data: {
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.emailAddress,
          phone: user.phone,
        },
      }
    },
    methods: {
      onSubmit() {
        return { data: this.data };
      },
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 450px;
    overflow visible;
  }

  .body{
    height auto
  }

  .group{
    display flex;
  }

  .group :global .rbInputContainer{
    flex: 1;
  }

  .group > div:last-child{
    margin-left 10px;
  }
</style>
