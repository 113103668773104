<template>
    <div :class="$style.container">
        <slot name="prefix" v-if="$slots.prefix"/>
        <label
            :style="counter"
            :for="name"
            :class="{[$style.counter]: true, [$style.maxed]: data.length >= max}">{{data.length}}/{{max}}</label>
        <textarea :placeholder="placeholder" ref="editor" @input="autoexpand" :maxlength="max" :id="name" v-model="data" :class="styles"></textarea>
        <i class="material-icons" @click="clear" :class="$style.clear">delete</i> 
        <i class="material-icons" @click="save" :class="{[$style.save]: true, [$style.active]: value !== data}">check_circle</i> 
    </div>
</template>


<script>
export default {
    name: "HotelDirectoryHeaderEditor",
    props: ['value', 'classes', 'max', 'name', 'placeholder'],
    mounted(){
        this.$refs.editor.focus();
        this.$refs.editor.select();
        this.autoexpand({target: this.$refs.editor});
    },
    computed: {
        styles(){
            return {
                [this.classes]: this.classes,
                [this.$style.editor]: true
            }
        },
        counter(){
            return this.$slots.prefix ? {"left": '45px'} : {};
        }
    },
    data(){
        return {
            data: this.value
        }
    },
    methods: {
        save(){
            this.$emit('save', this.data);
        },
        clear(){
            this.$emit('clear')
        },
        autoexpand (e) {
            const field = e.target;
            // Reset field height
            field.style.height = 'inherit';

            // Get the computed styles for the element
            var computed = window.getComputedStyle(field);

            // Calculate the height
            var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                        + parseInt(computed.getPropertyValue('padding-top'), 10)
                        + field.scrollHeight
                        + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                        + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

            field.style.height = height + 'px';

        }
    }
}
</script>


<style lang="stylus" module>
    .full{
        flex 1;
    }

    .container{
        composes full;
        position relative;
        background-color #313c41;
        display flex;
        align-items center;
        padding 0 5px;
    }

    .editor{
        background-color transparent;
        border: 0;
        resize: none;
        min-height: 100%;
        max-height 200px;
        width: 100%;
        color: #fff;
        font-size: 13px;
        padding: 10px 0 0 60px;
        box-sizing: border-box;
    }

    

    .counter{
        position: absolute;
        top: 50%;
        color: #2db8aa;
        transform: translateY(-50%);
        background: #263238;
        padding: 2px;
        font-size: 11px;
        border-radius: 10px;
        left 10px;
    }

    .maxed{
        color #d75539 !important;
    }

    .save, .clear{
        cursor pointer;
        color #90a2ae;
    }

    .active{
        color #fff;
    }
</style>
