import Vue from 'vue';

export default {
  template: '<div></div>',
  bindings: {
    bid: '<'
  },
  controller: Ctrl,
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const
    vm = this;

  let
    vueComponent = null;

  Object.assign(this, {

    $onInit () {
      import( /* webpackChunkName: 'TheRateQuickResponseView' */ 'vRoot/rfp-hotel/bid/rate-quick/TheRateQuickResponseView.vue')
        .then( imported => {
          vueComponent = new Vue({
            el: $element[0],
            render ( h ) {
              return h( imported.default, {
                props: {
                  bid: vm.bid,
                },
              });
            },
          });
        })
        .catch( () => {} );
    },

    $onDestroy () {
      if(vueComponent) {
        vueComponent.$destroy();
      }
    },
  });
}
