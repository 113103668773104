<template>
  <div class="rfp-manager-actions actions flex-hold">
    <label class="toolbox-label">
      RFP Actions:
    </label>
    <div
      v-if="!actions"
      class="toolbox-content message"
    >
      <i class="material-icons">arrow_downward</i>
      <span>Please select or right-click on a RFP</span>
    </div>
    <div
      v-else-if="actions && actions.length === 0"
      class="toolbox-content message"
    >
      No Actions available for selection
    </div>
    <div
      v-else
      class="toolbox-content actions"
    >
      <button
        v-for="primary in primaryActions"
        :key="primary.id"
        class="action with-icon"
        @click="executeAction(primary)"
      >
        <i class="material-icons">{{ primary.icon }}</i>
        <span v-html="primary.label"></span>
      </button>
      <div class="fixed">
        <rfp-dropdown-actions
          v-if="secondaryActions && secondaryActions.length > 0"
          :items="secondaryActions"
          :value="{}"
          align="left"
          @input="executeAction"
        >
          <template slot="value">
            More Actions
          </template>
        </rfp-dropdown-actions>
      </div>
    </div>
  </div>
</template>

<script>
  import rfpDropdownActions from './rfp-manager-dropdown-actions.vue';
  import rfpManagerActionsService from '../services/rfp-manager-actions-factory';

  export default {
    name: 'RfpManagerActions',
    components: {rfpDropdownActions},
    props: {
      selected: {
        type: Array,
        required: true
      },
      accounts: {
        type: Array,
        required: true
      }
    },
    computed: {
      actions() {
        return this.selected.length > 0 && this.accounts && rfpManagerActionsService.getActionsForSelectedRfps(this.accounts, this.selected);
      },
      primaryActions() {
        const actions = this.actions && this.actions.filter(action => action.primary);
        return actions && actions.length === 0? [this.actions[0]]: [actions[0]];
      },
      secondaryActions() {
        return this.actions && this.actions.filter(action => !this.primaryActions.find(primary => action.id === primary.id));
      }
    },
    methods: {
      executeAction(primary) {
        return primary.action &&
          primary.action(
            this.selected,
            this.accounts.map(({account}) => account),
          )
            .then(data => this.$emit('actionFired', {id: primary.id, data}));
      }
    }
  }
</script>

<style lang="stylus">
  .rfp-manager-actions{
    font-size 12px;

    .toolbox-content{
      display flex;
      align-items center;
    }
  }
</style>
