<template>
  <input
    :id="id"
    :readonly="readonly"
    @focus="selectAll"
    @input="onInput"
    @blur="onBlur"
    :value="viewValue"
  />
</template>

<script>
  import Pikaday from 'pikaday'
  import Moment from 'moment'

  export default {
    name: 'rb-questionnaire-form-table-question-date',

    props: {
      id: null,
      value: {
        "default": undefined
      },
      formatView: {
        "default": "MM/DD/YYYY"
      },
      formatData: {
        "default": "YYYY-MM-DD"
      },
      firstDay: {
        // first day of the week (0: Sunday, 1: Monday, etc)
        "default": 0
      },
      readonly: {
        "type": Boolean,
        "default": false,
      }
//      setDefaultDate make the defaultDate the initial selected value
//      firstDay first day of the week (0: Sunday, 1: Monday, etc)
//  minDate the minimum/earliest date that can be selected (this should be a native Date object - e.g. new Date() or moment().toDate())
//  maxDate the m
    },

    data () {
      return {
        pikaday: null,
        actualValue: undefined,
        viewValue: ''
      }
    },

    methods: {
      onBlur (e) { !this.readonly && this.$emit('blur', this.actualValue || e.target.value ) },
      onInput () { this.actualValue = undefined },
      selectAll ( event ) { event.target.select() },
      updatePikadayDate(){
        if(this.pikaday){
          const m = Moment(this.actualValue, this.formatData)
          if(this.actualValue && m.isValid()){
            this.pikaday.setMoment(m)
          } else {
            this.pikaday.setDate(null)
            this.viewValue = this.actualValue
          }
        }
      }
    },

    watch: {
      value ( val ) {
        this.actualValue = val
        this.updatePikadayDate()
      }
    },

    mounted () {
      const vue = this
      this.pikaday = new Pikaday({
        field: this.$el,
        format: this.formatView,
        position: 'bottom left',
        onSelect () { vue.actualValue = this.toString(vue.formatData) },
        onOpen () { vue.readonly && this.hide() }
      })
      this.actualValue = this.value
      this.updatePikadayDate()
    },

    destroyed () {
      this.pikaday && this.pikaday.destroy()
    }
  }
</script>
