/**
 * Created by DejanK on 12/22/2016.
 */

import * as ConfirmAccount from './confirm-account/confirm-account.ctrl'
import * as AskForPassword from './ask-for-password/ask-for-password.ctrl'
import * as SwitchAccount from './switch-account/switch-account.ctrl'

export default Dialogs

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .addDialog(ConfirmAccount)
    .addDialog(SwitchAccount)
    .addDialog(AskForPassword)
}
