import {get, noop} from 'lodash'
import HTML from './view-cover-letter.html'

export { HTML, BidCoverLetterViewController as Controller }

const CHAIN = 'CHAIN', DIRECT = 'DIRECT'

BidCoverLetterViewController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state']
function BidCoverLetterViewController(utils, RfpHotelBidRepository, $state){
  const vm = this

  $onInit()

  function $onInit(){
    vm.bidId = $state.params.bidId

    utils.onLoad(() => RfpHotelBidRepository.getBidPreview(vm.bidId), vm, 'bid', )
      .then(() => {
        const bidData = vm.bid.data
        vm.bidType = get(bidData, 'type', DIRECT)
        vm.isChainBid = vm.bidType === CHAIN
        vm.subHeaderTitle = vm.isChainBid ? 'Chain Cover Letter' : 'Direct to Property Cover Letter'
      })
      .catch(noop)
  }
}
