<template>
  <div>
    <div v-if="template.name" class="questionnaireGroupHeader">
      <span class="questionnaireGroupHeaderText">{{template.name}}</span>
    </div>
    <div class="questionnaireGroupCells">
      <questionnaire-form-question
        v-for="question in model.cells" :key="question.id"
        :globals="globals"
        :model="question"
        :template="getTemplate(question.id)"
        :response="response"
        @change="$rbEmit"
      />
    </div>
  </div>
</template>

<script>
  import RbEventMixin from '../../_mixins/RbEventsMixin.vue'

  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireFormQuestion from './QuestionnaireFormQuestion.vue'

  export default {
    name: 'rb-questionnaire-form-section-group',

    mixins: [ RbEventMixin, QuestionnaireMixin ],

    components: { QuestionnaireFormQuestion },

    props: [ 'globals', 'model', 'response', 'template' ],

    mounted () { this.$emit('mounted') }
  }
</script>

<style lang="stylus">

  .questionnaireGroupHeader {
    background: url("~images/ui/questionnaire-group-heading.png") repeat-x;
    height: 15px;
    line-height: 15px;
    margin: 1px 0;
    padding: 4px 0;
  }

  .questionnaireGroupHeaderText {
    height: 15px;
    line-height: 15px;
    vertical-align: top;

    background-color: #B0BEC5;
    border-radius: 0 2px 2px 0;
    color: white;
    display: inline-block;
    font-size: 10px;
    padding: 0 15px 0 20px;
    text-transform: uppercase;
  }

  .questionnaireGroupCells {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px 15px;

    &:after {
      content: '';
      flex: 100000 0 1%;
    }
  }

</style>
