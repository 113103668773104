import HTML from './rb-contact-as-contact.html'

export default {
  template: HTML,
  controller: Ctrl,
  bindings: {
    user: '<',
    selected: '<',
    onSelect: '&',
    onEdit: '&',
  }
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  vm.selected && $element.scrollIntoView();
  vm.edit = edit;

  vm.$onChanges = function(changes){
    if(changes.user){
      vm.userName = vm.user ? `${vm.user.firstName} ${vm.user.lastName}` : ''
    }
    if(changes.selected){
      vm.contact = vm.selected ? vm.user : null
    }

    vm.invitation = vm.user.invitation;
  };

  function edit($event) {
    $event.preventDefault();
    $event.stopPropagation();
    vm.onEdit();
  }
}
