import { Validator, Rules } from 'vee-validate';
import isNil from 'lodash/isNil';

export default function rbvValidators() {

  Validator.extend('blank', {
    validate: value => isNil(value) || value === '',
    getMessage: field => `The ${field} value is not empty`
  });

  Validator.extend('value', {
    validate (value, args) {
      // noinspection EqualityComparisonWithCoercionJS
      return value == args[0];
    },
    getMessage: (field, args) => `The ${field} value should be ${args[0]}`
  });

  Validator.extend('valueNot', {
    validate (value, args) {
      // noinspection EqualityComparisonWithCoercionJS
      return value != args[0];
    },
    getMessage: (field, args) => `The ${field} value should not be ${args[0]}`
  });

  Validator.extend('gt', {
    validate (value, args) {
      return value > args[0];
    },
    getMessage: (field, args) => `The ${field} must be greater than ${args[0]}`,
  });

  Validator.extend('lt', {
    validate (value, args) {
      return value < args[0];
    },
    getMessage: (field, args) => `The ${field} must be less than ${args[0]}`,
  });

  Validator.extend('has_lowercase', {
    getMessage: field => `The ${field} must contain at least 1 lowercase letter`,
    validate: value => {
        const regex = /(?=\S*?[a-z])/;
        return regex.test(value);
    }
  });

  Validator.extend('has_uppercase', {
    getMessage: field => `The ${field} must contain at least 1 uppercase letter`,
    validate: value => {
        const regex = /(?=\S*?[A-Z])/;
        return regex.test(value);
    }
  });

  Validator.extend('has_number', {
    getMessage: field => `The ${field} must contain at least 1 number`,
    validate: value => {
        const regex = /(?=\S*?[0-9])/;
        return regex.test(value);
    }
  });

  Validator.extend('no_whitespace', {
    getMessage: () => `White space is not allowed!`,
    validate: value => {
        const regex = /^(\S*)$/;
        return regex.test(value);
    }
  });
  
  /**
   * Extends native date_format
   * When date is displayed in one format and saved in the other format native date_format can validate
   * that data is in the right format, but it makes message inaccurate
   *
   * rb_date_format takes 2 arguments:
   * - data date format
   * - view date format
   * In error message it will warn the user that input is not in the view format
   */
  Validator.extend('rb_date_format', {
    validate: (value, args) => Rules.date_format.validate(value, { format: args[0] }),
    getMessage: (field, args) => `The ${field} must be in a format ${args[1]}`
  });

  Validator.extend('unique_included', {
    validate: (value, args) => args.filter( a => a === value).length < 2,
    getMessage: field => `The ${field} must be unique`
  });
}
