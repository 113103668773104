/**
 * Created by DejanK on 7/8/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = TcosFactory;

TcosFactory.$inject = [];
function TcosFactory(){

  let tcosConfig = {
      name: 'Total Cost of Stay',
      abbr: 'TCOS'
  };

  function Tcos(tcosData, negotiation, negotiations){
    angular.merge(this, tcosData, tcosConfig);
    this.negotiation = negotiation;
    this.negotiations = negotiations;
  }

  Tcos.prototype.getFormattedValue = function(season, roomType){
    let tcosValue = lodash.get(this, `tcos_s${season}_rt${roomType}.value`);
    return this.formatValue(tcosValue);
  };

  Tcos.prototype.formatValue = function(value){
    return this.negotiations.currencyFormat.format(value);
  };

  return {
    create: (tcosData, negotiation, negotiations)=>{
      return new Tcos(tcosData, negotiation, negotiations);
    }
  };
}
