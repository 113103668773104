import HTML from "./rb-chain-nag.html"
import "./../nag.scss"

export default {
  bindings: {
    rbClick: '&',
    supplier: '<',
    buyer: '<'
  },
  template: HTML,
  controller: Ctrl
}

Ctrl.$inject = ['$scope', '$element']
function Ctrl($scope, $element) {
  const vm = this
  let nagWindow, mainButtonElement
  Object.assign(vm, {
    $onInit() {
      $element.focus()
      const e = $element[0]
      nagWindow = e.querySelector('div.rbPublicBidNag')
      mainButtonElement = e.querySelector('button.documentLayoutNavigationHeaderButton.primary')
    },

    hide () {
      vm.nagClass = 'nagClosing'
      closeNagElementToButton(mainButtonElement, nagWindow)
      $scope.$applyAsync()
      $scope.$timeout( () => { vm.nagClass = 'nagClosed' }, 500)
    }
  })

  function closeNagElementToButton(mainButtonElement, nagWindow) {
    const buttonRect = mainButtonElement.getBoundingClientRect(),
      windowRect = nagWindow.getBoundingClientRect(),
      xDelta = (buttonRect.left + buttonRect.right - windowRect.left - windowRect.right)/2,
      yDelta = (buttonRect.top + buttonRect.bottom - windowRect.top - windowRect.bottom)/2,
      xScale = buttonRect.width/windowRect.width,
      yScale = buttonRect.height/windowRect.height

    nagWindow.setAttribute("style", `transform: translate(${xDelta}px, ${yDelta}px) scale(${xScale}, ${yScale}); opacity: .2`)
  }
}
