/**
 * Created by DejanK on 1/25/2017.
 */
import { simpleState } from '../../shared/tools/router-helper';
import * as PublicBidPreview from './public-preview/public-bid-preview.ctrl'

export default States;

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state('publicBidPreview', simpleState('/public-bid-preview?token,direct', PublicBidPreview.HTML, PublicBidPreview.Controller))
}
