/**
 * Created by DejanK on 1/16/2017
 *
 * Creates GoogleMap for TravelDestinationManager
 */
import { get } from 'lodash'

export default {
  bindings: {
    center: '<',
    destination: '<',
    suppliers: '<',
    distance: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['RbGoogle', '$element', 'TravelDestinationManagerMapService', 'TravelDestinationManagerService'];
function Ctrl(RbGoogle, $element, mapService, travelDestinationManagerService){
  const vm = this
  let gMapsInit, profileToShow

  this.$onInit = ()=>{
    gMapsInit = RbGoogle.getMap();
  };

  this.$onChanges = (changes)=>{
    if(gMapsInit) {
      changes.center && gMapsInit.then(onCenterChanged);
      changes.destination && gMapsInit.then(onDestinationChanged);
      changes.distance && gMapsInit.then(onDistanceChanged);
      changes.suppliers && gMapsInit.then(onSuppliersChanged);
    }
  };

  this.$doCheck = ()=>{
    if(travelDestinationManagerService.focusedSupplierId !== profileToShow){
      profileToShow = travelDestinationManagerService.focusedSupplierId;
      onProfileToShowChanged();
    }
    mapService.checkAndUpdateSuppliersIcons(vm.suppliers);
  };

  function onCenterChanged(){
    mapService.panMapTo(vm.center);
  }

  function onDestinationChanged(){
    const coordinates = get(vm, 'destination.location.coordinates')
    if(coordinates) {
      mapService.createMap($element, coordinates);
      mapService.updateDestination(vm.destination);
      onDistanceChanged();
    }
  }

  function onDistanceChanged() {
    mapService.updateDistanceCircle(vm.distance);
  }

  function onSuppliersChanged() {
    mapService.updateSuppliersMarkers(vm.suppliers)
  }

  function onProfileToShowChanged(){
    mapService.updateShownProfile(profileToShow);
  }
}


