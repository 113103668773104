/**
 * Created by DejanK on 3/6/2017.
 */

'use strict';

let lodash = require('lodash');

module.exports = BidCoverLetterEditController;

BidCoverLetterEditController.$inject = ['ControllerUtils', 'RfpHotelBidRepository'];
function BidCoverLetterEditController(utils, RfpHotelBidRepository){
  let vm = this, originalCoverLetter;

  vm.save = saveCoverLetter;
  vm.onCoverLetterChanged = onCoverLetterChanged;

  $onInit();

  function $onInit(){
    vm.bidId = utils.getParam('bidId');

    vm.coverLetterModel = undefined;

    utils.loadTo(vm, 'coverLetterModel', ()=>{
      return utils.$q.all({
        template: RfpHotelBidRepository.getBidCoverLetterTemplate(vm.bidId)
          .then(httpResponse => {
            originalCoverLetter = lodash.get(httpResponse, 'data.letter');
            return angular.copy(originalCoverLetter)
          }).catch(angular.noop),
        placeholders: RfpHotelBidRepository.getHotelRfpCoverLetterPlaceholders()
      });
    })
  }

  function saveCoverLetter(){
    if(lodash.isEqual(originalCoverLetter, vm.coverLetter, true)){
      viewLetter();
    } else if (vm.coverLetterModel.template.length > 1000000) {
      utils.showDialog('error', { locals: { Error: {
          heading: 'Saving Template',
          title: 'Failed!',
          message: 'Letter template is too big.'
        }}}
      ).catch(angular.noop)
    } else {
      utils.save(vm, ()=>{
        return RfpHotelBidRepository.updateBidCoverLetterTemplate(vm.bidId, vm.coverLetterModel.template);
      })
        .then(viewLetter)
        .catch(angular.noop);
    }
  }

  function onCoverLetterChanged($event){
    vm.coverLetterModel.template = $event.letter;
  }

  function viewLetter(){
    utils.go('hotelRfp.bid.coverLetter.view');
  }
}
