/**
 * Created by DejanK on 4/5/2017.
 */

import HTML from './delete-bids.html'
const NAME = 'deleteBids'

export { NAME, HTML, DeleteBidsDialogCtrl as Controller }

DeleteBidsDialogCtrl.$inject = ['NotificationUtils', 'MainAPI', 'rbDialog']
function DeleteBidsDialogCtrl(utils, mainAPI, dialog){
  const vm = this
  vm.confirm = confirm
  vm.cancel = dialog.cancel

  function confirm(){
    utils.onSave( () => mainAPI.deleteBids(vm.Bids.map( b => b._id )) )
      .then(dialog.hide)
  }
}
