/**
 * Created by DejanK on 4/4/2017.
 */

import { get } from 'lodash'

export default RfpDestinationsViewService;

RfpDestinationsViewService.$inject = ['RfpHotelRfpDestinationsRepository', '$q'];
function RfpDestinationsViewService(RfpHotelRfpDestinationsRepository, $q){
  const $service = this, lastPropertiesRequest = {}
  let lastDestinationsRequest = {}

  $service.getRfpTravelDestinations = getRfpTravelDestinations;
  $service.listDestinationProperties = listDestinationProperties;

  function getRfpTravelDestinations(rfpId){
    return RfpHotelRfpDestinationsRepository.getRfpTravelDestinations(rfpId)
      .then( httpResponse => {

        httpResponse.data = httpResponse.data.map( item => {
          return {
            ...item,
            country: get(item, 'location.address.countryFullName', ''),
            region: get(item, 'location.address.state') || get(item, 'location.address.region', ''),
            city: get(item, 'location.address.city', '')
          }
        })

        lastDestinationsRequest = {
          time: new Date(),
          response: httpResponse,
          rfp: rfpId
        }

        return httpResponse
      })
  }

  function listDestinationProperties(rfpId, destinationId){
    const savedPropertiesRequest = lastPropertiesRequest[destinationId];
    if(
      savedPropertiesRequest
      && lastDestinationsRequest.time < savedPropertiesRequest.time
      && new Date() - savedPropertiesRequest.time < 30000
    ) {
      return $q.when(savedPropertiesRequest.response)
    } else {

      return RfpHotelRfpDestinationsRepository.listDestinationProperties(rfpId, destinationId)
        .then( httpResponse => {
          lastPropertiesRequest[destinationId] = {
            time: new Date(),
            response: httpResponse
          }
          return httpResponse
        })
    }
  }
}
