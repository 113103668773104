<template>
  <div :class="$style.filterBar">
    <div :class="$style.filters">
      <distance-filter :class="$style.filter"
                       :distance="filters.maxDistance"
                       @distanceChanged="distanceFilterChanged"/>

      <chains-filter :class="$style.filter"
                     :filter="filters.chains"
                     :chains="chains"
                     @filtersChanged="chainFiltersChanged"/>
    </div>

    <action-buttons :class="$style.actions"
                    @save="save"
                    @setDefaultFilters="setDefaultFilters"
                    @done="done" />
  </div>
</template>

<script>
  import DistanceFilter from './distance-filter/DistanceFilter.vue';
  import ChainsFilter from './chains-filter/ChainsFilter.vue';
  import ActionButtons from './filter-action-buttons/ActionButtons.vue'
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue';
  import { cloneDeep } from 'lodash';

  export default {
    name: 'rb-travel-destinations-map-filter-bar',

    mixins: [ RbEventsMixin ],

    components: { DistanceFilter, ChainsFilter, ActionButtons },

    props: {
      filters: {
        type: Object,
        required: true
      },
      chains: {
        type: Array,
        required: true
      }
    },

    data: function(){
      return {
        newFilters: cloneDeep( this.filters )
      }
    },

    methods: {
      chainFiltersChanged(event) {
        this.emitFiltersChanged({
          ...this.filters,
          chains: event.filters
        })
      },
      emitFiltersChanged( filters ) {
        this.$rbEmit({
          name: 'filtersChanged',
          filters: filters
        });
      },
      distanceFilterChanged(event) {
        const filters = { ...this.filters }
        filters.maxDistance.value = event.value
        this.emitFiltersChanged(filters)
      },
      save () {
        this.$rbEmit({ name: 'saveFilters', filters: this.filters })
      },
      done () {
        this.$rbEmit({ name: 'done' })
      },
      setDefaultFilters(){
        this.$rbEmit({ name: 'setDefaultFilters', filters: this.filters })
      }
    }
  }
</script>

<style lang="stylus" module >

  .filterBar {
    display flex
    align-items center
    height 69px
    border-bottom 1px solid #ECEFF1
    background #fff
    padding 0 15px
  }

  .filters {
    flex: 1 0 auto
    display flex
  }

  .filter {
    margin-right 15px;
    flex 0 1 50%
    max-width 420px;
    min-width 230px;
  }

  .actions {
    flex 0 0 auto
    margin-left auto
  }
</style>
