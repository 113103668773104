/**
 * Created by Stefan on 10/4/2016.
 *
 * rbInputRepeatPassword
 * please note that using it as component will not work. Bindings do not work, for unknown reason.
 */

'use strict';

module.exports = ()=> {
  return {
    restrict:'E',
    template: ($element, $attrs)=> {
      let name = $attrs.name || 'repeatedPassword',
        label = $attrs.label || 'Repeat password',
        placeholder = $attrs.placeholder || label,
        required = $attrs.required === 'required' ? 'required="required"' : '';
      return `
<div class="rb-input" ng-form="${name}" ng-class="{'focused':focused, 'touched': ${name}.${name}.$touched || ${name}.${name}.$dirty}">
  <label>${label}</label>
  <input type="password" name="${name}" placeholder="${placeholder}" ng-model="$ctrl.model" rb-match="$ctrl.compared" ${required}
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${name}.${name}.$error" class="errors">
      <span ng-message="required">${label} is required!</span>
      <span ng-message="rbmatch">Passwords don't match!</span>
    </div>
  </div>
</div>`
    },
    scope: {
      model: '=',
      compared: '='
    },
    controller:()=>{},
    bindToController:true,
    controllerAs:'$ctrl'
  }
};







