<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-send-bids-action-report',
    mixins: [ ActionReportMixin ],
    data () {
      return {
        messages: {
          'OK': 'Bid Sent',
          'NOT_FOUND': 'Sending Failed - Bid Deleted',
          'STATUS_CHANGED': 'Sending Failed - Bid Changed',
          'ERROR': 'Sending Failed'
        }
      }
    },
    computed: {
      title () { return this.items.length === 1 ? "Sending failed!" : "Some bids could not be sent!" },
      message () {
        return this.items.length === 1
          ? "We were not able to send a bid because of the reason listed below:"
          : `${this.items.length - this.failures} of ${this.items.length} bids were successfully sent.<br /> We were not able to send all bids because of the reasons listed below:`
      }
    }
  }
</script>
