/**
 * Created by DejanK on 7/6/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = RoomTypesFactory;

RoomTypesFactory.$inject = ['NegotiationsHtmlFactory'];
function RoomTypesFactory(NegotiationsHtmlFactory){

  function RoomTypes(roomTypesData, negotiations){
    this.roomTypes = roomTypesData;
    this.negotiations = negotiations;
  }

  RoomTypes.prototype.count = function(){
    return this.roomTypes.length;
  };

  RoomTypes.prototype.print = function(roomTypeId){
    let rt = lodash.find(this.roomTypes, {id: parseInt(roomTypeId)});
    return `${rt.name} (${rt.count})`;
  };

  //RoomTypes.prototype.buildViewColumn = function(html){
  //  let seasonsCount = this.negotiations.seasons.count(),
  //    roomTypesCount = this.roomTypes.length,
  //    htmlFactory = NegotiationsHtmlFactory,
  //    addCellDiv = htmlFactory.addCellDiv,
  //    buildTooltip = htmlFactory.buildTooltip;
  //
  //  htmlFactory.addColumnWithHeaderDiv(html, 'RT', 'room-type');
  //  for(let i=0; i<seasonsCount; i++){
  //    for(let j=1; j<=roomTypesCount; j++){
  //      let roomType = this.roomTypes[j-1];
  //      addCellDiv(html, j,
  //        `room-type strong`,
  //        buildTooltip(`${roomType.name} (${roomType.count})`));
  //    }
  //  }
  //  htmlFactory.addDivClose(html);
  //};
  //
  //RoomTypes.prototype.viewColumn = function(){
  //  let roomTypesCount = this.roomTypes.length,
  //    htmlFactory = NegotiationsHtmlFactory,
  //    cellDiv = htmlFactory.cellDiv,
  //    buildTooltip = htmlFactory.buildTooltip;
  //
  //  let html = htmlFactory.columnWithHeaderDiv('RT', 'room-type');
  //  for(let i=0; i<this.negotiations.seasons.count(); i++){
  //    for(let j=1; j<=roomTypesCount; j++){
  //      console.log(j);
  //      let roomType = this.roomTypes[j-1];
  //      html += cellDiv(roomType.id, `room-type strong`, buildTooltip(`${roomType.name} (${roomType.count})`));
  //    }
  //  }
  //  html += htmlFactory.divClose;
  //  return html;
  //};
  //
  //RoomTypes.prototype.buildEditColumn = function(html, index){
  //  html.push(`<div class="room-type">
  //      ${index}
  //    </div>`);
  //};

  return {
    create: (roomTypesData, negotiations)=>{
      return new RoomTypes(roomTypesData, negotiations);
    }
  };
}
