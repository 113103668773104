/**
 * Created by DejanK on 11/7/2016.
 */
import './rb-input-coordinates.scss'
import {uniqueId} from 'lodash'

export default {
  template: templateFn,
  bindings: {
    model: '=',
    center: '=',
    zoom: '='
  }
}

templateFn.$inject = ['$attrs', 'RbInputTemplate']
function templateFn ($attrs, RbInputTemplate){
  const options = RbInputTemplate.readDefaultAttributes($attrs)
  options.id = options.id || uniqueId('map_')

  return `
<div class="rb-input layout-column flex-100" ng-form="${options.name}" ng-class="{'focused':focused, 'touched': ${options.name}.${options.name}.$touched}">
  <div class="label">${options.label}</div>
  <rb-coordinates class="flex-100 layout-column" id="${options.id}" name="${options.name}" ng-model="$ctrl.model" center="$ctrl.center" zoom="$ctrl.zoom"></rb-coordinates>
</div>`
}
