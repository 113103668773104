<template>
  <div class="vModal vModalDefault" :class="$style.dialog">
  <div :class="$style.header">
    <img src="~images/ui/icon-warning.svg" :class="$style.headerIcon"/>
    <div>
      <h1 :class="$style.headerMain"><span :class="$style.headerMainText">Upload Successful!</span></h1>
    </div>
    <button class="vModalDefaultCloseButton" @click="hide()">close</button>
  </div>
  <div :class="$style.modalBody">
    <div v-if="!questionnaireErrorsCount" :class="$style.bodyText">Responses have been uploaded to all selected bids!</div>
    <div v-else-if="questionnaireErrorsCount === 1" :class="$style.bodyText">1 Hotel needs attention. See status "Bid Incomplete"!</div>
    <div v-else :class="$style.bodyText">{{questionnaireErrorsCount}} Hotels need attention. See status "Bid Incomplete"!</div>
  </div>
  <div class="vModalDefaultFooter">
    <slot name="footer">
      <button class="rbButton mainButton" :class="$style.okButton" @click="hide()">OK</button>
    </slot>
  </div>
</div>
</template>

<script>
  import ActionReportMixin from './ActionReportMixin.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-upload-successful-action-report',
    mixins: [ ActionReportMixin ],
    computed: {
      questionnaireErrorsCount () {
        return this.report.children.filter( r => !r.tObject.state.properties.isValid).length
      }
    }
  }
</script>
