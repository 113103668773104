import angular from 'angular'
import DataServer from './data.server'
import MainServer from './main.server'
import DemoServer from './demo.server'
import AuthServer from './auth.server'

export default angular.module('rb.servers', [])
  .service('DataServer', DataServer)
  .service('MainServer', MainServer)
  .service('DemoServer', DemoServer)
  .service('AuthServer', AuthServer)
