<template>
  <div class="rbv-croppie">
    <div class="target"></div>
  </div>
</template>

<script>
  import Croppie from 'croppie';
  import { merge, noop } from 'lodash';

  import 'croppie/croppie.css';

  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue';
  export default {
    name: 'rbv-croppie',
    mixins: [rbEventsMixin],
    props: {
      url: {
        type: String,
        required: true,
        default: ''
      },
      options: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data(){
      return {
        croppie: null,
      }
    },
    mounted(){
      const defaultOpts = {
        viewport: { width: 260, height: 260 },
        boundary: { width: 490, height: 340 },
        showZoomer: true
      }

      const opts = merge(defaultOpts, this.options);
      this.croppie = new Croppie(this.$el.querySelector('div.target'), {
        ...opts,
        update: ev => {
          this.croppie.result({type: 'blob', format: 'png', quality: 0.9}).then(blob => {
            this.$rbEmit({
              name: 'change',
              blob: blob,
            });
          });
          this.options && this.options.update && this.options.update(ev);
        }
      });

      this.croppie.bind({
        url: this.url,
        zoom: 0
      }, noop);
    },
    destroyed(){
      this.croppie && this.croppie.destroy();
    }
  }
</script>

<style lang="stylus">
  .rbv-croppie{

    .target{
      position relative;
      .cr-boundary{
        margin-bottom 5px;
      }
      .cr-slider-wrap{
        width 100%;
        margin 0;
        position absolute;
        z-index 1;
        bottom -4px;

        .cr-slider{
          width 100%;
          margin 0;
          padding 0;

          &::-webkit-slider-thumb {
            border: 1px solid #fff;
            background: rgba(255, 255, 255, 0.99);
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }

          &::-ms-track{
            background #16272B;
            opacity: 1;
          }
        }
      }
    }
  }
</style>
