<template>
  <div :class="classes.overlay">
    <div :class="classes.container">

      <template v-if="tableOpen">
        <div :class="classes.header">
          <view-toggler class="flex-hold" :is-table-open="tableOpen" @toggled="toggleTable" />
          <local-search :class="$style.tableSearch" :searchTerm="query" @onSearch="setQuery" />
          <sorter :sorter="sort" size="large" @onDestinationSortChanged="setSortKey" />
        </div>
      </template>

      <template v-else>
        <div :class="classes.header">
          <view-toggler :is-table-open="tableOpen" @toggled="toggleTable"/>
          <properties-counter :class="$style.counter" :count="this.suppliers.length" />
        </div>

        <div :class="$style.searchFilterContainer">
          <div :class="$style.searchFilterHeader">
            <div class="flex-100">Add Hotels to Travel Destination</div>
            <sorter class="flex-hold" size="small" :sorter="sort" @onDestinationSortChanged="setSortKey"/>
          </div>
          <local-search @onSearch="setQuery" :searchTerm="query" />
        </div>
      </template>

      <v-list :class="classes.items" :items="sortedSuppliers" :size="62" :scroll-to="scroll">
        <template slot="before" v-if="tableOpen">
          <div :class="$style.tableHead">
            <div :class="$style.tableRowHead">
              <sortable class="msGridColumn1" :class="$style.centered" :id="'bidState'" :by="sort"
                        @onDestinationSortChanged="setSortKey">ADD</sortable>
              <sortable class="msGridColumn3" :id="'name'" :by="sort"
                        @onDestinationSortChanged="setSortKey"><properties-counter :count="suppliers.length" /></sortable>
              <sortable class="msGridColumn5" :id="'city'" :by="sort"
                        @onDestinationSortChanged="setSortKey">City</sortable>
              <sortable class="msGridColumn7" :id="'state'" :by="sort"
                        @onDestinationSortChanged="setSortKey">State/Region</sortable>
              <sortable class="msGridColumn9" :id="'country'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Country</sortable>
              <sortable class="msGridColumn11" :id="'distanceMi'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Distance</sortable>
              <sortable class="msGridColumn13" :id="'chain'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Chain</sortable>
              <sortable class="msGridColumn15" :id="'brand'" :by="sort"
                        @onDestinationSortChanged="setSortKey">Brand</sortable>
            </div>
          </div>
        </template>

        <template slot-scope="item">
          <div v-if="tableOpen" :class="$style.tableBody" >
            <expanded-supplier :class="$style.tableRowBody"
                               :supplier="item.item"
                               :filters="filters"
                               :sorter="sort"
                               @onRemoveFromBidManager="$rbEmit"
                               @onAddToBidManager="$rbEmit" />
          </div>
          <shrinked-supplier v-else
                             :supplier="item.item"
                             :sorter="sort"
                             @onRemoveFromBidManager="$rbEmit"
                             @onAddToBidManager="$rbEmit"

                             @onSupplierMouseEnter="$rbEmit"
                             @onSupplierMouseLeave="$rbEmit"
                             @onPanMapTo="$rbEmit"/>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue';
  import ViewToggler from './ViewToggler.vue'
  import LocalSearch from './LocalSearch.vue';
  import sorter from './TableSorter.vue';
  import PropertiesCounter from './PropertiesCounter.vue'
  import Sortable from './Sortable.vue';
  import vList from 'vRoot/_core/RbVirtualList.vue';
  import shrinkedSupplier from './ListSupplier.vue';
  import expandedSupplier from './TableSupplier.vue';
  import sortService from 'root/v-components/travel-destinations-map/travel-destinations-map-table/suppliers-sort-service';

  export default {
    name: 'travel-destinations-map-suppliers-table',
    mixins: [rbEventsMixin],
    components: {
      ViewToggler, LocalSearch, sorter, PropertiesCounter,
      Sortable, vList, shrinkedSupplier, expandedSupplier
    },
    data() {
      return {
        query: '',
        sort: sortService.getDefaultSort(),
        scroll: -1
      }
    },
    props: {
      'table-open': {
        type: Boolean,
        required: true,
        default: false
      },
      suppliers: {
        type: Array,
        required: true,
        default: []
      },
      filters: {
        type: Object,
        required: true,
        default: () => {}
      },
      scrollTo: {}
    },
    computed: {
      filteredSuppliers() {
        if(this.query) {
          return this.suppliers.filter(s => {
            if (!s.$$searchString) {
              s.$$searchString = s.name + ' ';
              if (s.chain) { s.$$searchString += s.chain.name + ' ' }
              if (s.location) { s.$$searchString += s.location.fullAddress }
              s.$$searchString = s.$$searchString.toLowerCase()
            }

            return s.$$searchString.indexOf(this.query.toLowerCase()) > -1
          })
        } else {
          return this.suppliers
        }
      },
      sortedSuppliers() {
        const sortedSuppliers = sortService.sort(this.filteredSuppliers, this.sort.key, this.sort.order)
        this.scroll = 0
        return sortedSuppliers
      },
      classes () {
        return this.tableOpen
          ? {
            overlay: this.$style.tableOverlay,
            container: this.$style.tableContainer,
            header: this.$style.tableHeader,
            items: this.$style.table,
          }
          : {
            overlay: this.$style.listOverlay,
            container: this.$style.listContainer,
            header: this.$style.listHeader,
            items: this.$style.list,
          }
      }
    },
    watch: {
      scrollTo (val) { this.scroll = val }
    },
    methods: {
      setQuery(event){
        this.query = event.value;
      },
      setSortKey(event){
        sortService.updateSortObject(this.sort, event.id )
      },
      toggleTable(){
        this.$rbEmit({
          name: 'toggleTable'
        });
      }
    }
  }
</script>

<style lang="stylus" module >

  .overlay {
    position absolute
    top 0
    bottom 0
    right 0
    transition width .3s linear, background .4s linear
    box-shadow 0 8px 16px 0 rgba(0,0,0,0.2)
  }

  .listOverlay {
    composes overlay
    width 421px
  }

  .tableOverlay {
    composes overlay
    width 100%
    background rgba(0,0,0, .3)
  }

  .container {
    height 100%
    display flex
    flex-direction column
    background #ECEFF1
  }

  .listContainer {
    composes container
  }

  .tableContainer {
    composes container
    margin-left 5vw

  }

  .header {
    display flex
    align-items center
  }

  .listHeader {
    composes header
    flex 0 0 34px
    padding-right: 10px
    margin-top: -1px;

    background: #fff;
    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #d9e0e3;
  }

  .tableHeader {
    composes header
    flex 0 0 auto

    background #ECEFF1
    padding 11px
  }

  .items {
    overflow-y auto
    border-bottom 5px solid #D9E0E3
  }

  .table {
    composes items
    background #ECEFF1
    position relative
  }

  .list {
    composes items
    flex 1 1 auto
  }

  .counter {
    margin-left auto
    font-size 14px
  }

  .searchFilterContainer {
    flex: 0 0 70px
    background #ECEFF1
    border 1px solid #D9E0E3
    border-top 0
    padding 6px 10px 9px
  }

  .searchFilterHeader {
    line-height 26px
    font-size 12px
    color #546E7A
    display flex
    align-items center
    margin-bottom 7px
  }

  .tableSearch {
    flex 0 1 100%
    margin 0 20px
  }

  .tableHead {
    background: #ECEFF1;
    font-size : 12px;
    color : #546E7A;
    position sticky
    top -1px
    z-index 1
    border-bottom: 1px solid #D9E0E3;
  }

  .tableRow {
    display grid
    grid-template-columns: 60px 1fr .5fr 100px 100px 100px .5fr 1fr;
    grid-column-gap: 5px;

    display: -ms-grid;
    -ms-grid-columns: 60px 5px 1fr 5px .5fr 5px 100px 5px 100px 5px 100px 5px .5fr 5px 1fr;
  }

  .tableRowHead {
    composes tableRow
    height 38px
  }

  .tableRowBody {
    composes tableRow
    height 61px
    line-height 61px
    margin-bottom 1px
  }

  .centered {
    justify-content center
  }
</style>
