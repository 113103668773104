/**
 * Created by DejanK on 1/25/2017.
 */
import { abstractState, simpleState } from "../../../../shared/tools/router-helper";
import {USER, GUEST} from "../../../../auth/auth-roles";
import * as CreateBidResponse from './response/bid-response.ctrl'
import * as ReviseBidResponse from './response/revise/revise-bid-response.ctrl'
import * as EditQuestionnaire from './questionnaire/edit-questionnaire.ctrl'
import * as SendFinalAgreement from './final-agreement/send-final-agreement.ctrl'
import * as EditFinalAgreement from './final-agreement/edit-final-agreement.ctrl'
import * as ViewFinalAgreement from './final-agreement/view-final-agreement.ctrl'
import * as PreviewCoverLetter from './response/view-cover-letter.ctrl'
import * as ViewCoverLetter from './cover-letter/view-cover-letter.ctrl'
import * as BidPreview from './preview/bid-preview.ctrl'

export default States;

export const SEND_FINAL_AGREEMENT_STATE = 'hotelRfp.bid.finalAgreement.send';
export const HOTEL_RFP_BID_PREVIEW = 'hotelRfp.bid.preview'

States.$inject = ['$stateProvider'];
function States($stateProvider) {

  $stateProvider

    .state('hotelRfp.bid', abstractState('/bids/{bidId}'), USER)

    .state(HOTEL_RFP_BID_PREVIEW, simpleState('/preview', BidPreview.HTML, BidPreview.Controller))

    .state('hotelRfp.bid.response', simpleState('/response', CreateBidResponse.HTML, CreateBidResponse.Controller))
    .state('hotelRfp.bid.reviseResponse', simpleState('/revise-response', ReviseBidResponse.HTML, ReviseBidResponse.Controller))

    .state('hotelRfp.bid.coverLetter', abstractState('/cover-letter'))
    .state('hotelRfp.bid.coverLetter.preview', simpleState('/letterview', PreviewCoverLetter.HTML, PreviewCoverLetter.Controller))
    .state('hotelRfp.bid.coverLetter.view', simpleState('/view', ViewCoverLetter.HTML, ViewCoverLetter.Controller))
    .state('hotelRfp.bid.coverLetter.edit', simpleState('/edit',
      require('./cover-letter/edit-cover-letter.html'), require('./cover-letter/edit-cover-letter.ctrl')))

    .state('hotelRfp.bid.questionnaire', abstractState('/questionnaire'))
    .state('hotelRfp.bid.questionnaire.view', simpleState('/view',
      require('./questionnaire/view-questionnaire.html'), require('./questionnaire/view-questionnaire.ctrl')))
    .state('hotelRfp.bid.questionnaire.edit', simpleState('/edit', EditQuestionnaire.HTML, EditQuestionnaire.Controller))

    .state('rateAudit', simpleState('/rate-audit',
      require('./rate-audit/rate-audit.html'), require('./rate-audit/rate-audit.ctrl')))

    .state('hotelRfp.bid.finalAgreement', abstractState('/final-agreement'))
    .state(SEND_FINAL_AGREEMENT_STATE, simpleState('/send', SendFinalAgreement.HTML, SendFinalAgreement.Controller))
    .state('hotelRfp.bid.finalAgreement.edit', simpleState('/edit', EditFinalAgreement.HTML, EditFinalAgreement.Controller))
    .state('hotelRfp.bid.finalAgreement.view', simpleState('/view?token', ViewFinalAgreement.HTML, ViewFinalAgreement.Controller, [USER, GUEST]))
}
