/**
 * Created by DejanK on 7/20/2017.
 */
import HTML from './rb-negotiations-input-taxes.html';
import { set, cloneDeep, debounce } from 'lodash';

export default {
  template: HTML,
  bindings: {
    negotiations: '<',
    model: '<',
    primaryRate: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$scope'];
function Ctrl($scope) {
  const
    vm = this,
    throttledUpdateValue = debounce(updateValue, 500);

  let
    initialized;

  this.$onChanges = initialize;

  function initialize(){
    if(vm.model && vm.negotiations && (vm.primaryRate || vm.primaryRate === 0) && !initialized) {
      initialized = true;
      updateValue();
      $scope.$on('RB_NEGOTIATION_CHANGED', throttledUpdateValue)
    }
  }

  function updateValue(){
    updateModel();
    vm.summary = createSummary();
  }

  function updateModel(){
    const
      taxes = vm.model.analytics.taxes,
      rateValue = parseFloat(vm.primaryRate.value),
      total = Object.keys(taxes).reduce( (sum, key) => {
        if(key !== 'vatGstFb') {
          const tax = taxes[key];

          if (tax.type === 'PERCENTAGE') {
            const amount = rateValue * parseFloat(tax.value);
            tax.amount = amount;
            return tax.included ? sum : sum + amount;
          } else if (key !== 'total' && !tax.included) {
            return sum + (parseFloat(tax.value) || 0);
          }
        }

        return sum;
      }, 0);

    set(vm.model, 'analytics.taxes.total.value', total);
  }

  function createSummary(){
    const
      taxes = vm.model.analytics.taxes,
      rateFormat = vm.negotiations.currencyFormat.format,
      percentageFormat = vm.negotiations.percentageFormatter.format;

    return Object.keys(taxes).reduce( ( accumulator, taxKey) => {
      const
        tax = cloneDeep(taxes[taxKey]);

      if(tax.type === 'PERCENTAGE'){
        tax.value = percentageFormat(tax.value);
        tax.amount = rateFormat(tax.amount);
      } else {
        tax.value = rateFormat(tax.value);
      }
      accumulator[taxKey] = tax;
      return accumulator;
    }, {});
  }
}
