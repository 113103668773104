<script>
  import isEmpty from 'lodash/isEmpty';
  import cloneDeep from 'lodash/cloneDeep';
  import noop from 'lodash/noop';
  import RbEventMixin from 'vRoot/_mixins/RbEventsMixin.vue';
  import EventBus from 'vRoot/_mixins/EventBus';
  import customValidations from './if-operations';

  export default {

    mixins: [ RbEventMixin ],


    props: {
      globals: {
        type: Object,
        required: true,
      },

      model: {
        type: Object,
        default: () => ({}),
      },

      response: {
        type: Object,
        required: true,
      },
    },


    data () {
      return {
        isDirty: false,
        isDirtyParentValidationField: false,
        validations: {}
      }
    },


    computed: {

      vModel () {
        return this.response.answers[this.updatedTemplate.id];
      },

      classes () {
        return Object.assign(
          {},
          this.templateClasses,
          {
            required: this.isRequired,
            error: this.showErrors && this.errors.has(this.updatedTemplate.id)
          },
        );
      },

      isRequired () {
        return !!( this.updatedTemplate.req || (this.model && this.model.req) || (this.validations && this.validations.required) );
      },

      showErrors () {
        return this.isDirty || this.isDirtyParentValidationField;
      },

      templateClasses () {
        return this.updatedTemplate.classes ? toObject(this.updatedTemplate.classes) : {};

        function toObject(classes) {
          return classes
            .split(' ')
            .reduce( ( acc, key ) => {
              acc[key] = true;
              return acc;
            }, {});
        }
      },
    },


    created () {
      this.onValidationCreated();
    },


    mounted () {
      this.parseValidations();
      this.$nextTick(() => this.emitChange(this.vModel));
      this.configureEventBusListeners();
    },

    destroyed () { 
      this.cleanupWatchers();
    },


    methods: {

      onChange ( value ) {
        this.isDirty = true;
        this.emitChange(value)
      },

      emitChange (value) {
        this.$validator.validate( this.updatedTemplate.id, value )
          .then(
            () => {
              this.$rbEmit({
                name: 'change',
                id: this.updatedTemplate.id,
                value: value,
                error: this.$validator.errors.collect( this.updatedTemplate.id, undefined , false )
                  .map( error => ({
                    ...error,
                    ord: this.updatedTemplate.ord
                  }) ),
              });
            }
          )
          .catch( noop )
        ;
      },

      parseValidations () {
        this.validations = {};
        this.$set(this.validations, 'required', this.isRequired);
        if (this.updatedTemplate.validations) {
          Object.keys(this.updatedTemplate.validations).forEach( this.parseValidation );
        }
      },

      parseValidation ( key ){
        switch (key) {
          case 'if': {
            this.parseIfValidation(this.updatedTemplate.validations[key]);
            break;
          }
          default:
            this.$set(this.validations, key, this.parseValidationRule(this.updatedTemplate.validations[key]));
        }
      },

      parseIfValidation ( validation ){
        if( Array.isArray(validation[0]) ){
          validation.forEach( v => this.parseIfValidation(v) );

        } else {
          const fieldValue = this.parseValidationRuleValue( validation[0] ),
            rules = customValidations[validation[1][0]] ( validation[1][1], fieldValue ) ? validation[2] : validation[3] || {};

          Object.keys(rules).forEach( k => {
            const validationValue = this.parseValidationRule(rules[k]);
            this.$set(this.validations, k, validationValue);
            setRequiredValueIfNeeded(k, this.vModel, validationValue, this.emitChange);
          });
        }

        function setRequiredValueIfNeeded(validationRule, modelValue, validationValue, emitChangeFn){
          if(validationRule === 'value' && isEmpty(modelValue)){
            emitChangeFn(validationValue);
          }
        }
      },

      parseValidationRule (r) {
        let rule = cloneDeep(r);

        if(Array.isArray(rule)){
          rule.forEach( ( val, index ) => {
            rule[index] = this.parseValidationRuleValue( val )
          } );

        } else {
          rule = this.parseValidationRuleValue( rule );
        }

        return rule;
      },

      parseValidationRuleValue ( val ) {
        const prefix = val[0];

        switch ( prefix ) {
          case '$': {
            return this.globals[val.substring(1)];
          }

          case '#': {
            const id = val.substring(1);

            if(this.unwatch[id]){
              this.unwatch[id]();
            }

            this.unwatch[id] = this.$watch(`response.answers.${id}`, () => {
              this.isDirtyParentValidationField = true;
              this.parseValidations();
              this.$nextTick(() => this.emitChange(this.vModel));
            });

            return this.response.answers[val.substring(1)];
          }

          default:
            return val;
        }
      },

      onValidationCreated () {
        this.unwatch = {};
        this.isDirty = !isEmpty(this.vModel);
      },

      cleanupWatchers () {
        if(this.unwatch) {
          Object.keys(this.unwatch).forEach( k => this.unwatch[k]() );
        }
      },

      configureEventBusListeners(){
        // configure event to dirty all fields in order to show all validation errors
        EventBus.$on('questionnaireQuestion.touch', () => {
          this.isDirty = true;
        });
      }
    }
  }
</script>
