/**
 * Created by DejanK on 12/20/2016.
 */
export default rbFormValidationDirective;

function rbFormValidationDirective(){
  return {
    restrict: 'A',
    require: '^form',
    link: (scope, element, attrs, formCtrl) => { element.on('click', () => formCtrl.$rbTouchAllFields && formCtrl.$rbTouchAllFields() ) }
  }
}
