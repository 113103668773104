/**
 * Created by DejanK on 12/22/2016.
 */
import HTML from './confirm-account.html'
const NAME = 'confirmAccount'

export { NAME, HTML, ConfirmAccountController as Controller }

ConfirmAccountController.$inject = ['NotificationUtils', 'CurrentUser', 'AccountService', 'rbDialog'];
function ConfirmAccountController(notificationUtils, currentUser, AccountService, dialog){
  const vm = this;
  vm.submit = submit;
  vm.cancel = dialog.cancel;

  onInit();

  function onInit(){
    notificationUtils.onLoad(() => {
      return currentUser.get()
        .then((user) => {
          return AccountService.getAccountType(vm.account.type)
            .then((accountType) => {
              vm.accountType = accountType;
              return user;
            })
        })
    }, vm, 'currentUser')
  }

  function submit(){
    dialog.hide(vm.jobTitle)
  }
}
