/**
 * Created by DejanK on 1/9/2017.
 *
 * rbIcon
 *
 * loads and shows svg icons via url
 */

'use strict';

require('./rb-icon.scss');

module.exports = {
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$attrs', '$http'];
function Ctrl($element, $attrs, $http) {
  let vm = this, urlRegex = /[-\w@:%\+.~#?&//=]{2,}\.[a-z]{2,4}\b(\/[-\w@:%\+.~#?&//=]*)?/i;

  vm.$onInit = $onInit;

  function $onInit(){
    $attrs.$observe('rbSrc', onSrcChange);
  }

  function onSrcChange(){
    let svgSrc = $attrs.rbSrc;
    if(!svgSrc) {
      $element.empty();
    } else if (urlRegex.test(svgSrc)){
      $http.get(svgSrc)
        .then((response)=>{
          $element.append(angular.element(response.data));
        });
    } else {
      $element.append(angular.element(`<i class="material-icons">${svgSrc}</i>`));
    }
  }
}
