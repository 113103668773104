<template>
  <div>
    <template v-if="template.name">
      <div v-if="template.locked || preview" class="questionnaireEditGroupHeader">
        <span class="questionnaireEditGroupHeaderText ">{{ template.name }}</span>
      </div>
      <div v-else="" class="questionnaireEditGroupHeader">
        <label class="rbCheckbox">
          <input type="checkbox"
                 class="rbCheckboxInput"
                 :id="template.id"
                 :name="template.id"
                 :value="isSelected"
                 @change="onChange($event.target.value)"
          />
          <span class="rbCheckboxLabel questionnaireEditGroupHeaderText" :class="isSelected">
           {{ template.name }}
          </span>
        </label>
      </div>
    </template>
    <div class="questionnaireEditGroupQuestions">
      <questionnaire-edit-question
        v-for="cell in template.cells" :key="cell.id"
        :model="getModel(cell.id)"
        :preview="preview"
        :template="cell"
        @questionAdded="addSectionAndForwardEvent"
        @questionRemoved="addSectionAndForwardEvent"
        @questionRequiredChanged="addSectionAndForwardEvent"
        @userDefinedChanged="addSectionAndForwardEvent"
        @userDefinedRemoved="addSectionAndForwardEvent"
      />
    </div>
  </div>
</template>

<script>
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import QuestionnaireMixin from '../mixins/QuestionnaireMixin.vue'
  import QuestionnaireEditQuestion from './QuestionnaireEditQuestion.vue'

  export default {
    name: 'rb-questionnaire-edit-section-group',

    mixins: [ RbEventsMixin, QuestionnaireMixin ],

    components: { QuestionnaireEditQuestion },

    props: [ 'model', 'preview', 'template'],

    computed: {
      modelCells () { return this.template.cells },

      isSelected () {
        return !this.model || this.model.cells.length === 0 ? 'unchecked'
          : this.model.cells.length !== this.template.cells.length ? 'indeterminate' : 'checked'
      }
    },

    methods: {
      onChange (value) {
        this.$rbEmit({
          name: value === 'checked' ? 'sectionRemoved' : 'sectionAdded',
          sectionId: this.template.id,
          sectionTemplate: this.template
        })
      },

      addSectionAndForwardEvent ( event ) {
        this.$rbEmit( event, {
          sectionId: this.template.id,
          sectionTemplate: this.template
        })
      }
    },

    mounted () { this.$emit('mounted') }
  }
</script>

<style lang="stylus">

  .questionnaireEditGroupHeader {
    background: #90a4ae;
    height: 22px;
    margin: 0;
    padding: 0 15px;
    line-height: 19px;
  }

  .questionnaireEditGroupHeaderText {
    color: #fff;
    font-size: 12px;
    vertical-align middle
    padding 0 4px 0 24px

    &.rbCheckboxLabel {
      padding 0 4px 0 16px

      &:before {
        width: 14px;
        height: 14px;
        top: 5px;
        border-radius: 2px;
      }

      &:after {
        width: 9px;
        top: 11px;
        left: 3px;
        border-color #90a4ae
      }
    }
  }

  .questionnaireEditGroupQuestions {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px 15px;

    &:after {
      content: '';
      flex: 100000 0 1%;
    }
  }

</style>
