<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <img
        v-if="user.profilePicture"
        :src="logo"
        alt="User Image"
        :class="$style.logo"
      >
      <div
        v-else
        :class="$style.logo"
      >
        <i class="material-icons">person</i>
      </div>
      <div :class="$style.desc">
        <div :class="$style.name">
          {{ user.fullName }}
        </div>
        <div
          :class="$style.role"
        >
          <i class="material-icons active">account_circle</i>
          {{ userType && userType.label }}
        </div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      <div :class="$style.companies">
        <div
          v-for="type in types"
          :key="type.value"
          :class="{[$style.company]: true, [ $style.selected ]: active(type)}"
          @click="select(type)"
        >
          <radio
            :option="type.value"
            :value="role"
            :class="$style.radio"
          />
          <div :class="$style.name">
            {{ type.label }}
          </div>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
        :disabled="!canSave"
      >
        <i class="material-icons">account_circle</i>
        Apply Changes
      </button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import {ACCOUNT_ADMIN, ACCOUNT_MANAGER} from '../services/account-manage-users-list.service';
  import Radio from 'vRoot/_core/RbRadio.vue';
  import {isEqual} from 'lodash';

  export default {
    name: 'ManageUserAssignmentsDialog',
    components: { ModalForm, Radio },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      user: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        role: this.user.role,
        types: [
          {
            value: ACCOUNT_ADMIN,
            label: 'Administrator'
          },
          {
            value: ACCOUNT_MANAGER,
            label: 'Account Manager'
          }
        ]
      }
    },
    computed: {
      logo() { return this.user.profilePicture? `/images/users/${this.user.profilePicture}`: '/images/user-image.png'},
      canSave() { return !isEqual(this.user.role, this.role);  },
      userType() {
        return this.types.find(type => type.value === this.user.role);
      }
    },
    methods: {
      onSubmit() {
        return { role: this.role };
      },
      active(type) {
        return isEqual(type.value, this.role)
      },
      select(type) {
        this.role = type.value;
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    overflow visible;
    width: 500px;
  }

  .header{
    display flex;
  }

  .header .logo{
    height: 64px;
    width: 64px;
    object-fit: contain;
    border-radius 3px;
    margin-right 10px;
    border 1px solid #ECEFF1;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing border-box;
  }

  .header .logo :global i.material-icons{
    font-size 50px;
  }

  .desc{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .desc .name{
    font-size 15px;
  }

  .role{
    font-size 12px;
    font-weight normal;
    color #78909C;
    display: flex;
    align-items: center;
  }
  .companies{
    max-height 400px;
    overflow-y auto;
    border: 1px solid #CFD8DC;
    color #90A4AE;
  }

  .company{
    padding 10px 5px;
    height 42px;
    border-bottom 1px solid #CFD8DC;
    display flex;
    align-items center;
    font-size 12px;
    cursor pointer;
    box-sizing border-box;
  }

  .company .name{
    flex 1;
  }

  .company .radio :global i.material-icons{
    font-size 18px;
    height unset;
  }

  .company.selected{
    color: #37474F;
  }
</style>
