/**
 * Created by DejanK on 8/17/2016.
 */
import '../../../ui-ng/notifications/dialogs/dialogs.scss'
import '../../../ui-ng/notifications/dialogs/notifications.scss'

import HTML from './small-success.html'
const NAME = 'smallSuccess'

export { NAME, HTML, SmallSuccessController as Controller }

SmallSuccessController.$inject = ['rbDialog']
function SmallSuccessController(rbDialog) {
  this.cancel = rbDialog.cancel;
}
