/**
 * Created by DejanK on 4/4/2017.
 */
import './preview-destinations.scss';
import { isNil, orderBy } from 'lodash';
import HTML from './preview-destinations.html';

const NAME = 'previewTravelDestinations'

export { NAME, HTML, RfpDestinationsPreviewController as Controller}

RfpDestinationsPreviewController.$inject = ['NotificationUtils', 'RfpDestinationsViewService', 'rbDialog'];
function RfpDestinationsPreviewController(notificationUtils, DestinationsViewService, dialog) {
    const vm = this, ASCENDING = 'asc', DESCENDING = 'desc';

    vm.showProperties = showProperties;
    vm.onDestinationSortChanged = onDestinationSortChanged;
    vm.onPropertySortChanged = onPropertySortChanged;
    vm.printEstimatedRoomNights = printEstimated;
    vm.printEstimatedSpend = printEstimated;
    vm.cancel = dialog.cancel;

    $onInit();

    function $onInit() {
        loadDestinations();
        vm.destinationSort = { id: ASCENDING };
        vm.propertySort = { id: ASCENDING };
    }

    function loadDestinations() {
        return notificationUtils
            .onLoad(() => DestinationsViewService.getRfpTravelDestinations(vm.rfpId), vm, 'destinations');
    }

    function showProperties(destinationId) {
        if (vm.destination === destinationId) {
            vm.destination = null;
        } else {
            vm.destination = destinationId;
            vm.propertySort = {};
            notificationUtils
                .onLoad(() => DestinationsViewService.listDestinationProperties(vm.rfpId, destinationId), vm, 'properties');
        }
    }

    function onDestinationSortChanged(key) {
        if (vm.destinationSort[key] === ASCENDING) {
            vm.destinationSort[key] = DESCENDING;
        } else {
            vm.destinationSort = {};
            vm.destinationSort[key] = ASCENDING;
        }

        vm.destinations.data = orderBy(vm.destinations.data, [key], [vm.destinationSort[key]]);
    }

    function onPropertySortChanged(key) {
        if (vm.propertySort[key] === ASCENDING) {
            vm.propertySort[key] = DESCENDING;
        } else {
            vm.propertySort = {};
            vm.propertySort[key] = ASCENDING;
        }

        vm.properties.data = orderBy(vm.properties.data, [key], [vm.propertySort[key]]);
    }

    function printEstimated(estimatedValue) {
        return isNil(estimatedValue) ? 'N/A' : estimatedValue;
    }
}
