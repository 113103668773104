<template>
  <layout-container>
    <template slot="left">
      <tooltip-container v-if="isRequestAccepted && areValuesPristine" boundary="window">
        <rb-button-with-material-icon @click="acceptOffer">
          <template slot="icon">check_circle</template>
          Finalize Negotiations
        </rb-button-with-material-icon>

        <span slot="tooltip">Finalize negotiations</span>
      </tooltip-container>

      <tooltip-container v-if="!isRequestAccepted && isNotInitialRequest && areValuesPristine" boundary="window">
        <rb-button-with-material-icon @click="acceptOffer">
          <template slot="icon">check_circle</template>
          Accept Supplier Offer
        </rb-button-with-material-icon>

        <span slot="tooltip">Finalize negotiations by accepting the last supplier offer</span>
      </tooltip-container>

      <the-negotiations-form-button-finalize-with-entered v-if="areValuesDirty" @click="finalizeWithModel"/>
    </template>

    <rbButton @click="cancel">Cancel</rbButton>

    <rb-button-with-material-icon :disabled="areValuesPristine" @click="submit" type="main">
      <template slot="icon">send</template>
      Submit Negotiation Request
    </rb-button-with-material-icon>
  </layout-container>
</template>

<script>
  import TheNegotiationsFormButtonsMixin from './TheNegotiationsFormButtonsMixin.vue';
  import TheNegotiationsFormButtonFinalizeWithEntered from './TheNegotiationsFormButtonFinalizeWithEntered.vue';

  export default {

    name: 'negotiations-form-buttons-for-buyer-creating-request',

    mixins: [ TheNegotiationsFormButtonsMixin ],

    components: { TheNegotiationsFormButtonFinalizeWithEntered }
  };
</script>
