/**
 * Created by DejanK on 10/02/2017.
 */
import noop from 'lodash/noop';
import HTML from './rate-loading-information.html';
import './rate-loading-information.scss';
const NAME = 'editRateLoadingInformation';

export { NAME, HTML, EditRateLoadingInformationController as Controller };

EditRateLoadingInformationController.$inject = ['NotificationUtils', 'CurrentUser', 'rbDialog', 'RateLoadingInformationRepository'];
function EditRateLoadingInformationController(notificationUtils, currentUser, dialog, rateLoadingInformationRepository){
  const vm = this;
  let entityId;

  vm.submit = submit;
  vm.cancel = dialog.cancel;

  $onInit();

  function $onInit(){
    vm.rateLoadingInformation = 'loading';
    currentUser.getActiveUserAccount()
      .then( userAccount => {
        entityId = userAccount.getEntityId();
        return notificationUtils.onLoad(
          () => rateLoadingInformationRepository.get(entityId),
          vm,
          'rateLoadingInformation'
        );
      })
      .catch( noop )
  }

  function submit($event){
    notificationUtils.onSave( () => rateLoadingInformationRepository.put(entityId, $event.model))
      .then(dialog.hide)
      .catch( noop )
  }
}
