/**
 * Created by DejanK on 7/10/2017.
 */

'use strict';

module.exports = createRbFixedOnScrollModule();

function createRbFixedOnScrollModule(){
  let rbFixedOnScrollModule = angular.module('rb.rb-fixed-on-scroll', []);

  rbFixedOnScrollModule
    .directive('rbFixedOnScrollContainer', require('./rb-fixed-on-scroll-container.dir'))
    .directive('rbFixedOnScrollY', require('./rb-fixed-on-scroll-y.dir'))
    .directive('rbOpacityOnScrollY', require('./rb-opacity-on-scroll-y.dir'))
  ;

  return rbFixedOnScrollModule;
}
