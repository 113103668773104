/**
 * Created by stefan on 9/5/2016.
 */

'use strict';

module.exports = {
  template: require('./rb-error.html'),
  bindings: {
    error: '<'
  },
  controller: ctrl
};

ctrl.$inject = [];
function ctrl(){
  const vm = this;
  vm.defaultLocation = '/images/exclamation.png';
}
