<template>
  <div :class="$style.container">
    <rbv-dropdown :is-open="isOpen" :backdrop-style="'transparent'" @close="toggle">
      <div slot="toggler">
        <button class="rbButton" :class="$style.toggler" @click="toggle">
          <i class="material-icons">save</i>
          <span>Save Filters</span>
          <i class="material-icons" :class="isOpen ? $style.dropdownOpenArrow : $style.dropdownArrow">arrow_drop_down</i>
        </button>
        <div class="rbTooltip simple">Set current Filters as default</div>
      </div>
      <div slot="dropdown" :class="$style.dropdownContainer" @click="toggle">
        <button class="rbButtonMenuItem"
                @click="$rbEmit({ name: 'save'})">To Current Travel Destination</button>
        <button class="rbButtonMenuItem"
                @click="$rbEmit({ name: 'setDefaultFilters'})">To All Travel Destinations in RFP</button>
      </div>
    </rbv-dropdown>
  </div>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';

  export default {
    name: 'rbv-travel-destinations-map-filter-bar-action-buttons-save-button',
    components: {RbvDropdown},
    mixins: [rbEventsMixin],
    data(){
      return {
        isOpen: false
      }
    },
    methods: {
      toggle(){
        this.isOpen = !this.isOpen;
      }
    }
  }
</script>

<style lang="stylus" module >
  .container {
    margin-right 15px
    display inline-block
  }

  .toggler {
    padding: 0 10px 0 24px
  }

  .dropdownArrow {
    color #78909C
  }

  .dropdownOpenArrow {
    composes dropdownArrow
    transform rotateX(180deg)
  }

  .dropdownContainer {
    background: #FFFFFF;
    border: 1px solid #CFD8DC;
    margin-top: 4px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow : 0 0 5px 1px rgba(0, 0, 0, 0.18);
  }
</style>
