/**
 * Created by DejanK on 8/3/2017.
 *
 * rbHasUppercase
 *
 * Validates that input has uppercase character
 */

'use strict';

module.exports = rbHasUppercase;

function rbHasUppercase() {
  return {
    require: 'ngModel',
    link: (s, e, attrs, ngModelCtrl)=>{
      ngModelCtrl.$validators.rbHasUppercase = (modelValue, viewValue)=>{
        let pattern = /(?=\S*?[A-Z])/;
        return pattern.test(modelValue || viewValue);
      }
    }
  }
}
