/**
 * Created by DejanK on 7/10/2017
 *
 */

'use strict';

let lodash = require("lodash");

module.exports = rbFixedOnScrollY;

function rbFixedOnScrollY(){
  return {
    restrict: 'A',
    require: '^rbFixedOnScrollContainer',
    //scope: {
    //  maskBackground: '@',
    //  rbFixedTableHeader: '@'
    //},
    controller: Ctrl,
    scope: true
    //controllerAs: '$ctrl',
    //bindToController: true
  }
}

Ctrl.$inject = ['$element', '$scope'];
function Ctrl($element, $scope){
  let elementHeight = 112, offset = 44, lastIsFocused;

  $scope.$on('RB_SCROLL_EVENT', (event, data)=>{
    let topDiff = data.element.getBoundingClientRect().top - $element[0].getBoundingClientRect().top + offset,
      isFocused = 20 > topDiff && topDiff > -92;

    if(isFocused !== lastIsFocused){
      lastIsFocused = isFocused;
      isFocused ? $element.removeClass('blurred'): $element.addClass('blurred');
      $scope.$broadcast('RB_AMENITIES_FOCUSED', {isFocused: isFocused});
    }
  });
}
