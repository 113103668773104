
import HTML from './ask-to-confirm-rfp-contract.html'

const NAME = 'askToConfirmRfpContract'

export { NAME, HTML, AskToConfirmRfpContract as Controller }

AskToConfirmRfpContract.$inject = ['rbDialog'];
function AskToConfirmRfpContract(dialog){
    const vm = this

    vm.confirm = false
    vm.error = false
    vm.submit = submit
    vm.cancel = dialog.cancel

    function submit(){
        if(vm.confirm){
            dialog.hide()
        } else {
            vm.error = true
        }
    }
}
