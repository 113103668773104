/**
 * Created by DejanK on 12/27/2017.
 */
import { isNumber } from 'lodash'
import HTML from './rb-progress-bar.html'
import './rb-progress-bar.scss'

export default {
  template: HTML,
  bindings: {
    label: '<',
    progress: '<'
  },
  controller: Controller
}

function Controller(){
  const vm = this
  vm.formattedProgress = '0%'

  vm.$onChanges = ( changes ) => {
    if(changes.progress){
      vm.formattedProgress = isNumber(vm.progress) && !isNaN(vm.progress) ? `${Math.round(vm.progress)}%` : '0%'
    }
  }
}
