<template>
  <rb-modal @cancel="cancel()" @hide="confirmed && hide()" :options="modalOptions">
    <span slot="header">{{headerText}}</span>
    <div slot="body">
      <p>{{messageText}}</p>

      <p v-if="confirmationText">
        <rb-checkbox-button @input="setConfirmed" :value="confirmed" check-box-value="1"/>
        {{confirmationText}}
      </p>
    </div>
  </rb-modal>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import RbCheckboxButton from 'vRoot/_core/RbCheckboxButton.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-question-mixin',
    mixins: [ DialogMixin, RbEventsMixin ],
    components: { RbModal, RbCheckboxButton },
    data () {
      return {
        confirmed: false,
        modalOptions: { labels: { buttons: { apply: 'Confirm' } } }
      }
    },
    methods: {
      setConfirmed (event){ this.confirmed = event }
    }
  }
</script>
