<template>
  <layout-container>

    <template slot="left">
      <the-negotiations-form-button-accept-buyer-request v-if="canAcceptBuyerRequest" @click="acceptRequest" />
    </template>

    <rbButton @click="cancel">Cancel</rbButton>

    <rb-button-with-material-icon :disabled="isInputPristine" @click="update" type="main">
      <template slot="icon">send</template>
      Resubmit Counteroffer
    </rb-button-with-material-icon>

  </layout-container>
</template>

<script>
  import TheNegotiationsFormButtonsMixin from './TheNegotiationsFormButtonsMixin.vue';
  import TheNegotiationsFormButtonAcceptBuyerRequest from './TheNegotiationsFormButtonAcceptBuyerRequest.vue';

  export default {

    name: 'negotiations-form-buttons-for-supplier-editing-response',

    mixins: [ TheNegotiationsFormButtonsMixin ],

    components: { TheNegotiationsFormButtonAcceptBuyerRequest }
  };
</script>
