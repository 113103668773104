import {noop, get} from 'lodash'
import {stopEvent} from 'root/shared/tools/view-utils'
import HTML from './rb-rfp-side-menu.html'
import {Dialog as VueDialog} from 'root/v-app/rbServices';
import NotificationDialog from 'vRoot/_core/notifications/NotificationDialog.vue';
import rfpStatuses from 'root/rfp-hotel/rfp-statuses';

export default {
  bindings: {
    'specifications': '<',
    'rfpStatus': '<',
    'rfpId': '<'
  },
  template: HTML,
  controller: Ctrl
}

Ctrl.$inject = ['RfpHotelRfpRepository', '$state', 'MainAPI', 'NotificationUtils'];
function Ctrl(rfpHotelRfpRepository, $state, mainAPI, utils){

  Object.assign(
    this,
    {

      isQuestionnaire: false,
      isNamLetter: false,
      showLetterMenu: false,

      $onInit () {
        this.isQuestionnaire = $state.current.name === 'hotelRfp.rfp.questionnaire.view';
        this.isNamLetter = $state.current.name === 'hotelRfp.rfp.namCoverLetter.view';
      },

      $onChanges ( changes ){
        if(changes.specifications){
          const chainSupport = get(this.specifications, 'chainSupport', false);
          this.showLetterMenu = chainSupport
            && ['hotelRfp.rfp.coverLetter.view', 'hotelRfp.rfp.namCoverLetter.view'].indexOf($state.current.name) !== -1;
        }
      },

      isRfpPending () {
        return this.rfpStatus === rfpStatuses.RFP_PENDING;
      },


      onChange(editorName, shouldSave, saveFn, options ){
        this[editorName] = 0;

        if(shouldSave) {
          utils.onSave(saveFn, options)
            .then(() => {
              return options.landingPage ? $state.go(options.landingPage, {}, {reload: true}) : $state.reload();
            })
            .catch(noop);
        }
      },

      openEditor(event, editorName){
        stopEvent(event);
        this[editorName] = 1;
      },

      onDueDateChanged($event){
        this.onChange('editDueDate',
          $event.apply && this.specifications.dueDate !== $event.dueDate,
          () => rfpHotelRfpRepository.updateRfpDueDate(this.rfpId, $event.dueDate))
      },

      onProgramPeriodChanged($event){
        this.onChange('editProgramPeriod',
          $event.apply && (this.specifications.programStartDate !== $event.programStartDate || this.specifications.programEndDate !== $event.programEndDate),
          () => rfpHotelRfpRepository.updateRfpProgramPeriod(this.rfpId, $event.programStartDate, $event.programEndDate),
          { customErrorHandlers: {
              '409' () {
                return VueDialog.show(NotificationDialog, {
                  title: 'Failed!',
                  subTitle: 'Program Period Update',
                  message: 'RFP has been modified on the server and the program period can no longer be changed.',
                });
              }
            }
          });
      },

      onRfpNameChanged($event){
        this.onChange('editRfpName',
          $event.apply && this.specifications.name !== $event.rfpName,
          () => rfpHotelRfpRepository.updateRfpName(this.rfpId, $event.rfpName))
      },

      onChainSupportChanged($event){
        this.onChange('editChainSupport',
          $event.apply && $event.chainSupport,
          () => mainAPI.enableChainSupportOnRfp(this.rfpId),
          { landingPage: 'hotelRfp.rfp.coverLetter.view'}
        );
      },
    }
  );
}
