<template>
  <div class="rfp-manager-main">
    <div class="bm-header layout-row">
      <rfp-actions
        :accounts="accounts"
        :selected="selectedRfpsList"
        @actionFired="actionFiredCallback"
      />
      <div class="right">
        <drop-down-actions
          :value="groupBy"
          :items="validGroupers"
          @input="resetFilter"
        >
          <template slot="label">
            Group By:
          </template>
          <template slot="value">
            {{ groupBy.label }}
          </template>
        </drop-down-actions>
        <drop-down-actions
          v-if="groupByKey"
          v-model="filterBy"
          :items="filters"
        >
          <template slot="label">
            Filter By:
          </template>
          <template slot="value">
            {{ filterBy.label }}
          </template>
        </drop-down-actions>
        <drop-down-actions
          :value="{}"
          :items="moreOptions"
          @input="applyMoreOptionsAction"
        >
          <template slot="label">
            <label>&nbsp;</label>
          </template>
          <template slot="value">
            <i class="material-icons">tune</i>
            Options
          </template>
        </drop-down-actions>
        <create-rfp-drop-down
          :accounts="accountsWithRfpsCount"
          @createRfp="createRfp"
        />
      </div>
    </div>
    <div
      v-if="!loaded"
      class="layout-column flex-center"
    >
      <rbv-loading label="Loading, please wait..." />
    </div>
    <rfp-manager-table
      v-else
      :rfps="groupedAndFiltered"
      :all-rfps="sortedAndGrouped"
      :accounts="accounts"
      :selected-rfps="selectedRfps"
      :all-selected="allRfpsAdded"
      :sorter="sorter"
      :group-by="groupBy"
      :is-company="isCompany"
      @select="select"
      @selectAll="selectAll()"
      @actionFired="actionFiredCallback"
      @sort="sortTableBy"
      @createRFP="createRfp"
      @assignTo="assignTo"
      @updateLaunchDate="updateLaunchDate"
    />
  </div>
</template>

<script>
  import {noop} from 'lodash';
  import moment from 'moment';

  import rfpActions from './core/actions.vue';
  import dropDownActions from './core/rfp-manager-dropdown-actions.vue';
  import rfpManagerTable from './components/rfp-manager-table.vue';
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';
  import createRfpDropDown from './components/createRfpDropDown.vue';

  import rfpManagerTableService, {
    ASSIGNEE_NAME_GROUPER_ID,
    COMPANY_NAME_GROUPER_ID,
    RFP_LAUNCH_MONTH_GROUPER_ID,
    RFP_STATUS_GROUPER_ID
  } from './services/rfp-manager-table.service';

  import listRfpsService from './services/rfp-manager-list.service';
  import {ACCOUNT_CREATE_RFP} from './services/rfp-manager-actions-factory';
  import rfpManagerActionsService from './services/rfp-manager-actions.service';
  import accountsManagerService, {
    TRAVEL_AGENCY_TYPE
  } from 'vRoot/account-profile/modules/manage-companies/services/manage-company.service';
  import manageAccountsService from 'vRoot/account-profile/modules/manage-users/services/account-manage-users-list.service';
  import {PAGE_RFP_MANAGER_COMPANY_PROFILE} from 'root/states';
  import {getStatusValue} from 'vRoot/rfp-manager/services/rfp-manager-table-grouper-service';


  export default {
    name: 'Manager',
    components: {
      rfpActions,
      dropDownActions,
      rfpManagerTable,
      RbvLoading,
      createRfpDropDown,
    },
    props: {
      user: {
        type: Object,
        required: true
      },
      accounts: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        selectedRfps: [],
        sorter: {
          by: null,
          direction: 'asc'
        },
        groupers: [
          {
            id: '',
            label: 'None',
            included: () => true,
          },
          {
            id: COMPANY_NAME_GROUPER_ID,
            label: 'Company Name',
            key: 'rfp.specifications.buyer.company.name',
            filter(value, {rfp}){ return rfp.specifications.buyer.company.accountId === value },
            included: () => !this.isCompany,
          },
          {
            id: ASSIGNEE_NAME_GROUPER_ID,
            label: 'Assigned To',
            key: 'rfp.assignee.fullName',
            filter(value, {rfp}){ return value? rfp.assignee && rfp.assignee.id === value: !rfp.assignee },
            included: () => !this.isCompany && this.isAdmin,
          },
          {
            id: RFP_STATUS_GROUPER_ID,
            label: 'RFP Status',
            key: ({rfp}) => getStatusValue(rfp),
            filter(value, {rfp}){ return value === getStatusValue(rfp) },
            included: () => true,
          },
          {
            id: RFP_LAUNCH_MONTH_GROUPER_ID,
            label: 'RFP Launch Month',
            key: ({rfp}) => rfp.specifications.launchDate ? moment(rfp.specifications.launchDate).format('MMMM YYYY'): 'N/A',
            filter(value, {rfp}){ return value === (rfp.specifications.launchDate ? moment(rfp.specifications.launchDate).format('MMMM YYYY'): 'N/A'); },
            included: () => true,
          },
        ],
        groupByKey: this.accounts.length > 0? COMPANY_NAME_GROUPER_ID: RFP_STATUS_GROUPER_ID,
        filterBy: {id: '', label: 'None'},
        showArchivedRFPs: false
      }
    },
    computed: {
      groupBy: {
        get() {
          return this.groupers.find(grouper => grouper.id === this.groupByKey);
        },
        set(groupBy) {
          this.groupByKey = groupBy.id;
        },
      },
      loaded() {
        return this.accounts && this.user && this.rfps && this.users;
      },
      accountsWithRfpsCount() {
        return this.rfps ?this.accounts.filter(({permissions}) => permissions.indexOf(ACCOUNT_CREATE_RFP) > -1)
          .map(({account}) => account): [];
      },
      allRfpsAdded() {
        return rfpManagerTableService.allSelected(this.groupedAndFiltered, this.selectedRfpsList, this.groupBy);
      },
      filters() {
        return rfpManagerTableService.generateFilters(this.sortedAndGrouped, this.groupBy);
      },
      moreOptions() {
        const vm = this;
        const options = [
          {
            id: 'companies',
            icon: 'business',
            label: 'Customers',
            action: () => rfpManagerActionsService.openManageCompanies(),
            included: () => !this.isCompany,
          },
          {
            id: 'createCompany',
            icon: 'playlist_add',
            label: 'Add a Customer',
            action: () => this.createCompany(),
            included: () => !this.isCompany && this.isAdmin,
          },
          {
            id: 'user',
            icon: 'group',
            label: 'Users & Permissions',
            action: ()  => rfpManagerActionsService.openUsersAndPermissions(),
            included: () => true,
          },
          {
            id: 'createAgent',
            icon: 'person_add',
            label: 'Add User',
            action:()  => manageAccountsService.createAgent(this.user.currentUserAccount.account.id, this.isCompany),
            included: () => this.isAdmin,
          },
          {
            id: 'showArchivedRFPs',
            icon: vm.showArchivedRFPs? 'toggle_on': 'toggle_off',
            label: vm.showArchivedRFPs? 'Hide Archived RFPs': 'Show Archived RFPs',
            action() { vm.showArchivedRFPs = !vm.showArchivedRFPs; },
            included: () => true,
          },
        ];

        return options.filter(({included}) => included());
      },
      sortedAndGrouped() {
        const sorted = rfpManagerTableService.sortRfpsBy(this.rfps, this.sorter);
        return rfpManagerTableService.groupRfpsBy(
          sorted,
          this.groupBy,
          this.accounts,
          this.users,
          this.shouldShowArchivedRfps
        );
      },
      groupedAndFiltered() {
        return rfpManagerTableService.filterRfpsBy(this.sortedAndGrouped, this.filterers);
      },
      shouldShowArchivedRfps(){
        return this.groupBy.id === RFP_STATUS_GROUPER_ID || this.showArchivedRFPs;
      },
      filterers() {
        const filters = [];

        //search term filter
        if(this.searchTerm){
          const searchTerm = this.searchTerm.toLowerCase();
          filters.push({
            id: 'search-term',
            compare({type, title, rfp}) {
              let valid = false;
              if(type !== 'header'){
                valid = valid || rfp.specifications.buyer.company.name.toLowerCase().indexOf(searchTerm) > -1;
                valid = valid || rfp.specifications.name.toLowerCase().indexOf(searchTerm) > -1;
                // add assigned to
                return valid;
              }else{
                return title.toLowerCase().indexOf(searchTerm) > -1;
              }
            }
          });
        }

        //filter by
        if(this.filterBy.id) {
          const value = this.filterBy.value;
          const groupById = this.groupBy.id;
          const groupByFilter = this.groupBy.filter;
          filters.push({
            id: this.filterBy.id,
            compare(rfp) {
              if(rfp.type === 'header') {
                return rfp.headerType === groupById? rfp.key === value: rfp.headerKey === value;
              }else{
                return groupByFilter(value, rfp)
              }
            }
          });
        }

        return filters;
      },
      selectedRfpsList() {
        return this.selectedRfps.filter(rfp => this.rfpIsIncluded(rfp));
      },
      account() {
        return this.user && this.user.currentUserAccount && this.user.currentUserAccount.account;
      },
      isCompany() {
        return this.account && this.account.type !== TRAVEL_AGENCY_TYPE;
      },
      validGroupers() {
        return this.groupers.filter(({included}) => included());
      },
      role() { return this.userAccount && this.userAccount.role && this.userAccount.role.permission; },
      isAdmin() { return this.role === 'ACCOUNT_ADMIN' },
    },
    asyncComputed: {
      userAccount() { return manageAccountsService.loadAuthorization().then(({data}) => data)},
      rfps() {
        return this.accounts.length > 0 ? listRfpsService.listRfps(this.accounts).then(rfps => rfps): Promise.resolve([]);
      },
      users() { return manageAccountsService.loadUsers().then(users => users); }
    },
    methods: {
      select({item}) {
        if(this.rfpIsIncluded(item)) {
          this.selectedRfps = rfpManagerTableService.selectRfp(item, this.sortedAndGrouped, this.selectedRfps, this.groupBy);
        }
      },
      selectAll() {
        this.selectedRfps = this.allRfpsAdded? []: rfpManagerTableService.selectAll(this.groupedAndFiltered, this.groupBy);
      },
      rfpIsIncluded(item) {
        return !!this.groupedAndFiltered.find(rfp => {
          if(item.type ==='header') {
            return rfp.title && rfp.title === item.title;
          }else{
            return rfp.rfp && rfp.rfp.id === item.rfp.id;
          }
        });
      },
      actionFiredCallback({id, data}) {
       switch (id) {
         case 'markAsComplete':
         case 'archiveRfp':
         case 'unArchiveRfp': this.refreshRfps(); break;
         case 'duplicateRfp':
         case 'duplicateRfpWithRateQuick':
         case 'createRfp':this.createRfpCallback(data); break;
         default: noop(); break;
       }
      },
      createRfpCallback({action}) {
        if(action === 'createCompany') {
          this.createCompany();
        }else{
          this.refreshRfps();
        }
      },
      refreshRfps() {
        return listRfpsService.listRfps(this.accounts).then(rfps => {
          this.rfps = rfps;
          this.selectedRfps = [];
        })
      },
      resetFilter(grouper){
        if(!grouper.id || grouper.id !== this.groupBy.id) {
          this.filterBy = { id: '', label: 'None'}
        }
        this.groupBy = grouper;
      },
      applyMoreOptionsAction($event) {
        return $event.action && $event.action();
      },
      sortTableBy(by){
        if(this.sorter.by && this.sorter.by.id === by.id) {
          this.sorter.direction = this.sorter.direction === 'asc'? 'desc': 'asc';
        }else{
          this.sorter = {by, direction: 'asc'}
        }
      },
      createRfp(account = null) {
        return rfpManagerActionsService
          .createNewRfp(this.accountsWithRfpsCount, account, this.isCompany, this.account.id)
          .then(({action}) => {
            if(action === 'createCompany') {
              this.createCompany();
            }else{
              this.refreshRfps();
            }
          });
      },
      createCompany() {
        return accountsManagerService.createCompany(this.accounts.map(({account}) => account), PAGE_RFP_MANAGER_COMPANY_PROFILE).then(() => this.refreshAccounts());
      },
      refreshAccounts() {
        this.$emit('refreshAccounts');
      },
      assignTo(accountId) {
        const selectedAccount = this.accounts.find(({account}) => accountId === account.id);
        accountsManagerService.manageAssignments(this.user.currentUserAccount.account.id, selectedAccount.account).then(this.refreshUsers);
      },
      refreshUsers() {
        this.refreshAccounts();
        this.$asyncComputed.users.update();
        return this.$asyncComputed.rfps.update();
      },
      updateLaunchDate(data) {
        return listRfpsService.updateLaunchDate(data.id, data.time).then(this.refreshRfps);
      }
    }
  }
</script>

<style lang="stylus">
  .rfp-manager-main{
    .bm-header{
      position relative;

      .search-box{
        position absolute;
        top 10px;
        right 0;
        border-top-left-radius 10px;
        border-bottom-left-radius 10px;
        height: 27px;
        width: 73px;
        overflow: hidden;
        transition: width .3s;

        &.focused{
          width 200px;
        }
      }

      .toolbox.flex-hold {
        width unset;

        .toolbox-content{
          line-height unset;
        }
      }

      .right{
        display flex;
        flex 1;
        justify-content flex-end;
      }

      .archive-toggle, .user-button{
        white-space: nowrap;
        background: #26323800;
        border: 1px solid #16272b;
        box-sizing: border-box;
        cursor: pointer;
        color: #90A4AE;
        font-size: 12px;
        padding: 0 10px;
        margin-right 0;
      }

      .user-button{
        display flex;
        align-items center;

        i.material-icons{
          margin-right 10px;
          font-size 20px;
        }
      }
    }

    .rfp-manager-select{
      min-width 120px;
    }
  }
</style>
