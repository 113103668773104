import HTML from './create-new-supplier-contact.html';
import './create-new-supplier-contact.scss';
import {noop} from 'lodash';
import { NAME as dueDateExpiredNotification } from 'root/rfp/bid-manager/main/dialogs/due-date-expired/due-date-expired.ctrl'
import {ID_OF_NEW, TYPE_HOTEL} from 'rfp/bid-manager/main/dialogs/set-supplier-contact/set-supplier-contact.ctrl';

const NAME = 'createNewSupplierContact';
export { NAME, HTML, CreateNewSupplierContactCtrl as Controller }

CreateNewSupplierContactCtrl.$inject = ['NotificationUtils', 'Contact', 'Bids', 'rbDialog', 'MainAPI'];
function CreateNewSupplierContactCtrl(notificationUtils, contact, bids, dialog, mainAPI) {

  const vm = this;

  vm.cancel = dialog.cancel;
  vm.save = saveSelected;
  vm.saveAndSend = saveAndSend;

  onInit();

  function onInit() {
    vm.isCreating = contact.id === ID_OF_NEW;
    vm.contact = contact;
    vm.accountType = contact.accountType === TYPE_HOTEL? 'Hotel Representative': 'Chain Representative';
  }

  function saveSelected(selected){
    return (bids.length === 1 ? saveToBid(bids[0]) : saveToBids(bids)).then(dialog.hide);


    function saveToBid(bid){
      const bidId = bid.$bidId;
      return mainAPI.createBidSupplierContact(bidId, selected)
    }

    function saveToBids(){
      const bidsIds = bids.map(b => b.$bidId);
      return mainAPI.createSupplierContactInBids(bidsIds, selected)
    }
  }

  function saveAndSend(){
    sendBidsToSelected(bids.map( b => b.$bidId))
      .then(dialog.hide)
      .catch(noop)
  }

  function sendBidsToSelected(bidsIds, ignoreDueDate){
    const actionFn = (['CREATED', 'SENT', 'RECEIVED'].indexOf(bidsStatus()) > -1? sendBidsToSupplier(): changeSupplier());

    function changeSupplier() {
      return contact.isUser?
            () => mainAPI.sendBidsChangeToSelectedContact(bidsIds, contact.id, !!ignoreDueDate):
            () => mainAPI.sendBidsChangeToNewContact(bidsIds, contact, !!ignoreDueDate);
    }

    function sendBidsToSupplier() {
      return contact.isUser?
            () => mainAPI.sendBidsToSelectedContact(bidsIds, contact.id, !!ignoreDueDate):
            () => mainAPI.sendBidsToNewContact(bidsIds, contact, !!ignoreDueDate);
    }

    function bidsStatus() {
      return bids[bids.length - 1].$status;
    }

    return notificationUtils.onSave( actionFn, {
      customErrorHandlers: {
        400(error) {
          if (error.data && error.data.id === 'DUE_DATE_EXPIRED') {
            return dialog.show(dueDateExpiredNotification, {locals: {expiredDueDate: error.data.value}})
              .then(() => sendBidsToSelected(bidsIds, contact, true))
          } else {
            return notificationUtils.handleErrors(error)
          }
        }
      }
    })
  }
}
