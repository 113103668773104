<template>
  <button
    type="button"
    :class="styleButton"
    @click.prevent.stop="onClick"
  >
    <span
      :class="styles.icon"
      class="material-icons"
      aria-hidden="true"
    >
      {{ stateIcon }}
    </span>
    <span :class="styles.label"><slot /></span>
  </button>
</template>

<script>
  export default {
    name: 'RbRadio',
    props: {
      label: null,
      option: { // replaces value attribute from native HTML radio input
        required: true
      },
      value: { // model
      },
      readonly: {
        'default': false,
        'type': Boolean
      },
      customStyles: {
        'type': Object,
        'default': () => ({})
      }
    },

    computed: {
      styleButton () {
        const buttonStyle = [...this.styles.button]
        if(this.readonly) { buttonStyle.push(...this.styles.buttonReadOnly) }
        if(this.isSelected) { buttonStyle.push(...this.styles.buttonActive) }
        return buttonStyle
      },
      styles () {
        return {
          button: [ this.$style.rbRadioButton, this.customStyles.button || '' ],
          buttonReadOnly: [ this.$style.rbRadioButtonReadonly, this.customStyles.buttonReadOnly || '' ],
          buttonActive: [ this.$style.rbRadioButtonActive, this.customStyles.buttonActive || ''],
          icon: [ this.$style.rbRadioIcon, this.customStyles.icon || '' ],
          label: [ this.$style.rbRadioLabel, this.customStyles.label || '' ]
        }
      },
      isSelected () { return this.value === this.option },
      stateIcon () { return this.isSelected ? 'radio_button_checked' : 'radio_button_unchecked' }
    },
    methods: {
      onClick () { !this.readonly && this.$emit('input', this.option) }
    }
  }
</script>

<style lang="stylus" module>
  .rbRadioButton {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 2px;
    line-height: 14px;
    font-size: 10px;
    margin-right: 1px;
    padding: 1px 5px 1px 1px;
    font-weight: 400;
    color: #78909c;

    &.rbRadioButtonReadonly {
      cursor: default;

      &:hover {
        border-color: transparent;
        color #78909c;
      }
    }

    &.rbRadioButtonActive {
      cursor: default;
      color: #546E7A;

      &:hover {
        border-color: transparent;
        color #546E7A;
      }
    }
  }


  .rbRadioIcon {
    font-size: 14px;
    height: 14px;
    line-height: inherit;
    vertical-align: middle;
  }

  .rbRadioLabel {
    vertical-align: middle;
  }
</style>
