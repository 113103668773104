/**
 * Created by DejanK on 7/11/2017.
 *
 * rb-horizontal-split-pane
 */
import HTML from './rb-horizontal-split-pane.html';
import './rb-horizontal-split-pane.scss';
import angular from 'angular';

export default {
  template: HTML,
  transclude: {
    east: 'rbEastPanel',
    west: 'rbWestPanel'
  },
  bindings: {
    open: '<',
    reset: '<'
  },
  controller: Ctrl
};


Ctrl.$inject = ['$element', '$window', '$scope'];
function Ctrl($element, $window, $scope){

  const
    vm = this,
    westPanelFullSizeMargin = 100;

  let
    managerElement,
    westPanelElement;

  this.$onInit = () => {
    managerElement = angular.element($element.children()[0]);
    westPanelElement = angular.element(managerElement.children()[2]);
    angular.element($window).on('resize', onResize);
  };

  this.$onChanges = ( changes ) => {
    changes.open && managerElement && setVisibility();
  };

  this.$onDestroy = () => {
    angular.element($window).off('resize', onResize);
  };

  function setVisibility(){
    vm.open > 0 ? openPanel(vm.open) : closePanel();

    function openPanel(width){
      $scope.$timeout(() => westPanelElement.css('width', `${width + 20}px`), 300);
      onResize();
    }

    function closePanel(){
      westPanelElement.css('width', '0');
      $scope.$timeout(() => {
        managerElement.removeAttr('class');
        managerElement.addClass('full-east');
      }, managerElement.hasClass('full-west') ? 0 : 300);
    }
  }

  function onResize(){
    if(vm.open && vm.open > 1) {
      const
        westPanelWidth = vm.open + 20,
        elementWidth = $element[0].getBoundingClientRect().width;

      managerElement.removeAttr('class');
      managerElement.addClass( elementWidth - westPanelWidth < westPanelFullSizeMargin ? 'full-west' : 'split');
    }
  }
}
