/**
 * Created by DejanK on 12/17/2016.
 */
import { abstractState } from "../../shared/tools/router-helper";
import {USER} from "../../auth/auth-roles";

export default States

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state('hotelRfp', abstractState('/hotel-rfp', USER))
  ;
}
