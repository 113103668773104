import { notificationUtils, ngDialog, mainAPI } from "root/angular-injector-provider";
import {NAME as askToConfirmRfpContractDialog} from "root/rfp-hotel/main/bid/dialogs/ask-to-confirm-rfp-contract/ask-to-confirm-rfp-contract";
import {Dialog as VueDialog} from "root/v-app/rbServices";
import ForceSendingInvalidResponsesQuestion from "vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingInvalidResponsesQuestion.vue";
import ForceSendingResponseWithoutRatesQuestion from "vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingResponseWithoutRatesQuestion.vue";

export default {
  /**
   * TODO: merge questionnaire on server!!!
   * -- IF http response returns RESPONSE_NOT_VALID and form doesn't report the error we have a problem. This should be rare, but with lack of discipline and organization it is possible that questionnaire templates become desynchronized
   * ---- the only way to avoid this is to force client to load questionnaire template from server
   * ------ as a quick fix, template is loaded from server every time questionnaire needs to be displayed (either with 2 requests or with one)
   * ------- it is relatively safe to cache template on client, since it is also cached on backend
   * ------ probably best solution would be to always merge questionnaire on server. This however requires removal of merge code on client.
   */

  save (response, bidId) {
    return isResponseValid(response)
      .then(areRatesSet)
      .then(confirmThatRfpContractIsSet)
      .then(r => saveResponse(r, bidId))
      .then( report => report.validate() )
  },

  saveDraft (bidId, answers) {
    return notificationUtils().onSave(() => mainAPI().saveDraftResponse( bidId, answers ))
      .then( report => report.validate() )
  },

  reviseResponse(answers, bidId){
    return mainAPI().reviseBidResponse( bidId, answers )
  }
}

function isResponseValid(response){
  return (Object.keys(response.errors).length === 0 ? Promise.resolve(response.response) : Promise.reject(response.response))
    .catch(shouldErrorsBeIgnoredDialog)
}

function areRatesSet(response){
  const rates = ['DYNAMIC_PCT_Discount', 'LRA_S1_RT1_SGL', 'LRA_S1_RT1_DBL', 'NLRA_S1_RT1_SGL', 'NLRA_S1_RT1_DBL', 'GOVT_S1_RT1_SGL', 'GOVT_S1_RT1_DBL'],
    answers = response.answers

  return (rates.some(r => answers[r]) ? Promise.resolve(response) : Promise.reject(response))
    .catch(shouldRatesBeIgnored)
}

function confirmThatRfpContractIsSet(response){
  return (response.answers.RFP_CONTRACT === 'Y' ? Promise.resolve(response) : Promise.reject(response))
    .catch(confirmRfpContractDialog)
}

function saveResponse(response, bidId){
  const ignoreErrors = true
  return notificationUtils().onSave(() => mainAPI().setBidResponse(bidId, response.answers, ignoreErrors))
}

function shouldErrorsBeIgnoredDialog(response){
  return VueDialog.show(ForceSendingInvalidResponsesQuestion, { isBulk: false })
    .then(() => response)
}

function shouldRatesBeIgnored(response){
  return VueDialog.show(ForceSendingResponseWithoutRatesQuestion)
    .then(() => response)
}

function confirmRfpContractDialog(response){
  return ngDialog().show(askToConfirmRfpContractDialog)
    .then(() => {
      response.answers.RFP_CONTRACT = 'Y'
      return response
    })
}
