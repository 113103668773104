import { DEBUG } from 'root/config/app.config';
import { ALLOW } from 'root/v-app/HtmlSanitizer/HtmlSanitizer';

export default {
  debug: DEBUG,

  escapeUndesirableContent: true,

  allowedAttributes: {
    'id': ALLOW,
    'class': ALLOW,
    'colspan': ALLOW,
    'rowspan': ALLOW,
  },

  allowedTags: {
    'table': {},
    'thead': {},
    'tbody': {},
    'tr': {},
    'th': {},
    'td': {},
    'strong': {},
    'small': {},
    'em': {},
    'ul': {},
    'li': {},
    'h1': {},
    'h2': {},
    'h3': {},
    'h4': {},
    'h5': {},
    'h6': {},
  },

  allowedCss: [
    'background',
    'border',
    'border-collapse',
    'border-radius',
    'color',
    'display',
    'font-size',
    'height',
    'line-height',
    'margin',
    'min-width',
    'padding',
    'text-align',
    'width',
  ],

  allowedUrls: [
    'http://',
    'https://',
    '/images/logos/'
  ],

};
