/**
 * Created by DejanK on 9/25/2017.
 */
import HTML from './send-final-agreement.html';
import 'root/rfp-hotel/main/rfp/pages/final-agreement/view-final-agreement.scss';
import autoSave from 'root/shared/tools/file-autosave';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { NAME as AskForRateLoadingInformationDialog }
  from 'root/rfp-hotel/main/bid/dialogs/ask-for-rate-loading-information/ask-for-rate-loading-information.ctrl';
import SelectFinalAgreementRatesDialog from 'vRoot/rfp-hotel/final-agreement/SelectFinalAgreementRatesDialog.vue';
import {Dialog as VueDialog} from 'root/v-app/rbServices';

export { HTML, BidFinalAgreementSendController as Controller };

BidFinalAgreementSendController.$inject = ['RfpHotelBidRepository', '$state', 'NotificationUtils', 'rbDialog', '$q', 'MainAPI'];
function BidFinalAgreementSendController(rfpHotelBidRepository, $state, notificationUtils, dialog, $q, mainAPI){
  const vm = this;

  vm.exportAsPdf = exportAsPdf;
  vm.exportAsExcel = exportAsExcel;
  vm.canSend = canSend;
  vm.canDownload = canDownload;
  vm.canSelectRates = canSelectRates;
  vm.selectRates = selectRates;
  vm.canEditLetter = canEdit;
  vm.send = send;

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId;
    notificationUtils.onLoad( () => rfpHotelBidRepository.getBidPreview(vm.bidId), vm, 'bid' )
      .then(() => {
        vm.showSideMenu = get(vm.bid, 'data.specifications.subType') !== 'RATE_QUICK';
      })
      .catch( noop );
  }

  function exportAsPdf(){
    notificationUtils
      .onLoadWithNotification( () => mainAPI.exportFinalAgreementAsPdf(vm.bidId) )
      .then( response => {
        autoSave(response, 'application/pdf', `${vm.bid.data.specifications.name} Final Agreement.pdf`);
      })
      .catch( noop );
  }

  function exportAsExcel(){
    notificationUtils
      .onLoadWithNotification(
        () => mainAPI.hotelRfpExportFinalAgreementsToExcel([ vm.bidId ], true)
      )
      .catch( noop );
  }

  function canSend(){
    return isBidStateIn(['RESPONDED', 'NEGOTIATION_FINALIZED']);
  }

  function canSelectRates() {
    return isBidStateIn(['RESPONDED', 'NEGOTIATION_FINALIZED']);
  }

  function selectRates() {
    return VueDialog.show(SelectFinalAgreementRatesDialog, { bidId: vm.bidId })
      .then( () => $state.reload() )
  }

  function canEdit(){
    return isBidStateIn(['RESPONDED', 'NEGOTIATION_FINALIZED']);
  }

  function canDownload(){
    return isBidStateIn(['FINAL_AGREEMENT', 'RESPONDED', 'NEGOTIATION_FINALIZED']);
  }

  function isBidStateIn(states){
    const bidState = get(vm.bid, 'data.state.id');
    return states.indexOf(bidState) !== -1;
  }

  function send(){
    notificationUtils.onSave( () => mainAPI.sendFinalAgreement(vm.bidId) , {
      customErrorHandlers: {
        '409' (errorResponse) {
          const message = errorResponse.data.message;
          switch (message) {
            case 'NO_ACCEPTED_RATES':
              return VueDialog.show(SelectFinalAgreementRatesDialog, { bidId: vm.bidId })
                .then(send, noop);
            case 'NO_RATE_LOADING_INFORMATION':
              return dialog
                .show(AskForRateLoadingInformationDialog, {locals: {EntityId: get(vm.bid, 'data.buyer.company.entityId')}})
                .then(send, noop);
            default:
              return $q.reject(errorResponse);
          }
        }
      }})
      .then( response => {
        if(response) {
          vm.bid = response;
        }
      })
      .catch( noop );
  }
}
