/**
 * Created by DejanK on 4/11/2017.
 */
import './set-supplier-contact.scss'
import {isEqual, noop} from 'lodash'
import HTML from './set-supplier-contact.html'
import { NAME as dueDateExpiredNotification } from 'root/rfp/bid-manager/main/dialogs/due-date-expired/due-date-expired.ctrl'

import defaultImage from 'images/user-image.png'
import moment from 'moment';

const NAME = 'SetBidSupplierContact';
export const TYPE_CHAIN = 'CHAIN', TYPE_HOTEL = 'HOTEL', ID_OF_NEW = 'NEW';

export { NAME, HTML, SetBidSupplierContactDialogCtrl as Controller }

SetBidSupplierContactDialogCtrl.$inject = ['RfpHotelBidRepository', 'NotificationUtils', 'Bids', 'AccountRepository', 'rbDialog', 'MainAPI'];
function SetBidSupplierContactDialogCtrl(RfpHotelBidRepository, notificationUtils, bids, accountRepository, dialog, mainAPI){
  const vm = this;
  let allRepresentatives = null;

  vm.defaultImage = defaultImage;
  vm.configuration = {};
  vm.representatives = 'LOADING';

  vm.cancel = dialog.cancel;
  vm.save = save;
  vm.edit = edit;
  vm.saveAndSend = saveAndSend;
  vm.createNewContact = createNewContact;

  vm.getSaveAndSendLabel = getSaveAndSendLabel;
  vm.isNew = user => user.id === ID_OF_NEW;
  vm.supplierHasChanged = () => !same(vm.configuration.supplier[vm.active.type], vm.configuration.selected[vm.active.type]);
  vm.isSupplierSet = isSupplierSet;
  vm.bids = bids;

  onInit();

  function onInit(){
    const report = loadBids(bids);
    vm.configuration = loadConfiguration(report);
    loadRepresentatives()
  }

  function loadRepresentatives(){
    const hotelId = vm.configuration.hotelId, chainId = vm.configuration.chainId,
      loadRepresentativesFn = hotelId ? () => mainAPI.listHotelContacts(hotelId) : () => mainAPI.listChainContacts(chainId);

    notificationUtils.onLoad(loadRepresentativesFn)
      .then( response => parseLoadRepresentativesResponse(response, vm.configuration.selected) )
      .then( representatives => {
        allRepresentatives = representatives;
        return vm.configuration.tabs.init === TYPE_CHAIN ? vm.toChain() : vm.toHotel()
      } )
  }

  vm.select = function(user){
    vm.configuration.selected[vm.active.type] = user
  };

  vm.isSelected = function(user){
    const selected = vm.selected(vm.active.type);
    return selected && selected.id === user.id
  };

  vm.selected = function(type) {
    return vm.configuration.selected[type]
  };

  vm.toHotel = function toHotel(){
    vm.active = {
      type: TYPE_HOTEL,
      hotelClass: 'tab active',
      chainClass: 'tab',
      header: 'Hotel',
      hotelRadioIcon: 'radio_button_checked',
      chainRadioIcon: 'radio_button_unchecked',
    };

    vm.representatives = filterRepresentatives(allRepresentatives, TYPE_HOTEL)
  };

  vm.toChain = function toChain(){
    vm.active = {
      type: TYPE_CHAIN,
      hotelClass: 'tab',
      chainClass: 'tab active',
      header: 'Chain',
      message: true,
      hotelRadioIcon: 'radio_button_unchecked',
      chainRadioIcon: 'radio_button_checked',
    };

    vm.representatives = filterRepresentatives(allRepresentatives, TYPE_CHAIN)
  };

  function save(){
    if(isSupplierSet()){
      notificationUtils.onSave(() => saveSelected(bids, vm.configuration.selected[vm.active.type]))
        .then(dialog.hide)
    }
  }

  function same(original, selected) {
    return original && selected && original.id === selected.id;
  }

  function saveSelected(bids, selected){
    return bids.length === 1 ? saveToBid(bids[0], selected) : saveToBids(bids, selected);


    function saveToBid(bid, selected){
      const bidId = bid.$bidId;
      return selected.isUser
        ? mainAPI.setBidSupplierContact(bidId, selected.id)
        : mainAPI.createBidSupplierContact(bidId, selected)
    }

    function saveToBids(bids, selected){
      const bidsIds = bids.map(b => b.$bidId);
      return selected.isUser
        ? mainAPI.setSupplierContactToBids(bidsIds, selected.id)
        : mainAPI.createSupplierContactInBids(bidsIds, selected)
    }
  }

  function isSupplierSet(){
    return vm.configuration.selected[vm.active.type]
  }

  function saveAndSend(){
    if(isSupplierSet()){
      sendBidsToSelected(bids.map( b => b.$bidId), vm.configuration.selected[vm.active.type])
        .then(dialog.hide)
        .catch(noop)
    }
  }

  function sendBidsToSelected(bidsIds, contact, ignoreDueDate){

    const actionFn = (['CREATED', 'SENT', 'RECEIVED'].indexOf(vm.configuration.status) > -1? sendBidsToSupplier(): changeSupplier());

    function changeSupplier() {
      return contact.isUser?
        () => mainAPI.sendBidsChangeToSelectedContact(bidsIds, contact.id, !!ignoreDueDate):
        () => mainAPI.sendBidsChangeToNewContact(bidsIds, contact, !!ignoreDueDate);
    }

    function sendBidsToSupplier() {
      return contact.isUser?
        () => mainAPI.sendBidsToSelectedContact(bidsIds, contact.id, !!ignoreDueDate):
        () => mainAPI.sendBidsToNewContact(bidsIds, contact, !!ignoreDueDate);
    }

    return notificationUtils.onSave( actionFn, {
      customErrorHandlers: {
        400(error) {
          if (error.data && error.data.id === 'DUE_DATE_EXPIRED') {
            return dialog.show(dueDateExpiredNotification, {locals: {expiredDueDate: error.data.value}})
              .then(() => sendBidsToSelected(bidsIds, contact, true))
          } else {
            return notificationUtils.handleErrors(error)
          }
        }
      }
    })
  }

  function getSaveAndSendLabel(){
    const action = vm.configuration.status === 'CREATED' ? 'Send' : (vm.configuration.status === 'SENT' ? 'Resend' : 'Send/Resend'),
      bids = vm.configuration.multipleBids ? 'Bids' : 'Bid';
    return `Save & ${action} ${bids}`
  }

  function createNewContact() {
    return dialog.hide({create: true, user: {accountType: vm.active.type, isUser: false, id: ID_OF_NEW}});
  }

  function edit(user) {
    return dialog.hide({edit: true, user});
  }

}


function loadBids(bids){
  const config = {
    multipleBids: bids.length > 1,
    status: null,
    hotelId: null,
    chainId: null,
    chainSupport: null,
    selected: { HOTEL: null, CHAIN: null },
    supplier: { HOTEL: null, CHAIN: null },
    bids: []
  };

  bids.forEach(b => loadBid(config, b));

  return config;

  function loadBid(config, bid){
    updateConfig('status', '$status');
    updateConfig('hotelId', '$hotelId');
    updateConfig('chainId', '$chainId');
    updateConfigChainSupport();
    addBid();
    updateSelectedSupplier();

    function updateConfig(configField, bidField){
      config[configField] = (config[configField] === null || config[configField] === bid[bidField]) && bid[bidField]
    }

    function updateConfigChainSupport() { config.chainSupport = config.chainSupport === null ? bid.$rfpChainSupport : config.chainSupport && bid.$rfpChainSupport }

    function addBid() { config.bids.push( { rfpId: bid.$rfpId, bidId: bid.$bidId } ) }

    function updateSelectedSupplier(){
      const bidSupplierContact = bid.$supplierContact;

      if(bidSupplierContact) {
        updateSelected(config, TYPE_HOTEL, bidSupplierContact);
        updateSelected(config, TYPE_CHAIN, bidSupplierContact)
      }
    }

    function updateSelected(config, type, contact){
      if(contact.company && contact.company.type === type){
        const selected = config.selected[type];
        config.selected[type] = (selected === null || isEqual(selected, contact)) && contact;
        config.supplier[type] = (selected === null || isEqual(selected, contact)) && contact;
      }
    }
  }
}

function loadConfiguration(report){
  const config = Object.assign({}, report, {
    tabs : {
      shown: report.hotelId && report.chainId && report.chainSupport,
      init: !report.hotelId || (report.selected && report.selected[TYPE_CHAIN]) ? TYPE_CHAIN : TYPE_HOTEL
    }
  });
  config.selected[TYPE_HOTEL] = normalizeSupplierContact(config.selected[TYPE_HOTEL]);
  config.selected[TYPE_CHAIN] = normalizeSupplierContact(config.selected[TYPE_CHAIN]);

  return config
}

function normalizeSupplierContact(supplierContact){
  if(supplierContact) {
    return {
      id: supplierContact.id,
      firstName: supplierContact.firstName,
      lastName: supplierContact.lastName,
      emailAddress: supplierContact.emailAddress,
      phone: supplierContact.phone,
      jobTitle: supplierContact.jobTitle,
      isUser: supplierContact.isUser,
      accountId: supplierContact.company.accountId,
      entityId: supplierContact.company.entityId,
      accountType: supplierContact.company.type,
      accountName: supplierContact.company.name
    }
  }
}

function parseLoadRepresentativesResponse(httpResponse, selectedRepresentatives){
  const reps =  httpResponse.data.map(user => ({...user, invitation: invitationStatusDetails(user)}));

  updateWithSelected(selectedRepresentatives[TYPE_CHAIN]);
  updateWithSelected(selectedRepresentatives[TYPE_HOTEL]);

  return reps;

  function updateWithSelected(selected) {
    if(selected && !selected.isUser){
      const index = reps.findIndex(rep => rep.emailAddress === selected.emailAddress && rep.accountType === selected.accountType);
      if(index > -1) {
        reps[index] = {
          ...selected,
          ...reps[index]
        };
      } else{
        reps.unshift(selected);
      }
    }
  }

  function invitationStatusDetails(user) {
    const status = {
      opened: '',
      firstSent: '',
      lastSent: '',
      nth: '',
      count: 0,
      first: '1st',
      statusDate: '',
      available: false
    };

    if(user.invitations){
      const details = user.invitations;

      setValue('available', details.invitationsCount > 0);
      setValue('opened', details.status && ['OPENED', 'USED'].indexOf(details.status.value) > -1);
      setValue('statusDate', moment(details.status && details.status.at).fromNow());
      setValue('firstSent', moment(details.firstSent).fromNow());
      setValue('lastSent', moment(details.lastSent).fromNow());
      setValue('count', +details.invitationsCount);
      setValue('status', details.status);
      setValue('statusText', details.statusText);
      setValue('nth', displayWithOrdinalSuffix(+details.invitationsCount));
    }

    return status;

    function setValue(field, value) {
      status[field] = value;
    }

    function displayWithOrdinalSuffix(i) {
      const j = i % 10, k = i % 100;

      if (j === 1 && k !== 11) {
        return `${i}st`;
      } else if (j === 2 && k !== 12) {
        return `${i}nd`;
      } else if (j === 3 && k !== 13) {
        return `${i}rd`;
      } else {
        return `${i}th`;
      }
    }
  }
}

function filterRepresentatives(representatives, type) {
  return representatives.filter(r => r.accountType === type)
}
