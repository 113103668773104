/**
 * Created by DejanK on 1/11/2017.
 */
import { noop } from 'lodash';
import HTML from './edit-destination.html';
import { getAddressType, TYPE_OFFICE } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { getValidationRules } from "root/rfp-hotel/main/rfp/destinations/travel-destination-validation";

const NAME = 'addTravelDestination'

export { NAME, HTML, RfpAddDestinationController as Controller}

RfpAddDestinationController.$inject = ['RfpHotelRfpDestinationsRepository', 'RfpId', 'rbDialog', 'NotificationUtils'];
function RfpAddDestinationController(repository, RfpId, dialog, notificationUtils){
  const vm = this

  vm.destinationRules = getValidationRules()
  vm.pageTitle = 'Create Travel Destination'
  vm.destination = { rfpId: RfpId, type: TYPE_OFFICE }
  onTypeChange()

  vm.cancel = dialog.cancel
  vm.onSubmit = onSubmit
  vm.onTypeChange = onTypeChange

  function onSubmit(isFormValid){
    vm.showForm = true
    if(isFormValid){
      vm.saving = 'saving'
      notificationUtils.onSave(() => repository.createRfpTravelDestination(vm.destination, RfpId, vm.destination.type) )
        .then(dialog.hide, noop)
        .finally(() => { vm.saving = null })
    }
  }

  function onTypeChange(){
    vm.addressType = getAddressType(vm.destination.type)
    delete vm.destination.location
  }
}
