<template>
  <div class="rbvQuestionnaireQuestionListTypeOptions">
    <checkbox-button v-for="option in template.options" :key="option.value"
                     :checkBoxLabel="option.label"
                     :checkBoxValue="option.value"
                     :value="value"
                     :readonly="readonly"
                     @input="onChange"
    />
  </div>
</template>

<script>
  import checkboxButton from 'root/v-components/_core/RbCheckboxButton.vue'

  export default {
    name: 'rb-questionnaire-form-question-list',

    components: { checkboxButton },

    props: {
      readonly: {
        "default": false,
        "type": Boolean
      },
      template: { required: true },
      value: { "default": undefined }
    },

    methods: {
      onChange ( value ){ this.$emit('input', value) }
    }
  }
</script>

<style lang="stylus">

  .rbvQuestionnaireQuestionListTypeOptions {
    display: block;
    white-space: nowrap;
  }

</style>
