import Vue from 'vue';

import DestinationsTable from './SuppliersTable.vue';
import { cloneDeep } from 'lodash';
export default {
  template: '<div></div>',
  bindings: {
    tableOpen: '<',
    onToggleTable:'&',
    suppliers: '<',
    onPanMapTo: '&',
    rfpId: '<',
    destinationId: '<',
    filters: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'TravelDestinationManagerService', '$scope'];
function Ctrl($element, ManagerService, $scope){
  const vm = this, vueData = { tableOpen: false, suppliers: [], filters: {}, scrollTo: undefined};
  let vueComponent;

  vm.panMapTo = panMapTo;
  vm.onHotelItemMouseEnter = ManagerService.setFocusedSupplierId;
  vm.onHotelItemMouseLeave = ManagerService.setFocusedSupplierId;
  vm.addToBidManager = addToBidManager;
  vm.removeFromBidManager = removeFromBidManager;

  vm.$onDestroy = () => { vueComponent && vueComponent.$destroy() };

  vm.$onChanges = (changes) =>{
    initVueComponent()

    if(vueComponent && changes.suppliers && vm.suppliers){
      vueData.suppliers = vm.suppliers
    }
    if(vueComponent && changes.tableOpen){
      vueData.tableOpen = vm.tableOpen
    }

    if(vueComponent && changes.filters && vm.filters){
      vueData.filters = vm.filters
    }
  };

  function handleTableToggle() {
    vm.onToggleTable({
      $event: {
        state: !vm.tableOpen
      }
    });
  }

  this.$doCheck = ()=>{
    if(ManagerService.scrollListToId){
      scrollIntoView(ManagerService.scrollListToId)
    }
  };

  function panMapTo(coordinates){
    $scope.$applyAsync(()=>{
      vm.onPanMapTo({$event: {coordinates: cloneDeep(coordinates)}})
    })
  }

  function addToBidManager(supplier){
    const newSupplier = vm.suppliers.find(a => a.id === supplier.id);
    ManagerService.addToTheBidManager(vm.rfpId, vm.destinationId, newSupplier);
  }

  function removeFromBidManager(supplier){
    const newSupplier = vm.suppliers.find(a => a.id === supplier.id);
    ManagerService.setFocusedSupplierId();
    ManagerService.removeFromBidManager(vm.rfpId, vm.destinationId, newSupplier)
      .then(() => { newSupplier.bidState = 'NA' });
  }

  function scrollIntoView(idToScrollTo){
    vueData.scrollTo = vueData.suppliers.findIndex( s => s.id === idToScrollTo )
  }

  function initVueComponent(){
    !vueComponent && initVue();
    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { vueData: vueData },
        render: function (createElement) {
          return createElement(DestinationsTable, {
            props: {
              tableOpen: vueData.tableOpen,
              suppliers: vueData.suppliers,
              filters: vueData.filters,
              scrollTo: vueData.scrollTo
            },
            on: {
              toggleTable(event){ handleTableToggle(event)},
              onSupplierMouseEnter(event){ vm.onHotelItemMouseEnter(event.id)},
              onSupplierMouseLeave(){ vm.onHotelItemMouseLeave()},
              onRemoveFromBidManager(event){ vm.removeFromBidManager(event.supplier)},
              onAddToBidManager(event){ vm.addToBidManager(event.supplier)},
              onPanMapTo(event) {vm.panMapTo(event.coordinates)}
            },
          })
        }
      })
    }
  }
}
