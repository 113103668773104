/**
 * Created by DejanK on 9/1/2016.
 */
import angular from "angular";
import rbEditHotelRfpChainSupport from './rb-edit-hotel-rfp-chain-support/rb-edit-hotel-rfp-chain-support.cmp'

export default angular.module('rb.rfp.core.ui', [])
  .component('rbEditRfpName', require('./rb-edit-rfp-name/rb-edit-rfp-name.cmp'))
  .component('rbEditDueDate', require('./rb-edit-due-date/rb-edit-due-date.cmp'))
  .component('rbEditProgramPeriod', require('./rb-edit-program-period/rb-edit-program-period.cmp'))
  .component('rbEditHotelRfpChainSupport', rbEditHotelRfpChainSupport)
