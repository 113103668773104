import Notification from './Notification';

class RbvNotificationService {
  constructor() {
    this.$$shownNotifications = [];
    this.containerElementId = "rbv-notifications-container";
    this.containerElement = document.getElementById(this.containerElementId);
  }

  show(dialogComponent, props, options){
    const dialog = new Notification( this.containerElement, dialogComponent, props, options );
    this.$$shownNotifications.push(dialog);
    return dialog.promise;
  }

  showSnackBar(dialogComponent, props, options) {
    const dialog = new Notification( this.containerElement, dialogComponent, props, options, "snackbar");
    this.$$shownNotifications.push(dialog);
    return dialog.promise;
  }

  cancel(reason){
    const dialog = this.$$shownNotifications.pop();
    return dialog.cancel(reason);
  }

  hide(reason){
    const dialog = this.$$shownNotifications.pop();
    return dialog.hide(reason);
  }
}

export default RbvNotificationService;
