/**
 * Created by DejanK on 2/10/2017.
 */

'use strict';

let Pikaday = require('pikaday');
let Moment = require('moment');

module.exports = {
  template: templateFn,
  bindings: {
    model: '=',
    minDate: '<',
    maxDate: '<',
    years: '<',
    format: '@'
  },
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl() {}

templateFn.$inject = ['$attrs' ];
function templateFn($attrs){
  let name = $attrs.name || 'date',
    label = $attrs.label || 'Date',
    format = $attrs.format || 'MM/DD/YYYY',
    placeholder = $attrs.placeholder || $attrs.format || '',
    required = $attrs.required === 'required' ? 'required="required"' : '';

return `<div class="rb-input" ng-form="${name}" ng-class="{'focused':focused, 'touched': ${name}.${name}.$touched}">
  <label>${label}</label>
  <input-date name="${name}" placeholder="${placeholder}" ng-model="$ctrl.model" ${required}
        min-date="$ctrl.minDate" max-date="$ctrl.maxDate" years="$ctrl.years" format="${format}"
        ng-focus="focused=1" ng-blur="focused=0"></input-date>
  <div class="errors-container">
    <div ng-messages="${name}.${name}.$error" class="errors">
      <div ng-message="required">${label} is required!</div>
      <div ng-message="valid">${label} is not a valid Date!</div>
    </div>
  </div>
</div>`;
}
