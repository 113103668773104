/**
 * Created by DejanK on 2/8/2017.
 */
import { noop, cloneDeep } from 'lodash'
import HTML from './edit-destination.html'
import { hasNotChanged } from '../../../../../../shared/tools/utils';
import { getAddressType } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { getValidationRules } from 'root/rfp-hotel/main/rfp/destinations/travel-destination-validation';

const NAME = 'editTravelDestination'

export { NAME, HTML, RfpEditDestinationController as Controller}

RfpEditDestinationController.$inject = ['NotificationUtils', 'RfpHotelRfpDestinationsRepository', 'rbDialog'];
function RfpEditDestinationController(notificationUtils, rfpHotelRfpDestinationsRepository, dialog){
  const vm = this
  let untouchedDestination

  vm.destinationRules = getValidationRules()
  vm.pageTitle = 'Edit Travel Destination';

  vm.cancel = dialog.cancel
  vm.onSubmit = onSubmit
  vm.onTypeChange = onTypeChange

  $onInit();

  function $onInit() {
    notificationUtils.onLoad( () => { return rfpHotelRfpDestinationsRepository.getRfpTravelDestination(vm.rfpId, vm.destinationId) },
      vm, 'destination')
      .then(() => {
        vm.destination = vm.destination.data
        untouchedDestination = cloneDeep(vm.destination)
        vm.addressType = getAddressType(vm.destination.type)
      }, noop )
  }

  function onSubmit(){
    if(hasNotChanged(vm.destination, untouchedDestination)){
      vm.cancel()
    } else {
      vm.saving = 'saving'
      notificationUtils.onSave( () => {
        return rfpHotelRfpDestinationsRepository.updateRfpTravelDestination(vm.destination, vm.rfpId, vm.destination.type)
      })
        .then(dialog.hide, noop)
        .finally(() => { vm.saving = null })
    }
  }

  function onTypeChange(){
    vm.addressType = getAddressType(vm.destination.type)
    delete vm.destination.location
  }
}
