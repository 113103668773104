/**
 * Created by DejanK on 10/19/2017.
 */
import angular from 'angular';

import rbvNgQuestionnaireView from './questionnaire/view/rbv-ng-questionnaire-view.cmp';
import rbvNgQuestionnaireEdit from './questionnaire/edit/rbv-ng-questionnaire-edit.cmp';
import rbvNgQuestionnaireForm from './questionnaire/form/rbv-ng-questionnaire-form.cmp';
import rbvNgTravelDestinationsMapFilterBar from './travel-destinations-map/travel-destinations-map-filter-bar/rb-travel-destinations-map-filter-bar.cmp';
import rbvNgTravelDestinationsMapDestinationsTable from './travel-destinations-map/travel-destinations-map-table/rb-travel-destinations-map-destinations-table.cmp';
import rbvNgUserProfileCmp from './user-profile/rbv-ng-user-profile.cmp';
import rbvNgAccountProfile from './account-profile/rbv-ng-account-profile.cmp';
import rbvNgHotelDirectory from './hotel-directory/rbv-ng-hotel-directory.comp';
import rbvNgBidManagerExport from './rfp-hotel/bid-manager/export/rbv-bid-manager-export.cmp';
import rbvNgNegotiationsFormButtons from './rfp-hotel/negotiations/rbv-negotiations-form-buttons.cmp';
import rbvRichTextareaLetterEditor from './_core/inputs/NgRichTextareaLetterEditor.cmp';
import rbvNgSelectRfpTemplate from './rfp/select-rfp-template/rbv-ng-select-rfp-template.cmp';
import rbvNgRfpManager from './rfp-manager/rbv-ng-rfp-manager.cmp';
import rbvNgInvitationsHandler from './invitations-handler/rbv-ng-invitations-handler.cmp';
import rbvRfpManagerAccountProfile from './rfp-manager/account-profile/rbv-ng-rfp-manager-account-profile';
import rbvNgDuplicateRfp from './rfp/duplicate-rfp/rbv-ng-duplicate-rfp.cmp';

export default angular.module('rb.v-components', [])
  .component('rbvNgQuestionnaireView', rbvNgQuestionnaireView)
  .component('rbvNgQuestionnaireEdit', rbvNgQuestionnaireEdit)
  .component('rbvNgQuestionnaireForm', rbvNgQuestionnaireForm)
  .component('rbvNgTravelDestinationsMapFilterBar', rbvNgTravelDestinationsMapFilterBar)
  .component('rbvNgTravelDestinationsMapDestinationsTable', rbvNgTravelDestinationsMapDestinationsTable)
  .component('rbvNgUserProfile', rbvNgUserProfileCmp)
  .component('rbvNgAccountProfile', rbvNgAccountProfile)
  .component('rbvNgHotelDirectory', rbvNgHotelDirectory)
  .component('rbvNgBidManagerExport', rbvNgBidManagerExport)
  .component('rbvNgNegotiationsFormButtons', rbvNgNegotiationsFormButtons)
  .component('rbvRichTextareaLetterEditor', rbvRichTextareaLetterEditor)
  .component('rbvNgSelectRfpTemplate', rbvNgSelectRfpTemplate)
  .component('rbvNgRfpManager', rbvNgRfpManager)
  .component('rbvNgInvitationsHandler', rbvNgInvitationsHandler)
  .component('rbvNgRfpManagerAccountProfile', rbvRfpManagerAccountProfile)
  .component('rbvNgDuplicateRfp', rbvNgDuplicateRfp)
