/**
 * Created by DejanK on 5/30/2017.
 */

'use strict';

require('./rate-audit.scss');

module.exports = RateAuditController;

RateAuditController.$inject = ['ControllerUtils'];
function RateAuditController(utils){
  let vm = this;

  vm.rates = getRates();

  function getRates(){
    return [
      { "checkDate": "2.18.2017  9:08:48", "hotel":"Hotel Del Coronado", "firstName":	"Tim", "lastName": "Madkour", "checkIn":"1.16.2017", "checkOut":"1.21.2017", "currency":"USD", "nightCount": "5", "negotiatedRate": "$145.00", "actualBookedRate": "$190.00", "rateVariance": "+ $45.00", "tcos":"$950.00", "notice": "Higher than expected", "reason":"Rate Loaded Incorrectly", "commDue": "$94.50" },
      { "checkDate": "2.18.2017  9:08:48", "hotel":"Hilton San Antonio", "firstName":	"Mark", "lastName": "Tarkanian", "checkIn":"2.16.2017", "checkOut":"2.19.2017", "currency":"USD", "nightCount": "3", "negotiatedRate": "$235.00", "actualBookedRate": "$235.00", "rateVariance": "---", "tcos":"$705.00", "notice": "--- --- --- ---", "reason":"--- --- --- ---", "commDue": "---" },
      { "checkDate": "2.18.2017  9:08:48", "hotel":"Fairfield Anchorage", "firstName":	"Jerry", "lastName": "Bear", "checkIn":"1.11.2017", "checkOut":"1.15.2017", "currency":"USD", "nightCount": "4", "negotiatedRate": "$135.00", "actualBookedRate": "$175.00", "rateVariance": "+ $40.00", "tcos":"$700.00", "notice": "Higher than expected", "reason":"Hotel Up sell", "commDue": "$70.00" },
      { "checkDate": "2.18.2017  9:08:48", "hotel":"Dallas W Hotel", "firstName":	"Billy", "lastName": "Mason", "checkIn":"1.7.2017", "checkOut":"1.8.2017", "currency":"USD", "nightCount": "1", "negotiatedRate": "$210.00", "actualBookedRate": "$265.00", "rateVariance": "+ $55.00", "tcos":"$265.00", "notice": "Higher than expected", "reason":"Agency Error", "commDue": "$26.50" },
      { "checkDate": "2.18.2017  9:08:48", "hotel":"Denver Chateau", "firstName":	"Mike", "lastName": "Mitchell", "checkIn":"3.23.2017", "checkOut":"3.24.2017", "currency":"USD", "nightCount": "1", "negotiatedRate": "$185.00", "actualBookedRate": "$215.00", "rateVariance": "+ $30.00", "tcos":"$215.00", "notice": "Higher than expected", "reason":"Agency Error", "commDue": "$21.50" }
    ]
  }
}
