/**
 * Created by Samier on 20/5/2017.
 *
 * rbCurrentUser Component
 */
import '../../../shared/styles/rb-current-user.scss'
import HTML from './rb-current-user.html'
import { noop } from 'lodash'
import { NAME as SwitchAccountDialog} from "../../dialogs/switch-account/switch-account.ctrl"
import { NAME as EditRateLoadingInformationDialog } from "../../../entity/rate-loading-information/dialogs/rate-loading-information/rate-loading-information.ctrl"
import { NAME as notImplementedDialog} from "root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl";
import {stopEvent} from "root/shared/tools/view-utils";
import defaultImage from "images/user-image.png"

export default {
  template: HTML,
  controller: Ctrl
}

Ctrl.$inject = ['$scope', 'AuthSession', 'CurrentUser', 'rbDialog']
function Ctrl($scope, authSession, currentUser, dialog){
  const vm = this

  vm.signOut = authSession.forget
  vm.switchAccount = switchAccount
  vm.editRateLoadingInformation = editRateLoadingInformation
  vm.showNotImplementedNotification = showNotImplementedNotification
  vm.defaultImage = defaultImage

  this.$onInit = function onInit () { currentUser.watchUser($scope, vm) }

  function switchAccount($event){
    $event.preventDefault()
    dialog.show(SwitchAccountDialog).catch( noop )
  }

  function editRateLoadingInformation($event){
    $event.preventDefault()
    dialog.show(EditRateLoadingInformationDialog).catch( noop )
  }

  function showNotImplementedNotification( $event ) {
    stopEvent($event)
    dialog.show(notImplementedDialog).catch( noop )
  }
}
