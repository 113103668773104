<template>
  <rbv-dropdown :is-open="isOpen" :backdrop-style="'transparent'" @close="toggle">
    <div slot="toggler" :class="$style.togglerContainer" >
      <button class="rbButton" :class="styleSize.toggler" @click="toggle">
        <span :class="$style.togglerText">{{toggleText}}</span>
        <i class="material-icons" :class="isOpen ? $style.dropdownOpenArrow : $style.dropdownArrow">arrow_drop_down</i>
      </button>
      <button class="rbButton" :class="styleSize.togglerExtension" @click="invertSort">
        <i class="material-icons" :class="sorter.order !== 'desc' && styleSize.sortAsc">sort</i>
      </button>
    </div>
    <div slot="dropdown" :class="$style.dropdownContainer" @click="toggle">
      <button class="rbButtonMenuItem"
              v-for="field in sorts" :key="field.id"
              @click="onDestinationSortChanged(field.id)">{{field.text}}</button>
    </div>
  </rbv-dropdown>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';
  import sortService from "root/v-components/travel-destinations-map/travel-destinations-map-table/suppliers-sort-service";

  export default {
    name: 'rbv-travel-destinations-map-suppliers-table-shrinked-sorter',
    mixins: [rbEventsMixin],
    components: { RbvDropdown },
    props: {
      size: {
        type: String, // 'small' or 'large'
        required: true
      },
      sorter: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    data(){
      return {
        isOpen: false,
        sorts: sortService.listSorts()
      }
    },
    computed: {
      styleSize () {
        switch (this.size){
          case 'small':
            return {
              toggler: this.$style.togglerSmall,
              togglerExtension: this.$style.togglerExtensionSmall,
              sortAsc: this.$style.sortAscSmall
            }
          case 'large':
            return {
              toggler: this.$style.togglerLarge,
              togglerExtension: this.$style.togglerExtensionLarge,
              sortAsc: this.$style.sortAscLarge
            }
          default:
            return {}
        }
      },
      toggleText(){
        const sort = sortService.findById(this.sorter.id)
        return sort ? `Sort by ${sort.text}` : 'Not Sorted'
      }
    },
    methods: {
      toggle() { this.isOpen = !this.isOpen },
      onDestinationSortChanged(id){ this.$rbEmit(sortService.createSortEvent(id)) },
      invertSort() { this.onDestinationSortChanged(this.sorter.id) }
    }
  }
</script>

<style lang="stylus" module>

  .togglerContainer {
    display flex
  }

  .toggler {
    white-space: nowrap;
    padding: 0 3px 0 16px
    border-radius: 2px 0 0 2px;
    text-align: left;

    &:hover {
      position relative
      z-index 1
    }
  }

  .togglerSmall {
    composes toggler

    height 27px !important
    line-height 25px !important
    font-size 12px !important
    width: 170px;
  }

  .togglerLarge {
    composes toggler

    height 36px !important
    line-height 34px !important
    font-size 13px !important
    width: 220px;
  }

  .togglerText {
    width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space nowrap
  }

  .togglerExtension {
    padding: 0 0 0 10px;
    text-align: center;
    border-radius: 0 2px 2px 0;
    margin-left: -1px !important;
  }

  .togglerExtensionSmall {
    composes togglerExtension

    height: 27px !important;
    line-height: 25px !important;
    width: 27px !important;
  }

  .togglerExtensionLarge {
    composes togglerExtension

    height: 36px !important;
    line-height: 34px !important;
    width: 36px !important;
  }

  .dropdownArrow {
    color #78909C
  }

  .dropdownOpenArrow {
    composes dropdownArrow
    transform rotateX(180deg)
  }

  .sortAsc {
    transform: rotateX(180deg);
  }

  .sortAscSmall {
    composes sortAsc
    line-height: 30px !important;
  }

  .sortAscLarge {
    composes sortAsc
    line-height: 36px !important;
  }

  .dropdownContainer {
    background: #FFFFFF;
    border: 1px solid #CFD8DC;
    margin-top: 4px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow : 0 0 5px 1px rgba(0, 0, 0, 0.18);
  }

</style>
