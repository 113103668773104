import {get, orderBy} from 'lodash';
import {formRfp, groupRfps} from './rfp-manager-table-grouper-service';

const COMPANY_NAME_GROUPER_ID = 'companyName';
const ASSIGNEE_NAME_GROUPER_ID = 'assignee';
const RFP_STATUS_GROUPER_ID = 'rfpStatus';
const RFP_LAUNCH_MONTH_GROUPER_ID = 'launchMonth';

function filterRfpsBy(rfps, filters) {
  return rfps.filter((rfp) => !filters.find(filter => !filter.compare(rfp)))
}

function generateFilters(rfps, grouper) {
  const filters = [{
    id: '',
    label: 'None'
  }];

  const groups =  (grouper.id && rfps)? rfps.filter(rfp => rfp.headerType === grouper.id)
      .map(rfp => ({
          id: Symbol(`${grouper.id}-${rfp.key}`),
          label: rfp.title,
          value: rfp.key
      })): [];

  return [...filters, ...groups];
}

function getObjectValue(object, key) {
  let value;
  if (typeof key === 'function') {
    value = key(object);
  } else {
    value = get(object, key);
  }
  return value;
}

function groupRfpsBy(rfps, grouper, accounts, users, shouldShowArchivedRfps) {
  return grouper.id?
    groupRfps(grouper, accounts, users, rfps, shouldShowArchivedRfps):
    rfps.filter(({rfp}) => !shouldShowArchivedRfps? !rfp.archived: true)
      .map(rfp => ({...rfp, rfp: formRfp(rfp.rfp, accounts)}));

}

function sortRfpsBy(rfps, sorter) {
  return orderBy(rfps, [(rfp) => getObjectValue(rfp, sorter.by && sorter.by.key)], [sorter.direction]);
}

function rfpIsSelected(item, allRfps, selectedRfps, grouper) {
  return item.headerType === grouper.id? groupSelected():
    (
      item.type === 'header' ? headerSelected(item):
      !!selectedRfps.find((rfp) => rfp.rfp && rfp.rfp.id === item.rfp.id)
    );

  function groupSelected() {
    const groupItems = allRfps.filter((rfp) => isGroupItem(rfp, item, grouper));

    if(groupItems.length === 0 && grouper.id === COMPANY_NAME_GROUPER_ID){
      return !!selectedRfps.find(rfp => rfp.account && rfp.account.id === item.account.id);
    }else{
      const selectedGroupItems = selectedRfps.filter((rfp) => isGroupItem(rfp, item, grouper));
      return selectedGroupItems.length !== 0 && groupItems.length === selectedGroupItems.length;
    }
  }

  function headerSelected(header) {
    return !!selectedRfps.find(rfp => rfp.type === 'header' && rfp.key === header.key);
  }
}

function isGroupItem(rfp, item, grouper) {
  return rfp.type === 'header'? rfp.headerKey === item.key: grouper.filter(item.key, rfp)
}

function selectRfp(item, allRfps, selectedRfps, grouper, force = false) {
  if(item.headerType !== grouper.id) {
    return !selected()? [...selectedRfps, item]: (force? selectedRfps: (
      item.type === 'header'?
        selectedRfps.filter(rfp => !(rfp.type === 'header' && rfp.key === item.key)):
        selectedRfps.filter(({rfp}) => rfp.id !== item.rfp.id)
    ));
  }else {
    const groupSelected = rfpIsSelected(item, allRfps, selectedRfps, grouper);
    return groupSelected? removeAllGroupItems(): addAllGroupItems();
  }

  function selected() {
    return !!selectedRfps.find(rfp => {
      return item.type === 'header'? (rfp.type === 'header' && rfp.key === item.key): (rfp.rfp && rfp.rfp.id === item.rfp.id)
    });
  }

  function removeAllGroupItems() {
    if(force) {
      return selectedRfps;
    }else{
      const groupItems = getGroupItems(selectedRfps, item, grouper);
      if(groupItems.length > 0){
        return selectedRfps.filter((rfp) => !isGroupItem(rfp, item, grouper));
      }else{
        return selectedRfps.filter(rfp => !(rfp.headerType === grouper.id && rfp.key === item.key));
      }
    }

  }

  function addAllGroupItems() {
    const groupItems = getGroupItems(allRfps, item, grouper);
    if(groupItems.length > 0){
      return [...removeAllGroupItems(), ...groupItems];
    }else{
      return [...removeAllGroupItems(), item];
    }
  }
}

function getGroupItems(allRfps, item, grouper) {
  return allRfps.filter((rfp) => isGroupItem(rfp, item, grouper));
}

function selectAll(allRfps, grouper) {
  return allRfps.reduce((acc, rfp) => selectRfp(rfp, allRfps, acc, grouper, true), []);
}

function allSelected(allRfps, selectedRfps, grouper) {
  return selectedRfps.length > 0 && !allRfps.find(rfp => !rfpIsSelected(rfp, allRfps, selectedRfps, grouper));
}

export default {
  groupRfpsBy,
  filterRfpsBy,
  sortRfpsBy,
  rfpIsSelected,
  selectRfp,
  selectAll,
  generateFilters,
  allSelected,
};

export {
  getObjectValue,
  COMPANY_NAME_GROUPER_ID,
  ASSIGNEE_NAME_GROUPER_ID,
  RFP_STATUS_GROUPER_ID,
  RFP_LAUNCH_MONTH_GROUPER_ID
}
