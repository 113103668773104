/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Add Hotels
 */
import { encodeState, stopEvent } from 'root/shared/tools/view-utils'
import './rb-bid-manager-add-hotels.scss'
import HTML from './rb-bid-manager-add-hotels.html'

export default {
  template: HTML,
  bindings: {
    bmView: '<'
  },
  controller: RbBidManagerAddHotels
};

RbBidManagerAddHotels.$inject = ['NotificationUtils', 'BidManagerRepository', '$state'];
function RbBidManagerAddHotels(utils, repository, $state) {
  const vm = this

  vm.onDropDownOpened = onDropDownOpened;
  vm.openDestinationMap = openDestinationMap;

  function openDestinationMap($event, td){
    stopEvent($event);
    $state.inNew('hotelRfp.rfp.destinations.map', {rfpId: td.rfpId, destinationId: td.id, from: encodeState($state)});
  }

  function onDropDownOpened(){
    if(vm.bmView){
      utils.onLoad( () => {
        const v = vm.bmView.$view
        return v.type === 'RFP' ? repository.getRfpTravelDestinations(v.rfpId) : repository.getTravelDestinations()
      } ,vm, 'destinations')
    }
  }
}
