/**
 * Created by DejanK on 12/28/2016.
 */

'use strict';

module.exports = RbTooltipService;

RbTooltipService.$inject = ['$timeout', '$document'];
function RbTooltipService($timeout, $document){
  let $service = this, tooltipContainerElement;

  $service.getShowFn = getShowFn;
  $service.getOnEventShowFn = getOnEventShowFn;
  $service.getHideFn = getHideFn;
  $service.adjustPosition = adjustPosition;
  $service.getBodyElement = getBodyElement;

  function getShowFn(scope, element, direction, toParentLeft){
    let parent = element.parent();

    return function show(){
      element.$tooltipPromise = $timeout(showTooltip, 500);

      function showTooltip() {
        if(scope.$$destroyed) { return; }
        let tooltipElement = element, tooltipParentElement = parent[0];

        addTooltip(tooltipElement);
        scope.$on('$destroy', function(){ tooltipElement.remove()});

        var position = getPosition(tooltipElement, tooltipParentElement, !toParentLeft, direction);
        if(position){
          showElement(tooltipElement, position);
          if(adjustPosition(tooltipElement)){
            var switchedDirection = switchDirection(direction, tooltipElement);
            if(switchedDirection !== null){
              var newPosition = getPosition(tooltipElement, tooltipParentElement, !toParentLeft, switchedDirection);
              showElement(tooltipElement, newPosition);
              adjustPosition(tooltipElement);
            }
          }
        }
      }
    }
  }

  function getOnEventShowFn(scope, element){
    return function show(event){
      var position = { top: event.pageY+10, left: event.pageX + 10};
      element.$tooltipPromise = $timeout(showTooltip, 500);

      function showTooltip() {
        if(scope.$$destroyed) { return; }
        var tooltipElement = element;

        addTooltip(tooltipElement);
        scope.$on('$destroy', function(){ tooltipElement.remove()});
        //var position = getPosition(tooltipElement, tooltipParentElement, !toParentLeft);
        showElement(tooltipElement, position);
        adjustPosition(tooltipElement);
      }
    }
  }

  function switchDirection(direction, tooltipElement){
    if(direction === 'bottom-right'){
      tooltipElement.css('left', '');
      tooltipElement.css('right', '');
      return 'bottom-left';
    }
    return null;
  }

  function addTooltip(tooltipElement){
    // show element next to parent to prevent flickering
    getTooltipContainerElement().append(tooltipElement);
    tooltipElement.css('display', 'block');
    tooltipElement.css('opacity', 0);
  }

  function getPosition(tooltipElement, tooltipParentElement, middle, direction){
    var elementBounds = tooltipElement[0].getBoundingClientRect();
    var parentBounds = tooltipParentElement.getBoundingClientRect();
    if(parentBounds.top < 0 || parentBounds.top > window.innerHeight) return false;
    direction = direction || 'up';
    switch(direction){
      case 'up':
        return {
          left: parentBounds.left + (parentBounds.width - elementBounds.width)/2,
          top: parentBounds.top - elementBounds.height
        };
      case 'left':
        return {
          left: parentBounds.left - elementBounds.width,
          top: parentBounds.top + (parentBounds.height - elementBounds.height)/2
        };
      case 'right':
        return {
          left: parentBounds.right,
          top: parentBounds.top + (parentBounds.height - elementBounds.height)/2
        };
      case 'bottom-right':
        return {
          left: parentBounds.right + 5,
          top: parentBounds.bottom + 5
        };
      case 'bottom-left':
        return {
          left: parentBounds.left - elementBounds.width - 5,
          top: parentBounds.bottom + 5
        };
      case 'bottom':
        return {
          left: parentBounds.left + (middle ? (parentBounds.width - elementBounds.width)/2 : 0),
          top: parentBounds.bottom
        };
    }
  }

  function adjustPosition(container) {
    var containerBounds = container[0].getBoundingClientRect(),
      body = getBodyElement()[0];
    if(containerBounds.left < 5){
      container.css('left', '5px');
      container.css('right', '');
      return true;
    } else if(containerBounds.right >= body.clientWidth){
      container.css('left', '');
      container.css('right', '5px');
      return true;
    }

    if(containerBounds.bottom > body.clientHeight){
      container.css('top', '');
      container.css('bottom', '5px');
    }

    return false;
  }

  function showElement(tooltipElement, position){
    tooltipElement.css('top', position.top + 'px');
    tooltipElement.css('left', position.left +  'px');
    tooltipElement.css('opacity', 1);
    tooltipElement.addClass('fadeIn');
  }

  function getHideFn(element){
    return function hide(){
      let tooltipElement = element;
      tooltipElement.remove();
      tooltipElement.css('top', null);
      tooltipElement.css('bottom', null);
      tooltipElement.css('left', null);
      tooltipElement.css('right', null);
      tooltipElement.$tooltipPromise && $timeout.cancel(tooltipElement.$tooltipPromise);
      tooltipElement.$tooltipPromise = null;
      tooltipElement.removeClass('fadeIn');
      tooltipElement.css('opacity', 0);
    };
  }

  function getBodyElement(){
    return angular.element($document[0].body);
  }

  function getTooltipContainerElement(){
    if(!tooltipContainerElement){
      tooltipContainerElement = angular.element(angular.element($document[0].body).find('tooltip-container')[0]);
    }
    return tooltipContainerElement;
  }
}
