<template>
  <form class="vModal vModalDefault" @submit.stop.prevent="submit()" novalidate>
    <div class="vModalDefaultHeader">
      <slot name="header">Default Header</slot>
      <button type="button" class="vModalDefaultCloseButton" @click="cancel()">close</button>
    </div>
    <slot name="body">Default Body</slot>
    <slot name="footer">
      <div class="vModalDefaultFooter">
        <button type="button" class="rbButton" @click="cancel()">Cancel</button>
        <button type="submit" class="rbButton mainButton">{{options.labels.buttons.apply}}</button>
      </div>
    </slot>
  </form>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'

  export default {
    name: 'rb-modal-form',
    mixins: [ DialogMixin ],
    props: {
      options: {
        type: Object,
        default() { return { labels: { buttons: { apply: 'Apply' } } } }
      }
    }
  }
</script>
