<script>
  import NotificationDialogTemplate from 'vRoot/_core/templates/NotificationDialogTemplate.vue';
  import VButton from 'vRoot/_core/buttons/Button.vue';
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import BUTTON_TYPE from './button-type';

  export default {

    name: 'NotificationDialog',


    components: { NotificationDialogTemplate, VButton },


    mixins: [ DialogMixin ],


    props: {
      title: {
        type: String,
        default: 'Error!'
      },

      subTitle: {
        type: String,
        default: 'Please Note'
      },

      message: {
        type: String,
        default: ''
      },

      type: {
        type: String,
        default: 'notice'
      },

      icon: {
        type: String,
        default: null
      },

      buttonsConfiguration: {
        type: Object,
        default: () =>({}),
      },
    },


    computed: {
      actualIcon () {
        return this.icon || 'exclamation';
      },

      styles () {
        if (this.type === 'error') {
          return {
            dialog: 'error',
            button: 'error'
          };
        } else {
          return {
            dialog: 'neutral',
            button: 'main',
          };
        }
      },
    },

    created () {
      Object.assign(
        this,
        {
          buttonType: BUTTON_TYPE,
        }
      );
    }
  }
</script>


<template>
  <notification-dialog-template
    :class="$style.dialog"
    :theme="styles.dialog"
    :icon="actualIcon"
    @cancel="cancel()"
  >
    <template slot="subTitle">
      {{ subTitle }}
    </template>

    <template slot="title">
      {{ title }}
    </template>

    <template slot="message">
      {{ message }}
    </template>

    <template slot="footer">
      <template v-if="buttonsConfiguration.type === buttonType.CONFIRMATION">
        <v-button @click="cancel()">
          {{ buttonsConfiguration.cancel || 'Cancel' }}
        </v-button>

        <v-button
          :type="styles.button"
          @click="hide()"
        >
          {{ buttonsConfiguration.confirm || 'OK' }}
        </v-button>
      </template>

      <template v-else>
        <v-button
          :type="styles.button"
          @click="hide()"
        >
          OK
        </v-button>
      </template>
    </template>
  </notification-dialog-template>
</template>


<style lang="stylus" module>

  .dialog {
    width: 500px;
  }

</style>

