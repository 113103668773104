<template>
    <div :class="$style.columnPanel">
        <div :class="$style.columnsPanelHeaderRow">
            <span :class="$style.columnsPanelRowNumber">#</span>
            <span :class="$style.columnsPanelRowName">Column Name</span>
            <span>Manage</span>
        </div>
        <div :class="$style.columnsPanelBody">
            <div
                v-for="(column, index) in columns"
                :class="{[$style.columnsPanelRow]: true, [$style.firstHidden]: visibleColumns > 0 && index === visibleColumns }"
                :key="column">
                    <span :class="$style.columnsPanelRowNumber">{{index+1}}</span>
                    <span :class="$style.columnsPanelRowName">
                        {{getColumn(column).text}}
                    </span>
                    <span :class="$style.columnsPanelRowIcons">
                        <i @click="pushUp(index)" class="material-icons" v-if="index > 0 && canReposition">publish</i>
                        <i @click="removeColumn(column)" v-if="canRemove" class="material-icons">remove_circle_outline</i>
                    </span>
            </div>
            <div :class="$style.otherColumns" v-if="canRemove">
                <div
                    v-for="column in otherColumns"
                    :class="$style.columnsPanelRow"
                    :key="column.id">
                        <span :class="$style.columnsPanelRowName">{{column.text}}</span>
                        <span :class="$style.columnsPanelOtherRowIcons">
                            <i class="material-icons" @click="addColumn(column.id)">add_circle_outline</i>
                        </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HotelDirectoryColumnsEditor',
    props: {
        columns: {
            required: true
        },
        allColumns: {
            required: true
        },
        get:{
            required: true
        },
        canRemove: {
          type: Boolean,
          default: true
        },
        canReposition: {
          type: Boolean,
          default: true
        },
        visibleColumns: {
          type: Number
        }
    },
    methods: {
        getColumn(column){
            return this.get(column)
        },
        addColumn(id){
            this.$emit('addColumn', id);
        },
        removeColumn(id){
            this.$emit('removeColumn', id);
        },
        pushUp(index){
            this.$emit('pushUp', index);
        }
    },
    computed: {
        otherColumns(){
            return this.columns && this.allColumns.filter(column => this.columns.indexOf(column.id) === -1)
        }
    }
}
</script>

<style lang="stylus" module>

    .columnPanel{
        margin-right 10px;
    }

    .columnsPanelRow{
        display: flex;
        justify-content: space-between;
        min-height: 40px;
        padding: 5px 0 0 0;
        border-bottom: 1px solid #eceff1;
        align-items center;
        font-size 14px;
    }

    .columnsPanelHeaderRow{
        composes columnsPanelRow;
        height auto;
        line-height inherit;
    }

    .columnsPanelBody{
        border: 2px solid #eaebec;
        padding: 0 10px 0 5px;
        height: 300px;
        overflow-y: auto;
    }

    .columnsPanelRowName{
        flex 1;
        text-align left;
        margin-left 15px;
    }

    .columnsPanelRowNumber{
        width 10px;
    }

    .columnsPanelRowIcons{
        display flex;
        justify-content flex-end;
        color: #CFD8DC;
        padding: 8px 0;
        *{
            cursor pointer;
        }
    }

    .otherColumns, .firstHidden{
        border-top 5px solid #DCDEE0;
    }

    .columnsPanelOtherRowIcons{
        composes columnsPanelRowIcons;
        color #455A64;
    }

    .alt {
        font-size 10px;
    }
</style>

