import { simpleState } from '../../shared/tools/router-helper';
import {noop} from 'lodash';

export default States;

export const PUBLIC_INVITATIONS_VIEW_PAGE_NAME = 'publicInvitationsView';

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state(PUBLIC_INVITATIONS_VIEW_PAGE_NAME, simpleState('/invitations/{token}?type,direct', '<rb-empty-header /><rbv-ng-invitations-handler/>', noop))
}
