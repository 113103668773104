const TYPE_OFFICE = "OFFICE",
  TYPE_CITY = "CITY",
  ADDRESS_TYPE_CITY = "CITY",
  ADDRESS_TYPE_ADDRESS = "ADDRESS"

export { getAddressType, TYPE_OFFICE, TYPE_CITY }

function getAddressType(destinationType){
  switch (destinationType) {
    case TYPE_CITY:
      return ADDRESS_TYPE_CITY
    case TYPE_OFFICE:
    default:
      return ADDRESS_TYPE_ADDRESS
  }
}
