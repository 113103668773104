/**
 * Created by DejanK on 4/20/2018.
*/
export default rbUrlValidator;

function rbUrlValidator() {
  return {
    require: '?ngModel',
    link: (s, e, attrs, ngModelCtrl)=>{
      if(ngModelCtrl){
        ngModelCtrl.$validators.rbUrlValidator = modelValue => {
          return !modelValue || !modelValue.length ||
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(modelValue)
        }
      }
    }
  }
}
