/**
 * Created by DejanK on 10/03/2016.
 */

import { noop } from 'lodash'
import './sign-up.scss'
import HTML from './sign-up.html'

export { HTML, SignUpController as Controller }

SignUpController.$inject = [ 'NotificationUtils', 'PublicAuth' ];
function SignUpController( notificationUtils, publicAuth){
  const vm = this;
  vm.signUp = onSignUp;

  function onSignUp(registration){
    notificationUtils.onSave( () => publicAuth.signUp(registration) )
      .then(() => { notificationUtils.showSuccess('signup-success') }, noop)
  }
}
