import { orderBy, isArray } from 'lodash';

const SORTS = [
  {id: 'bidState', text: 'Selected', key: 'bidState'},
  {id: 'lastYear', text: 'Previously in Program', key: 'lastYear'},
  {id: 'distanceMi', text: 'Distance', key: 'distanceMi', isDisplayedInBadge: true },
  {id: 'name', text: 'Property Name', key: 'name', isDisplayedInBadge: false },
  {id: 'city', text: 'City', key: 'location.address.city', isDisplayedInBadge: true },
  {id: 'state', text: 'State/Region', key: ['location.address.state', 'location.address.region'] , isDisplayedInBadge: true },
  {id: 'country', text: 'Country', key: 'location.address.country', isDisplayedInBadge: true },
  {id: 'chain', text: 'Chain', key: 'chain.masterChain.name', isDisplayedInBadge: true },
  {id: 'brand', text: 'Brand', key: 'chain.name', isDisplayedInBadge: true },
];

function findById(id){ return SORTS.find( s => s.id === id ) }

const service = {
  createSortEvent(id) { return {name: 'onDestinationSortChanged', id: id } },
  findById: findById,
  getDefaultSort() { return Object.assign({order: 'asc'}, SORTS[2]) },
  listSorts() { return SORTS },
  sort(suppliers, sortKey, sortOrder) {
    const keys = [], orders = []
    if(isArray(sortKey)){
      keys.push(...sortKey)
      sortKey.forEach(() => { orders.push(sortOrder) })
    } else {
      keys.push(sortKey)
      orders.push(sortOrder)
    }

    keys.push('name')
    orders.push('asc')
    return orderBy(suppliers, keys, orders)
  },
  updateSortObject(sortObject, id) {
    if (sortObject.id === id) {
      sortObject.order = sortObject.order === 'asc' ? 'desc' : 'asc'
    } else {
      sortObject = Object.assign(sortObject, {order: 'asc'}, findById(id))
    }
  }
}
export default service
