<template>
  <modal-form :class="$style.dialog"
    @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">
        <div>{{title || 'Edit Outgoing Message'}}</div>
        <div :class="$style.subHeader">{{subTitle}}</div>
    </span>

    <div slot="body" class="vModalDefaultBody hotel-directory">
        <edit-user-message v-model="data.message" :userAccount="userAccount" :user="user" />
        <rb-input :forId="'email'" :error="$showError('email')" v-if="shouldHaveEmail" :class="$style.input">
          <span slot="label">Recipient's Email Address</span>
          <input id="email" type="text" name="email" class="rbInput" autofocus
                 v-model="data.emailAddress"
                 maxlength="100"
                 v-validate="'required|email'"
                 placeholder="Email Address"
                 data-vv-as="Email Address"/>
        </rb-input>
    </div>

    <div slot="footer" class="vModalDefaultFooter">
        <div :class="$style.setDefault" v-if="message !== data.message">
            <check-box
                v-model="data.setMessageAsDefault"
                :check-box-value="true"
                check-box-label="Set as default"/>
            <span>
                <i class="material-icons">info</i>
                <div class="rbTooltip simple" tooltip="placement:top-start">
                    Checking this box will set this message as the default message for future hotel directory emails.
                </div>
            </span>
        </div>
        <button class="rbButton" type="button" @click="cancel()">Cancel</button>
        <button class="rbButton mainButton">
            <i class="material-icons">save</i>
            Send
        </button>
    </div>

  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
  import EditUserMessage from '../EditUserMessage.vue';
  import RbInput from 'root/v-components/_core/RbInput.vue'

  export default {
    name: 'outgoingMessageDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { ModalForm, CheckBox, EditUserMessage, RbInput },
    props: ['message', 'sub-title', 'userAccount', 'title', 'user', 'should-have-email'],
    data() {
       return {
            data : {
                message: this.message,
                setMessageAsDefault: false,
                emailAddress: this.user.emailAddress,
            }
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .setDefault{
        float left;
        display flex;
        color: #91a4af;
    }

    .input{
        margin-top 30px !important;
    }
</style>
