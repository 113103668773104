import Vue from 'vue'
import { isNil, noop, debounce } from 'lodash'

import QuestionnaireForm from './QuestionnaireForm.vue'
import EventBus from 'vRoot/_mixins/EventBus'

export default {
  template: '<div></div>',
  bindings: {
    questionnaire: '<',
    onChange: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'DataServer', '$scope']
function Ctrl($element, dataServer, $scope){
  const vm = this,
    getTemplatePromise = dataServer.getQuestionnaire(),
    questionnaire = {
      template: {},
      config: [],
      globals: {},
      model: {},
      response: {}
    },
    debouncedNotifyOfChange = debounce(notifyOfChange, 100)

  let vueComponent

  vm.errors = {}

  this.$onInit = () => {
    $scope.$on('vue-event', (ev, data) => EventBus.$emit(data.event, data.data));
  }

  this.$onDestroy = () => { vueComponent && vueComponent.$destroy() }

  this.$onChanges = ( changes ) => {
    if(changes.questionnaire && changes.questionnaire.currentValue) {
      vm.questionnaire = changes.questionnaire.currentValue
      questionnaire.config = vm.questionnaire.config
      questionnaire.globals = vm.questionnaire.globals
      questionnaire.model = vm.questionnaire.model
      questionnaire.response = vm.questionnaire.responseDraft

      getTemplatePromise
        .then(initVueComponent, noop)
    }
  }

  function initVueComponent(template){
    !vueComponent && initVue()

    function initVue(){
      questionnaire.template = template
      vueComponent = new Vue({
        el: $element[0],
        data: {
          questionnaire: questionnaire
        },
        render: function (createElement) {
          return createElement(QuestionnaireForm, {
            props: { questionnaire: questionnaire },
            on: { change: onChange, stateChange: onStateChange }
          })
        }
      })
    }
  }

  function onChange( data ){
    if(isNil(data.value) || data.value.trim() === ''){
      Vue.delete(questionnaire.response.answers, data.id)
    } else {
      Vue.set(questionnaire.response.answers, data.id, data.value.trim())
    }

    if(data.error.length){
      vm.errors[data.id] = data.error
    } else {
      delete vm.errors[data.id]
    }

    Vue.set(questionnaire.response, 'isValid', !Object.keys(vm.errors).length)
    debouncedNotifyOfChange(questionnaire.response, vm.errors)
  }

  function onStateChange( data ){
    const stateFilters = questionnaire.response.state.find( s => s.id === data.stateId).data
    Vue.set(stateFilters, data.filterId, data.templateFilters[data.filterId].slice(0, stateFilters[data.filterId].length + data.value))
    if(data.value < 0) { removeObsoleteValues( data, stateFilters[data.filterId] ) }
    debouncedNotifyOfChange(questionnaire.response, vm.errors)
  }

  function removeObsoleteValues ( data, filter ) {
    data.template.cells.forEach( row => {
      if( row.for && row.for[data.filterId] && filter.indexOf(row.for[data.filterId]) === -1 ) {
        row.cells.forEach( cellToRemove => {
          if(cellToRemove.cell) {
            const id = cellToRemove.cell.id
            Vue.delete(questionnaire.response.answers, id)
            delete vm.errors[id]
          }
        })
      }
    })
    debouncedNotifyOfChange(questionnaire.response, vm.errors)
  }

  function notifyOfChange(response, errors){
    $scope.$timeout(()=>{
      vm.onChange({ event: { response: JSON.parse(JSON.stringify(
        {
          response: response,
          errors: errors
        }
      )) }})
    })
  }
}
