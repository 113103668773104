<template>
  <div class="account-manage-companies-list">
    <row
      v-for="account in accounts"
      :key="account.id"
      :account="account"
      :is-current-account="isCurrentAccount(account)"
      :current-user-is-admin="currentUserIsAdmin"
      :is-assigned-to-current-user="isAssignedToCurrentUser(account)"
      @edit="edit(account)"
      @assignments="assignments(account)"
      @drop="drop(account)"
      @editLogo="editLogo(account)"
      @editPrimaryContact="editPrimaryContact(account)"
    />
  </div>
</template>

<script>
  import row from './row.vue';

  export default {
    name: 'AccountsList',
    components: {row},
    props: {
      accounts: {
        type: Array,
        required: true
      },
      userAccount:{
        type: Object,
        required: true
      },
      currentUserIsAdmin: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      edit(account) {
        this.$emit('edit', account);
      },
      assignments(account) {
        this.$emit('assignments', account);
      },
      drop(account){
        return this.$emit('drop', account);
      },
      isCurrentAccount(account) {
        return account.id === this.userAccount.accountId;
      },
      editLogo(account) {
        return this.$emit('editLogo', account);
      },
      editPrimaryContact(account) {
        return this.$emit('editPrimaryContact', account);
      },
      isAssignedToCurrentUser(account) {
        return account.primaryAgent.userId === this.userAccount.id;
      }
    }
  }
</script>

<style lang="stylus">

</style>
