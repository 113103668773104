export default RbvHotelDirectoryRepository

// default data for hotel directory
const columns = [
    {id: 'rating', text: 'Hotel Type', key: 'rating', default: true},
    {id: 'address', text: 'Address', key: 'company.location.address.address1', default: true},
    {id: 'distanceMi', text: 'Distance', key: 'analytics.distanceMi', default: true},
    {id: 'amenities', text: 'Amenities', key: 'amenities', default: true },
    {id: 'phone', text: 'Hotel Phone', key: 'company.phone', default: true},
    {id: 'code', text: 'Property Code', key: 'propertyCode', default: false}
];

RbvHotelDirectoryRepository.$inject = ['MainServer', '$q'];
function RbvHotelDirectoryRepository(mainServer, $q){
  const $repo = this;

    $repo.getHotelDirectory =
        (directoryId) => {return directoryId
        ? mainServer.get(`hotel-directories/get/${directoryId}`)
        : $q.reject({status: 404})};

    $repo.createHotelDirectoryWithDefaultConfigs = account => {
        return mainServer.post(
            `hotel-directories/create`,
            getDefaultHotelDirectory(account)
        );
    };

    $repo.updateView = (directoryId, view) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/view`,
            view
        );
    };

    $repo.createView = (directoryId, name, account) => {
        const view = createDefaultView(account, name);
        return name && mainServer.post(
            `hotel-directories/${directoryId}/create-view`,
            view
        );
    };

    $repo.deleteView = view => {
        return view && mainServer.delete(
            `hotel-directories/views/${view.id}/delete`
        );
    };

    $repo.getColumns = () => {
        return columns;
    };

    $repo.getAllFinalAgreementHotels = () => mainServer.get(`hotel-directories/all-final-agreement-hotels`);

    $repo.addHotels = (bids, directoryId) => {
        return directoryId && bids
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/add-hotels`, {bids}
            )
            : $q.reject({status: 404})
    };

    $repo.removeHotels = (bids, directoryId) => {
        return directoryId && bids
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/remove-hotels`, {bids}
            )
            : $q.reject({status: 404})
    };

    $repo.addUsers = (users, directoryId, message) => {
        return directoryId && users
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/add-users`,
                { users, message}
            )
            : $q.reject({status: 404})
    };

    $repo.getAddedUsers = (directoryId) => {
        return directoryId
            ? mainServer.get(`hotel-directories/${directoryId}/added-users`)
            : $q.reject({status: 404})
    };

    $repo.updateUser = (directoryId, user) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/user`,
            user
        );
    };

    $repo.assignView = (users, viewId, directoryId) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/assign-user-views`,
            {users, viewId}
        );
    };

    $repo.resendUserLink = (users, directoryId, message) => {
        return mainServer.put(
            `hotel-directories/link/${directoryId}/resend/`,
            {users, message}
        );
    };

    $repo.refreshUserLink = (userId, directoryId, message) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/refresh-link/${userId}`,
            {...message}
        );
    };

    $repo.removeUsers = (users, directoryId) => {
        return directoryId && users
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/remove-users`, {users}
            )
            : $q.reject({status: 404})
    };

    $repo.deactivateUserLink = (userId, directoryId) => {
        return directoryId && userId
            ? mainServer.delete(
                `hotel-directories/update/${directoryId}/user/${userId}/break-link`
            )
            : $q.reject({status: 404})
    };

    $repo.saveTravelPolicy = (directoryId, file, progressCallback) => {
        const fd = new FormData();
        fd.append('file', file);
        return directoryId && file
            ? mainServer.sendFile(`hotel-directories/update/${directoryId}/travel-policy`, fd, progressCallback)
            : Promise.reject({status: 404})
    };

    $repo.deleteTravelPolicy = (directoryId) => {
        return directoryId
            ? mainServer.delete(`hotel-directories/update/${directoryId}/delete-travel-policy`)
            : Promise.reject({status: 404})
    };

    $repo.getViewHotels = (directoryId, viewId) => {
        return directoryId && viewId
            ? mainServer.get(`hotel-directory-view/${directoryId}/hotels/${viewId}`)
            : $q.reject({status: 404})
    };

    $repo.getTravelDestinations = (directoryId) => {
      return directoryId
        ? mainServer.get(`hotel-directory-view/${directoryId}/travel-destinations`)
        : $q.reject({status: 404})
    };

    $repo.sendLinkToSelf = (viewId, messageRequest) => {
        return messageRequest && viewId
            ? mainServer.put(`hotel-directories/send-link-to-self/${viewId}`, messageRequest)
            : $q.reject({status: 404})
    };

    $repo.startExploring = () => mainServer.put(`hotel-directories/start-exploring-directory`);

    $repo.extendTrial = () => mainServer.put(`hotel-directories/extend-trial`);

    $repo.optInToPay = data => {
      return data
        ? mainServer.put(`hotel-directories/update-opting-for-payment`, data)
        : $q.reject({status: 404})
    };

    $repo.addTutorial = tutorial => {
      return tutorial
        ? mainServer.put(`hotel-directories/update-user-tutorial/${tutorial}`)
        : $q.reject({status: 404})
    };

    function getDefaultHotelDirectory(account){
        return {
           travelPolicy: '',
           defaultUserMessage: `${account.name} invites you to access our Hotel Directory for 2019. The directory provides an up to date listing of all our preferred hotels in our US and global markets. We do expect you to use these hotels when ever you make travel arrangements for ${account.name}.
                We also encourage you to review our cooperate travel policy which outlines and explains best practices when travelling and representing our company.
                We appreciate your adherence to these rules and wish you safe and prosperous travels. Feel free to contact me at anytime should you have any questions.
            `,
            views: [
                createDefaultView(account, 'Traveler View'),
                createDefaultView(account, 'Travel Agency View', true),
            ]
        }
    }

    function createDefaultView(account, name, isTravelAgency = false){
        const date = new Date();
        return {
            name,
            columns: !isTravelAgency? columns.filter(column => column.default).map(column => column.id): columns.filter(column => column.default).map(column => column.id).concat('sabrecode'),
            header: `${account.name} ${+date.getFullYear()+1} Hotel Directory`,
            subHeader: '',
            showSubHeader: true,
            warning: '',
            showWarning: true,
            background: {
                type: "IMAGE",
                value: 'https://images.pexels.com/photos/268819/pexels-photo-268819.jpeg?auto=compress&cs=tinysrgb&h=650&w=940'
            },
            hotelTypes: ["L", "UU", "U", "UM", "M", "E"],
            rates: ['SS', 'SD', 'US', 'UD', 'DS', 'DD', 'DYN']
        }
    }
}
