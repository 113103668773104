/**
 * Created by DejanK on 4/3/2017.
 */
import angular from 'angular'
import HotelRfpBid from './bid/rfp-hotel.bid.module'
import HotelRfpRfp from './rfp/rfp-hotel.rfp.module'
import States from './rfp-hotel.states'

export default angular.module('rb.rfpHotel.main', [
  HotelRfpRfp.name,
  HotelRfpBid.name
  ])
    .config(States)
