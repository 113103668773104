/**
 * Created by DejanK on 10/04/2016.
 */
import './verify-email.scss'
import { CHECKING} from "../../../ui-ng/notifications/dialogs/working/working.ctrl";
import HTML from './verify-email.html'

export { HTML, VerifyEmailController as Controller }

VerifyEmailController.$inject = ['NotificationUtils', 'PublicAuth', '$stateParams'];
function VerifyEmailController(notificationUtils, publicAuth,$stateParams){
  init();

  function init(){
    notificationUtils.onSave(()=>{ return publicAuth.verifyEmailAddress($stateParams.token) }, {
      workingDialogLabel: CHECKING
    })
      .then(onTokenVerified, onInvalidToken)

    function onTokenVerified(){ notificationUtils.showSuccess('email-address-verified') }

    function onInvalidToken(error){ notificationUtils.showError('email-address-verification-failed', error) }
  }
}
