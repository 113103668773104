/**
 * Created by DejanK on 7/21/2017.
 */
import angular from 'angular';
import './edit/negotiations-edit.scss';

import rbNegotiationView from './view/rb-negotiation-view/rb-negotiation-view.cmp';
import rbNegotiationsEdit from './edit/rb-negotiations-edit/rb-negotiations-edit.cmp';
import rbNegotiationsInputAmenityTooltip from './edit/rb-negotiations-input-amenity-tooltip/rb-negotiations-input-amenity-tooltip.cmp';
import rbNegotiationsInputTaxes from './edit/rb-negotiations-input-taxes/rb-negotiations-input-taxes.cmp';
import rbNegotiationsInputTcos from './edit/rb-negotiations-input-tcos/rb-negotiations-input-tcos.cmp';
import rbNegotiations from './rb-negotiations/rb-negotiations.cmp';
import rbNegotiationsPanelViewActions from './view/rb-negotiations-panel-view-actions/rb-negotiations-panel-view-actions.cmp';
import rbNegotiationsView from './view/rb-negotiations-view/rb-negotiations-view.cmp';

export default angular.module('rb.rfp.bidManager.main.ui.negotiations', [])
  .component('rbNegotiations', rbNegotiations)
  .component('rbNegotiationsPanelHeader', require('./rb-negotiations-panel-header/rb-negotiations-panel-header.cmp'))
  .component('rbNegotiationsPanelData', require('./rb-negotiations-panel-data/rb-negotiations-panel-data.cmp'))

  .component('rbNegotiationsView', rbNegotiationsView)
  .component('rbNegotiationsPanelViewActions', rbNegotiationsPanelViewActions)
  .component('rbNegotiationView', rbNegotiationView)
  .component('rbNegotiationViewRateCell', require('./view/rb-negotiation-view-rate-cell/rb-negotiation-view-rate-cell.cmp'))
  .component('rbNegotiationViewAmenityCell', require('./view/rb-negotiation-view-amenity-cell/rb-negotiation-view-amenity-cell.cmp'))
  .component('rbNegotiationViewTaxesCell', require('./view/rb-negotiation-view-taxes-cell/rb-negotiation-view-taxes-cell.cmp'))
  .component('rbNegotiationViewTcosCell', require('./view/rb-negotiation-view-tcos-cell/rb-negotiation-view-tcos-cell.cmp'))
  .component('rbNegotiationsRateHistory', require('./view/rb-negotiations-rate-history/rb-negotiations-rate-history.cmp'))
  .component('rbNegotiationsAmenityHistory', require('./view/rb-negotiations-amenity-history/rb-negotiations-amenity-history.cmp'))
  .component('rbNegotiationsTaxesSummary', require('./view/rb-negotiations-taxes-summary/rb-negotiations-taxes-summary.cmp'))
  .component('rbNegotiationsTcosHistory', require('./view/rb-negotiations-tcos-history/rb-negotiations-tcos-history.cmp'))

  .component('rbNegotiationsEdit', rbNegotiationsEdit)
  .component('rbNegotiationsPanelEditActions', require('./edit/rb-negotiations-panel-edit-actions/rb-negotiations-panel-edit-actions.cmp'))
  .component('rbNegotiationsInputRate', require('./edit/rb-negotiations-input-rate/rb-negotiations-input-rate.cmp'))
  .component('rbNegotiationsInputRateTooltip', require('./edit/rb-negotiations-input-rate-tooltip/rb-negotiations-input-rate-tooltip.cmp'))
  .component('rbNegotiationsInputAmenity', require('./edit/rb-negotiations-input-amenity/rb-negotiations-input-amenity.cmp'))
  .component('rbNegotiationsInputAmenityTooltip', rbNegotiationsInputAmenityTooltip)
  .component('rbNegotiationsInputTcos', rbNegotiationsInputTcos)
  .component('rbNegotiationsInputTcosTooltip', require('./edit/rb-negotiations-input-tcos-tooltip/rb-negotiations-input-tcos-tooltip.cmp'))
  .component('rbNegotiationsInputTaxes', rbNegotiationsInputTaxes)
  .component('rbNegotiationsInputTaxesTooltip', require('./edit/rb-negotiations-input-taxes-tooltip/rb-negotiations-input-taxes-tooltip.cmp'));
