<template>
  <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">
        <div>Assign User Category</div>
        <div :class="$style.subHeader">Select the category to be assigned to the selected users</div>
    </span>
    <div slot="body" class="vModalDefaultBody hotel-directory" :class="$style.body">
        <div :class="$style.table">
            <div :class="$style.tableHead">
                <div :class="$style.cell">User Name</div>
                <div :class="$style.cell">Category</div>
                <div :class="$style.cellAction">Actions</div>
            </div>
            <div :class="$style.tableBody">
                <div :class="$style.row" v-for="user in data.users" :key="user.id">
                    <div :class="$style.cell">
                        {{user.firstName}} {{user.lastName}}
                    </div>
                    <div :class="$style.cell">
                        {{category(user.directoryViewId).name || "___"}}
                    </div>
                    <div :class="$style.cellAction">
                        <i @click="drop(user.id)" :class="$style.action" class="material-icons">delete</i>
                    </div>
                </div>
            </div>
        </div>
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.view}" :forId="'view'" :error="$showError('view')">
                <span slot="label">User Category<i>*</i></span>
                <i class="material-icons icon">info</i>
                <rb-select class="simple" 
                    v-model="data.view"
                    v-validate="'required'"
                    id="view"
                    name="view"
                    data-vv-as="User Category">
                    <rb-option v-for="view in views" :key="view.id" :value="view.id">{{view.name}}</rb-option>
                </rb-select>
            </rb-input>
        </div>
    </div>

    <div slot="footer" class="vModalDefaultFooter">
        <button class="rbButton" type="button" @click="cancel()">Cancel</button>
        <button class="rbButton mainButton">                
            <i class="material-icons">save</i> 
            Save Changes
        </button>
    </div>
  </modal-form>
</template>

<script>
  
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import RbSelect from 'root/v-components/_core/RbSelect.vue'

  export default {
    name: 'ManageViewDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['views', 'users'],
    components: { RbInput, ModalForm, RbSelect },
    data() {
       return {
            data : {
                view: '',
                users: this.users
            },
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        },
        category(id){
            return this.views.find(view => view.id === id) || {};
        },
        drop(id){
            this.data.users = this.data.users.filter(user => user.id !== id);
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        min-width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .body{
        padding 0;
    }

    .group{
        padding: 1px 10px;
        background: #F5F6F7;
    }

    .row{
        display flex;
        align-items center;
        border-bottom 1px solid #ECEFF1
    }

    .row:hover{
        background #ECEFF1;
    }

    .action{
        cursor pointer;
        border-radius 100%;
        padding 3px;
        font-size 20px
    }

    .row:hover .action{
        background #fff;
    }



    .tableHead{
        composes row;
        padding 5px;
    }

    .cell{
        flex 1;
        font-size: 12px;
        padding: 3px;
        height: 30px;
        align-items: center;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #546E7A;
        box-sizing: border-box;
    }

    .cell + .cell{
        margin-right 5px;
    }

    .cellAction{
        composes cell;
        flex-basis: 45px;
        max-width 45px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .tableBody{
        border 2px solid #B0BEC5;
        max-height 210px;
        overflow-y auto;
    }

    .table{
        margin 5px 10px;
    }
</style>
