/**
 * Created by DejanK on 7/6/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = NegotiationFactory;

NegotiationFactory.$inject = ['RateFactory', 'AmenityFactory', 'TaxesFactory', 'TcosFactory'];
function NegotiationFactory(RateFactory, AmenityFactory, TaxesFactory, TcosFactory){

  function Negotiation(negotiationData, negotiations){
    angular.merge(this, negotiationData);
    this.negotiations = negotiations;
    let values = this.values;

    loadRates(values.rates, this, negotiations);
    loadAmenities(values.amenities, this, negotiations);
    //loadAmenitiesTotal(values.totalAmenities, this, negotiations);

    values.analytics = {};
    loadTaxes(values, this, negotiations);
    loadTcos(values, this, negotiations);

    function loadRates(negotiationRates, negotiation, negotiations) {
      let rateKeys = Object.keys(negotiationRates);
      for(let i=0, l=rateKeys.length; i<l; i++){
        let key = rateKeys[i], rate = negotiationRates[key];
        if(key !== 'dyn') {
          negotiationRates[key] = RateFactory.create(key, rate, negotiation, negotiations);
        }
      }
    }

    function loadAmenities(negotiationAmenities, negotiation, negotiations){
      let createAmenity = AmenityFactory.create,
        amenitiesInView = negotiations.getAmenities();

      for(let i=0,l=amenitiesInView.length; i<l; i++){
        let amenityId = amenitiesInView[i];
        negotiationAmenities[amenityId] = createAmenity(amenityId, negotiationAmenities[amenityId], negotiation, negotiations);
      }
    }

    function loadTaxes(values, negotiation, negotiations){
      values.taxes.total = values.taxesTotal;
      values.analytics.taxes = TaxesFactory.create(values.taxes, negotiation, negotiations);
    }

    function loadTcos(values, negotiation, negotiations){
      values.analytics.tcos = TcosFactory.create(values.totalCosts, negotiation, negotiations);
    }
  }

  Negotiation.prototype.getCompanyName = function(){
    return this.from.companyName;
  };

  Negotiation.prototype.getContactName = function(){
    return `${this.from.firstName} ${this.from.lastName}`
  };

  Negotiation.prototype.getFormattedDateTime = function(){
    return this.negotiations.dateTimeFormatter.format(this.at);
  };

  Negotiation.prototype.getRate = function(rateId, season, roomType){
    let rate = rateId;
    if(arguments.length > 1){ rate += `_s${season}_rt${roomType}` }
    return this.values.rates[rate];
  };

  Negotiation.prototype.getPrimaryRateValue = function(){
    return this.getRate(this.negotiations.config.rates[0], 1, 1).value;
  };

  Negotiation.prototype.getAmenity = function(amenityName){
    return this.values.amenities[amenityName]
  };

  Negotiation.prototype.getTaxesSummary = function(){
    return this.values.analytics.taxes
  };

  Negotiation.prototype.getTcos = function(){
    return this.values.analytics.tcos
  };


  Negotiation.prototype.getAllValues= function(){
    let values = {};
    //angular.forEach(this.values, (group, groupName)=>{
    angular.forEach({rates: this.values.rates, amenities: this.values.amenities, analytics: this.values.analytics}, (group, groupName)=>{
      let valueGroup = {};
      angular.forEach(group, (item, key)=>{
        item && item.getValue && (valueGroup[key] = item.getValue());
      });
      values[groupName] = valueGroup;
    });
    return values;
  };

  return {
    create: (negotiationData, negotiations)=>{
      return new Negotiation(negotiationData, negotiations);
    }
  }
}
