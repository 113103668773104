/**
 * Created by DejanK on 2/11/2017
 *
 * Questionnaire Navigator
 */
'use strict';

module.exports = rbQuestionnaireNavTarget;

rbQuestionnaireNavTarget.$inject = ['$document', '$interval', '$q'];
function rbQuestionnaireNavTarget($document, $interval, $q) {
  return {
    restrict: 'A',
    require: '^^rbQuestionnaireNav',
    scope: {},
    link: LinkFn
  };

  function LinkFn($scope, $element, $attrs, questionnaireNavCtrl) {
    let _getElementAttemptIntervalPromise, target, targetId;

    $attrs.$observe('rbQuestionnaireNavTarget', onTargetChanged);

    $scope.$on('$destroy', cancelGetElementAttempt);
    $scope.$on('RbQuestionnaireEditorPreview', refreshNavigation);

    function onTargetChanged(val) {
      target = null;
      targetId = val;
      hideNavLink();
      val && getElement(val)
        .then(setupQuestionnaireNav);
    }

    function setupQuestionnaireNav(targetElement) {
      target = targetElement;
      showNavLink();
      setupTargetScrollPositionObserver();
    }

    function getElement(val) {
      cancelGetElementAttempt();
      let elementDefer = $q.defer();
      _getElementAttemptIntervalPromise = $interval(getElementAttempt, 100);

      return elementDefer.promise;

      function getElementAttempt() {
        let el = $document[0].getElementById(val);
        if (el) {
          cancelGetElementAttempt();
          elementDefer.resolve(angular.element(el));
        }
      }
    }

    function cancelGetElementAttempt() {
      _getElementAttemptIntervalPromise && $interval.cancel(_getElementAttemptIntervalPromise);
      _getElementAttemptIntervalPromise = undefined;
    }

    function hideNavLink() {
      $element.addClass('disabled');
      $element.css('display', 'none');
    }

    function showNavLink() {
      $element.removeClass('disabled');
      $element.css('display', 'block');
      $element.on('click', ()=> {
        target && target[0].scrollIntoView();
      })
    }

    function setupTargetScrollPositionObserver() {
      questionnaireNavCtrl.registerNav(targetId, $scope, target, $element);
    }

    function refreshNavigation(event, args){
      if(args.preview){
        target.hasClass('off') ? hideNavLink() : showNavLink();
      } else {
        showNavLink();
      }
    }
  }
}
