import angular from 'angular'

import States from './states'
import RbChainNag from './components/rb-chain-nag/rb-chain-nag.cmp'
import RbRespondNag from './components/rb-respond-nag/rb-respond-nag.cmp'
import RbRespondedNag from './components/rb-responded-nag/rb-responded-nag.cmp'

export default angular.module('rb.public-bid', [ ])
  .config(States)
  .component('rbChainNag', RbChainNag)
  .component('rbRespondNag', RbRespondNag)
  .component('rbRespondedNag', RbRespondedNag)
