/**
 * Created by DejanK on 3/23/2017.
 *
 * Creates
 */

'use strict';

module.exports = BidManagerRedirectService;

BidManagerRedirectService.$inject = ['ControllerUtils', 'CurrentUser'];
function BidManagerRedirectService(utils, currentUser){
  let $service = this;

  $service.toDefaultView = toDefaultView;

  function toDefaultView(){
    return currentUser.getActiveUserAccount()
      .then((account)=>{
        let view = getView(account);
        if (view) {
          utils.go('bidManager', {viewId: view, bid: '', 'user-account': ''});
        } else {
          utils.go('rfpManager');
        }
      })
      .catch(toCreateAccount);

    function getView(account) {
      return account && (account.lastBidManagerViewId || account.defaultBidManagerViewId);
    }

    function toCreateAccount(){
      utils.go('account.create.affiliation');
    }
  }
}
