'use strict';

export default {
  template: require('./rb-questionnaire-form-side-menu.html'),
  bindings: {
    saveDraftQuestionaire: '&'
  },
  controller: EditQuestionaireSideMenu
};

EditQuestionaireSideMenu.$inject = ['$state'];
function EditQuestionaireSideMenu($state){
  const vm = this;

  vm.onCoverLetterClicked = onCoverLetterClicked;
  vm.isQuestionnaire = $state.current.name === 'hotelRfp.bid.response';

  function onCoverLetterClicked(){
    vm.saveDraftQuestionaire && vm.saveDraftQuestionaire();
  }
}
