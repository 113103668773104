/**
 * Created by DejanK on 4/5/2017.
 */
import './bid-manager.scss'
import HTML from './bid-manager.html'
import { noop, get, find, isUndefined, merge } from 'lodash'

export { HTML, BidManagerController as Controller }

BidManagerController.$inject = ['$scope', 'BidManagerRedirectService', 'BidManagerService', 'BidManagerViewService', 'BidManagerBidsParserService', 'NegotiationsPanelMessagingService', '$q', 'NotificationUtils', '$state', 'InvitationService', '$timeout'];
function BidManagerController($scope, bidManagerRedirectService, bidManagerService, bidManagerViewService, bidManagerBidsParserService, negotiationsPanelMessagingService, $q, notificationUtils, $state, invitationService, $timeout ){
  const vm = this;
  vm.bm = {};
  const bidManager = vm.bm;

  vm.onViewChanged = onViewChanged;
  vm.onGroupByChanged = onGroupByChanged;
  vm.onFilterByChanged = onFilterByChanged;
  vm.onSortByChanged = onSortByChanged;
  vm.onBidSelectionChange = onBidSelectionChange;
  vm.onActionExecuted= onActionExecuted;

  $onInit();

  function $onInit(){
    bidManager.selectedBids = [];
    $timeout(() => {
      invitationService.assureAccount($state.params.account)
        .then(loadBidManager, loadBidManager)
        .then(performPostLoad, noop);
    });

    function loadBidManager(){
      $scope.$on('RB_BID_MANAGER_VIEW_CHANGED', refresh);

      bidManager.viewId = $state.params.viewId;
      const rfpId = $state.params.rfp;

      if (bidManager.viewId) {
        return notificationUtils.onLoad(() => bidManagerService.loadView(bidManager.viewId))
      } else if (rfpId) {
        return notificationUtils.onLoad(() => bidManagerService.loadViewByRfpId(rfpId))
      } else {
        return $q.reject(bidManagerRedirectService.toDefaultView())
      }

      function refresh(event, data){
        bidManager.view = data.view;
        bidManager.bids = bidManagerBidsParserService.applyQuickiesChangeOnLastResponse(bidManager.view);
        $timeout(() => { bidManager.filters = bidManagerViewService.getFilterByOptions(bidManager.view) })
      }
    }

    function performPostLoad(){
      setGroups();
      loadViews();
      $timeout(() => { showNegotiations($state.params.negotiations) });

      function setGroups(){
        bidManager.groupableColumns = bidManagerViewService.getGroupByOptions(bidManager.view);
        bidManager.groupedBy = get(bidManager.view, 'group.id', null);
        return bidManager.groupedBy && onGroupByChanged({groupBy: bidManager.groupedBy});
      }

      function loadViews(){
        notificationUtils.onLoad(() => bidManagerService.getViews(), vm, 'views')
      }

      function showNegotiations(bidId){
        return bidId && negotiationsPanelMessagingService.openNegotiationsPanel(bidId);
      }
    }
  }

  function onGroupByChanged(event){
    bidManager.filteredBy = undefined;
    bidManager.filters = undefined;
    bidManager.view.quickies = bidManager.view.quickies || {};
    bidManager.view.quickies.group = find(bidManager.groupableColumns, {id: event.groupBy});
    bidManager.groupedBy = bidManager.view.quickies.group ? bidManager.view.quickies.group.id : undefined;
    bidManager.view.quickies.filter = undefined;

    bidManager.bids = bidManagerBidsParserService.applyQuickiesChangeOnLastResponse(bidManager.view);
    $timeout(() => { bidManager.filters = bidManagerViewService.getFilterByOptions(bidManager.view) })
  }

  function onViewChanged(event){ $state.go('bidManager', {viewId: event.view.id}) }

  function onFilterByChanged(event){
    bidManager.view.quickies = isUndefined(event.filterBy)
      ? merge({}, bidManager.view.quickies, {filter: null})
      : merge({}, bidManager.view.quickies, {filter: {value: event.filterBy}});
    bidManager.bids = bidManagerBidsParserService.applyQuickiesChangeOnLastResponse(bidManager.view);
  }

  function onSortByChanged($event) {
    const currentSort = get(bidManager.view.quickies, 'sort'),
      sortBy = $event.sortBy;

    if (!currentSort || currentSort.by.id !== sortBy.id) {
      bidManager.view.quickies = merge({}, bidManager.view.quickies, {sort: {by: sortBy, direction: 1}});
    } else {
      currentSort.direction = currentSort.direction * -1;
    }

    bidManager.bids = bidManagerBidsParserService.applyQuickiesChangeOnLastResponse(bidManager.view);
  }

  function onBidSelectionChange(event){
    bidManager.selectedBids = event.selectedBids;
  }

  function onActionExecuted(){
    bidManager.clearSelectedBids = {};
    bidManager.selectedBids = [];
  }
}
