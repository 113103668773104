/**
 * Created by DejanK on 1/8/2017.
 */

'use strict';

module.exports = RbAnchorScrollService;

RbAnchorScrollService.$inject = ['$location', '$anchorScroll', '$timeout'];
function RbAnchorScrollService($location, $anchorScroll, $timeout){
  let $service = this;

  $service.scroll = (hashId) => {
    $timeout(()=>{
      $location.hash(hashId);
      $anchorScroll();
    });
  }
}
