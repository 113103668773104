import {checkAndExecute} from 'root/api/helpers';

export default function HotelRfpBidResponseAPI(api) {
  Object.assign(api, {

    previewRfp: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/${rfpId}/preview`)),

    updateRateQuickRfp: ( rfpId, payload ) => checkAndExecute(
      () => rfpId && payload,
      () => api.server.put( `rfps/hotel/rate-quick/${rfpId}`, payload )),

    getHotelRfpCoverLetterTemplate: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/${rfpId}/cover-letter`)),

    getHotelRfpNamCoverLetterTemplate: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`rfps/hotel/${rfpId}/nam-cover-letter/template`)),

    getLetterTemplateForHotelRateQuickRFPChainRepresentative: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.get(`templates/letters/hotel-rfp-rate-quick-nam-cover-letter-template`)),

    setHotelRfpCoverLetterTemplate: (rfpId, template) => checkAndExecute(
      () => rfpId && template,
      () => api.server.put(`rfps/${rfpId}/cover-letter`, { template })),

    setHotelRfpNamCoverLetterTemplate: (rfpId, template) => checkAndExecute(
      () => rfpId && template,
      () => api.server.put(`rfps/hotel/${rfpId}/nam-cover-letter/template`, { template })),

    getHotelRfpCoverLetterPlaceholders: () => api.data.getHotelRfpCoverLetterPlaceholders(),

    getHotelRfpNamCoverLetterPlaceholders: () => api.data.getHotelRfpNamCoverLetterPlaceholders(),

    enableChainSupportOnRfp: rfpId => checkAndExecute(
      () => rfpId,
      () => api.server.post(`rfps/hotel/${rfpId}/chain-support`, {enableChainSupport: true})),
  })
}
