/**
 * Created by DejanK on 11/21/2016.
 */
import User from './user.factory'
import UserAccount from './user-account.factory'

import { find } from 'lodash'

const CURRENT_USER_CHANGED_EVENT = 'rbCurrentUserChanged'

export { CurrentUserService as default, CURRENT_USER_CHANGED_EVENT }

CurrentUserService.$inject = ['AuthorizationStorageService', 'UserRepository', '$rootScope', '$q'];
function CurrentUserService(authorizationStorageService, userRepository, $rootScope, $q) {
  const $service = this
  let refreshUserPromise = null
  /**
   * !!! TODO: this will prevent user from having different curUsers on diff browsers !!!
   */
  // refreshCurrentUser();

  $service.isSignedIn = isSignedIn;
  $service.get = getCurrentUser;
  $service.refresh = refreshCurrentUser;
  $service.forget = forgetAuthData;
  $service.getActiveUserAccount = getActiveUserAccount;
  $service.getUserAccount = getUserAccount;
  $service.listUserAccounts = userRepository.listCurrentUserUserAccounts
  $service.switchToAccount = switchToAccount
  $service.watchUser = watchUser

  $service.disableTutorial = userRepository.disableTutorial
  $service.upateBasicUserInformation = updateBasicUserInformation
  $service.updatePassword= updateUserPassword
  $service.updateEmailAddress = updateUserEmailAddress
  $service.updateProfilePicture = updateProfilePicture;

  function getActiveUserAccount(){
    return getCurrentUser()
      .then(getCurrentUserAccount);

    function getCurrentUserAccount(user){
      return user && user.currentUserAccount ? user.currentUserAccount : $q.reject();
    }
  }

  function isSignedIn(){
    return !!authorizationStorageService.getToken();
  }

  function getCurrentUser(){
    const currentUser = authorizationStorageService.getUser()
    return $q.when(currentUser ? User(currentUser) : refreshCurrentUser())
  }

  function forgetAuthData(){
    authorizationStorageService.forget();
    broadcastCurrentUser(null);
  }

  function refreshCurrentUser(){
    if(!refreshUserPromise) {
      refreshUserPromise = userRepository.getCurrentUser()
        .then(saveCurrentUser)
        .then(User)
        .then(broadcastCurrentUser, handleError)
        .finally(() => { refreshUserPromise = null })
    }
    return refreshUserPromise;

    function saveCurrentUser(serverResponse){
      const currentUser = serverResponse.data;
      authorizationStorageService.saveUser(currentUser);
      return currentUser;
    }

    function handleError(){
      forgetAuthData();
      return $q.reject(null);
    }
  }

  function broadcastCurrentUser(user){
    $rootScope.$broadcast(CURRENT_USER_CHANGED_EVENT, user);
    return user;
  }

  function switchToAccount(accountId) {
    return userRepository.switchToAccount(accountId)
      .then(refreshCurrentUser)
  }

  function watchUser($scope, vm, userKey){
    registerScopeToListenToUserChanges()
    return getCurrentUser().then( user => { vm[userKey || 'user'] = user } )

    function registerScopeToListenToUserChanges(){
      $scope.$on(CURRENT_USER_CHANGED_EVENT, (event, user) => { vm[userKey || 'user'] = user })
    }
  }

  function getUserAccount(userAccountId){
    return listCurrentUserUserAccounts()
      .then((httpResponse)=>{
        const userAccount = find(httpResponse.data, {id: userAccountId});
        return userAccount ? userAccount : $q.reject();
      })
  }

  function listCurrentUserUserAccounts(){
    return userRepository.listCurrentUserUserAccounts()
      .then((httpResponse)=>{
        for(let i=0, l = httpResponse.data.length; i<l; i++){
          httpResponse.data[i] = UserAccount(httpResponse.data[i]);
        }
        return httpResponse;
      })
  }

  function updateBasicUserInformation(data){
    return userRepository.updateBasicProfile(data)
      .then(refreshCurrentUser);
  }

  function updateProfilePicture(data){
    const fd = new FormData();
    fd.append('file', data.file);
    return userRepository.updateProfilePicture(fd)
      .then(refreshCurrentUser);
  }

  function updateUserEmailAddress(data){
    return userRepository.updateUserEmailAddress(data)
      .then(refreshCurrentUser)
  }

  function updateUserPassword(data){
    return userRepository.updateUserPassword(data)
      .then(refreshCurrentUser)
  }
}
