/**
 * Created by DejanK on 4/3/2017.
 */
export default RfpHotelRfpRepository

RfpHotelRfpRepository.$inject = ['MainServer', 'DataServer', '$q'];
function RfpHotelRfpRepository(mainServer, dataServer, $q){
  const $repo = this;

  $repo.getRfpPreview =
    (rfpId) => {return rfpId ? mainServer.get(`rfps/${rfpId}/preview`) : $q.reject({status: 404})};

  $repo.updateRfpName =
    (rfpId, name) => {return rfpId
      ? mainServer.put(`rfps/${rfpId}/specifications/name`, {name: name})
      : $q.reject({status: 404})};

  $repo.updateRfpDueDate =
    (rfpId, dueDate) => {return rfpId
      ? mainServer.put(`rfps/${rfpId}/specifications/due-date`, {dueDate: dueDate})
      : $q.reject({status: 404})};

  $repo.updateRfpProgramPeriod =
    (rfpId, programStartDate, programEndDate) => {return rfpId
      ? mainServer.put(`rfps/${rfpId}/specifications/program-period`, {programStartDate: programStartDate, programEndDate: programEndDate})
      : $q.reject({status: 404})};

  $repo.getQuestionnaireModel =
    (rfpId) => {return rfpId
      ? mainServer.get(`rfps/${rfpId}/questionnaire`)
      : $q.reject({status: 404})};

  $repo.updateQuestionnaireModel =
    (rfpId, questionnaireModel) => {return rfpId
      ? mainServer.put(`rfps/${rfpId}/questionnaire`, questionnaireModel)
      : $q.reject({status: 404})};

  $repo.getRfpFinalAgreementTemplate =
    (rfpId) => {return rfpId
      ? mainServer.get(`rfps/${rfpId}/final-agreement/template`)
      : $q.reject({status: 404})};

  $repo.updateRfpFinalAgreementTemplate =
    (rfpId, template) => {return rfpId
      ? mainServer.put(`rfps/${rfpId}/final-agreement/template`, {template: template})
      : $q.reject({status: 404})};

}
