/**
 * Created by DejanK on 7/20/2017.
 */

import angular from 'angular';
import { debounce, get } from 'lodash';

export default {
  template: '<input/>',
  bindings: {
    negotiations: '<',
    model: '<',
    season: '@',
    roomType: '@'
  },
  controller: Ctrl
};


// TODO: try to merge with NegotiationsModel Service once completed
Ctrl.$inject = ['$element', '$scope'];
function Ctrl($element, $scope) {
  const
    vm = this,
    throttledUpdateInputValue = debounce(updateInputValue, 200);

  let
    initialized,
    inputElement,
    inputHtmlElement,
    rateFormat,
    readOnly;


  this.$onChanges = initialize;

  function initialize(){
    if(!initialized && vm.model && vm.negotiations) {

      initialized = true;
      inputElement = angular.element($element.find('input')[0]);
      inputHtmlElement = inputElement[0];
      rateFormat = vm.negotiations.currencyFormat;

      readOnly = true;
      $element.addClass('readonly');
      inputElement.attr({
        'tabindex': '-1',
        'readonly': true
      });

      inputElement
        .on('input change', cleanupInput)
        .on('focus', parseElementValue)

      updateInputValue();
      $scope.$on('RB_NEGOTIATION_CHANGED', throttledUpdateInputValue)

    }
  }

  function updateInputValue(){
    const tcosValue = calculateTcos();
    inputHtmlElement.value = rateFormat.format(tcosValue);
  }

  function calculateTcos(){
    const
      rateValue = parseFloat(get(vm.model.rates, vm.negotiations.config.rates[0] + `_s${vm.season}_rt${vm.roomType}.value`)),
      taxesValue = calculateTotal(vm.model.analytics.taxes, rateValue),
      amenitiesValue = calculateTotal(vm.model.amenities, rateValue);
    return rateValue + taxesValue + amenitiesValue;

    function calculateTotal(values, rateValue){
      return Object.keys(values).reduce( ( sum, key ) => {
        let toAdd = 0;
        if(key !== 'vatGstFb' && key !== 'total'){
          const value = values[key];
          if(!value.included){
            toAdd = parseFloat(value.type === 'PERCENTAGE' ? rateValue * value.value : value.value);
          }
        }
        return sum + toAdd;
      }, 0);
    }
  }

  function cleanupInput(){
    inputHtmlElement.value = cleanValue(inputHtmlElement.value);
  }

  function cleanValue(value){
    return (value + '').replace(/[^0-9.]/g, '').slice(0, 15);
  }

  function parseElementValue(){
    if(!readOnly){
      const
        num = cleanValue(inputHtmlElement.value),
        result = parseFloat(num);

      inputHtmlElement.value = isNaN(result) ? '' : parseFloat(result.toFixed(2));
      inputHtmlElement.select();
    }
  }
}
