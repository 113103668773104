/**
 * All states should eventually be listed here
 */

import { abstractState, simpleState } from 'root/shared/tools/router-helper';
import { USER } from 'root/auth/auth-roles';
import noop from 'lodash/noop';
import PublicStates from './public-states'

import * as SelectRfpType from '../rfp/main/pages/select-rfp-type/select-rfp-type.ctrl';

const
  PAGE_COMPANY_PROFILE = 'company-profile',
  PAGE_RFP_MANAGER_COMPANY_PROFILE = 'rfp-manager-company-profile',
  PAGE_CURRENT_USER_PROFILE = 'user.profile',
  PAGE_HOTEL_DIRECTORY = 'hotel-directory',
  PAGE_SELECT_RFP_TYPE = 'createRfp.selectRfpType',
  PAGE_SELECT_RFP_TEMPLATE = 'createRfp.selectRfpTemplate',
  PAGE_DUPLICATE_RFP = 'duplicateRfp.select-template';

export { States as default,
  PAGE_CURRENT_USER_PROFILE,
  PAGE_COMPANY_PROFILE,
  PAGE_HOTEL_DIRECTORY,
  PAGE_SELECT_RFP_TYPE,
  PAGE_SELECT_RFP_TEMPLATE,
  PAGE_RFP_MANAGER_COMPANY_PROFILE,
  PAGE_DUPLICATE_RFP,
}

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  PublicStates($stateProvider);

  $stateProvider
    .state( 'user', abstractState('/user', USER))
    .state( PAGE_CURRENT_USER_PROFILE, simpleState('/profile', '<rb-header /><rbv-ng-user-profile />', noop, USER))
    .state( PAGE_COMPANY_PROFILE, simpleState('/company-profile?module,accountId', '<rb-header /><rbv-ng-account-profile />', noop, USER ) )
    .state( PAGE_RFP_MANAGER_COMPANY_PROFILE, simpleState('/rfp-manager/company-profile?module,accountId', '<rb-header /><rbv-ng-rfp-manager-account-profile />', noop, USER ) )
    .state( PAGE_HOTEL_DIRECTORY, simpleState('/hotel-directory?submodule,view', '<rb-hd-header /><rbv-ng-hotel-directory />', noop, USER ) )

    .state('createRfp', abstractState('/create-rfp', USER))
    .state( PAGE_SELECT_RFP_TYPE, simpleState('/select-type?accountId', SelectRfpType.HTML, SelectRfpType.Controller))
    .state( PAGE_SELECT_RFP_TEMPLATE, simpleState('/select-template?rfp-type,accountId', '<rb-header /><rbv-ng-select-rfp-template />', noop, USER))

    .state('duplicateRfp', abstractState('/duplicate-rfp', USER))
    .state(PAGE_DUPLICATE_RFP, simpleState('/select-template?rfpId' , '<rb-header /><rbv-ng-duplicate-rfp />'));
}
