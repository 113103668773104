/**
 * Created by DejanK on 7/7/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-taxes-summary.scss');

module.exports = {
  template: require('./rb-negotiations-taxes-summary.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    negotiationId: '@'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope', '$timeout'];
function Ctrl($element, $scope, $timeout) {
  let vm = this, tooltipster,
    htmlContentElement, parentElement;

  this.$onInit = ()=>{
    parentElement = $element.parent();
    htmlContentElement = $($element.children()[0]);

    parentElement.tooltipster({
      animationDuration: [300, 100],
      theme: ['', 'negotiations-taxes-summary'],
      content: htmlContentElement,
      contentAsHtml: true,
      interactive: true,
      distance: 0,
      side: ['left', 'right', 'top', 'bottom']
    });

    tooltipster = parentElement.tooltipster('instance');

    $scope.$on('RB_AMENITIES_FOCUSED', (event, data)=>{
      setEnabled(data.isFocused);
    })
  };

  this.$onChanges = ()=>{
    updateVM();
  };

  this.$onDestroy = ()=>{
    tooltipster.destroy();
  };

  function updateVM(){
    if(!checkRequirements()) return;
    if(!vm.negotiation){
      vm.negotiation = vm.negotiations.getNegotiationById(vm.negotiationId);
      if(!vm.negotiation) return;
    }

    vm.summary = vm.negotiation.getTaxesSummary();
    vm.taxes = vm.summary.getTaxes();
  }

  function checkRequirements(){
    return vm.negotiation || (vm.negotiations && vm.negotiationId);
  }

  function setEnabled(isEnabled){
    $timeout(()=>{
      isEnabled ? tooltipster.enable() : tooltipster.disable();
    })
  }
}
