/**
 * Created by DejanK on 11/03/2016.
 */
import '../../../../shared/styles/account-setup.scss'
import { noop } from "lodash"
import { stopEvent } from "../../../../shared/tools/view-utils"
import HTML from './select-account.html'

export { HTML , SelectAccountController as Controller }

SelectAccountController.$inject = ['NotificationUtils', 'AccountService', 'CurrentUser', 'rbDialog', '$stateParams'];
function SelectAccountController(notificationUtils, AccountService, currentUser, dialog, $stateParams){
  const vm = this;
  vm.confirmAccount = confirmAccount;
  vm.search = search;
  vm.loadMore = loadMore;

  $onInit();

  function $onInit(){
    vm.searchQuery = '';

    notificationUtils.onLoad(() => { return AccountService.getAccountType($stateParams.affiliation) },
      vm, 'affiliation' )
      .finally(() => { !vm.affiliation && notificationUtils.go('account.create.affiliation') })
  }

  function confirmAccount(event, account){
    stopEvent(event)

    dialog.show('confirmAccount', {locals: {account: account}})
      .then(saveAccount)
      .then(showSuccessPage, noop);

    function saveAccount(jobTitle){
      return notificationUtils.onSave(
        () => { return AccountService.addAccount({entityId: account.id, entityType: vm.affiliation.id, jobTitle: jobTitle}) },
        { customErrorHandlers: { '409' () { notificationUtils.showError('duplicate-account') } } })
    }

    function showSuccessPage(){
      currentUser.get()
        .then((user)=>{
          notificationUtils.showSuccess(user && user.getCreateAccountSuccessPage() || 'user-account-created')
        });
    }
  }

  function search(){
    if(vm.searchQuery){
      vm.loading = true;
      AccountService.searchEntities(vm.affiliation.id, vm.searchQuery)
        .then(loadResults);
    } else {
      vm.results = undefined;
    }

    function loadResults(results){
      vm.results = results;
      vm.results.page = 1;
      vm.results.hasMore = hasMore();
      vm.loading = false;
    }
  }

  function loadMore(){
    vm.loadingMore = true;
    AccountService.searchEntities(vm.affiliation.id, vm.searchQuery, vm.results.page+1)
      .then(addToResults);

    function addToResults(results){
      vm.results.data.push(...results.data);
      vm.results.count = results.count;
      vm.results.page++;
      vm.results.hasMore = hasMore();
      vm.loadingMore = false;
    }
  }

  function hasMore(){
    return vm.results.data.length < vm.results.count;
  }
}
