/**
 * Created by DejanK on 4/3/2017.
 */

// contains some style needed for map list
import './select-rfp-template.scss'
import angular from 'angular'

import Dialogs from './dialogs'
import rbPreviewTravelDestination from './rb-preview-travel-destination/rb-preview-travel-destination.cmp'
import TravelDestinationManagerFiltersService from './manager/filters.service'
import RfpHotelRfpDestinationsRepository from './rfp-hotel.rfp.destinations.repo'
import TravelDestinationManagerMapService from './manager/map/destinations-manager-map.service'
import TravelDestinationManagerService from './manager/destinations-manager.service'
import RfpDestinationsViewService from './view-destinations.service'
import rbDestinationsManagerSupplierProfileWindow from './manager/map/rb-destinations-manager-supplier-profile-window.cmp'
import rbDestinationsManagerMap from './manager/map/rb-destinations-manager-map.cmp'

export default angular.module('rb.rfpHotel.main.rfp.destinations', [])
    .config(Dialogs)
    .service('RfpHotelRfpDestinationsRepository', RfpHotelRfpDestinationsRepository)

    .service('RfpDestinationsViewService', RfpDestinationsViewService)
    .service('TravelDestinationManagerService', TravelDestinationManagerService)
    .service('TravelDestinationManagerFiltersService', TravelDestinationManagerFiltersService)
    .service('TravelDestinationManagerMapService', TravelDestinationManagerMapService)

    .component('rbDestinationsManagerMap', rbDestinationsManagerMap)
    .component('rbDestinationsManagerSupplierProfileWindow', rbDestinationsManagerSupplierProfileWindow)

    .component('rbPreviewTravelDestination', rbPreviewTravelDestination)
