function CreateActionReport(httpResponse){
  const reports = httpResponse.data.map(r => new ActionReport(r))
  return new ActionReport(reports)
}

function ActionReport(data){
  if(Array.isArray(data)) {
    this.children = data
  } else {
    Object.assign(this, data || {
      status: 'NOT_FOUND',
      tObject: null
    })
  }
}

ActionReport.prototype.validate = function validate(){
  return this.containsErrors() ? Promise.reject(this) : Promise.resolve(this)
}

ActionReport.prototype.containsErrors = function containsErrors(){
  return this.status ? this.status !== "OK" : this.children.some(c => c.containsErrors())
}

ActionReport.prototype.getStatus = function getStatus(){
  return this.status ? this.status : this.firstChild().getStatus()
}

ActionReport.prototype.firstChild = function firstChild(){
  return this.children && this.children[0] || new ActionReport()
}

ActionReport.prototype.getData = function getData(){
  return this.tObject ? this.tObject : this.firstChild().tObject
}

export { ActionReport as default, CreateActionReport }
