/**
 * Created by DejanK on 1/28/2017.
 */

'use strict';

module.exports = createRbFixedTableHeaderModule();

function createRbFixedTableHeaderModule(){
  let rbFixedTableHeaderModule = angular.module('rb.fixed-table-header', []);

  rbFixedTableHeaderModule
    .directive('rbFixedTableHeader', require('./rb-fixed-table-header.dir'))
  ;

  return rbFixedTableHeaderModule;
}
