import '../../../../public/ui/headers.scss'
import HTML from './rb-travel-destinations-map-header.html'

export default {
  template: HTML,
  transclude: true,
  controller: RbTravelDestinationsMapHeader,
  bindings: {
    linkClicked: '&',
    tableShowing: '<'
  }
};

RbTravelDestinationsMapHeader.$inject = ['$scope', 'CurrentUser'];
function RbTravelDestinationsMapHeader($scope, currentUser){
  const vm = this;

  vm.tableShowing = false;
  vm.toggleTable = toggleTable;

  this.$onInit = function onInit(){
    currentUser.watchUser($scope, vm);
  };

  function toggleTable(state) {
    vm.linkClicked({
      event: {
        state
      }
    });
  }
}
