/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Actions List
 */
'use strict';

module.exports = {
  template: require('./rb-bid-manager-actions-list.html'),
  bindings: {
    actions: '<',
    onAction: '&'
  },
  controller: ActionsListController
};

function ActionsListController(){
  let vm = this;

  vm.executeAction = executeAction;

  function executeAction(action){
    action();
    vm.onAction && vm.onAction();
  }
}
