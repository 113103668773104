/**
 * Created by DejanK on 8/02/2017.
 */
'use strict';

let lodash = require('lodash');

module.exports = {
  template: require('./rb-negotiation-view-tcos-cell.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    tcosId: '@'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope', 'NegotiationsHtmlFactory', '$rootScope'];
function Ctrl($element, $scope, NegotiationsHtmlFactory, $rootScope) {
  let vm = this;

  vm.$onInit = $onInit;
  vm.$onChanges = $onChanges;

  vm.onMouseEnter = onMouseEnter;
  vm.onMouseLeave = onMouseLeave;


  function $onInit(){
    $scope.$on('RB_NEGOTIATIONS_MOUSE_ENTER', onMouseEnterScopeEvent);
    $scope.$on('RB_NEGOTIATIONS_MOUSE_LEAVE', onMouseLeaveScopeEvent);
  }

  function $onChanges(changes){
    changes.negotiation && changes.negotiation.currentValue && vm.tcosId && setupVm(changes.negotiation.currentValue, vm.tcosId );

    function setupVm(negotiation, tcosId){
      vm.negotiation = negotiation;
      let tcosMap = negotiation.getTcos();
      vm.tcos = tcosMap[tcosId];
      vm.tcosValue = tcosMap.formatValue(vm.tcos.value);
      vm.tcosChangeClass = NegotiationsHtmlFactory.buildChangeNotificationClass(vm.tcos.change);
    }
  }

  function onMouseEnter(){
    $rootScope.$broadcast('RB_NEGOTIATIONS_MOUSE_ENTER', {cellId: vm.tcosId})
  }

  function onMouseLeave(){
    $rootScope.$broadcast('RB_NEGOTIATIONS_MOUSE_LEAVE', {cellId: vm.tcosId})
  }

  function onMouseEnterScopeEvent(event, data){
    if(data.cellId === vm.tcosId) $element.addClass('highlight');
  }

  function onMouseLeaveScopeEvent(event, data){
    if(data.cellId === vm.tcosId) $element.removeClass('highlight');
  }
}
