import './fill-response-only-once.scss'
import HTML from './fill-response-only-once.html'
import { noop, get } from 'lodash'
import { FillResponseOnlyOnce } from "../../../../../auth/tutorials.service";

export { FillResponseOnlyOnce as NAME, HTML, FillResponseOnlyOnceController as Controller }

FillResponseOnlyOnceController.$inject = ["RfpHotelBidRepository", "rbDialog", "$q", "NotificationUtils", "$stateParams", "TutorialsService"]
function FillResponseOnlyOnceController(RfpHotelBidRepository, dialog, $q, NotificationUtils, $stateParams, tutorials){
  const vm = this;

  vm.disableNotification = false;
  vm.chainName = "your chain";

  vm.close = closeDialog;

  $onInit();

  function $onInit(){
    NotificationUtils.onLoad(() => { return RfpHotelBidRepository.getSupplier($stateParams.bidId) })
      .then( (httpResponse) => { vm.chainName = get(httpResponse, "data.company.chain.name", vm.chainName) }, noop )
  }

  function closeDialog(){
    saveDisabledNotification()
      .finally(dialog.cancel)

    function saveDisabledNotification(){
      return vm.disableNotification ? tutorials.disable(FillResponseOnlyOnce) : $q.resolve()
    }
  }
}
