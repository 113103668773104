import Vue from 'vue';
import rfpManager from './rfpManager.vue';

export default {
  template: '<div></div>',
  controller: Ctrl,
  bindings: {
    user: '<',
  }
};

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;
  let vueComponent;


  vm.$onChanges = (changes) =>{
    initVueComponent();
  };

  vm.$onDestroy = () => { return vueComponent && vueComponent.$destroy(); };

  function initVueComponent(){
    return vm.user && !vueComponent && initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { user: vm.user },
        render: function (createElement) {
          return createElement(rfpManager, {
            props: {
              user: this.user,
            },
            on: {
              refreshAccounts() { vm.onRefreshAccounts()}
            }
          });
        }
      });
    }
  }
}
