/**
 * Created by DejanK on 2/22/2017
 *
 * Edit Header
 */
import "../../../../public/ui/headers.scss"
import HTML from './rb-edit-header.html'

export default {
  bindings: {
    menuWidth: '@'
  },
  transclude: {
    'left': '?leftPane',
    'center': 'centerPane',
    'right': '?rightPane'
  },
  template: HTML
}
