import { simpleState } from 'root/shared/tools/router-helper';
import * as RFPListController from './rfps';
import { USER } from 'root/auth/auth-roles';

const PAGE_RFP_MANAGER = 'rfpManager';

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state(PAGE_RFP_MANAGER, simpleState('/rfp-manager', RFPListController.HTML, RFPListController.Controller, USER))
}

export default States;

export {PAGE_RFP_MANAGER};
