<script>
  export default {
    methods: {
      getTemplate (id) {
        const template = this.template || this.questionnaire.template
        return template.cells.find((cell) => { return cell.id === id })
      },

      getModel (id) {
        const model = this.model || (this.questionnaire && this.questionnaire.model)
        return model ? model.cells.find((cell) => { return cell.id === id }) : undefined
      }
    }
  }
</script>
