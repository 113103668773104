/**
 * Created by DejanK on 7/14/2017.
 */
'use strict';

let lodash = require('lodash');

module.exports = {
  template: '<input />',
  bindings: {
    negotiations: '<',
    model: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsModelFactory', '$scope'];
function Ctrl($element, NegotiationsModelFactory, $scope) {
  let vm = this, model;

  this.$onChanges = initialize;

  function initialize() {
    if (!vm.negotiations || !vm.model || model) return;

    model = NegotiationsModelFactory.createRateModel(vm.model, $scope, $element, vm.negotiations.currencyFormat.format);
    $scope.$on('RB_REQUESTED_OF_ALL', function(event, data){
      model.resetAndReduceByPercent(data.percentage);
    });
  }
}
