/**
 * Created by DejanK on 2/10/2017
 *
 * Program Period Editor
 */

'use strict';

module.exports = {
  bindings: {
    show: '<',
    onClose: '&',
    programStartDate: '<',
    programEndDate: '<'
  },
  template: require('./rb-edit-program-period.html'),
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl(){
  let vm = this;
  vm.cancel = cancel;
  vm.apply = apply;
  vm.$onChanges = $onChanges;

  function $onChanges(changes){
    changes.programStartDate && resetProgramStartDateModel();
    changes.programEndDate && resetProgramEndDateModel();
  }

  function resetProgramStartDateModel(){
    vm.programStartDateModel = vm.programStartDate;
  }

  function resetProgramEndDateModel(){
    vm.programEndDateModel = vm.programEndDate;
  }

  function cancel(){
    resetProgramStartDateModel();
    resetProgramEndDateModel();
    vm.onClose({
      $event: {
        cancel: true,
        programStartDate: undefined,
        programEndDate: undefined
      }
    });
  }

  function apply(){
    vm.onClose({
      $event: {
        apply: true,
        programStartDate: vm.programStartDateModel,
        programEndDate: vm.programEndDateModel
      }
    });
  }
}
