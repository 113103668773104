import { merge } from 'lodash'

export default function ValueFactory (valueObject) { return new Value(valueObject) }

function Value(valueObject){
  merge(this, valueObject || {})
}

Value.prototype.print = function (percentageFormatter, currencyFormatter){
  if( this.isIncluded ) { return 'inc' }

  switch ( this.type ) {
    case 'PERCENTAGE':
      return percentageFormatter.format( this.amount )
    case 'FIXED':
      return currencyFormatter.format( this.amount )
    default:
      return '---'
  }
}
