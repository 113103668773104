<template>
    <div>
        <div class="bar no-border" v-responsive>
            <div :class="$style.leftControls" v-if="someAdded">
                <button
                    @click="manageOneLink"
                    v-if="addedUsers.length === 1"
                    class="button dark responsive">
                    <i class="material-icons">link</i>
                    Manage Link
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Manage Link</div>
                </button>
                <button
                    @click="resend"
                    class="button dark responsive">
                    <i class="material-icons">email</i>
                    Send Link to User{{addedUsers.length > 1 ? 's': ''}}
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Send Link to User{{addedUsers.length > 1 ? 's': ''}}</div>
                </button>
                <button
                    @click="edit"
                    v-if="addedUsers.length === 1"
                    class="button dark responsive">
                    <i class="material-icons">edit</i>
                    Edit User
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Edit User</div>
                </button>
                <button
                    @click="dropMany"
                    class="button dark responsive">
                    <i class="material-icons">delete</i>
                    {{addedUsers.length > 1?"Delete Users": "Delete User"}}
                    <div class="rbTooltip simple" tooltip="placement:end-middle">{{addedUsers.length > 1?"Delete Users": "Delete User"}}</div>
                </button>
                <button
                    @click="assignCategory"
                    class="button dark responsive">
                    <i class="material-icons">face</i>
                    Assign Category
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Assign Category</div>
                </button>
            </div>
            <div :class="$style.controls">
                <hd-select align="right" :dark="true" v-model="groupBy" :items="groupers">
                    <i slot="icon" class="material-icons">calendar_view_day</i>
                  <span slot="label">Group By</span>
                </hd-select>
                <hd-select align="right" :dark="true" v-if="filters.length > 0" v-model="filterBy" :items="filters">
                    <i slot="icon" class="material-icons">filter_list</i>
                    <span slot="label">Filter By</span>
                </hd-select>
                <hd-select align="right" :dark="true" v-model="sortBy" :sorter="true" :items="sorters">
                    <i slot="icon" class="material-icons">sort</i>
                    <span slot="label">Sort By</span>
                </hd-select>
                <div :class="$style.verticalSeparator"></div>
                <button
                    @click="upload"
                    class="button dark responsive">
                    <i class="material-icons">group_add</i>
                    Upload Users
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Upload Users</div>
                </button>
                <button
                    @click="create"
                    class="button dark cta responsive">
                    <i class="material-icons">person_add</i>
                    Add User
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Add User</div>
                </button>
                <button
                    @click="editColumns"
                    class="button dark icon-only">
                    <i class="material-icons">settings</i>
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Organise Table Columns</div>
                </button>
            </div>
        </div>
        <div class="manage-table" :class="{[$style.table]: true, [$style.fullTable]: users && users.length > 0}">
            <div class="row tableHead" >
                <div class="cell head checkboxHead">
                    <span @click="selectAll" class="material-icons">{{ allAdded?'check_box': someAdded? 'indeterminate_check_box': 'check_box_outline_blank' }}</span>
                </div>
                <div v-for="column in columns" :key="column" class="cell head">{{getColumn(column).text}}</div>
                <div class="cell head cell-xs"> Actions </div>
            </div>
            <template v-if="!loading" v-for="group in sortedAndGrouped">
                <div :key="group.key" class="grouper" v-if="group.key !== null">
                    <span v-if="groupBy === 'category'">{{getView(group.key) && getView(group.key).name || "Not Assigned"}}</span>
                    <span v-else>{{group.key || "___"}}</span>
                </div>
                <table-row
                    :key="item.id"
                    v-for="item in group.items"
                    :row="item"
                    :class="$style.table"
                    :columns="columns"
                    @add="add"
                    @remove="remove"
                    :added="added(item)"
                    :all-columns="allColumns"
                    :views="views"
                    @drop="drop"
                    @link="manageLink"
                    @assignView="assignView"
                />
            </template>
        </div>
        <div :class="$style.empty" v-if="empty">
            <div v-if="loading" class="layout-column flex-center" :class="$style.loader">
                <rbv-loading label="Loading, please wait..."/>
            </div>
            <template v-else>
                <div :class="$style.emptyTitle">Nothing to see here (:</div>
                <div :class="$style.emptySubTitle">You have not yet added any users to your hotel directory</div>
                <div>
                    <button
                        @click="upload"
                        class="button dark">
                        <i class="material-icons">group_add</i>
                        Upload Users
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { Dialog, Notification } from "root/v-app/rbServices";
import hotelDirectoryClientStore from '../hotel-directory-client-store';
import hotelDirectoryService from '../../hotel-directory-service';
import ValidityErrorNotification from 'vRoot/hotel-directory/notifications/ValidityErrorNotification.vue';


import EditColumn from 'vRoot/hotel-directory/dialogs/EditColumnsDialog.vue';
import SaveUserDialog from 'vRoot/hotel-directory/dialogs/SaveUserDialog.vue';
import ManageLinkDialog from 'vRoot/hotel-directory/dialogs/ManageLinkDialog.vue';
import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';
import RbvLoading from "vRoot/_core/RbvLoading.vue";
import TableRow from './table-row.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue'
import AssignViewDialogVue from '../../dialogs/AssignViewDialog.vue';
import PreUploadUsersDialogVue from './upload-dialogs/PreUploadUsersDialog.vue';
import manageUploadedUsersDialogVue from './upload-dialogs/manageUploadedUsersDialog.vue';
import outgoingMessageDialog from './upload-dialogs/outgoingMessageDialog.vue';
import ResponsiveBarMixin from 'vRoot/hotel-directory/_mixins/responsive-bar.vue';

import {orderBy, fill, get} from 'lodash';


export default {
    name: 'HotelDirectoryManageUsersModule',
    components: { HdSelect, RbvLoading, TableRow },
    mixins: [ ResponsiveBarMixin ],
    props: ['directory'],
    data(){
        let columns = hotelDirectoryClientStore.getManageUsersColumns();
        if(!columns){
            columns = hotelDirectoryClientStore.getDefaultManageUsersColumns().map(column => column.id);
            hotelDirectoryClientStore.setManageUsersColumns(columns);
        }
        return {
            columns,
            groupers: [
                {
                    id: null,
                    text: "None",
                },
                {
                    id: 'title',
                    text: "Job Title",
                    key: 'jobTitle'
                },
                {
                    id: 'department',
                    text: "Department",
                    key: 'department'
                },
                {
                    id: 'category',
                    text: "User Category",
                    key: 'directoryViewId'
                }
            ],
            sorters: [
                {
                    id: null,
                    text: "None",
                },
                {
                    id: 'firstName',
                    text: "First Name",
                    key: 'firstName'
                },
                {
                    id: 'lastName',
                    text: "Last name",
                    key: 'lastName'
                },
                {
                    id: 'email',
                    text: "Email",
                    key: 'email'
                },
                {
                    id: 'phone',
                    text: "Phone Number",
                    key: 'phone'
                },
                {
                    id: 'department',
                    text: "Department",
                    key: 'department'
                },
                {
                    id: 'category',
                    text: "User Category",
                    key: 'directoryViewId'
                },
                {
                    id: 'title',
                    text: "Job Title",
                    key: 'jobTitle'
                }
            ],
            groupBy: null,
            sortBy: {id: null},
            filterBy: '',
            addedUsers: [],
            allColumns: hotelDirectoryClientStore.getDefaultManageUsersColumns(),
            showUsersError: true,
            barWrapped: false
        }
    },
    computed: {
        grouper(){
            return this.groupers.find(group => group.id === this.groupBy);
        },
        sorter() {
          const s = this.sorters.find(group => group.id === this.sortBy.id);
          s.order = this.sortBy.order;
          return s;
        },
        filter(){
            const filter = this.filters.find(filter => filter.id === this.filterBy);
            return filter? filter: {
                id: null,
                text: 'None'
            }
        },
        filteredUsers(){
            let users = this.users;
            if(this.filter.id !== null){
                users = users.filter(hotel => {
                    return get(hotel, this.grouper.key) === this.filter.value;
                });
            }
            return users;
        },
        filters(){
            let filters = [];
            if(this.grouper.id !== null){
                filters = this.users
                    .map((user, index) => {
                        const value = get(user, this.grouper.key);
                        let text = value;
                        if(this.grouper.id === 'category'){
                            const view = this.getView(value);
                            text = view && view.name || "Not Assigned";
                        }else {
                            text = text? text: "___";
                        }
                        return {
                            id: value+""+index,
                            text,
                            value
                        }
                    })
                    .reduce((value, filter) => {
                        if(value.find(v => v.text === filter.text)){
                            return value
                        }else{
                            return value.concat(filter)
                        }
                    },[]);
            }
            return [{
                id: null,
                text: "None",
            }, ...filters];
        },
        sortedAndGrouped(){
            const sortedUsers = this.sort(this.filteredUsers);
            const groups = {};
            sortedUsers && sortedUsers.forEach(user => {
                const key = this.grouper.key? get(user, this.grouper.key): null;
                groups[key] = groups[key]? groups[key]: {
                    key,
                    items:[]
                };
                groups[key].items.push(user);
            });
            return Object.values(groups);
        },
        loading(){
            return this.users === null;
        },
        empty(){
            return this.loading || this.users.length === 0;
        },
        allAdded(){
            return this.someAdded && this.addedUsers.length === this.filteredUsers.length;
        },
        someAdded(){
            return this.filteredUsers && this.addedUsers.length > 0;
        }
    },
    asyncComputed: {
        users(){
            return hotelDirectoryService.loadAddedUsers().then(users => {
              this.checkInvalidUsers(users);
              return users;
            });
        },
        views(){
            return hotelDirectoryService.getViews();
        },
        userAccount() {
            return hotelDirectoryService.getUserAccount().then(account => {
              this.$nextTick(() => hotelDirectoryService.showOptInDialog());
              return account;
            });
        }
    },
    methods: {
        getColumn(id){
            return hotelDirectoryClientStore.getManageUsersColumn(id);
        },
        checkInvalidUsers(users) {
          if(users.find(user => !user.directoryViewId)) {
            Notification.show(ValidityErrorNotification, {
              title: "Important Information",
              subTitle: "Some users in your directory cannot access the directory because they have not category assigned to them",
              buttonText: "Show These Users"
            }).then(this.selectErroredUsers);
          }
        },
        editColumns(){
            Dialog.show(EditColumn, {
                columns: Object.assign([], this.columns),
                otherColumns: hotelDirectoryClientStore.getDefaultManageUsersColumns(),
                get: hotelDirectoryClientStore.getManageUsersColumn
            }).then(data => {
                this.columns = data.columns;
                hotelDirectoryClientStore.setManageUsersColumns(this.columns);
            });
        },
        sort(users){
            if(this.sorter.key){
                const key = Array.isArray(this.sorter.key)? this.sorter.key: [this.sorter.key];
                users = orderBy(users, key, fill(Array(key.length), this.sorter.value))
            }
            return users;
        },
        add(user){
            this.addedUsers.push(user.id);
        },
        remove(hotel) {
            this.addedUsers = this.addedUsers.filter(id => id !== hotel.id);
        },
        added(hotel){
            return this.addedUsers.indexOf(hotel.id) > -1;
        },
        selectAll(){
            if(!this.allAdded){
                this.addedUsers = this.filteredUsers.map(hotel => hotel.id);
            }else{
                this.addedUsers = [];
            }
        },
        refresh(){
            hotelDirectoryService.loadAddedUsers().then(users => {
                this.users = users;
                this.checkInvalidUsers(users);
            });
        },
        create(){
            Dialog.show(SaveUserDialog, {
                views: this.views
            }).then(data => {
                Dialog.show(outgoingMessageDialog, {
                    subTitle: `This action will send an email to ${data.user.firstName} ${data.user.lastName} containing their link to your hotel directory. Specify a custom message to be sent to them.`,
                    message: this.directory.defaultUserMessage,
                    title: `Edit Message to be sent to ${data.user.firstName} ${data.user.lastName}`,
                    userAccount: this.userAccount,
                    user: data.user
                }).then(message => {
                    hotelDirectoryService.addUsers(
                        [data.user],
                        {
                            message: message.message,
                            setMessageAsDefault: message.setMessageAsDefault,
                        }
                    ).then(() => {
                        this.refresh();
                        if(data.setMessageAsDefault) {
                            this.directory.defaultUserMessage = message.message;
                        }
                        data.another && this.create();
                    });
                });
            });
        },
        upload(){
            Dialog.show(PreUploadUsersDialogVue).then(data => {
                Dialog.show(manageUploadedUsersDialogVue, { ...data.data}).then(data => {
                    hotelDirectoryService.addUsers(data.users, {
                        message: this.directory.defaultUserMessage,
                        setMessageAsDefault: false
                    }).then(() => {
                        this.refresh();
                    });
                });
            });
        },
        getView(id){
            return this.views.find(view => view.id === id);
        },
        drop(user){
            Dialog.show(WarningDialog, {
                title: `Delete ${user.firstName} ${user.lastName}`,
                subTitle: 'Please be aware that by deleting this user, they will no longer be able to access the hotel directory.'
            }).then(() => {
                hotelDirectoryService.removeUsers([user.id]).then(() => {
                    if(this.addedUsers.length === 1 && this.addedUsers[0] === user.id){
                        this.addedUsers = [];
                    }
                    this.refresh();
                })
            });
        },
        dropMany(){
            if(this.addedUsers.length === 1){
                const user = Object.assign({}, this.users.find(user => user.id === this.addedUsers[0]));
                this.drop(user);
            }else{
                Dialog.show(WarningDialog, {
                    title: `Delete ${this.addedUsers.length} users`,
                    subTitle: 'Please be aware that by deleting these users, they will no longer be able to access the hotel directory.'
                }).then(() => {
                    hotelDirectoryService.removeUsers(this.addedUsers).then(() => {
                        this.addedUsers = [];
                        this.refresh();
                    });
                });
            }
        },
        edit(){
            const user = Object.assign({}, this.users.find(user => user.id === this.addedUsers[0]));
            Dialog.show(SaveUserDialog, {views: this.views, user}).then(data => {
                hotelDirectoryService.updateUser(data.user).then(() => {
                    this.refresh();
                });
                return data;
            });
        },
        assignCategory(){
            const users = this.users.filter(user => this.addedUsers.indexOf(user.id) > -1);
            Dialog.show(AssignViewDialogVue, {views: this.views, users}).then(data => {
                const addedUsers = data.users.map(user => user.id);
                addedUsers.length > 0 && this.assignUserView(addedUsers, data.view);
            });
        },
        manageOneLink(){
            const user = Object.assign({}, this.users.find(user => user.id === this.addedUsers[0]));
            this.manageLink(user);
        },
        manageLink(user){
            Dialog.show(ManageLinkDialog, {user, message: this.directory.defaultUserMessage, userAccount: this.userAccount}).then(data => {
                let action;
                if(data.action === 'resend'){
                    action = hotelDirectoryService.resendUserLink(
                        [user.id],
                        {
                            message: data.message,
                            setMessageAsDefault: data.setMessageAsDefault,
                        }
                    );
                }else if(data.action === 'deactivate'){
                    action = hotelDirectoryService.deactivateUserLink(user.id);
                }
                else{
                    action = hotelDirectoryService.refreshUserLink(
                        user.id,
                        {
                            message: data.message,
                            setMessageAsDefault: data.setMessageAsDefault,
                        }
                    );
                }
                action.then(this.refresh)
            });
        },
        resend(){
            let subTitle = 'This action will send an email to these users containing their link to your hotel directory. Specify a custom message to be sent to them.';
            let user;

            if(this.addedUsers.length === 1){
                user = this.users.find(user => user.id === this.addedUsers[0])
                subTitle = `This action will send an email to ${user.firstName} ${user.lastName} containing their link to your hotel directory. Specify a custom message to be sent to them.`;
            }

            Dialog.show(outgoingMessageDialog, {
                subTitle: subTitle,
                message: this.directory.defaultUserMessage,
                userAccount: this.userAccount,
                user
            }).then(data => {
                hotelDirectoryService.resendUserLink(this.addedUsers, data);
            });
        },
        assignView(data){
            this.assignUserView([data.id], data.viewId);
        },
        assignUserView(users, viewId){
            hotelDirectoryService.assignView(users, viewId).then(() => {
                this.refresh();
            })
        },
        selectErroredUsers(){
            this.groupBy = "category";
            this.$nextTick(() => {
                this.filterBy = this.filters.find(filter => !filter.value).id;
            });
        }
    }
}
</script>

<style lang="stylus" module>
    .title{
        color #fff;
        display flex;
    }

    .titleMain{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    .titleText{
        padding 0 10px;
    }

    .titleSubText{
        padding 0 10px;
        font-size: 12px;
        color: #acbec5;
    }

    .titleIcon{
        color: #2db8aa;
        background: #263238;
        padding: 14px;
        /* vertical-align: middle; */
        border-radius: 100%;
        height: 48px;
        width: 48px;
        box-sizing: border-box;
    }

    .controls{
        display flex;
        justify-content flex-end;
        flex 1;
        padding-bottom 5px;
    }

    .leftControls{
        composes controls;
        justify-content flex-start;
        margin-right 5px;
    }

    .verticalSeparator{
        height: 36px;
        border: 1px solid #263238;
        display: inline;
        vertical-align: middle;
        margin: 0 10px 0 5px;
    }

    .empty{
        width 100%;
        height calc(100vh - 198px);
        background-color #ECEFF1;
        text-align center;
        display flex;
        flex-direction column;
        justify-content center;
        color #263238;
        & > * {
            margin 5px 0;
        }
    }

    .emptyTitle{
        color #263238;
        font-size 20px;
    }

    .emptySubTitle{
        color #546E7A;
        font-size 13px;
        max-width 400px;
        margin 0 auto;
    }

    .overflow{
        height 30px;
        display table-row;
    }

    .grouper{
        padding: 5px 15px;
        color: #78909c;
        position: absolute;
    }

    .loader{
        padding 100px;
    }

    .usersInfo{
        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 300px;
        height: 180px;
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 1px 1px 5px 1px #78909c;
        border-radius: 3px;
        z-index 9;
    }

    .usersInfoHeader{
        display flex;
        align-items center;
        color #546E7A;
        font-size 17px;
        font-weight lighter;
    }

    .usersInfoHeader .info{
        color #ec5c0b;
        font-size 50px !important;
    }

    .infoButton{
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-image: unset !important;
        background-color: #f15a24;
        color: #fff !important;
        border 0px !important;
    }

    .infoText{
        color #82909d;
    }

    .infoClose{
        position absolute;
        right 10px;
        top 5px;
        color #82909d;
        cursor pointer;
    }

    .table{
        overflow auto;
    }

    .fullTable{
      height: calc(100vh - 159px);
      overflow: auto;
      box-sizing border-box;
    }
</style>

