<template>
  <div :class="$style.container">
    <div :class="$style.state">
      <rb-loading v-if="isWorking" rb-class="medium" />

      <template v-else>
        <button class="icon" @click="$emit('click')">
          <i class="material-icons" :class="checkbox.style">{{ checkbox.icon }}</i>
        </button>
        <div class="rbTooltip simple" tooltip="placement:top-start" :class="$style.tooltip" v-html="checkbox.tooltip"></div>
      </template>

    </div>
  </div>
</template>

<script>
  import RbLoading from 'vRoot/_core/RbvLoading.vue';

  export default {
    name: "rbv-travel-destinations-map-suppliers-table-supplier-selected-checkbox",
    components: { RbLoading },
    props: {
      'state': {
        type: String,
        required: true
      }
    },
    computed: {
      checkbox () {
        switch (this.state) {
          case 'ADDED':
            return {
              icon: 'check_box',
              tooltip: 'Remove Hotel from the <b>Bid Manager</b>',
              style: this.$style.added
            }
          case 'ACTIVE':
            return {
              icon: 'lock',
              tooltip: 'Bid process is already in progress for this Hotel and it can be managed only through the <b>Bid Manager</b>',
              style: this.$style.active
            }
          case 'NA':
            return {
              icon: 'check_box_outline_blank',
              tooltip: 'Add Hotel to the <b>Bid Manager</b>',
              style: this.$style.notAdded
            }
          default:
            return {}
        }
      },
      isWorking () { return this.state === 'WORKING' }
    }
  }
</script>

<style lang='stylus' module>

  .container {
    flex 0 0 50px
    line-height 58px
    text-align center
  }

  .state {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    display: inline-block;
  }

  .added {
    color: #00A99D
  }

  .active {
    color: #546E7A
  }

  .notAdded {
    color: #B0BEC5
  }

  .tooltip {
    max-width 240px
    text-align left
  }
</style>
