/**
 * Created by DejanK on 7/3/2017.
 */
import HTML from './rb-negotiations-panel-view-actions.html';
import './rb-negotiations-panel-view-actions.scss'

export default {
  template: HTML,
  bindings: {
    onRespond: '&',
    onEdit: '&',
    side: '<',
    bid: '<',
  },
  controller: Ctrl
};

function Ctrl() {
  const vm = this;

  vm.$onChanges = ( changes ) => {
    if(changes.bid && changes.bid.currentValue){
      vm.bid = changes.bid.currentValue;
    }
  };

  vm.respond = () => {
    vm.onRespond();
  };

  vm.edit = () => {
    vm.onEdit();
  };
}
