<template>
  <div class="questionnaireQuestion" :class="classes">
    <label :for="template.id" class="questionnaireQuestionLabel">
      <span class="questionnaireQuestionLabelOrd" :class="classes">{{template.ord}}.</span>
      <span>{{template.name}}</span>
    </label>

    <input class="questionnaireQuestionInput"
           readonly="readonly"
           :id="template.id"
           :name="template.id"
           :value="value"
    />

    <tooltip
      :ord="template.ord"
      :title="template.name"
      :description="template.description"
      :required="!!isRequired"
    />

  </div>
</template>

<script>
  import { merge, isEmpty } from 'lodash'
  import Tooltip from '../tooltips/QuestionnaireTooltip.vue'

  export default {

    components: { Tooltip },

    props: [ 'model', 'template', 'response' ],

    data () {
      return {
        conditionalValidations: {},
      }
    },

    computed: {

      classes () {
        return merge( this.templateClasses, {
          required: this.isRequired,
          error: this.showErrors && this.errors.has(this.id)
        } )
      },

      id () { return this.template.id },

      isRequired () {
        return !!( this.template.req || (this.model && this.model.req) || (this.validations && this.validations.required) )
      },

      templateClasses () {
        return this.template.classes ? toObject(this.template.classes) : {}

        function toObject(classes) {
          const cl = {}
          classes.split(' ').forEach( (key) => { cl[key] = true } )
          return cl
        }
      },

      value () {
        const answer = this.response && this.response.answers[this.id]
        return isEmpty(answer) ? undefined : this.formatAnswer(answer)
      }
    },

    methods: {
      formatAnswer (answer) {
        return answer
      }
    }
  }
</script>
