<script>
    export default {
      name: "ResponsiveTable",
      props: ['expanded'],
      data() {
        return {
          visibleColumns: null,
          el: null,
          visibleColumnStyles: []
        }
      },
      watch: {
        expanded() {
          this.$forceUpdate();
          window.setTimeout(() => this.updateTable(this.el), 500);
        }
      },
      directives: {
        responsiveTable: {
          bind(el, _, vNode) {
            vNode.context.el = el;
          },
          inserted(el, _, vNode) {
            window.addEventListener('resize', () => {
              vNode.context.$forceUpdate();
              vNode.context.updateTable(el)
            });
          },
          componentUpdated(el, _, vNode){
            vNode.context.updateTable(el);
          }
        }
      },
      methods: {
        updateTable(el){
          this.$nextTick(() => {
            this.cleanUp(el);
            const header = el.childNodes[0];
            const visibleCount = this.getVisibleCount(header);
            const styles = this.updateHeader(header, visibleCount);
            if(this.visibleColumns !== (visibleCount - 1)) {
              this.visibleColumns = visibleCount - 1;
              this.visibleColumnStyles = styles;
              this.$forceUpdate();
            }
          });
        },
        cleanUp(el){
          el.querySelectorAll('.cell.hidden').forEach(node => node.classList.remove('hidden'));
        },
        getVisibleCount(header) {
          let count = 0;
          const parentWidth = header.parentNode.clientWidth;
          let calculatedWidth = 40;
          for (let i = 0; i < header.children.length; i++) {
            const node = header.children[i];
            calculatedWidth += node.offsetWidth;
            if(parentWidth > calculatedWidth){
              count++;
            }else{
              break;
            }
          }
          return count;
        },
        updateHeader(header, count) {
          const children = header.getElementsByClassName('cell');
          const visible = [];
          for (let i = 1; i < children.length; i++) {
            const node = children[i];
            if(i>=count) {
              node.classList.add('hidden');
            }else{
              visible.push({minWidth: node.style.minWidth, width: `${node.offsetWidth}px`});
            }
          }
          children[children.length-1].insertAdjacentHTML('afterEnd', "<div class='cell checkboxCell plus'></div>");
          return visible;
        }
      }
    }
</script>
