import {
  notificationUtils,
  // httpErrorHandler,
  $state,
  rfpManagerRepository,
} from 'root/angular-injector-provider';

import {PAGE_RFP_MANAGER_COMPANY_PROFILE, PAGE_SELECT_RFP_TYPE} from 'root/states';
import { BID_MANAGER } from '../../../rfp/bid-manager/main/pages';
import {Dialog} from 'root/v-app/rbServices';
import CreateRfpDialog from '../dialogs/CreateRfpDialog.vue';
import {ACCOUNT_MANAGE_USERS_MODULE} from 'vRoot/account-profile/modules/manage-users/AccountManageUsersModule';
import {ACCOUNT_MANAGE_COMPANIES_MODULE} from 'vRoot/account-profile/modules/manage-companies/AccountManageCompaniesModule';
import {ACCOUNT_COMPANY_PROFILE} from 'vRoot/account-profile/modules/company-profile/AccountCompanyProfileModule';
import BidManagerViewTypes from 'rfp/bid-manager/bid-manager-view-types';
import { PAGE_DUPLICATE_RFP } from '../../../states';

function openBidManager([rfp, ]) {
  const params = rfp.rfp? {
    targetId: rfp.rfp.id,
    type: BidManagerViewTypes.RFP
  }: {
    targetId: rfp.account.id,
    type: BidManagerViewTypes.ALL
  };

  return openManager(params);

  function openManager() {
    return notificationUtils().onLoad(() => rfpManagerRepository().getBidManagerView(params)).then(({data}) => {
      $state().inNew(BID_MANAGER, {viewId: data.id})
    });
  }
}

function editRfp([{rfp}, ]) {
  return $state().inNew('hotelRfp.rfp.coverLetter.view', {rfpId: rfp.id})
}

function archiveRfps(rfps) {
  return notificationUtils()
    .onSave(() => rfpManagerRepository().archiveRfps(rfps.map(({rfp}) => rfp.id)))
}

function completeRfps(rfps) {
  return notificationUtils()
    .onSave(() => rfpManagerRepository().completeRfps(rfps.map(({rfp}) => rfp.id)))
}

function unArchiveRfps(rfps) {
  return notificationUtils()
    .onSave(() => rfpManagerRepository().unArchiveRfps(rfps.map(({rfp}) => rfp.id)));
}

function duplicateRfp(rfpId) {
  return Promise.resolve($state().inNew(PAGE_DUPLICATE_RFP, {rfpId}));
}

function createNewRfp(accounts, account, isCompany = false, currentAccountId) {
  return new Promise((resolve, reject) => {
    if(!isCompany) {
      Dialog.show(CreateRfpDialog, {accounts, accountId: account}).then(({action, data: {accountId}}) => {
        switch (action) {
          case 'createRfp':
            resolve(openCreate(accountId));
            break;
          case 'createCompany':
            resolve({action});
            break;
          default:
            break;
        }
      }).catch(reject)
    }else {
      Promise.resolve(openCreate(currentAccountId));
    }
  });

  function openCreate(accountId) {
    return $state().inNew(PAGE_SELECT_RFP_TYPE, {accountId});
  }
}

function openUsersAndPermissions() {
  return $state().go(PAGE_RFP_MANAGER_COMPANY_PROFILE, {module: ACCOUNT_MANAGE_USERS_MODULE});
}

function openManageCompanies() {
  return $state().go(PAGE_RFP_MANAGER_COMPANY_PROFILE, {module: ACCOUNT_MANAGE_COMPANIES_MODULE});
}

function manageAccount(accountId) {
  return $state().go(PAGE_RFP_MANAGER_COMPANY_PROFILE, {module: ACCOUNT_COMPANY_PROFILE, accountId});
}

export default {
  openBidManager,
  editRfp,
  archiveRfps,
  unArchiveRfps,
  duplicateRfp,
  createNewRfp,
  openUsersAndPermissions,
  openManageCompanies,
  completeRfps,
  manageAccount,
}
