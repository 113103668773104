/**
 * Created by DejanK on 01/11/2018.
 */
import HTML from './delete-uploaded-destination.html'

const NAME = 'deleteUploadedTravelDestination'

export { NAME, HTML, DeleteTravelDestinationDialogCtrl as Controller}

DeleteTravelDestinationDialogCtrl.$inject = [ 'rbDialog', 'Destination']
function DeleteTravelDestinationDialogCtrl( dialog, destination ){
  const vm = this

  vm.destination = destination
  vm.confirm = dialog.hide
  vm.cancel = dialog.cancel
}
