/**
 * Created by DejanK on 9/19/2017.
 */

import HTML from './bid-manager-hotel-profile.html'
import './bid-manager-hotel-profile.scss'

const NAME = 'bidManagerHotelProfile'

export { NAME, HTML, BidManagerHotelProfileDialogController as Controller }

BidManagerHotelProfileDialogController.$inject = ['NotificationUtils', 'HotelId', 'DataServer', 'rbDialog', 'MainAPI'];
function BidManagerHotelProfileDialogController(notificationUtils, hotelId, dataServer, dialog, mainApi){
  const vm = this;
  vm.cancel = dialog.cancel

  $onInit();

  function $onInit(){
    notificationUtils.onLoad( () => dataServer.getAmenities(), vm, 'amenities' )
    notificationUtils.onLoad( () => mainApi.getHotel(hotelId), vm, 'hotel' )
  }
}
