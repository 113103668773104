/**
 * authInterceptor
 */

export default HttpAuthorizationInterceptorFactory;

HttpAuthorizationInterceptorFactory.$inject = ['AuthorizationStorageService', '$q' ];
function HttpAuthorizationInterceptorFactory(AuthorizationStorageService, $q) {
  return {
    request: request,
    response: response,
    responseError: responseError
  };

  function request(config) {
    config.headers['X-AUTH-TOKEN'] = AuthorizationStorageService.getToken();
    return config;
  }

  function response(httpResponse) {
    saveAuthToken(httpResponse);
    return httpResponse;
  }

  function responseError(httpError){
    saveAuthToken(httpError);
    return $q.reject(httpError);
  }

  function saveAuthToken(httpResponse){
    const authToken = httpResponse.headers()['x-auth-token'];
    authToken && AuthorizationStorageService.saveToken(authToken);
  }
}
