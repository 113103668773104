<script>
  import noop from 'lodash/noop'
  import layoutContainer from 'vRoot/_core/layouts/FormFooterWithButtons.vue';
  import tooltipContainer from 'vRoot/_core/layouts/TooltipContainer.vue';
  import rbButton from 'vRoot/_core/buttons/Button.vue';
  import rbButtonWithMaterialIcon from 'vRoot/_core/buttons/ButtonWithMaterialIcon.vue';
  import NegotiationsFormService from 'vRoot/rfp-hotel/negotiations/NegotiationsFormService.js';
  import { Dialog } from 'root/v-app/rbServices';
  import ConfirmNegotiationFinalizationDialog from './ConfirmNegotiationFinalizationDialog.vue'
  import ProceedToFinalAgreementDialog from './ProceedToFinalAgreementDialog.vue'

  export default {

    components: { layoutContainer, rbButton, rbButtonWithMaterialIcon, tooltipContainer },

    data () {
      return {
        formState:{
          isInputPristine: true,
          areValuesPristine: true,
          isAcceptingReply: false,
          isInitialRequest: true,
          isRequestAccepted: false,
        }
      };
    },

    computed: {
      canAcceptBuyerRequest () {
        return this.areValuesPristine && !this.formState.isAcceptingReply;
      },

      areValuesPristine() {
        return this.formState.areValuesPristine;
      },

      areValuesDirty() {
        return !this.areValuesPristine;
      },

      isInputPristine () {
        return this.formState.isInputPristine;
      },

      isNotInitialRequest () {
        return !this.formState.isInitialRequest;
      },

      isRequestAccepted() {
        return this.formState.isRequestAccepted;
      }
    },

    methods: {

      cancel () {
        NegotiationsFormService.cancel();
      },

      submit () {
        NegotiationsFormService.submit();
      },

      update () {
        NegotiationsFormService.update();
      },

      acceptOffer () {
        NegotiationsFormService.acceptOffer()
          .then( ( bidId ) => Dialog.show( ProceedToFinalAgreementDialog, { bidId } ) )
          .catch( noop );
      },

      acceptRequest () {
        NegotiationsFormService.acceptRequest();
      },

      finalizeWithRequest () {
        this.confirmFinalization()
          .then( () => NegotiationsFormService.finalizeWithRequest() )
          .catch( noop );
      },

      finalizeWithModel () {
        this.confirmFinalization()
          .then( () => NegotiationsFormService.finalizeWithModel())
          .catch( noop );
      },

      confirmFinalization () {
        return Dialog.show( ConfirmNegotiationFinalizationDialog );
      },
    },

    mounted () {
      NegotiationsFormService.registerObservable( this.formState );
    },

    beforeDestroy () {
      NegotiationsFormService.deregisterObservable( this.formState );
    }
  };
</script>
