export { readFile , sheetToJson, fileToJson }

function importService(){
  return import('xlsx');
}

function readFile(fileData) {
  return importService().then(service => service.read(fileData, { type: "binary", raw: true }))
}

function sheetToJson(sheet, options){
  return importService().then(service => service.utils.sheet_to_json(sheet, options))
}

function fileToJson(file, options){
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      let binary = ""
      const bytes = new Uint8Array(e.target.result),
        length = bytes.byteLength
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      resolve(binary)
    }
    reader.onerror = (e) => reject(e)
    reader.readAsArrayBuffer(file)
  })
    .then(readFile)
    .then(wb => sheetToJson(wb.Sheets[options && options.sheetName ? options.sheetName : Object.keys(wb.Sheets)[0]]))
}
