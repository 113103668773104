<script>
  import BidManagerActionQuestionMixin from './BidManagerActionQuestionMixin.vue';

  export default {
    name: 'rbv-hotel-rfp-bid-manager-force-sending-invalid-responses-question',

    mixins: [ BidManagerActionQuestionMixin ],

    props: {
      isBulk: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      headerText () {
        return 'SEND ANYWAY!';
      },

      messageText () {
        return `I realize my RFP response${this.isBulk ? 's' : ''} still contain errors. I wish to reply anyway.`;
      },

      confirmationText () {
        return false;
      },
    },

    mounted(){
      this.confirmed = true;
    },
  }
</script>
