import accountBasicInformationModule from './basic-information/AccountBasicInformationModule'
import accountManageUsersModule from './manage-users/AccountManageUsersModule';
import accountManageCompaniesModule from './manage-companies/AccountManageCompaniesModule';
import accountCompanyProfile from './company-profile/AccountCompanyProfileModule';

export default {
  loadModules: loadModules
}

function loadModules(accountType){
  const availableModules = [];

  load(accountBasicInformationModule);
  load(accountManageUsersModule);
  load(accountManageCompaniesModule);
  load(accountCompanyProfile);

  availableModules.sort((a, b) => {return a.priority - b.priority});
  return availableModules;

  function load(module){
    if(module.isAvailable(accountType)) {
      availableModules.push(module)
    }
  }
}
