/**
 * Created by DejanK on 4/5/2017.
 *
 * Bid Manager Query Service
 * Creates Query out of View and Fetches data
 */

'use strict';

module.exports = BidManagerQueryService;

BidManagerQueryService.$inject = ['BidManagerRepository'];
function BidManagerQueryService(BidManagerRepository) {
  const $service = this;

  $service.queryBids = queryBids;
  $service.getBidContactLastActive = getBidContactLastActive;

  function queryBids(view){
    return BidManagerRepository.queryBids(buildQuery(view));
  }

  function buildQuery(view) {
    return {
      viewId: view.id,
      fields: buildQueryFields(view),
      filter: buildQueryFilter(view),
      sort: buildQuerySort(view)
    };
  }

  function getBidContactLastActive(bidId) {
    return BidManagerRepository.getBidContactLastActive(bidId);
  }

  function buildQueryFields(view) {
    let fields = [];
    addFieldsFromColumns(view.columns);
    view.sort && addFieldsFromSort(view.sort);
    view.group && addFieldsFromGroup(view.group);
    return fields;

    function addFieldsFromColumns(columns) {
      for(let i=0, l=columns.length; i<l; i++){
        addFieldsFromColumn(columns[i]);
      }

      function addFieldsFromColumn(column) {
        addFieldsNeededToViewData(column.fields);
        //column.$sortable && addFieldsNeededToSortSortableColumns(column.$sortable);
        //column.$groupable && addFieldsNeededToGroupGroupableColumns(column.$groupable);

        function addFieldsNeededToViewData(fields) {
          for(let fieldKey in fields){
            if(fields.hasOwnProperty(fieldKey)){
              let fieldValue = fields[fieldKey];
              if(angular.isObject(fieldValue)) {
                for(let fKey in fieldValue){
                  fieldValue.hasOwnProperty(fKey) && addToFields(fieldValue[fKey]);
                }
              } else {
                addToFields(fieldValue);
              }
            }
          }
        }
      }
    }

    function addFieldsFromSort(sortByColumns) {
      for(let sortColumnKey in sortByColumns){
        if(sortByColumns.hasOwnProperty(sortColumnKey)){
          let sortable = sortByColumns[sortColumnKey].$sortable;
          sortable && addFieldsNeededToSortSortableColumns(sortable);
        }
      }
    }

    function addFieldsFromGroup(groupByColumn) {
      groupByColumn.$groupable && addFieldsNeededToGroupGroupableColumns(groupByColumn.$groupable);
    }

    function addFieldsNeededToSortSortableColumns(sortable) {
      for(let i=0, il=sortable.length; i<il; i++){
        addToFields(sortable[i]);
      }
    }

    function addFieldsNeededToGroupGroupableColumns(groupable) {
      addToFields(groupable.by);
      groupable.text && addToFields(groupable.text);
    }

    function addToFields(field) {
      if (fields.indexOf(field) === -1) {
        fields.push(field);
      }
    }
  }

  function buildQueryFilter(view){
    let filters = {}, viewFilter = view.filter;
    for(let filterKey in viewFilter){
      if(viewFilter.hasOwnProperty(filterKey)){
        let filterData = viewFilter[filterKey];
        if(filterData.by){
          filters[filterData.by.$filter.by] = filterData.value
        }
      }
    }
    return filters;
  }

  function buildQuerySort(view){ // todo: zasto je Object ????
    let sort = {}, viewSort = view.sort;
    view.group && addGroupToSort(view.group);

    for(let sortKey in viewSort){
      if(viewSort.hasOwnProperty(sortKey)){
        let sortData = viewSort[sortKey],
          sortable = sortData.by.$sortable;
        if(sortable) {
          for (let i = 0, il = sortable.length; i < il; i++) {
            let field = sortable[i];
            sort[field] = sortData.direction;
          }
        }
      }
    }
    return sort;

    function addGroupToSort(viewGroup){
      sort[viewGroup.$groupable.by] = 1;
    }
  }
}
