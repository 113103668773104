<template>
    <div :class="getClosedStyle('navigation')">
        <div :class="getClosedStyle('header')">
            <div :class="$style.headerText" v-if="open">
                <div :class="$style.headerTitle">Hotel Directory</div>
                <div :class="$style.headerSubTitle">Admin Dashboard</div>
            </div>
            <div :class="$style.headerButtonContainer">
                <button class="button dark icon-only" @click="toggleState" :class="getClosedStyle('headerButton')">
                    <i class="material-icons">{{open? 'arrow_back': 'arrow_forward'}}</i>
                </button>
            </div>
        </div>
        <ul :class="$style.menu">
            <navigation-item
                :active="isActive(modules.previewAndEdit)"
                :id="modules.previewAndEdit"
                :open="open"
                text="Preview And Edit"
                icon="remove_red_eye">
                <view-list
                    v-if="directory"
                    :class="$style.menu"
                    :views="views"
                    :active-view="activeView"
                    @open="openView"
                    @createView="createView"
                    @updateView="updateView"
                    @deleteView="deleteView"
                />
            </navigation-item>
            <navigation-item
                :active="isActive(modules.manageHotels)"
                :id="modules.manageHotels"
                :open="open"
                text="Manage Hotels"
                icon="business">
            </navigation-item>
            <navigation-item
                :active="isActive(modules.manageUsers)"
                :id="modules.manageUsers"
                :open="open"
                text="Manage Users"
                icon="supervised_user_circle">
            </navigation-item>
            <navigation-item
                :active="isActive(modules.editTravelPolicy)"
                :id="modules.editTravelPolicy"
                :open="open"
                text="Edit Travel Policy"
                icon="description">
            </navigation-item>
        </ul>
    </div>
</template>

<script>
import hotelDirectoryService from 'vRoot/hotel-directory/hotel-directory-service';
import NavigationItem from './HotelDirectoryEditorNavigationItem.vue';
import ViewList from './HotelDirectoryNavigationViews.vue';

import { PREVIEW_AND_EDIT } from '../../modules/preview/HotelDirectoryPreviewModule';
import { MANAGE_HOTELS } from '../../modules/manageHotels/manageHotelsModule';
import { MANAGE_USERS } from '../../modules/manageUsers/manageUsersModule';
import { EDIT_TRAVEL_POLICY } from '../../modules/EditTravelPolicy/EditTravelPolicyModule';

export default {
    name: "HotelDirectoryEditorNavigation",
    components: {NavigationItem, ViewList},
    props: {
        open: {
            default: 0
        },
        directory: {}
    },
    data(){
        return {
            modules: {
                previewAndEdit: PREVIEW_AND_EDIT,
                manageHotels: MANAGE_HOTELS,
                manageUsers: MANAGE_USERS,
                editTravelPolicy: EDIT_TRAVEL_POLICY
            }
        }
    },
    computed: {
        activeView(){
            return hotelDirectoryService.getActiveView();
        },
        views(){
            return hotelDirectoryService.getViews();
        }
    },
    asyncComputed: {
        activeSubModule(){
            return hotelDirectoryService.getActiveSubModule()
                .then(module => module)
                .catch(hotelDirectoryService.handleActiveSubModuleErrors);
        },
    },
    methods: {
        isActive(id){
            return this.activeSubModule && (this.activeSubModule.id === id || this.activeSubModule.parent === id);
        },
        openView(data){
            hotelDirectoryService.openView(data.id);
        },
        openSubModule(id, data){
            hotelDirectoryService.openSubModule(id, data);
        },
        getClosedStyle(style){
            style = this.open? style: 'closed'+style.charAt(0).toUpperCase() + style.substr(1);
            return this.$style[style];
        },
        toggleState(){
            this.$emit('toggle');
        },
        createView(data){
            hotelDirectoryService.createView(data.view);
        },
        updateView(view){
            hotelDirectoryService.updateView(view);
        },
        deleteView(view) {
            hotelDirectoryService.deleteView(view);
        }
    }
}
</script>

<style lang="stylus" module>
    .navigation{
        margin-top: -10px;
        background-color: #313c42;
        width: 280px;
        min-height: 100%;
        display: block;
        border-top-right-radius: 10px;
        box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        color: #fff;
        overflow-x: hidden;
        transition: all 0.3s;
        position: fixed;
        bottom: 0;
        top: 60px;
    }

    .closedNavigation{
        composes navigation;
        width 48px;
    }

    .header{
        padding: 20px;
        padding-top: 40px;
        display: flex;
        position: sticky;
        top: 0;
        background: #313c42;
    }

    .closedHeader{
        composes header;
        padding 20px 3px;
    }

    .headerTitle{
        font-size 20px;
        line-height 30px;
        color #fff;
    }

    .headerSubTitle{
        color #72828A;
        font-size 13px;
    }

    .headerButtonContainer{
        flex-basis auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .headerButton{
        color #90A4AE;
        height 40px;
        width 40px;
    }

    .closedHeaderButton{
        composed headerButton;
        color #fff;
    }

    .headerText{
        flex 1;
    }

    .menu{
        list-style-type none;
        padding 0;
        margin 0;
    }
</style>
