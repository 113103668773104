<template>
  <div>
    <h1 :class="$style.pageTitle">
      Company Profile
    </h1>
    <navigation-item
      v-for="module in modules"
      :key="module.id"
      :module="module"
      :active-module="activeModule"
    />
  </div>
</template>

<script>
  import accountProfileService from '../accountProfileService'

  import NavigationItem from './AccountProfileNavigationItem.vue'

  export default {
    name: 'AccountProfileNavigation',
    components: { NavigationItem },
    asyncComputed: {
      modules () { return accountProfileService.getModules().then(modules => modules.filter(module => !module.hideFromMenu)) },
      activeModule () { return accountProfileService.getActiveModule() }
    }
  }
</script>

<style lang="stylus" module>
  .pageTitle {
    font-weight : bold;
    font-size : 14px;
    text-transform : uppercase;
    color : #37474F;
    padding: 5px 23px 15px;
  }
</style>
