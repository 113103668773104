/**
 * Created by DejanK on 2/11/2017
 *
 * Questionnaire Navigator
 */
'use strict';

let lodash = require('lodash');

module.exports = rbQuestionnaireNav;

function rbQuestionnaireNav() {
  return {
    restrict: 'A',
    controller: RbQuestionnaireNavCtrl
  };
}

RbQuestionnaireNavCtrl.$inject = ['$document'];
function RbQuestionnaireNavCtrl($document){
  let vm = this, navs = [],
    documentContainerElement;

  vm.registerNav = registerNav;

  onInit();

  function onInit(){
    const el = $document[0].getElementById('document-container')
    if(el){
      documentContainerElement = angular.element(el);
      documentContainerElement.on('scroll', updateNav);
    }
  }

  function updateNav(){
    if(documentContainerElement) {
      let active, documentContainerTop = documentContainerElement[0].getBoundingClientRect().top;

      for (let i = 0, l = navs.length; i < l; i++) {
        let nav = navs[i];
        nav.element.removeClass('active');
        if (!nav.element.hasClass('disabled') && nav.target[0].getBoundingClientRect().top - documentContainerTop <= 10 || i === 0) {
          active = nav;
        }
      }
      active && active.element.addClass('active');
    }
  }

  function registerNav(id, navScope, navTargetElement, navElement){
    let nav = {
      id: id,
      scope: navScope,
      target: navTargetElement,
      element: navElement
    };
    navs.push(nav);
    sortByOffset();

    navScope.$on('$destroy', ()=>{
      let navIndex = navs.indexOf(nav);
      navs.splice(navIndex, 1);
      sortByOffset();
    })
  }


  function sortByOffset(){
    angular.forEach(navs, (nav)=>{
      let rect = nav.target[0].getBoundingClientRect();
      nav.top =  rect.top;
    });

    navs = lodash.orderBy(navs, ['top'], ['asc']);
    updateNav();
  }
}
