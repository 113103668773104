<template>
  <div
    :class="{expanded: expanded}"
    class="hotel-directory-amenities amenities"
  >
    <template v-if="!expanded">
      <span
        v-for="amenity in amenitiesData"
        :key="amenity.abbr"
        class="icon"
        :class="{active: amenities.indexOf(amenity.abbr) >= 0}"
      >
        <span class="label"> {{ amenity.abbr }}</span>
        <i class="material-icons">{{ amenity.icon }}</i>
      </span>
    </template>
    <template v-else>
      <div
        v-for="amenity in amenitiesData"
        :key="amenity.abbr"
        class="amenity"
        :class="{active: amenities.indexOf(amenity.abbr) >= 0}"
      >
        <i class="material-icons figure">{{ amenity.icon }}</i>
        <span class="label"> {{ amenity.name }}</span>
        <i class="material-icons check">
          {{ amenities.indexOf(amenity.abbr) >= 0 ?'check_circle':'block' }}
        </i>
      </div>
    </template>
  </div>
</template>

<script>
  const amenities = [
    {
      abbr: 'wf',
      name: 'WIFI',
      icon: 'wifi',
    },
    {
      abbr: 'ft',
      name: 'Fitness',
      icon: 'fitness_center',
    },
    {
      abbr: 'ia',
      name: 'Internet Access',
      icon: 'offline_bolt',
    },
    {
      abbr: 'ec',
      name: 'Early Checkout',
      icon: 'av_timer',
    },
    {
      abbr: 'as',
      name: 'Airport Shuttle',
      icon: 'airport_shuttle',
    },
    {
      abbr: 'bf',
      name: 'Break Fast',
      icon: 'free_breakfast',
    },
    {
      abbr: 'prk',
      name: 'Parking',
      icon: 'local_parking',
    },
  ];
  export default {
    name: 'HotelDirectoryAmenities',
    props: ['amenities', 'expanded'],
    data() {
      return {
        amenitiesData: amenities,
      };
    },
  };

  export {
    amenities
  }
</script>

<style lang="stylus">
  .hotel-directory-amenities{
    display inline-flex;
    flex-direction row !important;

    .label{
      padding 0 0 5px 0;
    }

    & .icon{
      display: flex;
      align-items: center;
      text-transform: uppercase;
      flex-direction column;
      padding 0 3px;
      justify-content space-evenly;
      height 100%;
      color #CFD8DC !important;

      &.active{
        color: #546E7A !important;
      }

      i{
        width 24px;
      }
    }

    &.expanded{
      flex-direction column !important;
      flex 1;
    }

    .amenity{
      padding 5px 25px 5px 10px;
      display flex;
      justify-content space-between;
      align-items center;
      flex 1;
      width: 100%;
      box-sizing: border-box;
      border-bottom 1px solid #ECEFF1;

      .figure{
        padding-right 5px;
        width 25px;
      }

      .label{
        flex 1;
      }

      &.active .check{
        color #58b8a7;
        font-weight 900;
      }
    }
  }
</style>
