/**
 * Created by DejanK on 9/3/2016.
 *
 * rbHeader Component
 */
import '../../../../public/ui/headers.scss'
import HTML from './rb-header.html'
import {NAME as notImplementedDialog} from "root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl"
import {stopEvent} from "root/shared/tools/view-utils"
import {noop} from 'lodash'

export default {
  template: HTML,
  controller: RbHeaderCtrl
}

RbHeaderCtrl.$inject = ['$scope', 'CurrentUser', 'rbDialog']
function RbHeaderCtrl($scope, currentUserService, dialog){
  const vm = this

  vm.$onInit = () => { currentUserService.watchUser($scope, vm) }
  vm.showNotImplementedNotification = $event => {
    stopEvent($event)
    dialog.show(notImplementedDialog).catch( noop )
  }
}
