import './rfp-manager-companies.scss'
import HTML from './rfp-manager-companies.html'

export default {
  template: HTML,
  bindings: {
    accounts: '<',
    currentAccountId: '<',
    onAccountSelected: '&'
  },
  controller: RbRfpManagerCompanies
}

RbRfpManagerCompanies.$inject = [ 'rbDialog', '$state' ];
function RbRfpManagerCompanies( dialog, $state ) {
  const vm = this;

  vm.$onChanges = $onChanges;
  vm.onSelected = onSelected;
  vm.onDropDownOpened = onDropDownOpened;
  vm.onDropDownClosed = onDropDownClosed;
  vm.openInNew = $state.inNew;

  function $onChanges ( changes ) { return changes.accounts.currentValue && loadAccounts() }

  function onSelected ( account ) { if(account !== vm.currentAccount) { vm.onAccountSelected( { event: { account } } ) } }

  function onDropDownOpened () { vm.dropDownOpened = true }

  function onDropDownClosed () { vm.dropDownOpened = false }

  function loadAccounts() {
    const allCompanies = {name: 'All Companies'};
    vm.allAccounts = vm.accounts.map(({account}) => account).concat(allCompanies);

    vm.currentAccount = vm.allAccounts.find(account => account.id === vm.currentAccountId);
  }
}
