import Vue from 'vue'
import vNotification from './Notification.vue'

const config = {
  closeOnOutsideClick: false,
  snackBarDuration: 4000
};

class Notification {

  constructor(containerElement, dialogComponent, dialogData, options, type = 'dialog') {
    this.promise = new Promise((resolve, reject) => {
      this.$$promiseResolve = resolve
      this.$$promiseReject = reject
    })

    const element = document.createElement('div');
    containerElement.appendChild(element);

    options = Object.assign({}, config, options);

    this.$$instance = new Vue({
      el: element,
      render (createElement) { return createElement(vNotification, {
        props: {
          dialog: {
            component: dialogComponent,
            data: dialogData
          },
          options,
          ord: containerElement.childElementCount,
          type,
        }
      })}
    })

    if(options.snackBarDuration > 0 && type === 'snackbar') {
      setTimeout(() => this.cancel(), options.snackBarDuration);
    }
  }

  cancel (reason) { return this.$destroy( () => { this.$$promiseReject(reason) } ) }

  hide (reason) { return this.$destroy( () => { this.$$promiseResolve(reason) } ) }

  $destroy( action ){
    this.$$instance.$destroy();
    action && action();
    return this.promise
  }
}

export default Notification
