<script type="text/jsx">
  import RbSafeHtml from 'root/v-components/_core/RbSafeHtml.vue';

  /**
   * @name RbLetter
   *
   * @description
   * Prints RFP Letters
   *
   * @prop{string} letter Letter to print
   *
   * @usage:
   * <rb-letter :letter="htmlString" />
   */
  export default {
    functional: true,
    render (h, { props, data }) {
      return <RbSafeHtml
        html={ props.letter }
        class={ ['rbLetter', data.class, data.staticClass ] }
      />;
    }
  }
</script>

<style lang="stylus" module>

  :global {
    .rbLetter {

      font-size: 14px;
      color: #546E7A;
      font-weight: normal;
      display: block;

      p, div, span {
        font-size: 14px;
        line-height: 20px;
      }

      &:not(.printRbLetter) {
        .inserted {
          color: #00a99d;
        }
      }

      p {
        margin: 20px 0 !important;
      }

      h1 {
        display: block;
        font-size: 2em;
        margin: 0.67em 0;
        font-weight: bold;
      }

      h2 {
        display: block;
        font-size: 1.7em;
        margin: 0.83em 0;
        font-weight: bold;
      }

      h3 {
        display: block;
        font-size: 1.5em;
        margin: 1em 0;
        font-weight: bold;
      }

      h4 {
        display: block;
        font-size: 1.3em;
        margin: 1.33em 0;
        font-weight: bold;
      }

      h5 {
        display: block;
        font-size: 1.17em;
        margin: 1.67em 0;
        font-weight: bold;
      }

      h6 {
        display: block;
        font-size: 1em;
        margin: 2.33em 0;
        font-weight: bold;
      }

      hr {
        display: block;
        margin: 6px 0;
        border: 0;
        border-top: 1px dashed #E1E4E6;
      }

      .insertedLogo {
        max-width: 250px;
        max-height: 150px;
      }

      .ViewRateQuickConfiguration {
        width: 235px;
        color : #CFD8DC;

        display: inline-flex;
        flex-direction: column;
      }

      .ViewRateQuickConfigurationRow {
        margin-top: 5px;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
      }

      .ViewRateQuickConfigurationRowLabel {
        font-size: 13px;
        color: #78909C;
        flex: 0 0 auto;
        margin-right: 5px;
      }

      .ViewRateQuickConfigurationRowSeparator {
        font-size: 13px;
        border-bottom: 1px dotted #CFD8DC;
        flex: 1 0 auto;
      }

      .ViewRateQuickConfigurationRowValue {
        flex: 0 0 auto;
        margin-left: 5px;

        .inserted {
          font-size: 13px;
          color: #B0BEC5;
        }
      }

      .ViewRateQuickLogoSubtitle {
        .inserted {
          color: #546E7A;
        }
      }

      .ViewRateQuickRfpTitle {
        margin: 0;

        .inserted {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #455A64;
        }
      }
    }
  }
</style>
