/**
 * Created by DejanK on 8/15/2016.
 *
 * @ngdoc provider
 * @name rbDialogProvider
 * @module rb.core
 * @description
 * The rbDialogProvider provides all dialog manipulation
 */
import RbLog from "../../log/log"
import { isUndefined, find, merge } from 'lodash'

export default rbDialogProvider;

function rbDialogProvider(){
  const dialogs = [],
    provider = {
      $get: getService,
      // @Deprecated: use addDialog
      $new: newDialog,
      // @Deprecated: use addDialog
      add: newDialog,
      addDialog: addDialog,
      $isRegistered: isDialogRegistered
    };

  /*
   * @ngdoc method
   * @name rbDialogProvider#$new
   * @module rb.core
   * @description Registers new dialog.
   * @param `dialog` - {object}: dialog definition object with following properties:
   *
   *    - `name` - `{string}` Dialog name that will be used to call dialog with
   *    - `controller` - `{(string=|function()=}` Controller fn that should be associated with
   *      newly created scope or the name of a registered controller if passed as a string.
   *    - `controllerAs` - `{string=}` ['VM'] A controller alias name. If present the controller will be
   *      published to scope under the `controllerAs` name.
   *    - `template` - `{string=}` An html template as a string.
   *    - `templateUrl` - `{string=}` A path to an html template.
   *    - `transformTemplate` - `{function(template)=}` A function which transforms the template after
   *      it is loaded. It will be given the template string as a parameter, and should
   *      return a a new string representing the transformed template.
   *    - `resolve` - `{Object.<string, function>=}` - An optional map of dependencies which should
   *      be injected into the controller. If any of these dependencies are promises, the compiler
   *      will wait for them all to be resolved, or if one is rejected before the controller is
   *      instantiated `compile()` will fail..
   *      * `key` - `{string}`: a name of a dependency to be injected into the controller.
   *      * `factory` - `{string|function}`: If `string` then it is an alias for a service.
   *        Otherwise if function, then it is injected and the return value is treated as the
   *        dependency. If the result is a promise, it is resolved before its value is
   *        injected into the controller.
   *   - `locals` - `{object}`: The locals which will be passed into the controller once `link` is
   *     called. If `bindToController` is true, they will be copied to the ctrl instead
   *   - `bindToController` - `bool` [true] : bind the locals to the controller, instead of passing them in. Defaults to true
   *
   * @throws {'DialogAlreadyExistsException'} Will throw exception if dialog with given name is already registered
   */
  function newDialog(...theArguments){
    const dialogData = getDialogData(theArguments)
    if(findDialogByName(dialogData.name))
    {
      console.trace(`DialogAlreadyExistsException: ${dialogData.name}`)
      throw new Error(`DialogAlreadyExistsException: ${dialogData.name}`)
    }
    else {
      dialogs.push(dialogData);
    }
    return provider;

    function getDialogData(args){
      return args.length === 1 ? args[0] : {
        name: args[0],
        template: args[1],
        controller: args[2]
      }
    }
  }

  /*
 * @ngdoc method
 * @name rbDialogProvider#addDialog
 * @module rb.core
 * @description Registers new dialog.
 * @param `dialog` - {object}: dialog definition object with following properties:
 *    - `NAME` - `{string}` Dialog name that will be used to call dialog with
 *    - `HTML` - `{string=}` An html template as a string.
 *    - `Controller` - `{(string=|function()=}` Controller fn that should be associated with
 *      newly created scope or the name of a registered controller if passed as a string.
 * @throws {'DialogAlreadyExistsException'} Will throw exception if dialog with given name is already registered
 * @returns rbDialogProvider
 */
  function addDialog(dialogData){
    if(findDialogByName(dialogData.NAME)) {
      throw new Error(`DialogAlreadyExistsException: ${dialogData.NAME}`)
    } else {
      dialogs.push({
        name: dialogData.NAME,
        template: dialogData.HTML,
        controller: dialogData.Controller
      })
    }
    return provider
  }

  /*
   * @ngdoc method
   * @name rbDialogProvider#$isRegistered
   * @module rb.core
   * @description Checks to see if dialog with given dialogName is registered
   * @param `dialogName` - {string}: dialog name to check
   *
   * @returns {bool}: true if dialog is registered, false if not
   */
  function isDialogRegistered(dialogName){
    return !isUndefined(findDialogByName(dialogName));
  }

  function findDialogByName(dialogName){
    return find(dialogs, {name: dialogName})
  }

  /*
   * @ngdoc method
   * @name rbDialogProvider#$get
   * @module rb.core
   * @description Gets rbDialog serviceThe rbDialogProvider provides all dialog manipulation.
   * @param rbOverlayService - rbOverlayService
   *
   * @returns {object-} rbDialog service.
   * `rbDialog` service contains following properties:
   *    - `show` - `{function(dialogName, options)}`: Adds dialog with dialog name to the DOM. Returns dialog promise.
   *    - `hide` - `{function(result)}`: Removes last added dialog from the DOM and resolves dialog promise with result.
   *    - `cancel` - `{function(reason)}`: Removes last added dialog from the DOM and rejects dialog promise with reason.
   *    - `cancelAll` - `{function()}`: Removes all dialogs from the DOM and rejects dialog promises.
   */
  getService.$inject = ['rbOverlayService'];
  function getService(rbOverlayService){

    return {
      show: showDialog,
      hide: rbOverlayService.hide,
      cancel: rbOverlayService.cancel,
      cancelAll: rbOverlayService.cancelAll
    };

    /*
     * @ngdoc method
     * @name rbDialog#showDialog
     * @module rb.core
     * @description Adds dialog with dialog name to the DOM. Returns dialog promise.
     * @param
     *  `dialogName` - `{string}` - Name of the dialog which will be added to the DOM
     *  `options` - `{object}` - Object which can be used to customize shown dialog. Has following properties:
     *
     *    - `controller` - `{(string=|function()=}` Controller fn that should be associated with
     *      newly created scope or the name of a registered controller if passed as a string.
     *    - `controllerAs` - `{string=}` A controller alias name. If present the controller will be
     *      published to scope under the `controllerAs` name. Defaults to 'VM'
     *    - `template` - `{string=}` An html template as a string.
     *    - `templateUrl` - `{string=}` A path to an html template.
     *    - `transformTemplate` - `{function(template)=}` A function which transforms the template after
     *      it is loaded. It will be given the template string as a parameter, and should
     *      return a a new string representing the transformed template.
     *    - `resolve` - `{Object.<string, function>=}` - An optional map of dependencies which should
     *      be injected into the controller. If any of these dependencies are promises, the compiler
     *      will wait for them all to be resolved, or if one is rejected before the controller is
     *      instantiated `compile()` will fail..
     *      * `key` - `{string}`: a name of a dependency to be injected into the controller.
     *      * `factory` - `{string|function}`: If `string` then it is an alias for a service.
     *        Otherwise if function, then it is injected and the return value is treated as the
     *        dependency. If the result is a promise, it is resolved before its value is
     *        injected into the controller.
     *   - `locals` - `{object}`: The locals which will be passed into the controller once `link` is
     *     called. If `bindToController` is true, they will be copied to the ctrl instead
     *   - `bindToController` - `bool`: bind the locals to the controller, instead of passing them in. Defaults to true
     *
     * @returns {object=} promise A promise, which will be resolved when `cancel` or `hide` is called
     * @throws {'UnknownDialogException'} Will throw if dialog with given name is not registered
     */
    function showDialog(dialogName, options){
      const dialog = findDialogByName(dialogName);
      if(dialog){
        return rbOverlayService.show(merge({
          controllerAs: 'VM',
          bindToController: true
        }, dialog, options));
      } else {
        RbLog.error({ requested: dialogName, available: dialogs})
        throw new Error('UnknownDialogException');
      }
    }
  }

  return provider;
}
