/**
 * Created by DejanK on 4/13/2017.
 */
import HTML from './bid-response.html'
import '../bid-response.scss'

import { noop, get } from 'lodash'
import { FillResponseOnlyOnce } from '../../../../../../auth/tutorials.service';
import saveResponseService from '../save-response.service'

export default {
  template: HTML,
  bindings: {
    bid: '<',
  },
  controller: CreateBidResponseController,
}

CreateBidResponseController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', 'TutorialsService', '$state', '$scope', 'CurrentUser'];
function CreateBidResponseController(notificationUtils, RfpHotelBidRepository, tutorials, $state, $scope, currentUser){
  const vm = this;
  let areQuestionsTouched;

  vm.onAnswersChanged = onAnswersChanged;
  vm.close = close;
  vm.save = save;
  vm.saveDraft = saveDraft;
  vm.saveText = 'Save Draft';
  vm.gotoQuestion = gotoQuestion;

  $onInit();

  function $onInit(){
    currentUser.get()
      .then(cu => !cu.isAccountType('CHAIN') && tutorials.show(FillResponseOnlyOnce))
      .catch(noop)
  }

  this.$onChanges = function( changes ) {
    vm.bid = get(changes, 'bid.currentValue');
    vm.bidId = get(vm.bid, 'id');
    vm.questionnaire = get(vm.bid, 'questionnaire');
    vm.response = get(vm.bid, 'responseDraft');
  };

  function onAnswersChanged( event ){
    vm.response = event.response ;
    vm.saveText = 'Save Draft';

    if( isResponseTouched() ){
      touchQuestions();
    }

    if( areQuestionsTouched ) {
      checkErrors();
    }
  }

  function isResponseTouched(){
    return vm.response.response.touched
  }

  function touchQuestions(){
    if(!areQuestionsTouched) {
      $scope.$broadcast('vue-event', {event: 'questionnaireQuestion.touch'});
      areQuestionsTouched = true;
      checkErrors();
    }
  }

  function checkErrors(){
    vm.errors = Object.values(vm.response.errors).map(v => v[0]).sort((a, b) => a.ord - b.ord);
    vm.hiddenErrorsCount = vm.errors.length - 5;

    if( vm.hiddenErrorsCount > 0 ) {
      vm.errors.length = 5;
    }
  }

  function close(){
    $state.go('bidManager', { viewId: null });
  }

  function gotoQuestion(id){
    const div = document.getElementById('document-container'),
      el = document.getElementById(id);

    if(el){
      div.scrollTop += el.getBoundingClientRect().top - 180;
    }
  }

  function getAnswers(){
    return vm.response.response.answers
  }

  function saveDraft(){
    touchQuestions();
    if( vm.saveText !== 'SAVED' ){
      saveResponseService.saveDraft(vm.bidId, getAnswers())
        .then( () => { vm.saveText = 'SAVED' }, handleError )
        .catch(noop);
    }

    function handleError(report) {
      const notification = {
        heading: 'Response Draft',
        title: 'Not Saved!',
      };

      switch (report.getStatus()) {

        case 'NOT_FOUND':
          Object.assign(notification, {
            message: 'Bid was deleted. You will be redirected to your Bid Manager.',
            redirect: true
          });
          break;

        case 'STATUS_CHANGED':
          Object.assign(notification, {
            message: 'Bid has changed. You will be redirected to your Bid Manager.',
            redirect: true
          });
          break;

        case 'ERROR':
        default:
          Object.assign(notification, {
            message: 'Something went wrong! You could try again to save draft response.',
            redirect: false
          });
          break;
      }
      return notificationUtils
        .showErrorDialog({dialogData: notification})
        .finally(() => notification.redirect && close());
    }
  }


  function save() {
    touchQuestions();
    saveResponseService
      .save(vm.response, vm.bidId)
      .then(showSaveSuccess, handleError)
      .catch(noop);

    function showSaveSuccess(actionReport){
      const buyerName = get(actionReport.getData(), 'buyer.company.name', 'the buyer');
      return notificationUtils
        .showSuccessDialog({
          dialogData: {
            heading: 'Questionnaire Response',
            title: 'Sent Successfully',
            message: `RFP was received successfully at ${buyerName}`
          }})
        .finally(close)
    }

    function handleError(report) {
      if(report && report.getStatus) {
        return notificationUtils
          .showErrorDialog({
            dialogData: {
              heading: 'Response',
              title: 'Not Sent!',
              message: getMessage(report.getStatus())
            }
          })
          .finally(close);
      } else {
        return Promise.resolve();
      }

      function getMessage(status){
        switch (status) {
          case 'NOT_FOUND':
            return 'Bid was deleted. You will be redirected to your Bid Manager.';
          case 'STATUS_CHANGED':
            return 'Bid has changed. Response might have been sent by someone else. You will be redirected to your Bid Manager.';
          case 'ERROR':
            return 'Response was already sent!';
          default:
            return 'Something went wrong! You could try again to send response.';
        }
      }
    }

  }
}
