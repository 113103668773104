import AgentInvitationsHandlerModule from './AgentInvitationsHandler.vue';
const AGENT_INVITATIONS_HANDLER = 'AGENT';

export default {
  id: AGENT_INVITATIONS_HANDLER,
  component: AgentInvitationsHandlerModule,
}

export {
  AGENT_INVITATIONS_HANDLER
};
