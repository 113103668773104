<template>
  <div class="rbInputRangeContainer"
       @click="handleMouseClicked($event)"
       @mousedown="handleMouseDown($event)">
    <div class="rbInputRangeTrack">
      <div class="rbInputRangeFill"
           :style="{width: valuePercentage}"></div>
      <a href="#"
         class="rbInputRangeThumb"
         :style="{left: valuePercentage}"
         @focus="handleFocus"
         @blur="handleBlur"
         @keydown="handleKeyDown($event)"></a>
    </div>
  </div>
</template>

<script>
  const
    CLASS_THUMB = "rbInputRangeThumb",
    CLASS_TRACK = "rbInputRangeTrack",
    CLASS_CONTAINER = "rbInputRangeContainer"

  export default {
    name: 'rb-input-range',
    data () {
      return {
        inputValue: 0,
        containerElement: null,
        trackElement: null,
        thumbElement: null
      }
    },
    props: {
      value: {
        type: Number,
        required: true,
        'default': 0
      },
      min: {
        type: Number,
        'default': 0
      },
      max: {
        type: Number,
        'default': 100
      },
      step: {
        type: Number,
        'default': 1
      }
    },
    computed: {
      actualInputValue () { return this.value },
      valuePercentage () { return ((this.actualInputValue - this.min) / (this.max - this.min) * 100) + "%" }
    },
    methods: {
      enableMouseMovementListeners(enabled){
        if(enabled) {
          this.containerElement.addEventListener('mousemove', this.setValueFromMouseEvent)
          this.containerElement.addEventListener('mouseleave', this.handleMouseLeave)
          this.containerElement.addEventListener('mouseup', this.handleMouseLeave)
        } else {
          this.containerElement.removeEventListener('mousemove', this.setValueFromMouseEvent)
          this.containerElement.removeEventListener('mouseleave', this.handleMouseLeave)
          this.containerElement.removeEventListener('mouseup', this.handleMouseLeave)
        }
      },

      findElement(element, elementClass){
        return element.classList.contains(elementClass) ? element : this.findElementInChildren(element.children, elementClass)
      },
      findElementInChildren(children, elementClass) {
        for(let i = 0, l = children.length; i<l; i++){
          const e = this.findElement(children[i], elementClass)
          if(e){ return e }
        }
      },

      handleKeyDown( event ){
        let val = this.value;
        
        if(event.key === 'ArrowRight'){
          val += this.step;
        }else if(event.key === 'ArrowLeft'){
          val -= this.step;
        }

        if(val > this.max || val < this.min){
          return;
        }

        this.$emit('keys', { value: val });
        this.$emit('input', { value: val });
      },
      handleFocus(event){
        this.$emit('focus', event);
      },
      handleBlur(event){
        this.$emit('blur', event);
      },
      handleMouseClicked ( event ) {
        event.preventDefault()
        this.thumbElement.focus()
        this.setValueFromMouseEvent(event, true)
      },
      handleMouseDown (event) {
        event.target === this.thumbElement && this.enableMouseMovementListeners(true)
      },
      handleMouseLeave($event) {
        this.enableMouseMovementListeners(false)
        $event.target.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
          clientX: $event.clientX
        }))
      },

      setValueFromMouseEvent(event, emitChanged){
        const
          trackRect = this.trackElement.getBoundingClientRect(),
          valPercentage = (event.clientX - trackRect.left) / trackRect.width

        let val = (this.max-this.min) * valPercentage
        val = (Math.ceil(val / this.step) * this.step + this.min).toFixed(6)
        val = val < this.min ? this.min : ( val > this.max ? this.max : val )

        this.$emit('input', { value: val })
        emitChanged && this.$emit('changed', { value: val } )
      },
    },
    mounted () {
      this.containerElement = this.findElement(this.$el, CLASS_CONTAINER)
      this.trackElement = this.findElement(this.$el, CLASS_TRACK)
      this.thumbElement = this.findElement(this.$el, CLASS_THUMB)
      this.thumbElement.ondragstart = () => { return false }
    },
  }
</script>

<style lang="stylus">

  .rbInputRangeContainer
    display inline-block
    box-sizing border-box
    min-width 40px
    padding 8px 8px
    cursor pointer

  .rbInputRangeTrack
    box-sizing border-box
    position relative
    width 100%
    height 4px
    border 1px solid black
    border-radius 1px
    background lightgray

  .rbInputRangeFill
    position absolute
    top 0
    left 0
    height 2px
    width 0
    background gray

  .rbInputRangeThumb
    display block
    box-sizing border-box
    position absolute
    height 15px
    width 15px
    top 0
    left 0
    background gray
    border 1px solid black
    border-radius 15px
    margin-left -7px
    margin-top -7px
    &:focus
      border-color black
      background white
</style>
