/**
 * Created by DejanK on 12/22/2016.
 */
import * as AddTravelDestinationDialog from './edit-destination/add-destination.ctrl'
import * as EditTravelDestinationDialog from './edit-destination/edit-destination.ctrl'
import * as EditUploadedTravelDestinationDialog from './upload-destination/edit-destination/edit-uploaded-destination.ctrl'
import * as UploadTravelDestinationShowInstructionsDialog from './upload-destination/show-instructions/show-instructions.ctrl'
import * as UploadTravelDestinationShowResultsDialog from './upload-destination/show-results/show-results.ctrl'
import * as DeleteTravelDestinationDialog from './delete-destination/delete-destination.ctrl'
import * as DeleteUploadedTravelDestinationDialog from './upload-destination/delete-destination/delete-uploaded-destination.ctrl'
import * as DestinationManagerHotelProfileDialog from './hotel-profile/hotel-profile.ctrl'
import * as PreviewDestinationsAndHotelsDialog from './preview-destinations/preview-destinations.ctrl';

export default Dialogs

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {

  rbDialogProvider
    .addDialog(AddTravelDestinationDialog)
    .addDialog(EditTravelDestinationDialog)
    .addDialog(EditUploadedTravelDestinationDialog)
    .addDialog(UploadTravelDestinationShowInstructionsDialog)
    .addDialog(UploadTravelDestinationShowResultsDialog)
    .addDialog(DeleteTravelDestinationDialog)
    .addDialog(DeleteUploadedTravelDestinationDialog)
    .addDialog(DestinationManagerHotelProfileDialog)
    .addDialog(PreviewDestinationsAndHotelsDialog)
}

