import HTML from './rb-user-as-contact.html'
import defaultImage from 'images/user-image.png'
import moment from 'moment';

export default {
  template: HTML,
  controller: Ctrl,
  bindings: {
    user: '<',
    selected: '<',
    onSelect: '&',
  }
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  vm.defaultImage = defaultImage;
  if(vm.selected) {
    $element.scrollIntoView();
  } 

  vm.$onChanges = function(changes){
    if(changes.user){
      vm.userName = vm.user ? `${vm.user.firstName} ${vm.user.lastName}` : '';
      vm.invitation = vm.user.invitation;
      vm.lastActive = vm.user.lastActive && moment(vm.user.lastActive).fromNow();
    }
  };
}
