<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <img
        v-if="user.profilePicture"
        :src="logo"
        alt="User Image"
        :class="$style.logo"
      >
      <div
        v-else
        :class="$style.logo"
      >
        <i class="material-icons">person</i>
      </div>
      <div :class="$style.desc">
        <div :class="$style.name">
          {{ user.fullName }}
        </div>
        <dropdown-actions
          v-if="currentUserIsAdmin && !isCurrentUser"
          v-model="userType"
          :items="types"
          class="light"
          :class="$style.role"
        >
          <template slot="value">
            <i class="material-icons active">account_circle</i>
            User Type - {{ userType && userType.label }}
          </template>
        </dropdown-actions>
        <div
          v-else
          :class="$style.inactiveRole"
        >
          <i class="material-icons active">account_circle</i>
          User Type - {{ userType && userType.label }}
        </div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody"
      :class="$style.body"
    >
      <div :class="$style.listHead">
        <span>
          User is assigned to {{ user.accountsManaged.length }} {{ user.accountsManaged.length > 1? 'Companies': 'Company' }}
        </span>
        <span>User Assigned:</span>
      </div>
      <div :class="$style.companies">
        <template v-for="{ listAdded, list } in companiesList">
          <div
            v-for="(account, index) in list"
            :key="account.id"
            :class="{
              [$style.company]: true,
              [$style.lastAdded]: currentUserIsAdmin && listAdded && hasBothAddedAndNotAdded && index === list.length -1
            }"
            @click="toggle(account)"
          >
            <check-box
              v-if="currentUserIsAdmin"
              :value="added(account)"
              :check-box-value="true"
              @input="toggle(account)"
              @click.stop.prevent
            />
            <div :class="$style.name">
              {{ account.name }}
            </div>
            <div
              v-if="account.primaryAgent.userDetails"
              :class="$style.primary"
            >
              {{ account.primaryAgent.userDetails.fullName }}
            </div>
            <div
              v-else
              :class="$style.unAssigned"
            >
              Not Assigned
              <i class="material-icons">warning</i>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        class="rbButton"
        type="button"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        v-if="currentUserIsAdmin"
        class="rbButton mainButton"
        :disabled="!canSave"
        type="button"
        @click="save"
      >
        <i class="material-icons">account_circle</i>
        Apply Changes
      </button>
    </div>
  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import {ACCOUNT_ADMIN, ACCOUNT_MANAGER, showPrimaryToBeReplacedDialog} from '../services/account-manage-users-list.service';
  import dropdownActions from 'vRoot/rfp-manager/core/rfp-manager-dropdown-actions.vue';
  import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
  import {isEqual} from 'lodash';

  export default {
    name: 'ManageUserAssignmentsDialog',
    components: { ModalForm, dropdownActions, CheckBox },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      user: {
        type: Object,
        required: true,
      },
      companies: {
        type: Array,
        required: true,
      },
      currentUserIsAdmin: {
        type: Boolean,
        required: true
      },
      isCurrentUser: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        data: {
          ...this.user,
        },
      }
    },
    computed: {
      logo() { return this.user.profilePicture? `/images/users/${this.user.profilePicture}`: '/images/user-image.png'},
      types() {
        return [
          {
            id: ACCOUNT_ADMIN,
            label: 'Administrator'
          },
          {
            id: ACCOUNT_MANAGER,
            label: 'Account Manager'
          }
        ];
      },
      userType: {
        set(value) {
          this.data.role = value.id;
        },
        get() {
          return this.types.find(type => type.id === this.data.role);
        }
      },
      alreadyAddedCompanies() {
        return this.companies.filter(company => company.primaryAgent.userId === this.user.id);
      },
      otherCompanies() {
        return this.companies.filter(company => company.primaryAgent.userId !== this.user.id);
      },
      companiesList() {
        const list = [
          {listAdded: true, list: this.alreadyAddedCompanies},
        ];

        if(this.currentUserIsAdmin && this.otherCompanies.length > 0) {
          list.push({listAdded: false, list: this.otherCompanies});
        }

        return list;
      },
      canSave() { return !isEqual(this.user.role, this.data.role) || this.addedIds.length > 0 || this.removedIds.length > 0; },
      addedIds() {
        return this.data.accountsManaged.filter(account => this.user.accountsManaged.map(({accountId}) => accountId).indexOf(account.accountId) < 0).map(({accountId}) => accountId);
      },
      removedIds() {
        return this.user.accountsManaged.filter(account => this.data.accountsManaged.map(({accountId}) => accountId).indexOf(account.accountId) < 0).map(({accountId}) => accountId);
      },
      hasBothAddedAndNotAdded() {
        return this.companiesList.length > 1;
      }
    },
    methods: {
      onSubmit() {
        return { data: this.data, ids: {addedIds: this.addedIds, removedIds: this.removedIds}};
      },
      added(account) {
        return !!this.data.accountsManaged.find(managed => account.id === managed.id || account.id === managed.accountId);
      },
      toggle(account) {
        const vm = this;
        if(this.added(account)){
          this.data.accountsManaged = this.data.accountsManaged.filter(managed => managed.accountId !== account.id);
        } else{
          add();
        }

        function add() {
          vm.data.accountsManaged = [...vm.data.accountsManaged, {...account, accountId: account.id}];
        }
      },
      companyAlreadyAssigned(company) {
        return company.primaryAgent && company.primaryAgent.userId && company.primaryAgent.userId !== this.user.id;
      },
      save() {
        const replacement = !!this.data.accountsManaged.find(account => this.companyAlreadyAssigned(account));
        return replacement? showPrimaryToBeReplacedDialog().then(this.$formSubmitHandler): this.$formSubmitHandler();
      }
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 500px;
  }

  .header{
    display flex;
  }

  .header .logo{
    height: 64px;
    width: 64px;
    object-fit: contain;
    border-radius 3px;
    margin-right 10px;
    border 1px solid #ECEFF1;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing border-box;
  }

  .header .logo :global i.material-icons{
    font-size 50px;
  }

  .desc{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .desc .name{
    font-size 15px;
  }

  .role{
    font-size 12px;
    font-weight normal;
    color #546E7A;
  }

  .desc .role :global .material-icons.active{
    color: #00b8aa;
    font-size: 18px;
    margin-right 0 5px;
  }

  .inactiveRole {
    composes role;
    display: flex;
    align-items: center;
  }

  .listHead{
    display flex;
    justify-content space-between;
    align-items center;
    padding 5px 0;
    color #546E7A;
    font-size 12px;
  }

  .companies{
    max-height 400px;
    overflow-y auto;
    border: 1px solid #B0BEC5;
    color #546E7A;
  }

  .company{
    padding 10px 5px;
    height 42px;
    border-bottom 1px solid #ECEFF1;
    display flex;
    align-items center;
    font-size 12px;
    cursor pointer;
    box-sizing border-box;
  }

  .company .name{
    flex 1;
  }

  .dialog :global .rbCheckboxOptionButton{
    padding: 0;
    border: none !important;
  }

  .dialog :global .rbCheckboxOptionIcon.material-icons{
    font-size 20px;
  }

  .lastAdded{
    border-bottom 3px solid #CFD8DC;
  }

  .options{
    display: flex;
    font-size: 12px;
    color: #7f909b;
    margin: 10px 0;
  }

  .options i{
    font-size 30px;
    margin-right 10px;
  }

  .unAssigned{
    display flex;
    color #f25b26;
    align-items center;
  }

  .unAssigned i{
    font-size 14px;
  }
</style>
