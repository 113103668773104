<template>
  <div class="account-manage-companies">
    <div
      v-if="!loaded"
      class="layout-column flex-center loader"
    >
      <rbv-loading label="Loading, please wait..." />
    </div>
    <template v-else>
      <div class="header flex column">
        <div class="flex row">
          <div class="logo">
            <img
              v-if="account && account.logo"
              :src="logo"
              alt="Account Logo"
            >
            <div v-else>
              <i class="material-icons">business</i>
            </div>
          </div>
          <div class="flex column">
            <div class="title">
              {{ account && account.name }}
            </div>
            <div class="sub-title">
              {{ module.title }}
            </div>
          </div>
        </div>
        <div class="flex row controls">
          <div class="flex row">
            <button
              class="button"
              @click="editLogo"
            >
              <i class="material-icons">add_a_photo</i>
              Change Logo
            </button>
          </div>
          <template v-if="isFromRfpManager">
            <button
              class="button"
              @click="openRfpManager"
            >
              RFP Manager
            </button>
            <button
              class="button"
              @click="openManageCustomers"
            >
              Customers
            </button>
          </template>
          <button
            class="button"
            @click="editProfile"
          >
            <i class="material-icons">edit</i>
            Edit Basic Information
          </button>
        </div>
      </div>
      <account-details :entity="account" />
    </template>
  </div>
</template>

<script>
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';

  import listUsersService, { ACCOUNT_ADMIN } from '../manage-users/services/account-manage-users-list.service';
  import manageCompaniesService from '../manage-companies/services/manage-company.service';
  import {PAGE_RFP_MANAGER_COMPANY_PROFILE} from 'root/states';
  import module from './AccountCompanyProfileModule';
  import AccountDetails from './account-details.vue';

  export default {
    name: 'AccountCompanyProfile',
    components: {AccountDetails, RbvLoading },
    props: {
      state: {
        type: String,
        required: true
      }
    },
    data() {
      const sorters = [
        {
          id: 'nameDesc',
          label: 'Name A-Z',
          order: 'asc',
          key: 'name'
        },
        {
          id: 'nameAsc',
          label: 'Name Z-A',
          order: 'desc',
          key: 'name'
        }
      ];

      return {
        sorters,
        sortBy: sorters[0],
        module,
        updated: 0
      }
    },
    asyncComputed: {
      account: {
        get() {
          return manageCompaniesService.getAccountForForCurrentUrl(this.userAccount && this.userAccount.accountId);
        },
        watch() {
          return this.updated;
        }
      },
      userAccount() { return listUsersService.loadAuthorization().then(({data}) => data)},
    },
    computed: {
      logo() { return `/images/logos/${this.account.logo}` },
      loaded() { return this.userAccount && this.account; },
      role() { return this.userAccount && this.userAccount.role && this.userAccount.role.permission; },
      isAdmin() { return this.role === ACCOUNT_ADMIN },
      isFromRfpManager() { return this.state === PAGE_RFP_MANAGER_COMPANY_PROFILE },
    },
    methods: {
      editProfile() {
        return manageCompaniesService.editCompanyProfile(this.account).then(this.refresh);
      },
      editLogo() {
        return manageCompaniesService.editLogo(this.account).then(this.refresh);
      },
      openManageCustomers() { listUsersService.openManageCustomers(); },
      refresh() {
        this.updated++;
      },
      openRfpManager() {
        listUsersService.openRfpManager();
      }
    }
  }
</script>

<style lang="stylus">
  .account-manage-companies{
    color #37474f;
    position relative;
    overflow auto;
    max-height: calc(100vh - 100px);

    .loader{
      height 100%;
    }

    .header{
      padding: 20px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      position: sticky;
      top: 0;
      background: #fff;
      z-index 9;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      & > div > i.material-icons{
        padding: 10px;
        background: rgba(0,0,0,0.1);
        border-radius: 100%;
        margin-right: 10px;
      }

      .title{
        font-size: 12px;
        color: #8c999f;
      }

      .sub-title{
        font-size 20px;
      }
    }

    .flex{
      display flex;

      &.row{
        align-items center;
        justify-content flex-start;
      }

      &.column{
        flex-direction column;
      }
    }

    .controls{
      margin-top 10px;
      justify-content space-between !important;

      &, & > div{
        align-items flex-end !important;
      }

      .rfp-manager-group-by{
        font-size 12px;
      }

      & > .row{
        flex: 1;
      }
    }

    .button{
      padding: 2px 5px;
      align-items: center;
      display: flex;
      font-size 12px;
      background white linear-gradient(0deg, #f5f6f7 10%, white 11.05%, white 100%);
      height 27px;

      &:not(.icon-only) {
        i.material-icons{
          margin 0 5px 0 0;
          font-size 18px;
        }
      }
    }

    .create{
      background #00b8ab linear-gradient(0deg, #00b0a3 10%, #00b8ab 11.05%, #00b8ab 100%)
      color: #fff;
      border: 1px solid #00b8a9;
    }

    .logo{
      position relative;
      margin-right 10px;

      & > img, & > div{
        height: 65px;
        width: 110px;
        object-fit: contain;
        border-radius 3px;
        border 1px solid #ECEFF1;
        background: #ECEFF1;
        display: flex;
        justify-content: center;
        align-items: center;

        i.material-icons{
          font-size 50px;
        }
      }

      & > img{
        background transparent;
      }
    }
  }
</style>
