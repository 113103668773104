<template>
  <div
    v-if="entity === 'LOADING'"
    class="layout-column flex-center"
  >
    <rbv-loading label="Loading, please wait..." />
  </div>
  <div v-else>
    <div :class="$style.header">
      <div
        v-if="isVerified"
        :class="$style.headerStatus"
      >
        <i
          class="material-icons"
          :class="$style.headerStatusIcon"
        >check_circle</i>
        <span :class="$style.headerStatusLabel">Verified {{ type }}</span>
      </div>
      <div
        v-else-if="isObsolete"
        :class="$style.headerStatus"
      >
        <i
          class="material-icons"
          :class="$style.headerStatusIcon"
        >info</i>
        <span :class="$style.headerStatusLabel">Obsolete {{ type }}</span>
      </div>
      <div
        v-else
        :class="$style.headerStatus"
      >
        <i
          class="material-icons"
          :class="$style.headerStatusIcon"
        >info</i>
        <span :class="$style.headerStatusLabel">Unverified {{ type }}</span>
      </div>
      <h2 :class="$style.headerTitle">
        {{ entity.name }}
      </h2>
    </div>

    <div :class="$style.section">
      <div :class="$style.sectionHeading">
        <div
          class="material-icons"
          :class="$style.sectionHeadingIcon"
        >
          business_center
        </div>
        <div :class="$style.sectionHeadingLabel">
          Basic Information
        </div>
        <div>
          <button
            class="rbButton smallIcon"
            @click="edit"
          >
            <i class="material-icons">mode_edit</i>
          </button>
        </div>
      </div>
      <div :class="$style.sectionContent">
        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">
            Company Name
          </div>
          <div :class="$style.detailsValue">
            {{ entity.name }}
          </div>
        </div>

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">
            Industry
          </div>
          <div :class="$style.detailsValue">
            {{ industry }}
          </div>
        </div>

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">
            Address
          </div>
          <div :class="$style.detailsValue">
            {{ address || '-' }}
          </div>
        </div>

        <div :class="$style.detailsRow">
          <div :class="$style.detailsLabel">
            Company URL
          </div>
          <div
            v-if="website"
            :class="$style.detailsValue"
          >
            <a
              :href="website"
              :class="$style.website"
              target="_blank"
            >
              {{ website }}
            </a>
          </div>
          <div
            v-else
            :class="$style.detailsValue"
          >
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EntityTypeService from 'root/entity/EntityTypeService'
  import EntityIndustryService from 'root/entity/EntityIndustryService'
  import { noop } from 'lodash'
  import { Dialog } from 'root/v-app/rbServices';
  import EditBasicInformationDialog from './EditBasicInformationDialog.vue'
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';
  import accountProfileService from 'root/v-components/account-profile/accountProfileService';

  export default {
    name: 'AccountBasicInformationModule',
    components: { RbvLoading },
    data () {
      return {
        updated: 0
      }
    },
    asyncComputed: {
      entity: {
        get () { return accountProfileService.getEntity() },
        'default': 'LOADING',
        watch () {
          return this.updated
        }
      },
      industry () { return EntityIndustryService.labelOf(this.entity.industry) },
      type () { return EntityTypeService.labelOf(this.entity.type) }
    },
    computed: {
      isVerified () { return this.entity.status === 'ACTIVE' },
      isObsolete () { return this.entity.status === 'OBSOLETE' },
      address () { return this.entity && this.entity.location && this.entity.location.fullAddress },
      website () { return this.entity && this.entity.website }
    },
    methods: {
      edit () {
        Dialog.show(EditBasicInformationDialog, { entity: this.entity })
          .then(accountProfileService.refresh)
          .then( () => { this.updated++ }, noop)
      }
    }
  }
</script>

<style lang="stylus" module>

  .header {
    padding: 20px;
  }

  .headerStatus {
    line-height : 23px;
  }

  .headerStatusIcon {
    color: #00B8AB;
    font-size: 18px;
    vertical-align: middle;
  }

  .headerStatusLabel {
    font-size : 12px;
    color : #90A4AE;
    vertical-align: middle;
  }

  .headerTitle {
    font-size: 20px;
    color: #263238;
    font-weight: normal;
  }

  .sectionHeading {
    padding: 0 20px;
    height: 41px;
    display: flex;
    align-items: center;
  }

  .sectionHeadingIcon {
    margin-right: 10px
    color: #00B8AB;
  }

  .sectionHeadingLabel {
    flex: 1 0 auto
    font-weight: bold;
    font-size: 14px;
    color: #37474F;
  }

  .sectionContent {
    padding: 26px 20px;
    border-top: 1px solid #ECEFF1;
  }

  .detailsRow {
    display: flex
    font-size : 12px;
    line-height: 18px;
    margin-bottom: 9px;
  }

  .detailsLabel {
    flex: 0 0 130px;
    padding-right: 20px
    color: #78909C;
  }

  .detailsValue {
    flex: 1 0 300px;
    color: #455A64;
    overflow-x: auto;
  }

  .website {
    color: inherit !important;
    font-size: inherit !important;

    &:hover {
      color: #37474F !important;
      text-decoration: none;
    }
  }

</style>
