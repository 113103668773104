/**
 * Created by DejanK on 4/5/2017.
 *
 * Bid Manager View Service
 *
 */
import { clone, forEach, get, merge, orderBy, findIndex } from 'lodash'

export default BidManagerViewService

BidManagerViewService.$inject = ['BidManagerRepository', 'DataServer', '$q'];
function BidManagerViewService(BidManagerRepository, dataServer, $q){
  const $service = this;

  $service.getView = getViewById;
  $service.getViewByRfpId = getViewByRfpId;
  $service.getUserViews = BidManagerRepository.getUserViews;
  $service.getGroupByOptions = getGroupByOptions;
  $service.getFilterByOptions = getFilterByOptions;

  function getViewById(viewId) {
    return getView(() => BidManagerRepository.getView(viewId))
  }

  function getViewByRfpId(rfpId) {
    return getView(() => BidManagerRepository.getViewByRfpId(rfpId))
  }

  function getView(loadViewFn) {
    return $q.all({
      view: loadViewFn(),
      columnsData: dataServer.getBidManagerHeadings()
    })
      .then(initializeView);
  }

  function initializeView(data){
    const view = data.view.data, columnsData = clone(data.columnsData);
    return {
      $view: view,
      id: view.id,
      name: view.name,
      columns: initColumns(view.columns, columnsData),
      sort: initSort(view.sort, columnsData),
      group: get(columnsData, view.group),
      filter: initFilter(view.filter, columnsData)
    };

    function initColumns(columnsIds, cData){
      const initialized = [];
      forEach(columnsIds, (id)=>{
        const c = get(cData, id);
        return c && initialized.push(merge({}, c)); // must create a copy in case there are duplicates (for ng-repeat not to throw)
      });
      return initialized
    }

    function initSort(sortObj, cData){
      const initialized = {};
      forEach(sortObj, (direction, key)=>{
        const c = get(cData, key);
        if(c) {
          initialized[key] = { by: c, direction: direction};
        }
      });
      return initialized;
    }

    function initFilter(filterObj, cData){
      const initialized = {};
      forEach(filterObj, (filterValue, key)=>{
        const c = get(cData, key);
        if(c) {
          initialized[key] = { by: c, value: filterValue};
        }
      });
      return initialized;
    }
  }

  function getGroupByOptions(view){
    if(view) {
      let listOfGroupableColumns = getGroupableColumns(view.columns);
      listOfGroupableColumns = orderBy(listOfGroupableColumns, ['label']);
      listOfGroupableColumns.unshift({label: 'None', id: null});
      return listOfGroupableColumns;
    }

    return null;

    function getGroupableColumns(columns) {
      return columns.reduce((acc, column) => {
        if(column.$groupable) {
          acc = [...acc, column];
        }

        if(column.$groupableColumns && column.$groupableColumns.length > 0) {
          acc = [...acc, ...getGroupableColumns(column.$groupableColumns)];
        }

        return acc;
      }, []);
    }
  }

  function getFilterByOptions(view){
    const groupByFilter = get(view, 'quickies.group.$filter');
    let filtersList = [];
    if(groupByFilter) {
      filtersList = view.$lastHttpResponse.data.filter(bid => !bid.header).reduce((acc, item) => {
        const by = get(item, groupByFilter.by),
          value = get(item, groupByFilter.values);
        return (by && findIndex(acc, {value: by}) === -1) ? [...acc, {value: by, label: value}]: acc;
      }, []);
      filtersList = orderBy(filtersList, ['label']);
      filtersList.unshift({label: 'None'});
    }
    return filtersList;
  }
}
