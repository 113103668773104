import {simpleState} from "root/shared/tools/router-helper";
import * as ContactUs from "root/public/states/contact-us/contact-us.ctrl";
import * as CustomRfp from "root/public/states/custom-rfp/custom-rfp.ctrl";
import * as Pricing from "root/public/states/pricing/pricing.ctrl";
import * as Home from "root/public/states/home-page/home-page.ctrl";
import * as Terms from "root/public/states/terms/terms.ctrl";

const
  PAGE_HOME = 'Home',
  PAGE_PRICING = 'pricing',
  PAGE_TERMS = 'terms',
  PAGE_PUBLIC_CONTACT_US = 'contactUs',
  PAGE_PUBLIC_CUSTOM_RFP = 'customRfp'

export { PublicStates as default,
  PAGE_HOME,
  PAGE_PRICING,
  PAGE_TERMS,
  PAGE_PUBLIC_CONTACT_US,
  PAGE_PUBLIC_CUSTOM_RFP
}

function PublicStates($stateProvider) {
  $stateProvider
    .state(PAGE_HOME, simpleState('/', Home.HTML, Home.Controller))
    .state(PAGE_PRICING, simpleState('/pricing', Pricing.HTML, Pricing.Controller))
    .state(PAGE_TERMS, simpleState('/terms-of-service', Terms.HTML, Terms.Controller))

    .state(PAGE_PUBLIC_CUSTOM_RFP, simpleState('/custom-rfp', CustomRfp.HTML, CustomRfp.Controller))
    .state(PAGE_PUBLIC_CONTACT_US, simpleState('/contact-us', ContactUs.HTML, ContactUs.Controller))
}

