import { noop } from 'lodash';
import HTML from './public-bid-preview.html';

export { HTML, PublicBidPreviewController as Controller };

PublicBidPreviewController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', 'InvitationService', '$state', '$q'];
function PublicBidPreviewController(notificationUtils, RfpHotelBidRepository, Invitation, $state, $q){
  const vm = this;
  let token;

  vm.onRespondToBid = onRespondToBid;
  vm.onToBidManager = onToBidManager;
  vm.onToChainBidManager = onToChainBidManager;

  $onInit();

  function showInvalidInvitationError() {
    notificationUtils.showError('invalid-invitation');
  }

  function $onInit(){
    token = $state.params.token;
    if(token){
      notificationUtils.onLoad( () => RfpHotelBidRepository.getPublicBidPreview(token), vm, 'bid',
        {
          customErrorHandlers: {
            '403': error => {
              showInvalidInvitationError();
              return $q.reject(error);
            }
          }
        })
        .then(onBidLoaded)
        .catch( noop )
    } else {
      showInvalidInvitationError();
    }

    function onBidLoaded(){
      vm.mainButton = 'CHAIN';
      if(vm.bid.data.type === 'DIRECT'){
        vm.mainButton = ['SENT', 'RECEIVED'].indexOf(vm.bid.data.state.id) === -1 ? 'BID_MANAGER' : 'RESPOND';
      }
      const subType = vm.bid.data.specifications.subType;
      vm.isStandardBid = !subType || subType === 'STANDARD';
      vm.isRateQuickBid = subType === 'RATE_QUICK';

      if($state.params.direct) {
        onRespondToBid();
      }
    }
  }

  function onToBidManager(){
    Invitation.handle(token)
      .then(() => $state.go('bidManager', {viewId: null}))
      .catch( noop );
  }

  function onToChainBidManager(){
    Invitation.handle(token)
      .then(() => $state.go('bidManager', {viewId: null, rfp: vm.bid.data.rfpId}))
      .catch( noop );
  }

  function onRespondToBid(){
    Invitation.handle(token)
      .then(res => {
        if(res && res.redirect) {
          $state.go('publicBidPreview', {token: res.invitation.token, direct: true});
        }else{
          $state.go('hotelRfp.bid.response', {bidId: vm.bid.data.id});
        }
      }, noop)
  }
}
