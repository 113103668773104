export { parseGooglePlace, parseGooglePlaces }

function parseGooglePlaces( places ){
  if(places && places.length){
    return parseGooglePlace(places[0])
  }
}

function parseGooglePlace ( place ) {
  if(place){
    const address = loadAddress(place.address_components, place.formatted_address),
      coordinates = loadCoordinates(place.geometry.location)

    return {
      address: address,
      coordinates: coordinates,
      fullAddress: place.formatted_address
    }
  }
}

function loadAddress(addressComponents, formattedAddress){
  const address = {}

  addressComponents.forEach( parseComponent )

  cleanAddress(address, formattedAddress)
  return address

  // eslint-disable-next-line complexity
  function parseComponent( component ) {
    if( !component.types ) { return }

    switch (component.types[0]) {
      case 'floor':
      case 'street_number':
      case 'route':
        address.address1 = 1
        break
      case 'locality':
        address.city = component.long_name
        break
      case 'administrative_area_level_1':
        address.state = component.short_name
        address.region = component.long_name
        break
      case 'administrative_area_level_2':
        address.county = component.long_name
        break
      case 'country':
        address.country = component.short_name
        break
      case 'postal_code':
        address.zip = component.long_name
        break
      default:
    }
  }
}

function cleanAddress(address, formattedAddress){

  if(['US', 'CA'].indexOf(address.country) === -1){
    delete address.state
    delete address.county
  } else {
    delete address.region
  }

  if(!address.city && address.region){
    address.city = address.region
  }

  if(address.address1){
    address.address1 = formattedAddress.split(',')[0]
  }
}

function loadCoordinates(location){
  return {
    lat: location.lat(),
    lng: location.lng()
  }
}
