/**
 * Created by DejanK on 1/28/2017
 *
 * Behaves like a fixed table header
 *
 * - follows x-axis scrolling
 * - adds and masks y scroll bar on header when visible on target
 *    - uses FIXED positioning because it needs overflow:hidden to follow x-axis scrolling *
 */

'use strict';

let lodash = require("lodash");

module.exports = rbFixedTableHeaderDirective;

function rbFixedTableHeaderDirective(){
  return {
    restrict: 'A',
    scope: {
      maskBackground: '@',
      rbFixedTableHeader: '@'
    },
    controller: Ctrl,
    controllerAs: '$ctrl',
    bindToController: true
  }
}

Ctrl.$inject = ['$element', '$document', '$interval', '$scope'];
function Ctrl($element, $document, $interval, $scope){
  let vm = this, scrollSourceElement, headerElement, maskElement;
  this.$onInit = ()=>{
    let initInterval;

    initInterval = $interval(()=>{
      scrollSourceElement = $document[0].getElementById(vm.rbFixedTableHeader);
      if(scrollSourceElement){
        headerElement = $element[0];
        $element.css('overflow', 'hidden');
        $element.css('position', 'relative');
        $element.css('z-index', 0);
        prepareMaskScrollBarElement();
        setupVerticalScrollTracking();

        setupHeaderWidthAdjustmentForScrollBar();

        $interval.cancel(initInterval);
      }
    }, 20);

    function prepareMaskScrollBarElement(){
      maskElement = angular.element('<div style="position: fixed; display: none;"></div>');
      $element.append(maskElement);
    }

    function setupVerticalScrollTracking(){
      angular.element(scrollSourceElement).bind("scroll",() =>{
        headerElement.scrollLeft = scrollSourceElement.scrollLeft;
      });
    }

    function setupHeaderWidthAdjustmentForScrollBar(){
      let previousScrollHeight, previousClientHeight,
        scrollBarCheckerIntervalPromise = $interval(
          ()=>{
            if(scrollSourceElement.scrollHeight !== previousScrollHeight || scrollSourceElement.clientHeight != previousClientHeight){
              previousScrollHeight = scrollSourceElement.scrollHeight;
              previousClientHeight = scrollSourceElement.clientHeight;
              isScrollBarVisible() ? showScrollBarAndMask(): hideScrollBarAndMask();
            }
          }, 1000);

      $scope.$on('$destroy', ()=>{$interval.cancel(scrollBarCheckerIntervalPromise);});

      function isScrollBarVisible(){
        return scrollSourceElement.scrollHeight > scrollSourceElement.clientHeight;
      }

      function showScrollBarAndMask(){
        let rect = headerElement.getBoundingClientRect(),
          maskWidth = headerElement.offsetWidth - headerElement.clientWidth,
          headerComputedStyle = getComputedStyle(headerElement);

        $element.css('overflow-y', 'scroll');
        maskElement.css('top',  rect.top + 'px' );
        maskElement.css('left',  rect.right - (maskWidth ? maskWidth : 17) + 'px' );
        maskElement.css('width',  (maskWidth ? maskWidth : 17) + 'px' );
        maskElement.css('height',  rect.height + 'px' );
        maskElement.css('background',  headerComputedStyle.getPropertyValue('background'));
        maskElement.css('border-top',  headerComputedStyle.getPropertyValue('border-top'));
        maskElement.css('border-bottom',  headerComputedStyle.getPropertyValue('border-bottom'));
        maskElement.css('box-sizing',  headerComputedStyle.getPropertyValue('box-sizing'));
        maskElement.css('display', 'block');
      }

      function hideScrollBarAndMask(){
        maskElement.css('display', 'none');
        $element.css('overflow-y', 'hidden');
      }
    }
  };
}
