import angular from 'angular'

import BidManager from './bid-manager/rfp.bid-manager.module'
import Auth from '../auth'
import RfpCore from './core/rfp.core.module'
import RfpManager from './rfp-manager/main/rfp.rfp-manager.module';

import States from './rfp.routes'

export default angular.module('rb.rfp', [
  BidManager.name,
  RfpCore.name,
  Auth,
  RfpManager.name
])
  .config(States)

