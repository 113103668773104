/**
 * Created by DejanK on 4/3/2017.
 */
import { abstractState, simpleState } from '../../../../shared/tools/router-helper'
import * as ViewDestinations from './destinations/view-destinations.ctrl'
import * as ViewFinalAgreement from './final-agreement/view-final-agreement.ctrl'
import * as EditFinalAgreement from './final-agreement/edit-final-agreement.ctrl'
import * as ViewQuestionnaire from './questionnaire/view-questionnaire.ctrl'
import * as EditQuestionnaire from './questionnaire/edit-questionnaire.ctrl'
import * as ViewCoverLetter from './cover-letter/view-cover-letter.ctrl'
import * as ViewNamCoverLetter from './nam-cover-letter/view-cover-letter.ctrl'
import * as EditNamCoverLetter from './nam-cover-letter/edit-cover-letter.ctrl'
import * as EditCoverLetter from './cover-letter/edit-cover-letter.ctrl'
import * as RfpPreview from './preview/rfp-preview.ctrl'

export default States;

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state('hotelRfp.rfp', abstractState('/rfps/{rfpId}'))

    .state('hotelRfp.rfp.preview', simpleState('/preview', RfpPreview.HTML, RfpPreview.Controller, null, isNam(false)))
    .state('hotelRfp.rfp.namPreview', simpleState('/nam-preview', RfpPreview.HTML, RfpPreview.Controller, null, isNam(true)))

    .state('hotelRfp.rfp.coverLetter', abstractState('/cover-letter'))
    .state('hotelRfp.rfp.coverLetter.view', simpleState('/view', ViewCoverLetter.HTML, ViewCoverLetter.Controller))
    .state('hotelRfp.rfp.coverLetter.edit', simpleState('/edit', EditCoverLetter.HTML, EditCoverLetter.Controller))

    .state('hotelRfp.rfp.namCoverLetter', abstractState('/nam-cover-letter'))
    .state('hotelRfp.rfp.namCoverLetter.view', simpleState('/view', ViewNamCoverLetter.HTML, ViewNamCoverLetter.Controller))
    .state('hotelRfp.rfp.namCoverLetter.edit', simpleState('/edit', EditNamCoverLetter.HTML, EditNamCoverLetter.Controller))

    .state('hotelRfp.rfp.questionnaire', abstractState('/questionnaire'))
    .state('hotelRfp.rfp.questionnaire.view', simpleState('/view', ViewQuestionnaire.HTML, ViewQuestionnaire.Controller))
    .state('hotelRfp.rfp.questionnaire.edit', simpleState('/edit', EditQuestionnaire.HTML, EditQuestionnaire.Controller))

    .state('hotelRfp.rfp.destinations', abstractState('/travel-destinations'))
    .state('hotelRfp.rfp.destinations.view', simpleState('/view', ViewDestinations.HTML, ViewDestinations.Controller))
    .state('hotelRfp.rfp.destinations.map', simpleState('/{destinationId}/map?from',
      require('./destinations/map-destinations.html'), require('./destinations/map-destinations.ctrl')))

    .state('hotelRfp.rfp.finalAgreement', abstractState('/final-agreement'))
    .state('hotelRfp.rfp.finalAgreement.view', simpleState('/view', ViewFinalAgreement.HTML, ViewFinalAgreement.Controller))
    .state('hotelRfp.rfp.finalAgreement.edit', simpleState('/edit', EditFinalAgreement.HTML, EditFinalAgreement.Controller))
}

function isNam(v) { return { resolve: { isNam: () => v } } }
