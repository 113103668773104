<template>
    <div>
        <div class="bar no-border" :class="$style.bar" v-responsive>
          <hd-select :dark="true" v-model="groupBy" :items="groupers">
            <i slot="icon" class="material-icons">calendar_view_day</i>
            <span slot="label" v-if="!groupBy">Group By</span>
          </hd-select>
          <hd-select :dark="true" v-if="groupBy" v-model="filterBy" :items="filters">
            <i slot="icon" class="material-icons">filter_list</i>
            <span slot="label">Filter By</span>
          </hd-select>
            <div :class="$style.controls">
                <button
                    @click="editColumns"
                    class="button dark icon-only">
                    <i class="material-icons">settings</i>
                    <div class="rbTooltip simple" tooltip="placement:end-middle">Organise Table Columns</div>
                </button>
            </div>
        </div>
        <div class="manage-table" v-responsive-table :class="{[$style.table]: true, [$style.fullTable]: hotels && hotels.length > 0}">
            <div class="row tableHead">
                <div class="cell head checkboxHead"></div>
                <template  v-for="column in columns">
                    <div :title="rate.text" v-if="column === 'rates'" :key="column" :style="getColumn(column).style" :class="$style.rateColumn" class="cell head">
                        <hd-select class="dark-background" :dark="true" :class="$style.rateDropdown" v-model="shownRate" :items="rates">
                            <i class="material-icons">info</i>
                            <div class="rbTooltip simple" tooltip="placement:bottom-start">Use this dropdown to change the rate type showing in the table</div>
                            {{rate.name}}
                        </hd-select>
                    </div>
                    <div :title="propertyCode.text" v-else-if="column === 'code'" :key="column" :style="getColumn(column).style" :class="$style.rateColumn" class="cell head">
                      <hd-select class="dark-background" :dark="true" :class="$style.rateDropdown" v-model="propertyCodeShown" :items="propertyCodes">
                        <i class="material-icons">info</i>
                        <div class="rbTooltip simple" tooltip="placement:bottom-start">Use this dropdown to change the property code type showing in the table</div>
                        {{propertyCode.name}}
                      </hd-select>
                    </div>
                    <div v-else :key="column" :style="getColumn(column).style" class="cell head">
                        {{getColumn(column).text}}
                    </div>
                </template>
            </div>
            <div v-if="!loading" v-for="group in sortedAndGrouped" class="group">
                <div :key="group.key" class="grouper" v-if="group.key && group.key !== 'none'">
                    <span @click="toggleGroup(group.key)" class="material-icons check-box">
                      {{ allAddedInGroup(group.key)?'check_box': someAddedInGroup(group.key)? 'indeterminate_check_box': 'check_box_outline_blank' }}
                    </span>
                    <rb-rating v-if="groupBy === 'rating'" :rate="group.key"/>
                    <span v-else-if="groupBy === 'distance'">{{group.key | distance}} Mi</span>
                    <span v-else-if="groupBy === 'validity'">{{group.key? "Valid": 'With Errors'}}</span>
                    <span v-else-if="groupBy === 'added'">{{group.key? "On Directory": 'Not on Directory'}}</span>
                    <span v-else>{{group.key}}</span>
                </div>
                <table-row
                    :key="item[id]"
                    v-for="item in group.items"
                    :row="item"
                    :class="$style.table"
                    :columns="columns"
                    @add="add"
                    @remove="remove"
                    @openFinalAgreement="openFinalAgreement"
                    :rate="shownRate"
                    :visible-columns="visibleColumns"
                    :open="openedRow === item[id]"
                    @open="openRow"
                    :property-code="propertyCodeShown"
                    :updating="updatingRows.indexOf(item[id]) >= 0"
                    :visible-column-styles="visibleColumnStyles"
                />
            </div>
        </div>
        <div :class="$style.empty" v-if="empty">
          <div v-if="loading" class="layout-column flex-center" :class="$style.loader">
            <rbv-loading label="Loading, please wait..."/>
          </div>
          <template v-else>
            <div :class="$style.emptyTitle">Nothing to see here (:</div>
            <div :class="$style.emptySubTitle">Your Hotel Directory shows only those hotels to which you have sent Final Agreements on behalf of your company.</div>
            <div>
              <button
                @click="bidManager()"
                class="button dark">
                <i class="material-icons">list_alt</i>
                Bid Manager
              </button>
            </div>
          </template>
        </div>
    </div>
</template>

<script>
import hotelDirectoryService from '../../hotel-directory-service';
import { Notification } from "root/v-app/rbServices";
import ValidityErrorNotification from 'vRoot/hotel-directory/notifications/ValidityErrorNotification.vue';
import HotelsToggledNotification from 'vRoot/hotel-directory/notifications/HotelsToggledNotification.vue';

import HotelsMixin from 'vRoot/hotel-directory/_mixins/hotels.vue';
import ResponsiveBarMixin from 'vRoot/hotel-directory/_mixins/responsive-bar.vue';

import TableRow from './table-row.vue';

export default {
    name: 'HotelDirectoryManageHotels',
    mixins: [HotelsMixin, ResponsiveBarMixin],
    components: {TableRow},
    data(){
        return {
            id: 'bidId',
            amenities: [
                'WIFI',
                'Fitness',
                'Internet Access',
                'Early Checkout',
                'Airport Shuttle',
                'Break Fast',
                'Parking',
            ],
            updatingRows: []
        }
    },
    mounted() {
      return hotelDirectoryService.getCurrentUser().then(user => {
        this.$nextTick(() => this.showTutorial(user));
        this.$nextTick(() => hotelDirectoryService.showOptInDialog());
        return user;
      })
    },
    asyncComputed: {
        hotels(){
            return hotelDirectoryService.getAllFinalAgreementHotels().then(hotels => {
                if(hotels.find(hotel => !hotel.valid)) {
                  Notification.show(ValidityErrorNotification, {
                    title: "Important Information",
                    subTitle: "Some final agreement hotels can not be added to the directory because they contain seasons or rates that are invalid.",
                    buttonText: "Show These Hotels"
                  }).then(this.selectErroredHotels);
                }
                return hotels;
            });
        },
    },
    methods: {
        remove(row) {
            const hotel = this.validFilteredHotels.find(hotel => hotel[this.id] === row[this.id]);
            this.bulkRemove([hotel[this.id]]);
        },
        add(row) {
          const hotel = this.validFilteredHotels.find(hotel => hotel[this.id] === row[this.id]);
          this.bulkAdd([hotel[this.id]]);
        },
        bulkAdd(ids) {
          if(ids.length > 0) {
            hotelDirectoryService.addHotels(ids).then(() => this.updateHotels(ids, "add"));
            this.toggleUpdating(ids);
          }
        },
        bulkRemove(ids) {
          if(ids.length > 0) {
            hotelDirectoryService.removeHotels(ids).then(() => this.updateHotels(ids, "remove"));
            this.toggleUpdating(ids);
          }
        },
        openFinalAgreement(bidId) {
          hotelDirectoryService.openFinalAgreement(bidId);
        },
        toggleGroup(key) {
          const group = this.group(key);
          if(this.allAddedInGroup(key)) {
            const ids = group.items.filter(item => item.valid).map(hotel => hotel[this.id]);
            this.bulkRemove(ids);
          }else{
            const ids = group.items.filter(item => item.valid && !item.added).map(hotel => hotel[this.id]);
            this.bulkAdd(ids);
          }
        },
        updateHotels(ids, action) {
          if(ids.length > 0){
            hotelDirectoryService.getAllFinalAgreementHotels().then(hotels => {
              this.hotels = hotels;
              this.toggleUpdating(ids);
              this.showNotifications(ids, action);
            })
          }
        },
        showNotifications(ids, action) {
          const hotels = this.validFilteredHotels.filter(hotel => ids.indexOf(hotel[this.id]) > -1);
          Notification.showSnackBar(HotelsToggledNotification, {hotels, action}).then(data => {
            if(data.action === 'undo' && action === 'add') {
              this.bulkRemove(ids);
            }else if(data.action === 'undo' && action === 'remove') {
              this.bulkAdd(ids);
            }
          });
        },
        bidManager(){
          hotelDirectoryService.openBidManager();
        },
        toggleUpdating(ids) {
          ids.forEach(id => {
            this.updatingRows.indexOf(id) >=0? this.updatingRows.splice(this.updatingRows.indexOf(id), 1): this.updatingRows.push(id)
          });
        },
        selectErroredHotels(){
          this.groupBy = "validity";
          this.$nextTick(() => {
            this.filterBy = this.filters.find(filter => !filter.value).id;
          });
        },
        showTutorial(user){
          if(!hotelDirectoryService.stillExploring() && !hotelDirectoryService.userHasSeenManageHotelTutorial(user)){
            this.showTutorialDialog(user , ({dontShow}) => hotelDirectoryService.addManageHotelTutorials(dontShow));
          }
        }
    }
}
</script>

