/**
 * Created by DejanK on 10/04/2017.
 */
import * as EditRateLoadingInformation from './rate-loading-information/rate-loading-information.ctrl'

export default Dialogs

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .add(EditRateLoadingInformation.NAME, EditRateLoadingInformation.HTML, EditRateLoadingInformation.Controller)
}
