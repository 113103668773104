/**
 * Created by DejanK on 8/11/2016.
 */
import './sign-in.scss'
import HTML from './sign-in.html'
import {BAD_CREDENTIALS} from "../../shared/sign-in.service";

export { HTML , SignInController as Controller }

SignInController.$inject = ['SignInService', 'AuthSession'];
function SignInController(SignInService, authSession) {
  const vm = this;
  vm.submit = submit;

  function submit() {
    SignInService.signIn(vm.credentials)
      .then(authSession.continue, (error) => { vm.badCredentials = error === BAD_CREDENTIALS });
  }
}
