/**
 * Created by DejanK on 11/4/2016.
 */

export default {
  template: templateFn,
  bindings: { model: '=' }
}

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, rbInputTemplate){
  return rbInputTemplate.textLengthTemplate(rbInputTemplate.readDefaultAttributes($attrs, {
    maxlength: 20,
    pattern: /^(\+|00)?[(]?[0-9]{0,3}[)]?[-\s.]?[[0-9]{0,3}]?[-\s./]?[[0-9]{0,3}]?[-\s.]?[0-9]{3,7}$/
  }))
}
