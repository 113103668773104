/**
 * Created by DejanK on 11/4/2016.
 */
import angular from 'angular';

export default {
  template: templateFn,
  bindings: {
    model: '='
  }
};

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, RbInputTemplate){
  const attrs = angular.copy($attrs);
  attrs.placeholder = attrs.placeholder ? attrs.placeholder : 'http://';
  const options = angular.extend(RbInputTemplate.readDefaultAttributes(attrs));
  return RbInputTemplate.urlTemplate(options);
}
