/**
 * Created by DejanK on 4/5/2017.
 */

'use strict';

module.exports = BidQuestionnaireViewController;

BidQuestionnaireViewController.$inject = ['ControllerUtils', 'RfpHotelBidRepository'];
function BidQuestionnaireViewController(utils, RfpHotelBidRepository){
  let vm = this;

  $onInit();

  function $onInit(){
    vm.bidId = utils.getParam('bidId');
    utils.loadTo(vm, 'bid', ()=>{
      return RfpHotelBidRepository.getBidPreview(vm.bidId);
    });
  }
}
