<template>
  <div class="rb-bid-menu rb-rfp-menu">
    <ul>
      <li
        v-for="primary in actions"
        :key="primary.id"
        class="primary"
      >
        <button @click="executeAction(primary)">
          <i
            class="material-icons"
          >
            {{ primary.icon }}
          </i>
          <span v-html="primary.label"></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
      name: 'Menu',
      props: {
        actions: {
          type: Array,
          required: true
        },
        rfps: {
          type: Array,
          required: true
        },
        callback: {
          type: Function,
          required: true
        },
        accounts: {
          type: Array,
          required: true
        }
      },
      methods: {
        executeAction(primary){
          return primary.action &&
            primary.action(this.rfps, this.accounts)
              .then( data => this.callback({id: primary.id, data}) );
        }
      }
    }
</script>

<style lang="stylus">
  .rb-rfp-menu{
    border-radius: 3px;
    overflow: hidden;
  }
</style>
