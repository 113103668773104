<template>
  <rb-modal @cancel="cancel()" @hide="submit()" :options="modalOptions" :class="$style.dialog">
    <span slot="header">Decline to Bid</span>
    <div slot="body">
      You have chosen to Decline to bid. Are you sure you want to continue?
    </div>
  </rb-modal>
</template>

<script>
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import RbLoading from 'vRoot/_core/RbvLoading.vue'
  import {notificationUtils, mainAPI} from "root/angular-injector-provider";

  export default {
    name: 'rbv-hotel-rfp-bid-manager-action-send-not-interested',
    mixins: [ DialogMixin, RbEventsMixin ],
    components: { RbModal, RbLoading },
    props: { 'bidsIds': {
        required: true,
        type: Array
      } },
    data () {
      return {
        modalOptions: {labels: {buttons: {apply: 'Yes', cancel: 'No'}}}
      }
    },
    methods: {
      submit () {
        notificationUtils().onSave( () => mainAPI().sendNotInterested(this.bidsIds) )
          .then( report => this.hide(report), err => this.cancel(err) )
      }
    }
  }
</script>
<style lang="stylus" module="">
  .dialog {
    max-width: 400px;
    font-size: 12px;
    color: #263238;
  }
</style>
