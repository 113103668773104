import {checkAndExecute} from "root/api/helpers";

export default function HotelRfpBidFinalAgreementAPI(api) {
  Object.assign(api, {

    sendFinalAgreement: bidId => checkAndExecute (
      () => bidId,
      () => api.server.post(`rfps/hotel/bids/${bidId}/final-agreement`)),

    viewHotelRfpBidFinalAgreement: bidId => checkAndExecute(
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/final-agreement`)
    ),

    exportFinalAgreementAsPdf: bidId => checkAndExecute (
      () => bidId,
      () => api.server.getFile(`rfps/hotel/bids/${bidId}/final-agreement/pdf`)),

    exportFinalAgreementAsExcel: bidId => checkAndExecute (
      () => bidId,
      () => api.server.getAndAutoSaveFile(`rfps/hotel/bids/${bidId}/final-agreement/xlsx`)),

    getBidFinalAgreementTemplate: bidId => checkAndExecute (
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/final-agreement/template`)),

    updateBidFinalAgreementTemplate: (bidId, template) => checkAndExecute (
      () => bidId,
      () => api.server.put(`rfps/hotel/bids/${bidId}/final-agreement/template`, {template: template}))
  })
}
