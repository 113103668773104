import { get, isUndefined, forEach } from 'lodash'
import Value from './value.factory'
import { CurrencyFormatter, PercentageFormatter, DateTimeFormatter, DateFormatter } from '../../../../../internationalization/internationalization.service'

export default BidManagerTableFactory

function BidManagerTableFactory() {
  return new BidManagerTable()
}

BidManagerTableFactory.cacheWrap = function cacheWrap (cachedFn) {
  return function (bid, cell, view) {
    if(!bid.$$bmCachedValues) { bid.$$bmCachedValues = {} }
    let cachedValue = bid.$$bmCachedValues[cell.id];
    if(isUndefined(cachedValue)) {
      cachedValue = cachedFn(bid, cell, view);
      bid.$$bmCachedValues[cell.id] = cachedValue
    }
    return cachedValue;
  }
};

function BidManagerTable() {
  this.dateTimeFormatter = DateTimeFormatter();
  this.dateFormatter = DateFormatter();
  this.currencyFormatterFactory = CurrencyFormatter;
  this.percentageFormatter = PercentageFormatter();
}

BidManagerTable.prototype.printValue = function(bid, cell){
  const fields = cell.fields,
    currencyFormat = this.currencyFormatterFactory();
  // currencyFormat = this.currencyFormatterFactory(get(bid, fields.currency, 'USD')),
  return Value(get(bid, fields.obj)).print(this.percentageFormatter, currencyFormat)
};

BidManagerTable.prototype.printDiff = function(bid, cell, EMPTY_VALUE) {
  const value = get(bid, cell.fields.text);
  if(isEmptyString(value)) { return EMPTY_VALUE }
  if(value === 0) { return value }
  return value > 0 ? `+${value}%` : `-${value*-1}%`;

  function isEmptyString (str) { return !str || str === '' }
};

BidManagerTable.prototype.getParams = function(bid, cell) {
  const params = {};
  forEach(cell.params, (v, k) => {
    params[k] = get(bid, cell.fields[v]);
  });
  return params
};
