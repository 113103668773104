/**
 * Created by DejanK on 7/20/2017.
 */
'use strict';

let lodash = require('lodash');

module.exports = {
  template: require('./rb-negotiations-input-tcos-tooltip.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    seasonId: '@season',
    roomTypeId: '@roomType'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsHtmlFactory'];
function Ctrl($element, NegotiationsHtmlFactory) {
  let vm = this, tooltipster,
    htmlContentElement, parentElement, tableElement;

  vm.$onInit = onInit;
  vm.$onChanges = updateVM;
  vm.$onDestroy = onDestroy;

  function onInit(){
    parentElement = $element.parent();
    htmlContentElement = $($element.children()[0]);
    tableElement = $(htmlContentElement.find('div.table'));

    parentElement.tooltipster({
      animationDuration: [300, 100],
      arrow: false,
      theme: ['', 'negotiations-input-tooltip'],
      content: htmlContentElement,
      contentAsHtml: true,
      interactive: true,
      side: ['top'],
      zIndex: 999998,
      functionPosition: repositionTooltip,
      functionBefore: printHistory,
      functionReady: scrollToActive
    });

    tooltipster = parentElement.tooltipster('instance');
  }

  function onDestroy(){
    tooltipster.destroy();
  }

  function updateVM(){
    if(!checkRequirements()) return;
    vm.seasonPeriod = vm.negotiations.seasons.getFormattedPeriod(vm.seasonId);
    vm.roomType = vm.negotiations.roomTypes.print(vm.roomTypeId);
  }

  function repositionTooltip(instance, helper, coords){
    var origin = helper.geo.origin;
    coords.coord.top += origin.size.height - 27;
    coords.coord.left = origin.windowOffset.left - 186;
    return coords;
  }

  function printHistory(){
    if(!checkRequirements()) return;

    let historyHtml = NegotiationsHtmlFactory.printTcosHistory(vm.negotiations.communication, `tcos_s${vm.seasonId}_rt${vm.roomTypeId}`, vm.negotiation);
    tableElement.empty();
    tableElement.append(historyHtml);
  }

  function checkRequirements(){
    return vm.negotiations && vm.seasonId && vm.roomTypeId;
  }

  function scrollToActive(){
    let activeElements = tableElement.find('div.layout-row.active');
    if(activeElements.length) {
      scrollTo(tableElement, $(activeElements[0]));
    } else {
      tableElement[0].scrollTop = tableElement[0].scrollHeight;
    }

    function scrollTo(container, scrollTo){
      container.scrollTop(
        scrollTo.offset().top - container.offset().top + container.scrollTop() - (container.height()/2)
      );
    }
  }
}
