import { fileToJson } from 'root/shared/tools/xls.service'
import {FIELD_ID, default as makeUserUpload } from './upload-user.factory';
export default uploadUsers;

function uploadUsers(file, progressCallback){
    const users = [], fileLoadedProgressValue = 10;
    let actionsToBePerformed = 0, actionsPerformed = 0;

    return fileToJson(file, { sheetName: 'Users' })
    .then(markFileProgress)
    .then(parseUsers)


    function parseUsers(fileUsers){
        const rawUsers = fileUsers.filter( user => userNotEmpty(user)),
        chain = buildParseChain()
        actionsToBePerformed = rawUsers.length * 2
        actionsPerformed = 0
        return chain.then(() => users)

        function buildParseChain() {
            let chain = Promise.resolve()
            rawUsers.forEach(rawUser => {
                chain = chain
                .then(() => createUser(rawUser))
                .then(validateUser)
                .then(saveUsers)
            })
            return chain
        }

        function userNotEmpty(user){
            return Object.keys(user).some( field => field !== FIELD_ID && user[ field ].length > 0)
        }
    }

    function markFileProgress(data){
        progressCallback(fileLoadedProgressValue);
        return Promise.resolve(data);
    }

    function makeUserUploadProgress(data){
        progressCallback( fileLoadedProgressValue + ( ++actionsPerformed / actionsToBePerformed * (100 - fileLoadedProgressValue) ))
        return Promise.resolve(data)
      }
    
      function createUser(rawUser){
        return Promise.resolve(makeUserUpload(rawUser))
          .then(makeUserUploadProgress)
      }
    
      function validateUser(user){
        user.validate()
        return Promise.resolve(user)
          .then(makeUserUploadProgress)
      }

      function saveUsers(td){
        users.push(td)
        return Promise.resolve()
      }
}