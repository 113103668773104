/**
 * Created by DejanK on 12/27/2016.
 */
import angular from 'angular';

import footers from './footers';
import form from './form';
import headers from './headers';
import tooltips from './rb-tooltip/rb-tooltip.module';
import rbHorizontalSplitPaneNew from './rb-horizontal-split-pane-new/rb-horizontal-split-pane.cmp';

export default angular.module('rb.core.ui', [
  footers,
  form,
  headers,
  tooltips.name,

  require('./rb-float/rb-float.module').name,
  require('./rb-fixed-table-header/rb-fixed-table-header.module').name,
  require('./rb-fixed-on-scroll/rb-fixed-on-scroll.module').name,
  require('./rb-virtual-repeat/rb-virtual-repeat.module').name
])

  .component('rbRating', require('./rb-rating/rb-rating.cmp'))
  .directive('rbClassOnScroll', require('./rb-class-on-scroll/rb-class-on-scroll.dir'))

  .component('rbHorizontalSplitPaneNew', rbHorizontalSplitPaneNew)

  .filter('rbDate', require('./rb-date-filter/rb-date.filter'))
