/**
 * Created by DejanK on 13/02/2018.
 */
import { noop } from 'lodash'
import HTML from './ask-for-password.html'
import {BAD_CREDENTIALS} from "root/public-auth/shared/sign-in.service";

const NAME = "askForPassword"

export { NAME, HTML, AskForPasswordDialogController as Controller}

AskForPasswordDialogController.$inject = ['rbDialog'];
function AskForPasswordDialogController(dialog){

  const vm = this

  vm.submit = submit
  vm.cancel = dialog.cancel

  function submit() {
    vm.onSubmit(vm.credentials)
      .then( dialog.hide, (error) => { vm.badCredentials = error === BAD_CREDENTIALS } )
      .catch( noop )
  }
}
