/**
 * Created by DejanK on 10/8/2016.
 */

'use strict';

require("./rb-select.scss");

let template =`<a href="#" ng-click="$ctrl.onClick($event)" ng-transclude></a>`;

module.exports = {
  template: template,
  transclude: true,
  require: {
    rbSelect: "?^rbSelect"
  },
  bindings: {
    value: '<',
    index: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', '$scope'];
function Ctrl($element, $scope){
  var vm = this, registered;

  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;
  vm.onClick = onClick;

  vm.select = select;
  vm.deselect = deselect;

  vm.scrollIntoView = ()=>{
    $element[0].scrollIntoView();
  };

  vm.setFocus = ()=>{
    vm.scrollIntoView();
    $element.addClass('focused');
  };

  vm.removeFocus = ()=>{
    $element.removeClass('focused');
  };

  vm.getText = () => {
    return $element.text().trim();
  };

  function onInit(){
    if(vm.rbSelect){
      vm.rbSelect.registerOption(this);
      registered = 1;
    }

  }

  function onDestroy(){
    registered  && vm.rbSelect.deregisterOption(this);
  }

  function onClick($event){
    $event.stopPropagation();
    $event.preventDefault();
    vm.rbSelect.onSelect(vm.value);
  }

  function select(){
    $element.addClass('selected');
    return $element.find('a').html();
  }

  function deselect(){
    $element.removeClass('selected');
  }
}
