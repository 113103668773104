<template>
  <rbv-dropdown
    :is-open="open"
    class="rb-destination-picker"
    @close="toggle('destinations')"
    backdropStyle="dropdown-background">
    <div
      class="rbInputContainer toggler with-icon"
      slot="toggler"
      :class="{rbInputEmpty: !value && !open, dark}"
      @click="toggle('destinations')"
    >
      <span class="rbInputLabel"><span>Select Travel Destination</span></span>
      <i class="material-icons icon">location_on</i>
      <div class="rbSelect rbInput control">
        <div v-if="!value">Travel Destination - </div>
        <div class="name">{{destination? formattedValue: 'None'}}</div>
        <i
          class="material-icons action"
          @click.stop="clear"
        >{{!value? 'keyboard_arrow_down': 'highlight_off'}}</i>
      </div>
    </div>
    <ul slot="dropdown" :class="{dark}" class="dropdown">
      <li
        class="item"
        :class="{active: value === c.id}"
        v-for="c in travelDestinations"
        :key="c.id"
        @click="emit(c.id)"
      >
        <div>
          <div class="text-1">{{c.name}}</div>
          <div class="text-2">{{c.location.fullAddress}}</div>
        </div>
      </li>
    </ul>
  </rbv-dropdown>
</template>

<script>
  import RbvDropdown from 'vRoot/_core/RbDropdown.vue';

  export default {
    name: 'RbDestinationsDropdown',
    components: { RbvDropdown },
    props: ['travel-destinations', 'value', 'dark'],
    data() {
      return {
        open: false,
      };
    },
    computed: {
      destination() {
        return this.value && this.travelDestinations.find(td => td.id === this.value);
      },
      formattedValue() {
        return `${this.destination.name} - ${this.destination.location.fullAddress}`;
      },
    },
    methods: {
      toggle() {
        this.open = !this.open;
      },
      emit(value) {
        this.$emit('input', value);
        value && this.toggle();
      },
      clear() {
        this.value ? this.emit(null) : this.toggle();
      },
    },
  };
</script>

<style lang="stylus">
  .rb-destination-picker{
    height: 55px;
    flex: 1;
    min-width: 0;

    .rbInputEmpty.toggler .rbInputLabel{
      display none;
    }

    .toggler .rbSelect{
      line-height 2.5;
      cursor pointer;
      display flex;
      background: #fff;
      border 1px solid #40b8a9;

      div{
        min-width 0;
        overflow hidden;
        text-overflow ellipsis;
        white-space nowrap;
      }

      .name{
        flex auto;
        font-weight bolder;
      }
    }

    .dropdown{
      min-width 250px;
      max-width: -webkit-fill-available;
    }

    .dropdown-background{
      background-color transparent;
    }

    .action{
      margin 7px 0 0 10px;
    }
  }
</style>
