/**
 * Created by DejanK on 11/21/2016.
 */
import { find, get } from 'lodash'

export default AccountService

AccountService.$inject = ['AccountRepository', 'CurrentUser'];
function AccountService(AccountRepository, currentUser){
  const service = this;

  service.getAccountTypes = AccountRepository.getAccountTypes;
  service.getAccountType = getAccountType;
  service.searchEntities = AccountRepository.searchEntities;
  service.addAccount = addAccount;
  service.createAccount = createAccount;

  function getAccountType(type){
    return service.getAccountTypes()
      .then((types) => {return find(types, {id: type})})
  }

  function addAccount(accountData){
    return AccountRepository.addAccount(accountData)
      .then((httpResponse)=>{
        return switchAccount(get(httpResponse, 'data.accountId'))
          .finally(()=>{return httpResponse})
      })
  }

  function createAccount(createAccountData){
    return AccountRepository.createAccount(createAccountData)
      .then((httpResponse)=>{
        return switchAccount(get(httpResponse, 'data.accountId'))
          .finally(()=>{return httpResponse})
      })
  }

  function switchAccount(accountId){
    return currentUser.switchToAccount(accountId);
  }
}
