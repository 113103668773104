/**
 * Created by DejanK on 10/3/2016.
 *
 * rbInputEmailAddress
 */

'use strict';

module.exports = {
  template: templateFn,
  bindings: {
    model: '='
  }
};

templateFn.$inject = ['$element', '$attrs'];
function templateFn($element, $attrs) {
  let name = $attrs.name || 'emailAddress',
    label = $attrs.label || 'Email address',
    placeholder = $attrs.placeholder || label,
    required = $attrs.required === 'required' ? 'required="required"' : '',
    readonly = $attrs.readonly === 'readonly' ? 'readonly="readonly"' : '';

  return `
<div class="rb-input" ng-form="${name}" ng-class="{'focused':focused, 'touched': ${name}.${name}.$touched}">
  <label>${label}</label>
  <input type="email" name="emailAddress" placeholder="${placeholder}" ng-model="$ctrl.model" ${required} ${readonly}
  ng-focus="focused=1" ng-blur="focused=0">
  <div class="errors-container">
    <div ng-messages="${name}.emailAddress.$error" class="errors">
      <div ng-message="required">${label} is required!</div>
      <div ng-message="email">${label} is not valid!</div>
    </div>
  </div>
</div>`
}
