<template>
  <div class="rbvSelect" :class="{ 'focused': focused, 'opened': opened }">
    <div class="control" @mousedown.stop.prevent="onInputClicked" :class="{ 'focused': focused, 'opened': opened }">
      <div class="valueContainer">
        <div v-if="!selected && !hasQuery" class="selectPlaceholder">{{placeholder}}</div>
        <div class="selectValue"> {{ hasQuery ? '' : viewValue  }} </div>
        <input autocomplete="off" role="combobox"
               type="text" class="selectInput"
               v-model="query"
               @focus="onInputFocused"
               @blur="onInputBlurred"
               @keydown.stop="onInputKeyDown"/>
      </div>

      <div v-if="selected" class="clearContainer">
        <div class="clear material-icons" @mousedown.stop.prevent="onClearClicked">clear</div>
      </div>

      <div class="arrowContainer">
        <div class="arrow material-icons">{{arrow}}</div>
      </div>
    </div>

    <div v-if="opened" class="overlayContainer">
      <div class="overlay"></div>
    </div>

    <transition name="rbvSelectFade">
      <div v-if="opened" class="optionsContainer">
        <div class="optionsScrollContainer">
          <div v-if="!filteredOptions || !filteredOptions.length" class="noOptions">No Items Found</div>
          <rbv-option v-for="option in filteredOptions" :key="option.id"
                      class="option"
                      :option="option"
                      :selected="selected"
                      :marked="marked"
                      :highlight="query"
                      :scroll="scrollOptions"
                      :labelKey="labelKey"
                      @click="onOptionClicked(option)"
                      @hover="onOptionHovered(option)"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import RbvOption from './RbSelectOptionNew.vue'

  const
    KEY_CODE_BACKSPACE = 8,
    KEY_CODE_ENTER = 13,
    KEY_CODE_ESCAPE = 27,
    KEY_CODE_PAGE_UP = 33,
    KEY_CODE_PAGE_DOWN = 34,
    KEY_CODE_END = 35,
    KEY_CODE_HOME = 36,
    KEY_CODE_ARROW_UP = 38,
    KEY_CODE_ARROW_DOWN = 40,
    KEY_CODE_DELETE = 46

  export default {
    name: 'rb-select',
    components: {RbvOption},

    $_veeValidate: {
      value() {
        return this.modelValue
      }
    },

    props: {
      pageSize: {
        "default": 5,
        type: Number
      },
      value: {},
      options: {
        required: true,
        "default": []
      },
      placeholder: {"default": ''},
      labelKey: {
        type: String,
        "default": 'label'
      }
    },

    data() {
      return {
        focused: false,
        modelValue: undefined,
        viewValue: undefined,
        selected: undefined,
        marked: undefined,
        opened: false,
        query: '',
        scrollOptions: true,
        inputElement: null
      }
    },

    computed: {
      filteredOptions() {
        let options = []
        if (this.options && this.options.length) {
          options = this.hasQuery ? this.options.filter(o => o[this.labelKey].match(new RegExp('(' + this.query + ')', 'i'))) : this.options
        }
        this.resetMarked(options)
        return options
      },
      hasQuery() {
        return this.query && this.query.length
      },
      arrow() {
        return this.opened ? 'arrow_drop_up' : 'arrow_drop_down'
      }
    },

    watch: {

      value: {
        immediate: true,
        handler(v) {
          this.modelValue = v
          this.modelFormat()
          this.modelRender()
        },
      },

      options: {
        immediate: true,
        handler() {
          this.modelFormat()
          this.modelRender()
        }
      }
    },

    methods: {
      // model methods
      modelFormat() {
        this.selected = this.filteredOptions ? this.filteredOptions.find(o => o.id === this.modelValue) : undefined
      },
      modelRender() {
        this.viewValue = this.selected ? this.selected[this.labelKey] : undefined
      },
      modelParse() {
        this.modelValue = this.selected ? this.selected.id : undefined
      },
      modelEmit() {
        this.$emit('input', this.modelValue);
        this.$emit('change', this.modelValue);
      },

      // event methods
      onInputBlurred(){
        this.closeDropdown()
        this.emptyQuery()
        this.emptyMarked()
        this.$emit('blur', this.modelValue);
        this.focused = false
      },
      onInputClicked() {
        this.inputElement.focus()
        this.opened ? this.closeDropdown() : this.openDropdown()
      },
      onInputFocused() {
        this.resetMarked(this.filteredOptions)
        this.focused = true
      },

      // eslint-disable-next-line complexity
      onInputKeyDown($event){
        if(! ($event.ctrlKey || ($event.altKey && $event.keyCode !== KEY_CODE_ARROW_DOWN)) ) {
          switch ($event.keyCode) {

            case KEY_CODE_ENTER:
              $event.preventDefault()
              if (this.marked) {
                this.selectOption(this.marked)
                this.closeDropdown()
                this.emptyQuery()
              }
              break;

            case KEY_CODE_ESCAPE:
              $event.preventDefault()
              this.emptyQuery()
              this.closeDropdown()
              break;

            case KEY_CODE_BACKSPACE:
            case KEY_CODE_DELETE:
              if (!this.hasQuery) {
                $event.preventDefault()
                this.selectOption(undefined)
              }
              break;

            case KEY_CODE_ARROW_DOWN:
              if(this.opened){
                this.setMarked(this.filteredOptions.indexOf(this.marked)+1, true)
              } else {
                this.openDropdown()
              }
              break;

            case KEY_CODE_ARROW_UP:
              this.setMarked(this.filteredOptions.indexOf(this.marked)-1, true)
              break;

            case KEY_CODE_PAGE_DOWN:
              this.setMarked(this.filteredOptions.indexOf(this.marked)+this.pageSize)
              break;

            case KEY_CODE_PAGE_UP:
              this.setMarked(this.filteredOptions.indexOf(this.marked)-this.pageSize)
              break;

            case KEY_CODE_END:
              this.setMarked(this.filteredOptions.length-1)
              break;

            case KEY_CODE_HOME:
              this.setMarked(0)
              break;

            default:
              this.openDropdown()
          }
        }
      },

      onClearClicked() {
        this.selectOption(undefined)
        this.emptyQuery()
        this.$nextTick(() => {this.inputElement.focus()})
      },
      onOptionClicked(option) {
        this.selectOption(option)
        this.$nextTick(() => {this.inputElement.focus()})
      },
      onOptionHovered(option) {
        if(this.marked !== option){
          this.scrollOptions = false
          this.marked = option
        }
      },

      // methods for dropdown
      openDropdown(){
        this.opened = true
      },
      closeDropdown(){
        this.opened = false
      },

      // methods for marked
      resetMarked(options) {
        this.marked = undefined // needed to force change
        options.length && this.$nextTick(() => {
          const indexOfSelected = options.indexOf(this.selected)
          this.setMarked(indexOfSelected === -1 ? 0 : indexOfSelected)
        })
      },

      emptyMarked(){
        this.marked = undefined
      },

      setMarked(index, rotate){
        this.scrollOptions = true
        let safeIndex = null
        if(this.filteredOptions.length){
          safeIndex = index < 0 ? ( rotate ? this.filteredOptions.length-1 : 0) : index
          safeIndex = safeIndex >= this.filteredOptions.length ? ( rotate ? 0 : this.filteredOptions.length-1 ) : safeIndex
          this.marked = this.filteredOptions[safeIndex]
        } else {
          this.emptyMarked()
        }
      },

      selectOption(option){
        if(this.selected !== option){
          this.selected = option
          this.modelParse()
          this.modelEmit()
        }
      },

      emptyQuery(){
        this.query = ''
      }
    },
    mounted () {
      this.inputElement = this.$el.querySelector('input')
    }
  }
</script>

<style lang="stylus">

  .rbvSelect {
    position: relative;
    display: block;

    .control {
      display: flex;
      align-items: center;

      position: relative;
      z-index: 1001;

      box-sizing border-box
      width: 100%;
      height: 36px;
      border: 1px solid #DCDEE0;
      overflow: hidden;

      font-size: 13px;
      color: #455A64;
      cursor: text;
      outline: none;
      background : linear-gradient(0deg, rgba(245, 246, 247, 1) 10%, rgba(255, 255, 255, 1) 11.05%, rgba(255, 255, 255, 1) 100%);

      &:hover {
        box-shadow: 0 1px 0 rgba(0,0,0,0.06);
      }

      &.focused {
        border-color: #00b8ab;
      }
    }

    .valueContainer {
      box-sizing: border-box;
      overflow: hidden;
      margin-left: 10px;
      flex: 1;
      height: 24px;
      line-height: 24px;
      vertical-align: middle;
      position relative
    }

    .selectPlaceholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      color: #aaa;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1
    }

    .selectValue {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2
    }

    .selectInput {
      font-family: 'Noto Sans', sans-serif
      box-sizing: border-box;
      background: none transparent;
      border: 0 none;
      box-shadow: none;
      outline: none;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 3
      vertical-align middle
    }

    .clearContainer {
      cursor: pointer;
      text-align: center;
      user-select: none;
      padding-right: 2px;
      width: 25px;
      height: 24px;
      vertical-align: middle;
      position: relative;
      z-index: 1001;
    }

    .arrowContainer {
      cursor: pointer;
      text-align: center;
      user-select: none;
      padding-right: 5px;
      width: 25px;
      height: 24px;
      vertical-align: middle;
      position: relative;
      z-index: 1001;
    }

    .overlayContainer {
      pointer-events: none;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1000;
    }

    .overlay {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
      position: absolute;
      pointer-events: auto;
      z-index: 1000;
    }

    .optionsContainer {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      z-index: 1050;
      -webkit-overflow-scrolling: touch;

      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 1px 0 rgba(0,0,0,0.06);
    }

    .optionsScrollContainer{
      margin-bottom: 1px;

      display: block;
      height: auto;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      max-height: 200px;

      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    .option, .noOptions {
      font-size: 13px;
      color: #455a64;

      background-color: #fff;

      display: block;
      padding: 8px 10px;
      box-sizing: border-box;

      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.selected {
        background: rgba(0, 169, 157, .2);
      }

      &.marked {
        background: rgba(0, 169, 157, .6);
        color: white;
      }
    }

    .noOptions {
      color: #cccccc;
      cursor: default;
    }
  }


  .rbvSelectFade-enter-active, .rbvSelectFade-leave-active {
    transition: opacity .3s;
  }

  .rbvSelectFade-enter, .rbvSelectFade-leave-to {
    opacity: 0;
  }
</style>
