import InvitationTypesHandlerModules from './modules/InvitationsTypeHandlerModulesLibrary';
import {
  $state,
  InvitationService
} from 'root/angular-injector-provider';
import {noop} from 'lodash';

import { PUBLIC_INVITATIONS_VIEW_PAGE_NAME } from 'root/invitation-handlers/states';
import {Dialog} from 'root/v-app/rbServices';
import {PAGE_HOME} from 'root/states/public-states';
import ExitInvitationDialog from './dialogs/ExitInvitationInformationDialog.vue';

let invitationToken,
  availableModules,
  activeModule;

export default {
  initialize,
  handleInvitation,
  getToken,
  exit,
}

function initialize(token) {
  invitationToken = token;
  availableModules = InvitationTypesHandlerModules.loadModules();
  activeModule = findActiveModule(availableModules)
    .catch(handleActiveModuleErrors);

  return activeModule
}

function findActiveModule(modules) {
  const currentModule = $state().params.type,
    active = currentModule && modules.find( m => m.id === currentModule );

  return active ? Promise.resolve(active) : Promise.reject('no active modules')
}

function handleActiveModuleErrors(){
  $state().go('error', {id: '500'}).catch(noop)
}

function handleInvitation() {
  return InvitationService().handle(invitationToken).then( res => {
    if(res && res.redirect) {
      $state().go(PUBLIC_INVITATIONS_VIEW_PAGE_NAME, {
        token: res.invitation.token,
        direct: true,
        type: $state().params.type
      });
    }
    return res;
  });
}

function getToken() {
  return invitationToken;
}

function exit(then) {
  return Dialog.show(ExitInvitationDialog).then(then).catch(goHome);
}

function goHome() {
  return $state().go(PAGE_HOME);
}

