/**
 * Created by DejanK on 9/1/2017.
 */
import HTML from './ask-to-sign-up.html'
import './ask-to-sign-up.scss'
import { noop } from 'lodash'

const NAME = 'askToSignUp';

export { NAME, HTML, AskToSignUpDialogController as Controller }

AskToSignUpDialogController.$inject = ['InvitationService', 'Invitation', 'NotificationUtils', 'rbDialog', 'CurrentUser'];
function AskToSignUpDialogController(InvitationService, invitation, notificationUtils, dialog, currentUser){
  const vm = this;

  vm.submit = submit;
  vm.cancel = dialog.cancel;
  vm.notYou = notYou;

  $onInit();

  function $onInit(){
    vm.registration = {
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      emailAddress: invitation.emailAddress,
      jobTitle: invitation.jobTitle,
      phone: invitation.phone,
      token: invitation.token
    };
    vm.accountName = invitation.accountName
  }

  function submit(){
    notificationUtils.onSave(() => InvitationService.signUp(vm.registration) )
      .then(currentUser.refresh)
      .then(dialog.hide, noop)
  }

  function notYou() {
    dialog.hide({notYou: true});
  }
}
