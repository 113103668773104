/**
 * Created by DejanK on 1/18/2017.
 */

'use strict';

module.exports = createRbFloatModule();

function createRbFloatModule(){
  let rbFloatModule = angular.module('rb.float', []);

  rbFloatModule
    .component('rbFloat', require('./rb-float.cmp'))
  ;

  return rbFloatModule;
}
