/**
 * Created by DejanK on 9/25/2017.
 */
import HTML from './view-final-agreement.html'
import './view-final-agreement.scss'

export { HTML, HotelRfpFinalAgreementViewController as Controller }

HotelRfpFinalAgreementViewController.$inject = ['RfpHotelRfpRepository', '$stateParams', 'NotificationUtils'];
function HotelRfpFinalAgreementViewController(rfpHotelRfpRepository, $stateParams, notificationUtils){
  const vm = this;

  $onInit();

  function $onInit(){
    vm.rfpId = $stateParams.rfpId;
    notificationUtils.onLoad( () => rfpHotelRfpRepository.getRfpPreview(vm.rfpId), vm, 'rfp');
  }
}
