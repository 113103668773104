<template>
  <div :id="chunkId" :style="style" >
    <template v-if="show">
      <slot v-for="item in items" v-key="item.id" :item="item" />
    </template>
  </div>
</template>

<script>
  export default {
    name: "vListChunk",
    data: function(){
      return {
        mounted: false
      }
    },
    props: [ 'lowerBound', 'upperBound', 'size', 'chunkId', 'items', 'refresh'],
    computed: {
      minHeight () {
        return this.size * this.items.length
      },
      show: function() {
        if(this.mounted && this.refresh) {
          const rect = this.$el.getBoundingClientRect();
          return rect.bottom > this.upperBound && rect.top < this.lowerBound
        }
        return false;
      },
      style: function(){
        return {
          minHeight: this.minHeight ? this.minHeight + 'px' : ''
        }
      }
    },
    mounted: function() {
      this.mounted = true
    }
  }
</script>
