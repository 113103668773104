/**
 * Created by DejanK on 3/23/2017.
 */
import angular from 'angular'

import BidManagerUI from './ui/rfp.bid-manager.main.ui.module'


import States from './pages'
import Dialogs from './dialogs'
import BidManagerListViewService from './core/bid-manager-list.view.service'
import NegotiationsFactory from './core/negotiations/negotiations.factory'
import BidManagerService from './core/bid-manager.service'
import BidManagerRepository from './core/bid-manager.repo'
import BidManagerBulkBidActionsService from './core/actions/bulk-bid-actions.service'
import BidManagerSingleBidActionsService from './core/actions/single-bid-actions.service'
import BidManagerActionsFactory from './core/actions/bid-manager-actions.factory'
import BidManagerViewService from './core/bid-manager-view.service'
import NegotiationsModelFactory from './core/negotiations/negotiations-model.factory'

import rbContactAsSupplierContact from './dialogs/set-supplier-contact/suppliers/rb-contact-as-contact.cmp'
import rbUserAsSupplierContact from './dialogs/set-supplier-contact/suppliers/rb-user-as-contact.cmp'
import NegotiationsService from './core/negotiations/negotiations.service'

export default angular.module('rb.rfp.bidManager.main', [ BidManagerUI.name ])
  .config(States)
  .config(Dialogs)

  .service('BidManagerRedirectService', require('./bid-manager-redirect.service'))

  .service('BidManagerService', BidManagerService)
  .service('BidManagerRepository', BidManagerRepository)
  .service('BidManagerViewService', BidManagerViewService)
  .service('BidManagerQueryService', require('./core/bid-manager-query.service'))
  .service('BidManagerBidsParserService', require('./core/bid-manager-bids-parser.service'))
  .service('BidManagerListViewService', BidManagerListViewService)

  .service('BidManagerBidActionsService', require('./core/actions/bid-manager-bid-actions.service'))
  .factory('BidManagerActionsFactory', BidManagerActionsFactory)
  .service('BidManagerBulkBidActionsService', BidManagerBulkBidActionsService)
  .service('BidManagerSingleBidActionsService', BidManagerSingleBidActionsService)

  .service('NegotiationsPanelMessagingService', require('./core/negotiations/negotiations-panel-messaging.service'))

  .service('NegotiationsService', NegotiationsService)

  .service('NegotiationsFactory', NegotiationsFactory)
  .service('NegotiationFactory', require('./core/negotiations/negotiation.factory'))
  .service('RateFactory', require('./core/negotiations/rate.factory'))
  .service('AmenityFactory', require('./core/negotiations/amenity.factory'))
  .service('TaxesFactory', require('./core/negotiations/taxes.factory'))
  .service('TcosFactory', require('./core/negotiations/tcos.factory'))
  .service('SeasonsFactory', require('./core/negotiations/seasons.factory'))
  .service('RoomTypesFactory', require('./core/negotiations/room-types.factory'))
  .service('NegotiationsHtmlFactory', require('./core/negotiations/negotiations-html.factory'))


  .factory('NegotiationsModelFactory', NegotiationsModelFactory)
  .factory('NegotiationsEditorTooltipsFactory', require('./core/negotiations/negotiations-editor-tooltips.factory'))

  .component('rbContactAsSupplierContact', rbContactAsSupplierContact)
  .component('rbUserAsSupplierContact', rbUserAsSupplierContact)
