<template>
    <div :class="$style.hotels">
        <template v-for="group in hotelsList">
          <div :key="group.key" :class="$style.grouper" v-if="group.key !== null">
            <rb-rating v-if="groupBy === 'rating'" :rate="group.key"/>
            <span v-else-if="groupBy === 'distance'">{{group.key.toFixed(2)}} Mi</span>
            <span v-else>{{group.key || '__'}}</span>
          </div>
          <hotel
            :date="date"
            :hotel="hotel"
            :view="view" v-for="hotel in group.items"
            :mock="mockRate"
            :key="hotel.id"/>
        </template>
    </div>
</template>

<script>
import Hotel from './hotel.vue';
import sampleHotels from './samples';
import RbRating from 'vRoot/hotel-directory/_core/rating.vue';

export default {
    name: 'HotelDirectoryHotelList',
    props: ['hotels', 'view', 'groupBy', 'date', 'mockRate'],
    components: { Hotel, RbRating },
    computed: {
        hotelsList(){
            return this.hotels && this.hotels.length > 0 ? this.hotels: [{items:sampleHotels}]
        }
    }
}
</script>

<style lang="stylus" module>
  .grouper{
    padding: 0 10px;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    height: 30px;
    display: flex;
    align-items: center;
    color: #5e6c72;
  }

  .grouper > div{
    height: 20px;
    overflow: hidden;
  }
</style>
