import { mainAPI } from "root/angular-injector-provider";

export default {
  labelOf: labelOf
}

function labelOf(industry) {
  return mainAPI().getEntityIndustries()
    .then( industries => {
      const typeObject = industries.find( t => t.id === industry )
      return typeObject === null ? industry : typeObject.label
    })
    .catch( () => industry )
}
