import { merge, set, get, isArray, forEach } from 'lodash'

export { simpleState, abstractState }

function simpleState(url, template, controller, authorizedRoles, options) {
  const stateObject = merge({
    url: url,
    template: template,
    controller: controller,
    controllerAs: 'VM'
  }, options);
  addRoles(stateObject, authorizedRoles);
  return stateObject;
}

function abstractState(url, authorizedRoles, template) {
  const stateObject = {
    abstract: true,
    url: url,
    template: template || '<div ui-view></div>'
    // template: '<ui-view class="flex-0 layout-column"></ui-view>'
  };
  addRoles(stateObject, authorizedRoles);
  return stateObject
}

function addRoles(stateObject, roles){
  if(roles){
    const stateRoles = get(stateObject, 'data.authorizedRoles', []);
    if(isArray(roles)){
      forEach(roles, (role)=>{addToStateRolesSet(stateRoles, role)});
    } else {
      addToStateRolesSet(stateRoles, roles);
    }

    set(stateObject, 'data.authorizedRoles', stateRoles);
  }

  function addToStateRolesSet(rolesSet, role){
    if(rolesSet.indexOf(role) === -1){
      rolesSet.push(role);
    }
  }
}
