/**
 * Created by DejanK on 3/23/2017.
 */
import { simpleState } from '../../../../shared/tools/router-helper';
import * as BidManager from './manager/bid-manager.ctrl'

const BID_MANAGER = 'bidManager';

export default States;
export { BID_MANAGER }

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state(BID_MANAGER, simpleState('/bid-manager/{viewId}?account&negotiations,rfp', BidManager.HTML, BidManager.Controller))
}
