import {checkAndExecute} from "root/api/helpers";

export default function HotelRfpExportAPI(api) {

  function exportDataWithAutoSave(rfpId, exportData){
    return api.server.postAndAutoSaveFile(`rfps/hotel/${rfpId}/export`, exportData)
  }

  Object.assign(api, {

    hotelRfpExportTravelDestinations: rfpId => checkAndExecute(
      () => rfpId,
      () => exportDataWithAutoSave(rfpId, {name: 'HOTEL_RFP:TRAVEL_DESTINATIONS:EXCEL'}))

  })
}
