import {
  $state,
  InvitationHandlersRepository,
  notificationUtils
} from 'root/angular-injector-provider';
import { HOTEL_RFP_BID_PREVIEW } from 'root/rfp-hotel/main/bid/pages/rfp-hotel-bid.states';

export {
  handle,
}

function handle(token) {
  return notificationUtils().onSave(() => InvitationHandlersRepository().markInvitationAsUsed(token))
    .then(({data: {targetId}}) => Promise.resolve($state().go(HOTEL_RFP_BID_PREVIEW, {bidId: targetId})))
}
