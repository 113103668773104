/**
 * Created by DejanK on 11/4/2016.
 */
import { clone, find } from 'lodash'

export default {
  template: templateFn,
  bindings: {
    model: '=',
    country: '='
  },
  controller: Ctrl
};

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, RbInputTemplate){
  const options = Object.assign(RbInputTemplate.readDefaultAttributes($attrs), {
    repeat: 'state in $ctrl.states | filter: {country: $ctrl.country} track by state.id',
    value: '::state.id',
    option: '{{::state.label}}'
  })

  return RbInputTemplate.ngRequiredSelectTemplate(options);
}

Ctrl.$inject = ['DataServer','$element'];
function Ctrl(dataServer,$element) {
  const vm = this
  vm.required = $element.attr('required') === 'required';

  dataServer.getStates()
    .then((states) => {
      const s = clone(states)
      s.unshift({id: undefined, name: '-'})
      vm.states = s
      checkValue()
    })

  function checkValue() {
    const state = find(vm.states, c => c.id === vm.model)
    if (!state) {
      vm.model = undefined
    }
  }
}
