/**
 * Created by DejanK on 3/31/2017.
 */
import './select-rfp-type.scss';
import HTML from './select-rfp-type.html';
import noop from 'lodash/noop';

export { HTML, SelectRfpType as Controller }

SelectRfpType.$inject = ['NotificationUtils', 'DataServer', '$scope', 'CurrentUser', '$stateParams'];
function SelectRfpType(utils, DataServer, $scope , CurrentUser, $stateParams) {
  const vm = this;

  vm.getRfpImageUrl = getRfpImageUrl;

  onInit();

  function onInit(){
    utils.onLoad( () => DataServer.getRfpTypes(), vm, 'types' )
      .catch( noop );

    vm.accountId = $stateParams.accountId;

    CurrentUser.watchUser($scope, vm);
  }

  function getRfpImageUrl(urlPart){
    return `/images/rfp-types/${urlPart}`;
  }
}
