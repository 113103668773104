import {groupBy, orderBy, get} from 'lodash';

import {
  COMPANY_NAME_GROUPER_ID,
  ASSIGNEE_NAME_GROUPER_ID,
  RFP_LAUNCH_MONTH_GROUPER_ID, RFP_STATUS_GROUPER_ID
} from './rfp-manager-table.service';

import rfpStatuses from 'root/rfp-hotel/rfp-statuses';
import moment from 'moment';

const RFP_FINALIZED_STATUS = 'RFP FINALIZED';
const RFP_IN_PROGRESS_STATUS = 'RFP IN PROGRESS';
const ARCHIVED_STATUS = 'RFP_ARCHIVED';

function groupRfps(grouper, accounts, users, rfps, shouldShowArchivedRfps) {
  if(grouper.id === COMPANY_NAME_GROUPER_ID) {
    return accounts.reduce((acc, account) => {
      const items = getAccountRfps(rfps, account.account.id, shouldShowArchivedRfps);
      return [
        ...acc,
        {
          ...formCompanyHeader(account, items.length),
          ...account
        },
        ...items.map(item => ({...item, rfp: formRfp(item.rfp, accounts)}))
      ];
    }, []);
  } else if(grouper.id === ASSIGNEE_NAME_GROUPER_ID) {
    const userGroupedItems =  users.reduce((acc, user) => {
      if(user.accountsManaged && user.accountsManaged.length > 0) {
        const itemsForUser = user.accountsManaged && user.accountsManaged.reduce((reduced, account) => [...reduced, ...rfpsForAccount(account.accountDetails, user.id)], []);

        return [
          ...acc,
          {
            ...createHeaderWithEssentialParts(user.id, user.fullName, itemsForUser.length, grouper.id),
            assignee: user
          },
          ...itemsForUser
        ];
      }else{
        return acc;
      }
    }, []);

    const unAssignedCompanies = accounts.filter(({account}) => !account.primaryAgent || !account.primaryAgent.userId);

    let itemsForUnassignedCompanies = [];
    if(unAssignedCompanies.length > 0) {
      itemsForUnassignedCompanies.push(createHeaderWithEssentialParts(false, 'Unassigned', unAssignedCompanies.length, ASSIGNEE_NAME_GROUPER_ID));
      itemsForUnassignedCompanies = [
        ...itemsForUnassignedCompanies,
        ...unAssignedCompanies.reduce((reduced, account) => [...reduced, ...rfpsForAccount(account.account, false)], [])
      ]
    }

    return [...userGroupedItems, ...itemsForUnassignedCompanies];
  } else {
    const groups = groupBy(sortForGrouping(rfps).filter(item => (shouldShowArchivedRfps? true: !item.rfp.archived)), grouper.key);

    return Object.keys(groups).map(key => {
      const items = groups[key]
        .map(item => ({...item, rfp: formRfp(item.rfp, accounts)}));

      key = key === 'undefined'? null: key;
      const title = !key? 'None': key;

      return [
        createHeaderWithEssentialParts(key, title,  items.length, grouper.id),
        ...items
      ];
    }).reduce((acc, group) => { return [...acc, ...group]}, []);
  }

  function rfpsForAccount(account, headerKey = null) {
    const items = getAccountRfps(rfps, account.id, shouldShowArchivedRfps).map(item => ({...item, rfp: formRfp(item.rfp, accounts)}));
    if(items.length > 0) {
      return items;
    }else{
      const company =  accounts.find(c => c.account.id === account.id);
      return [{
        ...formCompanyHeader(company, 0, headerKey),
        ...company
      }];
    }
  }

  function sortForGrouping(items) {
    if(grouper.id === RFP_LAUNCH_MONTH_GROUPER_ID){
      return orderBy(items, [rfp => {
        const date = get(rfp, 'rfp.specifications.launchDate');
        return moment(date || 0).diff(moment(), 'days');
      }], ['desc']);
    }else if (grouper.id === RFP_STATUS_GROUPER_ID) {
      return orderBy(rfps, [({rfp}) => {
        const ranks = {
          [rfpStatuses.RFP_PENDING]: 1,
          [RFP_IN_PROGRESS_STATUS]: 2,
          [RFP_FINALIZED_STATUS]: 3,
          [ARCHIVED_STATUS]: 4,
        };
        return ranks[getStatusValue(rfp, false)];
      }], ['asc'])
    } else {
      return rfps;
    }
  }
}

function getAccountRfps(rfps, accountId, shouldShowArchivedRfps) {
  return rfps.filter(({rfp}) => rfp.buyer.company.accountId === accountId && (shouldShowArchivedRfps? true: !rfp.archived));
}

function formCompanyHeader(company, count, headerKey = null) {
  return {
    ...createHeaderWithEssentialParts(company.account.id, company.account.name, count, COMPANY_NAME_GROUPER_ID, headerKey),
    ...company
  };

}

function createHeaderWithEssentialParts(key, title, count, headerType, headerKey = null) {
  return { type: 'header', key, title, count, headerType, headerKey };
}

function getAssignee(rfp, accounts) {
  let assignee = accounts.find(({account}) => account.id === rfp.buyer.company.accountId).account;
  assignee = assignee.primaryAgent && assignee.primaryAgent.userDetails;
  return assignee;
}

function formRfp({rfpBidsCount, ...rfp}, accounts) {
  const sent = +rfpBidsCount.sent + +rfpBidsCount.responded + +rfpBidsCount.negotiating + +rfpBidsCount.finalAgreements;
  const responded = sent - +rfpBidsCount.sent;
  const obrp = `${sent > 0? parseFloat((responded/sent) * 100).toFixed(0): 0}%`;

  return {
    ...rfp,
    rfpBidsCount: {
      ...rfpBidsCount,
      obrp,
    },
    assignee: getAssignee(rfp, accounts),
  }
}

function getStatusValue(rfp, split = true) {
  const statuses = {
    [rfpStatuses.RFP_COMPLETED]: RFP_FINALIZED_STATUS,
    [rfpStatuses.RFP_SENT]: RFP_IN_PROGRESS_STATUS,
    [rfpStatuses.RFP_PENDING]: rfpStatuses.RFP_PENDING,
  };
  const value = rfp.archived? ARCHIVED_STATUS: statuses[rfp.status.value];
  return split? value.split('_').join(' '): value;
}

export {
  groupRfps,
  getAssignee,
  formRfp,
  getStatusValue
}
