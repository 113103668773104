<template>
  <div class="rbv-ng-invitations-handler">
    <component
      :is="activeModuleComponent"
      v-if="activeModuleComponent"
    />
  </div>
</template>

<script>
  import invitationsHandlerService from './invitations-handler.service';
  export default {
    name: 'InvitationsHandler',
    props: {
      token: {
        type: String,
        required: true
      }
    },
    asyncComputed: {
      activeModuleComponent() {
        return invitationsHandlerService.initialize(this.token)
          .then( module => module && module.component )
      }
    },
    mounted() {
      this.toggleDialogClass();
    },
    destroyed() {
      this.toggleDialogClass();
    },
    methods: {
      toggleDialogClass() {
        const el = document.getElementsByTagName('body')[0];
        el.classList.toggle('invitation');
      }
    }
  }
</script>

<style lang="stylus">
  .rbv-ng-invitations-handler{
    background: #F5F6F7 url('~images/ui/auth-background.png');
    background-size: cover;
    height calc(100vh - 61px) !important;
    overflow auto;
  }

  body.invitation .rbOverlay .rb-dialog{
    box-shadow: none;
    margin-top: 65px;

    .close.standalone{
      display none;
    }
  }
</style>
