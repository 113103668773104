import Vue from 'vue';
import NegotiationsFormButtons from './TheNegotiationsFormButtons.vue';

export default {
  template: '<div></div>',
  bindings: {
    mode: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element']
function Ctrl($element){
  const vm = this;
  let vueComponent;

  vm.$onChanges = () => {
    !vueComponent && ( ['EDIT', 'CREATE'].indexOf(vm.mode) > -1 ) && initVueComponent();
  };

  vm.$onDestroy = () => {
    vueComponent && vueComponent.$destroy();
  };

  function initVueComponent(){
    vueComponent = new Vue({
      el: $element[0],
      render ( createElement ) {
        return createElement(NegotiationsFormButtons, { props: { mode: vm.mode } });
      }
    });
  }
}
