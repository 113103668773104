import rfpManagerActionsService from './rfp-manager-actions.service';
import {ASSIGNEE_NAME_GROUPER_ID, COMPANY_NAME_GROUPER_ID} from './rfp-manager-table.service';
import rfpStatus from 'root/rfp-hotel/rfp-statuses';

//RFP MANAGER PERMISSIONS
const RFP_ACCESS = 'RFP_ACCESS',
  ACCOUNT_CREATE_RFP = 'ACCOUNT_CREATE_RFP';


const actions = [
  {
    action: {
      id: 'editRfp',
      label: 'Edit RFP',
      icon: 'edit',
      primary: true,
      action: rfpManagerActionsService.editRfp
    },
    validator(_, [rfp, ...more]) {
      const valid = more.length === 0 && rfpIsNotGroup(rfp) && !rfp.rfp.archived;
      return valid && itemHasPermission(rfp, RFP_ACCESS)
    }
  },
  {
    action: {
      id: 'openBidManager',
      icon: 'view_list',
      label: 'Open Bid Manager',
      primary: true,
      action: rfpManagerActionsService.openBidManager
    },
    validator(_, [rfp, ...more]) {
      const valid = more.length === 0;
      return valid && (isGroupType(rfp, COMPANY_NAME_GROUPER_ID)? itemHasPermission(rfp, ACCOUNT_CREATE_RFP): itemHasPermission(rfp, RFP_ACCESS))
    }
  },
  {
    action: {
      id: 'createRfp',
      icon: 'create_new_folder',
      label: 'Create New RFP',
      action: ([{account}], accounts) => rfpManagerActionsService.createNewRfp(
          accounts,
          account.id,
        )
    },
    validator(account, [rfp, ...more]) {
      const valid = more.length === 0 && isGroupType(rfp, COMPANY_NAME_GROUPER_ID);
      return valid && itemHasPermission(account, ACCOUNT_CREATE_RFP);
    }
  },
  {
    action: {
      id: 'manageCompany',
      icon: 'business_center',
      label: 'Manage Company',
      primary: true,
      action: ([{account, rfp}]) => rfpManagerActionsService.manageAccount(account? account.id: rfp.buyerCompanyAccountId)
    },
    validator(_, [rfp, ...more]) {
      const valid = more.length === 0;
      return valid && (isGroupType(rfp, COMPANY_NAME_GROUPER_ID)? itemHasPermission(rfp, ACCOUNT_CREATE_RFP): itemHasPermission(rfp, RFP_ACCESS))
    }
  },
  {
    action: {
      id: 'duplicateRfp',
      icon: 'add_to_photos',
      label: 'Duplicate RFP',
      action: ([{rfp}]) => rfpManagerActionsService.duplicateRfp(rfp.id)
    },
    validator(account, [rfp, ...more]) {
      const valid = more.length === 0 && rfpIsNotGroup(rfp);
      return valid && itemHasPermission(account, ACCOUNT_CREATE_RFP) && itemHasPermission(rfp, RFP_ACCESS);
    }
  },
  {
    action: {
      id: 'markAsComplete',
      label: 'Finalize RFP(s)',
      icon: 'done_all',
      primary: true,
      action: rfpManagerActionsService.completeRfps
    },
    validator(_, rfps) {
      return !rfps.find(rfp => !rfpIsNotGroup(rfp) || !itemHasPermission(rfp, RFP_ACCESS) || rfp.rfp.statusValue !== rfpStatus.RFP_SENT)
    }
  },
  {
    action: {
      id: 'archiveRfp',
      label: 'Archive RFP(s)',
      icon: 'archive',
      primary: true,
      action: rfpManagerActionsService.archiveRfps
    },
    validator(_, rfps) {
      return !rfps.find(rfp => !rfpIsNotGroup(rfp) || !itemHasPermission(rfp, RFP_ACCESS) || rfp.rfp.archived)
    }
  },
  {
    action: {
      id: 'unArchiveRfp',
      label: 'Unarchive RFP(s)',
      icon: 'unarchive',
      primary: true,
      action: rfpManagerActionsService.unArchiveRfps
    },
    validator(_, rfps) {
      return !rfps.find(rfp => !rfpIsNotGroup(rfp) || !itemHasPermission(rfp, RFP_ACCESS) || !rfp.rfp.archived)
    }
  },
];

function itemHasPermission(item, ...permissions){
  return !permissions.find(permission => item.permissions.indexOf(permission) === -1);
}

function rfpIsNotGroup(rfp) {
  return rfp.type !== 'header';
}

function isGroupType(rfp, headerType) {
  return !rfpIsNotGroup(rfp) && rfp.headerType === headerType;
}

function getActionsForSelectedRfps(accounts, rfps) {
  const primaryAccount = accounts.find(({account}) => {
    if(isGroupType(rfps[0], COMPANY_NAME_GROUPER_ID)) {
      return rfps[0].account.id === account.id;
    }else if(isGroupType(rfps[0], ASSIGNEE_NAME_GROUPER_ID)){
      return rfps[0].assignee.accountId === account.id
    }else{
      return rfps[0].rfp.specifications.buyer.company.accountId === account.id
    }
  });
  return primaryAccount && loadActions();

  function loadActions() {
    return actions.filter(action => action.validator(primaryAccount, rfps)).map(({action}) => action);
  }
}


export default { getActionsForSelectedRfps }
export {RFP_ACCESS, ACCOUNT_CREATE_RFP }
