/**
 * Created by DejanK on 4/12/2017.
 */
import HTML from './ask-to-sign-out.html'

const NAME = "askToSignOut"

export { NAME, HTML, AskToSignOutDialogController as Controller}

AskToSignOutDialogController.$inject = ['CurrentUser', 'rbDialog', 'User', 'Invitation'];
function AskToSignOutDialogController(CurrentUser, dialog, user, invitation){
  const vm = this;

  vm.signOut = signOut;
  vm.cancel = dialog.cancel

  $onInit();

  function $onInit(){
    vm.username = user.getName()
    vm.userEmailAddress = user.emailAddress
    vm.invitation = invitation
  }

  function signOut(){
    CurrentUser.forget();
    dialog.hide();
  }
}
