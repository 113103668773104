<template>
    <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler" @submit="$formSubmitHandler">
        <span slot="header">
            <div>{{name}}</div>
            <div :class="$style.subHeader">
                This is the file you uploaded in ReadyBid format. You can edit each individual user or just click to add your users if everything is in order.
            </div>
        </span>
        <div slot="body" class="vModalDefaultBody hotel-directory">
            <div :class="$style.bodyTop">
                <i :class="$style.check" v-if="erroredUsers === 0" class="material-icons">check</i>
                <i class="material-icons" v-else :class="{[$style.warning]: true, [$style.check]: true}">warning</i>
                <span :class="$style.bodyTopText">
                    We found {{users.length}} users in the file you uploaded
                    <span v-if="erroredUsers > 0" :class="$style.errorMessage"> - {{erroredUsers}} errors found</span>
                </span>
                <hd-select :class="$style.sorter" v-model="sortBy" :items="sorters">
                    <i class="material-icons">sort</i>
                    Sort By - {{sorter.text}}
                </hd-select>
            </div>
            <div :class="$style.tableHead">
                <div :class="$style.cellId">ID</div>
                <div :class="$style.cell">User Name</div>
                <div :class="$style.cell">Email</div>
                <div :class="$style.cell">Phone Number</div>
                <div :class="$style.cell">Title</div>
                <div :class="$style.cell">Department</div>
                <div :class="$style.cellAction">Actions</div>
            </div>
            <div :class="$style.tableBody">
                <div :class="$style.row" v-for="user in sortedUsers" :key="user.id">
                    <div :class="$style.cellId">{{user.id}}</div>
                    <div :class="$style.cell">
                        <template v-if="user.errorMessages('firstName')">
                            <i class="material-icons" :class="$style.warning">warning</i>
                            <div
                                class="rbTooltip simple"
                                tooltip="placement:top-start">{{user.errorMessages('firstName')}} </div>
                        </template>
                        {{user.firstName}} {{user.lastName}}
                        <template v-if="user.errorMessages('lastName')">
                            <i class="material-icons" :class="$style.warning">warning</i>
                            <div
                                class="rbTooltip simple"
                                tooltip="placement:top-start">{{user.errorMessages('lastName')}} </div>
                        </template>
                    </div>
                    <div :class="$style.cell">
                        <template v-if="user.errorMessages('email')">
                            <i class="material-icons" :class="$style.warning">warning</i>
                            <div
                                class="rbTooltip simple"
                                tooltip="placement:top-start">{{user.errorMessages('email')}} </div>
                        </template>
                        {{user.email}}
                    </div>
                    <div :class="$style.cell">
                        <template v-if="user.errorMessages('phone')">
                            <i class="material-icons" :class="$style.warning">warning</i>
                            <div
                                class="rbTooltip simple"
                                tooltip="placement:top-start">{{user.errorMessages('phone')}} </div>
                        </template>
                        {{user.phone}}
                    </div>
                    <div :class="$style.cell">
                        <template v-if="user.errorMessages('jobTitle')">
                            <i class="material-icons" :class="$style.warning">warning</i>
                            <div
                                class="rbTooltip simple"
                                tooltip="placement:top-start">{{user.errorMessages('jobTitle')}} </div>
                        </template>
                        {{user.jobTitle}}
                    </div>
                    <div :class="$style.cell">
                        <template v-if="user.errorMessages('department')">
                            <i class="material-icons" :class="$style.warning">warning</i>
                            <div
                                class="rbTooltip simple"
                                tooltip="placement:top-start">{{user.errorMessages('department')}} </div>
                        </template>
                        {{user.department}}
                    </div>
                    <div :class="$style.cellAction">
                        <i @click="edit(user)" :class="$style.action" class="material-icons">edit</i>
                        <i @click="drop(user)" :class="$style.action" class="material-icons">delete</i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="vModalDefaultFooter">
            <button class="rbButton" type="button" @click="cancel()">Cancel</button>
            <button class="rbButton mainButton" :disabled="erroredUsers > 0">
                <i class="material-icons">group_add</i>
                Add Users
            </button>
        </div>
    </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';
  import EditUploadUserDialog from './EditUploadUserDialog.vue';
  import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';

  import { Dialog } from "root/v-app/rbServices";

  import { orderBy } from 'lodash';

  export default {
    name: 'manageUploadedUsersDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['name','users'],
    components: { ModalForm, HdSelect },
    data() {
       return {
            data: {
                users: this.users,
                message: null
            },
            sorters: [
                {
                    id: 'none',
                    text: "Id",
                },
                {
                    id: 'firstName',
                    text: "First Name",
                    key: 'firstName'
                },
                {
                    id: 'lastName',
                    text: "Last Name",
                    key: 'lastName'
                },
                {
                    id: 'email',
                    text: "Email",
                    key: 'email'
                },
                {
                    id: 'phone',
                    text: "Phone Number",
                    key: 'phone'
                },
                {
                    id: 'department',
                    text: "Department",
                    key: 'department'
                },
                {
                    id: 'title',
                    text: "Job Title",
                    key: 'jobTitle'
                }
            ],
            sortBy: 'none'
        }
    },
    computed: {
        sorter(){
            return this.sorters.find(group => group.id === this.sortBy);
        },
        sortedUsers(){
            return this.sorter.key ? orderBy(this.data.users, [this.sorter.key], ['asc']): this.data.users;
        },
        erroredUsers(){
            return this.data.users.filter(user => !user.$isValid).length;
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        },
        edit(user){
            return Dialog.show(EditUploadUserDialog, {user}).then(data => {
                data.user.validate().then(newUser => {
                    this.data.users = this.data.users.map(user => {
                        return user.id === newUser.id? newUser: user
                    });
                })
            });
        },
        drop(user){
            Dialog.show(WarningDialog, {
                title: `Delete ${user.firstName} ${user.lastName}`,
                subTitle: 'This user will not be added to the hotel directory if you proceed to delete'
            }).then(() => {
                this.data.users = this.data.users.filter(u => user.id !== u.id);
            });
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        min-width: 950px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .bodyTop{
        display flex;
        align-items center;
    }

    .bodyTopText{
        flex 1;
        padding 0 10px;
        color #78909c;
    }

    .check{
        height 48px;
        width 48px;
        background #ECEFF1;
        color #00A99D;
        text-align center;
        padding 12px 0;
        box-sizing border-box;
        border-radius 100%;
    }

    .row{
        display flex;
        align-items center;
        border-bottom 1px solid #ECEFF1
    }

    .row:hover{
        background #ECEFF1;
    }

    .action{
        cursor pointer;
        border-radius 100%;
        padding 3px;
        font-size 20px
    }

    .row:hover .action{
        background #fff;
    }



    .tableHead{
        composes row;
        padding 5px 0;
    }

    .cell{
        flex-basis: 140px;
        font-size: 12px;
        padding: 3px;
        height: 30px;
        align-items: center;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #546E7A;
        box-sizing: border-box;
        flex 1;
    }

    .cellId{
        composes cell;
        flex-basis: 30px;
        width: 30px;
        flex unset;
    }

    .cellAction{
        composes cell;
        flex-basis: 70px;
        width: 70px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex unset;
    }

    .tableBody{
        border 2px solid #B0BEC5;
        max-height 210px;
        overflow-y auto;
    }

    .warning, .errorMessage{
        color #f15a25;
    }
</style>
