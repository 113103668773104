/**
 * Created by DejanK on 25/4/2017.
 *
 * rbBidManagerHeader Component
 */
import '../../../../public/ui/headers.scss'
import HTML from './rb-bid-manager-header.html'
import {stopEvent} from 'root/shared/tools/view-utils'
import {NAME as notImplementedDialog} from 'root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl'
import { noop } from 'lodash'

export default {
  template: HTML,
  transclude: true,
  controller: RbBidManagerHeaderCtrl
}

RbBidManagerHeaderCtrl.$inject = ['$scope', 'CurrentUser', 'rbDialog']
function RbBidManagerHeaderCtrl($scope, currentUser, dialog){
  const vm = this

  vm.$onInit = () => { currentUser.watchUser($scope, vm) }
  vm.showNotImplementedNotification = $event => {
    stopEvent($event)
    dialog.show(notImplementedDialog).catch( noop )
  }
}
