<template>
    <div :class="$style.editor">
        <div v-if="!activeSubModuleComponent" class="layout-column flex-center" :class="$style.loader">
            <rbv-loading label="Loading, please wait..."/>
        </div>
        <span v-else>
            <div :class="getContentStyles()">
                <component
                    @preview="preview"
                    v-if="directory"
                    :expanded="!open"
                    :directory="directory"
                    :is="activeSubModuleComponent"/>
                <div v-else class="layout-column flex-center" :class="$style.loader">
                    <rbv-loading label="Loading, please wait..."/>
                </div>
            </div>
            <navigation :open="open" :directory="directory" @toggle="toggleNavigator" />
        </span>
    </div>
</template>

<script>
import Navigation from './navigation/HotelDirectoryEditorNavigation.vue';
import hotelDirectoryService from '../hotel-directory-service';
import RbvLoading from "vRoot/_core/RbvLoading.vue";

export default {
    name: 'HotelDirectoryEditor',
    components: { Navigation, RbvLoading },
    props: ['user'],
    data(){
        return {
            open: true,
            directory: null
        }
    },
    asyncComputed:{
        activeSubModuleComponent(){
            return hotelDirectoryService.initialize(this.user).then(module => {
                this.getDirectory();
                return module && module.component
            });
        }
    },
    methods: {
        toggleNavigator(){
            this.open = !this.open;
        },
        getContentStyles(){
            return this.open? this.$style.content: this.$style.expanded;
        },
        preview(e){
            this.open = e;
        },
        getDirectory(){
            hotelDirectoryService.getDirectory().then(data => {
                this.directory = data;
            });
        }
    }
}
</script>

<style lang="stylus" module>
    .editor{
        position relative;
    }
    .content{
        height: calc(100vh - 61px);
        padding-left: 280px;
        transition padding .3s;
        overflow-y: auto;
    }

    .expanded{
        composes content;
        padding-left 48px;
    }

    .loader{
        padding 100px;
    }
</style>
