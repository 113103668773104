<template>
  <modal-form :class="$style.dialog" @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <div :class="$style.header" slot="header">
      <i class="material-icons" :class="$style.warning">info_outline</i>
      <div :class="$style.texts">
        <div>{{user.firstName}}</div>
        <div :class="$style.subHeader">{{title}}</div>
      </div>
    </div>
    <div slot="body" class="vModalDefaultBody hotel-directory">
      <p>Hi <b>{{user.firstName}},</b></p>
      <p>
        {{message}}
      </p>
    </div>
    <div slot="footer" class="vModalDefaultFooter" :class="$style.footer">
      <check-box
        :check-box-value="true"
        v-model="dontShow"
        check-box-label="Don't show this again"/>
      <button class="rbButton" type="submit"> Close </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';

  export default {
    name: 'DirectoryTutorialInfoDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['user', 'title', 'message'],
    components: { ModalForm, CheckBox },
    data(){
      return {
        dontShow: null
      }
    },
    methods: {
      onSubmit() {
        return {dontShow: this.dontShow};
      },
      takeAction(action) {
        this.action = action;
        this.$nextTick(this.$formSubmitHandler);
      },
      demo(){}
    },
    computed: {
      year(){
        return (new Date()).getFullYear();
      }
    }
  }
</script>

<style lang="stylus" module>
  p{
    font-size: 12px;
    color: #78909c;
    margin: 10px 0;
    line-height: 2;
  }

  .dialog{
    width 450px;
  }

  .subHeader{
    font-size 25px;
    line-height 14pt;
    color #78909c;
    font-weight lighter;
  }

  .header{
    display flex;
    font-weight lighter;
  }

  .warning{
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: #00b8ab;
    margin-right 10px;
  }

  .texts{
    padding 0 5px;
    font-size: 16px;
    color: #78909c;
  }

  .footer{
    display: flex;
    justify-content: space-between;
  }
</style>
