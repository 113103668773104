<template>
  <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">
        <div>{{view.name}} catergory display content</div>
        <div :class="$style.subHeader">Add or remove content to be displayed on your hotel directory</div>
    </span>
    <div slot="body" class="vModalDefaultBody hotel-directory">
        <rb-input :forId="'name'" :error="$showError('name')">
            <span slot="label">View Name</span>
            <input id="name" type="text" name="name" class="rbInput" autofocus
                v-model="data.view.name"
                maxlength="100"
                v-validate="'required|max:100'"
                placeholder="view name"
                data-vv-as="view name"/>
        </rb-input>
        <div>
            <h3 :class="$style.cardTitle">General Information - (columns to be shown)</h3>
            <div>
                <span
                    :class="$style.column"
                    v-for="column in columns"
                    :key="column.id">
                    <check-box
                      v-if="column.id !== 'code'"
                      :value="data.view.columns.indexOf(column.id) > -1"
                      :check-box-value="true"
                      :check-box-label="column.text"
                      @input="toggleColumn(column.id)"
                      class="light"
                      :class="$style.columnCheckbox"/>
                    <hd-select :value="selectedCode" @input="selectCode" v-else :items="propertyCodes" :class="$style.codeDropdown">
                        <i class="material-icons" slot="icon">info</i>
                        <label slot="label">{{column.text}}</label>
                    </hd-select>
                </span>
            </div>
        </div>
        <div :class="$style.group">
            <div>
                <div :class="$style.cardBody">
                    <h3 :class="$style.cardTitle">Rates to be shown:</h3>
                    <div
                        v-for="type in rates"
                        :key="type.id"
                    >
                        <check-box
                            :value="data.view.rates.indexOf(type.id) > -1"
                            :check-box-value="true"
                            :check-box-label="type.name"
                            @input="toggleRateType(type.id)"
                            class="light"/>
                    </div>
                </div>
            </div>
            <div>
                <h3 :class="$style.cardTitle">Users can see these types hotels:</h3>
                <div :class="$style.cardBody">
                    <div
                        v-for="type in hotelTypes"
                        :key="type.id"
                    >
                        <check-box
                            :value="data.view.hotelTypes.indexOf(type.id) > -1"
                            :check-box-value="true"
                            :check-box-label="type.name"
                            @input="toggleHotelType(type.id)"
                            class="light"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div slot="footer" class="vModalDefaultFooter">
        <button v-if="canDelete" class="rbButton" :class="$style.left" type="button" @click="drop">
            <i class="material-icons">delete</i>
            Delete View
        </button>
        <button class="rbButton" type="button" @click="cancel()">Cancel</button>
        <button class="rbButton mainButton">
            <i class="material-icons">save</i>
            Save Changes
        </button>
    </div>

  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import RbInput from 'root/v-components/_core/RbInput.vue'
  import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
  import hotelDirectoryService from '../hotel-directory-service';
  import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';
  import { cloneDeep } from 'lodash';

  export default {
    name: 'ManageContentDialog',
    props: ['view', 'can-delete'],
    mixins: [ RbFormMixin, DialogMixin ],
    components: { RbInput, ModalForm, CheckBox, HdSelect },
    data() {
       return {
          data : {
              action: 'update',
              view: cloneDeep(this.view),
          },
          hotelTypes: [
              {id: "L", name: "Luxury Hotels"},
              {id: "UU", name: "Upper Upscale Hotels"},
              {id: "U", name: "Upscale Hotels"},
              {id: "UM", name: "Upper Midscale Hotels"},
              {id: "M", name: "Midscale Hotels"},
              {id: "E", name: "Economy Hotels"},
          ],
          rates: [
              {id: 'SS', name: 'Standard Single Rate'},
              {id: 'SD', name: 'Standard Double Rate'},
              {id: 'US', name: 'Upgraded Single Rate'},
              {id: 'UD', name: 'Upgraded Double Rate'},
              {id: 'DS', name: 'Deluxe Single Rate'},
              {id: 'DD', name: 'Deluxe Double Rate'},
              {id: 'DYN', name: 'Dynamic Rate'},
          ],
          propertyCodes: [
            {id: null, text: "None", value: null},
            {id: 'AMADEUS_PROPCODE', text: "Amadeus Code", value: 'amadeuscode'},
            {id: 'APOLLO_PROPCODE', text: "Apollo Code", value: 'apollocode'},
            {id: 'SABRE_PROPCODE', text: "Sabre Code", value: 'sabrecode'},
            {id: 'WRLDSPAN_PROPCODE', text: "WorldSpan Code", value: 'worldspancode'}
          ]
        }
    },
    computed: {
        columns(){
            return hotelDirectoryService.getColumns();
        },
        selectedCode() {
          const c = this.propertyCodes.find(code => this.data.view.columns.indexOf(code.value) >= 0);
          return c && c.id;
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        },
        selectCode(e) {
          let columns;
          if(e) {
            if(this.selectedCode){
              columns = this.data.view.columns.map(column => {
                return column === this.getCodeValue(this.selectedCode)? this.getCodeValue(e): column;
              });
            }else{
              columns = [...this.data.view.columns, this.getCodeValue(e)];
            }
          }else {
            columns = this.data.view.columns.filter(column => column !== this.getCodeValue(this.selectedCode));
          }
          this.data.view.columns = columns;
        },
        getCodeValue(id) {
          return this.propertyCodes.find(code => code.id === id).value;
        },
        toggleHotelType(id){
            if(this.data.view.hotelTypes.indexOf(id) === -1){
                this.data.view.hotelTypes = this.data.view.hotelTypes.concat(id);
            }else{
                this.data.view.hotelTypes = this.data.view.hotelTypes.filter(type => type !== id);
            }
        },
        toggleRateType(id){
            if(this.data.view.rates.indexOf(id) === -1){
                this.data.view.rates = this.data.view.rates.concat(id);
            }else{
                this.data.view.rates = this.data.view.rates.filter(type => type !== id);
            }
        },
        toggleColumn(id){
            if(this.data.view.columns.indexOf(id) === -1){
                this.data.view.columns = this.data.view.columns.concat(id);
            }else{
                this.data.view.columns = this.data.view.columns.filter(column => column !== id);
            }
        },
        drop(){
            this.data.action = 'delete';
            this.$formSubmitHandler();
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        width: 560px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .cardTitle{
        padding 5px 0;
        font-size 13px;
    }

    .cardBody > div{
        height 25px;
    }

    .group{
        display flex;
    }

    .group > div{
        flex 1;
    }

    .icon{
        font-size: 20px;
        color: #79909d;
    }

    .columnCheckbox{
        margin 0 !important;
    }

    .column{
        display: inline-flex;
        width: 33%;
        align-items: center;
        justify-content: flex-start;
        padding-top 5px;
    }

    .left{
        float left;
    }
  .codeDropdown :global .button{
    height: 25px;
    min-width: 165px;
    max-width: 0;
    cursor: pointer;
    padding: 2px 6px;
  }

  .codeDropdown{
    cursor pointer;
  }

  .codeDropdown :global .button i.material-icons{
    z-index 9;
  }
  .codeDropdown :global .button i.material-icons:last-child{
    position: absolute;
    right: 10px;
  }
</style>
