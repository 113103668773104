<template>
  <div class="hotel"  :class="{open: open}" v-if="rates" >
    <template v-if="!open">
      <img class="image"
           :src="hotel.sample? hotel.company.image.url: formattedImageUrl"
           alt=""  @click="toggle">
      <div class="columns"  @click="toggle">
        <div class="main column sm">
          <span class="hotel-name label">{{hotel.company.name}}</span>
          <rating class="data" :rate="hotel.rating" v-if="hasColumn('rating')">
            &nbsp;&middot;&nbsp;
            <template v-if="hasColumn('address')">
              {{address}}
            </template>
          </rating>
          <div class="data hotel-type" v-else-if="hasColumn('address')">
            <div class="address">{{address}}</div>
          </div>
        </div>
        <template
          v-for="column in columns">
          <div :key="column.id" class="column" :class="column.id" v-if="hasColumn(column.id) || (column.id === 'code' && includedCode)">
            <span
              v-if="column.id !== 'amenities' && column.id !== 'code'"
              class="label">{{column.text}}</span>
            <span class="data"
                  v-if="column.id === 'distanceMi'">{{getValue(column.key).toFixed(2)}} Mi
            </span>
            <amenities class="data" v-else-if="column.id === 'amenities'"  :amenities="amenities" />
            <template v-else-if="column.id === 'code'">
              <span class="label">{{includedCode.text}}</span>
              <span class="data">{{includedCodeValue}}&nbsp;</span>
            </template>
            <span class="data" v-else>
              {{getValue(column.key) || '-'}}
            </span>
          </div>
        </template>
      </div>
      <div class="sm rate column" @click="toggle">
        <div class="label"> Pref. rate </div>
        <div class="data">
          <span class="value" v-if="bestRate">{{bestRate}} {{hotel.currency}}</span>
          <span class="value"
                v-else-if="showDynamicRate">{{hotel.dynamicRate}}% off Best Available</span>
          <i class="material-icons">keyboard_arrow_down</i>
          <span class="dyn"
                v-if="bestRate && showDynamicRate && hotel.dynamicRate">
              {{hotel.dynamicRate}}% off best available
            </span>
        </div>
      </div>
    </template>
    <template v-else>
      <span class="actions">
        <i
          class="material-icons screen"
          @click="imageExpanded = !imageExpanded">
          {{!imageExpanded ? 'fullscreen' : 'fullscreen_exit' }}
        </i>
        <i class="material-icons" @click="toggle">close</i>
      </span>

      <img
        v-if="hotel.company.logo"
        class="logo"
        :src="`images/logos/${hotel.company.logo}`" alt="">
      <img class="image"
           :class="{expanded: imageExpanded}"
           :src="hotel.sample? hotel.company.image.url: formattedImageUrl" alt="">
      <div class="columns">
        <div class="main column">
          <div>
            <span class="hotel-name label">{{hotel.company.name}}</span>
            <rating class="data" :rate="hotel.rating" v-if="hasColumn('rating')">
              &nbsp;&middot;&nbsp;
              <template v-if="hasColumn('address')">
                {{address}}
              </template>
            </rating>
            <div class="data hotel-type" v-else-if="hasColumn('address')">
              <div class="address">{{address}}</div>
            </div>
          </div>
          <a :href="directionsLink" target="_blank" class="button">
            <i class="material-icons">location_on</i>
            Directions ({{parseFloat(hotel.analytics.distanceMi).toFixed(2)}} Mi)
          </a>
        </div>
        <div class="column amenities inline" v-if="hasColumn('amenities')">
          <div
            class="amenity"
            v-for="amenity in amenitiesData"
            :class="{active: amenities.indexOf(amenity.abbr) >= 0}"
            :key="amenity.abbr">
            <i class="material-icons check">
              {{ amenities.indexOf(amenity.abbr) >= 0 ?'check':'block' }}
            </i>
            <span class="label"> {{amenity.name}}</span>
          </div>
        </div>
        <div class="season-rates column">
          <div class="season" v-for="(season, index) in hotel.seasons" :key="index">
            <div class="title">
              <span class="label">Season 1 ({{formatSeasonDate(season.startDate)}} - {{formatSeasonDate(season.endDate)}})</span>
              <span class="data">{{hotel.currency}}</span>
            </div>
            <div class="rates">
              <template  v-for="(rate, key) in season.rates" >
                <div class="rate" :key="key" v-if="showRate(key)">
                  <span class="label">{{rateLabel(key)}}</span>
                  <span class="data">{{parseFloat(rate).toFixed(2)}}</span>
                </div>
              </template>

            </div>
          </div>
        </div>
        <div class="column" v-if="showDynamicRate && hotel.dynamicRate">
          <span class="label">Dynamic Rate</span>
          <span class="data">{{hotel.dynamicRate}} % Off Best Available</span>
        </div>
        <template
          v-for="column in columns">
          <div
            class="column"
            :key="column.id"
            :class="{[column.id]: true}"
            v-if="hasColumn(column.id) || (column.id === 'code' && includedCode)">
             <span
               v-if="column.id === 'code' && includedCode"
               class="label">{{includedCode.text}}</span>
            <span
              v-else-if="column.id === 'distanceMi'"
              class="label">Distance from {{hotel.travelDestination.name}}</span>
            <span
              v-else-if="column.id !== 'amenities'"
              class="label">{{column.text}}</span>
            <span class="data"
                  v-if="column.id === 'distanceMi'">{{getValue(column.key).toFixed(2)}} Mi
            </span>
            <span class="data"
                  v-else-if="column.id === 'code'">{{includedCodeValue}}
            </span>
            <span class="data" v-else-if="column.id !== 'amenities'">
              {{getValue(column.key) || "-"}}
            </span>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Rating from 'vRoot/hotel-directory/_core/rating.vue';
import Amenities, { amenities } from 'vRoot/hotel-directory/_core/amenities.vue';

import { get } from 'lodash';
import * as moment from 'moment';

import icon from 'root/assets/icons/rate.svg';

export default {
  name: 'HotelDirectoryHotel',
  props: ['hotel', 'view', 'date', 'mock'],
  components: { Rating, Amenities },
  data() {
    return {
      open: false,
      columns: [
        {
          id: 'distanceMi',
          text: 'Distance',
          key: 'analytics.distanceMi',
        },
        {
          id: 'phone',
          text: 'Phone Number',
          key: 'company.phone',
        },
        {
          id: 'code',
        },
        {
          id: 'amenities',
          text: 'Amenities',
          key: 'amenities',
        },
      ],
      imageExpanded: false,
      rate_icon: icon,
      propertyCodes: [
        {id: 'AMADEUS_PROPCODE', text: "Amadeus Code", value: 'amadeuscode'},
        {id: 'APOLLO_PROPCODE', text: "Apollo Code", value: 'apollocode'},
        {id: 'SABRE_PROPCODE', text: "Sabre Code", value: 'sabrecode'},
        {id: 'WRLDSPAN_PROPCODE', text: "WorldSpan Code", value: 'worldspancode'}
      ],
      amenitiesData: amenities
    };
  },
  computed: {
    amenities() {
      return this.hotel.amenities;
    },
    formattedImageUrl() {
      if (this.hotel.company.image) {
        return this.hotel.company.image.url.startsWith('http') ?
          this.hotel.company.image.url : `/images/entities/${this.hotel.company.image.url}`;
      }
      return '/images/hotel_placeholder.png';
    },
    rates() {
      const season = this.mock ? this.hotel.seasons[0] : this.hotel.seasons.find(ss =>
        moment(new Date(this.date)).isBetween(ss.start, ss.end, null, '[]'));
      return season && season.rates;
    },
    bestRate() {
      const values = this.rates &&
        this.view.rates.map(rate => +this.rates[rate]).filter(value => value);
      const best = Math.min(...values);
      return best && parseFloat(+best).toFixed(2);
    },
    showDynamicRate(){
      return this.showRate('DYN');
    },
    address() {
      let address = '';
      address += this.getValue('company.location.address.address1')?
        `${this.getValue('company.location.address.address1')},` : '';
      address += this.getValue('company.location.address.city')?
        ` ${this.getValue('company.location.address.city')},` : '';
      address += this.getValue('company.location.address.state')?
        ` ${this.getValue('company.location.address.state')},` : '';
      address += this.getValue('company.location.address.countryName')?
        ` ${this.getValue('company.location.address.countryName')}` : '';
      return address;
    },
    includedCode() {
      return this.propertyCodes.find(code => this.view.columns.indexOf(code.value) > -1);
    },
    includedCodeValue() {
      return this.includedCode && this.hotel.propertyCodes && this.hotel.propertyCodes[this.includedCode.id];
    },
    directionsLink() {
      let url = `://maps.google.com/maps?daddr=${makeCoordinates(this.hotel.company.coordinates)}&saddr=${makeCoordinates(this.hotel.travelDestination.coordinates)}`;
      if( (navigator.platform.indexOf("iPhone") !== -1)
        || (navigator.platform.indexOf("iPod") !== -1)
        || (navigator.platform.indexOf("iPad") !== -1)){
        url = `maps${url}`;
      } else {
        url = `https${url}`;
      }

      return url;

      function makeCoordinates({latitude, longitude}) {
        return `${latitude},${longitude}`;
      }
    },
  },
  methods: {
    hasColumn(id) {
      return this.view.columns.indexOf(id) >= 0;
    },
    toggle() {
      this.open = !this.open;
    },
    getValue(path) {
      return get(this.hotel, path) || '-';
    },
    rate(id) {
      return this.view.rates.find(rate => rate === id)
        && this.rates[id] && parseFloat(this.rates[id]).toFixed(2);
    },
    rateLabel(rate) {
      const [roomType, rateType] = rate.split('');
      const roomTypes = {S: "Standard", U: 'Upgraded', D: 'Deluxe'};
      const rateTypes = {S: 'Single', D: 'Double'};
      return `${roomTypes[roomType]} ${rateTypes[rateType]}`;
    },
    formatSeasonDate(date) {
      return moment(date).format('DD/mm/YYYY');
    },
    showRate(rate) {
      return this.view.rates.indexOf(rate) > -1;
    },
  },
};
</script>
<style lang="stylus">
  .hotel{
    border-radius 5px;
    background #fff;
    overflow: hidden;
    display: flex;
    position relative;
    transition all .3s;
    margin 3px 0;
    min-width: 50vw;

    .image{
      height: 90px;
      width: 90px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      object-fit: cover;
      margin 1px;
    }

    .columns{
      flex 1;
      min-width 0;
      color: #323c43;
      overflow hidden;
      white-space nowrap;
      text-overflow ellipsis;
      display flex;
    }

    .column{
      padding 10px 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      box-sizing border-box;
      display flex;
      flex-direction column;
      justify-content space-evenly;
      border-right 1px solid #ECEFF1;


      &.main{
        width 400px;
        flex 1;
        min-width 150px;
      }

      &.amenities{
        width 240px;
        min-width 240px;
      }

      &.phone{
        min-width 130px;
        width 130px;
      }

      .label{
        color #90A4AE;
        text-transform uppercase;
        font-size 12px;
      }

      .hotel-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size 17px;
        color #37474F;
      }

      .data{
        display: flex;
        align-items: center;
        overflow hidden;
        color #546E7A;
        font-size 13px;

        &.hotel-type{
          justify-content flex-start;
        }

        .address{
          padding: 0;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &:not(.open){
      cursor pointer;

      .rate{
        position: relative;

        .value{
          color #546E7A;
          font-size 20px;
          font-weight 600;
        }
        i{
          color #90A4AE;
        }
        .data {
          justify-content flex-end;
        }

        .dyn{
          position absolute;
          right 0;
          bottom 0;
          padding-right: 10px;
        }
      }


      .column:not(.main) {
        width 140px;

        .label{
          text-align center;
        }

        .data{
          justify-content center;
        }

        &.rate{
          max-width: 212px;
          min-width: 212px;
          align-items: flex-end;
          padding: 10px;
        }

        &.distanceMi{
          max-width: 88px;
          min-width: 88px;
        }

        &.code{
          max-width: 110px;
          min-width: 110px;
        }
      }

      @media screen and (max-width: 751px) {
        border-radius 0 !important;
        .image{
          border-radius 0 !important;
        }

        .column:not(.sm){
          display none;
        }

        .rate{
          min-width 0 !important;
          width fit-content !important;
          .label{
            white-space normal;
            text-align right;
          }
        }

        .main{
          min-width 0;
        }
      }
    }

    &.open{
      .image{
        height: 200px;
        width: 200px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 5px;
        transition height .3s;
      }

      .columns{
        flex-direction column;

        .column{
          border-right 0;
          white-space normal;
          width auto;
          padding-right 25px;

          &.main{
             display: flex;
             flex-direction: row;
             align-items: center;
             justify-content: space-between;

            .button {
              i.material-icons{
                color: #e0161f;
              }
            }

            .hotel-name{
              white-space unset;
            }
           }

          &:not(.main){
            flex-direction row;
            justify-content space-between;
          }

          &.season-rates{
            padding: 0 10px;
            display: flex;
            flex-wrap: wrap;

            .season{
              min-width 240px;
              flex: 1;
              margin 5px;

              .title{
                font-weight bold;
                justify-content space-between;
                display flex;
                align-items center;
                padding 5px;
              }

              .rates{
                border: 1px solid #eceff1;
                border-radius: 3px;

                .rate {
                  border-bottom 1px solid #ECEFF1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 5px;

                  &.best .label{
                    color #40b6a7;
                  }

                  .label{
                    display: flex;
                    align-items: center;
                  }

                  .data{
                    font-size 13px;
                    font-weight 600;
                  }

                  &.best{
                    .data{
                      font-size 20px;
                    }
                  }
                }
              }
            }
          }

          &.amenities.inline{
            flex-wrap: wrap;
            justify-content: flex-start !important;
            padding: 5px 15px;

            .amenity{
              padding: 5px 0;
              flex: unset;
              width: fit-content;
              border: none;

              & + .amenity {
                margin-left 5px;
              }

              i.material-icons.check{
                font-size: 15px;
              }

              .label{
                padding 0;
              }

              &:not(.active){
                i.material-icons.check{
                  color: #91a5af;
                }
              }
            }
          }

          .hotel-type {
            .name{
              overflow: visible;
              white-space normal;
            }
          }
        }
      }

      .actions{
        position: absolute;
        right: 0;
        top: 5px;

        i{
          height 30px;
          width 30px;
          box-sizing border-box;
          cursor pointer;
          font-size: 24px;

          & + i{
            margin-left 5px;
          }
        }
        .screen{
          display none;
        }
      }

      .logo{
        height: 60px;
        width: 100px;
        border-radius 0;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 10px;
        object-fit: cover;
        position absolute;
        top 0;
        left 0;
      }

      @media screen and (max-width: 501px) {
        display block;
        border-radius 0;

        .data{
          flex-wrap wrap;
        }

        .image{
          width: calc(100% - 2px);
          height 100px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 5px;

          &.expanded{
            height 300px;
          }
        }

        .logo{
          width 120px;
          height 80px;
        }

        .actions {
          i{
            background rgba(0,0,0,0.5);
            color #fff;
            padding 3px;
            border-radius 100%;
          }

          .screen{
            display inline-block;
          }
        }

        .main{
          flex-direction column;
        }
      }
    }
  }
</style>
