/**
 * Created by DejanK on 7/8/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-tcos-history.scss');

module.exports = {
  template: require('./rb-negotiations-tcos-history.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    tcosId: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsHtmlFactory'];
function Ctrl($element, NegotiationsHtmlFactory) {
  let vm = this, tooltipster,
    htmlContentElement, parentElement, tableElement;

  this.$onInit = ()=>{
    parentElement = $element.parent();
    htmlContentElement = $($element.children()[0]);
    tableElement = $(htmlContentElement.find('div.table'));

    parentElement.tooltipster({
      theme: ['', 'negotiations-tcos-history'],
      content: htmlContentElement,
      contentAsHtml: true,
      distance: 0,
      interactive: true,
      functionBefore: printHistory,
      functionReady: scrollToActive
    });

    tooltipster = parentElement.tooltipster('instance');
  };

  this.$onChanges = ()=>{
    updateVM();
  };

  this.$onDestroy = ()=>{
    tooltipster.destroy();
  };

  function updateVM(){
    if(!checkRequirements()) return;
    let ids = vm.tcosId.split('_');
    vm.seasonId = ids[1].replace(/[^0-9]/g, '');
    vm.seasonPeriod = vm.negotiations.seasons.getFormattedPeriod(vm.seasonId);
    vm.roomTypeId = ids[2].replace(/[^0-9]/g, '');
    vm.roomType = vm.negotiations.roomTypes.print(vm.roomTypeId);
  }

  function printHistory(){
    if(!checkRequirements()) return;

    let historyHtml = NegotiationsHtmlFactory.printTcosHistory(vm.negotiations.communication, vm.tcosId, vm.negotiation);
    tableElement.empty();
    tableElement.append(historyHtml);
  }

  function checkRequirements(){
    return vm.negotiations && vm.tcosId;
  }

  function scrollToActive(){
    let activeElements = tableElement.find('div.layout-row.active');
    if(activeElements.length) {
      scrollTo(tableElement, $(activeElements[0]));
    } else {
      tableElement[0].scrollTop = tableElement[0].scrollHeight;
    }

    function scrollTo(container, scrollTo){
      container.scrollTop(
        scrollTo.offset().top - container.offset().top + container.scrollTop() - (container.height()/2)
      );
    }
  }
}
