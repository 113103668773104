/**
 * Created by DejanK on 7/3/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-panel-header.scss');

module.exports = {
  template: require('./rb-negotiations-panel-header.html'),
  transclude: true,
  bindings: {
    side: '<',
    negotiations: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl() {
  let vm = this;

  this.$onChanges = (changes)=>{
    (changes.side || changes.negotiations) && init();
  };

  function init(){
    if(!(vm.side && vm.negotiations)) return;
    let otherSideLastResponse = vm.negotiations.getOtherSideLastResponse(vm.side);
    vm.companyName = otherSideLastResponse.getCompanyName();
    vm.negotiatorName = otherSideLastResponse.getContactName();
    vm.dateTime = otherSideLastResponse.getFormattedDateTime();
  }
}
