<template>
  <rate
    :is-available="isAvailable"
    :value="formattedValue"
    :is-selected="isSelected"
    :toggle="toggleRate" />
</template>

<script>
  import Rate from './Rate.vue';

  export default {
    components: { Rate },
    props: {
      rate: { default: '' },
      occupancy: {default: '' },
      season: {default: '' },
      roomType: {default: '' },
      toggle: { default: null },
      answers: { default: {} },
      selected: { default: [] }
    },
    computed: {
      id () {
        return `${this.rate}_S${this.season}_RT${this.roomType}_${this.occupancy}`.toUpperCase();
      },
      isSelected () {
        return this.selected.indexOf(this.id) !== -1;
      },
      value () {
        return this.answers[this.id];
      },
      formattedValue () {
        return `$${this.value}`;
      },
      isAvailable(){
        const v = parseInt(this.value, 10);
        return !( isNaN(v) || v === 0);
      }
    },
    methods: {
      toggleRate () {
        this.toggle(this.id);
      }
    }
  }
</script>
