/**
 * Created by DejanK on 4/12/2018.
 *
 * rbUrlInputHelper
 * Adds http as default protocol to ngModel.$modelValue if protocol is missing from ngModel.$viewValue
 */

export default function() {
  return {
    require: 'ngModel',
    link: (s, e, attrs, ngModelCtrl) => {
      ngModelCtrl.$parsers.push((viewValue) => {
        return viewValue.match(/^[a-zA-Z]+?:\/\//) ? viewValue : `http://${viewValue}`
      })
    }
  }
}
