import HTML from './custom-rfp.html'
import ContactUsControllerMixin from '../contact-us/contact-us.ctrl.mixin'

export { CustomRfpController as Controller, HTML }

CustomRfpController.$inject = ['NotificationUtils', 'AuthAPI', '$state'];
function CustomRfpController(notificationUtils, authAPI, $state) {
  const vm = this
  vm.utils = notificationUtils
  vm.$state = $state
  vm.save = () => authAPI.sendCustomRfpMessage(vm.message)
  ContactUsControllerMixin(vm)
}
