import Moment from 'moment'

export { formatDate }

/**
 *
 * @param dateString ISO-8601
 */
function formatDate(dateString, format, defaultValue) {
  const m = Moment(dateString);
  return m.isValid() ? m.format( format || 'YYYY/MM/DD' ) : defaultValue || ''
}
