<template>
  <div>
    <div
      class="row"
      :class="{ selected: row.added, [$style.notInDirectory]: !row.added, [$style.row]: true }"
    >
      <template v-if="!updating">
        <check-box
          v-if="canAdd"
          :value="row.added"
          :check-box-value="true"
          class="cell checkboxCell dark-icon"
          @input="toggle"
        />
        <div
          v-else
          class="cell checkboxCell"
          :class="$style.invalidIcon"
          @click="openFinalAgreement"
        >
          <i class="material-icons">warning</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:top-start"
          >
            We have detected that this final agreement contains rates and/or seasons that are invalid. <br>
            Thus it can not be added to the hotel directory. Click here to view this bid in order to fix this error
          </div>
        </div>
      </template>
      <rbv-loading
        v-else
        class="cell checkboxCell"
        :class="$style.loading"
        rb-class="medium"
      />
      <div
        v-for="(column, i) in tableColumns"
        :key="column"
        class="cell"
        :style="getColumnStyles(i)"
      >
        <div v-if="is(column, 'distanceMi')">
          {{ getColumnValue(column) | distance }} Mi
        </div>
        <div
          v-else-if="isAmenity(column)"
          :class="row.amenities.indexOf(column) > -1?$style.amenity: $style.amenityAbsent"
        >
          <i class="material-icons">{{ amenity(column).icon }}</i>
          <span class="label"> {{ row.amenities.indexOf(column) > -1 ? column : 'N/A' }} </span>
        </div>
        <div
          v-else-if="is(column, 'rates')"
          :class="$style.clickable"
          @click="showRates"
        >
          <span><template v-if="rateShown">{{ rateShown }}</template></span>
        </div>
        <div
          v-else-if="is(column, 'code')"
        >
          <span><template v-if="propertyCodeShown">{{ propertyCodeShown }}</template></span>
        </div>
        <rb-rating
          v-else-if="is(column, 'rating')"
          :rate="getColumnValue(column)"
          :class="{[$style.hotelType]: true, dark: !row.added}"
        />
        <div v-else>
          {{ getColumnValue(column) }}
        </div>
      </div>
      <div
        class="cell checkboxCell plus"
        @click="openRow"
      >
        <i class="material-icons">{{ !open? 'add': 'remove' }}</i>
      </div>
    </div>
    <div
      class="wrapped-cells"
      :class="{open}"
    >
      <template v-for="column in wrappedColumns">
        <div
          v-if="is(column, 'distanceMi')"
          class="wrapped-cell"
        >
          <div class="label">
            {{ getColumnLabel(column).text }}
          </div>
          <div
            :key="column"
            class="value"
          >
            {{ getColumnValue(column) | distance }} Mi
          </div>
        </div>
        <div
          v-else-if="isAmenity(column)"
          class="wrapped-cell"
        >
          <div class="label">
            {{ amenity(column).name }}
          </div>
          <div
            :key="amenity.abbr"
            class="value"
          >
            <div :class="row.amenities.indexOf(column) > -1?$style.amenity: $style.amenityAbsent">
              <i class="material-icons">{{ amenity(column).icon }}</i>
              <span class="label"> {{ row.amenities.indexOf(column) > -1 ? column : 'N/A' }} </span>
            </div>
          </div>
        </div>
        <div
          v-else-if="is(column, 'rating')"
          class="wrapped-cell"
        >
          <div class="label">
            {{ getColumnLabel(column).text }}
          </div>
          <rb-rating
            :key="column"
            class="value"
            :rate="getColumnValue(column)"
            :class="$style.hotelType"
          />
        </div>
        <div
          v-else-if="is(column, 'rates')"
          class="wrapped-cell"
          :class="$style.wrappedRate"
        >
          <div class="label">
            {{ shownRateLabel }}
          </div>
          <div class="value">
            <div
              :class="$style.rate"
              @click="showRates"
            >
              <template v-if="rateShown">
                {{ rateShown }}
              </template>
            </div>
          </div>
        </div>
        <div
          v-else-if="is(column, 'code')"
          class="wrapped-cell"
        >
          <div class="label">
            {{ shownRateLabel }}
          </div>
          <div class="value">
            <div>
              <template v-if="propertyCodeShown">
                {{ propertyCodeShown }}
              </template>
            </div>
          </div>
        </div>
        <div
          v-else
          class="wrapped-cell"
        >
          <div class="label">
            {{ getColumnLabel(column).text }}
          </div>
          <div
            :key="column"
            class="value"
          >
            {{ getColumnValue(column) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import RbRating from '../../_core/rating.vue';
import {get} from 'lodash';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import hotelDirectoryClientStore from '../hotel-directory-client-store';
import HotelRatesDialog from '../../dialogs/HotelRatesDialog.vue';
import {Dialog} from 'root/v-app/rbServices';
import RbvLoading from 'vRoot/_core/RbvLoading.vue';

export default {
    name: 'HotelDirectoryTableRow',
    components: {RbRating, CheckBox, RbvLoading},
    filters: {
        distance(value) {
            return Math.round(value * 100) / 100;
        }
    },
    props: ['row', 'columns', 'rate', 'visibleColumns', 'open', 'visibleColumnStyles', 'updating', 'propertyCode'],
    data(){
        return {
            amenitiesData: [
                {
                    abbr: 'wf',
                    name: 'WIFI',
                    icon: 'wifi',
                },
                {
                    abbr: 'ft',
                    name: 'Fitness',
                    icon: 'fitness_center',
                },
                {
                    abbr: 'ia',
                    name: 'Internet Access',
                    icon: 'offline_bolt',
                },
                {
                    abbr: 'ec',
                    name: 'Early Checkout',
                    icon: 'av_timer',
                },
                {
                    abbr: 'as',
                    name: 'Airport Shuttle',
                    icon: 'airport_shuttle',
                },
                {
                    abbr: 'bf',
                    name: 'Break Fast',
                    icon: 'free_breakfast',
                },
                {
                    abbr: 'prk',
                    name: 'Parking',
                    icon: 'local_parking',
                },
            ],
            rateTypes: [
              {id: 'SS', text: 'Standard Single Rate', name: 'SS Rate'},
              {id: 'SD', text: 'Standard Double Rate', name: 'SD Rate'},
              {id: 'US', text: 'Upgraded Single Rate', name: 'US Rate'},
              {id: 'UD', text: 'Upgraded Double Rate', name: 'UD Rate'},
              {id: 'DS', text: 'Deluxe Single Rate', name: 'DS Rate'},
              {id: 'DD', text: 'Deluxe Double Rate', name: 'DD Rate'},
              {id: 'DYN', text: 'Dynamic Rate', name: 'DYN Rate'},
            ],
        }
    },
    computed : {
        amenities(){
            return this.getColumnValue('amenities')
        },
        rateShown() {
            if(this.rate === 'DYN'){
                return this.row.dynamicRate && `${this.row.dynamicRate}% off Best Available`;
            }
            return this.row.seasons[0]
                && this.row.seasons[0].rates[this.rate]
                && `${parseFloat(this.row.seasons[0].rates[this.rate]).toFixed(2)} ${this.row.currency}`;
        },
        propertyCodeShown() {
          return this.propertyCode && this.row.propertyCodes[this.propertyCode];
        },
        shownRateLabel() {
            return this.rateTypes.find(type => type.id === this.rate).text;
        },
        canAdd() {
          return this.row.valid;
        },
        tableColumns() {
          return this.columns.slice(0, +this.visibleColumns);
        },
        wrappedColumns() {
          return this.columns.slice(+this.visibleColumns);
        },
    },
    methods: {
        getColumn(id){
            return hotelDirectoryClientStore.getDefaultManageHotelsColumn(id);
        },
        is(src, dest){
            return src === dest;
        },
        isAmenity(id) {
          const column = this.getColumn(id);
          return column && column.isAmenity;
        },
        getColumnValue(id){
            const column = this.getColumn(id);
            if(Array.isArray(column.key)){
                for(const i in column.key) {
                    if(get(this.row, column.key[i])) {
                        return get(this.row, column.key[i]);
                    }
                }
            }else {
                return get(this.row, column.key);
            }
        },
        toggle(){
            this.$emit(this.row.added? 'remove': 'add', this.row);
        },
        showRates() {
          return Dialog.show(HotelRatesDialog, {hotel: this.row});
        },
        openFinalAgreement() {
          this.$emit('openFinalAgreement', this.row.bidId);
        },
        openRow(){
          this.$emit('open', this.open? null: this.row.bidId);
        },
        getColumnLabel(column) {
          return hotelDirectoryClientStore.getDefaultManageHotelsColumn(column);
        },
        getColumnStyles(i) {
          return this.visibleColumnStyles[i];
        },
        amenity(id) {
          return this.amenitiesData.find(amenity => amenity.abbr === id);
        }
    }
}
</script>

<style lang="stylus" module>
    .amenity{
        display flex;
        align-items center;
        font-weight bolder;
        /*color #37474F;*/
    }

    .amenity i{
        width 25px;
    }

    .amenity span{
        text-transform uppercase;
    }

    .amenityAbsent{
        composes amenity;
        color #cfd8dc;
    }

    .hotelType{
        height 15px !important;
    }

    .clickable{
      cursor pointer;
      overflow: visible !important;
    }

    .invalidIcon{
      color: rgba(242,60,24,0.8) !important;
      justify-content: center !important;
      cursor: pointer;
      min-width: 40px !important;
    }

    .notInDirectory{
      background #e6e9eb;
      color: #77909d !important;
    }

    .notInDirectory .amenityAbsent{
      color #76909e5e;
    }

    .loading{
      padding 0 0 0 5px !important;
    }

    .clickable span, .wrappedRate .rate{
      min-width: 20px;
      min-height: 17px;
      cursor: pointer;
      border-radius: 20px;
    }

    .row:hover .clickable span, .wrappedRate:hover .rate{
      background #fff;
      border: 1px solid;
      padding: 5px;
    }

</style>
