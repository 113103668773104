import Vue from 'vue'
import HotelDirectory from './HotelDirectory.vue';
import {CURRENT_USER_CHANGED_EVENT} from 'root/auth/current-user.service';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'CurrentUser', '$scope'];
function Ctrl($element, currentUser, $scope){
  const vm = this;
  let vueComponent;

  vm.$onInit = () => {
    vm.user = null;
    $scope.$on(CURRENT_USER_CHANGED_EVENT, (event, user) => {
      vm.user = user;
      initVueComponent();
    });
    currentUser.get().then(initVueComponent);
  };

  vm.$onDestroy = () => { return vueComponent && vueComponent.$destroy() };

  function initVueComponent(user = null){
    user && (vm.user = user);
    return !vueComponent && initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { user: vm.user},
        render: function (createElement) {
          return createElement(HotelDirectory, {
            props: {
              user: vm.user
            },
          })
        }
      })
    }
  }
}
