<script type="text/jsx">
  import TooltipContainer from 'vRoot/_core/layouts/TooltipContainer.vue';
  import RbButtonWithMaterialIcon from 'vRoot/_core/buttons/ButtonWithMaterialIcon.vue';

  export default {
    functional: true,
    render (h, { listeners, $style }) {

      return <TooltipContainer boundary="window" scopedSlots={{

        default: () => <RbButtonWithMaterialIcon onClick={listeners.click} scopedSlots={{
          default: () => 'Finalize With Entered',
          icon: () => 'check_circle' }} />,

        tooltip: () => <div class={ $style.container }>
          Finalize negotiations with rates and/or amenities you just entered. Keep in mind that this action ALWAYS
          assumes supplier has agreed to changes. Rates may not be loaded otherwise.
        </div>

      }} />
    }
  }
</script>

<!--suppress CssUnusedSymbol -->
<style module>

  .container {
    max-width: 300px;
  }

</style>
