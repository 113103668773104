import {
  httpErrorHandler,
  rfpManagerRepository,
  notificationUtils
} from 'root/angular-injector-provider';
import {getAssignee} from './rfp-manager-table-grouper-service';

function listRfps(accounts) {
  return new Promise((resolve, reject) => rfpManagerRepository()
      .listRFPs(accounts.map(({account}) => account.id))
      .then(({data: {result}}) => {
        resolve(result.map(rfp => {
          return {
            ...rfp,
            rfp: {
              ...rfp.rfp,
              assignee: getAssignee(rfp.rfp, accounts)
            }
          };
        }));
      }).catch(reject)).catch(httpErrorHandler().handle);
}

function listAccounts() {
  return new Promise((resolve, reject) =>
    rfpManagerRepository()
      .listCompanies()
      .then(({data: {companies}}) => resolve(companies)).catch(reject)).catch(httpErrorHandler().handle)
}

function updateLaunchDate(id, time) {
  return notificationUtils().onLoad(() => rfpManagerRepository().updateLaunchDate(id, time));
}

export  default {
  listRfps,
  listAccounts,
  updateLaunchDate,
};
