<template>
  <div class="pageWithHeaderBody userBackground layout-column">
    <div :class="$style.accountProfileContainer">
      <component
        :is="activeModuleComponent"
        v-if="activeModuleComponent"
        :state="state"
        :class="$style.accountProfileContent"
      />
    </div>
  </div>
</template>

<script>
  import accountProfileService from 'vRoot/account-profile/accountProfileService';
  import {PAGE_RFP_MANAGER_COMPANY_PROFILE} from 'root/states';

  export default {
    name: 'RbvNgRfpManagerUserProfileComp',
    props: {
      accountId: {
        required: true,
        type: String
      },
      accountType: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        state: PAGE_RFP_MANAGER_COMPANY_PROFILE
      }
    },
    asyncComputed:{
      activeModuleComponent () {
        return accountProfileService.initialize(this.accountType, this.accountId)
          .then( module => module && module.component )
      },
    },
  }
</script>

<style lang="stylus" module>

  .accountProfileContainer {
    box-sizing: border-box;
    flex: 1 1 100%;
    margin: 20px auto;
    display: flex;
    max-width: 95%;
  }

  .accountProfileContent {
    background: white;
    min-width : 800px;
    border-radius : 6px;
    box-shadow : 0 0 7.53px rgba(0, 0, 0, 0.18);
  }

</style>
