/**
 * Created by DejanK on 12/28/2017.
 */

import HTML from './rb-confirm-authorization.html'

export default {
  template: HTML,
  require: {
    modelCtrl: 'ngModel'
  },
  bindings: {
    userName: '<',
    accountName: '<',
    showError: '<'
  },
  controller: Controller
}

function Controller(){
  const vm = this

  vm.onConfirmChanged = () => {
    vm.modelCtrl.$setTouched()
    vm.modelCtrl.$setDirty()
    vm.modelCtrl.$setViewValue(vm.confirm)
  }
  vm.onConfirmBarClicked = () => {
    vm.confirm = !vm.confirm
    vm.onConfirmChanged()
  }

  this.$onInit = () => {
    vm.modelCtrl.$isEmpty = function(value) { return value !== true }

    vm.modelCtrl.$render = function(){ vm.confirm = vm.modelCtrl.$modelValue }
  }

}
