import {checkAndExecute} from "root/api/helpers";

export default function HotelRfpBidResponseAPI(api) {

  function exportDataWithAutoSave(exportData){
    return api.server.postAndAutoSaveFile(`rfps/hotel/bids/export`, exportData)
  }

  Object.assign(api, {

    hotelRfpExportFinalAgreementsToExcel: (bidsIds, includeUnsent) => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:FINAL_AGREEMENT:EXCEL', bids: bidsIds, options: { includeUnsent: !!includeUnsent }})),

    hotelRfpExportBidManagerToExcel: (bidsIds, columns) => checkAndExecute(
      () => bidsIds && bidsIds.length && columns && columns.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:BID_MANAGER:EXCEL', bids: bidsIds, options: {columns: columns}})),

    hotelRfpExportGbtaToExcel: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:GBTA:EXCEL', bids: bidsIds})),

    hotelRfpExportGbtaToCsv: bidsIds => checkAndExecute(
      () => bidsIds && bidsIds.length,
      () => exportDataWithAutoSave({name: 'HOTEL_RFP:GBTA:CSV', bids: bidsIds})),

  })
}
