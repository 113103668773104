/**
 * Created by DejanK on 8/3/2017.
 *
 * rbHasNumber
 *
 * Validates that input has number
 */

'use strict';

module.exports = rbHasNumber;

function rbHasNumber() {
  return {
    require: 'ngModel',
    link: (s, e, attrs, ngModelCtrl)=>{
      ngModelCtrl.$validators.rbHasNumber = (modelValue, viewValue)=>{
        let pattern = /(?=\S*?[0-9])/;
        return pattern.test(modelValue || viewValue);
      }
    }
  }
}
