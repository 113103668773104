<template>
    <div :class="$style.directory" v-if="loaded">
        <preview-bar
            :groupers="groupers"
            :filters="filters"
            :groupBy="groupBy"
            :sortBy="sortBy"
            :filterBy="filterBy"
            :grouper="grouper"
            :filter="filter"
            :sorter="sorter"
            :date="date"
            :can-filter="canFilter"
            :travel-policy="directory.travelPolicy"
            @groupBy="groupBy = $event"
            @filterBy="filterBy = $event"
            @sortBy="sortBy = $event"
            @date="date=$event"
            @destination="destination = $event"/>

        <div :class="$style.background" :style="background">
            <div :class="$style.content">
                <hotel-directory-header
                    :header="activeView.header"
                    :sub-header="activeView.subHeader"
                    :warning="activeView.warning"
                    :show-sub-header="activeView.showSubHeader"
                    :show-warning="activeView.showWarning"
                    :logo="account.logo"
                    :edit="editAllText"
                    :preview-mode="previewMode"
                    @update="update"
                />
                <hotels :view="activeView" :hotels="sortedAndGrouped" :group-by="groupBy" :date="date" :mock-rate="mockRate" />
            </div>
        </div>
        <background-editor
            v-if="!previewMode"
            @close="toggleBg"
            @open="toggleBg"
            :open="editingBg"
            :full="expanded"
            :value="activeView.background"
            @input="updateBg"
        />
    </div>
</template>

<script>
import HotelDirectoryHeader from './HotelDirectoryHeader.vue';
import RbvLoading from "vRoot/_core/RbvLoading.vue";
import BackgroundEditor from './HotelDirectoryViewBackgroundEditor.vue';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import RbvDropdown from 'vRoot/_core/RbDropdown.vue';
import Hotels from './hotels-list.vue';
import PreviewBar from './HotelDirectoryPreviewBar.vue';

import { Dialog } from "root/v-app/rbServices";
import ManageContentDialog from 'vRoot/hotel-directory/dialogs/ManageContentDialog.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import outgoingMessageDialog from 'vRoot/hotel-directory/modules/manageUsers/upload-dialogs/outgoingMessageDialog.vue';
import DirectoryPreviewDialog from 'vRoot/hotel-directory/dialogs/tutorialDialog.vue';

import hotelDirectoryService from '../../hotel-directory-service';
import { isEqual, get, orderBy, fill } from 'lodash';
import * as moment from 'moment';

import {
  EventBus,
  SEND_PREVIEW_EVENT,
  EDIT_BACKGROUND_EVENT,
  EDIT_HEADER_EVENT,
  EDIT_WARNING_EVENT,
  EDIT_SUBHEADER_EVENT,
  SHOW_SUBHEADER_EVENT,
  SHOW_WARNING_EVENT,
  EDIT_VIEW_EVENT
} from 'vRoot/hotel-directory/_core/EventBus';

export default {
    name: 'HotelDirectoryPreview',
    props: ['expanded'],
    components: {
        HotelDirectoryHeader,
        RbvLoading,
        BackgroundEditor,
        CheckBox,
        RbvDropdown,
        Hotels,
        PreviewBar
    },
    data(){
        return {
            activeView: null,
            editingBg: false,
            editAllText: '',
            previewMode: this.expanded,
            editOptionsDropDownOpen: false,
            hotels: [],
            groupers: [
              {
                id: null,
                text: 'None',
              },
              {
                id: 'td',
                text: 'Travel Destinations',
                key: 'travelDestination.name',
              },
              {
                id: 'city',
                text: 'Hotel City',
                key: 'company.address.city',
              },
              {
                id: 'state',
                text: 'State/Region',
                key: ['company.address.state', 'company.address.region'],
              },
              {
                id: 'country',
                text: 'Country',
                key: 'company.address.countryName',
              },
              {
                id: 'brand',
                text: 'Hotel Brand',
                key: 'company.chain.name',
              },
              {
                id: 'chain',
                text: "Hotel Chain",
                key: 'company.chain.masterChainName'
              },
              {
                id: 'rating',
                text: 'Hotel Type',
                key: 'rating',
              },
              {
                id: 'distance',
                text: 'Distance',
                key: 'analytics.distanceMi',
              },
              {
                id: 'bestRate',
                text: 'Best Rate',
                key: this.bestRate,
              },

            ],
            groupBy: 'td',
            sortBy: {id: null},
            filterBy: '',
            destination: null,
            date: null,
            hotelTypes: [
              { id: 'E', name: 'Economy Hotels' },
              { id: 'M', name: 'Midscale Hotels' },
              { id: 'UM', name: 'Upper Midscale Hotels' },
              { id: 'U', name: 'Upscale Hotels' },
              { id: 'UU', name: 'Upper Upscale Hotels' },
              { id: 'L', name: 'Luxury Hotels' },
            ],
        }
    },
    asyncComputed: {
        account(){ return hotelDirectoryService.getAccount().then(account => account) },
        directory() { return hotelDirectoryService.getDirectory().then(directory => directory)},
        userAccount() { return hotelDirectoryService.getUserAccount().then(act => act)},
        user() { return hotelDirectoryService.getCurrentUser().then(user => {
          this.$nextTick(() => this.showPreviewDialog(user));
          this.$nextTick(() => hotelDirectoryService.showOptInDialog());
          return user;
        })},
        // destinations() { return hotelDirectoryService.fetchTravelDestinations().then(destinations => destinations)}
    },
    mounted(){
        const view = hotelDirectoryService.getActiveView();
        if(!view){
            hotelDirectoryService.openView();
        }else{
            this.activeView = view;
            this.loadHotels();
            this.setupEvents();
            this.togglePreview();
        }
    },
    computed: {
        loaded(){
            return this.activeView && this.account;
        },
        background(){
            const style = {
                height: 'calc(100vh - 149px)'
            };

            if(this.activeView.background){
                if(this.activeView.background.type === "COLOR"){
                    style["background-color"] = this.activeView.background.value
                }else{
                    style["background-image"] = `url(${this.activeView.background.value})`
                }
            }

            return style;
        },
        canSave(){
            return !isEqual(hotelDirectoryService.getActiveView(), this.activeView);
        },
        grouper() {
          return this.groupers.find(group => group.id === this.groupBy);
        },
        sorter() {
          const s = this.groupers.find(group => group.id === this.sortBy.id);
          s.order = this.sortBy.order;
          return s;
        },
        filter() {
          const filter = this.filters.find(f => f.id === this.filterBy);
          return filter || {
            id: null,
            text: 'None',
          };
        },
        filteredHotels() {
          let { hotels } = this;
          hotels = !this.previewMode? hotels: hotels.filter((hotel) => {
            let valid = true;
            if (this.destination) {
              valid = valid && hotel.travelDestination.id === this.destination;
            }

            if (this.date) {
              const season = hotel.seasons.find(ss =>
                moment(new Date(this.date)).isBetween(ss.start, ss.end, null, '[]'));
              valid = valid && season && season.rates;
            }

            if (this.filter.id !== null && this.canFilter) {
              if(this.groupBy === 'bestRate'){
                valid = valid && this.bestRate(hotel) === this.filter.value;
              }else{
                valid = valid && get(hotel, this.grouper.key) === this.filter.value;
              }
            }
            return valid;
          });
          return hotels;
        },
        filters() {
          let filters = [];
          if (this.grouper.id !== null) {
            filters = this.hotels
              .map((hotel, index) => {
                const {value, text} = this.makeFilter(hotel);
                return {
                  id: (value + '') + index,
                  text,
                  value,
                };
              })
              .reduce((value, filter) => {
                if (value.find(v => v.text === filter.text)) {
                  return value;
                }
                return value.concat(filter);
              }, []);
          }
          filters = [{
            id: null,
            text: "None",
          }, ...filters];
          return filters;
        },
        sortedAndGrouped() {
          const sortedHotels = this.sort(this.filteredHotels);
          const groups = {};
          sortedHotels && sortedHotels.forEach((hotel) => {
            const key = this.getGroupKey(hotel);
            groups[key] = groups[key] ? groups[key] : {
              key,
              items: [],
            };
            groups[key].items.push(hotel);
          });
          return Object.values(groups);
        },
        canFilter() {
          return !(this.groupBy === 'td' && this.destination);
        },
        mockRate() {
          return !this.previewMode || (this.previewMode && !this.date);
        }
    },
    watch: {
        expanded(value) {
          this.previewMode = value;
        }
    },
    methods: {
        setupEvents() {
          EventBus.$on(SEND_PREVIEW_EVENT, this.sendPreview);
          EventBus.$on(EDIT_SUBHEADER_EVENT, () => this.editSection('subHeader'));
          EventBus.$on(EDIT_HEADER_EVENT, () => this.editSection('header'));
          EventBus.$on(EDIT_WARNING_EVENT, () => this.editSection('warning'));
          EventBus.$on(EDIT_BACKGROUND_EVENT, this.editBg);
          EventBus.$on(SHOW_WARNING_EVENT, () => this.update({showWarning: !this.activeView.showWarning}));
          EventBus.$on(SHOW_SUBHEADER_EVENT, () => this.update({showSubHeader: !this.activeView.showSubHeader}));
          EventBus.$on(EDIT_VIEW_EVENT, this.editContent);
        },
        save(){
          this.canSave && hotelDirectoryService.updateView(this.activeView).then(this.loadHotels);
        },
        makeFilter(hotel) {
          let value = get(hotel, this.grouper.key);
          let text = value;
          switch (this.groupBy) {
            case 'distance':
              text = (Math.round(value * 100) / 100) + " Mi";
              break;
            case 'rating': {
              const type = this.hotelTypes.find(hotelType => hotelType.id === value);
              text = type ? type.name : 'Hotel Property';
              break;
            }
            case 'bestRate':
              text = value = this.bestRate(hotel);
              break;
            default: break;
          }
          return {text, value};
        },
        getGroupKey(hotel) {
          let key;
          if(this.groupBy === 'bestRate') {
            key = this.bestRate(hotel);
          }else{
            key = this.grouper.key ? get(hotel, this.grouper.key) : null;
          }
          return key;
        },
        rates(hotel) {
          const season = this.mockRate ? hotel.seasons[0] : hotel.seasons.find(ss =>
            moment(new Date(this.date)).isBetween(ss.start, ss.end, null, '[]'));
          return season && season.rates;
        },
        bestRate(hotel) {
          const rates = this.rates(hotel);
          const values = rates &&
            this.activeView.rates.map(rate => +rates[rate]).filter(value => value);
          const best = Math.min(...values);
          return best && `${parseFloat(+best).toFixed(2)} ${hotel.currency}`;
        },
        update(data){
            this.activeView = {
                ...this.activeView,
                ...data
            };
            this.save();
        },
        editContent(){
            Dialog.show(ManageContentDialog, {
                view: this.activeView,
                canDelete: hotelDirectoryService.getViews().length > 1
            }).then(data => {
                if(data.action === 'update') {
                    this.update(data.view);
                }else{
                    Dialog.show(WarningDialog, {
                        title: `Delete ${this.activeView.name} View?`,
                        subTitle: 'Please be aware that deleting this view will eliminate all viewership for this directory and all related functionality.'
                    }).then(() => {
                        hotelDirectoryService.deleteView(this.activeView);
                    });
                }

            });
        },
        togglePreview(){
            this.$emit('preview', this.previewMode);
            // this.previewMode = !this.previewMode;
        },
        toggleBg() {
            this.editingBg = !this.editingBg
        },
        updateBg(background) {
            this.update({background})
        },
        toggleEditOptions(){
            this.editOptionsDropDownOpen = !this.editOptionsDropDownOpen;
        },
        editSection(section){
            if(
                (section === 'warning' && !this.activeView.showWarning) ||
                (section === 'subHeader' && !this.activeView.showSubHeader)){
                return;
            }
            this.editAllText = section;
            this.toggleEditOptions();
        },
        editBg(){
            !this.editingBg && this.toggleBg();
            this.toggleEditOptions();
        },
        loadHotels(){
            hotelDirectoryService.getViewHotels(this.activeView.id).then(hotels => {
                this.hotels = hotels;
            });
        },
        sendPreview() {
            Dialog.show(outgoingMessageDialog, {
                subTitle: 'This action will send your hotel directory link to your email. Edit the message to be atached to the email below',
                message: this.directory.defaultUserMessage,
                userAccount: this.userAccount,
                user: this.user,
                shouldHaveEmail: true
            }).then(data => {
                hotelDirectoryService.sendLinkToSelf(this.activeView.id, data);
            });
        },
        sort(hotels) {
          if (this.sorter.key) {
            const key = Array.isArray(this.sorter.key) ? this.sorter.key : [this.sorter.key];
            hotels = orderBy(hotels, key, fill(Array(key.length), this.sorter.order));
          }
          return hotels;
        },
        showPreviewDialog(user) {
           if (this.loaded && !hotelDirectoryService.stillExploring() && !hotelDirectoryService.userHasSeenPreviewDialog(user)) {
              Dialog.show(DirectoryPreviewDialog, {
                user,
                title: 'Hotel Directory Preview',
                message: 'You are currently in preview mode. This is the view your travellers see. In order to switch to edit mode (which only you have access to), close this dialog and click the arrow on the top, left sidebar.'
              }).then(({dontShow}) => {
                hotelDirectoryService.addPreviewTutorial(dontShow);
              });
           }
        }
    },
    beforeDestroy() {
      EventBus.$off(SEND_PREVIEW_EVENT);
      EventBus.$off(EDIT_SUBHEADER_EVENT);
      EventBus.$off(EDIT_HEADER_EVENT);
      EventBus.$off(EDIT_WARNING_EVENT);
      EventBus.$off(EDIT_BACKGROUND_EVENT);
      EventBus.$off(SHOW_WARNING_EVENT);
      EventBus.$off(SHOW_SUBHEADER_EVENT);
      EventBus.$off(EDIT_VIEW_EVENT);
    }
}
</script>

<style lang="stylus" module>
    .directory{
        height calc(100vh - 61px);
        position relative;
        overflow hidden;
        overflow-y auto;
    }

    .loader{
        position: absolute;
        left 50%;
        top 50%;
        transform translate(-50%, -50%)
    }

    .background{
        background-size: cover;
        min-height: calc(100vh - 149px);
        padding: 100px 50px;
        overflow-y: auto
        box-sizing border-box;
    }

    .content{
        min-width: calc(65vw - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .controls{
        display flex;
        justify-content flex-end;
    }

    .rightControls{
        display flex;
        justify-content flex-start;
    }

    .editOptions{
        margin 0 5px;
    }

    .editOptionsBackDrop{
       background-color transparent !important;
    }

    .editOptionsDropdown{
        background-color: #263238;
        padding: 5px 0;
        margin: 5px 0;
        border-top: 2px solid #16272b;
        color: #fff;
        list-style-type none;
        max-height: 150px;
        overflow: auto;
    }

    .disabled{
        color #b1bec6;
    }

    .editOptionsItem{
        display flex;
        justify-content flex-start;
        overflow visible;
        padding: 5px 15px;
        box-sizing: border-box;
        cursor pointer;
        white-space nowrap;

        &:hover{
            background #313c41;
        }
    }

    .editOptionsItem i{
        margin-right 5px;
    }

    .exitPreview{
        position: fixed;
        right: 50px;
        top: 160px;
        z-index 99;
    }
</style>
