/**
 * Created by DejanK on 5/16/2017.
 *
 * Bid Manager Actions Factory
 */
import { NAME as ForwardToNationalSalesDialog } from 'root/rfp/bid-manager/main/dialogs/forward-to-national-sales/forward-to-national-sales.ctrl'
import { noop } from 'lodash'
import { NAME as dueDateExpiredNotification } from 'root/rfp/bid-manager/main/dialogs/due-date-expired/due-date-expired.ctrl'
import { NAME as NotImplementedNotification } from 'root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl'
import { NAME as SetSupplierContactDialog } from 'root/rfp/bid-manager/main/dialogs/set-supplier-contact/set-supplier-contact.ctrl';
import { NAME as DeleteBidsDialog } from 'root/rfp/bid-manager/main/dialogs/delete-bids/delete-bids.ctrl';
import { NAME as UPLOAD_HOTEL_RFP_RESPONSES_DIALOG } from 'root/rfp/bid-manager/main/dialogs/upload-responses/upload-responses.ctrl';
import SendNotInterestedDialog from 'vRoot/rfp-hotel/bid-manager/actions/SendNotInterestedAction.vue';
import SendNoThankYouDialog from 'vRoot/rfp-hotel/bid-manager/actions/SendNoThankYouAction.vue';
import ViewNoThankYouMessageDialog from 'vRoot/rfp-hotel/bid-manager/actions/ViewNoThankYouMessageAction.vue';
import UploadResponsesActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/UploadResponsesActionReport.vue';
import UploadSuccessfulReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/UploadSuccessfulReport.vue';
import DeleteBidsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/DeleteBidsActionReport.vue';
import SendBidsActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendBidsActionReport.vue';
import SetContactActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SetContactActionReport.vue';
import SendResponsesActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendResponsesActionReport.vue';
import SendNotInterestedActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendNotInterestedActionReport.vue';
import SendNoThankYouActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendNoThankYouActionReport.vue';
import ForceSendingInvalidResponsesQuestion from 'vRoot/rfp-hotel/bid-manager/actions/questions/ForceSendingInvalidResponsesQuestion.vue';

import {Dialog as VueDialog} from "root/v-app/rbServices";

const CREATED = 'CREATED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  RESPONDED = 'RESPONDED',
  NEGOTIATION_SENT = 'NEGOTIATION_SENT',
  NEGOTIATION_RESPONDED = 'NEGOTIATION_RESPONDED',
  NEGOTIATION_FINALIZED = 'NEGOTIATION_FINALIZED',
//  NO_THANK_YOU = 'NO_THANK_YOU',
  FINAL_AGREEMENT = 'FINAL_AGREEMENT'

export default BidManagerActionsFactory

BidManagerActionsFactory.$inject = ['rbDialog', 'BidManagerRepository', 'BidManagerService', 'NegotiationsPanelMessagingService', '$state', 'MainAPI', 'NotificationUtils'];
function BidManagerActionsFactory(dialog, BidManagerRepository, bidManagerService, NegotiationsPanelMessagingService, $state, mainAPI, notificationUtils) {

  return {
    sendBid: createSendBid,
    sendBids: createSendBids,
    setContact: createSetContact,
    setContacts: createSetContacts,
    previewBid: createPreviewBid,
    editBid: editBid,
    deleteBid: deleteBid,
    deleteBids: deleteBids,
    openNegotiationsPanel: openNegotiationsPanel,
    withdrawBid: withdrawBid,
    withdrawBids: withdrawBids,
    sendFinalAgreement: sendFinalAgreement,
    sendFinalAgreements: sendFinalAgreements,
    editFinalAgreement: createEditFinalAgreement,
    viewFinalAgreement: viewFinalAgreement,
    sendNoThankYouLetter: sendNoThankYouLetter,
    sendNoThankYouLetters: sendNoThankYouLetters,
    viewNoThankYouLetter: viewNoThankYouLetter,
    editRfp: editRfp,
    createEditResponse: createEditResponse,
    sendResponses: sendResponses,
    uploadResponses: createUploadResponses,
    sendNotInterested: sendNotInterested,
    sendNotInterestedInBulk: sendNotInterestedInBulk,
    sendNoLongerInterested: sendNoLongerInterested,
    sendNoLongerInterestedInBulk: sendNoLongerInterestedInBulk,
    forwardToNationalSales: createForwardToNationalSales
  }

  function createSendBid(options){
    const o = options || {}
    return function sendBid(report) {
      return {
        label: o.label || 'Send Bid',
        icon: o.icon || 'send',
        isAvailable () {
          return allSelectedBidsHaveSupplierContact(report.bids);
        },
        action: sendBidsAction(report.bids)
      }
    }
  }

  function createSendBids(options){
    const o = options || {}
    return function sendBids(report){
      return {
        label: o.label || 'Send Bids',
        icon: o.icon || 'send',
        isAvailable () {
          return statusMatches(report.statuses, [ CREATED, SENT, RECEIVED ]) && allSelectedBidsHaveSupplierContact(report.bids);
        },
        action: sendBidsAction(report.bids)
      }
    }
  }

  function allSelectedBidsHaveSupplierContact(bids) {
    return !bids.find( ( bid ) => {
      return !bid.supplier.contact;
    });
  }

  function sendBidsAction(bids){
    return () => {
      sendBids( getBidsIds(bids) )
        .then(actionReport => bidManagerService.handleActionResult(actionReport, bids, SendBidsActionReport))
        .catch(noop)

      function sendBids(bidsIds, forceSending) {
        return notificationUtils.onSave(() => mainAPI.sendBids(bidsIds, forceSending), {
          customErrorHandlers: {
            400(error) {
              if (error.data && error.data.id === 'DUE_DATE_EXPIRED') {
                return dialog.show(dueDateExpiredNotification, { locals: {
                    expiredDueDate: error.data.value
                  }})
                  .then(() => sendBids(bidsIds, true))
              } else {
                return notificationUtils.handleErrors(error);
              }
            }
          }
        });
      }
    };
  }

  function createSetContact(options){
    const o = options || {};
    return function setContact(report){
      return {
        label: o.label || 'Edit Contact',
        icon: o.icon || 'person',
        isAvailable () {
          return statusMatches(report.statuses, [ CREATED, SENT, RECEIVED ]);
        },
        action: setContactsAction(report.bids)
      };
    };
  }

  function createSetContacts(options){
    const o = options || {}
    return function setContact(report){
      return {
        label: o.label || 'Set NAM Contact',
        icon: o.icon || 'contacts',
        isAvailable () {
          return report.chainSupport && report.chain && statusMatches(report.statuses, [ CREATED, SENT, RECEIVED, NOT_INTERESTED ]);
        },
        action: setContactsAction(report.bids)
      };
    };
  }

  function setContactsAction(bids){
    return () => {
      dialog.show( SetSupplierContactDialog, { locals: {
          Bids: bids
        }})
        .then( actionReport => bidManagerService.handleActionResult(actionReport, bids, SetContactActionReport) )
        .catch(noop)
    };
  }

  function createPreviewBid(options) {
    const o = options || {};
    return function previewBid(report) {
      return {
        label: o.label || 'Preview Bid',
        icon: o.icon || 'remove_red_eye',
        action () {
          $state.inNew('hotelRfp.bid.preview', {bidId: report.bids[0].$bidId});
        }
      }
    };
  }

  function editBid(report) {
    return {
      label: 'Edit Bid',
      icon: 'edit',
      isAvailable: () => report.bids[0].$rfpSubType !== 'RATE_QUICK',
      action: () => {
        $state.inNew('hotelRfp.bid.coverLetter.view', {bidId: report.bids[0].$bidId});
      }
    };
  }

  function deleteBid(report) {
    return {
      label: 'Delete Bid',
      icon: 'delete',
      action: deleteBidsAction(report.bids)
    };
  }

  function deleteBids(report){
    return {
      label: 'Delete Bids',
      icon: 'delete',
      isAvailable: () => statusMatches(report.statuses, [ CREATED ]),
      action: deleteBidsAction(report.bids)
    };
  }

  function deleteBidsAction(bids){
    return () => {
      dialog.show(DeleteBidsDialog, { locals: {
          Bids: bids
        }})
        .then(actionResult => bidManagerService.handleActionResult(actionResult, bids, DeleteBidsActionReport))
        .catch(noop)
    };
  }

  function openNegotiationsPanel(report) {
    return {
      label: 'Negotiate',
      icon: 'forum',
      action: () => {
        NegotiationsPanelMessagingService.openNegotiationsPanel(report.bids[0].$bidId);
      }
    };
  }

  function withdrawBid() {
    return {
      label: 'Withdraw Bid',
      icon: 'delete',
      action () {
        dialog.show( NotImplementedNotification ).catch( noop );
      }
    };
  }

  function withdrawBids(selectedBidsReport){
    return {
      label: 'Withdraw Bids',
      icon: 'delete',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ SENT ]),
      action () {
        dialog.show( NotImplementedNotification ).catch( noop );
      }
    };
  }

  function sendFinalAgreement(report) {
    return {
      label: 'Final Agreement',
      icon: 'thumb_up',
      action: ( ) => {
        const bidId = report.bids[0].$bidId;
        $state.inNew('hotelRfp.bid.finalAgreement.send', { bidId });
      }
    };
  }

  function sendFinalAgreements(selectedBidsReport){
    return {
      label: 'Send Final Agreements',
      icon: 'thumb_up',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED ]),
      action () {
        dialog.show( NotImplementedNotification ).catch( noop );
      }
    };
  }

  function createEditFinalAgreement(options) {
    const o = options || {};
    return function previewBid(report) {
      return {
        label: o.label || 'Edit Final Agreement',
        icon: o.icon || 'thumb_up',
        action () {
          const bidId = report.bids[0].$bidId;
          $state.inNew('hotelRfp.bid.finalAgreement.send', { bidId });
        }
      };
    }
  }

  function viewFinalAgreement(report) {
    return {
      label: 'Final Agreement',
      icon: 'thumb_up',
      action () {
        const bidId = report.bids[0].$bidId;
        $state.inNew('hotelRfp.bid.finalAgreement.view', { bidId });
      }
    };
  }

  function sendNoThankYouLetter(selectedBidsReport) {
    return {
      label: 'Send "No Thank You"',
      icon: 'thumb_down',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED, FINAL_AGREEMENT]),
      action: () => { sendNoThankYouAction(selectedBidsReport.bids); },
    };
  }

  function sendNoThankYouLetters(selectedBidsReport){
    return {
      label: 'Send "No Thank You"',
      icon: 'thumb_down',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [RESPONDED, NEGOTIATION_SENT, NEGOTIATION_RESPONDED, NEGOTIATION_FINALIZED, FINAL_AGREEMENT]),
      action: () => sendNoThankYouAction(selectedBidsReport.bids)
    }
  }

  function sendAction(bids, actionDialog, actionReportDialog){
    return VueDialog.show( actionDialog, { bidsIds: getBidsIds(bids) })
      .then( actionReport => bidManagerService.handleActionResult(actionReport, bids, actionReportDialog))
      .catch( noop );
  }

  function sendNoThankYouAction(bids){
    return sendAction(bids, SendNoThankYouDialog, SendNoThankYouActionReport);
  }

  function viewNoThankYouLetter(selectedBidsReport) {
    return {
      label: 'View "No Thank You" Message',
      icon: 'thumb_down',
      action() {
        const bidId = getBidsIds(selectedBidsReport.bids)[0];
        return VueDialog.show(ViewNoThankYouMessageDialog, { bidId })
          .catch( noop )
      }
    }
  }

  function editRfp(selectedBidsReport) {
    return {
      label: 'Edit RFP',
      icon: 'folder_shared',
      isAvailable: () => selectedBidsReport.sharedRfp,
      action () {
        const rfpId = selectedBidsReport.sharedRfp;
        $state.inNew('hotelRfp.rfp.coverLetter.view', { rfpId });
      }
    };
  }

  function createEditResponse(options) {
    const o = options || {}
    return function sendResponse(report) {
      return {
        label: o.label || 'Edit Response',
        icon: o.icon || 'edit',
        action: () => {
          const bidId = report.bids[0].$bidId;
          $state.inNew('hotelRfp.bid.response', { bidId });
        }
      };
    };
  }

  function sendResponses(report){
    return {
      label: isSingleBid(report) ? 'Send Response' : 'Send Responses',
      icon: 'textsms',
      isAvailable: () => statusMatches(report.statuses, [ RECEIVED ]),
      action : sendResponsesAction(report.bids)
    }
  }

  function sendResponsesAction(bids){
    return () => {
      sendResponsesToAPI(bids, bids.map( b => b.$bidId ))
        .then(httpResponse => bidManagerService.handleActionResult(httpResponse, bids, SendResponsesActionReport))
        .catch(noop);

      function sendResponsesToAPI(bidsToSend, bidsIds, forceSending) {
        return notificationUtils.onSave(() => mainAPI.sendResponses(bidsIds, forceSending), {
          customErrorHandlers: {
            400(error) {
              if (error.data && error.data.id === 'RESPONSE_NOT_VALID') {
                return VueDialog.show(ForceSendingInvalidResponsesQuestion, { isBulk: bidsToSend.length > 1 })
                  .then(() => sendResponsesToAPI(bidsToSend, bidsIds, true))
              } else {
                return notificationUtils.handleErrors(error)
              }
            }
          }
        });
      }
    };
  }


  function createUploadResponses(options){
    const o = options || {}
    return function sendResponse(report) {
      return {
        label: o.label || isSingleBid(report) ? 'Upload Response' : 'Upload Responses',
        icon: o.icon || 'open_in_browser',
        isAvailable: () => statusMatches(report.statuses, [ SENT, RECEIVED, NOT_INTERESTED ]) && allBidsAreUniqueByHotel(report.bids),
        action: () => {
          dialog.show( UPLOAD_HOTEL_RFP_RESPONSES_DIALOG, { locals: { Bids: report.bids }} )
            .then(actionReport => bidManagerService.handleActionResult(actionReport, report.bids, UploadResponsesActionReport, UploadSuccessfulReport))
            .catch(noop)
        }
      };
    };

    function allBidsAreUniqueByHotel(bids){
      const hotelsIds = [];
      for(let i =0, l = bids.length; i<l; i++){
        const hotelId = bids[i].supplier.company.entityId;
        if(hotelsIds.indexOf(hotelId) === -1){
          hotelsIds.push(hotelId);
        } else {
          return false;
        }
      }
      return true;
    }
  }

  function sendNotInterested(selectedBidsReport) {
    return {
      label: 'Decline to bid',
      icon: 'delete',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ SENT, RECEIVED ]),
      action: () => sendNotInterestedAction(selectedBidsReport.bids)
    };
  }

  function sendNotInterestedInBulk(selectedBidsReport){
    return {
      label: 'Decline to bid',
      icon: 'delete',
      isAvailable: () => statusMatches(selectedBidsReport.statuses, [ SENT, RECEIVED ]),
      action: () => sendNotInterestedAction(selectedBidsReport.bids)
    };
  }

  function sendNotInterestedAction(bids){
    return sendAction(bids, SendNotInterestedDialog, SendNotInterestedActionReport);
  }

  function sendNoLongerInterested() {
    return {
      label: 'No Longer Interested',
      icon: 'delete',
      action () { dialog.show( NotImplementedNotification ).catch( noop ) }
    };
  }

  function sendNoLongerInterestedInBulk(selectedBidsReport) {
    return {
      label: 'Send "No Longer Interested"',
      icon: 'delete',
      isAvailable: () => { return statusMatches(selectedBidsReport.statuses, [ NEGOTIATION_SENT ])},
      action () { dialog.show( NotImplementedNotification ).catch( noop ) }
    };
  }

  function createForwardToNationalSales(options){
    const o = options || {};
    return function forwardToNationalSales(){
      return {
        label: o.label || 'Forward to National Sales',
        icon: o.icon || 'remove_red_eye',
        action: () => { dialog.show(ForwardToNationalSalesDialog) }
      };
    };
  }

  function statusMatches(selectedBidsStatuses, requiredStatuses){
    if(selectedBidsStatuses.length > requiredStatuses.length) {
      return false;
    }

    // all selected bids must have one of action required statuses
    for(let i=0, l = selectedBidsStatuses.length; i<l; i++){
      if( requiredStatuses.indexOf(selectedBidsStatuses[i]) === -1 ) {
        return false;
      }
    }
    return true;
  }

  function isSingleBid(report) {
    return report.bids.length === 1;
  }

  function getBidsIds(bids){
    return bids.map( b => b.$bidId );
  }
}
