/**
 * Created by DejanK on 4/5/2017.
 *
 * Creates
 */
import { forEach, startsWith, clone, isObject, get } from 'lodash'

export default BidManagerListViewService

BidManagerListViewService.$inject = ['DataServer', '$q'];
function BidManagerListViewService(dataServer, $q){
  const $service = this;

  $service.buildLayout = buildLayout;


  function buildLayout(view){
    return dataServer.getBidManagerHeadings()
      .then(build);

    function build(httpResponse) {
      const headings = loadHeadings(httpResponse);
      return createView(headings, view);

      function loadHeadings(headingsData){
        const sortable = [], groupable = [], filters = [], headings = clone(headingsData);
        forEach(headings, loadHeading);

        return {
          headings: headings,
          sortableHeadings: sortable,
          groupableHeadings: groupable,
          filterableHeadings: filters
        };

        function loadHeading(heading, key){
          if(startsWith(key, '$')){ return }
          if(heading.$group){
            forEach(heading, loadHeading);
          } else {
            heading.$sortable && sortable.push(heading);
            heading.$groupable && groupable.push(heading);
            heading.$filter && filters.push(heading);
          }
        }
      }

      function createView(headings, savedView){
        const headers = [], fields = [], sort = {}, filter = {}
        let isCondensed = false, group
          try {
          savedView.group && prepareGroup(savedView.group);
          forEach(savedView.sort, prepareSort);
          forEach(savedView.columns, prepareColumn);
          prepareFilter(savedView.filter);
          return formatResponse();
        } catch (exception){
          return $q.reject(exception);
        }

        function prepareGroup(groupEntry){
          const heading = getHeading(groupEntry);
          if(heading && heading.$groupable){
            const groupable = heading.$groupable;
            sort[groupable.by] = 1;
            addToFields(groupable.by);
            addToFields(groupable.text);
            group = groupable;
          }
        }

        function prepareSort(direction, key){
          const heading = getHeading(key);
          if(heading && heading.$sortable){
            forEach(heading.$sortable, function(field){
              sort[field] = direction;
              addToFields(field);
            })
          }
        }

        function prepareColumn(column){
          const heading = getHeading(column);
          if (heading) {
            headers.push(heading);
            isCondensed = isCondensed || heading.isCondensed;

            forEach(heading.$groupable, function(key){ addToFields(key) })
            forEach(heading.$sortable, function(key){ addToFields(key) });

            extractFields(heading.fields);
          }
        }

        function prepareFilter(filters){
          forEach(filters, function(value, key){
            const heading = getHeading(key);
            if(heading && heading.$filter){
              filter[heading.$filter.by] = value;
            }
          });
        }

        function formatResponse(){
          return {
            headers: headers,
            isFlat: !isCondensed,
            query: {
              fields: fields,
              filter: filter,
              sort: sort
            },
            view: {
              sort: savedView.sort,
              group: savedView.group
            },
            group: group
          };
        }

        function addToFields(item){

          if(fields.indexOf(item) === -1){
            fields.push(item);
          }
        }

        function extractFields(fieldsContainer){
          forEach(fieldsContainer, extractFromContainer);

          function extractFromContainer(fieldValue){
            isObject(fieldValue) ? extractFields(fieldValue) : addToFields(fieldValue);
          }
        }

        function getHeading(key){
          return get(headings.headings, key);
        }
      }
    }
  }
}
