<template>
  <div class="row" :class="{selected: added, [$style.row]: true}">
    <check-box
        :value="added"
        :check-box-value="true"
        @input="toggle"
        class="cell  checkboxCell  dark-icon"/>

    <div v-for="column in columns" :key="column" class="cell" :class="{[$style[column]]: $style[column], [$style.cell]: true}">
        <template v-if="is(column, 'category')" :class="$style.category">
            <div v-if="row.directoryViewId" :class="$style.categoryDetails">
                <i class="material-icons">face</i>
                {{getViewName() || '___'}}
            </div>
            <div v-else :class="$style.categoryDetails">
                <i class="material-icons" :class="$style.warning">warning</i>
                Assign Category
            </div>
            <div class="rbInputContainer" :class="$style.categorySelect">
                <i class="material-icons icon">face</i>
                <rb-select class="simple" :value="row.directoryViewId" @input="assignView">
                    <rb-option :value="null">Select Category</rb-option>
                    <rb-option v-for="view in views" :key="view.id" :value="view.id">{{view.name}}</rb-option>
                </rb-select>
            </div>
        </template>
        <div v-else>{{getColumnValue(column) || "___"}}</div>
    </div>
    <div class="cell cell-xs">
        <div :class="$style.actions">
            <i class="material-icons" v-if="!row.directoryViewId" :class="$style.clickable" @click="drop">delete</i>
            <i class="material-icons" v-else :class="$style.clickable" @click="link">visibility</i>
        </div>
    </div>
  </div>
</template>

<script>
import {get} from 'lodash';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import RbSelect from 'vRoot/_core/RbSelect.vue';
import RbInput from 'root/v-components/_core/RbInput.vue'

export default {
    name: "hotel-directory-manage-users-table-row",
    props: ['row', 'columns', 'added', 'all-columns', 'views'],
    components: {RbSelect, CheckBox, RbInput},
    methods: {
        getColumn(id){
            return this.allColumns.find(col => col.id === id);
        },
        is(src, dest){
            return src === dest;
        },
        getColumnValue(id){
            const column = this.getColumn(id);
            if(Array.isArray(column.key)){
                return column.key.map(key => get(this.row, key)).join(" ");
            }else {
                return get(this.row, column.key);
            }
        },
        getViewName(){
            const view = this.views.find(view => view.id === this.row.directoryViewId);
            return view && view.name;
        },
        toggle(){
            this.$emit(this.added? 'remove': 'add', this.row);
        },
        drop(){
            this.$emit('drop', this.row);
        },
        link(){
            this.$emit('link', this.row);
        },
        assignView(viewId){
            if(viewId && this.row.directoryViewId !== viewId){
                this.$emit('assignView', { id: this.row.id, viewId});
            }
        }
    }
}
</script>

<style lang="stylus" module>
    .actions{
        display flex;
    }

      .cell{
        min-width 100px !important;
      }

    .clickable{
        cursor pointer;
        border-radius 100%;
        padding 3px;
    }

    .category{
        padding 0;
        overflow visible;
    }

    .categoryDetails{
        display: flex;
        justify-content flex-start;
        align-items: center;
        padding-bottom: 10px;

        *{
            padding 0 5px;
        }
    }

    .categorySelect{
        display none;
        margin 0 !important;
        max-width 170px;
        overflow visible !important;
    }

    .categorySelect i{
        margin 5px 0;
    }

    .warning{
        color #f15a25;
    }

    .row:hover .categoryDetails{
        display none;
    }

    .row:hover .categorySelect{
        display block;
    }

    .row:hover .clickable{
        background #fff;
    }

    .category{
        overflow visible !important;
    }
</style>
