<template>
    <div :class="$style.message">
            <label for="message">Outgoing message</label>
            <div>Hi <template v-if="!user">USER_NAME</template><template v-else>{{user.firstName}}</template>,</div>
            <textarea name="message" id="message" :value="value" @input="input"></textarea>
            <div>Sincerely,</div>
            <div>{{userAccount.firstName}} {{userAccount.lastName}}</div>
            <div>{{account.name}}</div>
            <div>{{userAccount.emailAddress}}</div>
        </div>
</template>

<script>
export default {
    name: 'EditUserEmailMessage',
    props: ['value', 'userAccount', 'user'],
    methods: {
        input(e) {
            this.$emit('input', e.target.value);
        }
    },
    computed: {
        account() {
            return this.userAccount.getAccount();
        }
    }

}
</script>

<style lang="stylus" module>
    .message{
        position: relative;
        font-size: 12px;
        margin: 0 !important;
    }

    .message label{
        position absolute;

        background #fff;
        top 20px;
        left: 5px;
        padding: 0 5px;
        font-size: 10px;
    }

    .message textarea{
        width: 100%;
        height: 300px;
        border: 1px solid #91a4af;
        font-size: 13px;
        border-radius: 5px;
        margin: 10px 0 !important;
        display: block;
        padding: 15px;
        color: #90A4AE;
        line-height: 1.7;
        box-sizing: border-box;
        text-align justify;
        resize: none;
    }

    .message div{
        padding-left 15px;
    }
</style>
