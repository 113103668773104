/**
 * Created by DejanK on 2/10/2017
 *
 * RFP Name Editor
 */

'use strict';

module.exports = {
  bindings: {
    show: '<',
    onClose: '&',
    rfpName: '<'
  },
  template: require('./rb-edit-rfp-name.html'),
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl(){
  let vm = this;

  vm.cancel = cancel;
  vm.apply = apply;
  vm.$onChanges = $onChanges;

  function $onChanges(changes){
    changes.rfpName && resetRfpNameModel();
  }

  function resetRfpNameModel(){
    vm.rfpNameModel = vm.rfpName;
  }

  function cancel(){
    resetRfpNameModel();
    vm.onClose({
      $event: {
        cancel: true,
        rfpName: undefined
      }
    });
  }

  function apply(){
    vm.onClose({
      $event: {
        apply: true,
        rfpName: vm.rfpNameModel
      }
    });
  }
}
