/**
 * Created by DejanK on 3/1/2017
 *
 * RbEditLetter
 */
export default {
  template: '<rbv-rich-textarea-letter-editor ng-if="$ctrl.model !== \'loading\'" model="$ctrl.model" on-change="$ctrl.onChange({$event: $event})" />',
  bindings: {
    model: '<',
    onChange: '&'
  }
};
