/**
 * Created by DejanK on 8/3/2017.
 *
 * rbHasUppercase
 *
 * Validates that input has lowercase character
 */

'use strict';

module.exports = rbHasLowercase;

function rbHasLowercase() {
  return {
    require: 'ngModel',
    link: (s, e, attrs, ngModelCtrl)=>{
      ngModelCtrl.$validators.rbHasLowercase = (modelValue, viewValue)=>{
        let pattern = /(?=\S*?[a-z])/;
        return pattern.test(modelValue || viewValue);
      }
    }
  }
}
