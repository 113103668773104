<template>
  <input
    :id="id"
    :readonly="readonly"
    :autocomplete="autocomplete"
    @focus="selectAll"
    @input="$emit('input', $event.target.value)"
    @blur="$emit('blur')"
  >
</template>

<script>
  import Pikaday from 'pikaday'
  import Moment from 'moment'

  export default {
    name: 'RbInputDate',

    props: {
      id: null,
      value: {
        'default': undefined
      },
      formatView: {
        'type': String,
        'default': 'MM/DD/YYYY'
      },
      formatData: {
        'type': String,
        'default': 'YYYY-MM-DD'
      },
      firstDay: {
        // first day of the week (0: Sunday, 1: Monday, etc)
        'default': 0
      },
      minDate: {
        'default': undefined
      },
      maxDate: {
        'default': undefined
      },
      readonly: {
        'type': Boolean,
        'default': false
      },
      autocomplete: null
    },
    data () {
      return {
        pikaday: null
      }
    },
    watch: {
      value ( val ) { this.pikaday && this.updateView(val) },
      minDate (minDate) {
        const m = Moment(minDate);
        m.isValid() && this.pikaday && this.pikaday.setMinDate(m.toDate())
      },
      maxDate (maxDate) {
        const m = Moment(maxDate);
        m.isValid() && this.pikaday && this.pikaday.setMaxDate(m.toDate())
      }
    },

    mounted () {
      const vue = this;

      this.pikaday = new Pikaday({
        field: this.$el,
        format: this.formatView,
        position: 'bottom left',
        onSelect () { vue.$emit('input', this.toString(vue.formatData)) },
        onOpen () { vue.readonly && this.hide() }
      });

      this.updateView(this.value)
    },

    destroyed () {
      this.pikaday && this.pikaday.destroy()
    },
    methods: {
      selectAll ( event ) {
        event.target.select();
        this.$emit('focus')
      },
      updateView(value){
        let m;
        if( value && value.length >=10 ){
          m = Moment(value, this.formatView);
          m = m.isValid() ? m : Moment(value, this.formatData);
          m.isValid() && this.pikaday.setMoment(m)
        }
      }
    }
  }
</script>
