import { TYPE_CITY, TYPE_OFFICE } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { validateTravelDestination } from 'root/rfp-hotel/main/rfp/destinations/travel-destination-validation';
import {parseGooglePlace} from 'root/shared/tools/google/googlePlaceParser';
import {GoogleMapsGeocode} from 'root/shared/tools/google/googleProvider';

const FIELD_ID = 'Id',
  FIELD_NAME = 'Travel Destination Name',
  FIELD_ESTIMATED_ROOM_NIGHTS = 'Estimated Room Nights',
  FIELD_ESTIMATED_SPEND = 'Estimated Spend',
  FIELD_ADDRESS = 'Address',
  FIELD_CITY = 'City',
  FIELD_STATE = 'State Code',
  FIELD_REGION = 'Region',
  FIELD_COUNTRY = 'Country Code',
  FIELD_ZIP = 'Postal Code';

export { make as default, FIELD_ID }

function make(rawTravelDestination){
  return new TravelDestinationUpload(rawTravelDestination)
}

function TravelDestinationUpload(raw){
  this.$isValid = false;
  this.$errors = null;
  this.$geocoded = false;

  this.id = get(FIELD_ID);
  this.name = get(FIELD_NAME);
  this.estimatedRoomNights = loadNumber(FIELD_ESTIMATED_ROOM_NIGHTS);
  this.estimatedSpend = loadNumber(FIELD_ESTIMATED_SPEND);
  this.location = {
    address: {
      address1: get(FIELD_ADDRESS),
      city: get(FIELD_CITY),
      state: get(FIELD_STATE),
      region: get(FIELD_REGION),
      zip: get(FIELD_ZIP),
      country: get(FIELD_COUNTRY)
    }
  };

  this.type = parseType();
  this.location.fullAddress = getFullAddressFromRaw(this.type, this.location.address);

  function parseType(){
    return (raw[FIELD_ADDRESS] && raw[FIELD_ADDRESS].trim().length) ? TYPE_OFFICE : TYPE_CITY
  }

  function getFullAddressFromRaw(type, address){
    const fullAddress = `${address.city}, ${address.state || address.region}, ${address.zip}, ${address.country}`;
    return removeEmpty(type === TYPE_OFFICE ? `${address.address1}, ${fullAddress}` : fullAddress)
  }

  function get(fieldName){ return `${(raw[fieldName]? raw[fieldName]: '')}`.trim() }

  function removeEmpty(s){ return s.replace(/, , /g, ', ') }

  function loadNumber(field){
    const value = `${raw[field]}`,
      v = parseInt(value.replace(/[^\d]/g, ''), 10);
    return isNaN(v) ? undefined : v
  }
}

TravelDestinationUpload.prototype.geocode = function(){
  return new Promise((resolve, reject) => {
    if(this.$geocoded) { return resolve(this) }
    if(!this.location.fullAddress || this.location.fullAddress.length < 10 ) { return resolve(this) }

    setTimeout(() => {
      GoogleMapsGeocode({address: this.location.fullAddress})
        .then(data => {
          const parsedLocation = parseGooglePlace(data[0]);
          if(parsedLocation){
            this.location = parsedLocation;
            this.$geocoded = true
          }
          return resolve(this)
        }, error => {
          if(error === 'OVER_QUERY_LIMIT') {
            return this.geocode()
          } else {
            return reject(error)
          }
        })
    }, 1000)
  })
};

TravelDestinationUpload.prototype.validate = function(){
  this.$errors = validateTravelDestination(this);
  this.$isValid = !Object.keys(this.$errors).some( field => this.$errors[field].invalid );
  return Promise.resolve(this)
};
