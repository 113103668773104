import { Validator } from 'vee-validate'

export default function rbvVeeValidatorCustomMessages() {
  const dict = {
    messages: {
      regex: (field) => `The ${field} is in invalid format.`
    },
    custom: {
      confirmPassword: {
        value: 'Passwords do not match.'
      },
      confirmEmail: {
        value: 'Email addresses do not match.'
      },
      firstName: {
        required: 'First Name is required.',
        max: 'First Name is too long.'
      },
      lastName: {
        required: 'Last Name is required.',
        max: 'Last Name is too long.'
      }
    }
  }

  Validator.localize('en', dict);
}
