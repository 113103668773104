/**
 * Created by DejanK on 3/8/2017.
 */
import angular from 'angular'
import rbHeader from './rb-header/rb-header.cmp';
import rbHdHeader from './rb-hd-header/rb-hd-header.cmp';
import rbPublicHeader from './rb-public-header/rb-public-header.cmp';
import rbGuestHeader from './rb-guest-header/rb-guest-header.cmp';
import rbTravelDestinationsMapHeader from './rb-travel-destinations-map-header/rb-travel-destinations-map-header.cmp';
import rbEditHeader from './rb-edit-header/rb-edit-header.cmp';
import rbEmptyHeader from './rb-empty-header/rb-empty-header.cmp';
import rbBidManagerHeader from './rb-bid-manager-header/rb-bid-manager-header.cmp';

export default angular.module('rb.core.ui.headers', [ ])
  .component('rbHeader', rbHeader)
  .component('rbHdHeader', rbHdHeader)
  .component('rbPublicHeader', rbPublicHeader)
  .component('rbGuestHeader', rbGuestHeader)
  .component('rbEmptyHeader', rbEmptyHeader)
  .component('rbEditHeader', rbEditHeader)
  .component('rbBidManagerHeader', rbBidManagerHeader)
  .component('rbTravelDestinationsMapHeader', rbTravelDestinationsMapHeader)
