import { checkAndExecute } from 'root/api/helpers';
import HotelRfpBidAPI from './rfp-hotel/bid/HotelRfpBidAPI.js';
import HotelRfpBidNegotiationsAPI from './rfp-hotel/bid/HotelRfpBidNegotiationsAPI.js';
import HotelRfpBidExportAPI from './rfp-hotel/bid/HotelRfpBidExportAPI.js';
import HotelRfpAPI from './rfp-hotel/rfp/HotelRfpAPI.js';
import HotelRfpExportAPI from './rfp-hotel/rfp/HotelRfpExportAPI.js';
import RfpAPI from './rfp/RfpAPI.js';
import ManageCompaniesApi from 'root/api/account/companies/ManageCompaniesApi';

export default MainAPI

MainAPI.$inject = ['MainServer', 'DataServer'];
function MainAPI( mainServer, dataServer ) {
  const $api = this;
  this.server = mainServer;
  this.data = dataServer;
  RfpAPI(this);
  HotelRfpAPI(this);
  HotelRfpExportAPI(this);
  HotelRfpBidAPI(this);
  HotelRfpBidNegotiationsAPI(this);
  HotelRfpBidExportAPI(this);
  ManageCompaniesApi(this);

  $api.getCountries = dataServer.getCountries;
  $api.getStates = dataServer.getStates;
  $api.getEntityTypes = dataServer.getAccountTypes;
  $api.getEntityIndustries = dataServer.getIndustries;

  $api.getEntity = ( entityType, entityId ) => checkAndExecute(
    () => entityType && entityId,
    () => mainServer.get(`entities/${entityType}/${entityId}`));

  $api.updateEntityDetails = ( entityType, entityId, data ) => checkAndExecute(
    () => entityType && entityId,
    () => mainServer.put(`entities/${entityType}/${entityId}/details`, data));

  $api.updateEntityLogo = ( entityType, entityId, file ) => {
    const fd = new FormData();
    fd.append('file', file);
    return checkAndExecute(
      () => entityType && entityId,
      () => mainServer.sendFile(`entities/${entityType}/${entityId}/logo`, fd),
      404 )
  };

  $api.updateEntityImage = ( entityType, entityId, file ) => {
    const fd = new FormData();
    fd.append('file', file);
    return checkAndExecute(
      () => entityType && entityId,
      () => mainServer.sendFile(`entities/${entityType}/${entityId}/image`, fd),
      404)
  };

  $api.getHotel = ( hotelId ) => checkAndExecute(
    () => hotelId,
    () => mainServer.get(`hotels/${hotelId}`))

}
