/**
 * Created by DejanK on 9/1/2016.
 */

'use strict';

require('../notices.scss');
module.exports = ErrorCtrl;

ErrorCtrl.$inject = ['errorId', 'MessagesService'];
function ErrorCtrl(errorId, messagesService) {
  const vm = this;
  vm.errorId = errorId;
  initialize();

  function initialize() {
    vm.errorId = errorId;
    vm.loading = true;
    loadMessage();
  }

  function setMessage(msg) {
    vm.message = msg;
    vm.loading = false;
  }

  function loadMessage() {
    return messagesService.getMessageById(vm.errorId,'error').then(
      setMessage,
      ()=> { setMessage({
        "id": "500",
        "title": " ",
        "subtitle": "O-ohh",
        "header": " ",
        "type":"error",
        "action": {
          "text": "Back to Home",
          "state": "Home"
        },
        "text":"Something's gone very wrong!"
      }) }
    );
  }
}
