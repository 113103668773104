import { validateUser, getErrorMessage } from "./upload-user.validator";


const FIELD_ID = "#",
    FIELD_FIRST_NAME = "First Name",
    FIELD_LAST_NAME = "Last Name",
    FIELD_EMAIL = "Email",
    FIELD_PHONE = "Phone Number",
    FIELD_JOB_TITLE = "Job Title",
    FIELD_DEPARTMENT = "Department";

export { make as default, FIELD_ID }

class MakeUploadUser {
    constructor(raw) {
        this.$errors = null;
        this.$isValid = false;
        this.id = get(FIELD_ID);
        this.firstName = get(FIELD_FIRST_NAME);
        this.lastName = get(FIELD_LAST_NAME);
        this.email = get(FIELD_EMAIL);
        this.phone = get(FIELD_PHONE);
        this.jobTitle = get(FIELD_JOB_TITLE);
        this.department = get(FIELD_DEPARTMENT);

        function get(fieldName) { return (raw[fieldName] || "").trim(); }
    }
    validate() {
        this.$errors = validateUser(this);
        this.$isValid = !Object.keys(this.$errors).some(field => this.$errors[field].invalid);
        return Promise.resolve(this);
    }
    errorMessages(field) {
        if (this.$errors[field].invalid) {
            return this.$errors[field].errors.map(error => getErrorMessage(field, error)).join(', ');
        }
    }
}

function make(rawUser){
    return new MakeUploadUser(rawUser);
}



