<template>
  <div :class="$style.container">
    <button class="rbButton smallIcon" :class="$style.button" @click="$rbEmit('toggled')"><i class="material-icons">{{icon}}</i></button>
    <div :class="$style.label">{{label}}</div>
  </div>
</template>

<script>
  import rbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'

  export default {
    name: "rbv-travel-destinations-map-view-toggler",
    mixins: [rbEventsMixin],
    props: ['isTableOpen'],
    computed: {
      label () { return this.isTableOpen ? 'Show Map' : 'Expand Table' },
      icon () { return this.isTableOpen ? 'arrow_forward' : 'arrow_back' }
    }
  }
</script>

<style lang="stylus" module>

  .container {
    display: inline-block
    white-space nowrap
  }

  .label {
    display inline-block
    margin-left 10px
    text-transform uppercase
    font-size 10px
    line-height 12px
    color #78909C
  }

  .button{
    height: 36px !important
    width: 36px;
    line-height: 34px !important
    border-radius 0 !important
  }

</style>
