<template>
  <rb-modal @cancel="cancel()" @hide="submit()" :options="modalOptions">
    <div slot="header"><slot name="header">
      <div class="margin-bottom">Select Final Agreement Rates</div>
      <div class="layout-row">
        <div class="material-icons" :class="$style.infoIcon">info</div>
        <div>
          <p :class="$style.infoText">Click on the rate you wish to appear on the final agreement.</p>
          <p :class="$style.infoText">Rates appearing in <span :class="$style.infoGreen">Green</span> will be considered your final approved rates.</p>
        </div>
      </div>
    </slot>
    </div>
    <div v-if="!questionnaire" slot="body" >
      <rb-loading />
    </div>
    <div v-else="" slot="body" >
      <div :class="isError ? $style.tableContainerError : $style.tableContainer">
        <table class="final-agreement" :class="$style.table">
          <thead>
          <tr>
            <th colspan="2" class="season">{{tableTitle}}</th>
            <th v-for="rate in rates" :colspan="occupanciesLength" class="rate">{{rate}}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="season in seasons">
            <tr class="heading">
              <td class="season">{{season.label}}</td>
              <td class="room-type">RT</td>
              <template v-for="rate in rates">
                <td v-for="occupancy in occupancies" class="rate">{{occupancy.label}}</td>
              </template>
            </tr>
            <tr v-for="roomType in roomTypes">
              <td v-if="roomType === 1" :rowspan="roomTypesLength" class="season"><strong>{{season.period}}</strong></td>
              <td class="room-type">{{roomType}}</td>
              <template v-for="rate in rates">
                <td v-for="occupancy in occupancies">

                  <rate-container
                    :rate="rate"
                    :occupancy="occupancy.id"
                    :season="season.id"
                    :room-type="roomType"
                    :answers="answers"
                    :selected="acceptedRates"
                    :toggle="toggle"
                    class="rate" />
                </td>
              </template>
            </tr>
          </template>
          </tbody>
        </table>
        <dynamic-rate-container :toggle="toggle" :answers="answers" :selected="acceptedRates" />
      </div>
      <div class="errors-container">
        <div class="errors" :class="{'rb-show': isError}">
          At least one rate must be selected!
        </div>
      </div>
    </div>
  </rb-modal>
</template>

<script>
  import {Dialog} from "root/v-app/rbServices"
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import RbModal from 'vRoot/_core/RbModal.vue'
  import { RateContainer, DynamicRateContainer } from './components'
  import RbLoading from 'vRoot/_core/RbvLoading.vue'
  import moment from 'moment'
  import {isEqual} from 'lodash'
  import {notificationUtils, mainAPI} from "root/angular-injector-provider"
  import NotificationDialog from 'vRoot/_core/notifications/NotificationDialog.vue'

  export default {
    name: 'rbv-hotel-rfp-bid-manager-question-mixin',
    mixins: [ DialogMixin, RbEventsMixin ],
    components: { RbModal, RateContainer, RbLoading, DynamicRateContainer },
    props: { 'bidId': {
        required: true,
        type: String
      } },
    data () {
      return {
        rateTableConfig: {},
        answers: {},
        rates: [],
        occupancies: [],
        seasons: [],
        roomTypes: [],
        acceptedRates: []
      }
    },
    computed: {
      occupanciesLength () { return this.occupancies.length },
      roomTypesLength () { return this.roomTypes.length },
      modalOptions() {
        const isDisabled = !this.acceptedRates.length
        return {
          labels: { buttons: { apply: 'Save' } },
          bodyScroll: true,
          disableOk () { return isDisabled }
        }
      },
      isError(){ return !this.acceptedRates.length },
      tableTitle () {
        const seasons = this.seasons.length,
          roomTypes = this.roomTypesLength
        if(!seasons || !roomTypes) {
          return ''
        } else {
          const sLabel = seasons === 1 ? 'Season' : 'Seasons',
            rtLabel = roomTypes === 1 ? 'Room Type' : 'Room Types'
          return `${seasons} ${sLabel} / ${roomTypes} ${rtLabel}`
        }
      }
    },
    methods: {
      determineProvided(config, answers, regExpFn){
        if(config.length === 1) {
          return config
        } else {
          const provided = []
          config.forEach( c => {
            const regExp = regExpFn(c)
            const providedAnswers = Object.keys(answers).filter( aKey => regExp.test(aKey) && this.hasValue(answers[aKey]))
            providedAnswers.length && provided.push(c)
          })
          return provided.length ? provided : [ config[0] ]
        }
      },
      determineProvidedLinear(config, answers, regExpFn, format){
        const provided = this.determineProvided(this.rateTableConfig.season, this.answers, regExpFn),
          maxWithValue = provided.reduce( (acc, v) => { return v > acc ? v : acc }, 1 ),
          resultArray = new Array(maxWithValue)

        for(let i=0, l=resultArray.length; i<l; i++){
          resultArray[i] = format(i)
        }
        return resultArray
      },
      hasValue(testVal){
        const v = parseInt(testVal, 10)
        return !( isNaN(v) || v === 0)
      },
      localDateFormat(localDate){
        return moment(localDate).format('L')
      },
      readRates(rateTableConfig, answers){
        return this.determineProvided(rateTableConfig.rate, answers, cr => new RegExp( `${cr}.{11}` , 'i'))
      },
      readOccupancies (rateTableConfig, answers) {
        const occupancies = this.determineProvided(rateTableConfig.occupancy, answers, co => new RegExp( `.{11,12}${co}` , 'i'))
        return occupancies.map( o => { return o === 'SGL' ? { id: 'SGL', label: 'Single' } : { id: 'DBL', label: 'Double' } })
      },
      readSeasons (rateTableConfig, answers) {
        return this.determineProvidedLinear(
          rateTableConfig.season,
          answers,
          s => new RegExp( `.{4,5}S${s}.{8}` , 'i'),
          i => {
            const seasonId = i+1
            return {
              id: seasonId,
              label: `Season ${seasonId}`,
              period: `${this.localDateFormat(this.answers[`SEASON${seasonId}START`])} - ${this.localDateFormat(this.answers[`SEASON${seasonId}END`])}`
            }
          }
        )
      },
      readRoomTypes (rateTableConfig, answers) {
        return this.determineProvidedLinear(rateTableConfig.roomType, answers, rt => new RegExp( `.{7,8}RT${rt}.{4}` , 'i'), i => i+1 )
      },
      getPossibleValidRates(rates, occupancies, seasons, roomTypes){
        const allRatesIds = []

        if(this.answers.DYNAMIC_PRICING === 'Y' && this.hasValue(this.answers.DYNAMIC_PCT_Discount)){
          allRatesIds.push('DYNAMIC_PCT_Discount')
        }

        rates.forEach( r => {
          seasons.forEach( s => {
            roomTypes.forEach(rt => {
              occupancies.forEach(o => {
                const rateId = `${r}_S${s.id}_RT${rt}_${o.id}`.toUpperCase()
                if(this.hasValue(this.answers[rateId])){
                  allRatesIds.push(rateId)
                }
              })
            })
          })
        })
        return allRatesIds;
      },
      toggle(id){
        const index = this.acceptedRates.indexOf(id)
        index === -1 ? this.acceptedRates.push(id) : this.acceptedRates.splice(index, 1)
      },
      saveSelectedRates(){
        if(isEqual(this.acceptedRates, this.questionnaire.accepted)){
          return Promise.resolve()
        } else {
          return notificationUtils().onSave(() => mainAPI().setAcceptedRates(this.bidId, this.acceptedRates), {
            customErrorHandlers: {
              '409' () {
                return Dialog.show(NotificationDialog, {
                  title: 'Save Aborted',
                  subTitle: 'Warning',
                  message: 'Save was aborted because of the bid status change.',
                })
                  .catch( () => Promise.resolve() )
              }
            }
          })
        }
      },
      submit(){
        this.saveSelectedRates()
          .then(() => this.hide(), () => this.cancel())
      }
    },
    asyncComputed: {
      questionnaire: {
        lazy: true,
        get() {
          return notificationUtils().onLoad(() => mainAPI().getQuestionnaire(this.bidId))
            .then( r => {
              const q = r.data.questionnaire,
                rtConfig = q.config.find( c => c.id === 'RT')

              this.rateTableConfig = rtConfig ? rtConfig.data : {}
              this.answers = q.response.answers

              this.rates = this.readRates(this.rateTableConfig, this.answers)
              this.occupancies = this.readOccupancies(this.rateTableConfig, this.answers)
              this.seasons = this.readSeasons(this.rateTableConfig, this.answers)
              this.roomTypes = this.readRoomTypes(this.rateTableConfig, this.answers)
              this.acceptedRates = q.accepted ? q.accepted.slice() : this.getPossibleValidRates(this.rates, this.occupancies, this.seasons, this.roomTypes)

              return q
            } )
        }
      }
    }
  }
</script>
<style lang="stylus" module="">

  .infoIcon {
    color: #90A4AE;
    margin-right: 5px;
  }

  .infoText {
    font-size: 12px;
    color: #263238;
  }

  .infoGreen {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 7px;
    border-radius: 3px;
    background: #15A99D;
    color: white;
  }

  .table {
    width: 100%;
  }

  .tableContainer {
    border: 1px solid #B0BEC5;
    padding: 3px;
    transition: border-color .3s linear
  }

  .tableContainerError {
    composes tableContainer
    border-color: #F15A24;
  }

</style>
