import {
  $state,
  InvitationHandlersRepository,
  notificationUtils
} from 'root/angular-injector-provider';

import {PAGE_RFP_MANAGER} from 'root/rfp/rfp-manager/main/pages/rfp.rfp-manager.states';

export default {
  getInvitationDetails,
  handleAgentInvitation,
}

function getInvitationDetails(token) {
  return notificationUtils().onSave(() => InvitationHandlersRepository().getInvitationDetails(token));
}

function handleAgentInvitation(token) {
  return getInvitationDetails(token)
    .then(({data: { invitation: {id, invitationMeta: {role}}}}) => handle(id, role).then(openRfpManager));
}

function handle(id, role) {
  return notificationUtils().onSave(() => InvitationHandlersRepository().handleAgentInvitation({id, role}));
}

function openRfpManager(){
  return $state().go(PAGE_RFP_MANAGER, {});
}
