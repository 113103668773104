<template>
    <div class='bar hotel-directory-preview-bar'>
        <div class='controls left'>
            <hd-select :dark="true" :items="groupers" :value="groupBy" @input="emitGroupBy">
              <i slot="icon" class="material-icons">calendar_view_day</i>
              <span slot="label">Group By</span>
            </hd-select>
            <hd-select :dark="true" v-if="groupBy && canFilter" :value="filterBy" :items="filters" @input="emitFilterBy">
              <i slot="icon" class="material-icons">calendar_view_day</i>
              <span slot="label">Filter By</span>
            </hd-select>
        </div>
        <div class='controls right'>

        </div>
    </div>
</template>

<script>
import HdSelect from 'vRoot/hotel-directory/_core/hd-select.vue';
import RbInput from 'root/v-components/_core/RbInput.vue';
import RbInputDate from 'root/v-components/_core/RbInputDate.vue';
import RbTdDropdown from 'vRoot/hotel-directory/modules/preview/TravelDestinationPicker.vue';

export default {
  name: 'HotelDirectoryPreviewBar',
  components: {HdSelect, RbInput, RbInputDate, RbTdDropdown},
  props: ['date', 'groupers', 'groupBy', 'filterBy',
      'sortBy', 'filters', 'grouper', 'filter', 'sorter',
      'destinations', 'destination', 'can-filter', 'travelPolicy' ],
  methods: {
    emitFilterBy(e) {
      this.$emit('filterBy', e);
    },
    emitGroupBy(e) {
      this.$emit('groupBy', e);
    },
    emitSortBy(e) {
      this.$emit('sortBy', e);
    },
    changeDate(e) {
      this.$emit('date', e)
    }
  }
}
</script>

<style lang="stylus">
    .hotel-directory-preview-bar{
        height 90px;
        flex-wrap nowrap !important;
        padding 25px 50px !important;

        .controls{
            display: flex;
            align-items center;

            &.right{
                justify-content flex-end;
            }
        }

        .rbInputContainer.date{
            margin 0 5px;

            input{
                background-color: #323c43 !important;
                border-color: #273239 !important;
            }

            .rbInputError{
                display none;
            }

            .rbInputLabel{
                margin-left 40px;
            }

            .rbInputLabel *{
              background #323c43 !important;
            }
        }

        .info{
            margin 0 5px;
        }
    }
</style>


