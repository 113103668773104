/**
 * Created by DejanK on 7/3/2017.
 *
 */
import { CurrencyFormatter, PercentageFormatter, DateTimeFormatter, DateFormatter } from '../../../../../internationalization/internationalization.service'
import { merge, get, sortBy, filter, find } from 'lodash'

export default NegotiationsFactory

NegotiationsFactory.$inject = ['NegotiationFactory', 'SeasonsFactory', 'RoomTypesFactory', 'CurrentUser'];
function NegotiationsFactory(NegotiationFactory, SeasonsFactory, RoomTypesFactory, CurrentUser){
  const sides = ['BUYER', 'SUPPLIER'];

  function Negotiations(negotiationsData){
    const self = this;
    merge(this, negotiationsData);

    CurrentUser.getActiveUserAccount().then((userAccount)=>{
      self.company = userAccount.account.name;
    });

    loadFormats(this);
    loadCommunication(this.communication, this);
    this.seasons = SeasonsFactory.create(this.config.seasons, this);
    this.roomTypes = RoomTypesFactory.create(this.config.roomTypes, this);

    function loadFormats(self){
      self.dateTimeFormatter = DateTimeFormatter();
      self.dateFormatter = DateFormatter();
      // self.currencyFormat = CurrencyFormatter(get(self, 'config.currency', 'USD'));
      self.currencyFormat = CurrencyFormatter();
      self.percentageFormatter = PercentageFormatter();
    }

    function loadCommunication(communication, negotiations){
      const createNegotiation = NegotiationFactory.create;
      for(let i=0, l=communication.length; i<l; i++){
        communication[i] = createNegotiation(communication[i], negotiations);
      }
    }
  }

  Negotiations.prototype.getLastResponse = function(side){
    checkSide(side);
    const sortedCommunication = sortBy(filter(this.communication, ['from.type', side]), ['from.at']);
    return sortedCommunication.pop();
  };

  Negotiations.prototype.getOtherSideLastResponse = function(side){
    checkSide(side);
    return this.getLastResponse(getOtherSide(side));
  };

  Negotiations.prototype.removeLastCommunication = function(){
    return this.communication.pop();
  };

  Negotiations.prototype.getAmenities = function(){
    return this.config.amenities;
  };

  Negotiations.prototype.getAmenity = function(amenityName){
    return this.communication.length ? this.communication[0].getAmenity(amenityName) : {};
  };

  Negotiations.prototype.getRate = function(rateName){
    return this.communication.length ? this.communication[0].getRate(rateName) : {};
  };

  Negotiations.prototype.getNegotiationsTableWidth = function(){
    const seasonColumnWidth = 226,
      rateColumnWidth = 69,
      amenityColumnWidth = 69,
      taxesColumnWidth = 69,
      diffColumnWidth = 0,
      tcosColumnWidth = 70,
      offset = 70;

    return seasonColumnWidth + (rateColumnWidth * this.config.rates.length)
      + (amenityColumnWidth * this.config.amenities.length) + taxesColumnWidth + diffColumnWidth + tcosColumnWidth + offset;
  };

  Negotiations.prototype.calculateViewNegotiationElementWidth = function(){
    const seasonColumnWidth = 64,
      roomTypeColumnWidth = 42,
      rateColumnWidth = 69,
      amenityColumnWidth = 69,
      taxesColumnWidth = 69,
      diffColumnWidth = 0,
      tcosColumnWidth = 70,
      offset = 0;

    return seasonColumnWidth + roomTypeColumnWidth + (rateColumnWidth * this.config.rates.length)
      + (amenityColumnWidth * this.config.amenities.length) + taxesColumnWidth + diffColumnWidth + tcosColumnWidth + offset;
  };

  Negotiations.prototype.calculateNegotiationElementWidth = function(){
    const seasonColumnWidth = 63,
      roomTypeColumnWidth = 41,
      rateColumnWidth = 69,
      amenityColumnWidth = 69,
      taxesColumnWidth = 69,
      diffColumnWidth = 0,
      tcosColumnWidth = 70,
      offset = 120;

    return seasonColumnWidth + roomTypeColumnWidth + (rateColumnWidth * this.config.rates.length)
      + (amenityColumnWidth * this.config.amenities.length) + taxesColumnWidth + diffColumnWidth + tcosColumnWidth + offset;
  };

  Negotiations.prototype.getLastCommunication = function(count){
    const lastCommunication = [], communication = this.communication,
      communicationLength = communication.length;
    for(let i=count; i>0; i--){
      const index = communicationLength-i;
      if(index >= 0){
        lastCommunication.push(communication[communicationLength-i])
      }
    }
    return lastCommunication;
  };

  Negotiations.prototype.getNegotiationById = function(negotiationId){
    return find(this.communication, {_id: negotiationId});
  };

  function checkSide(side){
    if(sides.indexOf(side) === -1) { throw { reason: `Unknown Side: ${side}` } }
  }

  function getOtherSide(side){
    return side === 'BUYER' ? 'SUPPLIER' : 'BUYER';
  }

  return {
    create: (negotiationsData)=>{
      return new Negotiations(negotiationsData);
    }
  }
}
