import AccountBasicInformationModule from './AccountCompanyProfile.vue'
import {TRAVEL_AGENCY_TYPE} from 'vRoot/account-profile/modules/manage-companies/services/manage-company.service';
export const ACCOUNT_COMPANY_PROFILE = 'company-profile';

export default {
  id: ACCOUNT_COMPANY_PROFILE,
  priority: 10,
  title: 'Basic Information',
  icon: 'business_center',
  component: AccountBasicInformationModule,
  isAvailable (accountType) { return accountType === TRAVEL_AGENCY_TYPE },
  hideFromMenu: true
}
