/**
 * Created by DejanK on 8/25/2015.
 */
import { noop } from 'lodash'

export default function RbFileReader() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: linkFn
  }
}

function linkFn (scope, element, attrs, ngModel) {
  ngModel.$render = noop

  element.on('change', () => { ngModel.$setViewValue(element[0].files) })
}
