import AccountManageUsers from './AccountManageUsers.vue';
const ACCOUNT_MANAGE_USERS_MODULE = 'manageUsers';

export default {
  id: ACCOUNT_MANAGE_USERS_MODULE,
  priority: 11,
  title: 'Users & Permissions',
  icon: 'people',
  component: AccountManageUsers,
  isAvailable () { return true }
}

export {
  ACCOUNT_MANAGE_USERS_MODULE
};
