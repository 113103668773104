/**
 * Created by DejanK on 7/3/2017.
 */
import HTML from './rb-negotiations.html';
import './rb-negotiations.scss';

import NegotiationsFormService from 'vRoot/rfp-hotel/negotiations/NegotiationsFormService.js';

export default {
  template: HTML,
  bindings: {
    bid: '<',
    side: '<',
    onClose: '&',
    onResize: '&'
  },
  controller: Ctrl
};


Ctrl.$inject = ['NegotiationsService'];
function Ctrl(NegotiationsService) {
  const vm = this;

  vm.$onChanges = $onChanges;
  vm.closePanel = closePanel;
  vm.createNegotiation = createNegotiation;
  vm.editNegotiation = editNegotiation;
  vm.areNegotiationsFinalized = false;

  function $onChanges(changes){
    vm.panelMode = 'VIEW';
    setBid(changes.bid);
    setSide(changes.side);
  }

  function loadNegotiations(){
    NegotiationsService
      .getNegotiations(vm.bid.$bidId)
      .then( ( negotiations ) => {
        vm.onResize({
          $event: {
            size: vm.panelMode === 'VIEW' ? negotiations.calculateViewNegotiationElementWidth() : negotiations.getNegotiationsTableWidth()
          }
        });
        vm.negotiations = negotiations;
      }, ( err ) => {
        console.log(err);
      } );
  }

  function closePanel(){vm.onClose()}

  function createNegotiation(){
    openEditor('CREATE');
  }

  function editNegotiation(){
    openEditor('EDIT');
  }

  function openEditor(mode){
    vm.editorConfig = {
      negotiations: vm.negotiations,
      side: vm.side,
      mode
    };

    vm.panelMode = 'EDIT';
    NegotiationsFormService.init(vm.negotiations, vm.side, mode, closeEditor);
    vm.onResize({
      $event: {
        size: vm.negotiations.getNegotiationsTableWidth(),
      }
    });
  }

  function closeEditor(){
    vm.panelMode = 'VIEW';
    vm.editorConfig = null;
    vm.onResize({
      $event: {
        size: vm.negotiations.calculateViewNegotiationElementWidth(),
      }
    });
  }

  function setSide(side){
    if(side && side.currentValue){
      vm.side = side.currentValue;
    }
  }

  function setBid(bid){
    if(bid && bid.currentValue){
      vm.bid = bid.currentValue;
      loadNegotiations();
      vm.areNegotiationsFinalized = areNegotiationsFinalized(vm.bid.$status);
    }
  }

  function areNegotiationsFinalized(bidStatus){
    return [ 'NEGOTIATION_FINALIZED', 'FINAL_AGREEMENT' ].indexOf(bidStatus) !== -1;
  }
}
