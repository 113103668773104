/**
 * Created by DejanK on 9/18/2016.
 */
import './forgotten-password.scss'
import { noop } from 'lodash'
import HTML from './set-password.html'

export { HTML, ForgottenPasswordSetPasswordCtrl as Controller}

ForgottenPasswordSetPasswordCtrl.$inject = ['NotificationUtils', 'PublicAuth', '$stateParams'];
function ForgottenPasswordSetPasswordCtrl(notificationUtils, publicAuth, $stateParams){
  const vm = this;

  vm.onSubmit = onSubmit;

  function onSubmit(password){
    notificationUtils.onSave(() => { return publicAuth.setPassword($stateParams.token, password) }, {
      customErrorHandlers: { '404' () { notificationUtils.showError('forgotten-password-email-failed') } }
    })
      .then(()=>{ notificationUtils.showSuccess('forgotten-password-success') }, noop )
  }
}
