/**
 * Created by DejanK on 12/27/2016.
 *
 * rbRatingComponent
 */

'use strict';

require('core/ui/rb-rating/rb-rating.scss');

module.exports = {
  template: `<i class="material-icons">{{$ctrl.getIcon(1)}}</i><i class="material-icons">{{$ctrl.getIcon(2)}}</i><i class="material-icons">{{$ctrl.getIcon(3)}}</i><i class="material-icons">{{$ctrl.getIcon(4)}}</i><i class="material-icons">{{$ctrl.getIcon(5)}}</i>`,
  bindings: {
    rating: '<',
    on: '@',
    off: '@'
  },
  controller: Ctrl
};

function Ctrl() {
  let vm = this;

  vm.getIcon = (forStar)=> {
    return vm.rating > forStar - 1 ? vm.on || 'star' : vm.off || 'star_outline';
  }
}
