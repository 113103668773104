<script>
  import rfpActionsService from '../../services/rfp-manager-actions-factory';
  import Vue from 'vue';
  import Menu from './menu.vue';

  export default {
    directives: {
      rfpMenu: {
        bind($el, {value}, {context}){
          let actions = null;
          if(value.rfp.type !== 'header' || value.rfp.headerType === 'companyName'){
            context.bindingValue = value;

            $el.addEventListener('contextmenu', ($event) => {
              if (!$event.shiftKey) {
                $event.stopPropagation();
                $event.preventDefault();
                $el.classList.add('rb-bid-menu-focus');
                actions = rfpActionsService.getActionsForSelectedRfps(context.accounts, [context.rfp]);

                showMenu(document.querySelector('body'), $event);
              }
            });
          }

          function showMenu(container, event){
            createMenu(container, event);
          }

          function createMenu(container, event) {

            const overlay = document.createElement('div');
            overlay.classList.add('rb-bid-menu-overlay');
            const menu = document.createElement('div');
            overlay.appendChild(menu);

            setTimeout(() => {
              container.appendChild(overlay);
              overlay.addEventListener('click', destroyMenu);
              overlay.addEventListener('contextmenu', destroyMenu);
              menu.addEventListener('click', destroyMenu);
              menu.addEventListener('contextmenu', destroyMenu);
            });

            const menuScope = new Vue({
              el: menu,
              render(createElement) {
                return createElement(Menu, {
                  props: {
                    actions,
                    rfps: [context.rfp],
                    callback: context.bindingValue.callback,
                    accounts: context.accounts.map(({account}) => account)
                  }
                })
              }
            });


            setMenuPosition(menuScope.$el, event);

            return menuScope;

            function destroyMenu($event) {
              $el.classList.remove('rb-bid-menu-focus');
              $event.stopPropagation();
              $event.preventDefault();
              overlay.remove();
              menuScope.$destroy();
            }

            function setMenuPosition(menuElement, $event) {
              const maxHeight = 300,
                maxWidth = 250,
                body = document.body;

              if (body.clientHeight > $event.pageY + maxHeight) {
                menuElement.style.top =`${  $event.pageY  }px`;
              } else {
                menuElement.style.bottom = `${ body.clientHeight - $event.pageY }px`;
              }

              if (body.clientWidth > $event.pageX + maxWidth) {
                menuElement.style.left = `${  $event.pageX }px`;
              } else {
                menuElement.style.right = `${ body.clientWidth - $event.pageX  }px`;
              }
            }
          }
        },
        componentUpdated($el, {value}, {context}) {
          context.bindingValue = value;
        }
      }
    },
    data() {
      return {bindingValue: null}
    }
  }
</script>

<style scoped>

</style>
