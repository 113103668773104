<template>
  <div>
    <rb-input-range class="destinationsManagerMapDistanceFilterRangeInput"
                    :min="minValue"
                    :max="maxValue"
                    :step="0.1"
                    :value="distanceValue"
                    @changed="handleChange"
                    @input="handleSlide"
                    @keys="handleKeys" />

    <distance-input :class="$style.textInput"
                    :distance="distanceValueFormatted"
                    :unit="unit"
                    :min-value="minValue"
                    :max-value="maxValue"
                    @changed="handleChange" />
  </div>
</template>

<script>
  import DistanceInput from './DistanceInput.vue';
  import RbInputRange from 'vRoot/_core/RbInputRange.vue'
  import RbEventsMixin from 'vRoot/_mixins/RbEventsMixin.vue'
  import { debounce }from 'lodash';

  export default {
    name: 'rb-travel-destinations-map-distance-filter',
    mixins: [ RbEventsMixin ],
    components: { DistanceInput, RbInputRange },
    props: [ 'distance' ],
    data: function() {
      return {
        sliderValue: 0,
        unit: 'mi',
        minValue: 0.1,
        maxValue: 50
      }
    },
    watch: {
      distance (newDistance) {
        this.sliderValue = newDistance && newDistance.value || 0
        this.unit = newDistance && newDistance.unit || 'mi'
      }
    },
    computed: {
      distanceValue() {
        return parseFloat("" + (Math.round(this.sliderValue * 100) / 100))
      },
      distanceValueFormatted() {
        return this.distanceValue.toFixed(1)
      },
    },
    methods: {
      handleChange(e) {
        this.sliderValue = e.value;
        this.$rbEmit(e, { name: 'distanceChanged' })
      },
      handleSlide(event){ this.sliderValue = event.value },
      handleKeys: debounce(function(e) {
        this.handleChange({
          value: e.value
        });
      }, 500)
    }
  }
</script>

<style lang="stylus" module >
  .textInput {
    display inline-block
    height 36px
    width: 65px;
  }

</style>

<style lang="stylus" >
  @import "~vRoot/_core/RbInputRangeMixin"

  .destinationsManagerMapDistanceFilterRangeInput {
    width calc(100% - 80px)
    vertical-align middle
    margin-right 5px

    rbInputRangeDimensions 2px 0 18px 18px 18px
    rbInputRangeTrackColors #CFD8DC #CFD8DC #00B8AB
    rbInputRangeThumbColors #00A99D #00B8AB #00A99D #00A99D none (0 0 2px 5px rgba(0,169,157,0.2))
  }

</style>
