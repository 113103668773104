/**
 * Created by DejanK on 4/5/2017.
 *
 * All Bulk Bid Manager Actions
 */
export default BidManagerBulkBidActionsService;

BidManagerBulkBidActionsService.$inject = ['BidManagerActionsFactory'];
function BidManagerBulkBidActionsService(BidManagerActionsFactory) {
  const BUYER = 'BUYER', SUPPLIER = 'SUPPLIER';
  const $service = this;

  $service.getActions = getActions;

  function getActions(selectedBids, view) {
    const bidsReport = analyzeSelectedBids(selectedBids, view);

    switch (bidsReport.rfpType) {
      case 'HOTEL':
        return getHotelRfpActions(bidsReport);
      default:
        return {};
    }

    function analyzeSelectedBids(selectedBids, view){
      const statuses = [], rfps = [], chains = []
      let chainSupport = null

      for(let i=0, l=selectedBids.length; i<l; i++){
        const bid = selectedBids[i],
          bidRfp = bid.$rfpId,
          bidStatus = bid.$status,
          bidChain = bid.$chainId,
          bidRfpIndex = rfps.indexOf(bidRfp),
          bidStatusIndex = statuses.indexOf(bidStatus),
          bidChainIndex = chains.indexOf(bidChain)

        bidRfpIndex === -1 && rfps.push(bidRfp);
        bidStatusIndex === -1 && statuses.push(bidStatus);
        bidChainIndex === -1 && chains.push(bidChain);
        chainSupport = chainSupport === null ? bid.$rfpChainSupport : chainSupport && bid.$rfpChainSupport
      }

      return {
        bids: selectedBids,
        view: view,
        statuses: statuses,
        sharedRfp: rfps.length === 1 ? rfps[0] : false,
        chainSupport: chainSupport,
        user: view.side,
        rfpType: view.rfpType,
        viewType: view.type,
        chain: chains.length === 1 ? chains[0] : false
      };
    }
  }

  function addAction(actionsArray, actionFn, selectedBidsReport){
    const action = actionFn(selectedBidsReport);
    if(action.isAvailable()) { actionsArray.push(action) }
  }

  function getHotelRfpActions(bidsReport) {
    const user = bidsReport.user
    let actions = {}
    if (user === BUYER) { actions = getBuyerHotelRfpActions(bidsReport) }
    else if (user === SUPPLIER) { actions = getSupplierHotelRfpActions(bidsReport) }
    return actions;

    function getBuyerHotelRfpActions(bidsReport) {
      const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

      addAction(primaryActions, BidManagerActionsFactory.sendBids(), bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.setContacts(), bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.sendFinalAgreements, bidsReport);

      addAction(secondaryActions, BidManagerActionsFactory.sendNoThankYouLetters, bidsReport);
      addAction(primaryActions, BidManagerActionsFactory.withdrawBids, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.deleteBids, bidsReport);

      addAction(rfpActions, BidManagerActionsFactory.editRfp, bidsReport);

      return {
        primary: primaryActions,
        secondary: secondaryActions,
        other: otherActions,
        rfp: rfpActions
      };
    }

    function getSupplierHotelRfpActions(bidsReport) {
      const primaryActions = [], secondaryActions = [], otherActions = [], rfpActions = [];

      addAction(primaryActions, BidManagerActionsFactory.sendResponses, bidsReport);

      addAction(secondaryActions, BidManagerActionsFactory.uploadResponses(), bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.sendNotInterestedInBulk, bidsReport);
      addAction(secondaryActions, BidManagerActionsFactory.sendNoLongerInterestedInBulk, bidsReport);

      return {
        primary: primaryActions,
        secondary: secondaryActions,
        other: otherActions,
        rfp: rfpActions
      };
    }
  }
}
