<template>
  <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">
        <div v-if="!user">Add New User</div>
        <div v-else>Edit User Details</div>
        <div :class="$style.subHeader">Please fill out the form below, we will send the a link to your user.</div>
    </span>
    <div slot="body" class="vModalDefaultBody hotel-directory">
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.firstName}" :forId="'firstName'" :error="$showError('firstName')">
                <span slot="label">First Name<i>*</i></span>
                <input id="firstName" type="text" name="firstName" class="rbInput" autofocus
                    v-model="data.user.firstName"
                    maxlength="100"
                    v-validate="'required|max:100'"
                    data-vv-as="First Name"/>
            </rb-input>
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.lastName}" :forId="'lastName'" :error="$showError('lastName')">
                <span slot="label">Last Name<i>*</i></span>
                <input id="lastName" type="text" name="lastName" class="rbInput"
                    v-model="data.user.lastName"
                    maxlength="100"
                    v-validate="'required|max:100'"
                    data-vv-as="Last name"/>
            </rb-input>
        </div>
        <rb-input :class="{rbInputEmpty: !data.user.email}" :forId="'email'" :error="$showError('email')">
            <span slot="label">Email Address<i>*</i></span>
            <input id="email" type="text" name="email" class="rbInput"
                v-model="data.user.email"
                v-validate="'required|email'"
                data-vv-as="Email Adress"/>
        </rb-input>
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.phone}" :forId="'phone'" :error="$showError('phone')">
                <span slot="label">Phone Number</span>
                <input id="phone" type="text" name="phone" class="rbInput"
                    v-model="data.user.phone"
                    maxlength="100"
                    v-validate="'max:100'"
                    data-vv-as="Phone number"/>
            </rb-input>
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.directoryViewId}" :forId="'directoryViewId'" :error="$showError('directoryViewId')">
                <span slot="label">User Category<i>*</i></span>
                <i class="material-icons icon">info</i>
                <rb-select class="simple" 
                    v-model="data.user.directoryViewId"
                    v-validate="'required'"
                    id="directoryViewId"
                    name="directoryViewId"
                    data-vv-as="User Category">
                    <rb-option v-for="view in views" :key="view.id" :value="view.id">{{view.name}}</rb-option>
                </rb-select>
            </rb-input>
        </div>
        <div :class="$style.group">
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.jobTitle}" :forId="'jobTitle'" :error="$showError('jobTitle')">
                <span slot="label">Job Title</span>
                <input id="jobTitle" type="text" name="jobTitle" class="rbInput"
                    v-model="data.user.jobTitle"
                    maxlength="100"
                    v-validate="'max:100'"
                    data-vv-as="Job Title"/>
            </rb-input>
            <rb-input :class="{[$style.groupItem]: true, rbInputEmpty: !data.user.department}" :forId="'department'" :error="$showError('department')">
                <span slot="label">Deparment</span>
                <input id="department" type="text" name="department" class="rbInput"
                    v-model="data.user.department"
                    maxlength="100"
                    v-validate="'max:100'"
                    data-vv-as="Department"/>
            </rb-input>
        </div>
    </div>

    <div slot="footer" class="vModalDefaultFooter">
        <button v-if="creating" class="rbButton" :class="$style.another" type="button" @click="addAnother">
            <i class="material-icons">save</i> 
            Add and Create New User
        </button>
        <button class="rbButton" type="button" @click="cancel()">Cancel</button>
        <button class="rbButton mainButton">                
            <i class="material-icons">save</i> 
            Save User
        </button>
    </div>

  </modal-form>
</template>

<script>
  
import ModalForm from 'vRoot/_core/RbModalForm.vue'
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
import RbInput from 'root/v-components/_core/RbInput.vue'
import RbSelect from 'vRoot/_core/RbSelect.vue';

import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';

export default {
    name: 'SaveUserDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { RbInput, ModalForm, RbSelect, CheckBox },
    props: {
        user: {
            default: null
        },
        views: {
            required: true
        }
    },
    computed: {
        creating(){
            return !this.user;
        }
    },
    data() {
       return {
            data : {
                user: this.user || {},
                another: false,
                setMessageAsDefault: false,
                message: this.message
            }
        }
    },
    methods: {
        onSubmit(){
            return this.data;
        },
        addAnother(){
            this.data.another = true;
            this.$formSubmitHandler();
            this.$nextTick(() => {
                this.data.another = false;
            });
        }
    }
}
</script>

<style lang="stylus" module>

    .dialog {
        min-width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .group{
        display: flex;
        justify-content space-between;
    }

    .groupItem{
        flex: 1;

        &+&{
            margin-left 5px;
        }
    }

    .sub{
        flex 1;
        display flex;
        align-items center;
        font-size 12px;
    }

    .sub i{
        color #90A4AE;
        padding-left 50px
    }

    .subEnd{
        composes sub;
        justify-content flex-end;
        color #90A4AE;
        font-weight bolder;
        i{
            padding 0;
        }
    }

    .message{
        position relative;
        font-size 12px;
    }

    .message label{
        position absolute;
        color: #91a4af;
        background #fff;
        top 0;
        left: 5px;
        padding: 0 5px;
        font-size: 10px;
    }

    .message textarea{
        height: 52px;
        width: calc(100% - 5px);
        border: 1px solid #91a4af;
        border-radius: 6px;
        margin: 10px 0;
        color: #91a4af;
        padding: 5px 10px;
        box-sizing: border-box;
    }

    .another{
        float left;
    }
</style>
