import {
  notificationUtils,
  // httpErrorHandler,
  $state,
  AccountRepository,
  rfpManagerRepository, accountRepository,
} from 'root/angular-injector-provider';

import { PAGE_CURRENT_USER_PROFILE } from 'root/states';
import {Dialog} from 'root/v-app/rbServices';
import CreateNewUserDialog from '../dialogs/ManageUserDialog.vue';
import ManageUserAssignmentDialog from '../dialogs/ManageUserAssignmentsDialog.vue';
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import UserCreatedDialog from '../dialogs/UserCreatedDialog.vue';
import {PAGE_RFP_MANAGER} from 'rfp/rfp-manager/main/pages/rfp.rfp-manager.states';
import rfpManagerService from 'vRoot/rfp-manager/services/rfp-manager-actions.service';
import ChangeAccountTypeDialog from 'vRoot/account-profile/modules/manage-users/dialogs/ChangeAccountTypeDialog.vue';
import AssignmentsChangedDialog from 'vRoot/account-profile/modules/manage-users/dialogs/AssignmentsChangedDialog.vue';

const ACCOUNT_ADMIN = 'ACCOUNT_ADMIN';
const ACCOUNT_MANAGER = 'ACCOUNT_MANAGER';
const PENDING_USER_TYPE = 'PENDING';
const VERIFIED_USER_TYPE = 'VERIFIED';
const UNVERIFIED_USER_TYPE = 'UNVERIFIED';

function loadUsers() {
  return notificationUtils().onLoad(() => AccountRepository().loadAccountUsers()).then(({data}) => {
    let users = data.users;
    users = users.concat(data.invitations);
    return Promise.resolve(users.map(user => makeUser(user)));

    function makeUser(user) {
      const type = user.account? (!user.active? UNVERIFIED_USER_TYPE: VERIFIED_USER_TYPE): PENDING_USER_TYPE;
      const {id, firstName, fullName, lastName,  emailAddress, phone, accountsManaged, jobTitle } = user;
      let newUser = {id, firstName, fullName, lastName,  emailAddress, phone, accountsManaged, jobTitle, type };

      if(user.account){
        const { profilePicture, role } = user;
        newUser = {
          ...newUser,
          profilePicture,
          role: role && role.permission,
        };
      }else{
        const { invitationMeta: {role}, } = user;
        newUser = {
          ...newUser,
          role,
        }
      }

      return newUser;
    }
  });
}

function loadAuthorization() {
  return notificationUtils().onLoad(() => AccountRepository().loadAuthorizations());
}

function gotoUserProfile() {
  $state().go(PAGE_CURRENT_USER_PROFILE);
}

function createAgent(accountId, isCompany) {
  return listAccounts().then(({data: companies}) => Dialog.show(CreateNewUserDialog, {
    title: 'Create New User',
    companies,
    isCompany
  })
    .then(({data}) => notificationUtils().onSave(() => AccountRepository().sendAgentInvitation(accountId, {
        ...data,
        role: data.type
      })).then((user) => {
        const role = data.type === ACCOUNT_ADMIN? 'Administrator': 'Account Manager';
        showUserCreatedDialog(user.data, role, accountId);
        return Promise.resolve(user.data);
      })
    ));
}

function showUserCreatedDialog(user, role, accountId) {
  return Dialog.show(UserCreatedDialog, {name: user.fullName, role}).then(() => resendUserInvitation(accountId, user.id));
}

function editUser(accountId, user) {
  return Dialog.show(CreateNewUserDialog, {
    title: 'Edit User',
    user
  }).then(({data}) => notificationUtils().onSave(() => AccountRepository().updateUser(accountId, {
      ...data,
      role: data.type
    }))
  );
}

function confirmUser(accountId, userId) {
  return notificationUtils().onSave(() => AccountRepository().confirmUser(accountId, userId));
}

function deactivateUser(accountId, userId) {
  return notificationUtils().onSave(() => AccountRepository().deactivateUser(accountId, userId));
}

function listAccounts() {
  return notificationUtils().onLoad(() => rfpManagerRepository().listAllCompanies());
}

function manageAssignments(accountId, user, currentUserIsAdmin, isCurrentUser) {
  return listAccounts()
    .then(({data: companies}) => Dialog.show(
      ManageUserAssignmentDialog,
      {user, companies, currentUserIsAdmin, isCurrentUser}
    ).then(({data, ids}) =>  updateAssignments(accountId, user, {data, ids})));
}

function updateAssignments(accId, user, {data, ids}) {
  const request = {
    userId: data.id,
    role: data.role,
    isUser: user.type !== PENDING_USER_TYPE,
    addedAccountIds: ids.addedIds,
    removedAccountIds: ids.removedIds
  };

  return notificationUtils().onSave(() => accountRepository().updateAssignments(accId, request)).then(() => {
    if(request.addedAccountIds.length > 0 || request.removedAccountIds.length > 0) {
      showAssignmentsChangedDialog(request);
    }
    return Promise.resolve();
  });
}

function showAssignmentsChangedDialog(request) {
  return Dialog.show(AssignmentsChangedDialog).then(
    () => notificationUtils().onSave(() => accountRepository().sendAssignmentsChangedEmails(request))
  );
}

function deleteUser(accountId, user) {
  const data = {
    targetId: user.id,
    isUser: user.type !== PENDING_USER_TYPE
  };

  return Dialog.show(WarningDialog, {
    title: 'Delete User',
    subTitle: 'You are about to delete a user, this action can not be undone!'
  }).then(() => notificationUtils().onSave(() => accountRepository().deleteUser(accountId, data)));
}

function resendUserInvitation(accountId, id) {
  return notificationUtils().onSave(() => AccountRepository().resendUserInvitation(accountId, id));
}

function showPrimaryToBeReplacedDialog() {
  return Dialog.show(WarningDialog, {
    title: 'Did you intend to change Account Manager',
    subTitle: `You about to reassign an account. ReadyBID allows you to make this change, but the current account manager will no longer have oversight of the account.`,
    showCancel: true,
    mainButtonText: 'Yes, Make Change'
  });
}

function openRfpManager() {
  $state().go(PAGE_RFP_MANAGER);
}

function openManageCustomers() {
  return rfpManagerService.openManageCompanies();
}

function changeAccountType(accountId, user) {
  return Dialog.show(ChangeAccountTypeDialog, {user}).then(({role}) => notificationUtils()
    .onSave(() => AccountRepository().changeAccountType(accountId, {
      userId: user.id,
      role,
      isUser: user.type !== PENDING_USER_TYPE,
    }))
  );
}

export default {
  loadUsers,
  loadAuthorization,
  gotoUserProfile,
  createAgent,
  editUser,
  confirmUser,
  deactivateUser,
  manageAssignments,
  deleteUser,
  resendUserInvitation,
  listAccounts,
  openRfpManager,
  openManageCustomers,
  changeAccountType
}

export {
  ACCOUNT_MANAGER,
  ACCOUNT_ADMIN,
  VERIFIED_USER_TYPE,
  UNVERIFIED_USER_TYPE,
  PENDING_USER_TYPE,
  showPrimaryToBeReplacedDialog,
}
