/**
 * Created by DejanK on 5/18/2017.
 */
export default (server) => { return new InvitationRepository(server)}

function InvitationRepository(server) {
  this.server = server;
}

InvitationRepository.prototype = {
  constructor: InvitationRepository,

  getInvitationDetails (token) { return this.server.get('invitation', {token: token}) },
  signUp (registration) { return this.server.post('invitation/sign-up', registration) },
  addAccount (registration) { return this.server.post('invitation/add-account', registration) },
  changeEmailAddress(registration) { return this.server.post('invitation/change-email-address', registration)},
};
