<template>
  <div class="manage-users-row flex row">
    <img
      v-if="user.profilePicture"
      :src="logo"
      alt="User Image"
      class="logo"
    >
    <div
      v-else
      class="logo"
    >
      <i class="material-icons">person</i>
    </div>
    <div
      class="details flex column"
      :class="user.type.toLowerCase()"
    >
      <div class="name">
        {{ user.fullName }}
      </div>
      <div
        class="top flex"
      >
        <template v-if="isVerified || currentAccountIsCompany">
          <i
            class="material-icons"
            :class="{admin: isAdmin}"
          >
            account_circle
          </i>
          <div class="position">
            <template v-if="isAdmin">
              Admin
            </template>
            <template v-else>
              Account Manager
            </template>
          </div>
          <template v-if="!currentAccountIsCompany">
            <span class="dot">&middot;</span>
            <template v-if="isAssigned">
              <div class="companies">
                Managing <b>{{ managingCount }} Companies</b>
              </div>
            </template>
            <template v-else>
              <span class="unassigned">Unassigned</span>
            </template>
          </template>
        </template>
        <template v-else-if="isUnverified">
          <i class="material-icons">access_time</i>
          Pending Approval
        </template>
        <template v-else-if="isPending">
          <i class="material-icons">access_time</i>
          Pending Acceptance
        </template>
      </div>
      <div class="contact flex">
        <div>{{ user.emailAddress }}</div>
        <span
          v-if="user.emailAddress && user.phone"
          class="dot"
        >
          &middot;
        </span>
        <div>{{ user.phone }}</div>
      </div>
    </div>
    <div class="actions flex row">
      <dropdown-actions
        v-if="currentUserIsAdmin"
        :items="actions"
        :value="{}"
        class="light"
        @input="executeAction"
      >
        <template slot="value">
          <i class="material-icons">settings</i>
          Manage
        </template>
      </dropdown-actions>
      <button
        v-else-if="!currentAccountIsCompany"
        class="button"
        @click="showAssignments"
      >
        <i class="material-icons">perm_contact_calendar</i>
        Assignments
      </button>
    </div>
  </div>
</template>

<script>
  import listUsersService, {
    ACCOUNT_ADMIN, PENDING_USER_TYPE,
    UNVERIFIED_USER_TYPE,
    VERIFIED_USER_TYPE
  } from '../services/account-manage-users-list.service';
  import dropdownActions from 'vRoot/rfp-manager/core/rfp-manager-dropdown-actions.vue';

  export default {
    name: 'UserRow',
    components: {dropdownActions},
    props: {
      user: {
        type: Object,
        required: true
      },
      searchTerm: {
        type: String,
        required: true
      },
      isCurrentUser:{
        type: Boolean,
        required: true
      },
      currentUserIsAdmin: {
        type: Boolean,
        required: true
      },
      currentAccountIsCompany: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        userActions: [
          {
            id: 'assignments',
            icon: 'perm_contact_calendar',
            label: 'Assignments',
            action: this.showAssignments,
            isApplicable: () => !this.currentAccountIsCompany
          },
          {
            id: 'accountType',
            icon: 'perm_contact_calendar',
            label: 'Change Account Type',
            action: this.changeAccountType,
            isApplicable: () => this.currentAccountIsCompany && !this.isCurrentUser
          },
          {
            id: 'profile',
            icon: 'person',
            label: this.isCurrentUser? 'Edit My Profile': 'Edit Profile',
            action: this.isCurrentUser? this.openProfile: this.editUser,
            isApplicable: () => this.isCurrentUser || !this.isUser,
          },
          {
            id: 'deactivate',
            icon: 'block',
            label: 'Deactivate User',
            action: this.deactivateUser,
            isApplicable: () => this.isVerified && !this.isCurrentUser
          },
          {
            id: 'confirm',
            icon: 'check_circle',
            label: 'Confirm User',
            action: this.verifyUser,
            isApplicable: () => this.isUnverified && !this.isCurrentUser
          },
          {
            id: 'resend',
            icon: 'email',
            label: 'Resend Invitation',
            action: this.resendInvitation,
            isApplicable: () => this.isPending && !this.isCurrentUser
          },
          {
            id: 'delete',
            icon: 'delete',
            label: 'Delete User',
            action: this.deleteUser,
            isApplicable: () => !this.isCurrentUser
          },
        ]
      }
    },
    computed: {
      logo() { return `/images/users/${this.user.profilePicture}`; },
      isAssigned() {
        return this.user.accountsManaged.length > 0;
      },
      isAdmin() {
        return this.user.role === ACCOUNT_ADMIN;
      },
      isVerified() {
        return this.user.type === VERIFIED_USER_TYPE;
      },
      isUnverified(){
        return this.user.type ===  UNVERIFIED_USER_TYPE;
      },
      isPending() {
        return this.user.type === PENDING_USER_TYPE;
      },
      actions() {
        return this.userActions.filter(action => action.isApplicable());
      },
      managingCount() {
        return this.user.accountsManaged.length;
      },
      isUser(){
        return !this.isPending;
      }
    },
    methods: {
      openProfile() {
        listUsersService.gotoUserProfile();
      },
      executeAction(item) {
        return item && item.action && item.action(this.user);
      },
      editUser(){
        this.$emit('editUser');
      },
      verifyUser() {
        return this.$emit('confirmUser');
      },
      resendInvitation() {
        return this.$emit('resendInvitation');
      },
      deactivateUser() {
        return this.$emit('deactivateUser');
      },
      showAssignments(){
        return this.$emit('showAssignments');
      },
      deleteUser(){
        return this.$emit('deleteUser');
      },
      changeAccountType() {
        return this.$emit('changeAccountType');
      }
    }
  }
</script>

<style lang="stylus">
  .manage-users-row{
    padding 0 20px;
    border-bottom: 1px solid #ECEFF1;
    height: 100px;
    box-sizing border-box;

    .logo{
      height: 64px;
      width: 64px;
      object-fit: contain;
      border-radius 3px;
      margin-right 10px;
      border 1px solid #ECEFF1;
      background: #ECEFF1;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing border-box;

      i.material-icons{
        font-size 50px;
      }
    }

    img.logo{
      background transparent;
    }

    .details{
      flex 1;

      .top{
        font-size: 12px;
        align-items: center;
        color: #8a999f;

        & > *{
          margin-right 5px;
        }

        i.material-icons{
          color: #00b8aa;
          border-radius: 100%;
          font-size: 18px;

          &.admin{
            color #f79423;
          }
        }

        .position{
          text-transform uppercase;
        }

        .unassigned{
          color: #f25b26;
          font-size 13px;
        }
      }

      &.unverified, &.pending{
        color: #8a999f !important;

        i.material-icons {
          color: #8a999f;
          font-size 18px;
        }
      }

      span.dot {
        font-size 15px;
        font-weight bolder;
      }

      .name{
        font-size 16px;
      }

      .contact{
        font-size: 12px;
        color: #8d999f;

        & > *{
          margin-right 5px;
        }
      }
    }

    .actions{
      justify-content flex-end;

      .button{
        i.material-icons{
          &.active{
            color #00b8aa;
          }
        }
      }

      .rfp-manager-select{
        margin-left 5px;
      }
    }
  }
</style>
