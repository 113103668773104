/**
 * Created by DejanK on 12/17/2016.
 */
import HTML from './contact-us.html'
import ContactUsControllerMixin from './contact-us.ctrl.mixin'

export { ContactUsController as Controller, HTML }

ContactUsController.$inject = ['NotificationUtils', 'AuthAPI', '$state'];
function ContactUsController(notificationUtils, authAPI, $state) {
  const vm = this
  vm.utils = notificationUtils
  vm.$state = $state
  vm.save = () => authAPI.sendContactUsMessage(vm.message)
  ContactUsControllerMixin(vm)
}
