/**
 * Created by DejanK on 4/25/2017.
 */
import './rb-bid-manager-views.scss'
import HTML from './rb-bid-manager-views.html'
import { groupBy, noop } from 'lodash'
import {NAME as notImplementedDialog} from 'root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl'
import BidManagerViewTypes from 'rfp/bid-manager/bid-manager-view-types';

export default {
  template: HTML,
  bindings: {
    views: '<',
    currentView: '<',
    onViewSelected: '&'
  },
  controller: RbBidManagerViews
}

RbBidManagerViews.$inject = [ 'rbDialog', '$state' ];
function RbBidManagerViews( dialog, $state ) {
  const vm = this;

  vm.$onChanges = $onChanges;
  vm.onSelected = onSelected;
  vm.onDropDownOpened = onDropDownOpened;
  vm.onDropDownClosed = onDropDownClosed;
  vm.showNotImplementedNotification = showNotImplementedNotification;
  vm.isSelected = isSelected;
  vm.selectedViewIsSubOfView = selectedViewIsSubOfView;
  vm.openInNew = $state.inNew;
  vm.openGroup = id => noop(vm.openedGroup = id);

  function $onChanges ( changes ) { return (changes.views || changes.currentView) && groupViews() }

  function onSelected ( view ) { if(view !== vm.selectedView) { vm.onViewSelected( { event: { view: view } } ) } }

  function groupViews () {
    if(vm.views && vm.currentView) {
      const groupedViews = groupBy(vm.views, 'rfpType');
      vm.groupedViews = Object.keys(groupedViews).reduce((acc, key) => {
        return {
          ...acc,
          [key]: groupByAccount(groupedViews[key])
        };

        function groupByAccount(views) {
          return views.reduce((grouped, view) => {
            return  view.type !== BidManagerViewTypes.RFP? [...grouped, {
              ...view,
              subViews: [...views.filter(({accountId, id}) => accountId === view.accountId && id !== view.id), view]
            }]: grouped;
          }, []);
        }
      }, {});

      vm.openedGroup = getOpenedGroup();
    }
  }

  function getOpenedGroup() {
    return  Object.keys(vm.groupedViews).reduce((acc, key) => {
      const view = vm.groupedViews[key].find(isSelected);
      return view? view.id: acc;
    }, null);
  }

  function isSelected(view) {
    return view.id === vm.currentView.id || selectedViewIsSubOfView(view)
  }

  function selectedViewIsSubOfView(view) {
    return !!view.subViews.find(({id}) => view.id !== id && id === vm.currentView.id);
  }

  function onDropDownOpened () { 
    vm.dropDownOpened = true;
    setTimeout(() => document.getElementById(vm.currentView.id).scrollIntoView(true), 100);
  }

  function onDropDownClosed () { vm.dropDownOpened = false }

  function showNotImplementedNotification () { dialog.show(notImplementedDialog).catch( noop ) }
}
