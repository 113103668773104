<template>
  <div
    v-rfp-menu="{rfp, callback}"
    class="rfp-manager-table-row"
    :class="{header: rfp.type === 'header' && rfp.headerType === groupBy.id, archived}"
    @click="select"
  >
    <div
      v-if="rfp.type === 'header'"
      class="flex layout-row-flex row"
      :class="{'group-by-title': rfp.headerType === groupBy.id}"
    >
      <div class="cell checkbox">
        <input
          :id="rfp.title"
          type="checkbox"
          :value="true"
          :checked="selected"
          @input="select"
        >
        <label :for="rfp.title" />
      </div>
      <div
        v-if="rfp.headerType === groupBy.id"
        class="cell title xl"
      >
        <div>
          <b>{{rfp.title}}</b>
        </div>
      </div>
      <template v-else>
        <div
          v-for="cell in columns"
          :key="cell.id"
          class="cell data-cell"
          :class="{[cell.class]: true, [cell.id]: true}"
        >
          <div
            v-if="isCell(cell, 'companyName')"
          >
            {{rfp.title}}
          </div>
          <div
            v-else-if="isCell(cell, 'rfpName')"
            class="hover-button"
            @click.prevent.stop="createRFP"
          >
            <i class="material-icons">create_new_folder</i>
            <span>Create RFP</span>
          </div>
          <div
            v-else-if="isCell(cell, 'assignedTo')"
            class="assignee hover-button"
            @click.stop.prevent="assignTo(rfp.account.id)"
          >
            <i class="material-icons">person</i>
            <span v-if="rfp.account && rfp.account.primaryAgent && rfp.account.primaryAgent.userDetails">
              {{
                rfp.account.primaryAgent && rfp.account.primaryAgent.userDetails.fullName
              }}
            </span>
            <span v-else>Assign To</span>
          </div>
          <div v-else>
            ---
          </div>
        </div>
      </template>
    </div>

    <div
      v-else
      class="flex layout-row-flex row"
    >
      <div class="cell checkbox">
        <input
          :id="rfp.rfp.id"
          type="checkbox"
          :value="true"
          :checked="selected"
          @input="select"
        >
        <label :for="rfp.rfp.id" />
      </div>

      <div
        v-for="cell in columns"
        :key="cell.id"
        class="cell data-cell"
        :class="{[cell.class]: true, [cell.id]: true}"
      >
        <div
          v-if="isCell(cell, 'companyName')"
          @click.prevent.stop="manageCompany()"
        >
          {{ getCellValue(cell) }}
        </div>
        <div
          v-else-if="isCell(cell, 'rfpName')"
          @click.prevent.stop="openBidManager()"
        >
          <i
            v-if="archived"
            class="material-icons"
          >
            query_builder
          </i>
          {{ getCellValue(cell) }}
        </div>
        <div
          v-else-if="isCell(cell, 'assignedTo')"
          class="assignee hover-button"
          @click.stop.prevent="assignTo(rfp.rfp.buyer.company.accountId)"
        >
          <i class="material-icons">person</i>
          <span>{{ getCellValue(cell) || 'Assign To' }}</span>
        </div>
        <inline-date-picker
          v-else-if="isCell(cell, 'rfpLaunchDate')"
          class="assignee hover-button launch-date"
          placeholder=""
          :value="getCellValue(cell) || ''"
          @change="updateLaunchDate"
        />
        <div v-else>
          {{ getCellValue(cell) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getObjectValue} from '../services/rfp-manager-table.service';
  import rfpMenuDir from '../directives/rfp-menu/rfp-menu.dir.vue';
  import rfpActionsService from '../services/rfp-manager-actions.service';
  import inlineDatePicker from './inline-date-picker.vue';

  export default {
    name: 'TableRow',
    components: { inlineDatePicker },
    mixins: [rfpMenuDir],
    props: {
      rfp: {
        type: Object,
        required: true
      },
      columns: {
        type: Array,
        required: true
      },
      selected: {
        type: Boolean,
        required: true
      },
      accounts: {
        type: Array,
        required: true
      },
      groupBy: {
        type: Object,
        required: true
      },
    },
    computed: {
      archived() {
        return this.rfp.rfp && this.rfp.rfp.archived;
      },
    },
    methods: {
      isCell(cell, key) {
        return cell.id === key;
      },
      getCellValue(cell) {
        return getObjectValue(this.rfp, cell.key);
      },
      select() {
        this.$emit('select', this.rfp);
      },
      callback(data) {
        this.$emit('actionFired', data);
      },
      openBidManager() {
        return rfpActionsService.openBidManager([this.rfp]);
      },
      createRFP() {
        this.$emit('createRFP');
      },
      assignTo(account) {
        this.$emit('assignTo', account);
      },
      updateLaunchDate(value) {
        this.$emit('updateLaunchDate', (new Date(value).getTime()))
      },
    }
  }
</script>

<style lang="stylus">
  .rfp-manager-table-row{
    background #fff;

    &.header{
      background #eceff1;
      position: sticky;
      top: 51px;
      z-index 9;
    }

    &:not(.header) {
      .row.group-by-title{
        border-top: 0;
      }
    }

    .row{
      border-bottom: 1px solid #D9E0E3;
      justify-content: space-between;

      &.group-by-title{
        height: 37px;
        border-top: 5px solid #D9E0E3;
        justify-content flex-start;

        .cell{
          &.title{
            /*padding-left 10px;*/
            flex 1;
            padding-left: 7px;

            button.button{
              padding: 5px;
              margin-left: 10px;
              border: 1px solid #d9e0e3;
              display inline-flex;
              font-size 12px;
              background white linear-gradient(0deg, #f5f6f7 10%, white 11.05%, white 100%);
              height 27px;

                i.material-icons{
                  margin 0 5px 0 0;
                  font-size 18px;
                }
            }

            b{
              margin-right 10px;
            }
          }
        }

      }

      .cell{
        padding 0;
        //column specific styles
        &.companyName, &.rfpName{
          & > div{
            text-decoration underline;
            cursor pointer;

            i.material-icons{
              vertical-align: bottom;
            }
          }
        }

        &.rfpLaunchDate{
          &>div{
            margin 0 !important;
          }
        }

        .assignee{
          display flex;
          margin: 0 -3px;

          span{
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        & > div{
          padding 0;
        }
      }

      &:hover, &.selected{
        background #eceff1;
      }
    }

    &.archived{
      .cell{
        color: #b0c0c7 !important;
      }
    }

    .hover-button{
      text-decoration: none !important;
    }

    &:hover{
      .hover-button{
        cursor: pointer;
        height: 25px;
        line-height: 2;
        background: #fff linear-gradient(0deg, #f5f6f7 10%, #fff 11.05%, #fff 100%);
        border: 1px solid #DCDEE0;
        padding: 0 5px !important;
        border-radius 2px;
        min-width 50px;

        &.assignee{
          margin: 0;
        }

        i.material-icons{
          line-height 1.5;
        }
      }
    }
  }
</style>
