/**
 * Created by DejanK on 3/24/2017.
 */
import { merge } from 'lodash'
import Account from './account.factory'

export default (userAccountData) => { return new UserAccount(userAccountData) }

function UserAccount(data){
  merge(this, data);

  this.account = Account(this.account);
}

UserAccount.prototype.getCreateAccountSuccessPage = function(){
  return this.account.getCreateAccountSuccessPage();
};

UserAccount.prototype.isBuyer = function(rfpType){
  return this.account.isBuyer(rfpType);
};

UserAccount.prototype.getAccountName = function(){
  return this.account.name;
};

UserAccount.prototype.getEntityId = function(){
  return this.account ? this.account.getEntityId() : undefined
};

UserAccount.prototype.getAccount = function getAccount(){
  return this.account
};

UserAccount.prototype.getAccountId = function getAccountId(){
  return this.account ? this.account.getId() : undefined
};

UserAccount.prototype.getAccountType = function getAccountType(){
  return this.account ? this.account.getType() : undefined
};
