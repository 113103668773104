/**
 * Created by DejanK on 6/29/2017.
 */
import { cloneDeep, find } from 'lodash';
import './rb-bid-manager-with-negotiations.scss';
import HTML from './rb-bid-manager-with-negotiations.html';

export default {
  template: HTML,
  controller: Ctrl,
  bindings: {
    view: '<',
    bids: '<',
    clearSelectedBids: '<',
    onSortBy: '&',
    onBidSelectionChange: '&'
  }
};

Ctrl.$inject = ['$scope', 'NegotiationsPanelMessagingService'];
function Ctrl($scope, NegotiationsPanelMessagingService) {
  const vm = this;

  this.$onInit = setupScopeListeners;

  vm.sortBy = delegatedOnSortBy;
  vm.selectionChange = delegatedOnBidSelectionChange;
  vm.closePanel = closePanel;
  vm.onResetPanelWidth = onResetPanelWidth;
  vm.onResize = onResize;

  function delegatedOnSortBy($event){
    vm.onSortBy({$event: $event});
  }

  function delegatedOnBidSelectionChange($event){
    vm.onBidSelectionChange({$event: $event});
  }

  function closePanel(){
    NegotiationsPanelMessagingService.closeNegotiationsPanel();
    vm.resetPanelWidth = {};
  }

  function onResetPanelWidth(){
    vm.resetPanelWidth = null;
  }

  function setupScopeListeners(){
    $scope.$on(NegotiationsPanelMessagingService.PANEL_OPENED, openPanel);
    $scope.$on(NegotiationsPanelMessagingService.BID_CHANGED, changeBid);
    $scope.$on(NegotiationsPanelMessagingService.PANEL_CLOSED, closePanel);

    function openPanel(event, data){
      changeBid(event, data);
      vm.negotiationsOpened = true;
    }

    function changeBid(event, data){
      vm.negotiatedBid = cloneDeep(find(vm.view.$lastHttpResponse.data, {$bidId: data.bidId}));
    }

    function closePanel(){
      vm.negotiationsPanelWidth = -1;
      vm.negotiationsOpened = false;
      vm.negotiatedBid = null;
    }
  }

  function onResize(event){
    vm.negotiationsPanelWidth = event.size;
  }
}


