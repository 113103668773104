/**
 * Created by DejanK on 8/11/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-panel-edit-actions.scss');

module.exports = {
  template: require('./rb-negotiations-panel-edit-actions.html'),
  bindings: {
    side: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$rootScope'];
function Ctrl($rootScope) {
  let vm = this;

  vm.$onChanges = $onChanges;
  vm.$onInit = $onInit;

  vm.requestOffAll = requestOffAll ;

  function requestOffAll(percent){
    $rootScope.$broadcast('RB_REQUESTED_OF_ALL', {percentage: 1 - parseFloat(percent)/100});
  }

  function $onChanges(changes){
    changes.side && changes.side.currentValue && setSide(changes.side.currentValue);

    function setSide(side){
      vm.side = side;
    }
  }

  function $onInit(){
    vm.requestOffAllPercentage = 5;
  }
}
