<template>
  <div>
    <div class="questionnaireEditTableHeader">
      <span class="questionnaireEditTableHeaderText" v-if="template.locked || preview">{{ template.name }} Table</span>

      <label class="rbCheckbox" v-else="">
        <input type="checkbox"
               class="rbCheckboxInput"
               :id="template.id"
               :name="template.id"
               :value="isSelected"
               @change="onChange($event.target.value)"
        />

        <span class="rbCheckboxLabel questionnaireEditTableHeaderText" :class="isSelected">
          {{ template.name }} Table
        </span>
      </label>
    </div>

    <div class="questionnaireEditTableContainer" :class="classes">

      <div v-if="!preview && isSelected === 'checked' && template.actions">
        <button class="rb-buttons small main" @click="configureTable()">
          <i class="material-icons">grid_on</i> Configure
        </button>
      </div>

      <div class="layout-row">
        <div class="questionnaireEditTableWrapper">
          <table class="questionnaireEditTable">
            <questionnaire-table-row
              v-for="cell in cellsToView" :key="cell.id"
              :template="cell"
              :model="tableConfig"
            />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { cloneDeep, noop } from 'lodash'
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import { Dialog } from "root/v-app/rbServices";


  import QuestionnaireTableRow from '../view/QuestionnaireViewSectionTableRow.vue'
  import QuestionnaireConfigureTableDialog from './QuestionnaireEditSectionTableConfigureDialog.vue'

  export default {
    name: 'rb-questionnaire-edit-section-table',

    mixins: [ RbEventsMixin ],

    components: { QuestionnaireTableRow, QuestionnaireConfigureTableDialog },

    props: [ 'config', 'model', 'preview', 'template' ],

    computed: {
      classes () {
        return Object.assign({}, this.templateClasses, {
          unchecked: this.isSelected === 'unchecked'
        })
      },

      isSelected () {
        return this.model ? 'checked' : 'unchecked'
      },

      tableConfig () {
        const config = cloneDeep(this.config.find( c => c.id === this.template.id ))
        if(config) {
          config.filters = config.data || this.template.defaultFilters
        }
        return config
      },

      cellsToView() {
        return this.template.cells.slice(0,3)
      }
    },

    data () {
      return {
        showTableConfig: false
      }
    },

    methods: {
      onChange (value) {
        this.$rbEmit({
          name: value === 'checked' ? 'tableRemoved' : 'tableAdded',
          sectionId: this.template.id,
          sectionTemplate: this.template
        })
      },

      configureTable(){
        Dialog.show(QuestionnaireConfigureTableDialog, { template: this.template, config: this.tableConfig })
          .then( reason => this.$rbEmit({
            name: 'tableConfigure',
            sectionId: this.template.id,
            sectionTemplate: this.template,
            config: reason
          }))
          .catch( noop )
      }
    },

    mounted () { this.$emit('mounted')}
  }
</script>

<style lang="stylus">

  .questionnaireEditTableHeader {
    font-size: 13px;
    color: #546e7a;
    padding: 0 15px;
    margin-top 10px
  }

  .questionnaireEditTableHeaderText {
    font-size: 13px;
    color: #546e7a;
    padding-left: 10px;

    &:before {
      top: 2px;
      left: 0
    }

    &:after {
      top: 7px;
      left: 3px
    }
  }

  .questionnaireEditTableContainer {
    padding: 10px 15px;

    &.unchecked {
      opacity: .4
      background: #f0f0f0
    }
  }

  .questionnaireEditTableWrapper {
    border: 1px solid #CFD8DC;
    padding: 3px;
    margin: 12px auto;
  }

  .questionnaireEditTable {
    table-layout fixed
    border-spacing 0
    border-collapse collapse

    font-size: 11px;
    font-weight: 400;
    background white
    color: #78909C;
    border: 1px solid #CFD8DC
  }

</style>
