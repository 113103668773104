/**
 * Created by DejanK on 12/17/2016.
 *
 * rbPublicHeader Component
 */

'use strict';

module.exports = {
  template: require('./rb-home-header.html'),
  controller: HomeHeaderController
};

HomeHeaderController.$inject = [];
function HomeHeaderController(){}
