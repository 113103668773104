/**
 * Created by DejanK on 1/24/2017
 *
 * Displays Google Marker and Fills parent NgModel with coordinates it is dragged to
 */
import {GoogleAnimation, Google} from "root/shared/tools/google/googleProvider"
import {isNil} from "lodash"

export default {
  bindings: {
    position: '<',
    icon: '<',
    iconSize: '<',
    draggable: '<',
    animation: '<',
    onDragEnd: '&'
  },
  require: {
    MapCtrl: '^rbMap',
    ModelCtrl: '^ngModel'
  },
  controller: Ctrl
}

Ctrl.$inject = []
function Ctrl(){
  const vm = this
  let markerPromise, marker

  vm.$onInit = $onInit
  vm.$onChanges = $onChanges

  function $onInit() {
    markerPromise = vm.MapCtrl.getMap()
      .then(newMarker)
  }

  function $onChanges ( changes ) {
    if(markerPromise) {
      changes.position && onPositionChanged()
      changes.icon && markerPromise.then(onIconChanged);
      changes.iconSize && markerPromise.then(onIconSizeChanged);
      changes.draggable && markerPromise.then(onDraggableChanged);
      changes.animation && markerPromise.then(onAnimationChanged);
    }
  }

  function onPositionChanged(){
    markerPromise.then( ( marker ) => {
      marker && marker.setPosition(vm.position)
    })
  }

  function onIconChanged(){
    markerPromise.then( ( marker ) => {
      if(marker) {
        const icon = marker.getIcon()
        marker.setIcon({
          url: vm.icon,
          scaledSize: icon.scaledSize
        });
      }
    })
  }

  function onIconSizeChanged(){
    markerPromise.then( ( marker ) => {
      if(marker) {
        const icon = marker.getIcon()
        marker.setIcon({
          url: icon.url,
          scaledSize: vm.iconSize
        });
      }
    })
  }

  function onDraggableChanged(){
    markerPromise.then( ( marker ) => {
      marker && marker.setDraggable(vm.draggable)
    })
  }

  function onAnimationChanged(){
    markerPromise.then( ( marker ) => {
      marker && GoogleAnimation(vm.animation).then( ( animation ) => { marker.setAnimation(animation) } )
    })
  }


  function newMarker(parentMap){
    if (marker) {
      return Promise.resolve(marker)
    } else {
      return createMarkerData()
        .then(createMarker)
    }

    function createMarkerData(){
      const markerData = {
        map: parentMap,
        position: vm.position || {lat: 40.1451, lng: -99.6680},
        draggable: !isNil(vm.draggable) ? vm.draggable : true
      }
      if (vm.icon) { markerData.icon = { url: vm.icon} }
      if (vm.iconSize) { markerData.icon.scaledSize = vm.iconSize }

      if (vm.animation) {
        return GoogleAnimation(vm.animation).then( (animation) => {
          markerData.animation = animation
          return markerData
        })
      } else {
        return Promise.resolve(markerData)
      }
    }

    function createMarker(markerData){
      return Google().then( (google) => {
        marker = new google.maps.Marker(markerData);

        marker.addListener('dragend', event => {
          const coordinates = event.latLng.toJSON();
          vm.ModelCtrl.$setViewValue(coordinates);
          vm.onDragEnd({$event: {coordinates: coordinates}});
        })

        return marker
      })
    }
  }
}
