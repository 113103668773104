/**
 * Created by DejanK on 11/29/2018.
 *
 * rbGuestHeader Component
 */
import '../../../../public/ui/headers.scss'
import HTML from './rb-guest-header.html'

export default {
  template: HTML
}
