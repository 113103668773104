/**
 * Created by DejanK on 6/29/2017.
 */
import './rb-negotiation-view.scss'
import HTML from './rb-negotiation-view.html'

export default {
  template: HTML,
  bindings: {
    negotiations: '<',
    negotiation: '<',
    finalized: '<',
    width: '<',
    side: '<'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', '$compile', '$scope', 'NegotiationsHtmlFactory', '$q', '$timeout'];
function Ctrl($element, $compile, $scope, NegotiationsHtmlFactory, $q, $timeout) {
  const vm = this;

  this.$onChanges = (changes)=>{
    changes.width && vm.width && setWidth($element, vm.width);
    (changes.negotiations || changes.negotiation || changes.side) && vm.negotiations && vm.negotiation && vm.side && init();
  };

  function setWidth($element, width){
    $element.css('width', `${width}px`);
  }

  function init(){

    defered().then((html)=>{
      const tableElement = $($element.find('div.negotiation-view-table')[0]);
      tableElement.append(html);
    });

    vm.companyName = vm.negotiation.getCompanyName();
    vm.negotiatorName = vm.negotiation.getContactName();
    vm.dateTime = vm.negotiation.getFormattedDateTime();

    vm.isOtherSide = vm.negotiation.from.type !== vm.side;
    vm.showAcceptedBy = vm.finalized && vm.negotiation.from.type === 'BUYER';

    function defered(){
      const deferred = $q.defer();

      $timeout(function() {
        const t = Date.now();
        const html = $(buildTable(vm.negotiations, vm.negotiation));
        $compile(html)($scope);
        console.log('view time', Date.now() - t);
        deferred.resolve(html);
      });

      return deferred.promise;
    }
  }

  function buildTable(negotiations, negotiation){
    const seasonsCount = negotiations.seasons.count(),
      roomTypesCount = negotiations.roomTypes.count();
    let html = ''

    html += seasonsColumn(roomTypesCount, negotiations.seasons);
    html += roomTypesColumn(seasonsCount, negotiations.roomTypes);
    html += ratesColumns(negotiation, negotiations.config.rates, seasonsCount, roomTypesCount);
    html += amenitiesColumns(negotiations.getAmenities(), negotiation);
    html += taxBreakdownColumn(negotiation.getTaxesSummary());
    html += addTcosColumn(negotiation.getTcos(), seasonsCount, roomTypesCount);
    //addDiffColumn($element);

    return html;
  }

  function seasonsColumn(roomTypesCount, seasons){
    const htmlFactory = NegotiationsHtmlFactory,
      cellDiv = htmlFactory.cellDiv,
      buildTooltip = htmlFactory.buildTooltip;

    let html = htmlFactory.columnWithHeaderDiv('SEASON', 'season');
    for(let i=1; i<= seasons.count(); i++){
      html += cellDiv(`<i class="fa fa-calendar"></i> ${i}`, `season col-span-${roomTypesCount} strong`, buildTooltip(seasons.getFormattedPeriod(i)));
    }
    html += htmlFactory.divClose;
    return html;
  }

  function roomTypesColumn(seasonsCount, roomTypes){
    const htmlFactory = NegotiationsHtmlFactory,
      cellDiv = htmlFactory.cellDiv,
      buildTooltip = htmlFactory.buildTooltip,
      roomTypesLength = roomTypes.count();

    let html = htmlFactory.columnWithHeaderDiv('RT', 'room-type');
    for(let i=0; i<seasonsCount; i++){
      for(let j=1; j<=roomTypesLength; j++){
        html += cellDiv(j, `room-type strong`, buildTooltip(roomTypes.print(j)));
      }
    }
    html += htmlFactory.divClose;
    return html;
  }

  function ratesColumns(negotiation, ratesConfig, seasonsCount, roomTypesCount){
    const htmlFactory = NegotiationsHtmlFactory;

    let html = '';

    for(let i=0, ratesCount = ratesConfig.length; i<ratesCount; i++){
      const ratePrefix = ratesConfig[i];
      for(let season=1; season<=seasonsCount; season++){

        for(let roomType=1; roomType<=roomTypesCount; roomType++){
          const rate = negotiation.getRate(ratePrefix, season, roomType);
          if(season === 1 && roomType === 1) {html += htmlFactory.columnWithHeaderDiv(rate.abbr)}
          html += `<rb-negotiation-view-rate-cell negotiations="$ctrl.negotiations" negotiation="$ctrl.negotiation" rate-id="${rate.id}"></rb-negotiation-view-rate-cell>`;
        }
      }
      html += htmlFactory.divClose;
    }

    return html;
  }

  function amenitiesColumns(amenities, negotiation){
    const htmlFactory = NegotiationsHtmlFactory,
      columnWithHeaderDiv = htmlFactory.columnWithHeaderDiv,
      divClose = htmlFactory.divClose
    let html = ''

    for(let i=0, amenitiesCount = amenities.length; i<amenitiesCount; i++){
      const amenity = negotiation.getAmenity(amenities[i]);
      html += columnWithHeaderDiv(amenity.abbr);
      html += `<rb-negotiation-view-amenity-cell negotiations="$ctrl.negotiations" negotiation="$ctrl.negotiation" amenity-id="${amenity.id}"></rb-negotiation-view-amenity-cell>`;
      html += divClose;
    }

    return html;
  }

  function taxBreakdownColumn(taxes){
    const htmlFactory = NegotiationsHtmlFactory;

    let html = htmlFactory.columnWithHeaderDiv(`<i class="material-icons">info</i> <span>${taxes.abbr}</span>`);
    html += `<rb-negotiation-view-taxes-cell negotiations="$ctrl.negotiations" negotiation="$ctrl.negotiation"></rb-negotiation-view-taxes-cell>`;
    html += htmlFactory.divClose;

    return html;
  }

  function addTcosColumn(tcosList, seasonsCount, roomTypesCount){
    const htmlFactory = NegotiationsHtmlFactory;

    let html = htmlFactory.columnWithHeaderDiv(`<i class="material-icons">info</i> <span>${tcosList.abbr}</span>`);

    for(let season=1; season<=seasonsCount; season++){
      for(let roomType=1; roomType<=roomTypesCount; roomType++){
        html += `<rb-negotiation-view-tcos-cell negotiations="$ctrl.negotiations" negotiation="$ctrl.negotiation" tcos-id="tcos_s${season}_rt${roomType}"></rb-negotiation-view-tcos-cell>`;
      }
    }
    html += htmlFactory.divClose;

    return html;
  }
}
