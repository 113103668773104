/**
 * Created by DejanK on 4/4/2017.
 */
import './view-destinations.scss';
import { isNil, orderBy, noop } from 'lodash';
import HTML from './view-destinations.html';
import { stopEvent } from '../../../../../shared/tools/view-utils';
import { NAME as showInstructionsDialog } from 'root/rfp-hotel/main/rfp/destinations/dialogs/upload-destination/show-instructions/show-instructions.ctrl';
import { NAME as showUploadResultsDialog } from 'root/rfp-hotel/main/rfp/destinations/dialogs/upload-destination/show-results/show-results.ctrl';

export { HTML , RfpDestinationsViewController as Controller };

RfpDestinationsViewController.$inject = ['NotificationUtils', 'RfpHotelRfpRepository', 'RfpDestinationsViewService', '$state', 'rbDialog', 'MainAPI'];
function RfpDestinationsViewController(notificationUtils, rfpHotelRfpRepository, DestinationsViewService, $state, dialog, mainAPI){
  const vm = this, ASCENDING = 'asc', DESCENDING = 'desc';

  vm.addDestination = addDestination;
  vm.editDestination = editDestination; // todo: check Please Wait
  vm.uploadDestination = uploadDestinations;
  vm.showOnMap = showOnMap;
  vm.deleteDestination = deleteDestination;
  vm.showProperties = showProperties;
  vm.onDestinationSortChanged = onDestinationSortChanged;
  vm.onPropertySortChanged = onPropertySortChanged;
  vm.printEstimatedRoomNights = printEstimated;
  vm.printEstimatedSpend = printEstimated;
  vm.exportTravelDestinations = exportTravelDestinations;

  $onInit();

  function $onInit(){
    vm.rfpId = $state.params.rfpId;

    notificationUtils
      .onLoad(() => rfpHotelRfpRepository.getRfpPreview(vm.rfpId), vm, 'rfp')
      .catch( noop );

    loadDestinations();
    vm.destinationSort = { id: ASCENDING };
    vm.propertySort = { id: ASCENDING };
  }

  function loadDestinations(){
    return notificationUtils
      .onLoad(() => DestinationsViewService.getRfpTravelDestinations(vm.rfpId), vm, 'destinations');
  }

  function uploadDestinations() {
    dialog.show(showInstructionsDialog)
      .then( uploadResults => dialog.show(showUploadResultsDialog, { locals: uploadResults }) )
      .then( loadDestinations )
      .catch( noop );
  }

  function addDestination(){
    dialog
      .show('addTravelDestination', {resolve: {RfpId: ()=> {return vm.rfpId;}}})
      .then( loadDestinations )
      .catch( noop );
  }

  function editDestination(event, destinationId){
    stopEvent(event);
    dialog.show('editTravelDestination', {locals: {rfpId: vm.rfpId, destinationId: destinationId}})
      .then(loadDestinations)
      .catch( noop );
  }

  function showOnMap(event, destinationId){
    stopEvent(event);
    $state.go('hotelRfp.rfp.destinations.map', {'destinationId':destinationId});
  }

  function deleteDestination(event, destination){
    stopEvent(event);
    dialog.show('deleteTravelDestination', {locals: {rfpId: vm.rfpId, destination: destination}})
      .then(loadDestinations)
      .catch( noop );
  }

  function showProperties(destinationId){
    if(vm.destination === destinationId) {
      vm.destination = null;
    } else {
      vm.destination = destinationId;
      vm.propertySort = {};
      notificationUtils
        .onLoad(() => DestinationsViewService.listDestinationProperties(vm.rfpId, destinationId), vm, 'properties');
    }
  }

  function onDestinationSortChanged(key){
    if( vm.destinationSort[key] === ASCENDING){
      vm.destinationSort[key] = DESCENDING;
    } else {
      vm.destinationSort = {};
      vm.destinationSort[key] = ASCENDING;
    }

    vm.destinations.data = orderBy(vm.destinations.data, [key], [vm.destinationSort[key]]);
  }

  function onPropertySortChanged(key){
    if( vm.propertySort[key] === ASCENDING){
      vm.propertySort[key] = DESCENDING;
    } else {
      vm.propertySort = {};
      vm.propertySort[key] = ASCENDING;
    }

    vm.properties.data = orderBy(vm.properties.data, [key], [vm.propertySort[key]]);
  }

  function printEstimated(estimatedValue){
    return isNil(estimatedValue) ? 'N/A' : estimatedValue;
  }

  function exportTravelDestinations() {
    return notificationUtils.onLoad( () => mainAPI.hotelRfpExportTravelDestinations(vm.rfpId) );
  }
}
