import HTML from './rb-edit-hotel-rfp-chain-support.html'

export default {
  bindings: {
    show: '<',
    onClose: '&',
    chainSupport: '<'
  },
  template: HTML,
  controller: Ctrl
}

function Ctrl(){
  const vm = this

  vm.cancel = cancel
  vm.apply = apply
  vm.$onChanges = $onChanges

  function $onChanges(changes){
    changes.chainSupport && resetModel();
  }

  function resetModel(){
    vm.model = vm.chainSupport
  }

  function cancel(){
    resetModel()
    onClose(false, undefined)
  }

  function apply(){ onClose(true, vm.model) }

  function onClose(shouldApply, chainSupport){
    vm.onClose({
      $event: {
        cancel: !shouldApply,
        apply: shouldApply,
        chainSupport: chainSupport
      }
    })
  }
}
