import HotelDirectoryModulesLibrary from './modules/HotelDirectoryModulesLibrary';
import { noop } from 'lodash';
import * as moment from 'moment';
import {
    notificationUtils,
    httpErrorHandler,
    $state,
    hotelDirectoryRepository,
    currentUser,
  } from 'root/angular-injector-provider';

import { PAGE_HOTEL_DIRECTORY } from 'root/states';
import { BID_MANAGER } from 'root/rfp/bid-manager/main/pages';
import {SEND_FINAL_AGREEMENT_STATE} from 'root/rfp-hotel/main/bid/pages/rfp-hotel-bid.states';

import {Dialog} from 'root/v-app/rbServices';
import OptInDialog from 'vRoot/hotel-directory/dialogs/DirectoryPaymentOptInDialog.vue';

let availableSubModules,
    activeSubModule,
    directory,
    authenticatedUser,
    account,
    optInDialogOpen = false;

const HOTEL_DIRECTORY_PREVIEW_INFO = 'HOTEL_DIRECTORY_PREVIEW_INFO',
  HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL = 'HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL';

export default {
    initialize,
    getActiveSubModule,
    getAccount,
    getDirectory,
    handleActiveSubModuleErrors,
    openSubModule,
    getActiveView,
    getColumn,
    getColumns,
    updateView,
    openView,
    getViews,
    createView,
    deleteView,
    getFinalAgreementHotels,
    getAllFinalAgreementHotels,
    addHotels,
    removeHotels,
    openBidManager,
    addUsers,
    loadAddedUsers,
    updateUser,
    removeUsers,
    assignView,
    resendUserLink,
    refreshUserLink,
    deactivateUserLink,
    saveTravelPolicy,
    deleteTravelPolicy,
    getViewHotels,
    getUserAccount,
    sendLinkToSelf,
    getCurrentUser,
    openFinalAgreement,
    fetchTravelDestinations,
    showOptInDialog,
    stillExploring,
    addPreviewTutorial,
    userHasSeenPreviewDialog,
    addManageHotelTutorials,
}


function initialize(user){
    availableSubModules = availableSubModules? availableSubModules: HotelDirectoryModulesLibrary.loadModules();
    activeSubModule = getActiveSubModule().catch(handleActiveSubModuleErrors);

    return activeSubModule.then(
      module => module &&
        new Promise(resolve => {
          authenticatedUser = user;
          account = user.getAccount();
          if(account.getHotelDirectoryId()){
            return resolve(module);
          } else {
            return createHotelDirectoryWithDefaultConfigs(account).then(() => {
              return resolve(module);
            });
          }
        })
    );
}

function getActiveSubModule(){
    const currentModule = $state().params.submodule,
    active = currentModule && availableSubModules.find( m => m.id === currentModule)
    return active ? Promise.resolve(active) : Promise.reject('no active modules')
}

function createHotelDirectoryWithDefaultConfigs(newAccount){
    return new Promise(resolve => {
       hotelDirectoryRepository().createHotelDirectoryWithDefaultConfigs(newAccount)
                .then(() => getAccount(true).then(resolve), httpErrorHandler().handle)
    });
}

function handleActiveSubModuleErrors(){
    if (availableSubModules.length) {
        $state().go(PAGE_HOTEL_DIRECTORY, {submodule:  availableSubModules[0].id}).catch(noop)
    } else {
        $state().go('error', {id: '500'}).catch(noop)
    }
}


function getAccount(force = false){
    return new Promise(resolve => {
        if(!account || force){
            return currentUser().refresh().then( user => {
                account = user.getAccount();
                resolve(account);
            })
        }else {
          return account ? resolve(account) : resolve(authenticatedUser.getAccount)
        }
    });
}

function getUserAccount() {
    return Promise.resolve(authenticatedUser);
}

function getDirectory(force = false){
    return new Promise(resolve => {
        return (directory && directory.accountId === account.getId() && !force)? resolve(directory): getAccount().then(newAccount => {
            const id = newAccount.getHotelDirectoryId();
            return id && hotelDirectoryRepository().getHotelDirectory(id)
                .then(httpResponse => {
                    directory = httpResponse.data;
                    resolve(directory);
                }, httpErrorHandler().handle);
        });
    })
}

function openSubModule(submodule, data={category: null}){
    activeSubModule = Promise.resolve(availableSubModules.find(module => module.id === submodule))
    $state().go(PAGE_HOTEL_DIRECTORY, {submodule, ...data}).catch(noop)
}

function getStateParams(){
    return $state().params;
}

function getColumns(){
    return hotelDirectoryRepository().getColumns();
}

function getColumn(columnId){
  return getColumns().find(column => column.id === columnId) || {};
}

function updateView(view){
    return hotelDirectoryRepository().updateView(directory.id, view).then(() => {
            directory.views = directory.views.map(v => { return view.id === v.id? Object.assign({}, view): v})
        }, httpErrorHandler().handle);
}

function getActiveView(){
    const cat_id = getStateParams().view;
    return cat_id && directory && Object.assign({}, directory.views.find(cat => cat.id === cat_id));
}

function openView(view = null){
    Promise.all([activeSubModule, getDirectory()]).then(data => {
        view = view? view: data[1].views[0].id;
        openSubModule(data[0].id, {view})
    });
}

function getViews(){
    return directory && directory.views;
}

function createView(name){
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().createView(directory.id, name, account)
    ).then(() => getDirectory(true).then(dir => {
        directory = dir;
        openView(directory.views[directory.views.length-1].id);
    }));
}

function deleteView(view) {
    return getViews().length > 1 && notificationUtils().onSave(
        () => hotelDirectoryRepository().deleteView(view)
    ).then(() => getDirectory(true).then(() => {
        const activeView = getActiveView();
        if(activeView.id === view.id) {
            openView(directory.views[0].id);
        }else{
            openView();
        }
        directory.views = directory.views.filter(v => v.id !== view.id);
    }));
}

function getFinalAgreementHotels(){
    return new Promise(resolve => {
        hotelDirectoryRepository().getFinalAgreementHotels(account.id)
            .then(httpResponse => {
                resolve(httpResponse.data);
            }, httpErrorHandler().handle);
        });
}

function addHotels(hotels) {
    return hotelDirectoryRepository().addHotels(hotels, directory.id);
}

function getAllFinalAgreementHotels() {
    return new Promise(resolve => {
        hotelDirectoryRepository().getAllFinalAgreementHotels()
            .then(httpResponse => {
                resolve(httpResponse.data);
            }, httpErrorHandler().handle);
        });
}

function removeHotels(hotels) {
    return hotelDirectoryRepository().removeHotels(hotels, directory.id);
}

function openBidManager(){
    $state().go(BID_MANAGER, {viewId: null});
}

function addUsers(users = null, message) {
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().addUsers(users, directory.id, message)
    );
}

function loadAddedUsers(){
    return new Promise(resolve => {
        hotelDirectoryRepository().getAddedUsers(directory.id)
            .then(httpResponse => {
                resolve(httpResponse.data);
            }, httpErrorHandler().handle);
        });
}

function updateUser(user){
    return notificationUtils().onSave(() =>
        hotelDirectoryRepository().updateUser(directory.id, user));
}

function removeUsers(users) {
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().removeUsers(users, directory.id)
    );
}

function assignView(users, viewId){
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().assignView(users, viewId, directory.id)
    );
}

function resendUserLink(users, message){
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().resendUserLink(users, directory.id, message)
    );
}

function refreshUserLink(userId, message){
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().refreshUserLink(userId, directory.id, message)
    );
}

function deactivateUserLink(userId){
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().deactivateUserLink(userId, directory.id)
    );
}

function saveTravelPolicy(file, progressCallback){
    return hotelDirectoryRepository().saveTravelPolicy(directory.id, file, progressCallback)
        .then(httpResponse => httpResponse.data, httpErrorHandler().handle);
}

function deleteTravelPolicy(){
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().deleteTravelPolicy(directory.id)
    );
}

function getViewHotels(viewId) {
    return hotelDirectoryRepository().getViewHotels(directory.id, viewId)
        .then(httpResponse => httpResponse.data, httpErrorHandler().handle);
}

function sendLinkToSelf(viewId, messageRequest) {
    return notificationUtils().onSave(
        () => hotelDirectoryRepository().sendLinkToSelf(viewId, messageRequest)
    );
}

function getCurrentUser() {
    return getUserAccount();
}

function openFinalAgreement(bidId){
  $state().go(SEND_FINAL_AGREEMENT_STATE, {bidId});
}

function fetchTravelDestinations() {
  return hotelDirectoryRepository().getTravelDestinations(directory.id)
    .then(httpResponse => httpResponse.data, httpErrorHandler().handle);
}

function startExploring() {
  return hotelDirectoryRepository().startExploring().then(refreshDirectory)
}

function refreshDirectory() {
  return new Promise(resolve => getDirectory(true).then(dir => {
    directory = dir;
    resolve(dir);
  }));
}

function optInToPay() {
  return hotelDirectoryRepository().optInToPay({optingForPayment: true}).then(refreshDirectory)
}

function extendTrial() {
  if(!directory.trialExtended) {
    return hotelDirectoryRepository().extendTrial().then(refreshDirectory)
  }
}

function showOptInDialog() {
  if(!optInDialogOpen && stillExploring()) {
    getCurrentUser().then(user => {
      optInDialogOpen = true;
      Dialog.show(OptInDialog, {directory, user, account}).then(data => {
        if(data.action === 'explore') {
          startExploring();
        }else if(data.action === 'extend') {
          extendTrial();
        }
        else{
          optInToPay();
        }
        optInDialogOpen = false;
      }).catch(() => {
        optInDialogOpen = false;
        openBidManager();
      });
    });
  }
}

function stillExploring() {
  return !directory.optingForPayment &&
    !(
      directory.explorationStartDate &&
      moment(new Date()).diff(moment(directory.explorationStartDate),'days' ) < 7
    );
}

function addPreviewTutorial(send) {
  return send && hotelDirectoryRepository().addTutorial(HOTEL_DIRECTORY_PREVIEW_INFO).then(() => {
    currentUser().refresh().then(user => (authenticatedUser = user));
  })
}

function addManageHotelTutorials(send) {
  return send && hotelDirectoryRepository().addTutorial(HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL).then(() => {
    currentUser().refresh().then(user => (authenticatedUser = user));
  })
}

function userHasSeenPreviewDialog(user) {
  return user.tutorials.indexOf(HOTEL_DIRECTORY_PREVIEW_INFO) > -1;
}

function userHasSeenManageHotelTutorial(user) {
  return user.tutorials.indexOf(HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL) > -1;
}
