<template>
  <modal-form :class="$style.dialog"
              @cancel="$formCancelHandler" @submit="$formSubmitHandler">
    <span slot="header">
        <div>Upload New Users</div>
        <div :class="$style.subHeader" v-if="state==='INSTRUCTIONS'">        
            Here you can upload your users using an Excel file. First you will need to download our template and enter your users' information. Then you will upload a completed template so our system can populate your hotel directory users.
        </div>
    </span>
    <div slot="body" class="vModalDefaultBody hotel-directory">
        <input type="file" ref="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
           class="hidden"
           @change="fileChanged"
        />
        <div v-if="state === 'WORKING'">
            <rb-progress-bar :max="100" :value="progress" />
            <div :class="$style.loading">                
                <rbv-loading label="Loading, please wait..."/>
            </div>
        </div>
        <div :class="$style.error" v-if="state=='ERROR'">
            Upload encountered an error. Please make sure you are uploading provided Template file.
        </div>
    </div>
    <div  v-show="state != 'WORKING'" slot="footer" class="vModalDefaultFooter">
        <a class="rbButton"
            :class="$style.delete" 
            target="_self" :href="file"
            download="hotel_directory_users">
            <i class="material-icons">insert_drive_file</i> 
            Template
        </a>
        <button class="rbButton" type="button" @click="cancel()">Cancel</button>
        <button type="button" class="rbButton mainButton" @click="select">                
            <i class="material-icons">publish</i> 
            Upload Users
        </button>
    </div>
  </modal-form>
</template>

<script>
  
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'
  import template from 'root/assets/files/hotel_directory_users_upload.xlsx';
  import RbProgressBar from 'vRoot/_core/RbProgressBar.vue';
  import RbvLoading from "vRoot/_core/RbvLoading.vue";

  import uploadUsers from "../upload-users.service";
  export default {
    name: 'PreUploadUsersDialog',
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['user'],
    components: { ModalForm, RbProgressBar, RbvLoading },
    data() {
       return {
            file: template,
            state: 'INSTRUCTIONS',
            progress: 0,
            data: {
                name: '',
                users: []
            }
        }
    },
    methods: {
        onSubmit(){
            return {
                data: this.data,
            };
        },
        fileChanged(e){
            this.state = "WORKING";
            uploadUsers(e.target.files[0], p => {this.progress = p;}).then((users => {
                this.data = {
                    name: e.target.files[0].name,
                    users
                }
                this.$formSubmitHandler();
            }), () => {
                this.state = "ERROR";
            });
        },
        select(){
            this.$refs.file.click();
        }
    }
  }
</script>

<style lang="stylus" module>

    .dialog {
        width: 500px;
        overflow visible
    }

    .subHeader{
        font-size 12px;
        line-height 14pt;
        color #78909C;
        font-weight lighter;
    }

    .delete{
        float left;
    }

    .loading{
        font-size: 12px;
        text-align: center;
        color: #556e7b;
    }
</style>
