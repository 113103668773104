/**
 * Created by DejanK on 11/7/2016.
 */
'use strict';

module.exports = {
  template: require('./rb-coordinates.html'),
  require: {
    ngModel: "ngModel"
  },
  controller: Ctrl,
  bindings: {
    id: '@',
    center: '<',
    zoom: '<'
  }
};

Ctrl.$inject = [];
function Ctrl() {
  var vm = this;

  this.$onInit = () => {
    vm.ngModel.$render = ()=>{
      vm.center = vm.ngModel.$modelValue || {lat: 40.1451, lng: -99.6680};
      vm.zoom = vm.ngModel.$modelValue ? 17 : 3;
    };
  };
}
