import { noop } from 'lodash';
import HTML from './rb-bid-side-menu.html';
import {stopEvent} from 'root/shared/tools/view-utils';
import {Dialog as VueDialog} from 'root/v-app/rbServices';
import NotificationDialog from 'vRoot/_core/notifications/NotificationDialog.vue';
import rfpStatuses from 'root/rfp-hotel/rfp-statuses';


export default {
  bindings: {
    'specifications': '<',
    'rfpStatus': '<',
    'supplier': '<',
    'bidId': '<'
  },
  template: HTML,
  controller: Ctrl,
};

Ctrl.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state'];
function Ctrl(utils, bidRepository, $state) {

  Object.assign(
    this,
    {
      $onInit () {
        this.isQuestionnaire = $state.current.name === 'hotelRfp.bid.questionnaire.view';
      },

      isRfpPending () {
        return this.rfpStatus === rfpStatuses.RFP_PENDING;
      },

      onDueDateChanged ($event) {
        this.editDueDate = 0;
        if ($event.apply && this.specifications.dueDate !== $event.dueDate) {
          utils.onSave( () => bidRepository.updateBidDueDate(this.bidId, $event.dueDate) )
            .then(reload)
            .catch(noop);
        }
      },

      onProgramPeriodChanged($event){
        this.editProgramPeriod = 0;
        if($event.apply && (this.specifications.programStartDate !== $event.programStartDate || this.specifications.programEndDate !== $event.programEndDate)){
          utils.onSave( () => bidRepository.updateBidProgramPeriod(this.bidId, $event.programStartDate, $event.programEndDate), { customErrorHandlers: {
            '409' () {
              return VueDialog.show(NotificationDialog, {
                title: 'Failed!',
                subTitle: 'Program Period Update',
                message: 'Bid has been modified on the server and the program period can no longer be changed.',
              });
            }
          }
          } )
            .then(reload)
            .catch(noop);
        }
      },

      openEditor (event, editorName){
        stopEvent(event);
        this[editorName] = 1;
      }
    },
  );

  function reload(){
    return $state.reload();
  }
}
