import HTML from './bid-preview.html';

export { HTML, BidPreviewController as Controller};

BidPreviewController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', '$window'];
function BidPreviewController(utils, RfpHotelBidRepository, $state, $window){
  const vm = this;

  $onInit();

  function $onInit(){
    vm.bidId = $state.params.bidId;
    vm.bid = 'loading';
    vm.isStandardBid = false;
    vm.isRateQuickBid = false;
    vm.hasBack = () => $window.history.length > 1;
    vm.goBack = () => $window.history.back();

    utils.onLoad( () => RfpHotelBidRepository.getBidPreview(vm.bidId, 0), vm, 'bid' )
      .then( () => {
        const subType = vm.bid.data.specifications.subType;
        vm.isStandardBid = !subType || subType === 'STANDARD';
        vm.isRateQuickBid = subType === 'RATE_QUICK';
      })
      .catch( () => {} )
  }
}
