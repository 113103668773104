/**
 * Created by DejanK on 7/6/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = AmenityFactory;

AmenityFactory.$inject = [];
function AmenityFactory(){

  let amenitiesConfig = {
    wf: { name: 'WiFi', abbr: 'WF', cat: 'FIXED'},
    bf: { name: 'Breakfast', abbr: 'BF', cat: 'MOCKED'},
    prk: { name: 'Parking', abbr: 'PRK', cat: 'FIXED'},
    ft: { name: 'Fitness', abbr: 'FT', cat: 'FIXED'},
    ec: { name: 'Early Checkout', abbr: 'EC', cat: 'MIXED'},
    as: { name: 'Airport Shuttle', abbr: 'AS', cat: 'FIXED'},
    ia: { name: 'High Speed Internet Access', abbr: 'IA', cat: 'FIXED'}
  };

  function Amenity(amenityId, amenityData, negotiation, negotiations){
    angular.merge(this, amenityData, amenitiesConfig[amenityId]);
    this.id = amenityId;
    this.negotiation = negotiation;
    this.negotiations = negotiations;
  }

  Amenity.prototype.getValue = function(){
    return {
      value: this.value,
      included: this.included,
      type: this.type,
      valid: this.valid,
      mocked: this.mocked,
      mockPercentage: this.mockPercentage,
      amount: this.amount
    };
  };

  Amenity.prototype.getFormattedValue = function(){
    let value = this.type === 'UNAVAILABLE' || !this.valid ? null : this.value;

    return this.type === 'PERCENTAGE'
      ? this.negotiations.percentageFormatter.format(value)
      : this.negotiations.currencyFormat.format(value);
  };

  Amenity.prototype.getFormattedSecondaryValue = function(){
    let primaryRateValue = this.negotiation.getPrimaryRateValue(),
      value = this.type === 'UNAVAILABLE' || !this.valid ? null : this.value;

      return this.type === 'PERCENTAGE'
      ? this.negotiations.currencyFormat.format(primaryRateValue && primaryRateValue*value)
      : this.negotiations.percentageFormatter.format(primaryRateValue && value/primaryRateValue);
  };

  return {
    create: (amenityId, amenityData, negotiation, negotiations)=>{
      return new Amenity(amenityId, amenityData, negotiation, negotiations);
    }
  };
}
