/**
 * Created by DejanK on 12/19/2016.
 *
 * adds a class when element is scrolled
 *
 * rbClassOnScroll: class name to be added
 * targetElement: element in the document to target. Finds element by id. By default this is directive element.
 */

'use strict';

module.exports = rbClassOnScroll;

rbClassOnScroll.$inject = ['$document'];
function rbClassOnScroll($document){
  return {
    scope: {
      rbClassOnScroll: '@',
      targetElement: '@'
    },
    link: function(scope, element){
      let targetElement = scope.targetElement ? angular.element($document[0].getElementById(scope.targetElement)) : element;
      element.on('scroll', ()=>{
        element[0].scrollTop > 1 ? targetElement.addClass(scope.rbClassOnScroll) : targetElement.removeClass(scope.rbClassOnScroll);
      })
    }
  };
}
