/**
 * Created by DejanK on 03/20/2017.
 */
import { abstractState, simpleState } from "../../shared/tools/router-helper";
import { GUEST } from "../../auth/auth-roles";
import { HTML as SignUpHtml, Controller as SignUpController} from './sign-up/sign-up.ctrl'
import { HTML as VerifyEmailHtml, Controller as VerifyEmailController} from './verify-email/verify-email.ctrl'
import { HTML as SignInHtml, Controller as SignInController} from './sign-in/sign-in.ctrl'
import { HTML as ForgottenPasswordEnterEmailHtml, Controller as ForgottenPasswordEnterEmailController} from './forgotten-password/enter-email-address.ctrl'
import { HTML as ForgottenPasswordVerifyEmailHtml, Controller as ForgottenPasswordVerifyEmailController} from './forgotten-password/verify-email-address.ctrl'
import { HTML as ForgottenPasswordSetPasswordHtml, Controller as ForgottenPasswordSetPasswordController} from './forgotten-password/set-password.ctrl'

export default States

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state('signUp', simpleState('/sign-up', SignUpHtml, SignUpController, GUEST))
    .state('verifyEmailAddress', simpleState('/verify-email-address?token', VerifyEmailHtml, VerifyEmailController))
    .state('signIn', simpleState('/sign-in?redirect', SignInHtml, SignInController, GUEST))

    .state('forgottenPassword', abstractState('/forgotten-password', GUEST))
    .state('forgottenPassword.enterEmailAddress', simpleState('/enter-email-address', ForgottenPasswordEnterEmailHtml, ForgottenPasswordEnterEmailController))
    .state('forgottenPassword.verifyEmailAddress', simpleState('/verify-email-address?token', ForgottenPasswordVerifyEmailHtml, ForgottenPasswordVerifyEmailController))
    .state('forgottenPassword.setPassword', simpleState('/set-password?token', ForgottenPasswordSetPasswordHtml, ForgottenPasswordSetPasswordController))
}
