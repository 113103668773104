/**
 * Created by DejanK on 4/4/2017.
 */
export default RfpHotelRfpDestinationsRepository

RfpHotelRfpDestinationsRepository.$inject = ['MainServer', '$q'];
function RfpHotelRfpDestinationsRepository(mainServer, $q){
  const $repo = this;

  $repo.getRfpTravelDestinations =
    (rfpId) => {return rfpId
      ? mainServer.get(`rfps/${rfpId}/travel-destinations`)
      : $q.reject({status: 404})};

  $repo.listDestinationProperties =
    (rfpId, destinationId) => {return rfpId && destinationId
      ? mainServer.get(`rfps/${rfpId}/travel-destinations/${destinationId}/properties`)
      : $q.reject({status: 404})};

  $repo.createRfpTravelDestination =
    (travelDestination, rfpId, type) => {
      return rfpId
        ? mainServer.post(`rfps/${rfpId}/travel-destinations/${type === 'CITY' ? 'city' : 'office'}`, travelDestination)
        : $q.reject({status: 404})};

  $repo.createRfpTravelDestinations =
    (travelDestinations, rfpId) => {
      return rfpId
        ? mainServer.post(`rfps/${rfpId}/travel-destinations/bulk`, {travelDestinations: travelDestinations})
        : $q.reject({status: 404})};

  $repo.updateRfpTravelDestination =
    (travelDestination, rfpId, type) => {
      return rfpId && travelDestination && travelDestination.id
        ? mainServer.put(`rfps/${rfpId}/travel-destinations/${type === 'CITY' ? 'city' : 'office'}/${travelDestination.id}`, travelDestination)
        : $q.reject({status: 404})};

  $repo.deleteRfpTravelDestination =
    (destinationId, rfpId) => {
      return rfpId && destinationId
        ? mainServer.delete(`rfps/${rfpId}/travel-destinations/${destinationId}`)
        : $q.reject({status: 404})};

  $repo.getRfpTravelDestination =
    (rfpId, destinationId) => {return rfpId && destinationId
      ? mainServer.get(`rfps/${rfpId}/travel-destinations/${destinationId}`)
      : $q.reject({status: 404})};

  $repo.geoSearchHotels =
    (rfpId, destinationId, query) => {return rfpId && destinationId && query
      ? mainServer.post(`rfps/${rfpId}/travel-destinations/${destinationId}/geo-search`, query)
      : $q.reject({status: 404})};

  $repo.addToBidManager =
    (rfpId, data) => {return rfpId
      ? mainServer.post(`rfps/${rfpId}/bids`, data)
      : $q.reject({status: 404})};

  $repo.removeFromBidManager =
    (rfpId, bidId) => {return rfpId && bidId
      ? mainServer.delete(`rfps/${rfpId}/bids/${bidId}`)
      : $q.reject({status: 404})};

  $repo.saveDestinationFilter =
    (rfpId, destinationId, filter) => {return rfpId && destinationId && filter
      ? mainServer.put(`rfps/${rfpId}/travel-destinations/${destinationId}/filter`, filter)
      : $q.reject({status: 404})};

  $repo.setRfpTravelDestinationsDefaultFilter = (rfpId, filter) => {return rfpId && filter
    ? mainServer.put(`rfps/${rfpId}/set-default-filter`, filter)
    : $q.reject({status: 404})
  };

  $repo.listChains = () => { return mainServer.get('hotel-chains/all') }
}
