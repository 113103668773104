/**
 * Created by DejanK on 4/26/2017.
 *
 * Bid Manager Filter By
 */
import HTML from './rb-bid-manager-filter-by.html'

export default {
  template: HTML,
  bindings: {
    filteredBy: '<',
    filterableBy: '<',
    onChange: '&'
  },
  controller: RbBidManagerFilterBy
}

RbBidManagerFilterBy.$inject = ['$scope'];
function RbBidManagerFilterBy($scope) {
  const vm = this

  vm.$onChanges = changes => {
    if(changes.filterableBy && vm.filterableBy){
      vm.filterItems = false
      $scope.$timeout( () => { vm.filterItems = vm.filterableBy })
    }
  }

  vm.onFilterByChanged = onFilterByChanged;

  function onFilterByChanged(){
    vm.onChange({event: {filterBy: vm.filteredBy}})
  }
}
