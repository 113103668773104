/**
 * Created by DejanK on 4/5/2017.
 */
'use strict';

require('./rb-bid-menu.scss');

function RbBidMenuDirective(){
  return {
    restrict: 'A',
    scope: {
      'rbBidMenu': '=',
      'view': '='
    },
    controller: Ctrl
  };
}

Ctrl.$inject = ['BidManagerBidActionsService', '$document', '$compile', '$timeout', '$scope', '$element'];
function Ctrl(BidActionsService, $document, $compile, $timeout, $scope, $element) {

  $element.bind('contextmenu', ($event)=> {
    if(!$event.shiftKey){
      $event.stopPropagation();
      $event.preventDefault();
      $element.addClass('rb-bid-menu-focus');
      $scope.actions = BidActionsService.getActions([$scope.rbBidMenu], $scope.view);
      showMenu(angular.element($document[0].body), $scope, $event);
    }
  });

  function showMenu(element, $scope, $event) {
    let menu = createMenu($scope, $event);
    $timeout(element.append(menu));
  }

  function createMenu($scope, $event) {
    let overlayElement = angular.element(`<div class="rb-bid-menu-overlay"></div>`),
      menu = angular.element(require('./rb-bid-menu.html')),
      menuScope = $scope.$new(false, $scope);

    overlayElement.append(menu);
    setMenuPosition(menu, $event);
    overlayElement.bind('click contextmenu', destroyMenu);
    menu.bind('click contextmenu', destroyMenu);

    return $compile(overlayElement)(menuScope);

    function destroyMenu($event) {
      $element.removeClass('rb-bid-menu-focus');
      $event.stopPropagation();
      $event.preventDefault();
      overlayElement.remove();
      menuScope.$destroy();
    }

    function setMenuPosition(menuElement, $event) {
      let maxHeight = 300,
        maxWidth = 250,
        body = $document[0].body;

      if (body.clientHeight > $event.pageY + maxHeight) {
        menuElement.css('top', $event.pageY + 'px');
      } else {
        menuElement.css('bottom', body.clientHeight - $event.pageY + 'px');
      }

      if (body.clientWidth > $event.pageX + maxWidth) {
        menuElement.css('left', $event.pageX + 'px');
      } else {
        menuElement.css('right', body.clientWidth - $event.pageX + 'px');
      }
    }
  }
}

module.exports = RbBidMenuDirective;
