/**
 * Created by DejanK on 2/9/2017
 *
 * Due Date Editor
 */

'use strict';

module.exports = {
  bindings: {
    show: '<',
    onClose: '&',
    dueDate: '<'
  },
  template: require('./rb-edit-due-date.html'),
  controller: Ctrl
};

Ctrl.$inject = [];
function Ctrl(){
  let vm = this;
  vm.cancel = cancel;
  vm.apply = apply;
  vm.$onChanges = $onChanges;

  function $onChanges(changes){
    changes.dueDate && resetDueDateModel();
  }

  function resetDueDateModel(){
    vm.dueDateModel = vm.dueDate;
  }

  function cancel(){
    resetDueDateModel();
    vm.onClose({
      $event: {
        cancel: true,
        dueDate: undefined
      }
    });
  }

  function apply(){
    vm.onClose({
      $event: {
        apply: true,
        dueDate: vm.dueDateModel
      }
    });
  }
}
