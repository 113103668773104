import Vue from 'vue'
import invitationsHandler from './invitationsHandler.vue';

export default {
  template: '<div></div>',
  controller: Ctrl
}

Ctrl.$inject = ['$element', '$stateParams'];
function Ctrl($element, $stateParams){
  const vm = this;
  let vueComponent, token;

  vm.$onInit = () => {
    token = $stateParams.token;

    initVueComponent();
  };

  vm.$onDestroy = () => { return vueComponent && vueComponent.$destroy() };

  function initVueComponent(){
    return !vueComponent && initVue();

    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { token },
        render: function (createElement) {
          return createElement(invitationsHandler, {
            props: {
              token
            },
          })
        }
      })
    }
  }
}
