<script>
  import { htmlSanitizer } from 'root/v-app/HtmlSanitizer'

  /**
   * @name RbSafeHtml
   *
   * @description
   * Sanitizes String provided in html prop using global HtmlSanitizer. This Component should be used instead of XSS
   * unsafe v-html directive.
   *
   * @prop{string} html HTML String to sanitize and print
   *
   * @usage:
   * <rb-safe-html :html="htmlString" />
   */
  export default {
    functional: true,
    render (h, { props, data }) {
      const sanitized = htmlSanitizer.sanitizeString(props.html || '');
      return h( 'div', {
        domProps: { innerHTML:  sanitized},
        class: [ data.class, data.staticClass ]
      } );
    }
  }
</script>
