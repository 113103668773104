/**
 * Created by DejanK on 2/20/2017
 *
 * Edit Questionnaire side menu
 */

'use strict';

module.exports = {
  template: require('./rb-edit-questionnaire-side-menu.html')
};
