/**
 * Created by DejanK on 7/6/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = SeasonsFactory;

SeasonsFactory.$inject = ['NegotiationsHtmlFactory'];
function SeasonsFactory(NegotiationsHtmlFactory){

  function Seasons(seasonsData, negotiations){
    this.seasons = seasonsData;
    this.negotiations = negotiations;
  }

  Seasons.prototype.count = function(){
    return this.seasons.length;
  };

  Seasons.prototype.getFormattedPeriod = function(seasonId){
    let season = lodash.find(this.seasons, {id: parseInt(seasonId)}),
      dateFormat = this.negotiations.dateFormatter;
    return `${dateFormat.format(season.start)} - ${dateFormat.format(season.end)}`;
  };

  Seasons.prototype.getFormattedPeriodByIndex = function(seasonIndex){
    let season = this.seasons[seasonIndex],
      dateFormat = this.negotiations.dateFormatter;
    return `${dateFormat.format(season.start)} - ${dateFormat.format(season.end)}`;
  };

  Seasons.prototype.getLabelByIndex = function(seasonIndex){
    return this.seasons[seasonIndex].id;
  };

  return {
    create: (seasonsData, negotiations)=>{
      return new Seasons(seasonsData, negotiations);
    }
  };
}
