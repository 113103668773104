import Vue from 'vue';
import noop from 'lodash/noop';

export default {
  template: '<div></div>',
  bindings: {
    rfp: '<',
  },
  controller: Ctrl,
}

Ctrl.$inject = ['$element', '$state'];
function Ctrl($element, $state){
  const
    vm = this;

  let
    vueComponent = null;

  Object.assign(this, {

    $onInit () {
      import( /* webpackChunkName: 'TheViewHotelRateQuickRfpNavigation' */ 'vRoot/rfp-hotel/rfp/rate-quick/view/TheViewHotelRateQuickRfpNavigation.vue')
        .then( imported => {
          vueComponent = new Vue({
            el: $element[0],
            render ( h ) {
              return h( imported.default, {
                props: {
                  rfpName: vm.rfp.specifications.name,
                  chainSupport: vm.rfp.specifications.chainSupport,
                  active: $state.current.name,
                  hrefFactory: state => $state.href(state),
                },
              });
            },
          });
        })
        .catch( noop );
    },

    $onDestroy () {
      if(vueComponent) {
        vueComponent.$destroy();
      }
    },
  });
}
