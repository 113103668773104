/**
 * Created by DejanK on 2018-01-08
 */
import HTML from './show-instructions.html'
import './show-instructions.scss'
import loadDestinations from 'root/rfp-hotel/main/rfp/destinations/upload-destinations'
import {logError} from "root/log/log";

const NAME = 'uploadTravelDestinationShowInstructions'

export { NAME, HTML, uploadDestinationShowInstructionsController as Controller }

uploadDestinationShowInstructionsController.$inject = ['rbDialog', '$scope']
function uploadDestinationShowInstructionsController(dialog, $scope) {
  const vm = this
  vm.cancel = dialog.cancel
  vm.state = null

  vm.onFileSelected = () => {
    if (vm.files && vm.files.length) {
      vm.progress = 0
      vm.state = 'WORKING'

      loadDestinations(vm.files[0], progress => { $scope.$applyAsync(()=>{ vm.progress = progress}) })
        .then(destinations => dialog.hide({ filename: vm.files[0].name, destinations: destinations}), showError)
    } else {
      showError()
    }

    function showError ( error ) {
      vm.state = 'ERROR'
      logError(error)
    }
  }
}
