import Vue from 'vue'

export default {
  template: '<div class="layout-column flex-center height-100"><rb-loading /></div>',
  bindings: {
    model: '<',
    onChange: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){

  const
    vm = this;

  let
    vueComponent = null;

  this.$onInit = () => {
    initVueComponent();
  }

  this.$onDestroy = () => {
    if(vueComponent) {
      vueComponent.$destroy();
    }
  }

  function initVueComponent(){
    if(!vueComponent) {
      vueComponent = 'loading';
      import( /* webpackChunkName: "RichTextareaLetterEditor" */ './RichTextareaLetterEditor.vue' )
        .then( imported => {
          vueComponent = new Vue({
            el: $element[0],
            render (createElement) {
              return createElement(imported.default, {
                props: {
                  model: vm.model.template,
                  placeholders: vm.model.placeholders,
                },
                on: {
                  change (event) {
                    vm.onChange({
                      $event: {
                        letter: event.data
                      }
                    })
                  }
                }
              });
            }
          });
        });
    }
  }
}
