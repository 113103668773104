
'use strict';
import HTML from './view-cover-letter.html';

export { HTML, BidCoverLetterViewController as Controller }

BidCoverLetterViewController.$inject = ['ControllerUtils', 'RfpHotelBidRepository'];
function BidCoverLetterViewController(utils, RfpHotelBidRepository){
  const vm = this;

  $onInit();

  function $onInit(){
    vm.bidId = utils.getParam('bidId');

    utils.loadTo(vm, 'bid', ()=>{
      return RfpHotelBidRepository.getBidPreview(vm.bidId);
    });
  }
}
