<template>
  <modal-form
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <i
        class="material-icons"
        :class="$style.warning"
      >
        error_outline
      </i>
      <div :class="$style.texts">
        <div>{{ title }}</div>
        <div :class="$style.subHeader">
          Are You Sure?
        </div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <div :class="$style.message">
        {{ subTitle }}
      </div>
    </div>
    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        v-if="showCancel"
        class="rbButton"
        type="button"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        class="rbButton mainButton"
        :class="$style.mainButton"
      >
        <i class="material-icons">check</i>
        {{ mainButtonText }}
      </button>
    </div>
  </modal-form>
</template>

<script>

import ModalForm from 'vRoot/_core/RbModalForm.vue'
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'

export default {
    name: 'WarningDialog',
    components: { ModalForm },
    mixins: [ RbFormMixin, DialogMixin ],
    props: {
      title: {
        type: String,
        default: ''
      },
      subTitle: {
        type: String,
        required: true
      },
      showCancel: {
        type: Boolean,
        default: false
      },
      mainButtonText: {
        type: String,
        default: 'Yes, Continue'
      }
    },
    methods: {
        onSubmit(){
            return {};
        },
    }
}
</script>

<style lang="stylus" module>

    .dialog {
        width: 450px;
        overflow visible
    }

    .subHeader{
        font-size 25px;
        line-height 14pt;
        color #78909c;
        font-weight lighter;
    }

    .header{
        display flex;
        font-weight lighter;
    }

    .warning{
        height: 50px;
        width: 50px;
        font-size: 50px;
        color: #f15a24;
        margin-right 10px;
    }

    .texts{
        padding 5px;
        font-size: 14px;
        color: #78909c;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mainButton{
        background : #F15A24 !important;
        border none !important;
    }

    .message{
        font-size 12px;
        color: #78909c;
    }
</style>
