/**
 * Created by DejanK on 9/1/2016.
 */

'use strict';

const lodash = require('lodash')

module.exports = SuccessCtrl;

require('../notices.scss');

SuccessCtrl.$inject = ['$stateParams', 'MessagesService'];
function SuccessCtrl($stateParams, messagesService) {
  var vm = this, requestData;
  vm.genericMessage ={
    "id": "genericMessage",
    "title": "It's done.",
    "subtitle": "All done!",
    "header": "Action was successfully performed",
    "image":{
      "location":"/images/success.png",
      "alt":"Success"
    },
    "action": {
      "text": "Back to Homepage",
      "state": "Home"
    }
  };
  initialize();

  function initialize() {
    vm.loading = true;
    vm.successId = $stateParams.id;
    requestData = $stateParams.data && JSON.parse(atob($stateParams.data));

    loadMessage();
  }

  function setMessage(msg) {
    vm.message = msg ? lodash.merge(msg, requestData) : vm.genericMessage;
    vm.loading = false;
  }

  function loadMessage() {
    return messagesService.getMessageById(vm.successId, 'success')
      .then( setMessage, lodash.noop )
  }
}
