/**
 * Created by DejanK on 4/5/2017.
 *
 *
 */

'use strict';

module.exports = BidManagerBidActionsService;

BidManagerBidActionsService.$inject = ['BidManagerSingleBidActionsService', 'BidManagerBulkBidActionsService'];
function BidManagerBidActionsService(SingleBidActions, BulkBidActions) {
  let $service = this;

  $service.getActions = getActions;

  function getActions(selectedBids, view){
    if(!selectedBids || selectedBids.length === 0) return null;
    if(selectedBids.length === 1){
      return SingleBidActions.getActions(selectedBids[0], view.$view);
    } else {
      return BulkBidActions.getActions(selectedBids, view.$view);
    }
  }
}


