/**
 * Created by DejanK on 9/7/2016.
 */
import './switch-account.scss'
import HTML from './switch-account.html'
import noop from 'lodash/noop';
import { stopEvent } from '../../../shared/tools/view-utils';
import { PAGE_HOTEL_DIRECTORY } from 'root/states';

const NAME = 'switchAccount';

export { NAME, HTML, SwitchAccountController as Controller };

SwitchAccountController.$inject = ['NotificationUtils', 'CurrentUser', 'rbDialog', '$q', '$state'];
function SwitchAccountController(notificationUtils, currentUser, dialog, $q, $state){
  const vm = this;
  vm.accountName = '';
  vm.userAccounts = 'loading';

  vm.cancel = dialog.cancel;
  vm.switchTo = switchTo;

  onInit();

  function onInit(){
    notificationUtils.onLoad(() => {
      return $q.all({
        user: currentUser.get(),
        userAccounts: currentUser.listUserAccounts()
      })
        .then(( responses ) => {

          const
            userAccounts = responses.userAccounts.data,
            currentUserAccount = responses.user.currentUserAccount;

          vm.accountName = currentUserAccount.account.name;
          userAccounts.splice( userAccounts.findIndex( ua => ua.id === currentUserAccount.id ), 1 );
          return userAccounts;
        })
    }, vm, 'userAccounts');
  }

  function switchTo($event, accountId){
    stopEvent($event);
    currentUser.switchToAccount(accountId)
      .then(( user ) => {
        dialog.hide();

        if(user.isAccountType('COMPANY') && $state.current.name === PAGE_HOTEL_DIRECTORY) {
          $state.go(PAGE_HOTEL_DIRECTORY, { submodule: null, view: null }, { reload: true });
        } else {
          $state.go('bidManager', { viewId: null }, { reload: true });
        }
      })
      .catch( noop );
  }
}
