import './styles/app.scss';
import './styles/custom-tags.scss';
import 'tooltipster';
import VueApp from './v-app/vue-app.js';

// import to fix removal of public-profile module, and provide styling for hotel profile modal
import './public-profile.scss';
import BrowserUpdate from './browser-update-config';
import AngularApp from './angular-app';
import TooltipListener from 'root/core/ui/tooltip/tooltip-listener';

BrowserUpdate();
VueApp();
TooltipListener();
export default AngularApp;

