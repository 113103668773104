/**
 * Created by DejanK on 11/29/2018.
 *
 * rbPublicHeader Component
 */
import HTML from './rb-public-header.html'

export default {
  template: HTML,
  controller: RbHeaderCtrl
}

RbHeaderCtrl.$inject = ['$scope', 'CurrentUser']
function RbHeaderCtrl($scope, currentUserService){
  const vm = this

  vm.$onInit = () => { currentUserService.watchUser($scope, vm) }
}
