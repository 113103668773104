import HTML from './bid-response.html';
import get from 'lodash/get';

const
  // states
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',

  // hotel RFP subtypes
  STANDARD = 'STANDARD',
  RATE_QUICK = 'RATE_QUICK';

export { HTML, BidResponsePreloadController as Controller }

BidResponsePreloadController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$stateParams'];
function BidResponsePreloadController(utils, rfpHotelBidRepository, $stateParams){
  const
    vm = this;

  vm.isLoading = () => vm.state === LOADING;
  vm.isLoaded = () => vm.state === LOADED;
  vm.showStandard = () => vm.isLoaded() && isSubtype(STANDARD);
  vm.showOther = () => vm.isLoaded() && isSubtype(RATE_QUICK);

  $onInit();

  function $onInit(){
    vm.state = LOADING;
    utils.onLoad( () => rfpHotelBidRepository.getBidResponseDraft($stateParams.bidId) )
      .then( httpResponse  => {
        vm.bid = httpResponse.data;
        vm.state = LOADED;
      })
      .catch(() => {
        vm.state = ERROR;
      });
  }

  function isSubtype( subType ){
    return get(vm.bid, 'specifications.subType', STANDARD) === subType;
  }
}
