<template>
  <input
         :value="value"
         :readonly="readonly"
         @focus="selectAll"
         @input="$emit('input', $event.target.value)"
         @blur="$emit('blur', $event.target.value)"
  />
</template>

<script>
  export default {
    name: 'rb-questionnaire-form-table-question-text',

    props: {
      value: { "default": undefined },
      readonly: {
        "type": Boolean,
        "default": false,
      }
    },

    methods: {
      selectAll (event) { event.target.select() },
    }
  }
</script>
