/**
 * Created by DejanK on 11/04/2016.
 */
import '../../../../shared/styles/account-setup.scss'
import { noop } from 'lodash'
import HTML from './new-account.html'

export { HTML, NewAccountController as Controller }

NewAccountController.$inject = ['NotificationUtils', 'AccountService', 'CurrentUser', '$state', 'rbDialog'];
function NewAccountController(notificationUtils, accountService, currentUser, $state, dialog){
  const vm = this;
  vm.confirmAccount = confirmAccount;
  init()

  function init(){
    vm.entity = {}

    notificationUtils.onLoad(() => { return accountService.getAccountType($state.params.affiliation) },
      vm, 'affiliation')
      .finally(() => {
        if(vm.affiliation){
          vm.entity.type = vm.affiliation.id;
          if(vm.entity.type !== 'COMPANY'){
            vm.industryReadOnly = true
            vm.entity.industry = 'TRAVEL_AND_HOSPITALITY'
          }
        } else {
          $state.go('account.create.affiliation')
        }
      });
  }

  function confirmAccount(isFormValid){
    vm.showForm = true
    isFormValid && dialog.show('confirmAccount', {locals: {account: vm.entity}})
      .then(saveAccount)
      .then(showSuccessPage, noop)

    function saveAccount(jobTitle){
      return notificationUtils.onSave(() => { return accountService.createAccount({entity: vm.entity, jobTitle: jobTitle}) },
        { customErrorHandlers: { 409 () {notificationUtils.showError('duplicate-account')} } })
    }

    function showSuccessPage(){
      currentUser.get()
        .then(( user ) => {
          notificationUtils.showSuccess(user && user.getCreateAccountSuccessPage() || 'user-account-created')
        })
    }
  }
}
