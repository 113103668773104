import agentInvitationTypeHandlerModule from './agent/agentInvitationsHandler';
import supplierContactChangehandlerModule from './supplier-contact-change/supplierContactChangeInvitationsHandler';

export default {
  loadModules: loadModules
}

function loadModules(){
  const availableModules = [];

  load(agentInvitationTypeHandlerModule);
  load(supplierContactChangehandlerModule);

  return availableModules;

  function load(module){
    availableModules.push(module)
  }
}
