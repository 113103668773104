/**
 * Created by DejanK on 10/14/2016.
 */
export { isValidToken }

function isValidToken(token) {
  try {
    const payload = JSON.parse(window.atob(token.split('.')[1]));
    return new Date(payload.exp * 1000) > new Date();
  } catch (e) {
    return false;
  }
}
