/**
 * Created by DejanK on 12/17/2016.
 *
 * rbPublicFooter Component
 */
import './rb-home-footer.scss'
import HTML from './rb-home-footer.html'
import {DEMO} from "root/config/app.config"
import {stopEvent} from "root/shared/tools/view-utils"
import { NAME as NotImplementedNotification } from "root/ui-ng/notifications/dialogs/not-implemented/not-implemented-notification.ctrl"
import { noop } from 'lodash'

export default {
  template: HTML,
  controller: HomeFooterController
}

HomeFooterController.$inject = [ 'rbDialog' ]
function HomeFooterController ( dialog ){
  const vm = this

  vm.isDemo = DEMO
  vm.showNotImplementedNotification = $event => {
    stopEvent($event)
    dialog.show( NotImplementedNotification ).catch( noop )
  }
}
