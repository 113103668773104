/**
 * Created by DejanK on 7/24/2017.
 *
 */

'use strict';

let lodash = require('lodash');

module.exports = NegotiationsEditorTooltipsFactory;

NegotiationsEditorTooltipsFactory.$inject = [];
function NegotiationsEditorTooltipsFactory(){

  function NegotiationsEditorTooltip(controller, $element, html){
    let self = this;

    this.$element = $element;
    controller.$onDestroy = ()=>{ self.destroy() };
    this.html = html;
    this.tooltipster = false;
  }

  NegotiationsEditorTooltip.prototype = {
    constructor: NegotiationsEditorTooltip,

    enable: function(){
      if(this.tooltipster) {
        this.tooltipster.enable()
      } else {
        let self = this;

        this.parentElement = this.$element.parent();
        this.htmlContentElement = $(this.$element.children()[0]);
        this.tableElement = $(this.htmlContentElement.find('div.table'));

        this.parentElement.tooltipster({
          //animationDuration: [300, 100],
          arrow: false,
          theme: ['', 'negotiations-input-tooltip'],
          content: this.htmlContentElement,
          contentAsHtml: true,
          interactive: true,
          side: ['top'],
          zIndex: 999998,
          functionPosition: (instance, helper, coords)=>{return self._tooltipsterPosition(instance, helper, coords)},
          functionBefore: ()=>{self._tooltipsterBefore()},
          functionReady: ()=>{ self._tooltipsterReady()}
        });

        this.tooltipster = this.parentElement.tooltipster('instance');
      }
    },

    disable: function(){
      this.tooltipster && this.tooltipster.disable()
    },

    _tooltipsterPosition: function(instance, helper, coords){
      let origin = helper.geo.origin;
      coords.coord.top += origin.size.height - 27;
      coords.coord.left = origin.windowOffset.left - 186;
      return coords;
    },

    _tooltipsterBefore: function (){
      this.tableElement.html(this.html);
    },

    _tooltipsterReady: function (){
      let activeElements = this.tableElement.find('div.layout-row.active');
      if(activeElements.length) {
        scrollTo(this.tableElement, $(activeElements[0]));
      } else {
        this.tableElement[0].scrollTop = this.tableElement[0].scrollHeight;
      }

      function scrollTo(container, scrollTo){
        container.scrollTop(
          scrollTo.offset().top - container.offset().top + container.scrollTop() - (container.height()/2)
        );
      }
    },

    destroy: function(){
      this.tooltipster && this.tooltipster.destroy();
    }
  };


  /**
   * Amenity Tooltip
   */
  function AmenityTooltip(controller, $element, html, hasTwoColumns){
    NegotiationsEditorTooltip.call(this, controller, $element, html);
    this.hasTwoColumns = hasTwoColumns;
  }

  AmenityTooltip.prototype = Object.create(NegotiationsEditorTooltip.prototype, {
    constructor: {value: AmenityTooltip},

    _super: {value: NegotiationsEditorTooltip.prototype},

    _tooltipsterPosition: {value: function (instance, helper, coords){
      let origin = helper.geo.origin;
      coords.coord.top += origin.size.height - 27;
      coords.coord.left = origin.windowOffset.left - (this.hasTwoColumns ? 172 : 186);
      return coords;
    }}
  });

  return {
    createRateTooltip: (controller, $element, html)=>{
      return new NegotiationsEditorTooltip(controller, $element, html);
    },
    createAmenityTooltip: (controller, $element, html, hasTwoColumns)=>{
      return new AmenityTooltip(controller, $element, html, hasTwoColumns);
    }
  }
}
