/**
 * Created by DejanK on 4/5/2017.
 *
 * Creates
 */

export default BidManagerRepo

BidManagerRepo.$inject = ['MainServer', '$q'];
function BidManagerRepo(mainServer, $q){
  const $repo = this;

  $repo.getUserViews = () => mainServer.get(`bid-managers`);

  $repo.getRfpTravelDestinations =
    rfpId => { return rfpId
      ? mainServer.get(`bid-managers/travel-destinations/rfps/${rfpId}`)
      : $q.reject({status: 404})};

  $repo.getTravelDestinations = () => mainServer.get(`bid-managers/travel-destinations`);

  $repo.getView =
    viewId => { return viewId
      ? mainServer.get(`bid-managers/${viewId}`)
      : $q.reject({status: 404})};

  $repo.getViewByRfpId =
    rfpId => { return rfpId
      ? mainServer.get(`bid-managers/rfps/${rfpId}`)
      : $q.reject({status: 404})};

  $repo.queryBids =
    query => { return query
      ? mainServer.post(`rfps/bids/query`, query)
      : $q.reject({status: 400})}

  $repo.getBidContactLastActive =
    bidId => { return bidId
      ? mainServer.get(`rfps/hotel/representatives/bid/${bidId}/last-active`)
      : $q.reject({status: 400})}
}
