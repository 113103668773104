import { checkAndExecute } from 'root/api/helpers';

export default function RfpAPI(api) {

  Object.assign(api, {

    listRfpTemplates: rfpType => checkAndExecute(
      () => rfpType,
      () => api.server.get(`rfps/templates`, { 'rfp-type': rfpType })),

    getRfpTemplatePreview: ( accountId, templateId ) => checkAndExecute(
      () => accountId && templateId,
      () => api.server.get(`rfps/templates/${templateId}/preview`, { account: accountId })),

    getAccountRfpPreview: ( accountId, rfpId ) => checkAndExecute(
      () => accountId && rfpId,
      () => api.server.get(`rfps/${rfpId}/preview-for-account`, { account: accountId })),

    useRfpTemplate: ( accountId, rfpTemplateId ) => checkAndExecute(
      () => accountId && rfpTemplateId,
      () => api.server.post(`rfps`, { rfpTemplateId, accountId })),
    
    duplicateRfp: (accountId, rfpId, templateId) => checkAndExecute(
      () => accountId && rfpId && templateId,
      () => api.server.post(`rfps/${rfpId}/duplicate/`, {accountId, templateId})
    )
  });
}
