/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Custom Actions
 */
import {DEMO} from "root/config/app.config"
import './rb-bid-manager-custom-actions.scss'
import HTML from './rb-bid-manager-custom-actions.html'

export default{
  template: HTML,
  bindings: {
    bidManager: '<'
  },
  controller: RbBidManagerCustomActions
}

RbBidManagerCustomActions.$inject = []
function RbBidManagerCustomActions() {
  const vm = this

  vm.shouldShowAddHotels = view => view.rfpType === 'HOTEL' && view.side === 'BUYER'
  vm.shouldShowRateAudit = view => DEMO && view.side === 'BUYER'
}
