const samples = [{
    "id" : "5be40e768ab398dc0f8c3bf6",
    sample: true,
    currency: "CAD",
    "bidId": "5b72cae0154c05fd20c00cb8",
    "directoryId":"5bbda83b5757422d016feed6",
    "company":{
        "name":"Sample hotel name",
        "type":"HOTEL","industry":
        "TRAVEL_AND_HOSPITALITY",
        "phone":"81334334141",
        "location":{
            "address":{
                "address1":"1-5-10 Shibakoen",
                "address2":"",
                "city":"Tokyo",
                "region":"",
                "zip":"105 0011",
                "country":"JP",
                "stateOrRegion":"",
                "fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan",
                "shortAddress":"1-5-10 Shibakoen, Tokyo, Japan",
                "countryName":"Japan"
            },
            "fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan",
            "coordinates":{
                "latitude":35.6588208,
                "longitude":139.7519103
            }
        },
        "chain":{
            "id":"5aec27946588c041dad049c8",
            "type":"CHAIN","name":"Pegasus Connect",
            "industry":"TRAVEL_AND_HOSPITALITY",
            "created":{"at":946684800000},"status":{"value":"ACTIVE","at":946684800000},
            "code":"UZ",
            "subtype":"BRAND",
            "active":true,
            "statusValue":"ACTIVE"
        },
        "category":{"id":1,"label":"Hotel"},
        "rating":3,
        "amenities":["parking","airConditioning","wifi","roomService","americanExpress","luggageStorage","privateBathroom","airportTransportation","dryCleaningLaundryService","highSpeedWiredInternet","visa","breakfast","refrigerator","jcbInternational","dinersClubCarteBlanche","masterCard"],
        "image":{"url":"/images/hotel_placeholder.png","caption":"Featured Image","width":350,"height":350},
        "sabreCode":"10305",
        "brandChainName":"Pegasus Connect",
        "address":{"address1":"1-5-10 Shibakoen","address2":"","city":"Tokyo","region":"","zip":"105 0011","country":"JP","stateOrRegion":"","fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan","shortAddress":"1-5-10 Shibakoen, Tokyo, Japan","countryName":"Japan"},
        "coordinates":{"latitude":35.6588208,"longitude":139.7519103},
        "fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan"
    },
    "contact":{
        "id":"5bc71312005a1a0556a17c30",
        "firstName":"Gaston",
        "lastName":"Che","fullName":"Gaston Che",
        "emailAddress":"gastonnkh@gmail.com",
        "company":{"entityId":"56014c099537801174120161","accountId":"5b72cae0154c05fd20c00cb7","name":"SHIBA PARK HOTEL","type":"HOTEL","industry":"TRAVEL_AND_HOSPITALITY","phone":"81334334141","location":{"address":{"address1":"1-5-10 Shibakoen","address2":"","city":"Tokyo","region":"","zip":"105 0011","country":"JP","stateOrRegion":"","fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan","shortAddress":"1-5-10 Shibakoen, Tokyo, Japan","countryName":"Japan"},"fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan","coordinates":{"latitude":35.6588208,"longitude":139.7519103}},"address":{"address1":"1-5-10 Shibakoen","address2":"","city":"Tokyo","region":"","zip":"105 0011","country":"JP","stateOrRegion":"","fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan","shortAddress":"1-5-10 Shibakoen, Tokyo, Japan","countryName":"Japan"},"coordinates":{"latitude":35.6588208,"longitude":139.7519103},"fullAddress":"1-5-10 Shibakoen, Tokyo 105 0011, Japan"},
        "phone":"671446226",
        "jobTitle":"Manager",
        "companyName":"SHIBA PARK HOTEL",
        "companyAccountId":"5b72cae0154c05fd20c00cb7",
        "user":true,
        "companyOrUserEmailAddress":"gastonnkh@gmail.com"
    },
    "rating":"U",
    "travelDestination":{"id":"5b72ca90154c05fd20c00cb2","rfpId":"5b72ca6a154c05fd20c00cb1","type":"OFFICE","name":"Tokyo Office","estimatedSpend":200,"estimatedRoomNights":12,"location":{"address":{"address1":"Tokyo Tower Street","city":"Minato-ku","region":"Tōkyō-to","country":"JP","countryFullName":"Japan"},"fullAddress":"Tokyo Tower Street, Minato-ku Tōkyō-to, Japan","coordinates":{"lat":35.65937042236328,"lng":139.7449493408203}},"filter":{"maxDistance":{"value":5.0,"unit":"MI"}},"created":{"at":1534249616118},"status":{"at":1534249616118,"value":"CREATED"}},
    "analytics":{"address":{"country":"Japan","city":"Tokyo, Japan","stateRegion":"Japan"},"distanceKm":0.6318564824857184,"currency":"USD","distanceMi":0.39261741584503895},
    "seasons":[
        {
            "id":"1",
            "start":"2019-01-01",
            "end":"2019-05-06",
            "rates":{
                "SS":12.0,
                "SD":13.0,
                "US":10.0,
                "UD":11.0
            }
        },
        {
            "id":"2",
            "start":"2019-05-07",
            "end":"2019-09-10",
            "rates":{
                "SS":34.0,
                "SD":33.0,
                "US":32.0,
                "UD":133.0
            }
        },
        {
            "id":"3",
            "start":"2019-11-19",
            "end":"2019-12-31",
            "rates":{
                "SS":123.0,
                "SD":300.0,
                "US":44.0,
                "UD":45.0
            }
        }
    ],
    "amenities": ['bf', 'wf'],
    "created":{"at":1541672565854}
}];

export default samples;
