import {get} from "lodash"
import {Dialog} from "root/v-app/rbServices";

/**
 * Created by DejanK on 4/5/2017.
 *
 * Creates
 */
export default BidManagerService

BidManagerService.$inject = ['BidManagerViewService', 'BidManagerQueryService', 'BidManagerBidsParserService', 'rbDialog', '$rootScope'];
function BidManagerService(BidManagerViewService, BidManagerQueryService, BidsParserService, rbDialog, $rootScope){
  const $service = this;
  let view;

  $service.getViews = BidManagerViewService.getUserViews;
  $service.loadView = loadViewByViewId;
  $service.loadViewByRfpId = loadViewByRfpId;
  $service.updateBids = updateBids;
  $service.handleActionResult = handleActionResult;
  $service.getBidContactLastActive = getBidContactLastActive;

  function notifyListeners(){
    $rootScope.$broadcast('RB_BID_MANAGER_VIEW_CHANGED', {view: view});
  }

  function getBidContactLastActive(bidId){
    return BidManagerQueryService.getBidContactLastActive(bidId).then(({data}) => data);
  }

  function loadViewByViewId(viewId){
    return loadView(() => BidManagerViewService.getView(viewId))
  }

  function loadViewByRfpId(rfpId){
    return loadView(() => BidManagerViewService.getViewByRfpId(rfpId))
  }

  function loadView(loadFn){
    return loadFn()
      .then(copyViewSortToQuickieSort)
      .then(cacheView)
      .then(BidManagerQueryService.queryBids)
      .then(parseBidsQueryHttpResponse)
      .then(notifyListeners)
  }

  function copyViewSortToQuickieSort(httpResponse){
    httpResponse.sort && ( httpResponse.quickies = { sort: httpResponse.sort[Object.keys(httpResponse.sort)[0]] });
    return httpResponse;
  }

  function cacheView(httpResponse){
    view = httpResponse;
    return httpResponse;
  }

  function parseBidsQueryHttpResponse(httpResponse){
    BidsParserService.parseBidsQueryHttpResponse(httpResponse, view);
  }

  /**
   * Uses ActionReports to update BidManager
   */
  function updateBids(actionReport){
    const savedResponseData = view.$lastHttpResponse.data,
      reports = actionReport && actionReport.children;

    if(reports) {
      reports.forEach( report => {
        const modifiedBid = report.getData(),
          index = modifiedBid && savedResponseData.findIndex(b => b.$bidId === modifiedBid.$bidId);

        if (index !== null && index !== -1) {
          if (modifiedBid.$status === 'DELETED') {
            savedResponseData.splice(index, 1);
            view.$lastHttpResponse.total--;
          } else {
            savedResponseData[index] = modifiedBid
          }
        }
      });
    } 
    notifyListeners(view);
    return actionReport;
  }

  function handleActionResult(actionReport, bids, actionReportDialog, successDialog){
    updateBids(actionReport);
    updateActionReport(actionReport, bids);
    actionReport.containsErrors()
      ? Dialog.show( actionReportDialog, { report: actionReport })
      : successDialog && Dialog.show( successDialog, { report: actionReport });

    function updateActionReport(actionReport, bids){
      const reports = actionReport && actionReport.children;
      reports.forEach(r => {
        const reportBid = r.getData(),
          reportBidId = reportBid.$bidId,
          bid = bids.find(b => b.$bidId === reportBidId );
        r.name = get(bid, 'supplier.company.name', get(reportBid, 'supplier.company.name', ''))
      });
      return actionReport
    }
  }
}
