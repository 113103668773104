<template>
  <rb-drop-down :is-open="dropDownOpen" @close="toggleDropDown" align="right">
    <div slot="toggler">
      <button class="action with-icon" @click="toggleDropDown">
        <i class="material-icons">archive</i>
        <span>Export</span>
      </button>
    </div>
    <div slot="dropdown" :class="$style.dropdownContainer" @click="toggleDropDown">
      <button class="rbButtonMenuItem" @click="exportBidManagerToExcel()">Bid Manager</button>
      <button class="rbButtonMenuItem" @click="exportGbtaToExcel()">GBTA (xlsx)</button>
      <button class="rbButtonMenuItem" @click="exportGbtaToCsv">GBTA (csv)</button>
      <button v-if="selectionContainsFinalAgreements" class="rbButtonMenuItem" @click="exportFinalAgreementsToExcel()">Final Agreements (xlsx)</button>
    </div>
  </rb-drop-down>
</template>

<script>
  import RbDropDown from 'vRoot/_core/RbDropdown.vue'
  import {mainAPI, notificationUtils} from "root/angular-injector-provider";

  export default {
    name: 'rbv-hotel-rfp-bid-manager-export',
    components: {RbDropDown},
    props: {
      view: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        dropDownOpen: false,
      }
    },
    computed: {
      selectionContainsFinalAgreements() {
        return this.view.bids && this.view.bids.find(b => b.$status === 'FINAL_AGREEMENT')
      }
    },
    methods: {
      getBidsIds () { return this.view.bids.map(b => b.$bidId) },
      export ( exportFn ) { return notificationUtils().onLoadWithNotification( exportFn ) },
      exportFinalAgreementsToExcel () {
        this.export( () => mainAPI().hotelRfpExportFinalAgreementsToExcel(this.getBidsIds()) )
      },
      exportGbtaToExcel () {
        this.export( () => mainAPI().hotelRfpExportGbtaToExcel(this.getBidsIds()) )
      },
      exportBidManagerToExcel () {
        this.export( () => mainAPI().hotelRfpExportBidManagerToExcel(this.getBidsIds(), this.view.columns) )
      },
      exportGbtaToCsv() {
        this.export( () => mainAPI().hotelRfpExportGbtaToCsv(this.getBidsIds()) )
      },
      toggleDropDown() {
        if (this.dropDownOpen) {
          this.dropDownOpen = false
        } else {
          this.$emit('refreshView')
          this.dropDownOpen = true
        }
      }
    }
  }
</script>

<style lang="stylus" module="">
  .dropdownContainer {
    background: #FFFFFF;
    border: 1px solid #CFD8DC;
    margin-top: 4px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow : 0 0 5px 1px rgba(0, 0, 0, 0.18);
  }
</style>

