import * as SmallSuccess from './small-success/small-success.ctrl'

export { Dialogs as default }

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .$new( SmallSuccess.NAME, SmallSuccess.HTML, SmallSuccess.Controller )
  ;
}

