/**
 * Created by DejanK on 1/18/2017.
 *
 * rbInputDouble
 */

'use strict';

module.exports = {
  template: templateFn,
  bindings: {
    model: '='
  }
};

templateFn.$inject = ['$attrs', 'RbInputTemplate'];
function templateFn($attrs, RbInputTemplate){

  let options = angular.extend(RbInputTemplate.readDefaultAttributes($attrs));

  return RbInputTemplate.doubleTemplate(options);
}
