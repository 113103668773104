/**
 * Created by DejanK on 8/10/2016.
 */
import { abstractState } from '../shared/tools/router-helper';

export default States;

States.$inject = ['$stateProvider'];
function States($stateProvider) {

  $stateProvider
    .state('rfps', abstractState('/rfps'))
    ;
}
