<template>
  <modal-form :class="$style.dialog" @cancel="$formCancelHandler" @submit="$formSubmitHandler" >

    <span slot="header">Change Personal Information</span>

    <div slot="body" class="vModalDefaultBody personal-info">

      <div :class="$style.columnsContainer">

        <rb-input forId="firstName" :error="$showError('firstName')" :class="$style.column">
          <span slot="label">First Name</span>
          <input id="firstName" type="text" name="firstName" class="rbInput" autofocus
                 v-model="data.firstName"
                 v-validate="{required: true, max: 50}"
                 data-vv-as="First Name"/>
        </rb-input>

        <rb-input forId="lastName" :error="$showError('lastName')" :class="$style.column">
          <span slot="label">Last Name</span>
          <input id="lastName" type="text" name="lastName" class="rbInput"
                 v-model="data.lastName"
                 v-validate="{required: true, max: 50}"
                 data-vv-as="Last Name"/>
        </rb-input>

      </div>

      <rb-input forId="phone" :error="$showError('phone')">
        <span slot="label">Phone</span>
        <input id="phone" type="text" name="phone" class="rbInput"
               v-model="data.phone"
               v-validate="{ max:20, regex: /^(\+|00)?[(]?[0-9]{0,3}[)]?[-\s.]?[[0-9]{0,3}]?[-\s./]?[[0-9]{0,3}]?[-\s.]?[0-9]{3,7}$/ }"
               data-vv-as="Phone Number"/>
      </rb-input>

    </div>

  </modal-form>
</template>

<script>
  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'vRoot/_mixins/RbFormMixin.vue'
  import RbInput from 'vRoot/_core/RbInput.vue'


  export default {
    name: 'rbv-user-profile-edit-personal-info-dialog',
    mixins: [ RbFormMixin, DialogMixin ],
    components: { RbInput, ModalForm },
    data() {
      return {
        data: {}
      }
    },
    props: {
      user: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    methods: {
      onSubmit () { return {form: this.data} }
    },
    mounted () {
      this.data = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        phone: this.user.phone
      }
    }
  }
</script>

<style lang="stylus" module="">

  .dialog {
    min-width 400px
  }


  .columnsContainer {
    display flex
    justify-content space-between
  }

  .column {
    width: 49%
  }

</style>
