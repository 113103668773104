import { isFunction, isNil } from 'lodash'

const validators = {
  required ( subject, isRequired ) {
    return isRequired && (subject === undefined || subject === null || (subject+'').trim().length === 0)
  },
  maxLength ( subject, maxLength ) { return subject && subject.length > maxLength },
  pattern ( subject, patternValue ) {
    return !isNil(subject) && !patternValue.test(subject)
  }
}

export { getValidationRules, validateUser, getErrorMessage }

function getValidationRules() {
  return {
    firstName: {
      required: true,
      maxLength: 100
    },
    lastName: {
      required: true,
      maxLength: 100
    },
    email: {
        required: true, 
        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    },
    phone: {
        maxLength: 100
    },
    jobTitle: {
        maxLength: 100
    },
    department: {
        maxLength: 100
    }
  }
}

function getErrorMessage(field, rule){
    switch(rule){
        case 'required':
            return field + " is required";
        case 'maxLength':{
            const n = getValidationRules()[field][rule];
            return `${field} must be at most ${n} characters long`;
        }
        case 'pattern':
        default: 
            return `${field} must be a valid email address`;
    }
}

function validateUser(td){
  const errors = {}, validationRules = getValidationRules()
  Object.keys(validationRules).forEach( rule => { errors[rule] = validate(td[rule], validationRules[rule], td) })
  return errors
}

function validate(subject, rules, user) {
  const errorsBox = { invalid: false, errors: [] }

  Object.keys(rules).forEach( ruleName => {
    const rule = rules[ruleName],
      isError = isFunction(rule) ? rule(subject, user) : validators[ruleName](subject, rule)

    if(isError){
      errorsBox.invalid = isError
      errorsBox.errors.push(ruleName)
    }
  })

  return errorsBox
}
