import Vue from 'vue';

import FilterBar from './FilterBar.vue';
import { noop, cloneDeep } from 'lodash';
export default {
  template: '<div></div>',
  bindings: {
    filters: '<',
    onUpdate: '&',
    onSave: '&',
    onDone: '&',
    onSetDefaultFilters: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element', 'RfpHotelRfpDestinationsRepository', 'NotificationUtils'];
function Ctrl($element, repository, utils){
  const
    vm = this,
    reactiveData = { filters: {}, chains: [] }

  let vueComponent

  vm.$onInit = () => {
    initVueComponent()
    utils.onLoad( () => repository.listChains() )
      .then( chains => { reactiveData.chains = chains.data })
      .catch(noop)
  }
  vm.$onDestroy = () => { vueComponent && vueComponent.$destroy() }
  vm.$onChanges = () => {
    vm.filters && (reactiveData.filters = cloneDeep(vm.filters))
  }

  function handleFiltersChanged(event) {
    vm.onUpdate({
      $event: {
        filters: event.filters,
        apply: true
      }
    });
  }

  function handleSaveFilters(event){
    vm.onSave({
      $event: {
        filters: event.filters,
        apply: true
      }
    });
  }

  function handleSetDefaultFilters(event){
    vm.onSetDefaultFilters({
      $event: {
        filters: event.filters,
        apply: true
      }
    });
  }

  function initVueComponent(){
    !vueComponent && initVue();
    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { reactiveData: reactiveData },
        render: function (createElement) {
          return createElement(FilterBar, {
            props: {
              filters: reactiveData.filters,
              chains: reactiveData.chains
            },
            on: {
              filtersChanged (event) { handleFiltersChanged(event)},
              saveFilters(event) { handleSaveFilters(event)},
              done() { vm.onDone( {$event:{} } )},
              setDefaultFilters(event) { handleSetDefaultFilters(event)}
            },
          })
        }
      })
    }
  }
}
