<template>
    <div>
        <div class="bar">
            <div :class="$style.title">
                <i class="material-icons" :class="$style.titleIcon">local_airport</i>
                <div>
                    <div :class="$style.titleText">Travel Policy</div>
                    <div :class="$style.titleSubText">Upload your travel policy to make it available for your users to download</div>
                </div>
            </div>
        </div>
        <div :class="$style.main" v-if="loaded">
            <input type="file" accept="application/pdf" :class="$style.hide" ref="fileSelector" @change="upload" />
            <rbv-loading v-if="uploading" :label="`Uploading File (${uploadProgress}%)`"/>
            <template v-else-if="hasTravelPolicy" >
                <div :class="$style.name">{{filename}}</div>
                <i class="fa fa-file-pdf-o" :class="$style.uploadedIcon"></i>
                <div :class="$style.text">
                    You can only upload files in .PDF format. if your file is in another format, you can convert it , or save it as a .pdf in your text editor.
                </div>
                <div :class="$style.buttons">
                    <div>
                        <a :href="directory.travelPolicy"
                            target="_self"
                            download="travel_policy"
                            class="button">
                            <i class="material-icons">get_app</i>
                            Download
                        </a>
                    </div>
                    <button
                        @click="drop"
                        class="button">
                        Delete
                    </button>
                    <button
                        @click="select"
                        class="button cta">
                        <i class="material-icons">publish</i>
                        Upload New
                    </button>
                </div>
            </template>
            <template v-else>
                <div :class="$style.name">Upload Your Travel Policy</div>
                <i class="fa fa-file-pdf-o" :class="$style.icon"></i>
                <div :class="$style.text">
                    You can only upload files in .PDF format. if your file is in another format, you can convert it , or save it as a .pdf in your text editor.
                </div>
                <div :class="$style.buttons">
                    <div>
                        <a href="/files/sample_hotel_directory_travel_policy.docx"
                            target="_self"
                            download="sample_travel_policy"
                            class="button">
                            <i class="material-icons">get_app</i>
                            Download Sample
                        </a>
                    </div>

                    <button
                        @click="select"
                        class="button cta">
                        <i class="material-icons">publish</i>
                        Upload File
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import hotelDirectoryService from '../../hotel-directory-service';
import { Dialog } from "root/v-app/rbServices";

import RbvLoading from "vRoot/_core/RbvLoading.vue";
import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';

export default {
    name: 'HotelDirectoryAddHotels',
    components: { RbvLoading },
    data(){
        return {
            uploading: false,
            uploadProgress: 0,
        }
    },
    mounted() {
      this.$nextTick(() => hotelDirectoryService.showOptInDialog());
    },
    asyncComputed: {
        directory(){
            return hotelDirectoryService.getDirectory().then(directory => directory);
        }
    },
    computed: {
        loaded(){ return this.directory },
        hasTravelPolicy(){ return this.directory && this.directory.travelPolicy; },
        filename(){
            const subs = this.directory && this.directory.travelPolicy.split('_');
            return subs.slice(1, subs.length-1).join('_');
        }
    },
    methods: {
        upload(e){
            if(!e.target.files[0]){
                return;
            }
            this.uploading = true;
            this.uploadProgress = 0;
            hotelDirectoryService.saveTravelPolicy(e.target.files[0], this.updateProgress).then(() => {
                this.uploading = false;
                this.refresh();
                this.$refs.fileSelector.value = "";
            });
        },
        updateProgress(value){
            value = value > 90? 90: value;
            this.uploadProgress = value;
        },
        select(){
            this.$refs.fileSelector.click();
        },
        refresh(){
            hotelDirectoryService.getDirectory(true).then(directory => {
                this.directory = directory;
            });
        },
        drop(){
            Dialog.show(WarningDialog, {
                title: `Delete Travel Policy`,
                subTitle: 'Deleting this travel policy will make it unavailable to travelers and travel agencies visiting your directory.'
            }).then(() => {
                hotelDirectoryService.deleteTravelPolicy().then(this.refresh);
            });
        }
    }
}
</script>

<style lang="stylus" module>
    .title{
        color #fff;
        display flex;
    }

    .titleText{
        padding 0 10px;
    }

    .titleSubText{
        padding 0 10px;
        font-size: 12px;
        color: #acbec5;
        padding: 0 10px;
    }

    .titleIcon{
        color: #2db8aa;
        background: #263238;
        padding: 12px;
        /* vertical-align: middle; */
        border-radius: 100%;
        height: 48px;
        width: 48px;
        box-sizing: border-box;
    }

    .controls{
        display flex;
        justify-content flex-end;
        flex 1;
    }

    .main{
        height calc(100vh - 158px);
        display flex;
        flex-direction column;
        justify-content center;
        align-items center;
    }

    .main > *{
        margin: 10px 0;
    }

    .name{
        font-weight bold;
        color #66767f;
    }

    .icon{
        color #cfd8dc;
        font-size 120px;
    }

    .uploadedIcon{
        color: #f5f6f7;
        background-color: #525f67;
        font-size: 120px;
        border-radius: 35px;
        border-top-right-radius: 55px;
    }

    .text{
        color: #91a4af;
        width: 450px;
        font-size: 12px;
        text-align center;
    }

    .buttons{
        min-width: 435px;
        display: flex;
        justify-content flex-end;
    }

    .buttons > div{
        flex 1;
    }

    .hide{
        display none;
    }
</style>

