import { noop } from 'lodash'
import { NAME as smallSuccess } from "../../dialogs/small-success/small-success.ctrl";

export default function (ctrl) {
  Object.assign(ctrl, {
    showErrors: {},

    sendMessage () {
      ctrl.utils.onSave(ctrl.save)
        .then( () => ctrl.utils.showSuccessDialog({ dialogName: smallSuccess}) )
        .catch(noop)
        .finally(()=>{
          ctrl.$state.reload()
            .catch(noop)
        })
    }
  })
}
