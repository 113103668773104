/**
 * Created by DejanK on 11/03/2016.
 */

'use strict';

require('../../../../shared/styles/account-setup.scss');

export default {
  HTML: require('./set-affiliation.html'),
  Controller: SetAffiliationController
}

SetAffiliationController.$inject = ['AccountService', 'NotificationUtils'];
function SetAffiliationController(AccountService, notificationUtils){
  const vm = this;
  init();

  function init() {
    notificationUtils.onLoad(() => { return AccountService.getAccountTypes() }, vm, 'affiliations')
  }
}
