/**
 * Created by DejanK on 7/7/2017.
 */
'use strict';

let lodash = require('lodash');

require('./rb-negotiations-amenity-history.scss');

module.exports = {
  template: require('./rb-negotiations-amenity-history.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    amenity: '<'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsHtmlFactory'];
function Ctrl($element, NegotiationsHtmlFactory) {
  let vm = this, tooltipster,
    htmlContentElement, parentElement, tableElement;

  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;

  function onInit(){
    parentElement = $element.parent();
    htmlContentElement = $($element.children()[0]);
    tableElement = $(htmlContentElement.find('div.table'));

    parentElement.tooltipster({
      animationDuration: [300, 100],
      theme: ['', 'negotiations-amenity-history'],
      content: htmlContentElement,
      contentAsHtml: true,
      distance: 0,
      interactive: true,
      functionBefore: printHistory,
      functionReady: scrollToActive
    });

    tooltipster = parentElement.tooltipster('instance');
  }

  function onDestroy(){
    tooltipster.destroy();
  }


  function printHistory(){
    if(!checkRequirements()) return;
    vm.mixed = vm.amenity.cat === 'MIXED';

    let historyHtml = NegotiationsHtmlFactory.printAmenityHistory(vm.negotiations.communication, vm.amenity.id, vm.negotiation, vm.mixed);
    vm.mixed ? tableElement.addClass('mixed') : tableElement.removeClass('mixed');
    tableElement.empty();
    tableElement.append(historyHtml);
  }

  function checkRequirements(){
    return vm.negotiations && vm.amenity;
  }

  function scrollToActive(){
    let activeElements = tableElement.find('div.layout-row.active');
    if(activeElements.length) {
      scrollTo(tableElement, $(activeElements[0]));
    } else {
      tableElement[0].scrollTop = tableElement[0].scrollHeight;
    }

    function scrollTo(container, scrollTo){
      container.scrollTop(
        scrollTo.offset().top - container.offset().top + container.scrollTop() - (container.height()/2)
      );
    }
  }
}
