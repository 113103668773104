/**
 * Created by DejanK on 7/24/2017.
 *
 */
import { cloneDeep, isUndefined } from 'lodash'

export default NegotiationsModelFactory

NegotiationsModelFactory.$inject = ['$rootScope'];
function NegotiationsModelFactory($rootScope){

  function NegotiationModel(model, $scope, elements){
    this.model = model;
    this.initialModel = cloneDeep(model);
    this.elements = elements;
    this.elements.setModel(this);
    this.render();

    $scope.$on('RB_NEGOTIATION_CHANGED', ()=>{this.render()});

  }

  NegotiationModel.prototype = {
    constructor: NegotiationModel,
    getValue: function (){
      return this.model.value;
    },
    setValue: function(model){
      Object.assign(this.model, model);
      this.onModelChanged();
    },
    update: function (value) {
      if(!value && value !== 0) {
        Object.assign(this.model, this.initialModel);
        this.onModelChanged();
      } else {
        this.model.valid = true;
        this.model.type = this.model.type === 'UNAVAILABLE' ? 'FIXED' : this.model.type;
      }
      this.render();
    },
    onModelChanged: function (){
      $rootScope.$broadcast('RB_NEGOTIATION_CHANGED');
    },
    render: function (){
      this.elements.updateView(this.model);
    }
  };

  /**
   * Rate Model
   */
  function RateModel(model, $scope, $element, currencyFormat){
    NegotiationModel.call(this, model, $scope, new ModelElements($element, currencyFormat));
  }

  RateModel.prototype = Object.create(NegotiationModel.prototype, {
    _super: {value: NegotiationModel.prototype},
    constructor: {value: RateModel},
    setValue: {value: function(value){
        this._super.setValue.call(this, {value: value});
      }},
    update: { value: function(){ this._super.update.call(this, this.model.value)}},
    resetAndReduceByPercent: { value: function(percent){
        Object.assign(this.model, this.initialModel);
        this.model.value = Math.floor(this.model.value * percent/5)*5;
        this.onModelChanged();
      }}
  });

  /**
   * Amenity Model
   */
  function AmenityModel(model, $scope, $element, currencyFormat, percentageFormat){
    NegotiationModel.call(this, model, $scope, new AmenityModelElements($element, currencyFormat, percentageFormat));
  }

  AmenityModel.prototype = Object.create(NegotiationModel.prototype, {
    _super: {value: NegotiationModel.prototype},
    constructor: {value: AmenityModel},
    setValue: {value: function(value){
        const model = this.model;
        this._super.setValue.call(this, Object.assign({}, model, {value: model.type === 'PERCENTAGE' ? value/100 : value}));
      }},
    update: {value: function() { this._super.update.call(this, this.model.value)}},
    resetAndReduceByPercent: { value: function(percent){
        Object.assign(this.model, this.initialModel);
        if(this.model.type === 'FIXED' && !this.model.included && this.model.valid)
        {
          this.model.value = Math.floor(this.model.value * percent/5)*5;
          this.model.included = this.model.value <= 20;
        }

        this.onModelChanged();
      }}
  });


  /**
   * ModelElements
   */
  function ModelElements($element, currencyFormat){
    this.amountFormat = currencyFormat;

    this.children = $element.children();
    this.self = $element;
    this.input = $(this.children[0]);
  }

  ModelElements.prototype.setFormattedValue = function(rawValue){
    if(!this.focused) {
      const value = parseFloat(rawValue.type === 'UNAVAILABLE' || !rawValue.valid ? null : rawValue.value);
      this.setValue(isNaN(value) ? '---' : this.amountFormat(value));
    }
  };

  ModelElements.prototype.getValue = function(){
    const v = (this.input[0].value+'').replace(/[^0-9.]/g, ''),
      num = v.slice(0, 15),
      result = parseFloat(num);

    return isNaN(result) ? undefined : parseFloat(result.toFixed(2));
  };

  ModelElements.prototype.setValue = function(v){
    this.input[0].value = isUndefined(v) ? '---' : v;
  };

  ModelElements.prototype.updateView = function(value) {
    this.setFormattedValue(value);
  };

  ModelElements.prototype.setModel = function(model){
    const self = this;
    this.input
      .on('input change', cleanAndUpdateModel)
      .on('focus', ()=> { self.focused = true; parseElementValue()})
      .on('blur', ()=>{ self.focused = false; !self.readOnly && model.update(); });

    function cleanAndUpdateModel(){
      model.setValue(self.getValue());
      self.setValue((self.input[0].value+'').replace(/[^0-9.]/g, '').slice(0, 15));
    }

    function parseElementValue(){
      if(!self.readOnly){
        const inputElement = self.input,
          v = self.getValue();
        self.setValue(v || 0);
        inputElement.select();
      }
    }
  };

  function AmenityModelElements($element, currencyFormat, percentageFormat){
    ModelElements.call(this, $element, currencyFormat);
    this.includedClass = 'included';
    this.percentageFormatter = percentageFormat;
    this.includedNotification = $(this.children[1]);
  }

  AmenityModelElements.prototype = Object.create(ModelElements.prototype, {
    constructor: {
      value: AmenityModelElements
    },

    setFormattedValue: {
      value: function (viewValue) {
        if(!this.focused) {
          const value = parseFloat(viewValue && viewValue.type !== 'UNAVAILABLE' && viewValue.valid && viewValue.value),
            isPercentage = (viewValue && viewValue.type) === 'PERCENTAGE';
          this.input.val(isPercentage ? this.percentageFormatter(value) : this.amountFormat(value));
        }
      }
    },

    setReadOnly: {
      value: function(){
        this.readOnly = true;
        this.self.addClass('readonly');
        this.input.attr({
          'tabindex': '-1',
          'readonly': true
        })
      }
    },

    setIncluded: {
      value: function () {
        this.readOnly = true;
        this.self.addClass(this.includedClass);
        this.includedNotification.css('display', 'block');
        this.input.attr({
          'tabindex': '-1',
          'readonly': true
        })
      }
    },

    unsetIncluded: {
      value: function () {
        this.readOnly = false;
        this.self.removeClass(this.includedClass);
        this.includedNotification.css('display', 'none');
        this.input.removeAttr('tabindex');
        this.input.removeAttr('readonly');
      }
    },

    updateView: {
      value: function (viewValue) {
        this.setFormattedValue(viewValue);
        viewValue.included ? this.setIncluded() : this.unsetIncluded();
        ['UNAVAILABLE', 'MOCKED'].indexOf(viewValue.type) !== -1 && this.setReadOnly();
      }
    }
  });

  return {
    createAmenityModel: (model, $scope, $element, currencyFormat, percentageFormat)=>{
      return new AmenityModel(model, $scope, $element, currencyFormat, percentageFormat);
    },
    createRateModel: (model, $scope, $element, currencyFormat)=>{
      return new RateModel(model, $scope, $element, currencyFormat);
    }
  }
}
