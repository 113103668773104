import * as Working from './working/working.ctrl'
import * as Success from './success/success.ctrl'
import * as SuccessLarge from './success-large/success-large.ctrl'
import * as Error from './error/error-notification.ctrl'
import * as NotImplemented from './not-implemented/not-implemented-notification.ctrl'

export { Dialogs as default }

Dialogs.$inject = ['rbDialogProvider'];
function Dialogs(rbDialogProvider) {
  rbDialogProvider
    .addDialog( Working )
    .addDialog( Success )
    .addDialog( SuccessLarge )
    .addDialog( Error )
    .addDialog( NotImplemented )
  ;
}

