import {CURRENT_USER_CHANGED_EVENT} from 'root/auth/current-user.service';

RFPsListController.$inject = ['ControllerUtils', 'NotificationUtils', 'rfpManagerRepository', 'CurrentUser', '$scope'];

function RFPsListController(utils, NotificationUtils, rfpManagerRepository, CurrentUser, $scope) {
  const vm = this;

  vm.$onInit = () => {
    vm.user = null;
    $scope.$on(CURRENT_USER_CHANGED_EVENT, (event, user) => { vm.user = user; });
    CurrentUser.refresh();
  };
}

export default RFPsListController;
