/**
 * Created by DejanK on 9/6/2017.
 *
 */
import HTML from './forward-to-national-sales.html'

const NAME = 'ForwardToNationalSales'

export { NAME, HTML, SendToNamDialogCtrl as Controller }

SendToNamDialogCtrl.$inject = ['rbDialog'];
function SendToNamDialogCtrl(dialog){
  const vm = this
  vm.cancel = dialog.cancel
}
