/**
 * Created by DejanK on 8/12/2016.
 *
 * @ngdoc service
 * @name rbOverlayService
 * @module rb.core
 * @description
 * The rbOverlayService adds and removes overlay elements to the DOM. Used for dialogs, notifications...
 *
 * TODO: Will not work properly if dialog is created using templateUrl that needs to be fetched and loading is finished
 * after rbDialog cancel or hide is called... This will leave dirty overlays with overlay element shown.
 */

import { identity } from 'lodash'
import angular from 'angular'

export default RbOverlayService;

RbOverlayService.$inject = ['$document', '$rootScope', '$mdCompile', '$q', '$timeout'];
function RbOverlayService($document, $rootScope, $mdCompile, $q, $timeout){
  const self = this,
    overlays = [],
    startZIndex = 10000;

  self.show = show;
  self.hide = hide;
  self.cancel = cancel;
  self.cancelAll = cancelAll;
  self.onClickOutsideElement = onClickOutsideElement;

  $rootScope.$on('$locationChangeStart', cancelAll);

  /*
   * @ngdoc method
   * @name rbOverlayService#show
   * @module rb.core
   * @description Adds dialog with dialog name to the DOM. Returns dialog promise.
   * @param `overlayObject` -  `{Object}` Object used to create overlay element:
   *
   *    - `controller` - `{(string=|function()=}` Controller fn that should be associated with
   *      newly created scope or the name of a registered controller if passed as a string.
   *    - `controllerAs` - `{string=}` A controller alias name. If present the controller will be
   *      published to scope under the `controllerAs` name.
   *    - `template` - `{string=}` An html template as a string.
   *    - `templateUrl` - `{string=}` A path to an html template.
   *    - `transformTemplate` - `{function(template)=}` A function which transforms the template after
   *      it is loaded. It will be given the template string as a parameter, and should
   *      return a a new string representing the transformed template.
   *    - `resolve` - `{Object.<string, function>=}` - An optional map of dependencies which should
   *      be injected into the controller. If any of these dependencies are promises, the compiler
   *      will wait for them all to be resolved, or if one is rejected before the controller is
   *      instantiated `compile()` will fail..
   *      * `key` - `{string}`: a name of a dependency to be injected into the controller.
   *      * `factory` - `{string|function}`: If `string` then it is an alias for a service.
   *        Otherwise if function, then it is injected and the return value is treated as the
   *        dependency. If the result is a promise, it is resolved before its value is
   *        injected into the controller.
   *   - `locals` - `{object}`: The locals which will be passed into the controller once `link` is
   *     called. If `bindToController` is true, they will be copied to the ctrl instead
   *   - `bindToController` - `bool`: bind the locals to the controller, instead of passing them in.
   *   - `closeOnOutsideClick` - `bool`: should be closed on click outside object
   *
   *   - `params` - `Object`: params available on dialog scope
   *
   * @returns {object=} promise A promise, which will be resolved when `cancel` or `hide` is called
   */
  function show(overlayObject){
    document.activeElement && document.activeElement.blur()
    return $mdCompile
      .compile(overlayObject)
      .then(showOverlay);

    function showOverlay(compiledData) {
      const deferred = $q.defer(),
        promise = deferred.promise,
        overlayScope = createOverlayScope(compiledData.link, overlayObject.params)

      overlays.push({
        element: createOverlayElement(compiledData.element, overlayScope),
        closeOnOutsideClick: overlayObject.closeOnOutsideClick,
        scope: overlayScope,
        deferred: deferred
      })

      return promise

      function createOverlayElement(compileDataElement) {
        const overlayElement = angular.element('<div class="rbOverlay"></div>');
        overlayElement.attr('data-id', overlays.length);
        overlayElement.css('z-index', startZIndex + (overlays.length * 1000));
        overlayElement.append(compileDataElement);

        $document.find('body').eq(0).append(overlayElement); // modal.html's template in an element
        return overlayElement;
      }

      function createOverlayScope(compileDataLink, params) {
        const overlayScope = $rootScope.$new()
        overlayScope.params = params
        compileDataLink(overlayScope) // attach controller & scope to element
        return overlayScope
      }
    }
  }

  /*
   * @ngdoc method
   * @name rbOverlayService#hide
   * @module rb.core
   * @description Removes last added overlay element from the DOM. Resolves dialog promise with result.
   * @param `result` - `{Object=|string=}` Result with which dialog promise will be resolved.
   * @returns {object=} promise A promise, which will be resolved when `cancel` or `hide` is called
   */
  function hide(result){
    return destroyLastOverlay()
      .then((overlay) => {
        overlay && overlay.deferred.resolve(result)
        return overlay ? overlay.deferred.promise : {}
      })
  }

  /*
   * @ngdoc method
   * @name rbOverlayService#cancel
   * @module rb.core
   * @description Removes last added overlay element from the DOM. Rejects dialog promise with result.
   * @param `reason` - `{Object=|string=}` Reason with which dialog promise will be rejected.
   * @returns {object=} promise A promise, which will be resolved when `cancel` or `hide` is called
   */
  function cancel(reason){
    return destroyLastOverlay()
      .then((overlay) => {
        overlay && overlay.deferred.reject(reason)
        return overlay ? overlay.deferred.promise : {}
      }, identity)
  }

  /*
   * @ngdoc method
   * @name rbOverlayService#cancelAll
   * @module rb.core
   * @description Removes all added overlay elements from the DOM. Rejects all dialog promises.
   */
  function cancelAll(){
    const cancelPromises = []
    overlays.forEach( () => { cancelPromises.push(cancel()) })
    return $q.all(cancelPromises)
  }

  function destroyLastOverlay(){
    return $timeout(()=>{
      const overlay = overlays.pop()
      if(overlay) {
        overlay.element.remove()
        overlay.scope.$destroy()
      }
      return overlay
    })
  }

  function onClickOutsideElement(){
    overlays[overlays.length-1].closeOnOutsideClick && cancel()
  }
}
