import { get, merge } from 'lodash'
import { NAME as CAPTCHA_DIALOG } from "../dialogs/captcha/captcha.ctrl";

export default SignInService
export const BAD_CREDENTIALS = 'BAD_CREDENTIALS'

SignInService.$inject = ['NotificationUtils', 'PublicAuth', 'rbDialog', '$q', 'CurrentUser'];
function SignInService(notificationUtils, publicAuth, dialog, $q, currentUser) {
  const vm = this;
  vm.signIn = signIn;

  function signIn(credentials) {
    return notificationUtils.onSave(() => { return publicAuth.signIn(credentials) }, {
      customErrorHandlers: {
        '400' (error) {
          if(get(error, 'data.message') === 'INVALID_CAPTCHA_TOKEN'){
            return dialog.show(CAPTCHA_DIALOG).then(
              (dialogResult) => { return signIn(merge({}, credentials, dialogResult)) },
              badCredentials)
          } else {
            notificationUtils.showError('500')
            return $q.reject(error)
          }
        },
        '401': badCredentials
      }
    }).then(currentUser.refresh)

    function badCredentials() { return $q.reject(BAD_CREDENTIALS) }
  }
}
