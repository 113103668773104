<script type="text/jsx">
  import TwoLinesWithHeaderLayout from 'vRoot/_core/layouts/dialog-layouts/notifications/TwoLinesWithIconHeaderLayout.vue';
  import SvgIcon from 'vRoot/_core/icons/SvgIcon.vue';
  import TextContainer from 'vRoot/_core/layouts/dialog-layouts/notifications/TextContainer.vue';
  import QuestionIcon from 'root/assets/icons/notifications-icons.svg';

  export default {
    functional: true,
    render( h, { listeners, $style, props, data, scopedSlots, slots }) {
      const
        iconStyle = props.theme === 'error' ? $style.iconError : $style.iconNeutral,
        iconLink = `${QuestionIcon}#${props.icon}`;

      return (
        <div class={'vModal vModalDefault ' + data.class + ' ' + data.staticClass}>

          <TwoLinesWithHeaderLayout onCancel={listeners.cancel} scopedSlots={{
            icon: () => <SvgIcon class={ iconStyle } icon={iconLink} />,
            subTitle: () => slots().subTitle,
            title: () => slots().title,
          }} />

          <TextContainer class={$style.bodyText} scopedSlots={{
            default: () => slots().message
          }} />

          <div class={ 'vModalDefaultFooter' } >
            { slots().footer }
          </div>

        </div>
      )
    },
  }
</script>

<style lang="stylus" module>

  .icon {
    width: 52px;
    height: 52px;
  }

  .iconNeutral {
    composes: icon;
    fill: #00A99D;
  }

  .iconError {
    composes: icon;
    fill: #f15a24;
  }

  .bodyText{
    padding: 0 20px;
    flex: 0 0 auto;
    margin-bottom 25px
  }

</style>
