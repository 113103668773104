import {checkAndExecute, checkAndExecuteToActionReport} from "root/api/helpers";
import HotelRfpBidFinalAgreementAPI from './HotelRfpBidFinalAgreementAPI';

export default function HotelRfpBidAPI(api) {
  Object.assign(api, {

    deleteBids: bids => checkAndExecuteToActionReport(
      () => bids,
      () => api.server.post(`rfps/hotel/bids/delete`, { bids: bids })),

    setBidSupplierContact: ( bidId, contactUserAccountId ) => checkAndExecuteToActionReport(
      () => bidId && contactUserAccountId,
      () => api.server.post(`rfps/hotel/bids/${bidId}/supplier/contact/set`, { userAccountId : contactUserAccountId })),

    createBidSupplierContact: ( bidId, contact) => checkAndExecuteToActionReport(
      () => bidId && contact,
      () => api.server.post(`rfps/hotel/bids/${bidId}/supplier/contact/create`, contact )),

    setSupplierContactToBids: ( bidsIds, contactUserAccountId ) => checkAndExecuteToActionReport(
      () => bidsIds && contactUserAccountId,
      () => api.server.post(`rfps/hotel/bids/supplier/contact/set`, { userAccountId : contactUserAccountId, bids: bidsIds })),

    createSupplierContactInBids: ( bidsIds, contact ) => checkAndExecuteToActionReport(
      () => bidsIds && contact,
      () => api.server.post(`rfps/hotel/bids/supplier/contact/create`, Object.assign({}, contact, { bids: bidsIds }))),

    sendBids: ( bids, ignoreDueDate ) => checkAndExecuteToActionReport(
      () => bids,
      () => api.server.post(`rfps/hotel/bids/send`, {bids: bids, ignoreDueDate: !!ignoreDueDate })),
    changeBidsSupplier: ( bids, ignoreDueDate ) => checkAndExecuteToActionReport(
      () => bids,
      () => api.server.post(`rfps/hotel/bids/change`, {bids: bids, ignoreDueDate: !!ignoreDueDate })),

    sendBidsToSelectedContact: ( bidsIds, contactUserAccountId, ignoreDueDate ) => checkAndExecuteToActionReport(
      () => bidsIds && contactUserAccountId,
      () => api.server.post(`rfps/hotel/bids/send/to/${contactUserAccountId}`, { bids: bidsIds, ignoreDueDate: !!ignoreDueDate })),

    sendBidsChangeToSelectedContact: ( bidsIds, contactUserAccountId, ignoreDueDate ) => checkAndExecuteToActionReport(
          () => bidsIds && contactUserAccountId,
          () => api.server.post(`rfps/hotel/bids/change/to/${contactUserAccountId}`, { bids: bidsIds, ignoreDueDate: !!ignoreDueDate })),

    sendBidsToNewContact: ( bidsIds, contact, ignoreDueDate ) => checkAndExecuteToActionReport(
      () => bidsIds && contact,
      () => api.server.post(`rfps/hotel/bids/send/to/new`, Object.assign({}, contact, { bids: bidsIds, ignoreDueDate: ignoreDueDate }))),
    sendBidsChangeToNewContact: ( bidsIds, contact, ignoreDueDate ) => checkAndExecuteToActionReport(
          () => bidsIds && contact,
          () => api.server.post(`rfps/hotel/bids/change/to/new`, Object.assign({}, contact, { bids: bidsIds, ignoreDueDate: ignoreDueDate }))),

    listChainContacts: (chainId) => checkAndExecute(
      () => chainId,
      () => api.server.get(`rfps/hotel/representatives/chain/${chainId}`)),

    listHotelContacts: (hotelId) => checkAndExecute(
      () => hotelId,
      () => api.server.get(`rfps/hotel/representatives/hotel/${hotelId}`)),

    saveDraftResponse: ( bidId, response ) => checkAndExecuteToActionReport (
      () => bidId && response,
      () => api.server.post(`rfps/hotel/bids/${bidId}/response/draft`, { response: response })),

    saveDraftResponses: ( bidsIds, responses ) => checkAndExecuteToActionReport (
      () => bidsIds && responses,
      () => api.server.post('rfps/hotel/bids/responses/draft', { bidsIds: bidsIds, responses: responses })),

    sendResponses: ( bidsIds, ignoreErrors ) => checkAndExecuteToActionReport (
      () => bidsIds,
      () => api.server.post('rfps/hotel/bids/responses', { bidsIds: bidsIds, ignoreErrors: ignoreErrors})),

    setBidResponse: (bidId, answers, ignoreErrors) => checkAndExecuteToActionReport (
      () => bidId && answers,
      () => api.server.post(`rfps/hotel/bids/${bidId}/response`, { response: answers, ignoreErrors: ignoreErrors})),

    getQuestionnaire: bidId => checkAndExecute (
      () => bidId,
      () => api.server.get(`rfps/bids/${bidId}/response`)),

    getResponse: bidId => checkAndExecute (
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/response`)),

    setAcceptedRates: (bidId, acceptedRates) => checkAndExecute (
      () => bidId && acceptedRates && acceptedRates.length,
      () => api.server.post(`rfps/hotel/bids/${bidId}/accept`, {acceptedRates: acceptedRates})),

    sendNotInterested: bidsIds => checkAndExecuteToActionReport (
      () => bidsIds,
      () => api.server.post(`rfps/hotel/bids/not-interested`, { bids: bidsIds })),

    sendNoThankYou: bidsIds => checkAndExecuteToActionReport (
      () => bidsIds,
      () => api.server.post(`rfps/hotel/bids/no-thank-you`, { bids: bidsIds })),

    viewNoThankYouMessage: bidId => checkAndExecute (
      () => bidId,
      () => api.server.get(`rfps/hotel/bids/${bidId}/no-thank-you`)),

    reviseBidResponse: (bidId, answers) => checkAndExecute (
      () => bidId && answers,
      () => api.server.post(`rfps/hotel/bids/${bidId}/revise-response`, { response: answers, acceptedRates: [] })),

  })

  HotelRfpBidFinalAgreementAPI(api)
}
