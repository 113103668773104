<template>
  <div class="rfp-manager">
    <div
      v-if="!accounts"
      class="layout-column flex-center"
    >
      <rbv-loading label="Loading, please wait..." />
    </div>
    <rfp-manager
      v-else
      :user="user"
      :accounts="accounts"
      @refreshAccounts="refreshAccounts"
    />
  </div>
</template>

<script>
  import rfpManager from './manager.vue';
  import rfpManagerListService from './services/rfp-manager-list.service';
  import RbvLoading from 'vRoot/_core/RbvLoading.vue';

  export default {
    name: 'RfpManager',
    components: {rfpManager, RbvLoading},
    asyncComputed: {
      accounts() { return rfpManagerListService.listAccounts().then(accounts => accounts)}
    },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    methods: {
      refreshAccounts() {
        return rfpManagerListService.listAccounts().then(accounts => {this.accounts = accounts});
      }
    }
  }
</script>

<style lang="stylus">
  body{
    min-height 100vh;
  }

  .rfp-manager{
    i.material-icons{
      font-size 18px;
    }
  }
</style>
