/**
 * Created by DejanK on 4/27/2017.
 *
 * Bid Manager Actions
 */


require('./rb-bid-manager-actions.scss');

module.exports = {
  template: require('./rb-bid-manager-actions.html'),
  bindings: {
    bmView: '<',
    selectedBids: '<',
    onAction: '&'
  },
  controller: RbBidManagerActions
};

RbBidManagerActions.$inject = ['BidManagerBidActionsService'];
function RbBidManagerActions(BidManagerBidActionsService) {
  const vm = this;

  vm.$onChanges = $onChanges;
  vm.executeAction = executeAction;

  function $onChanges(changes){
    changes.selectedBids && updateAvailableActions();
  }

  function updateAvailableActions(){
    vm.actions = BidManagerBidActionsService.getActions(vm.selectedBids, vm.bmView);
  }

  function executeAction(action){
    action && action();
    vm.onAction && vm.onAction();
  }
}
