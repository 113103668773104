import Vue from 'vue';
import FilterBar from './BidManagerExport.vue';

export default {
  template: '<div></div>',
  bindings: {
    bidManager: '<',
    onDone: '&'
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element']
function Ctrl($element){
  const
    vm = this,
    reactiveData = { view: {} }

  let vueComponent

  vm.$onInit = () => { initVueComponent() }
  vm.$onDestroy = () => { vueComponent && vueComponent.$destroy() }

  function updateView(){
    const bidManager = vm.bidManager,
      bids = bidManager.selectedBids && bidManager.selectedBids.length ? bidManager.selectedBids.slice() : bidManager.bids.data.filter(b => !b.header)

    Vue.set(reactiveData, 'view', {
      bids: bids,
      columns: getExportColumns(bidManager.view.columns)
    })

    function getExportColumns(viewColumns){
      const columns = []
      viewColumns.forEach( vc => vc.export && columns.push(... vc.export) )
      return columns;
    }
  }

  function initVueComponent(){
    !vueComponent && initVue();
    function initVue(){
      vueComponent = new Vue({
        el: $element[0],
        data: { reactiveData: reactiveData },
        render: function (createElement) {
          return createElement(FilterBar, {
            props: {
              view: reactiveData.view
            },
            on: {
              refreshView () { updateView() },
            },
          })
        }
      })
    }
  }
}
