<template>
  <div @click.stop.prevent="toggle">
    <i class="material-icons">today</i>
    <span>{{ value? formattedValue: placeholder }}</span>
  </div>
</template>

<script>
  import Pikaday from 'pikaday';
  import Moment from 'moment';

  export default {
    name: 'InlineDatepikaday',
    props: {
      formatView: {
        type: String,
        default: 'MM/DD/YYYY'
      },
      formatData: {
        type: String,
        default: 'YYYY-MM-DD'
      },
      minDate: {
        type: String,
        default: undefined
      },
      maxDate: {
        type: String,
        default: undefined
      },
      value: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        pikaday: null
      }
    },
    computed: {
      formattedValue() {
        return this.value && Moment(this.value).format(this.formatView);
      },
    },
    mounted() {
      const vue = this;

      this.pikaday = new Pikaday({
        field: this.$el,
        format: this.formatView,
        position: 'bottom left',
        onSelect () {
          const value = this.toString(vue.formatData);
          vue.$emit('input', value);
          return value !== vue.value && vue.$emit('change', value);
        },
        onOpen () { return vue.readonly && this.hide() }
      });

      this.updateView(this.value);
    },
    destroyed() {
      return this.pikaday && this.pikaday.destroy();
    },
    methods: {
      toggle() {
        return this.pikaday.isVisible()? this.pikaday.hide(): this.pikaday.show();
      },
      updateView(value){
        let m;
        if( value && value.length >=10 ){
          m = Moment(value, this.formatView);
          m = m.isValid() ? m : Moment(value, this.formatData);
          if(m.isValid()) this.pikaday.setMoment(m);
        }
      },
    }
  }
</script>

<style scoped>

</style>
