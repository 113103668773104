<script>
  import QuestionnaireQuestionMixin from '../mixins/QuestionnaireQuestionMixin.vue'
  import { formatDate } from 'root/shared/tools/date-tools'

  export default {
    name: 'rb-questionnaire-question-date',

    mixins: [ QuestionnaireQuestionMixin ],

    methods: {
      formatAnswer (answer) { return formatDate(answer) }
    }
  }
</script>
