<template>
  <div :class="supplierClass"
       :id="supplier.id"
       @mouseenter="mouseEnter(supplier.id)"
       @mouseleave="mouseLeave">

    <supplier-checkbox :state="supplier.bidState"
                       @click="handleSupplierCheckboxClicked(supplier)"/>

    <div :class="$style.details"
         @click="panMapTo(supplier.location.coordinates)">
      <div :class="$style.detailsNameLine">
        <div :class="nameClass">
          <i v-if="supplier.lastYear" class="material-icons" :class="$style.lastYearIcon">star</i>
          <span class="vAlignMiddle">{{supplier.name}}</span>
          <div v-if="supplier.lastYear"
               class="rbTooltip simple"
               tooltip="placement:top-start">Was in Last Years Programme</div>
        </div>
        <span class="vAlignMiddle">&bull; {{supplier.location.fullAddress}}</span>
      </div>
      <div :class="$style.detailsSpecsLine">
        <span v-if="sortedField" :class="$style.badge">{{ sortedField }} </span><span>{{ specification }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import suppliersListMixin from './SupplierMixin.vue';
  import SupplierCheckbox from './SupplierSelectedCheckbox.vue';
  import { get } from 'lodash'

  export default {
    name: "rbv-travel-destinations-map-suppliers-table-shrinked-view-supplier",
    mixins: [suppliersListMixin],
    components: { SupplierCheckbox },
    computed: {
      nameClass() { return this.isSelected ? this.$style.nameAdded : this.$style.nameNotAdded },
      sortedField() {
        let s = false
        if(this.sorter.isDisplayedInBadge){
          if(this.sorter.key === 'distanceMi'){
            s = this.sorter.order === 'asc' ? false : this.formattedDistance
          } else {
            s = get(this.supplier, this.sorter.key)
          }
        }
        return s
      },
      specification() {
        const fs = []

        if(!(this.sorter.key === 'distanceMi' && this.sorter.order === 'desc')) {
          fs.push(`Distance: ${this.formattedDistance}`)
        }

        if(this.sorter.key !== 'chain.masterChain.name') {
          const chain = get(this.supplier, 'chain.masterChain.name')
          chain && fs.push( chain.toUpperCase() )
        }

        if(this.sorter.key !== 'chain.name') {
          const brand = get(this.supplier, 'chain.name')
          brand && fs.push( `(${brand})` )
        }

        return fs.join(' • ')
      },
      supplierClass() { return this.isSelected ? this.$style.supplierSelected : this.$style.supplier }
    },
    methods: {
      mouseEnter(id) { this.$rbEmit({ name: 'onSupplierMouseEnter', id }) },
      mouseLeave() { this.$rbEmit({ name: 'onSupplierMouseLeave' }) },
      panMapTo(coordinates) { this.$rbEmit({ name: 'onPanMapTo', coordinates }) }
    }
  }
</script>

<style lang='stylus' module>

  .supplier {
    box-sizing: border-box;
    height: 62px;
    background: white;
    border-top: solid 1px #ECEFF1;
    cursor: pointer;
    display: flex;
  }

  .supplierSelected {
    composes supplier
    background: #F4F5F7;
  }

  .details {
    flex 0 1 100%
    display: flex
    flex-direction column
    justify-content center
    overflow hidden
  }

  .detailsLine {
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    padding-right: 10px
    color: #90A4AE
  }

  .detailsNameLine {
    composes detailsLine
    font-size 13px
    line-height 22px
  }

  .detailsSpecsLine {
    composes detailsLine
    font-size 11px
    line-height 16px
  }

  .name {
    display inline-block
  }

  .nameAdded {
    composes name
    color #00A99D
  }

  .nameNotAdded {
    composes name
    color #546E7A
  }

  .lastYearIcon {
    font-size 18px
    vertical-align middle
  }

  .badge {
    display: inline-block;
    background: #B0BEC5;
    color: #fff;
    border-radius: 10px;
    padding: 1px 7px;
    margin-right: 5px;
  }

</style>
