/**
 * Created by DejanK on 7/3/2017.
 *
 */

export default NegotiationsService;

NegotiationsService.$inject = ['MainAPI', 'NegotiationsFactory'];
function NegotiationsService(mainAPI, NegotiationsFactory){
  const $service = this;

  $service.getNegotiations = getNegotiations;

  function getNegotiations(bidId){
    return mainAPI.hotelRfpNegotiationGet(bidId)
      .then( reply => NegotiationsFactory.create(reply.data) );
  }
}
