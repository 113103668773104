<template>
  <div>
    <component :is="buttonsConfiguration" />
  </div>
</template>

<script>
  import TheButtonsForBuyerCreatingRequest from './TheNegotiationsFormButtonsForBuyerCreatingRequest.vue';
  import TheButtonsForBuyerEditingRequest from './TheNegotiationsFormButtonsForBuyerEditingRequest.vue';
  import TheButtonsForSupplierCreatingResponse from './TheNegotiationsFormButtonsForSupplierCreatingResponse.vue';
  import TheButtonsForSupplierEditingResponse from './TheNegotiationsFormButtonsForSupplierEditingResponse.vue';

  import { noop } from "lodash";
  import { currentUser } from "root/angular-injector-provider";

  export default {
    name: 'negotiations-form-buttons',

    props: [ 'mode' ],

    components: { TheButtonsForBuyerCreatingRequest, TheButtonsForBuyerEditingRequest, TheButtonsForSupplierCreatingResponse, TheButtonsForSupplierEditingResponse },

    data () {
      return {
        buttonsConfiguration: null
      };
    },

    mounted() {
      currentUser().get().then(
        user => {
          const isInCreationMode = this.mode === 'CREATE';
          if(user.isBuyer()){
            this.buttonsConfiguration = isInCreationMode ? TheButtonsForBuyerCreatingRequest : TheButtonsForBuyerEditingRequest;
          } else {
            this.buttonsConfiguration = isInCreationMode ? TheButtonsForSupplierCreatingResponse : TheButtonsForSupplierEditingResponse;
          }
        }
      ).catch(noop);
    }
  };
</script>
