/**
 * Created by DejanK on 7/17/2017.
 */
'use strict';

let lodash = require('lodash');

module.exports = {
  template: require('./rb-negotiations-input-rate-tooltip.html'),
  bindings: {
    negotiations: '<',
    negotiation: '<',
    rateId: '@'
  },
  controller: Ctrl
};

Ctrl.$inject = ['$element', 'NegotiationsEditorTooltipsFactory', 'NegotiationsHtmlFactory', '$timeout'];
function Ctrl($element, NegotiationsEditorTooltipsFactory, NegotiationsHtmlFactory, $timeout) {
  let vm = this;

  vm.$onChanges = updateVM;

  function updateVM(){
    if (!(vm.negotiations && vm.rateId)) return;
    //$timeout(()=>{
      vm.rate = vm.negotiations.getRate(vm.rateId);
      vm.seasonPeriod = vm.negotiations.seasons.getFormattedPeriod(vm.rate.season);
      vm.roomType = vm.negotiations.roomTypes.print(vm.rate.roomType);

      let html = NegotiationsHtmlFactory.printRateHistory(vm.negotiations.communication, vm.rateId),
        tt = NegotiationsEditorTooltipsFactory.createRateTooltip(vm, $element, html);
      tt.enable();
    //}, 1000);
  }
}
